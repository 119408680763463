import React, { useState } from "react";
import { connect } from "react-redux";
import Recordings from "./Recordings";
import Sidebar from "./Sidebar";
import VideoMeetings from "./VideoMeetings";
import UpcomingMeetings from "./VideoMeetings/UpcomingMeetings";

function Meet(props) {
  const [state, setState] = useState("meet");
  const [MeetType, setMeetType] = useState("start_meet");

  const OnChangeState = (state, subState = "") => {
    setState(state);
    switch (subState) {
      case "start_meet":
        return setMeetType("start_meet");
      case "join_meet":
        return setMeetType("join_meet");
      case "schedule_meet":
        return setMeetType("schedule_meet");
      default:
        return setMeetType("");
    }
  };

  return (
    <div className="right-side-wrapper pt-0 ">
      <div className="card">
        <div className="card-body p-0">
          <div className="row m-0">
            <Sidebar
              state={state}
              MeetType={MeetType}
              Selecttab={OnChangeState}
            />
            {state === "meet" && (
              <VideoMeetings meetType={MeetType} Selecttab={OnChangeState} />
            )}
            {state === "upcoming" && (
              <UpcomingMeetings
                type={"upcoming"}
                direct={false}
                Selecttab={OnChangeState}
              />
            )}
            {state === "All Recording" && <Recordings />}
            {state === "my_recordings" && <Recordings />}
            {state === "shared" && <Recordings />}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {};
export default connect(mapStateToProps)(Meet);
