export const USERPANEL  = "userPanel/";
export const ADMINPANEL = "adminPanel/";


export const ACCOUNT_SUMMARY            =       "account-summary";
export const DID_AVAILABLE              =       "did-number-available";
export const DID_NUMBER_BUY             =       "did-number-buy";
export const DID_CITY_LIST              =       "did-city-list";
export const CURRENCY_LIST              =       "currency-list";
export const BUY_NXA_PLAN               =       "get-plan";
export const PLANS_LIST                 =       "plan-list";
export const INITIATE_PAYMENT           =       "initiate-payment";
export const NXA_VOUCHER                =       "add-crdit-by-voucher";
export const SHOW_PAYMENT               =       "show-payments";
export const DELETE_REGISTERED_CARD     =       "delete-card"; 
export const CANCEL_PLAN                =       "cancel-plan";
export const CHANGE_PLAN                =       "change-plan"; 
export const UPGRADE_PLAN               =       "upgrade-plan";
export const CARD_LISTING               =       "card-details-listing";
export const PAYMENT_LISTING            =       "payment-listing";
export const PLAN_RENEWAL_PAYMENT       =       "plan-renewal-payment";



export const PREPARE_PEACH_PAYMENT_CHECKOUT         =       "api/NxaAPI/PreparePeachPaymentCheckout";
export const FETCH_ONE_VOUCHER                      =       "api/NxaAPI/FlashSwitchRedeemOperations";
export const ZOHO_ACCESS_TOKEN                      =       "zoho-access-token";
export const PREPARE_PEACH_PAYMENT                  =       "api/NxaAPI/PreparePeachPayment";
 