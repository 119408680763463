import React, { useState, useEffect } from "react";
import moment from "moment";
import { store } from "../../../../Services";
import {
  DeleteTeam,
  IVRGreetingList,
} from "../../../../Services/Actions/settingActions";
import { convertUtcToLocalTime } from "../../../../Utils/Common";
import Tooltips from "../../../../Component/Tooltips";
import Pagination from "../../../../Component/Pagination";
import TeamSlider from "../../../Calls/Team/TeamSlider";
import { TeamListing } from "../../../../Services/Actions/callActions";
import swal from "sweetalert";
import { connect } from "react-redux";

function Team({ SetTab, team_listing ,extension_listing }) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [totalItems, setTotalItems] = useState([]);
  const [pageofItems, setPageOfItems] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [team_details, setTeamDeatils] = useState({});

  useEffect(async () => {
    TeamListing(0,store.dispatch);
  }, []);

  useEffect(() => {
    setTotalItems(team_listing);
  }, [team_listing]);

  const SearchDataHandler = (e) => {
    let search = e.target.value;
    setSearch(search);
    setTotalItems(
      team_listing.filter((value) => {
        return value.name.toLowerCase().includes(search.toLowerCase());
      })
    );
  };

  const sliceData = (pageofItems) => {
    setPageOfItems(pageofItems);
  };

  const DeleteTeamHandler = (id) => {
    swal({
      text: "Are you sure want to delete this team?",
      icon: "warning",
      title:"Warning!",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await DeleteTeam(id, store.dispatch);
        if (response === 1) {
          IVRGreetingList(store.dispatch);
        }
      }
    });
  };

  const ViewTeamHandler = (value) => {
    setTeamDeatils(value);
    setIsEdit(true);
    setOpen(true);
  };

  const addMemberHandler = () => {
    setTeamDeatils({});
    setIsEdit(false);
    setOpen(true);
  };

  return (
    <>
      <div className="phone-system-inner-main-div">
        <div className="phone-system-inner-div">
          <div className="phone-system-inner-head">
            <div className="ps-inner-head-left">
              <h3>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => SetTab("phone")}
                  className="phone-system-name"
                >
                  Phone Settings
                </span>
                <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                <span>Teams</span>
              </h3>
            </div>

            <div className="ps-inner-head-right">
              <span className="search-ps-inner">
                <img src="./../assets/images/search-dash.svg" alt="1" />
                <input
                  className="form-control"
                  type="text"
                  name="search"
                  value={search}
                  placeholder=""
                  onChange={SearchDataHandler}
                />
              </span>
              <a
                href={void 0}
                style={{ cursor: "pointer" }}
                className="create-ps-num-btn"
                onClick={() => addMemberHandler()}
              >
                Add Team
              </a>
            </div>
          </div>
          {/* table start */}
          <div className="phone-system-inner-table">
            {pageofItems && pageofItems?.length === 0 && (
              <div className="team-user-add-div add-data-vactor">
                <img src="./../assets/images/IVR.svg" alt="1" />
                <h4>No Team created</h4>
                <a
                  href={void 0}
                  className="btn btn-orange"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(true)}
                >
                  Add Team
                </a>
              </div>
            )}
            
            <div className="phone-system-inner-table">
              {pageofItems && pageofItems?.length !== 0 && (
                <div className="table-responsive call-info-scroll single-table-height">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Creation Date</th>
                        <th> Name</th>
                        <th> Team Members</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageofItems &&
                        pageofItems.map((value, index) => {
                          let date_time = convertUtcToLocalTime(
                            value.created_at
                          );
                          let formattedDate = moment(date_time)
                            .locale("en")
                            .format("ll");
                          let formattedTime = moment(date_time)
                            .locale("en")
                            .format("LT");
                          return (
                            <tr key={index}>
                              <td>{`${formattedDate} ${formattedTime}`}</td>
                              <td>
                                <span className="business-hrs-service">
                                  <h3>{value.name}</h3>
                                </span>
                              </td>
                              <td>
                                {value?.participants &&
                                  value?.participants?.length === 0 &&
                                  `No Members`}
                                {value?.participants instanceof Array &&
                                  value.participants.map((data, keys) => {
                                    const profile_image = extension_listing?.find(val=>val.customerID === data.customerID)?.profile_picture??""
                                    let colors =
                                      keys % 3 === 0
                                        ? "yellow"
                                        : keys % 3 === 1
                                        ? "blue"
                                        : "pink";
                                    return (
                                      <>
                                        {profile_image &&  keys <= 2 &&(
                                          <Tooltips
                                            placement="top"
                                            heading={data.name}
                                          >
                                            <span className={`assign-to-span`}>
                                              <img
                                                src={
                                                  profile_image
                                                    ? profile_image
                                                    : "./../assets/images/Mask.png"
                                                }
                                                alt="1"
                                              />
                                            </span>
                                          </Tooltips>
                                        )}
                                        {!profile_image && keys <= 2 && (
                                          <Tooltips
                                            placement="top"
                                            heading={data?.name}
                                          >
                                            <span
                                              className={`assign-to-span assign-to-color-${colors}`}
                                            >
                                              <h5>
                                                {data?.name
                                                  ?.match(/\b(\w)/g)
                                                  .join("")
                                                  .toUpperCase()}
                                              </h5>
                                            </span>
                                          </Tooltips>
                                        )}
                                        {keys === 3 && (
                                          <Tooltips
                                            placement="top"
                                            heading={`+${
                                              value?.participants?.length - 3
                                            } more`}
                                          >
                                            <span className="assign-to-span assign-to-color-yellow">
                                              <h5> M </h5>
                                            </span>
                                          </Tooltips>
                                        )}
                                      </>
                                    );
                                  })}
                              </td>

                              <td>
                                <a
                                  title="Edit Team"
                                  className="edit-business-hrs"
                                  onClick={() => ViewTeamHandler(value)}
                                >
                                  <img
                                    src="./../assets/images/edit-new.svg"
                                    alt="1"
                                  />
                                </a>
                                &nbsp;&nbsp;
                                <a
                                  title="Delete Team"
                                  className="edit-business-hrs"
                                  onClick={() =>
                                    DeleteTeamHandler(value.id)
                                  }
                                >
                                  <img
                                    src="./../assets/images/trash-2.svg"
                                    alt="delete"
                                  />
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
              <Pagination data={totalItems} currentData={sliceData} />
            </div> 
          </div>
        </div>
      </div>

      <TeamSlider
        addTeam={open}
        closeTeamSlider={() => setOpen(false)}
        team_details={team_details}
        isEdit={isEdit}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  team_listing: state?.call?.team_listing ?? [],
  extension_listing:state?.setting?.extension_listing ?? [],
});

export default connect(mapStateToProps)(Team);
