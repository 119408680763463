import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { firebaseLogin } from "../../Firebase/firebaseActions";
import { LoginFxn } from "../../Services/Actions/signupActions";
import authService from "../../Utils/authService";
import { withRouter } from "../../Utils/withRouter";
import { store } from "../../Services";
import LoaderPage from "./LoaderPage";

function LoginViaToken(props) {
  useEffect(() => {
    let token = window.location.pathname.replace("/login-via-token/", "");
    let data = { token, request_from: "web" }; 
    LoginFxn(data, store.dispatch).then((response) => {
      if (response.ResponseCode === "1") {
        const { status, user_status, email, password , db_name, payment_token ,payment_status} =
        authService.getUserDetail();
        localStorage.setItem("login-via-token", token );
        firebaseLogin({ email, password });
        if (status === "P") {
          if (user_status === "UP") {
            return props.navigate("/choose-plan");
          } else if (user_status === "UF") {
            return props.navigate("/order-summary");
          } else if (user_status === "RE") {
            if(db_name === ""){ 
              return props.navigate(`/fetch-payment/${payment_token}`);
            }else{
              return props.navigate("/rica-process");
            }  
          } else {
            return props.navigate("/choose-number");
          }
        } else if(status === "A" && (payment_status === "P" || payment_status === "E")) {
          return props.navigate("/settings");
        }else{
          return props.navigate("/dashboard");
        }
      } else {
        swal({
          title: "Error!",
          text: response.ResponseText,
          icon: "error",
        });
      }
    });
  }, []);
  return <LoaderPage type="initial" />;
}

export default withRouter(LoginViaToken);
