import React, { useEffect, useState, useRef } from "react"; 
import SimpleReactValidator from "simple-react-validator"; 
import { store } from "../../Services";
import { AuthorizeMeeting } from "../../Services/Actions/meetActions"; 
import { IdentifyLinkUrl, MeetDomain } from "../../Utils/Common";

function Join({ UpdateTopic }) {
  const [meetingID, setMeetingID] = useState("");
  const [clicked, setClicked] = useState(false);
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  useEffect(() => {
    UpdateTopic("join");
    return function cleanup() {
      UpdateTopic("");
    };
  }, []);

  const JoinMeeting = async()=>{
      if(validator.current.allValid()){
      setClicked(true)
      let room_name  = IdentifyLinkUrl(meetingID) ?  meetingID && meetingID.split("/").pop() : meetingID ;
      let response = await AuthorizeMeeting({ room_name,password: '' },store.dispatch);
      if (response.code === 1) {    
        let login_via_token = localStorage.getItem("login-via-token");   
        let url = `https://${MeetDomain}/${room_name}?token=${(login_via_token)}`   
          window.open(url, "_blank"); 
          setClicked(false)
          setMeetingID("")
          UpdateTopic("")
      }
      else {
          setClicked(false)
          setMeetingID("")
          UpdateTopic("")
      }
  }
      else{
          validator.current.showMessages()
          forceUpdate(1)
      }
  }

  return (
    <>
      <div className="modal-body join-modal-body">
        <div className="col-md-12">
          <div className="meeting-modal-heading">
            <h2>Join a meeting</h2>
          </div>
        </div>
        <form>
          <div className="form-group join-group">
            <label htmlFor="join-meet-id" className="col-form-label join-meet-id">
              Meeting ID
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter meeting ID or meeting link"
              name="meetingID"
              value={meetingID}
              onChange={(event) => setMeetingID(event.target.value)}
            />
            {validator.current.message("Meeting ID", meetingID, "required")}
          </div>
        </form>
      </div>
      <div className="modal-footer meeting-modal-footer">
        <button
          type="button"
          className="btn btn-blue-text"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-meet-join"
          onClick={JoinMeeting}
        >
          {clicked ? "Please Wait..." : "Join"}
        </button>
      </div>
    </>
  );
}
export default Join;
