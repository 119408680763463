export const FETCH_TEAM_LISTING             =           "FETCH_TEAM_LISTING"
export const FETCH_SMS_CHAT_LISTING         =           "FETCH_SMS_CHAT_LISTING"
export const NXA_CONTACT_LISTING            =           'NXA_CONTACT_LISTING'
export const FETCH_RECENT_CALL_LISTING      =           'FETCH_RECENT_CALL_LISTING'
export const CALL_LISTING_COUNT             =           'CALL_LISTING_COUNT'
export const ALL_RECORDING_COUNT            =           'ALL_RECORDING_COUNT'
export const FETCH_ALL_RECORDING_LISTING    =           'FETCH_ALL_RECORDING_LISTING'
export const FETCH_VOICEMAIL_LISTING        =           "FETCH_VOICEMAIL_LISTING" 
export const FORCE_CALL                     =           'FORCE_CALL'
export const SELECTED_CHAT_USER             =           'SELECTED_CHAT_USER';
export const SELECTED_CHAT_TYPE             =           'SELECTED_CHAT_TYPE';
export const SELECTED_GROUP_CHAT_USER       =           'SELECTED_GROUP_CHAT_USER'
export const FETCH_CALL_DETAILS             =           'FETCH_CALL_DETAILS' 
export const FETCH_ASSIGNED_NUMBER_LIST     =           'FETCH_ASSIGNED_NUMBER_LIST' 
export const GET_TAG_LISTING                =           "GET_TAG_LISTING"
export const FETCH_PROFILE_INFO_DATA        =           'FETCH_PROFILE_INFO_DATA'
export const FETCH_NOTES_LISTING            =           'FETCH_NOTES_LISTING'
export const FETCH_SMS_CHAT_HISTORY         =           "FETCH_SMS_CHAT_HISTORY"
export const OPEN_NXATEL_USER_CHAT          =           "OPEN_NXATEL_USER_CHAT"
export const FETCH_TRANSFER_CALL_SESSIONS   =           "FETCH_TRANSFER_CALL_SESSIONS"
export const FETCH_CONFERENCE_CALL_SESSIONS =           "FETCH_CONFERENCE_CALL_SESSIONS"




export const INDIVIDUAL_CONTACT_CALL_HISTORY    =       "INDIVIDUAL_CONTACT_CALL_HISTORY"