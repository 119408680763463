import React, { useEffect, useState, useRef } from "react";
import Fade from "@material-ui/core/Fade";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import { store } from "../../../Services";
import {
  AddTag,
  AssignTag,
  FetchProfileInfoData,
  TagListing,
} from "../../../Services/Actions/callActions";
import Modal from "../../../Component/Modal";
import { connect } from "react-redux";

function TagModal(props) {
  const {
    open,
    handleClose,
    tag_listing,
    destination_number,
    assignedTagList,
  } = props;
  const [pageType, setPageType] = useState("tag-list");
  const [colorCode, setColorCode] = useState("#1569a8");
  const [tagName, setTagName] = useState("");
  const [assignedTag, setAssignedTag] = useState([]);
  const [, forceUpdate] = useState();
  const [pageOfItems, setPageOfItems] = useState([]);
  const [searchTag, setSearchTag] = useState("");
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  useEffect(async () => {
    TagListing(store.dispatch);
  }, []);

  useEffect(() => {
    let array = [];
    setPageOfItems(tag_listing);
    tag_listing &&
      tag_listing.map((value) => {
        assignedTagList && assignedTagList
          .filter((assigned_tag) => assigned_tag.tagID === value.uniquecode)
          .map((previoustags) => {
            return array.push(previoustags.tagID);
          });
      });
    setAssignedTag(array);
  }, [tag_listing]);

  const SaveTagHandler = async () => {
    if (validator.current.allValid()) {
      let res = await AddTag(
        { color_code: colorCode, tag_name: tagName },
        store.dispatch
      );
      if (res === 1) {
        setPageType("tag-list");
        setTagName("");
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const checkboxCheckHandler = (e, tagID) => {
    if (e.target.checked) {
      if (assignedTag.length < 3) {
        setAssignedTag([...assignedTag, tagID]);
      } else {
        swal({
          icon: "warning",
          title:"Warning!",
          text: "You may add up to 3 tags.If you wish to replace a tag,please uncheck one of the assigned tags first!",
          buttons: true,
        });
      }
    } else {
      let array = [...assignedTag];
      let index = array.indexOf(tagID);
      if (index !== -1) {
        array.splice(index, 1);
        setAssignedTag(array);
      }
    }
  };

  const AssignTagHandler = async () => {
    let res = await AssignTag(
      { tagID: assignedTag, number: destination_number },
      store.dispatch
    );
    if (res === 1) {
      FetchProfileInfoData({ number: destination_number }, store.dispatch);
      handleClose();
    }
  };

  const FilterTagsHandler = (search_tag) => {
    let updatedList = pageOfItems;
    updatedList = pageOfItems.filter((value) => {
      return value.name.toLowerCase().search(search_tag) !== -1;
    });
    setPageOfItems(search_tag ? updatedList : tag_listing);
  };

  const searchTagHandler = (e) => {
    setSearchTag(e.target.value);
    FilterTagsHandler(e.target.value);
  };

  return (
    <div>
      <Modal
        open={open}
        size={`sm`}
        CloseModal={handleClose}
        title={`Tags`}
        showActiveClass={open}
      >
        <>
          {pageType === "tag-list" && (
            <Fade in={true} timeout={500}>
              <React.Fragment>
                <div className="assign-modal-list">
                  <div className="search-assign">
                    <img src="./../assets/images/search-dash.svg" alt="1" />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      name="searchTag"
                      value={searchTag}
                      onChange={(e) => searchTagHandler(e)}
                    />
                  </div>
                  <ul className="assign-modal-list-ul">
                    {pageOfItems &&
                      pageOfItems.map((value, index) => {
                        return (
                          <li key={index}>
                            <span
                              className="color-circle"
                              style={{ background: value.color_code }}
                            ></span>
                            <span className="assign-li-text assign-li-text-2">
                              <h5>{value.name}</h5>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={value.uniquecode}
                                  value={value.uniquecode}
                                  name="assignedTag"
                                  onChange={(e) =>
                                    checkboxCheckHandler(e, value.uniquecode)
                                  }
                                  checked={assignedTag.includes(
                                    value.uniquecode
                                  )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={value.uniquecode}
                                ></label>
                              </div>
                            </span>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="assign-modal-blank">
                  {assignedTag && assignedTag.length === 0 && (
                    <a
                      href={void 0}
                      className="add-team-member"
                      style={{ cursor: "pointer" }}
                      onClick={() => setPageType("add-tag")}
                    >
                      Add More Tags
                    </a>
                  )}
                  {assignedTag && assignedTag.length !== 0 && (
                    <a
                      href={void 0}
                      className="add-team-member"
                      style={{ cursor: "pointer" }}
                      onClick={AssignTagHandler}
                    >
                      Add Tags
                    </a>
                  )}
                </div>
              </React.Fragment>
            </Fade>
          )}
          {pageType === "add-tag" && (
            <Fade in={true} timeout={500}>
              <React.Fragment>
                <div className="add-color-input">
                  <input
                    className="form-control"
                    type="text"
                    name="tagName"
                    value={tagName}
                    onChange={(e) => setTagName(e.target.value)}
                    placeholder="Enter Tag Name"
                  />
                  <label>
                    <span
                      className="color-circle"
                      style={{ background: colorCode }}
                    />
                    <input
                      type="color"
                      value={colorCode}
                      onChange={(e) => setColorCode(e.target.value)}
                      className="hidden-input"
                    />
                  </label>
                  {validator.current.message("Tag Name", tagName, "required")}
                </div>
                <div className="assign-modal-blank">
                  <a
                    href={void 0}
                    className="add-team-member"
                    style={{ cursor: "pointer" }}
                    onClick={SaveTagHandler}
                  >
                    Save Tag
                  </a>
                </div>
              </React.Fragment>
            </Fade>
          )}
        </>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    tag_listing: state?.call?.tag_listing ?? [],
  };
};
export default connect(mapStateToProps)(TagModal);
