import React, { Component } from "react";
import Modal from "../../../../Component/Modal";
// import Modal from "./../../Widgets/Modal";
// import { connect } from "./../../../../base/redux";  

class DeleteIPDevices extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    } 

    render() {
        const { openModal, handleClose } = this.props;
        return (
            <>
                <Modal
                    open={openModal}
                    CloseModal={handleClose}
                    title={""}
                    showActiveClass={openModal}
                    size={`sm`}
                >
                    <div className="assign-modal-div delete-num-modal">
                        <h3>
                            <span>Delete IP Device::</span>
                            <span className="assign-to-num">
                                Customer Service
                            </span>
                        </h3>
                        <p>
                            You about to delete the IP device please note the device will go offline and will not be able to make or receive calls. 
                        </p>
                        <div className="modal-footer number-dl-footer text-center">
                            <button type="button" className="btn assign-del-btn" style={{cursor:'pointer'}}>
                                Delete
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}
 

export default  DeleteIPDevices 
