import React, { Component } from "react";
import moment from "moment";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official' 
class MonthlyCallReportGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { callData } = this.props;

        const options = {
            colors: ["#FFD24F", "#457AFE", "#f17c2b"],
            title: {
                text: "",
            },
            xAxis: {
                categories:
                    callData &&
                    callData.map(({ date }) => {
                        const category = [];
                        let _formatedDate = moment(date).locale("en").format("ll").split(",")
                        return [
                            ...category,
                            _formatedDate[0],
                        ];
                    }),
            },
            yAxis: {
                title: {
                    text: "Total Calls",
                },
                labels: {
                    formatter: function () {
                        return this.value + "";
                    },
                },
            },
            tooltip: {
                crosshairs: true,
                shared: true,
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                        lineColor: "#666666",
                        lineWidth: 1,
                    },
                }, 
            }, 
            series: [
                {
                    name: "All Calls",
                    data:
                        callData &&
                        callData.map(({ total_calls }) => {
                            const allCalls = [];
                            return [...allCalls, total_calls];
                        }),
                },
                {
                    name: "Inbound Calls",
                    data:
                        callData &&
                        callData.map(({ incoming_calls }) => {
                            const inbound = [];
                            return [...inbound, incoming_calls];
                        }),
                },
                {
                    name: "Outbound Calls",
                    data:
                        callData &&
                        callData.map(({ outgoing_calls }) => {
                            const outbound = [];
                            return [...outbound, outgoing_calls];
                        }),
                },
            ],
        };
        return ( 
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        );
    }
}

export default MonthlyCallReportGraph;
