import React, { Component } from "react";
import moment from "moment";
import Picker, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import ReactTooltip from "react-tooltip";
import authService from "../../Utils/authService";
import Chat from "../../Utils/Chat";
import {
  SelectedChatType,
  UploadFiles,
} from "../../Services/Actions/callActions";
import { store } from "../../Services";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { firebaseApp } from "../../Utils/FirebaseService";

const __initialState = {
  _FilePath: "",
  chat: [],
  message: "",
  selectedFile: "",
  type: "",
  groupID: "",
  open: false,
  existing_group: [],
  room_id: "",
  showEmoji: false,
  favourite: false,
  last_seen: [],
  team_info: "",
};
class GroupChat extends Component {
    constructor(props) {
        super(props);
        this.state = __initialState;
        this.extensions = {};
    }

    componentDidMount() {
        var el = this.messagesEnd;
        el.scrollTop = el.scrollHeight;
        this.ChatChange();
    }

    componentDidUpdate(prevProps) {
        const { selected_group_chat_user: old_props } = prevProps;
        const { selected_group_chat_user } = this.props;
        if (selected_group_chat_user !== old_props && selected_group_chat_user) {
        this.ChatChange();
        }
    }

    UpdateChat = (chat) => {
        chat = chat ? Object.values(chat) : [];
        this.setState({ chat });
    };

    ChatChange = () => {
        const { uniquecode } = authService.getUserDetail();
        let {
        selected_group_chat_user,
        selected_group_chat_user: { participants, name, profile, uniquekey },
        } = this.props;
        {
        /** TO CHECK IF USERS CHAT EXISTING  */
        }
        this.setState({ room_id: uniquekey }, () => {
        Chat.fetch_message_data_on_update(uniquekey, this.UpdateChat);
        });
        let joinee = Object.values(participants).map((item) => item.uniquecode);
        let __GrpExists = Chat.fetch_if_ref_exists(`/Groups/${uniquekey}/`);
        __GrpExists.then((response) => {
        if (response === false) {
            Chat.update_ref_with_children(`/Groups/${uniquekey}/`, {
            createdBy: uniquecode,
            displayName: name,
            profile,
            participants,
            last_message: "",
            last_message_sender: "",
            last_message_type: "",
            roomName: uniquekey,
            type: "group",
            updated_at: firebaseApp.database.ServerValue.TIMESTAMP,
            joinee,
            }).then(() => {
            if (selected_group_chat_user.message) {
                this.setState({ message: selected_group_chat_user.message }, () =>
                this.handleSend()
                );
            }
            });
            Chat.fetch_ref_on_update(`/Groups/${uniquekey}`, this.FetchLastSeen);
        } else {
            Chat.fetch_ref_on_update(`/Groups/${uniquekey}`, this.FetchLastSeen);
        }
        });
    };

    FetchLastSeen = (value) => {
        const { uniquecode } = authService.getUserDetail();
        let favourites = [];
        if (value?.participants) {
        this.extensions = value.participants;
        }
        if (value?.favourite) {
        favourites = value.favourite;
        }
        if (value?.last_seen) {
        const { last_seen } = value;
        this.setState({ last_seen, favourite: favourites.includes(uniquecode) });
        } else {
        this.setState({
            last_seen: [],
            favourite: favourites.includes(uniquecode),
        });
        }
    };

    UpdateChat = (chat) => {
        chat = chat ? Object.values(chat) : [];
        this.setState({ chat });
    };

    selectfileHandler = (event) => {
        if (event.target.files && event.target.files[0]) {
        const selectedFile = event.target.files[0];
        this.setState({ selectedFile }, () => this.onChooseFile());
        }
    };

    onChooseFile = () => {
        const { selectedFile } = this.state;
        if (selectedFile) {
        const prefixFiletype = selectedFile.type.toString();
        if (
            prefixFiletype.includes("image") ||
            prefixFiletype.includes("video") ||
            prefixFiletype.includes("application") ||
            prefixFiletype.includes("audio") ||
            prefixFiletype.includes("text")
        ) {
            let type = prefixFiletype.includes("image")
            ? "image"
            : prefixFiletype.includes("video")
            ? "video"
            : prefixFiletype.includes("application")
            ? "file"
            : prefixFiletype.includes("audio")
            ? "audio"
            : "textfile";
            this.setState({ type }, () => this.uploadfile());
        }
        }
    };

    uploadfile = async () => {
        const { selectedFile, type } = this.state;
        let response = await UploadFiles(selectedFile, "chat", store.dispatch);
        if (response.code === 1) {
        this.setState(
            {
            _FilePath: response.file_path,
            },
            () => {
            this.handleSend(type);
            }
        );
        }
    };

    handleSend = (type = "text") => {
        let { room_id, message, _FilePath: media } = this.state;
        const {
        firstname,
        lastname,
        uniquecode,
        profile_picture,
        } = authService.getUserDetail();
        let dataToSend = {
        message,
        date: firebaseApp.database.ServerValue.TIMESTAMP,
        from: uniquecode,
        type,
        media,
        from_name: `${firstname} ${lastname}`,
        isPrivate: false,
        chatWith: "",
        chatFrom: "",
        profile:profile_picture ? profile_picture : "./../assets/images/Mask.png",
        };
        Chat.update_message_data(room_id, dataToSend);
        Chat.fetch_if_ref_exists(`/Groups/${room_id}/`, true).then((value) => {
        Chat.update_ref_with_children(`/Groups/${room_id}/`, {
            ...value,
            last_message: message,
            updated_at: firebaseApp.database.ServerValue.TIMESTAMP,
            last_message_type: type,
            last_message_sender: uniquecode,
            last_seen: [],
        });
        });
        this.setState({
        message: "",
        selectedFile: "",
        _FilePath: "",
        showEmoji: false,
        });
    };

    onEmojiClick = (event, emojiObject) => {
        const { emoji } = emojiObject;
        const { message } = this.state;
        this.setState({ message: `${message}${emoji}` });
    };

    __AddToFavourite = () => {
        const { uniquecode } = authService.getUserDetail();
        let { room_id } = this.state;
        {
        /** TO CHECK IF USERS CHAT EXISTING  */
        }
        Chat.fetch_if_ref_exists(`/Groups/${room_id}/`, true).then((value) => {
        let __ifExists =
            value.favourite && value.favourite.includes(uniquecode)
            ? value.favourite.filter((response) => response !== uniquecode)
            : value.favourite
            ? [...value.favourite, uniquecode]
            : [uniquecode];
        Chat.update_ref_with_children(`/Groups/${room_id}/`, {
            ...value,
            favourite: __ifExists,
        });
        this.setState({
            favourite: __ifExists.includes(uniquecode) ? true : false,
        });
        });
    };

    __UpdateLastSeen = () => {
        const { uniquecode } = authService.getUserDetail();
        const { room_id } = this.state;
        Chat.fetch_if_ref_exists(`/Groups/${room_id}/`, true).then((value) => {
        let __ifExists =
            value.last_seen && value.last_seen.includes(uniquecode)
            ? value.last_seen
            : value.last_seen
            ? [...value.last_seen, uniquecode]
            : [uniquecode];
        Chat.update_ref_with_children(`/Groups/${room_id}/`, {
            ...value,
            last_seen: __ifExists,
        });
        });
    };

    onHideHandler = () => {
        this.setState({ team_info: "" });
    };

    onKeyPressHandler = (e)=>{
        if(e.key === "Enter"){
        this.handleSend();
        } 
    }

  render() {
    const { message, chat, showEmoji, favourite, last_seen, team_info } =
      this.state;
    const myData = authService.getUserDetail();
    const {
      selected_group_chat_user: { name },OpenSmsModal
    } = this.props;
    let length_chat = chat.length;
    return (
      <div className="col-md-8 col-lg-9">
        <div className="open-chat message-chat-body ">
          <div className="open-chat22 ">
            <div className="d-flex chat-profile p-2 justify-content-between">
              <div className="d-flex chat-page-top-dt">
                <Link
                  to="#"
                  className="chat-back-arrow chat-wind-back-arrow chat-back-arrow-2"
                  onClick={() =>
                    SelectedChatType("all_recent_chats", store.dispatch)
                  }
                >
                  <img src="./../assets/images/backA.svg" alt="1" />
                </Link>
                <div className="chat-pro-img chat-pro-new-img">
                  {name && 
                      <div className="one-to-one-chat">
                        <h5>
                          {name.match(/\b(\w)/g).join("").toUpperCase()}
                        </h5>
                    </div>}
                  {!name && <img src={`${"./../assets/images/Mask.png"}`} alt="1" />} 
                </div>
                <div className="chat-pro-name align-self-center">
                  <h5 className="chat-name mb-0">{name}</h5>
                  <ul class="chat-find-media-sms-ul">
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ team_info: "test" });
                        }}
                      >
                        <img src="./../assets/images/find-search.svg" />
                        Find
                      </Link>
                    </li>
                    <li> 
                      <Link
                        to="#" 
                        onClick={() => this.mediaRef.click()}
                      >
                        <img src="./../assets/images/media-ico-chat.svg" />
                        Media
                      </Link>
                      <input
                        className="file-cont hide"
                        type="file"
                        ref={(input) => {
                          this.mediaRef = input;
                        }}
                        onChange={this.selectfileHandler} 
                      />
                    </li>
                    <li>
                      <Link to="#" onClick={()=>OpenSmsModal()}>
                        <img src="./../assets/images/mail-chat-ico.svg" />
                        SMS
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="chat-icons chat-icons-new-wind align-self-center">
                  <Link
                    to="#"
                    onClick={() => this.__AddToFavourite()}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={`./../assets/images/${
                        favourite ? "fav-green-star.svg" : "fav-greystar.svg"
                      }`}
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className=" mid-sec message-chat-height"
              ref={(input) => {
                this.messagesEnd = input;
              }}
              onDragOver={this.dragOver}
              onDragEnter={this.dragEnter}
              onDragLeave={this.dragLeave}
            >
              {chat instanceof Array &&
                chat.map((values, keys) => {
                  let time = values.date;
                  return (
                    <div
                      className={`w-100 float-${
                        myData.uniquecode === values.from ? "right" : "left"
                      }`}
                      key={`messages_${keys}`}
                    >
                      <div
                        className={`msg-inbox ${
                          values.type !== "text" ? "bubble-remove" : ""
                        } ${
                          myData.uniquecode === values.from ? "send" : "recive"
                        }`}
                        key={`messages_${keys}`}
                        onMouseEnter={(e) =>
                          length_chat === keys + 1 &&
                          myData.uniquecode !== values.from
                            ? this.__UpdateLastSeen("Mouse")
                            : e.preventDefault()
                        }
                      >
                        {values.type === "text" ? (
                          <div
                            className={`chats-text ${
                              myData.uniquecode === values.from
                                ? "bg-orang"
                                : ""
                            }`}
                          >
                            <p className="m-0 chat-font">{values.message}</p>
                          </div>
                        ) : values.type === "image" ? (
                          <img src={values.media} className="msg-img-video" />
                        ) : values.type === "video" ? (
                          <video
                            controls
                            src={`${values.media}`}
                            className="msg-img-video"
                          >
                            Your browser does not support the video tag.
                          </video>
                        ) : values.type === "audio" ? (
                          <audio controls className="audio-custom">
                            <source src={values.media} />
                            Your browser does not support the audio element.
                          </audio>
                        ) : values.type === "file" ? (
                          <>
                            {values.media.includes(".pdf") === true ? (
                              <a
                                href={values.media}
                                type="application/pdf"
                                download
                                target="_blank"
                              >
                                <i
                                  className="fa fa-file-pdf"
                                  style={{ fontSize: "48px" }}
                                ></i>
                              </a>
                            ) : values.media.includes(".zip") ? (
                              <a href={values.media}>
                                <i
                                  className="fa fa-file-archive"
                                  style={{ fontSize: "48px" }}
                                ></i>
                              </a>
                            ) : null}
                          </>
                        ) : values.type === "textfile" ? (
                          <a href={values.type} download target="_blank">
                            <i
                              className="fa fa-file-text-o"
                              style={{ fontSize: "48px" }}
                            ></i>
                          </a>
                        ) : null}
                        <div className="chat-time text-right">
                          <p
                            className={`text-black ${
                              myData.uniquecode === values.from
                                ? ""
                                : "float-left"
                            }`}
                            style={{ paddingRight: "30px" }}
                          >
                            {last_seen &&
                              keys + 1 === length_chat &&
                              last_seen.map((val, key) => {
                                if (this.extensions?.[val]) {
                                  let { profile, name } = this.extensions[val];
                                  return (
                                    <>
                                      <Link
                                        to="#"
                                        key={`seen_anchors${key}`}
                                        className="seen-anchor"
                                        data-tip
                                        data-for={`seen_anchors${key}`}
                                      >
                                        <img
                                          src={`${
                                            profile && profile !== ""
                                              ? profile
                                              : "./../assets/images/Mask.png"
                                          }`}
                                          alt="a"
                                        />
                                      </Link>
                                      <ReactTooltip
                                        id={`seen_anchors${key}`}
                                        type="error"
                                      >
                                        <span>{name}</span>
                                      </ReactTooltip>
                                    </>
                                  );
                                }
                              })}
                            <small className="chat-date-time">
                              {moment(time).locale("en").format("lll")}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {chat && chat.length === 0 && (
                <div className="start-meesage-div">
                  <img src="./../assets/images/start-chat-img-v.png" />
                  <h2>Start by saying Hi ✌️</h2>
                  <p>Start new conversation with an existing contact.</p>
                </div>
              )}
            </div>
          </div>
          <div className="form-group  msg-input new-wind-msg-input m-0">
            <div className="btn-file btn align-self-center">
              <i className="msg-paperclip">
                <img src="./../assets/images/message-attch-file.svg" />
              </i>
              <input
                className="file-cont"
                type="file"
                onChange={this.selectfileHandler}
              />
            </div>
            <input
              type="text"
              className="form-control chat-input"
              name="message"
              value={message}
              placeholder="Type something here..."
              onChange={(e) => this.setState({ message: e.target.value })}
              onKeyPress={(e) => this.onKeyPressHandler(e)}
              onDrop={this.fileDrop}
            />
            <Link to="#" className="paper-plane" onClick={this.handleSend}>
              <i className="msg-paper-plane">
                <img src="./../assets/images/message-send-new.svg" />
              </i>
            </Link>
            <ul class="message-action-new-ico">
              <li>
                <Link
                  to="#"
                  className="webphone-btns"
                  onClick={() => this.imageRef.click()}
                >
                  <img src="./../assets/images/chat-add-img-ico.svg" />
                </Link>
                <input
                  className="file-cont hide"
                  ref={(input) => {
                    this.imageRef = input;
                  }}
                  type="file"
                  onChange={this.selectfileHandler}
                  accept="image/*"
                />
              </li>
              <li>
                <Link
                  to="#"
                  className="webphone-btns"
                  onClick={() => this.gifRef.click()}
                >
                  <img src="./../assets/images/chat-file-gif-line.svg" />
                </Link>
                <input
                  className="file-cont hide"
                  type="file"
                  ref={(input) => {
                    this.gifRef = input;
                  }}
                  onChange={this.selectfileHandler}
                  accept="image/gif"
                />
              </li>
              <li>
                <Link
                  to="#"
                  className="webphone-btns"
                  onClick={() => this.fileRef.click()}
                >
                  <img src="./../assets/images/chat-file-attch.svg" />
                </Link>
                <input
                  className="file-cont hide"
                  type="file"
                  ref={(input) => {
                    this.fileRef = input;
                  }}
                  onChange={this.selectfileHandler}
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"
                />
              </li>
              <li>
                <Link to="#" className="webphone-btns">
                  <img src="./../assets/images/chat@ico.svg" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="webphone-btns"
                  onClick={() => this.setState({ showEmoji: !showEmoji })}
                >
                  <img src="./../assets/images/chat-smile-ico.svg" />
                </Link>
                {showEmoji && (
                  <div className="chat-page-emoji-div">
                    <Picker
                      onEmojiClick={this.onEmojiClick}
                      skinTone={SKIN_TONE_NEUTRAL}
                    />
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  onEmojiClick = (event, emojiObject) => {
    const { emoji } = emojiObject;
    const { message } = this.state;
    this.setState({ message: `${message}${emoji}` });
  };

  // drag drop function start
  dragOver = (e) => {
    e.preventDefault();
  };

  dragEnter = (e) => {
    e.preventDefault();
  };

  dragLeave = (e) => {
    e.preventDefault();
  };

  fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.setState(
      {
        selectedFile: files[0],
      },
      () => {
        this.onChooseFile();
      }
    );
  };
}

const mapStateToProps = (state) => {
  return {
    selected_group_chat_user: state?.call?.selected_group_chat_user,
    extension_listing: state?.setting?.extension_listing,
  };
};
export default connect(mapStateToProps)(GroupChat);
