import * as React from "react";
import { Route, Routes, useRoutes } from "react-router-dom";

import EmptyLayout from "../Component/Layout/EmptyLayout";
import PortalLayout from "../Component/Layout/PortalLayout";
import SignupLayout from "../Component/Layout/SignupLayout";
import Calls from "../Pages/Calls";

import Dashboard from "../Pages/Dashboard";
import Welcome from "../Pages/Dashboard/Welcome";
import Meet from "../Pages/Meet";
import Message from "../Pages/Message";
import PowerApps from "../Pages/PowerApps";
import Reports from "../Pages/Reports";
import Settings from "../Pages/Settings";
import CardSaveSuccess from "../Pages/Settings/Account/BillingPlan/CardSaveSuccess";
import ChoosePlan from "../Pages/Signup/ChoosePlan";
import CreateNumber from "../Pages/Signup/CreateNumber";
import FetchPayment from "../Pages/Signup/FetchPayment";
import LoginViaToken from "../Pages/Signup/LoginViaToken";
import OrderSummary from "../Pages/Signup/OrderSummary";
import Rica from "../Pages/Signup/Rica";
import Support from "../Pages/Support"; 
import AuthProvider from "./AuthProvider";

export const ROUTES = [
  {
    path: "/",
    element: (
      <AuthProvider>
        <PortalLayout />  
      </AuthProvider>
    ),
    children: [
      {
        path: "/",
        element: (
          <AuthProvider>
            <Dashboard />
          </AuthProvider>
        )
      },
      { 
        path: "dashboard",
        element: (
          <AuthProvider>
            <Dashboard />
          </AuthProvider>
        ),
      },
      {
        path: "call",
        element: (
          <AuthProvider>
            <Calls />
          </AuthProvider>
        ),
      },
      {
        path: "meet",
        element: (
          <AuthProvider>
            <Meet />
          </AuthProvider>
        ),
      },
      {
        path: "chat",
        element: (
          <AuthProvider>
            <Message />
          </AuthProvider>
        ),
      },
      {
        path: "reports",
        element: (
          <AuthProvider>
            <Reports />
          </AuthProvider>
        ),
      },
      {
        path: "power-apps",
        element: (
          <AuthProvider>
            <PowerApps />
          </AuthProvider>
        ),
      },
      {
        path: "support",
        element: (
          <AuthProvider>
            <Support />
          </AuthProvider>
        ),
      },
      {
        path: "settings",
        element: (
          <AuthProvider>
            <Settings />
          </AuthProvider>
        ),
      },
      {
        path: "rica-process",
        element: (
          <AuthProvider>
            <Rica />
          </AuthProvider>
        ),
      },
      {
        path: "porting-process",
        element: (
          <AuthProvider>
            <Rica />
          </AuthProvider>
        ),
      },
      { 
        path: "welcome",
        element: (
          <AuthProvider>
            <Welcome />
          </AuthProvider>
        ),
      }, 
    ],
  },

  {
    path: "/",
    element: (
      <SignupLayout />  
    ),
    children: [
      {
        path: "choose-number",
        element: (
          <AuthProvider>
            <CreateNumber />
          </AuthProvider>
        ),
      },
      {
        path: "choose-plan",
        element: (
          <AuthProvider>
            <ChoosePlan />
          </AuthProvider>
        ),
      },
      {
        path: "order-summary",
        element: (
          <AuthProvider>
            <OrderSummary />
          </AuthProvider>
        ),
      },
      {
        path: "fetch-payment/:token",
        element: (
          <FetchPayment /> 
        ),
      },
    ],
  },

  {
    path: "/",
    element: <EmptyLayout />,
    children: [
      {
        path: "login-via-token/:token",
        element: <LoginViaToken />,
      },
      {
        path: "add-card/:token",
        element: <CardSaveSuccess />,
      },
    ],
  },

  // { path: "*", element: <PageNotFound /> },
];

export const AppRouter = () => {
  let element = useRoutes(ROUTES);

  return element;
};
