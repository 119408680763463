import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TransferScreen from "../Design/TransferScreen";
import CallListing from "./CallListing";
import KeypadFrame from "./KeypadFrame";
import MultiIncomingPop from "./MultiIncomingPop";
import MultiTopDialerCallList from "./MultiTopDialerCallList";

const categories = {
  connected: "Active Call",
  ringing: "Incoming Call",
  hold: "On Hold Call",
};

const MultipleCalls = (props) => {
  const [categorizedReferences, setCategorizedReferences] = useState({});
  const [showCalls, setShowCalls] = useState(false);
  const {
    MuteCall,
    AnswerCall,
    reference,
    _activeCall,
    HangUpCall,
    speaker,
    SetActiveCall,
    keyPad,
    SpeakerOff,
    ShowKeyPad,
    Transfer, 
    HoldCall,
    CallRecording,
    transfer_type,
    TransferHandler,
    ConferenceHandler,
    conference,
    conference_type,
    PostConference,
    ConferenceCall,
    transfer,
    extension_listing,
    DTMF,
    PostTransfer,
    TransferCall,
    CallTransfer,
    BackHandler,
    activeSession,
    ShowKeyPadHandler, 
    number, 
  } = props;

  const transfer_sessions = useSelector(
    (state) => state?.call?.transfer_sessions ?? {}
  );

  let ActiveCalls =
    Object.values(reference).filter(
      (value) => value.uuid === _activeCall
    )?.[0] ?? {};

  const { uuid } = ActiveCalls;

  let inactiveCallStatus =
    Object.values(reference).filter(
      (value) => value.uuid !== _activeCall && value.status === "ringing"
    ) ?? [];

  useEffect(() => {
    const obj = {};
    Object.values(reference).forEach((item) => {
      if (item.status in obj) {
        obj[item.status].push(item);
      } else {
        obj[item.status] = [item];
      }
    });
    setCategorizedReferences(obj);
  }, [props]); // eslint-disable-line

  console.log("categorizedReferences=====>", categorizedReferences);

  const RenderNumberOfCalls = () => {
    return (
      <>
        {Object.entries(categorizedReferences).map((value, key) => {
          const icon =
            value[0] === "ringing"
              ? "incoming_call_icon"
              : value[0] === "hold"
              ? "hold_icon"
              : "active_call_icon";

          return (
            <a
              className={`${
                value[0] === "hold" ? "hold-user" : "active-green-user"
              } ${
                value[0] === "ringing" || value[0] === "hold"
                  ? "text-white"
                  : ""
              }`}
              key={key}
            >
              <img
                src={`./../assets/images/icons/multiple-calls/${icon}.png`}
                alt={value[1].length}
              />
              {value[1].length}
            </a>
          );
        })}
      </>
    );
  };

  return (
    <div className="col-md-12 mt-2">
      <div
        className={`dailer-key dailer-key-2 ${
          keyPad ? "" : "calling-pad"
        } m-auto ${
          inactiveCallStatus?.length > 0 ? "on-call-incoming-call" : ""
        }`}
      >
        <div className="incoming-call-body incoming-call-body-2 tranfer-call-body">
          <div className="caller-div caller-div-transfer">
            <div className=" calls-transfer-count">
              <div className="top-div-transfer">
                <span className="top-div-transfer-left webphone-btns">
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="mb-0">{`${
                      Object.values(reference)?.length
                    } Calls`}</h3>
                    <span className="top-div-transfer-right">
                      <Link
                        to="#"
                        className="transfer-end webphone-btns"
                        onClick={() => setShowCalls(true)}
                      >
                        <img
                          src="./../assets/images/icons/group-call-ico.png"
                          alt="calls"
                        />
                      </Link>

                      {showCalls && (
                        <Link
                          to="#"
                          className="transfer-min webphone-btns"
                          onClick={() => setShowCalls(false)}
                        >
                          <img
                            src="./../assets/images/icons/minimize.svg"
                            alt="min"
                          />
                        </Link>
                      )}
                      {(transfer || conference) && (
                        <Link
                          to="#"
                          className="transfer-min webphone-btns text-white"
                          onClick={() => BackHandler(false)}
                        >
                          <i class="fa fa-arrow-left"></i>
                        </Link>
                      )}
                    </span>
                  </div>
                  <h6>{RenderNumberOfCalls()}</h6>
                </span>
              </div>
            </div>
            {!showCalls && !transfer && !conference && (
              <div className="call-tranfer-list">
                <MultiTopDialerCallList
                  categorizedReferences={categorizedReferences}
                  categories={categories}
                  AnswerCall={AnswerCall}
                  HangUpCall={HangUpCall}
                  inactiveCallStatus={inactiveCallStatus}
                  SetActiveCall={SetActiveCall}
                  uuid={uuid}
                />
              </div>
            )}
          </div>
          {showCalls && (
            <CallListing
              categorizedReferences={categorizedReferences}
              categories={categories}
              AnswerCall={AnswerCall}
              SetActiveCall={SetActiveCall}
              HangUpCall={HangUpCall}
              HoldCall={HoldCall}
              uuid={uuid}
              inactiveCallStatus={inactiveCallStatus}
            />
          )}
          {inactiveCallStatus?.length === 0 &&
            !showCalls &&
            !transfer &&
            !conference && (
              <div
                className={`incoming-btn-div ${
                  transfer_sessions[uuid]
                    ? "transfer-now-btn"
                    : "transfer-btn-div"
                }`}
              >
                {keyPad && props.children}
                {!keyPad && (
                  <KeypadFrame 
                    DTMF={DTMF} 
                    transfer_type={transfer_type}
                    conference_type={conference_type}
                    speaker={speaker}
                    MuteCall={MuteCall}
                    SpeakerOff={SpeakerOff}
                    HangUpCall={HangUpCall}
                    ShowKeyPad={ShowKeyPad}
                    HoldCall={HoldCall}
                    TransferHandler={TransferHandler}
                    ConferenceHandler={ConferenceHandler} 
                    CallRecording={CallRecording}
                    activeSession={activeSession}
                    NotesClickHanlder={() =>
                      this.setState(
                        { notes: true, notes_list: true, showNote: "" },
                        () => {
                          this.NotesList();
                        }
                      )
                    }
                  />
                )}
              </div>
            )}

          {(transfer || conference) && !showCalls && (
            <TransferScreen
              list={extension_listing}
              DTMF={DTMF}
              PostTransfer={PostTransfer}
              PostConference={PostConference}
              TransferCall={TransferCall}
              ConferenceCall={ConferenceCall}
              CallTransfer={CallTransfer}
              activeSession={activeSession}
              renderFrom={"multiple_call_page"}
              transfer={transfer}
              conference={conference} 
              ShowKeyPadHandler={ShowKeyPadHandler}
              number={number}
            />
          )}

          {inactiveCallStatus?.length > 0 &&
            !transfer &&
            !conference &&
            !showCalls && (
              <MultiIncomingPop
                AnswerCall={AnswerCall}
                reference={reference}
                _activeCall={_activeCall}
                HangUpCall={HangUpCall}
                Transfer={Transfer}
                SetActiveCall={SetActiveCall}
                HoldCall={HoldCall}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default MultipleCalls;
