import React, { useEffect, useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import Select , {components} from "react-select";
import { connect, useSelector } from "react-redux";
import { MeetingsList, ScheduleMeeting, UpdateMeeting } from "../../Services/Actions/meetActions";
import authService from "../../Utils/authService";
import { store } from "../../Services";
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';  
import { TimezoneList } from "../../Services/Actions/settingActions";

const CustomInput = (props) => {
  const DefaultInput = components.Input
  return <DefaultInput {...props} placeholder={props?.selectProps?.placeholder} />
} 

function Schedule({ UpdateTopic, extension_listing, scheduleMeetingInfo,tab,timezone_list }) {
  const { accountno } = authService.getUserDetail();
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [duration, setDuration] = useState(15);
  const [title, setTitle] = useState("");
  const [scheduleUsing, setScheduleUsing] = useState("");
  const [participants, setParticipants] = useState([]);
  const [requirePassword, setRequirePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [joinAfterHost, setJoinAfterHost] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [screenShare, setScreenShare] = useState(false);
  const [authUsers, setAuthUsers] = useState(false);
  const [enableLobby, setEnableLobby] = useState(false);
  const [uniquekey, setUniquekey] = useState(""); 
  const [searchTerm, setSearchTerm] = useState("");  
  const [timezone, setTimezone] = useState(""); 
  const [meetingDuration,setMeetingDuration] = useState([]) 
  const [customDuration,setCustomDuration] = useState([]) 
  const [draft,setDraft] = useState(false)
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  ); 

  let video_calling_minutes = useSelector(
    (state) => state?.user?.user_details?.userDetail?.user_plan?.video_calling_minutes ?? 0
  ); 

  const MAX_DURATION_GAP = 15 
  let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; 
  

  useEffect(() => {
    UpdateTopic("schedule"); 
    TimezoneList(store.dispatch);
    getTimesInterval();  
    return function cleanup() {
      UpdateTopic("");
    }; 
  }, []);
 

  useEffect(async() => {
    if (scheduleMeetingInfo) {
      const {
        date,
        start_time,
        meeting_time,
        topic,
        schedule_using,
        participants,
        password,
        joinAfterHost, 
        authSharing,
        authorised,
        enableLobby,
        uniquekey, 
        media,
        timezone_offset,
        draft
      } = scheduleMeetingInfo; 
      let participants_array = [];
      participants &&
        participants.map((value) => {
          participants_array.push({
            label: value.name ? `${value.name}(${value.user_extension})` : value.email,
            value: value.email,
          });
        }); 
      setParticipants(participants_array);
      setDate(date);
      setTime(start_time); 
      setTitle(topic);
      setScheduleUsing(schedule_using);
      setRequirePassword(password ? true : false); 
      setJoinAfterHost(joinAfterHost === "Y" ? true : false);
      setAudioMuted(media && JSON.parse(media).audio_muted);
      setVideoMuted(media && JSON.parse(media).video_muted);
      setScreenShare(authSharing === "Y" ? true : false);
      setAuthUsers(authorised === "Y" ? true : false);
      setEnableLobby(enableLobby === "Y" ? true : false);
      setUniquekey(uniquekey);
      setTimezone(timezone_offset)
      setDraft(draft === "Y" ? true : false) 
 
      let response = await getTimesInterval();  
      if(response){ 
        if(response?.includes(meeting_time) === false){  
          setDuration("custom")
          setCustomDuration(meeting_time)
        } 
      } 
      window.$("#collapseExample").addClass("show")
    }
  }, [scheduleMeetingInfo]);

  const ScheduleMeetings = async () => {
    if (validator.current.allValid()) { 
      let members = [];
      participants &&
        participants.map(({ value }) => {
          members = [...members, value];
        });  
      let data = {
        date,
        start_time: time,
        end_time:  duration === "custom" ? customDuration : duration,
        participants: members,
        room_name: accountno,
        password:  requirePassword ? password : "",
        topic: title,
        schedule_using: scheduleUsing,
        joinAfterHost: joinAfterHost ? "Y" : "N",
        enableLobby: enableLobby ? "Y" : "N",
        authorised: authUsers ? "Y" : "N", 
        authSharing: screenShare ? "Y" : "N",
        timezone_offset : timezone, 
        media:JSON.stringify({"audio_muted": audioMuted,"video_muted":videoMuted}),
        draft:draft ? "Y" : "N"
      };

      let response;

      if (uniquekey) { 
        response = await UpdateMeeting({ ...data, uniquekey }, store.dispatch);
        if (response) {
          UpdateTopic("");
          MeetingsList({ type: tab }, store.dispatch);
          swal({
            title: "Success!",
            text: "Meeting Updated Successfully",
            icon: "success",
          });
        } else {
          UpdateTopic("");
        }
      } else {
        response = await ScheduleMeeting(data, store.dispatch);
        if (response === 1) {
          UpdateTopic("");
          MeetingsList({ type: tab }, store.dispatch);
          swal({
            title: "Success!",
            text: "Meeting Scheduled Successfully",
            icon: "success",
          });
        } else {
          UpdateTopic("");
        }
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  useEffect(()=>{
    if(draft){
      ScheduleMeetings();
    }
  },[draft])
  
  const SaveAsDraftHandler = ()=>{
    setDraft(true); 
  }

  const handleChange = (selectedOption) => {
    setParticipants(selectedOption);
  };

  const handleDelete = (emailToDelete) => () => { 
    setParticipants(participants.filter((response) => response.label !== emailToDelete.label));
  };

  const getTimesInterval = ()=>{  
    let meetingDuration = [] 
    video_calling_minutes = video_calling_minutes === "unlimited" ? 4*60 : video_calling_minutes
    for (let i = 1; i <= video_calling_minutes / MAX_DURATION_GAP; i++) { 
       meetingDuration.push(MAX_DURATION_GAP * i)
    }
    if (video_calling_minutes % MAX_DURATION_GAP > 0) { 
       meetingDuration.push(video_calling_minutes)
    }  
    if(meetingDuration){  
      setMeetingDuration([...meetingDuration])
    }  

    return meetingDuration
  }  
  
   
  return (
    <>
      <div className="modal-body schedule-modal-body">
        <div className="col-md-12">
          <div className="meeting-modal-heading">
            <h2>Schedule Online Meeting</h2>
          </div>
        </div>
        <form>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group form-group-title">
                <label className="col-form-label">
                  Meeting Title
                  <img
                    onClick={() => setTitle("")}
                    src="./../assets/images/clear-title.svg"
                    alt="1"
                  />
                </label>
                <input
                  type="text"
                  className="form-control form-control-bottom"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Meeting Title"
                />
                {validator.current.message("Meeting Title", title, "required")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label className="col-form-label">Date</label>
                <input
                  type="date"
                  className="form-control form-control-bottom"
                  name="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
                {validator.current.message("Date", date, "required")}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="col-form-label">Time</label>
                <input
                  type="time"
                  className="form-control form-control-bottom"
                  name="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
                {validator.current.message("Time", time, "required")}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="col-form-label meeting-custom-duration">
                  Duration
                  {duration === "custom" && 
                    <img 
                      src="./../assets/images/clear-title.svg" 
                      alt="1" 
                      onClick={()=>setDuration("")}
                    />
                  } 
                </label>
                {duration !== "custom" && <select
                  className="form-control form-control-bottom"
                  name="duration"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)} 
                >   
                  <option value="custom">Custom Duration</option>
                  {meetingDuration && meetingDuration.map((value) => { 
                      return (
                        <option key={value} value={value}>
                          {value} mins
                        </option>
                      );
                    }  
                  )}
                </select> 
                }
                {duration === "custom" && 
                  <input
                    type="number"
                    className="form-control form-control-bottom" 
                    name="customDuration"
                    value={customDuration}
                    placeHolder="Custom Duration(in mins)"
                    onChange={(e) => setCustomDuration(e.target.value)}
                    autoComplete="off"
                  />
                }
                 {validator.current.message("Duration", duration, "required")}
              </div>
            </div>
          </div> 
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">Timezone</label>
                <select
                  className="form-control form-control-bottom"
                  name="timezone"
                  value={timezone}
                  onChange={(e) => setTimezone(e.target.value)}
                > 
                  <option value="">Select Timezone</option>
                  {timezone_list instanceof Array && timezone_list.map((value) => {
                    return (
                      <option key={value.timezoneID} value={value.gmtoffset}>
                        {`${value.gmtzone} (${value.gmtoffset})`}
                      </option>
                    );
                  })}
                </select>
                 {validator.current.message("TimeZone", timezone, "required")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">Participants</label>
                <Select
                  isMulti
                  isSearchable
                  isClearable
                  name="participants" 
                  value={participants}
                  placeholder="Add team member or enter email manually"
                  selectProps={{
                    count: participants?.length,
                  }}
                  inputId="selectInput"
                  inputValue={searchTerm}
                  onInputChange={setSearchTerm}
                  components={{
                    MultiValue: () => null,
                    Input: CustomInput,
                    Placeholder: () => null, 
                    NoOptionsMessage: ({ children, ...rest }) => {
                      const DefaultNoOptionsMessage = components.NoOptionsMessage
        
                      return (
                        <DefaultNoOptionsMessage {...rest}>
                          <span
                            className="no-contacts" 
                            onClick={() => {  
                                if (searchTerm.match(validRegex)) {  
                                  setParticipants((prev) => [...prev, { label: searchTerm, value: searchTerm }])
                                  const input = document.getElementById("selectInput")
                                  input.blur()  
                                }   
                              }
                            }
                          >  
                            {searchTerm.match(validRegex) && 
                              <div className="participant-email">
                              {searchTerm} <small>Click here to add email</small>
                              </div> 
                            }
                          </span>
                        </DefaultNoOptionsMessage> 
                      )
                    },
                  }}
                  onChange={handleChange} 
                  options={
                    extension_listing &&
                    extension_listing.map((value) => ({
                      label: `${value.name}(${value.user_extension})`,
                      value: value.email,
                    }))
                  }
                />
              </div>
              <Paper component="ul" className="email-input-ul">
                  {participants && participants.map((data,key) => {
                      let icon;
                      return (
                          <li key={key}>
                              <Chip icon={icon} label={data.label} onDelete={handleDelete(data)} className={"chip-org"} />
                          </li>
                      );
                  })} 
              </Paper>
            </div>
          </div>
          {/* settings */}
          <div className="meeting-setting-div">
            <h4>Meeting settings</h4>
            <div className="meeting-setting-div-inner">
              <div className="meeting-setting-left">
                <label className="col-form-label">Require password</label>
              </div>
              <div className="meeting-setting-right">
                {requirePassword && (
                  <input
                    type="text"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                )}
                <div className="form-check">
                  <input
                    className="form-check-input position-static"
                    type="checkbox"
                    value={requirePassword ? "checked" : ""}
                    name="requirePassword"
                    checked={requirePassword ? "checked" : ""}
                    onChange={() => setRequirePassword(!requirePassword)}
                  />
                </div>
              </div>
            </div>
            <div className="meeting-setting-div-inner">
              <div className="meeting-setting-left">
                <label className="col-form-label">
                  Participants can only join after me
                </label>
              </div>
              <div className="meeting-setting-right">
                <div className="form-check">
                  <input
                    className="form-check-input position-static"
                    type="checkbox"
                    value={joinAfterHost ? "checked" : ""}
                    name="joinAfterHost"
                    checked={joinAfterHost ? "checked" : ""}
                    onChange={() => setJoinAfterHost(!joinAfterHost)}
                  />
                </div>
              </div>
            </div> 
          </div>
          {/* settings */}
          <div className="meeting-setting-div meeting-setting-div-2">
            <h4>
              Enable waiting room
              <div className="form-check">
                <input
                  className="form-check-input position-static"
                  type="checkbox"
                  value={enableLobby ? "checked" : ""}
                  name="enableLobby"
                  checked={enableLobby ? "checked" : ""}
                  onChange={() => setEnableLobby(!enableLobby)}
                />
              </div>
            </h4>
          </div>
          {/* advanced settings */}
          <div className="Advanced-settings">
            <a
              className="collapsed advanced-setting-link"
              data-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Advanced settings<i className="fas fa-caret-down ml-2"></i>
            </a>
            <div className="collapse" id="collapseExample">
              <div className="card card-body">
                <div className="meeting-setting-div meeting-setting-div-2">
                  <div className="meeting-setting-div-inner">
                    <div className="meeting-setting-left">
                      <label className="col-form-label">
                        Only authenticated users can join
                      </label>
                    </div>
                    <div className="meeting-setting-right">
                      <div className="form-check">
                        <input
                          className="form-check-input position-static"
                          type="checkbox"
                          value={authUsers ? "checked" : ""}
                          name="authUsers"
                          checked={authUsers ? "checked" : ""}
                          onChange={() => setAuthUsers(!authUsers)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="meeting-setting-div-inner">
                    <div className="meeting-setting-left">
                      <label className="col-form-label">
                        Turn off camera for participants
                      </label>
                    </div>
                    <div className="meeting-setting-right">
                      <div className="form-check">
                        <input
                          className="form-check-input position-static"
                          type="checkbox"
                          value={videoMuted ? "checked" : ""}
                          name="videoMuted"
                          checked={videoMuted ? "checked" : ""}
                          onChange={() => setVideoMuted(!videoMuted)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="meeting-setting-div-inner">
                    <div className="meeting-setting-left">
                      <label className="col-form-label">
                        Mute audio for participants
                      </label>
                    </div>
                    <div className="meeting-setting-right">
                      <div className="form-check">
                        <input
                          className="form-check-input position-static"
                          type="checkbox"
                          value={audioMuted ? "checked" : ""}
                          name="audioMuted"
                          checked={audioMuted ? "checked" : ""}
                          onChange={() => setAudioMuted(!audioMuted)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="meeting-setting-div-inner">
                    <div className="meeting-setting-left">
                      <label className="col-form-label">
                        Only host & moderators can share screen
                      </label>
                    </div>
                    <div className="meeting-setting-right">
                      <div className="form-check">
                        <input
                          className="form-check-input position-static"
                          type="checkbox"
                          value={screenShare ? "checked" : ""}
                          name="screenShare"
                          checked={screenShare ? "checked" : ""}
                          onChange={() => setScreenShare(!screenShare)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer meeting-modal-footer">
        <button
          type="button"
          className="btn btn-blue-text"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-meet-join"
          onClick={SaveAsDraftHandler}
        >
          Save as Draft
        </button>
        <button
          type="button"
          className="btn btn-meet-join"
          onClick={ScheduleMeetings}
        >
          Schedule
        </button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  timezone_list: state?.setting?.timezone_list ?? [],
});

export default connect(mapStateToProps)(Schedule);
