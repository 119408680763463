import React, { useEffect, useState } from "react"; 
import { connect } from "react-redux";
import Pagination from "../../Component/Pagination";
import { store } from "../../Services";
import {
  SelectedGroupChatUser,
  TeamListing,
} from "../../Services/Actions/callActions"; 

function TeamList(props) {
  const { team_listing } = props;
  const [search_call, setSearchCall] = useState("");
  const [pageOfItems, setPageItems] = useState([]);
  useEffect(() => {
    TeamListing(0, store.dispatch);
  }, []);
  const UpdateData = (value) => {
    setPageItems(value);
  };
  useEffect(() => {
    if (search_call !== "") {
      var filtered = setPageItems.filter((el) => el.name.includes(search_call));
      setPageItems(filtered);
    }
  }, [search_call]);

  const handleTeamClick = (info) => {
    let {
      ActiveTab,
      user_details: {
        firstname,
        lastname,
        user_extension,
        phone,
        profile_picture: profile,
        uniquecode,
      },
    } = props;
    let { name, participants, uniquekey } = info;
    let participant = {
      [uniquecode]: {
        name: `${firstname} ${lastname}`,
        extension: user_extension,
        phone,
        profile,
        uniquecode,
      },
    };
    participants.map((value) => {
      participant = {
        ...participant,
        [value.uniquecode]: {
          name: value.name,
          phone: value.phone,
          extension: value.user_extension,
          profile: value.profile_picture ?? "",
          uniquecode: value.uniquecode,
        },
      };
    });
    let data = { participants: participant, name, uniquekey, profile: "" };
    SelectedGroupChatUser(data, store.dispatch);
    ActiveTab("group_chat");
  };

  return (
    <div className="col-md-8 col-lg-9">
      <div className="right-side-top-header">
        <h5>Teams</h5>
        <span className="filter-serach">
          <img src="./../assets/images/filter.svg" alt="1" />
          <input
            type="text"
            className="form-control"
            placeholder="Filter call history"
            name="search_call"
            value={search_call}
            onChange={(e) => setSearchCall(e.target.value)}
          />
        </span>
      </div>
      <div className="tab-content">
        <div className="tab-pane call-info-scroll single-table-height show active">
          <div className="table-responsive calls-table calls-table-hover">
            <table className="table table-hover table-center mb-0">
              <tbody>
                {pageOfItems &&
                  pageOfItems.map((value, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <div className="d-flex all-message-email-row">
                            <div className="text-center align-self-center caller-img nxa-contact">
                              <h6>
                                {value.name
                                  .match(/\b(\w)/g)
                                  .join("")
                                  .toUpperCase()}
                              </h6>
                              {value.profile_picture && (
                                <img src={value.profile_picture} alt="a" />
                              )}
                            </div>
                            <div className="align-self-center caller-name-number">
                              <h3>{value.name}</h3>
                            </div>
                          </div>
                        </td>
                        <td align="right">
                          <div className="recentcall-img-ico allcall-img-ico">
                            <a
                              href={void 0}
                              onClick={() => handleTeamClick(value)}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src="./../assets/images/call-chat-ico.svg"
                                alt="1"
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                {pageOfItems && pageOfItems.length === 0 && (
                  <tr>
                    <td colSpan="2" className="text-center">
                      No team contacts found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination data={team_listing} currentData={UpdateData} />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    team_listing: state?.call?.team_listing??[],
    user_details: state?.user?.user_details?.userDetail ?? {},
  };
};
export default connect(mapStateToProps)(TeamList);
