import React, { Component } from "react";
import { connect } from "react-redux";
import { store } from "../../Services";
import { FetchUserDetails } from "../../Services/Actions/userActions";

const initialState = {};

class CloudStorage extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async () => {
    FetchUserDetails(store.dispatch) 
  };

  render() {
    const {
      SetTab,
      user_details: { total_storage_size = "1tb", storage_used = "" } = {},
    } = this.props;
    let total_Storage_in_bytes =
      parseInt(total_storage_size) * 1024 * 1024 * 1024 * 1024;
    let storage_percentage = parseInt(
      (storage_used / total_Storage_in_bytes) * 100
    ).toFixed(2);

    return (
      <div className="card discover-inner">
        <div className="card-body pl-0 pr-0">
          <div className=" cloud-recording-mian  mb-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 p-0">
                  <div className="powerapp-inner">
                    <div className="cloud-recording">
                      <h5 className="font-24 color3B">
                        <img
                          src="./../assets/images/cloud/carbon_recording.svg"
                          alt="1"
                          className="mr-3"
                        />
                        Cloud Storage
                      </h5>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${storage_percentage}%` }}
                        ></div>
                      </div>
                      <span className="font-13 color7f pt-1">
                        {`${storage_percentage}% of ${total_storage_size.toUpperCase()} used`}
                      </span>
                    </div>
                    <div className="row pt-4">
                      <div
                        className="col-md-4"
                        style={{ cursor: "pointer" }}
                        onClick={() => SetTab("call_recording")}
                      >
                        <div className="powerapp-box p-4 bg-yellow  text-center">
                          <img
                            src="./../assets/images/cloud/carbon_recording.svg"
                            alt="1"
                          />
                          <h6 className="font-16 color3c">
                            Call <br></br>Recording
                          </h6>
                        </div>
                      </div>
                      <div
                        className="col-md-4"
                        style={{ cursor: "pointer" }}
                        onClick={() => SetTab("video_recording")}
                      >
                        <div className="powerapp-box p-4 bg-yellow  text-center">
                          <img
                            src="./../assets/images/cloud/ant-design_video-camera-outlined.svg"
                            alt="1"
                          />
                          <h6 className="font-16 color3c">
                            Video Meeting <br></br>Recording
                          </h6>
                        </div>
                      </div>
                      <div
                        className="col-md-4"
                        style={{ cursor: "pointer" }}
                        onClick={() => SetTab("documents")}
                      >
                        <div className="powerapp-box p-4 bg-yellow  text-center">
                          <img
                            src="./../assets/images/cloud/entypo_documents.svg"
                            alt="1"
                          />
                          <h6 className="font-16 color3c">Documents</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user_details: state?.user?.user_details?.userDetail ?? {},
});

export default connect(mapStateToProps)(CloudStorage);
