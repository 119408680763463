import { apiAdminUrl, apiBaseUrl } from "../../Utils/Common";
import * as constants from "../Constants/reportConstants"; 
import authService from "../../Utils/authService";
import { handleResponse } from "./userActions";
import { SHOW_LOADER } from "../Constants/userConstants";


// ******************************************** REPORT ACTIONS ************************************************************

export const DidNumberListing = (dispatch, type=null) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/did-number-listing`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
    body: JSON.stringify({type})
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") { 
        if(type === "phoneSettings"){
          dispatch({
            type: constants.FETCH_DID_NUMBER_LISTING,
            payload: data.data,
          });
        }else{
          dispatch({
            type: constants.FETCH_UNASSIGNED_DID_NUMBER_LISTING,
            payload: data.data,
          });
        }
        
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const CallReport = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/call-report`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.CALL_REPORT,
          payload: data,
        });
        return 1;
      }
      return 0;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};
