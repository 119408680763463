import React, { useEffect, useState, useRef } from "react";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { store } from "../../../Services";
import {
  DeleteMeeting,
  MeetingsList,
} from "../../../Services/Actions/meetActions";
import Pagination from "../../../Component/Pagination";
import { connect } from "react-redux";
import swal from "sweetalert";
import {
  capitalizeFirstLetter,
  convert24hrsTO12hrs,
  dateDiff,
  MeetDomain,
  removeBackdrop,
} from "../../../Utils/Common";
import ParticipantsModal from "./ParticipantsModal";
import InviteMemberModal from "./InviteMemberModal";
import MeetingInfoModal from "./MeetingInfoModal";
import RenderProp from "../../../Component/Modal/RenderProp";
import Schedule from "../../../Component/Modal/Schedule";
import { Link } from "react-router-dom";
import Tooltips from "../../../Component/Tooltips"; 

function UpcomingMeetings(props) {
  const [inviteMembers, setInviteMembers] = useState(false);
  const [viewparticipants, setViewParticipants] = useState(false);
  const [participantsData, setParticipantsData] = useState([]);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState([]);
  const [openScheduleModal, setOpenScheduleModal] = useState([]);
  const [scheduleMeetingInfo, setScheduleMeetingInfo] = useState([]);
  const [uniquekey, setUniquekey] = useState(null);
  const [tab, settab] = useState("upcoming");
  const [pageOfItems, setPageOfItems] = useState([]);
  const [searchText, setSearchText] = useState(""); 
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [modal, setModal] = useState("");

  const login_via_token = localStorage.getItem("login-via-token");
  let intervalID = 0;
  const debounced = useRef(
    debounce(async (searchText, tab) => {
      handleMeetingSearch(searchText, tab);
    }, 300)
  );

  const reduceDateToString = (unformated) => {
    const date = unformated ? unformated.toLocaleString().split(",")[0] : "";
    return moment(date).format("YYYY-MM-DD");
  };  

  useEffect(() => {
    debounced.current(searchText, tab);
  }, [searchText, tab]);

  const handleMeetingSearch = (text, tab) => {
    if (text) {
      MeetingsList({ type: tab, topic: text }, store.dispatch);
    } else {
      MeetingsList({ type: tab }, store.dispatch);
    }
  };

  const DeleteMeet = (uniquekey, type = "") => {
    swal({
      title: `${
        type === "past"
          ? "Are you sure want to send this meeting in archived ?"
          : "Are you sure want to delete this meeting?"
      }`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (Cancel) => {
      if (Cancel) {
        let response = await DeleteMeeting({ uniquekey }, store.dispatch);
        if (response === 1) {
          MeetingsList({ type: tab }, store.dispatch)
          swal(
            `${
              type === "past"
                ? "Your Meeting successfully sent in archived!"
                : "Your meeting is deleted successfully!"
            }`,
            {
              icon: "success",
            }
          );
        }
      } else {
        console.log("Your record is safe!");
      }
    });
  };

  const InitialModal = () => {
    setInviteMembers(false);
    setUniquekey("");
    removeBackdrop();
  };

  const DateFormat = (value, time) => {
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(value);
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear(); 
    return `${monthNames[month]} ${day}, ${year} ${convert24hrsTO12hrs(time)}`;
  }; 
   

  const UpdateData = (value) => {
    setPageOfItems(value);
  };

  const InviteMembersHandler = (value) => {
    setModal("invite");
    setInviteMembers(true);
    setUniquekey(value.uniquekey);
    setParticipantsData(value.participants);
  };

  const ViewParticipantsHandler = (value) => {
    setModal("view-participants");
    setViewParticipants(true);
    setParticipantsData(value);
  };

  const MeetingInfohandler = (value) => {
    setModal("meeting-info");
    setOpenInfoModal(true);
    setMeetingInfo(value);
  };

  const EditScheduleMeeting = (value) => { 
    setModal(" ");
    setModal("schedule_meet");
    setOpenScheduleModal(true);
    if(value){
      setScheduleMeetingInfo(value);
    }else{
      setScheduleMeetingInfo({});
    }
    
  };

  const closeParticipantsModal = () => {
    setViewParticipants(false);
    setParticipantsData([]);
  };

  const closeMeetingInfo = () => {
    setOpenInfoModal(false);
    setMeetingInfo([]);
  }; 
  
  const { direct, meetings_list,extension_listing } = props;
  return (
    <>
      <div className={direct ? "" : `col-md-8 col-lg-9`}>
        <div className="row">
          <div className="col-sm-12">
            <div className="appointment-tab">
              <div className="tab-content">
                <div className="tab-pane show active">
                  <div className="right-side-top-header">
                    <span className="meeting-serach filter-serach">
                      <img src="./../assets/images/filter.svg" alt="1" />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by Meeting Title"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </span>
                    <span className="filter-serach">
                      <img src="./../assets/images/filter.svg" alt="1" />
                      <DatePicker
                        placeholderText="Filter by Date Range"
                        className="form-control"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setDateRange(update);
                        }}
                        isClearable={true}
                      />
                      {dateRange?.[0] !== null && (
                        <img
                          src="./../assets/images/search-dash.svg"
                          className="search-date-ico"
                          alt="1"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            MeetingsList(
                              {
                                type: tab,
                                from: startDate
                                  ? reduceDateToString(startDate)
                                  : "",
                                to: endDate ? reduceDateToString(endDate) : "",
                              },
                              store.dispatch
                            )
                          }
                        />
                      )}
                    </span>
                  </div>
                  <div className="meeting-page-tabs">
                    <h3>Meetings</h3>
                    <div className="meeting-page-tabs-inner">
                      <div className="schedule-meeting-btn">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {["upcoming", "past", "archived"].map((value) => {
                          return (
                            <li
                              key={value}
                              className="nav-item"
                              role="presentation"
                            >
                              <a
                                className={`nav-link ${
                                  tab === value ? "active" : ""
                                }`}
                                onClick={() => settab(value)}
                                href={void 0}
                              >
                                {capitalizeFirstLetter(value)}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                      <a  
                        className="btn btn-orange"  
                        onClick={() =>
                          EditScheduleMeeting("")
                        }
                      >
                        Schedule Meeting
                      </a>
                      </div>
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-pane show  d-block">
                          <div className="table-responsive call-info-scroll upcoming-meeting-table-scroll">
                            <table className="table table-hover table-center mb-0">
                              <tbody>
                                {pageOfItems.map((value, key) => { 
                                  let diff = Math.trunc(
                                    dateDiff(
                                      `${value.date} ${value.start_time}`,
                                      new Date()
                                    ) / 60
                                  );  
                                  let meeting_date =  `${value.date} ${value.start_time}`
                                  return (
                                    <tr className={`upcoming-meeting-table ${value.draft === "Y" ? "draft" : ""}`}  key={`listing_${key}`}>
                                      <td className="meeting-name-div">
                                        {value.topic}
                                        <span>
                                          {DateFormat(
                                            value.date,
                                            value.start_time
                                          )}
                                        </span>
                                      </td>
                                      <td className="ready-draft-btn-td">  
                                          {value.draft === "Y" ? 
                                            <div className="dropdown">
                                                <a
                                                  className="btn btn-ready dropdown-toggle"
                                                  type="button"
                                                  id="dropdownMenu2"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  Draft
                                                </a>
                                            </div> :  
                                            <div className="dropdown">
                                              <a
                                                className="btn btn-ready dropdown-toggle"
                                                type="button"
                                                id="dropdownMenu2"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                Ready
                                              </a>
                                          </div> 
                                        } 
                                      </td>
                                      <td className="meeting-participants-td text-center">
                                      {value?.participants && value?.participants?.length === 0 && `No Participants`}
                                      {value?.participants instanceof Array &&
                                        value?.participants?.map((data, keys) => {
                                          const profile_image = extension_listing?.find(val=>val.customerID === data.customerID)?.profile_picture??""
                                          let colors =
                                            keys % 3 === 0
                                              ? "orange"
                                              : keys % 3 === 1
                                              ? "blue"
                                              : "pink";
                                          let name = data?.name  ? data?.name : "Guest User"
                                          return (
                                            <>
                                              {profile_image && keys <= 2 && (
                                                <Tooltips
                                                  placement="top"
                                                  heading={name}
                                                >
                                                  <span className={`assign-to-span`} key={keys}>
                                                    <img
                                                      src={ profile_image
                                                          ? profile_image
                                                          : "./../assets/images/Mask.png"
                                                      }
                                                      alt="1"
                                                    />
                                                  </span>
                                                </Tooltips>
                                              )}
                                              {!profile_image && keys <= 2 && (
                                                <Tooltips
                                                  placement="top"
                                                  heading={name}
                                                >
                                                  <span
                                                    key={keys}
                                                    className={`assign-to-span assign-to-color-${colors}`}
                                                  >
                                                    <h5>
                                                      {name
                                                        ?.match(/\b(\w)/g)
                                                        ?.join("")
                                                        ?.toUpperCase() }
                                                    </h5>
                                                  </span>
                                                </Tooltips>
                                              )}

                                              {keys === 3 && (
                                                <Tooltips
                                                  placement="top"
                                                  heading={`+${
                                                    value?.participants?.length - 3
                                                  } more`}       
                                                >
                                                  <span className="assign-to-span assign-to-color-orange" key={keys}>
                                                    <h5>{`+${ value?.participants?.length - 3}`}  </h5>
                                                  </span>
                                                </Tooltips>
                                              )} 
                                            </>
                                          );
                                        })}
                                        {value?.participants?.length > 0 && <p 
                                          className="mb-0 text-center" 
                                          onClick={() =>
                                            ViewParticipantsHandler(
                                              value.participants
                                            )
                                          }
                                          style={{cursor:"pointer"}}
                                        >
                                          {value?.participants?.length}{" "}
                                          Participants
                                        </p>} 
                                      </td>
                                      <td className="start-meeting-td text-center" style={{maxWidth:"170px",minWidth:"170px"}}>
                                        {tab === "upcoming" && 
                                          <>
                                             {diff <= 30 &&
                                              diff >= -value.meeting_time && (
                                                <a
                                                  href={`https://${MeetDomain}/${value.room_name}?token=${login_via_token}&uniquekey=${value.uniquekey}`}
                                                  className="btn btn-orange"
                                                  target="_blank"
                                                >
                                                  Start Meeting
                                                </a>
                                              )}  
                                          </>
                                        }  
                                      </td>
                                      <td className="ellipsis-menu-td"> 
                                        <div className="menu-ellipsis number-collase-action">
                                          <a
                                            href={`#${key}`}
                                            data-toggle="collapse"
                                            aria-expanded="false"
                                            className="dropdown-toggle collapsed"
                                          >
                                            <i className="fas fa-ellipsis-h"></i>
                                          </a>
                                          <ul
                                            className="list-unstyled number-dr-collapse collapse"
                                            id={key}
                                          >
                                            <li>
                                              <Link
                                                to="#"
                                                className="dropdown-item"
                                                onClick={() =>
                                                  MeetingInfohandler(value)
                                                }
                                              >
                                                <img
                                                  src="./../assets/images/meeting-info-ico.svg"
                                                  alt="..."
                                                />
                                                Meeting Info  
                                              </Link>
                                            </li>
                                            {tab === "upcoming" && ( 
                                              <>
                                                <li>
                                                  <Link
                                                    to="#"
                                                    className="dropdown-item"
                                                    onClick={() =>
                                                      EditScheduleMeeting(value)
                                                    }
                                                  >
                                                    <img
                                                      src="./../assets/images/meeting-edit-ico.svg"
                                                      alt="..."
                                                    />
                                                    Edit Meeting
                                                  </Link>
                                                </li>  
                                                <li>
                                                  <Link
                                                    className="dropdown-item"
                                                    to="#"
                                                    onClick={() =>
                                                      InviteMembersHandler(value)
                                                    }
                                                  >
                                                    <img
                                                      src="./../assets/images/invite-meeting-ico.svg"
                                                      alt="..."
                                                    />
                                                    Invite Members
                                                  </Link>
                                                </li>
                                              </>
                                            )}  
                                            {tab === "past" && (
                                              <>
                                                <li>
                                                <Link
                                                  to="#"
                                                  className="dropdown-item"  
                                                >
                                                  <img
                                                    src="./../assets/images/download-action-ico.svg"
                                                    alt="..."
                                                  />
                                                    Download Meeting
                                                </Link>
                                              </li> 
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="#"
                                                  onClick={() =>
                                                    DeleteMeet(
                                                      value.uniquekey,
                                                      "past"
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src="./../assets/images/number-drop-trash-2.svg"
                                                    alt="1"
                                                  />
                                                  Archive Meeting
                                                </Link>
                                              </li>
                                            </>
                                          )}
                                          {tab !== "past" && <li>
                                            <Link
                                              to="#"
                                              className="dropdown-item"
                                              onClick={() =>
                                                DeleteMeet(value.uniquekey)
                                              }
                                            >
                                              <img
                                                src="./../assets/images/sdfas.svg"
                                                alt="1"
                                              />
                                              Delete Meeting
                                            </Link>
                                          </li>} 
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                                {pageOfItems && pageOfItems.length === 0 && (
                                  <tr>
                                    <td className="text-center">
                                      {`No ${tab} meetings are found.`}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Pagination data={meetings_list} currentData={UpdateData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal === "invite" && inviteMembers && (
        <InviteMemberModal
          inviteMembers={inviteMembers}
          InitialModal={InitialModal}
          uniquekey={uniquekey}
          participantsData={participantsData}
          tab={tab}
        />
      )}
      {modal === "view-participants" && viewparticipants && (
        <ParticipantsModal
          openModal={viewparticipants}
          participantsData={participantsData}
          onClose={closeParticipantsModal}
        />
      )}
      {modal === "meeting-info" && openInfoModal && (
        <MeetingInfoModal
          openInfoModal={openInfoModal}
          meetingInfo={meetingInfo}
          onInfoClose={closeMeetingInfo}
        />
      )}
      {modal === "schedule_meet" && openScheduleModal && (
        <RenderProp>
          {({ UpdateTopic, extension_listing }) => (
            <Schedule
              UpdateTopic={UpdateTopic}
              extension_listing={extension_listing}
              scheduleMeetingInfo={scheduleMeetingInfo}
              tab={tab}
            />
          )}
        </RenderProp>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  const { meet: { meetings_list, meetings_count } = {} , setting:{extension_listing} = {}} = state; 
  return {
    meetings_list,
    meetings_count,
    extension_listing
  };
};

export default connect(mapStateToProps)(UpcomingMeetings);
