import React, { Component } from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { store } from "../../../Services";
import { UploadFiles } from "../../../Services/Actions/callActions";
import {
  UpdateUserDetails,
  UploadRicaDocument,
} from "../../../Services/Actions/signupActions";
import { dataType64toFile } from "../../../Utils/Common";
import { withRouter } from "../../../Utils/withRouter";

class IdVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageType: "id_process",
      clicked: false,
      selfie: "",
      profile_picture: "",
      uploadDocument: "",
      uploadedDoc: false,
      isDocUpload: false,
      selectedFile: "",
      mediaType: "",
      mediaSrc: "",
      media: "",
      reupload: false,
      docMediaType: "",
      isLoading: false,
      timer: 30, // secs
    };
  }

  componentDidMount() {
    const {
      user_details: { userDetail },
    } = this.props;
    if (userDetail?.ricaProcess?.selfie) {
      const {
        ricaProcess: { selfie },
      } = userDetail;
      this.setState({ selfie });
    }
  }

  Retry = () => {
    this.setState({ clicked: false, profile_picture: "" });
  };

  handleTakePhoto = (dataUri) => {
    this.setState({ profile_picture: dataUri, clicked: true });
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  
  UploadSelfieWithID = async () => {
    const { profile_picture } = this.state;
    const wrapper = document.createElement("div");
    if(profile_picture.includes("image/")){
      const imageFile = dataType64toFile(profile_picture) 
      let response = await UploadFiles(imageFile,"document",store.dispatch) 
      if (response.code === 1) { 
        let user_res = await UpdateUserDetails({selfie: response.file_path, action: "rica" },store.dispatch);
        if (user_res.code === 1) {
          this.setState({ clicked: false, isDocUpload: true }, () => {
            wrapper.innerHTML = `<p> Your selfie with valid ID has been submitted successfully. Now upload ID document/valid passport. </p>`;
            swal({
              icon: "success",
              title: "Successfully Uploaded",
              content: wrapper,
              className: "add-subscription",
            });
          });
        } 
      } 
    }else {
      wrapper.innerHTML = `<p>Please take first selfie with valid ID.</p>`;
      swal({
        icon: "error",
        title: "Error",
        content: wrapper,
        className: "add-subscription",
      });
    }  
  };

  selectFileHandler = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const media = URL.createObjectURL(file);
      this.setState({ selectedFile: file, media }, () => {
        this.onChooseFile();
      });
    }
  };

  onChooseFile = () => {
    const { selectedFile } = this.state;
    if (selectedFile) {
      const prefixFiletype = selectedFile.type.toString();
      if (prefixFiletype.indexOf("image/") === 0) {
        this.setState({ mediaType: "image" }, () => {
          this.UploadDocument();
        });
      } else if (prefixFiletype.indexOf("application/pdf") === 0) {
        this.setState({ mediaType: "pdfFile" }, () => {
          this.UploadDocument();
        });
      } else if (prefixFiletype.indexOf("application/zip") === 0) {
        this.setState({ mediaType: "zipFile" }, () => {
          this.UploadDocument();
        });
      } else if (prefixFiletype.indexOf("text/") === 0) {
        this.setState({ mediaType: "textfile" }, () => {
          this.UploadDocument();
        });
      }
    }
  };

  UploadDocument = () => {
    const wrapper = document.createElement("div");
    const { selectedFile, media, mediaType } = this.state; 
    this.intervalID = setInterval(() => {
      this.setState((prevstate) => ({ timer: prevstate.timer - 1 }));
    }, 1000);
    this.setState({ isLoading: true }, async() => {
      let response = await UploadFiles(selectedFile,"document",store.dispatch) 
      if (response.code === 1) { 
        UploadRicaDocument({document:response.file_path}, store.dispatch).then((value) => {
          if (value === 1) {
            this.setState(
              {
                uploadedDoc: true,
                reupload: true,
                mediaSrc: media,
                docMediaType: mediaType,
                isLoading: false,
              },
              () => {
                clearInterval(this.intervalID);
                this.intervalID = 0;
                wrapper.innerHTML = `<p>Your ID document/valid passport has been submitted successfully.</p>`;
                swal({
                  icon: "success",
                  title: "Successfully Uploaded",
                  content: wrapper,
                  className: "add-subscription",
                });
              }
            );
          }
        }); 
      } 
    });
  };

  SaveImageHandler = async () => {
    const { uploadedDoc } = this.state;
    const { Selecttab } = this.props;
    if (uploadedDoc) {
      Selecttab("company_detail_verification");
    } else {
      swal({
        icon: "error",
        title:"Error!",
        text: "Documents and your picture not uploaded yet.",
      });
    }
  };

  handleCameraError(error) {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = `<p>Please allow access permission to the camera.</p>`;
    swal({
      icon: "error",
      title: `Camera permission denied!`,
      content: wrapper,
      className: "add-subscription",
    });
  }

  render() {
    const {
      pageType,
      clicked,
      selfie,
      profile_picture,
      isDocUpload,
      mediaSrc,
      reupload,
      docMediaType,
      isLoading,
      timer,
    } = this.state;
    return (
      <>
        {pageType === "id_process" && (
          <div className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-12">
                <div className="porting-process id-verifiy">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="take-selfie-text id-verifiy-text">
                          <h5>Upload Acceptable ID/Passport</h5>
                          <h6>
                            To complete this process, we require the following:
                          </h6>
                          <li>
                            We require a picture of you holding your identity
                            document
                          </li>
                          <li>
                            Picture of your Smart ID Card or Valid passport
                          </li>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 id-verifiy-img-main">
                        <div className="id-verifiy-img">
                          <img
                            src="./../assets/images/id-verifiy-img.svg"
                            alt="1"
                          />
                        </div>
                        <span>
                          <Link to="#">
                            Learn More{" "}
                            <img
                              src="./../assets/images/red-right-arrow.svg"
                              alt="1"
                            />
                          </Link>
                          <button
                            type="button"
                            className="btn btn-orange address"
                            onClick={() =>
                              this.setState({
                                pageType: "selfie_process",
                              })
                            }
                          >
                            Start Process
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {pageType === "selfie_process" && (
          <>
            {" "}
            <div className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="porting-process id-verifiy">
                    <div className="container-fluid">
                      <div
                        className={`${
                          selfie ? "tick" : "porting"
                        }-box p-4 d-flex porting-box-rica flex-column justify-content-center align-items-center webphone-btns`}
                      >
                        <img
                          src={`./../assets/images/${
                            selfie
                              ? "icons/tick-white-circle.svg"
                              : "signup/COMPANY-REGISTRATION-IMG.png"
                          }`}
                          alt="1"
                        />
                        <h6 className="font-14 color-fff lh-17">
                          {selfie
                            ? "ID/PASSPORT SUCCESS,PENDING VERIFCATION"
                            : "ID Verification"}
                        </h6>
                      </div>

                      <div className="rica-main-div">
                        {!isDocUpload && (
                          <div
                            className={`rica-process-div ${
                              clicked ? "image-captured" : "camera-shown"
                            }`}
                          >
                            {!clicked && (
                              <>
                                <span className="top-right"></span>
                                <span className="top-left"></span>
                                <span className="bottom-left"></span>
                                <span className="bottom-right"></span>
                              </>
                            )}
                            {!clicked && (
                              <Camera
                                onTakePhoto={(dataUri) => {
                                  this.handleTakePhoto(dataUri);
                                }}
                                onCameraError={(error) => {
                                  this.handleCameraError(error);
                                }}
                                idealFacingMode={"FACING_MODES.ENVIRONMENT"}
                                idealResolution={{
                                  width: 320,
                                  height: 380,
                                }}
                                imageCompression={1}
                                isMaxResolution={true}
                                isImageMirror={false}
                                isSilentMode={false}
                                isDisplayStartCameraError={false}
                                isFullscreen={false}
                                sizeFactor={0.3}
                              />
                            )}
                            {clicked && profile_picture.includes("image/") && (
                              <img
                                className="w-100"
                                src={profile_picture}
                                alt="My Selfie"
                              />
                            )}
                          </div>
                        )}

                        {isDocUpload && (
                          <div
                            className={`rica-process-div image-captured upload-rica-image ${
                              isLoading ? "spinner-rica" : ""
                            }`}
                          >
                            {isLoading && (
                              <>
                                <img
                                  src="./../assets/images/Spinner-rica.gif"
                                  alt="1"
                                />
                                <p className="d-flex justify-content-space-between mb-0">
                                  <span className="mr-2 font-design">
                                    Please wait while uploading...
                                  </span>
                                </p>
                                <p classname="uploading-timer">
                                  <img
                                    style={{
                                      height: "18px",
                                      width: "16px",
                                      marginRight: "7px",
                                    }}
                                    src="./../assets/images/clock-alarm.svg"
                                  />
                                  <span>{`${timer} secs`}</span>
                                </p>
                              </>
                            )}
                            {docMediaType === "image" && !isLoading ? (
                              <img
                                src={mediaSrc}
                                className="w-100"
                                alt="My Documents"
                              />
                            ) : docMediaType === "pdfFile" && !isLoading ? (
                              <a
                                href={mediaSrc}
                                type="application/pdf"
                                download
                                target="_blank"
                              >
                                <i
                                  className="fa fa-file-pdf"
                                  style={{
                                    fontSize: "105px",
                                  }}
                                ></i>
                              </a>
                            ) : docMediaType === "zipFile" && !isLoading ? (
                              <a href={mediaSrc}>
                                <i
                                  className="fa fa-file-archive"
                                  style={{
                                    fontSize: "105px",
                                  }}
                                ></i>
                              </a>
                            ) : docMediaType === "textfile" && !isLoading ? (
                              <>
                                <a href={mediaSrc} download target="_blank">
                                  <i
                                    className="fas fa-file"
                                    style={{
                                      fontSize: "105px",
                                    }}
                                  ></i>
                                </a>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                        {/* ---- */}
                        <div className="take-selfie-text">
                          <h5>
                            {isDocUpload
                              ? "Upload your smart ID card or valid passport"
                              : "Take a selfie off yourself with clear ID or passport documents"}
                          </h5>
                          <h6>
                            To complete this process, we require the following:
                          </h6>
                          <li>
                            We require a picture of you holding your identity
                            document
                          </li>
                          <li>Upload your Smart ID Card or Valid passport</li>
                        </div>
                      </div>
                      {!isDocUpload && (
                        <div className="form-group google-process-btn self-e-btn">
                          <button
                            type="button"
                            className="btn btn-orange address"
                            onClick={this.Retry}
                          >
                            Retake Picture
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-orange address"
                            onClick={this.UploadSelfieWithID}
                          >
                            Continue
                          </button>
                        </div>
                      )}
                      {isDocUpload && (
                        <div className="form-group google-process-btn self-e-btn">
                          <button
                            type="button"
                            className="btn btn-orange address"
                            onClick={(e) => this.uploadRef.click()}
                          >
                            <input
                              type="file"
                              id="upload_documents"
                              name="documents"
                              ref={(input) => (this.uploadRef = input)}
                              accept="*"
                              onChange={(e) => this.selectFileHandler(e)}
                              hidden
                            />
                            {reupload ? "Reupload Document" : "Upload Document"}
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-orange address"
                            onClick={this.SaveImageHandler}
                          >
                            Save Next
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state?.user?.user_details ?? {},
  };
};
export default connect(mapStateToProps)(withRouter(IdVerification));
