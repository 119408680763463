import React, { Component } from "react"; 
import moment from "moment";
import {
  AllRecordingListing,
  ForceCall,
  SelectedChatType,
  SelectedChatUser,
} from "../../../Services/Actions/callActions";
import { convertUtcToLocalTime, removeBackdrop } from "../../../Utils/Common";
import { Link } from "react-router-dom";
import Pagination from "../../../Component/Pagination";
import ContactSlider from "../NxaContacts/ContactSlider";
import MessageContactModal from "../SMS/MessageContactModal";
import { connect } from "react-redux";
import { withRouter } from "../../../Utils/withRouter";
import { store } from "../../../Services"; 
import { getMediaDownloadUrl } from "../../../Services/Actions/settingActions";



class CallRecordings extends Component {
  constructor(props) {
    super(props);
    
    let recording_url = {}; 
    [...Array(this.props.all_recording_list?.length).keys()].map((v, i) => {
      let n = "url" + v;
      recording_url[n] = ""; 
      return null;
    });

    const initialState = { 
      modal_type: "",
      pageOfItems: [],
      show: false,
      number: "",
      search_num: "",
      addContact: false, 
    }
    this.state = {initialState}; 
    Object.assign(initialState,recording_url);
  }

  componentDidMount = async () => {  
    AllRecordingListing(store.dispatch); 
  };

  componentDidUpdate = (prevProps)=>{
    const {all_recording_list} = this.props;
    if(prevProps?.all_recording_list !== all_recording_list){
        this.FetchCallRecordingurl(all_recording_list);
    } 
  }

  FetchCallRecordingurl = (all_recording_list)=>{  
    all_recording_list && all_recording_list.map(async(value,index)=>{ 
      let responseUrl = await getMediaDownloadUrl(value.recording_file)
      if(responseUrl){
        this.setState({
          [`recording_url${index}`]: responseUrl
        }) 
      }
    })  
  }

  UpdateData = (pageOfItems) => {
    this.setState({ pageOfItems });
  };

  closeModal = () => {
    this.setState({ show: false }, () => removeBackdrop());
  };

  closeAddContactSlider = () => {
    this.setState({ addContact: false });
  };

  StartChat = (value) => {
    let name = value.firstname ? `${value.firstname} ${value.lastname}` : "";
    let userData = {
      profile_picture: value?.profile_picture ?? "",
      phone: value?.destination_number,
      name,
      uniquecode: value?.uniquecode ?? "",
    };

    SelectedChatUser(userData,store.dispatch)
    SelectedChatType("one_to_one_chat",store.dispatch)
    store.navigate("/chat");
  };

  FilterCallRecording = (search_num) => {
    const { pageOfItems } = this.state;
    const { all_recording_list } = this.props;
    const filtered = pageOfItems.filter((value) => {
      let name = `${value.firstname} ${value.lastname}`;
      let toShow = value.direction === "inbound" ? value.caller_id_number : value.destination_number;
      return (
        (toShow &&toShow.trim().toLowerCase().includes(search_num)) ||
        (name && name.trim().toLowerCase().includes(search_num))
      );
    });
    this.setState({
      pageOfItems: search_num ? filtered : all_recording_list.slice(0, 5),
    });
  };

  filterCallRecordingHandler = (e) => {
    this.setState(
      {
        search_num: e.target.value,
      },
      () => {
        this.FilterCallRecording(e.target.value);
      }
    );
  };

  render() {
    const { modal_type, pageOfItems, show, number, search_num, addContact } =  this.state;
    const { all_recording_list } = this.props; 
    return (
      <>
        <div className="col-md-8 col-lg-9">
          <div className="right-side-top-header">
            <h5>Call Recordings</h5>
            <div className="call-filter-refresh-div">
              <Link to="#" title="Refresh Call Recording" onClick={() => AllRecordingListing(store.dispatch)}>
                <img src="./../assets/images/refresh-ico.svg" alt="1" />
              </Link>
              <span className="filter-serach">
                <img src="./../assets/images/filter.svg" alt="1" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter recording history"
                  name="search_num"
                  value={search_num}
                  onChange={(e) => this.filterCallRecordingHandler(e)}
                />
              </span>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane call-info-scroll single-table-height show active">
              <div className="table-responsive calls-table calls-table-hover">
                <table className="table table-hover table-center mb-0">
                  <tbody>
                    {pageOfItems &&
                      pageOfItems.map((value, index) => {
                        const localCreatedTime = convertUtcToLocalTime(
                          value.start_stamp
                        );
                        let formattedDate = moment(localCreatedTime)
                          .locale("en")
                          .format("ll");
                        let formattedTime = moment(localCreatedTime)
                          .locale("en")
                          .format("LT"); 
                        let toShow = value.direction === "inbound" ? value.caller_id_number : value.destination_number;
                        if (value) {
                          return (
                            <tr>
                              <td>
                                <div className="d-flex all-message-email-row">
                                  <div className="text-center align-self-center caller-img">
                                    <img
                                      src={
                                        value.profile_picture
                                          ? value.profile_picture
                                          : "./../assets/images/Mask.png"
                                      }
                                      alt="1"
                                    />
                                  </div>
                                  <div className="align-self-center caller-name-number">
                                    <h3>
                                      {value.firstname
                                        ? `${value.firstname} ${value.lastname}`
                                        : "Unknown"}
                                    </h3>
                                    <p> {toShow} </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="align-self-center recording-td"> 
                                  <audio
                                    src={this.state[`recording_url${index}`]}
                                    controls
                                  > 
                                  </audio>
                                </div>
                              </td>
                              <td></td>
                              <td align="right" style={{ width: "20%" }}>
                                <Link
                                  to="#"
                                  className="text-grey calls-date-time"
                                >
                                  {" "}
                                  {`${formattedDate} ${formattedTime}`}{" "}
                                </Link>
                                <div className="recentcall-img-ico allcall-img-ico">
                                  {value.nxatel_user === "N" && (
                                    <>
                                      <Link
                                        to="#"
                                        title="Add Contact"
                                        onClick={() =>
                                          this.setState({
                                            number: toShow,
                                            addContact: true,
                                          })
                                        }
                                      >
                                        <img
                                          src="./../assets/images/call-add-contact.svg"
                                          alt="1"
                                        />
                                      </Link>
                                      <Link
                                        to="#"
                                        title="SMS"
                                        onClick={() =>
                                          this.setState({
                                            modal_type: "sms",
                                            number:toShow,
                                            show: true,
                                          })
                                        }
                                      >
                                        <img
                                          src="./../assets/images/email-contact.svg"
                                          alt="1"
                                        />
                                      </Link>
                                    </>
                                  )}
                                  {value.nxatel_user === "Y" && (
                                    <Link
                                      to="#"
                                      title="Chat"
                                      onClick={() => this.StartChat(value)}
                                    >
                                      <img
                                        src="./../assets/images/call-chat-ico.svg"
                                        alt="1"
                                      />
                                    </Link>
                                  )}
                                  <Link
                                    to="#"
                                    title="Audio Call"
                                    onClick={() =>
                                      ForceCall(toShow,store.dispatch)
                                    }
                                  >
                                    <img
                                      src="./../assets/images/calls-call-ico.svg"
                                      alt="1"
                                    />
                                  </Link>
                                  <Link to="#">
                                    <img
                                      src="./../assets/images/call-menu-ico.svg"
                                      alt="1"
                                    />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    {pageOfItems && pageOfItems.length === 0 && (
                      <div className="text-center w-100">
                        <h5>No call recordings</h5>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination data={all_recording_list} currentData={this.UpdateData} />
        </div>
        <ContactSlider
          addContact={addContact}
          closeAddContactSlider={this.closeAddContactSlider}
          phone={number}
          renderPage={"call_recording"}
        />

        {modal_type === "sms" && show && (
          <MessageContactModal
            OpenMessageModal={show}
            closeMessageContactModal={this.closeModal}
            destination_number={number}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  all_recording_list: state?.call?.all_recording_list ?? [],
});

export default connect(mapStateToProps)(withRouter(CallRecordings));
