 
import { firebaseDatabase } from "./FirebaseService";

class Chat {
    constructor() {
        this.Users = firebaseDatabase.ref("/Users/");
        this.Group = firebaseDatabase.ref("/Groups/");
        this.Chat = firebaseDatabase.ref("/Chat/");
        this.Status = firebaseDatabase.ref("/status/");
    }

    room_id(user1, user2) {
        if (user1 < user2) {
            return `${user1}_${user2}`;
        } else {
            return `${user2}_${user1}`;
        }
    }

    if_room_exists(room_id) {
        return Promise.resolve(
            this.Users.child(room_id).get().then((snapshot) => {
                if (snapshot.exists()) {
                    return true;
                } else {
                    return false;
                }
            }).catch((error) => {
                console.error(error);
                return false;
            })
        )
    }

    if_room_exists_return_value(room_id) {
        return Promise.resolve(
            this.Users.child(room_id).get().then(async (snapshot) => {
                if (snapshot.exists()) {
                    return snapshot.val();
                } else {
                    return {};
                }
            }).catch((error) => {
                console.error(error);
                return {};
            })
        )

    }

    add_room_id(room_id, user_data) {
        this.Users.child(room_id).set(user_data);
    }

    update_room_data(room_id, user_data) {
        this.Users.child(room_id).update(user_data);
    }

    fetch_room_data_on_update(room_id, UpdateFxn) {
        this.Users.child(room_id).on('value', (snapshot) => {
            const data = snapshot.val();
            { UpdateFxn && UpdateFxn(data); }
        });
    }

    fetch_room_data_on_init(room_id, UpdateFxn) {
        this.Users.child(room_id).once('value').then((snapshot) => {
            const data = snapshot.val();
            { UpdateFxn && UpdateFxn(data); }
        });
    }

    fetch_room_list(room_ids,UpdateFxn) {
        let sendRooms = [];
        this.Users.once('value').then((snapshot) => {
            const key = snapshot.key();
            if (room_ids.includes(key)) {
                const data = snapshot.val();
                sendRooms = [...sendRooms, data];
            }
        });
        { UpdateFxn && UpdateFxn(sendRooms.reverse()); }
    }

    add_message_data(room_id, user_data) {
        this.Chat.child(room_id).set(user_data);
    }

    update_message_data(room_id, user_data) {
        this.Chat.child(room_id).push(user_data);
    }

    fetch_message_data_on_update(room_id, UpdateFxn) {
        this.Chat.child(room_id).on('value', (snapshot) => {
            const data = snapshot.val();
            { UpdateFxn && UpdateFxn(data); }
        });
    }

    fetch_message_data_on_init(room_id, UpdateFxn) {
        this.Chat.child(room_id).once('value').then((snapshot) => {
            const data = snapshot.val();
            { UpdateFxn && UpdateFxn(data); }
        });
    }

    remove_room_list(key, room_id) {
        this.Users.child(`/${key}/${room_id}`).remove();
    }

    remove_group_list(room_id) {
        this.Group.child(`/${room_id}`).remove();
    }

    remove_message_data(room_id) {
        this.Chat.child(room_id).remove();
    }

    fetch_online_status(uid, UpdateFxn) {
        this.Status.child(uid).on('value', (snapshot) => {
            const data = snapshot.val();
            { UpdateFxn && UpdateFxn(data); }
        });
    }

    add_ref_with_children = (refs, updates) => {
        return Promise.resolve(firebaseDatabase.ref(refs).set(updates));
    }

    update_ref_with_children = (refs, updates) => {
        return Promise.resolve(firebaseDatabase.ref(refs).update(updates));
    }

    fetch_ref_on_update = (refs, UpdateFxn) => {
        firebaseDatabase.ref(refs).on('value', (snapshot) => {
            const data = snapshot.val();
            { UpdateFxn && UpdateFxn(data); }
        });
    }

    fetch_if_ref_exists = (refs, value = false) => {
        return Promise.resolve(
            firebaseDatabase.ref(refs).get().then((snapshot) => {
                if (snapshot.exists()) {
                    return value ? snapshot.val() : true;
                } else {
                    return value ? {} : false;
                }
            }).catch((error) => {
                console.error(error);
                return value ? {} : false;
            })
        )
    }

    remove_ref_data(refs) {
        firebaseDatabase.ref(refs).remove();
    }

    remove_on_disconnect(refs) {
        firebaseDatabase.ref(refs).onDisconnect().remove((err) => {
            if (err) {
                console.error("could not establish onDisconnect event", err);
            }
        })
    }

    set_on_disconnect(refs, updates) {
        firebaseDatabase.ref(refs).onDisconnect().set(updates);
    }

    callback_exit(refs) {
        firebaseDatabase.ref(refs).off();
    }

}

export default new Chat();