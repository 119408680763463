import React, { Component } from "react";
import authService from "../../Utils/authService";

const existInPhoneSystem = [
  "phone",
  "business-hours",
  "numbers",
  "team-members",
  "teams",
  "voice-files",
];

class Sidebar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { state, SetTab, status , payment_status  } = this.props; 
    const user_details = authService.getUserDetail();
    const { type: user_type } = user_details;
    return (
      <>
        {/* customer setting sidebar */}
        {(user_type === "C" || user_type === "A") && (
          <div className="col-md-4 col-lg-3 pl-0">
            <div className="mid-sidebar">
              <div className="mid-nav-inner bg-gray p-0">
                <div className="mid-nav pt-4 phone-system-menus">
                  <div className="online-store welcome-mid-side">
                    <h5
                      className={` ${state === "account" ? "active" : ""}`}
                      onClick={() => SetTab("account")}
                    >
                      <img src="./../assets/images/rica.svg" alt="1" />
                      Account
                    </h5>
                    {(status === "A" && payment_status === "A") && (
                      <h5
                        className={` ${
                          existInPhoneSystem.includes(state) ? "active" : ""
                        }`}
                        onClick={() => SetTab("phone")}
                      >
                        <img
                          src="./../assets/images/add-contact-ico.svg"
                          alt="1"
                        />
                        Phone System
                      </h5>
                    )} 
                    {(status === "A" && payment_status === "A")&& (
                      <h5
                        className={` ${state === "contacts" ? "active" : ""}`}
                        onClick={() => SetTab("contacts")}
                      >
                        <img
                          src="./../assets/images/contact-icon.svg"
                          alt="1"
                        />
                        Contacts
                      </h5>
                    )} 
                    {(status === "A" && payment_status === "A") && (
                      <h5
                        className={` ${
                          state === "notification" ? "active" : ""
                        }`}
                        onClick={() => SetTab("notification")}
                      >
                        <img
                          src="./../assets/images/notification.svg"
                          alt="1"
                        />
                        Notifications
                      </h5>
                    )} 
                  </div>
                  <div className="side-bar-join-webinar bottom-fixed-sidebar">
                    <h2>Upcoming Training from Nxatel</h2>
                    <div className="side-bar-bottom-list">
                      <ul className="side-bar-bottom-list-ul">
                        <li>
                          <a href={void 0}>
                            <span className="side-sp-ico-left">
                              <img
                                src="./../assets/images/side-calender-ico.svg"
                                alt="1"
                              />
                            </span>
                            Attend Live Training
                            <span className="side-sp-ico-right">
                              <img
                                src="./../assets/images/Polygon-2.svg"
                                alt="1"
                              />
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href={void 0}>
                            <span className="side-sp-ico-left">
                              <img
                                src="./../assets/images/side-video-ico.svg"
                                alt="1"
                              />
                            </span>
                            Video Tutorials
                            <span className="side-sp-ico-right">
                              <img
                                src="./../assets/images/Polygon-2.svg"
                                alt="1"
                              />
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href={void 0}>
                            <span className="side-sp-ico-left">
                              <img
                                src="./../assets/images/side-book-open.svg"
                                alt="1"
                              />
                            </span>
                            Knowledge Base
                            <span className="side-sp-ico-right">
                              <img
                                src="./../assets/images/Polygon-2.svg"
                                alt="1"
                              />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <a href={void 0} className="get-app-side-btn">
                      Get the Mobile App
                      <span className="ml-2">
                        <img src="./../assets/images/itune-blue.svg" alt="1" />
                      </span>
                      <span className="ml-2">
                        <img
                          src="./../assets/images/play-store-blue.svg"
                          alt="1"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* EXTENSION / TEAM MEMBER SIDEBAR */}

        {user_type === "E" && (
          <div className="col-md-3 pl-0">
            <div className="mid-sidebar">
              <div className="mid-nav-inner bg-gray p-0">
                <div className="mid-nav p-4 phone-system-menus">
                  <h4>Settings</h4>
                  <hr className="mb-0 mt-0"></hr>
                  <h5
                    className={` ${state === "account" ? "active" : ""}`}
                    onClick={() => SetTab("account")}
                  >
                    <img src="./../assets/images/rica.svg" alt="1" />
                    Account
                  </h5>
                  {status && status === "A" && (
                    <h5
                      className={` ${state === "notification" ? "active" : ""}`}
                      onClick={() => SetTab("notification")}
                    >
                      <img
                        src="./../assets/images/notification-ico.png"
                        alt="1"
                      />
                      Notifications
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Sidebar;
