import * as constants from "../Constants/dashboardConstants";

 

const initalState = {  
    dashbaord_details:[],
    onboard_process_statics:{}
}

const dashboardReducer = (state = initalState, action) => {
    switch (action.type) {
        case constants.FETCH_DASHBOARD_DETAILS:
            return {
                ...state,
                dashbaord_details: action.payload,
            };
        case constants.FETCH_ONBOARD_PROCESS_STATICS:
            return {
                ...state,
                onboard_process_statics:action.payload,
            } 
        default:
            return state
    }
}

export default dashboardReducer;
