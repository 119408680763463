import React, { useState } from "react";
import moment from "moment";
import { convertUtcToLocalTime } from "../../../Utils/Common";
import { store } from "../../../Services";
import {
  CreateNotes,
  FetchProfileInfoData,
} from "../../../Services/Actions/callActions";
import Modal from "../../../Component/Modal";
import { connect } from "react-redux";

function NotesModal({ open, handleClose, call_number, previous_note }) {
  const [note, setNote] = useState("");
  let date_time = convertUtcToLocalTime(previous_note?.[0]?.creationdate);
  let formattedDate = moment(date_time).locale("en").format("ll");

  const CreateNoteHandler = async () => {
    let response = await CreateNotes(
      { note, number: call_number },
      store.dispatch
    );
    if (response === 1) {
      FetchProfileInfoData({ number: call_number }, store.dispatch);
      handleClose();
    }
  };

  return (
    <div>
      <Modal open={open} size={`sm`} CloseModal={handleClose} title={`Notes`}>
        <div className="notes-modal-textarea">
          <div className="notes-modal-edit-div">
            <img src="./../assets/images/edit-modal-notes.svg" alt="logo" />
            <a className="modal-note-save-link" onClick={CreateNoteHandler}>
              {" "}
              Save{" "}
            </a>
          </div>
          <textarea
            className="form-control"
            rows="3"
            name="note"
            value={note}
            onChange={(event) => setNote(event.target.value)}
          ></textarea>
          {previous_note && previous_note.length !== 0 && (
            <>
              <h5>Previous Notes</h5>
              <div className="call-md-notes-list">
                <h3>{previous_note?.[0]?.notes}</h3>
                <span className="added-by-span">
                  <h6>{`Added by ${previous_note?.[0]?.created_by_name}`}</h6>
                  <h6>{`${formattedDate}`}</h6>
                </span>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
export default connect()(NotesModal);
