import { apiBaseUrl, PaymentDomain } from "../../Utils/Common";
import * as constants from "../Constants/supportConstants";
import * as ACTIONS from "./../Constants/apiConstants.js";
import swal from "sweetalert";
import { store } from "../../Services";
import authService from "../../Utils/authService";
import { handleResponse } from "./userActions";
import { SHOW_LOADER } from "../Constants/userConstants";

export const ZohoAccessToken = (dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}${ACTIONS.ZOHO_ACCESS_TOKEN}`, {
    method: "GET",
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return data.data.access_token;
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return false;
      }
    });
};

export const SupportLogQuery = (params,dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${PaymentDomain}api/NxaAPI/ZohoDeskCreateTicket`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.Data.success === true) {
        return 1;
      } else {
        swal({ title: "Error!", text: data.Message, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const ProblemCategorySkillsList = (params,dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${PaymentDomain}api/NxaAPI/ZohoDeskProblemCategorySkills`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      return data;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const SendSupportFeedback = (params,dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${PaymentDomain}api/NxaAPI/ZohoDeskCreateTicketSupportFeedback`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.Data.success === true) {
        return 1;
      } else {
        swal({ title: "Error!", text: data.Message, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};
