import React, { useState,useEffect } from "react"; 
import moment from "moment";
import { store } from "../../../../Services"; 
import { DeleteExtension, ExtensionListing } from "../../../../Services/Actions/settingActions";
import { convertUtcToLocalTime } from "../../../../Utils/Common";
import Pagination from "../../../../Component/Pagination";
import TeamMemberSlider from "../../../Calls/Team/TeamMemberSlider";
import { connect } from "react-redux"; 
import swal from 'sweetalert';

function Member({ SetTab,extension_listing }) {
    const [open,setOpen] = useState(false)
    const [search,setSearch] = useState("")
    const [totalItems,setTotalItems] = useState([])
    const [pageofItems,setPageOfItems] = useState([])
    const [isEdit,setIsEdit] = useState(false)
    const [extensionDetails,setExtensionDetails] = useState({}) 

    useEffect(()=>{
        setTotalItems(extension_listing)
    },[extension_listing])

     
    const SearchDataHandler = e => {
        let search = e.target.value
        setSearch(search)
        setTotalItems(
            extension_listing.filter(value=>{ 
                return value.name.toLowerCase().includes(search.toLowerCase()) 
            })
        ) 
    }

    const sliceData = pageofItems => {
        setPageOfItems(pageofItems)
    }

    const DeleteTeamMemberHandler = (id) => {
        swal({
            text: "Are you sure want to delete this team member?", 
            icon: "warning",
            title:"Warning!",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    let response = await DeleteExtension(id,store.dispatch)
                    if(response === 1){
                        ExtensionListing(0,store.dispatch)
                    }
                }
            });
    } 

    const ViewTeamMemberHandler = (value)=>{
        setExtensionDetails(value);
        setIsEdit(true);
        setOpen(true);
    }

    const addMemberHandler = ()=>{
        setExtensionDetails({})
        setIsEdit(false)
        setOpen(true) 
    }
    

    return (
        <>
            <div className="phone-system-inner-main-div">
                <div className="phone-system-inner-div">
                    <div className="phone-system-inner-head">
                        <div className="ps-inner-head-left">
                            <h3>
                                <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => SetTab("phone")}
                                    className="phone-system-name"
                                >
                                    Phone Settings
                                </span>
                                <img
                                    src="./../assets/images/menu-arrow-up.svg"
                                    alt="1"
                                />
                                <span>Team Members</span>
                            </h3>
                        </div>

                        <div className="ps-inner-head-right">
                            <span className="search-ps-inner">
                                <img
                                    src="./../assets/images/search-dash.svg"
                                    alt="1"
                                />
                                <input
                                    className="form-control"
                                    type="text"
                                    name="search" 
                                    value={search}  
                                    placeholder="" 
                                    onChange={SearchDataHandler}
                                />
                            </span>
                            <a
                                href={void 0}
                                style={{ cursor: "pointer" }}
                                className="create-ps-num-btn"
                                onClick={()=>addMemberHandler()} 
                            >
                                Add Team members
                            </a>
                        </div>
                    </div>
                    {/* table start */}
                    <div className="phone-system-inner-table">
                        {pageofItems && pageofItems.length === 0 &&
                            <div className="team-user-add-div add-data-vactor">
                                <img src="./../assets/images/IVR.svg" alt="1" />
                                <h4>No Team Member created</h4>
                                <a href={void 0} className="btn btn-orange" style={{ cursor: "pointer" }} onClick={() =>setOpen(true)}>
                                    Add Team Member
                                </a>
                            </div>
                        }
                        
                        <div className="phone-system-inner-table">
                            {pageofItems && pageofItems.length !== 0 &&
                                <div className="table-responsive call-info-scroll single-table-height">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Creation Date</th>
                                                <th>Name</th>
                                                <th>Phone</th> 
                                                <th>Extension Number</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageofItems && pageofItems.map((value, index) => {
                                                    let date_time = convertUtcToLocalTime(value.creationdate)
                                                    let formattedDate = moment(date_time).locale("en").format("ll");
                                                    let formattedTime = moment(date_time).locale("en").format("LT");
                                                return (
                                                    <tr key={index}>
                                                        <td>{`${formattedDate} ${formattedTime}`}</td>
                                                        <td>
                                                            <span className="ps-name-number"> 
                                                                <img
                                                                    src={value.profile_picture ? value.profile_picture : "./../assets/images/Mask.png"}
                                                                    alt="1"
                                                                />
                                                                <span>
                                                                    <h3>
                                                                        {value.name}
                                                                    </h3>
                                                                    <p>
                                                                        {value.email}
                                                                    </p> 
                                                                </span>
                                                            </span> 
                                                        </td>
                                                        <td>{value.phone}</td> 
                                                        <td>{value.user_extension}</td> 
                                                        <td> 
                                                            <a 
                                                                title="Edit Member"
                                                                className="edit-business-hrs" 
                                                                onClick={()=>ViewTeamMemberHandler(value)}         
                                                            >
                                                                <img src="./../assets/images/edit-new.svg" alt="1" />
                                                            </a>
                                                            &nbsp;&nbsp;
                                                            <a 
                                                                title="Delete Member"
                                                                className="edit-business-hrs" 
                                                                onClick={()=>DeleteTeamMemberHandler(value.customerID)}
                                                            >
                                                                <img src="./../assets/images/trash-2.svg" alt="delete" />
                                                            </a> 
                                                        </td>
                                                    </tr>
                                                )})
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            } 
                            <Pagination data={totalItems} currentData={sliceData}  /> 
                        </div> 
                    </div>
                </div>
            </div> 

            <TeamMemberSlider addTeamMember={open} closeTeamMemberSlider={()=>setOpen(false)} extensionDetails = {extensionDetails} isEdit={isEdit} /> 
            
        </>
    );
}


const mapStateToProps = (state) => ({
    extension_listing: state?.setting?.extension_listing??[],
});

export default connect(mapStateToProps)(Member); 
