import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { store } from "../../../Services";
import { SendMessage } from "../../../Services/Actions/callActions";
import { getMediaDownloadUrl } from "../../../Services/Actions/settingActions";
import { TextSubstring } from "../../../Utils/Common";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function MultiIncomingPop({
  AnswerCall,
  reference,
  _activeCall,
  HangUpCall,
  Transfer,
}) {
  const ringingCalls =
    Object.values(reference).filter(
      (value) => value.uuid !== _activeCall && value.status === "ringing"
    )?.[0] ?? [];
  const { name, number, uuid } = ringingCalls;

  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [caller_picture, setCallerPicture] = useState("");

  const { nxatel_call, extension_listing } = useSelector((state) => {
    const nxatel_call = state?.call,
      extension_listing = state?.setting?.extension_listing ?? [];
    return { nxatel_call, extension_listing };
  });

  const {
    profile_info: {
      notes: notes_list,
      app_picture,
      phone: callee_phone,
      company,
    },
  } = nxatel_call;

  useEffect(() => {
    (async () => {
      if (app_picture) {
        const url = await getMediaDownloadUrl(app_picture, store.dispatch);
        if (url) setCallerPicture(url);
      }
    })();
  }, [app_picture]);

  const SendMessageHandler = async () => {
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
      forceUpdate(1);
      return;
    }
    let response = await SendMessage(
      { message, number: callee_phone ?? "" },
      "",
      store.dispatch
    );
    if (response === 1) {
      // HangUpCall();
    }
  };
  return (
    <div className=" dailer-modal  modal_call_popup in">
      <div
        className=" incoming-call-pop modal-sm justify-content-end"
        role="document"
      >
        <div className="">
          <div className="tranfer-call-body ">
            <div className="caller-div-transfer">
              <div className="row">
                <div className=" col-md-12">
                  <div className="">
                    <div className="col-md-12 ellipse-div">
                      <div className="caller-img">
                        <img
                          src={
                            caller_picture
                              ? caller_picture
                              : "./../assets/images/pro-pic.png"
                          }
                          alt="a"
                        />
                      </div>

                      {!showMessage && (
                        <div className="caller-details w-100">
                          <h2>Incoming call</h2>
                          <span className="cl-name">
                            {name ?? "Unknown Caller"}
                          </span>
                          {company && (
                            <h3 className="cl-comp-name">{company}</h3>
                          )}
                          <p className="cl-no">{number}</p>
                          {notes_list && notes_list.length > 0 && (
                            <span className="cl-last-note">{`Last Note: ${TextSubstring(
                              notes_list[0].notes
                            )}`}</span>
                          )}
                        </div>
                      )}

                      {showMessage && (
                        <div className="col-md-12">
                          <div className="caller-details message-on-call">
                            <span className="cl-name ">
                              {name ?? "Unknown Caller"}
                            </span>
                            {company && (
                              <h3 className="cl-comp-name">{company}</h3>
                            )}
                            <p className="cl-no">{number}</p>
                          </div>
                          <textarea
                            className="form-control transfer-call-message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                          {validator.current.message(
                            "Message",
                            message,
                            "required"
                          )}
                        </div>
                      )}
                    </div>

                    {!showMessage && (
                      <h6 className="text-center answer_call in-call-action-btn">
                        <Link to="#" onClick={() => setShowMessage(true)}>
                          <img src="./../assets/images/call-msg.png" alt="1" />
                        </Link>
                        <div className="btn-group dropup">
                          <a
                            className="dropdown-toggle"
                            href={void 0}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              src="./../assets/images/call-arrow.png"
                              alt="1"
                            />
                          </a>
                          <div className="dropdown-menu">
                            {extension_listing &&
                              extension_listing.map((values, keys) => {
                                return (
                                  <Link
                                    key={keys}
                                    className="dropdown-item"
                                    to="#"
                                    onClick={() =>
                                      Transfer(values.user_extension)
                                    }
                                  >
                                    {`${values.firstname} ${values.lastname} (${values.user_extension})`}
                                  </Link>
                                );
                              })}
                            {extension_listing &&
                              extension_listing.length === 0 && (
                                <Link className="dropdown-item" to="#">
                                  No member found...
                                </Link>
                              )}
                          </div>
                        </div>
                        <button
                          className="btn btn-success"
                          onClickCapture={() => AnswerCall(uuid)}
                        >
                          Accept
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-danger"
                          onClick={() => HangUpCall(uuid)}
                        >
                          Reject
                        </button>
                      </h6>
                    )}
                    {showMessage && (
                      <h6 className="text-center answer_call in-call-action-btn">
                        <button
                          className="btn btn-danger"
                          onClick={() => setShowMessage(false)}
                        >
                          Back
                        </button>
                        <button
                          className="btn btn-success m-0"
                          onClick={() => SendMessageHandler()}
                        >
                          Send
                        </button>
                      </h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiIncomingPop;
