import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Modal from "../../../../Component/Modal";
import { store } from "../../../../Services";
import { DidNumberListing } from "../../../../Services/Actions/reportActions";
import { NumberForwardCreateUpdate } from "../../../../Services/Actions/settingActions";
import { formatDIDNumber } from "../../../../Utils/Common";

class ForwardToDeviceModal extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({});
    this.state = {
      uniquecode: "",
      forwardTo: "",
      profilePic: "",
      // call_recording: false,
      selectedMember: "",
      selectedTeam: "",
      customerID: "",
      departmentID: "",
    };
  }

  componentDidMount() {
    const {
      rule_listing,
      numberDetails: { did_number },
      extension_listing,
      team_listing,
    } = this.props;
    if (rule_listing && did_number) {
      rule_listing.map((value) => {
        if (value.did_number === did_number) {
          switch (value.forward_to) {
            case "EXTENSION":
              let extension_name =
                extension_listing.filter(
                  (vall) => vall.customerID === value?.customerID
                )?.[0]?.name ?? "";
              this.setState({
                forwardTo: value.forward_to,
                selectedMember: extension_name,
                profilePic: value?.profile_picture,
                customerID: value?.customerID,
                // call_recording: value.call_record === "Y",
              });
              return null;
            case "DEPARTMENT":
              let team_name =
                team_listing.filter(
                  (vall) => vall.id === value?.departmentID
                )?.[0]?.name ?? "";
              this.setState({
                forwardTo: value.forward_to,
                selectedTeam: team_name,
                departmentID: value.departmentID,
                // call_recording: value.call_record === "Y",
              });
              return null;
            case "PHONE":
              this.setState({
                forwardTo: value.forward_to,
                external_number: value.forward_to_extension,
                // call_recording: value.call_record === "Y",
              });
              return null;
            default:
              return null;
          }
        }
      });
    }
  }

  onSelectHandler = (type, value) => {
    switch (type) {
      case "EXTENSION": {
        this.setState({
          selectedMember: value?.name,
          profilePic: value?.profile_picture,
          customerID: value?.customerID,
        });
        break;
      }
      case "DEPARTMENT": {
        this.setState({
          selectedTeam: value?.name,
          departmentID: value?.id,
        });
        break;
      }
      default:
        return false;
    }
  };

  createPhoneSystemRule = async () => {
    const {
      handleClose,
      numberDetails: { did_number, phonesystemID },
    } = this.props;
    const {
      uniquecode,
      // call_recording,
      forwardTo,
      external_number,
      departmentID,
      customerID,
    } = this.state;
    if (this.validator.allValid()) {
      let data = {
        did_number,
        // call_record: call_recording ? "Y" : "N",
        forward_to: forwardTo,
        forward_to_id:
          forwardTo === "EXTENSION"
            ? customerID
            : forwardTo === "DEPARTMENT"
            ? departmentID
            : "",
        phone: external_number,
        type: "number_forward",
        id: phonesystemID ?? "",
      };

      let response = await NumberForwardCreateUpdate(
        data,
        uniquecode && uniquecode !== "",
        "number_forward",
        store.dispatch
      );
      if (response === 1) { 
        DidNumberListing(store.dispatch,"phoneSettings");
        handleClose();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    this.validator.purgeFields();
    const {
      openModal,
      handleClose,
      extension_listing,
      team_listing,
      numberDetails: { did_number, countryImage },
    } = this.props;
    const {
      selectedMember,
      selectedTeam,
      forwardTo,
      external_number,
      profilePic,
      // call_recording,
    } = this.state;
    return (
      <>
        <Modal
          open={openModal}
          CloseModal={handleClose}
          title={""}
          showActiveclassName={openModal}
          size={`md`}
        >
          <div className="assign-modal-div">
            <h3>
              <span>Forward phone number:</span>
              <span className="assign-to-num">
                <img
                  src={
                    countryImage
                      ? `./../assets/images/${countryImage}`
                      : "./../assets/images/mask.png"
                  }
                  alt="1"
                />
                {formatDIDNumber(did_number)}
              </span>
            </h3>
            <form className="assign-to-modal-from">
              <div className="form-group">
                <label>Forward to:</label>
                <div className="dropdown custom-drop-assign">
                  <a
                    className=" dropdown-toggle custom-drop-control"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {!forwardTo
                      ? "Select"
                      : forwardTo === "PHONE"
                      ? "External Number"
                      : forwardTo === "EXTENSION"
                      ? "Team Member"
                      : "Team"}
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={() => this.setState({ forwardTo: "PHONE" })}
                    >
                      External Number
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={() => this.setState({ forwardTo: "EXTENSION" })}
                    >
                      Team Member
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={() => this.setState({ forwardTo: "DEPARTMENT" })}
                    >
                      Team
                    </Link>
                  </div>
                </div>
              </div>
              {forwardTo === "EXTENSION" && (
                <div className="form-group">
                  <label>Team Member:</label>
                  <div className="dropdown custom-drop-assign">
                    <a
                      className=" dropdown-toggle custom-drop-control"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {selectedMember ? (
                        <>
                          <img
                            src={
                              profilePic
                                ? profilePic
                                : "./../assets/images/Mask.png"
                            }
                            alt="1"
                          />
                          {selectedMember}
                        </>
                      ) : (
                        "Select Team Member"
                      )}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {extension_listing &&
                        extension_listing.map((value) => {
                          return (
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() =>
                                this.onSelectHandler("EXTENSION", value)
                              }
                            >
                              <img
                                src={
                                  value.profile_picture ?  value.profile_picture :
                                  "./../assets/images/Mask.png"
                                }
                                alt={value.name}
                              />
                              {value.name}
                            </Link>
                          );
                        })}
                    </div>
                    {this.validator.message(
                      "Team Member",
                      selectedMember,
                      "required"
                    )}
                  </div>
                </div>
              )}
              {forwardTo === "DEPARTMENT" && (
                <div className="form-group">
                  <label>Team :</label>
                  <div className="dropdown custom-drop-assign">
                    <a
                      className=" dropdown-toggle custom-drop-control"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {selectedTeam ? selectedTeam : `Select Team`}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {team_listing &&
                        team_listing.map((value) => {
                          return (
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() =>
                                this.onSelectHandler("DEPARTMENT", value)
                              }
                            >
                              {value.name}
                            </Link>
                          );
                        })}
                    </div>
                    {this.validator.message("Team", selectedTeam, "required")}
                  </div>
                </div>
              )}
              {forwardTo === "PHONE" && (
                <div className="form-group">
                  <label>Number:</label>
                  <div className="dropdown custom-drop-assign">
                    <input
                      type="text"
                      className="custom-drop-control"
                      name="external_number"
                      value={external_number}
                      onChange={(e) =>
                        this.setState({ external_number: e.target.value })
                      }
                    />
                  </div>
                  {this.validator.message(
                    "External Number",
                    external_number,
                    "required"
                  )}
                </div>
              )}
              {/* <div className="form-group">
                <label>Call Recording:</label>
                <div className="custom-drop-assign">
                  <label className="switch ml-3">
                    <input
                      type="checkbox"
                      name="call_recording"
                      value={call_recording ? "checked" : ""}
                      checked={call_recording ? "checked" : ""}
                      onChange={() =>
                        this.setState({ call_recording: !call_recording })
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div> */}
            </form>
          </div>
          <div className="modal-footer add-modal-modal-footer">
            <button
              type="button"
              className="btn btn-meet-join"
              onClick={this.createPhoneSystemRule}
            >
              Continue
            </button>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  team_listing: state?.call?.team_listing,
  extension_listing: state?.setting?.extension_listing,
});

export default connect(mapStateToProps)(ForwardToDeviceModal);
