import React, { Component } from "react"; 
import { Modal } from "react-bootstrap"; 
import moment from "moment";
import { VideoRecordingList } from "../../../Services/Actions/meetActions";
import Pagination from "../../../Component/Pagination";
import { connect } from "react-redux";
import JwPlayer from "../JWPlayer";
import { bytesToSize, convertUtcToLocalTime, SecondsTohhmmss } from "../../../Utils/Common";
import { store } from "../../../Services";

class Recording extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageofitems: [],
      page: 1,
      open: false,
      file: "",
      title: "",
    };
  }

  componentDidMount = async() => {
    VideoRecordingList(store.dispatch)
  };

  UpdateData = (pageOfItems) => {
    this.setState({ pageOfItems });
  };

  OpenModal = (file, title) => {
    this.setState({ open: true, file, title });
  };

  CloseModal = () => {
    this.setState({ open: false });
    setTimeout(() => {
      this.setState({ file: "", title: "" });
    }, 500);
  };

  render() {
    const { video_record_listing } = this.props;
    const { file, open, title, pageOfItems } = this.state;
    return (
      <div className="col-md-8 col-lg-9 p-0">
        <div className="row">
          <div className="col-sm-12">
            <div className="appointment-tab">
              <div className="tab-content">
                <div className="tab-pane show active call-info-scroll">
                  <div className="table-responsive">
                    <table className="table table-hover table-center mb-0 ">
                      <tbody>
                        {pageOfItems instanceof Array &&
                          pageOfItems.map((value, key) => {
                            let date_time = convertUtcToLocalTime(
                              value.created
                            );
                            let formattedDate = moment(date_time)
                              .locale("en")
                              .format("ll");
                            let formattedTime = moment(date_time)
                              .locale("en")
                              .format("LT");
                            return (
                              <tr key={key}>
                                <td>
                                  <div className="d-flex all-message-email-row">
                                    <div className="text-center align-self-center">
                                      <img src={`${value.thumbnail}`} alt="1" />
                                    </div>
                                    <div className="align-self-center">
                                      <h3> {value.topic} </h3>
                                      <p>
                                        {" "}
                                        {`${formattedDate} ${formattedTime}`}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td align="center">
                                  Duration{" "}
                                  {SecondsTohhmmss(value.duration)}
                                </td>
                                <td align="center">
                                  Size {bytesToSize(value.size)}
                                </td>
                                <td align="center">
                                  <span
                                    className="badge badge-success"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.OpenModal(value.file, value.topic);
                                    }}
                                  >
                                    Play
                                  </span>
                                  &nbsp;&nbsp;
                                </td>
                              </tr>
                            );
                          })}
                        {pageOfItems && pageOfItems.length === 0 && (
                          <div className="text-center w-100">
                            <h5>No cloud recordings</h5>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination
                data={video_record_listing}
                currentData={this.UpdateData}
              />
            </div>
          </div>
        </div>
        <Modal
          show={open}
          onHide={this.CloseModal}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <JwPlayer file={file} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { meet: { video_record_listing, recording_count } = {} } = state;
  return {
    video_record_listing,
    recording_count,
  };
};
export default connect(mapStateToProps)(Recording);
