import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { store } from "../../../../Services";
import { DeleteRegisteredCard, PlanRenewalPayment, RecentTransactionListing } from "../../../../Services/Actions/settingActions";
import { InitiatePayment } from "../../../../Services/Actions/signupActions";
import { FetchUserDetails } from "../../../../Services/Actions/userActions";
import SaveCard from "./SaveCard"; 

function PaymentMethodSlider(props) {
  const {
    addPaymentMethod,
    getCalculatedDate,
    user_details,
    card_listing,
    closePaymentSlider,
    choosePlanModal,
    payment,
    bill_number
  } = props;
  const {
    firstname,
    lastname,
    email,
    phone,
    user_plan: { currency, name, amount, plan_duration, plan_start_date } = {},
  } = user_details;

  let duration = plan_duration === 12 ? "annually" : plan_duration === 24 ? "2 yearly" : "monthly";

  const initialState = { selectedCard: "" ,saveCard:false};

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (card_listing) {
      setState((prev) => ({
        ...prev,
        selectedCard: card_listing?.find((val) => val.primary === "Y")?.registrationID,
      }));
    }
  }, [card_listing]);

  const AddCardHandler = async () => { 
    let data = {
      Currency: "ZAR",
      Price: 0,
      RecurringType: "INITIAL",
      CustomerName: firstname,
      CustomerSurname: lastname,
      CustomerEmail: email,
      CustomerMobile: phone,
      CreateRegistration: true,
      PaymentType: "",
    };
    let order_details = {
      save_card: "Y",
      type: "C",
      payment_type: "save-card",
      path_name: "add-card",
    };
    let response = await InitiatePayment(data, order_details, store.dispatch);
    if (response === 1) { 
      setState((prev) => ({
        ...prev,
        saveCard: true,
      }));
    }
  };

  const RemoveCardHandler = (registrationID) => {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = `<p>Are you sure want to remove this card ? </p>`;
    swal({
      title: `Remove Card`,
      buttons: [true, "Remove"],
      content: wrapper,
      className: "add-subscription",
    }).then((action) => {
      if (action) {
        DeleteRegisteredCard({ registrationID }, store.dispatch);
      }
    });
  };

  const planRenewalPaymentHandler = async()=>{
      const response = await PlanRenewalPayment({registrationID : state?.selectedCard,bill_number},store.dispatch)
      if(response === 1){
        closePaymentSlider();
        setState(initialState);
        FetchUserDetails(store.dispatch)
        RecentTransactionListing(store.dispatch)
      }
  }

  return (
    <>
    <div
      className={`manage-team-member-slide ${
        addPaymentMethod ? "" : "manage-team-member-slide-close"
      }`}
    >
      {addPaymentMethod && (
        <>
          <div className="member-slide-title">
            <h5>Payment Method</h5>
            <img
              src="./../assets/images/cross-add-modal.svg"
              alt="1"
              onClick={closePaymentSlider}
            />
          </div>
          <div className="payment-listing">
            <h4>{`Your plan renews on ${getCalculatedDate(
              plan_start_date
            )}`}</h4>
            <div className="payment-list-scroll">
              {card_listing &&
                card_listing.map((value, index) => {
                  let card = JSON.parse(value.card_details);
                  return (
                    <h6 key={index}>
                      <span className="billig-card-img-num">
                        {payment && (
                          <div className="form-check form-check-payment-bar">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="inlineRadio1"
                              name="selectedCard"
                              value={value.registrationID}
                              onChange={() => (prev) => ({
                                ...prev,
                                selectedCard: value.registrationID,
                              })}
                              defaultChecked={
                                state?.selectedCard === value.registrationID
                                  ? "checked"
                                  : undefined
                              }
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              <img
                                src="./../assets/images/master-car-billing.svg"
                                alt="a"
                              />
                              {`****${card?.last4Digits ?? "NA"}`} &nbsp;
                              {value.primary === "Y" && (
                                <i className="italic-font-color">{`(Primary Card)`}</i>
                              )}
                            </label>
                          </div>
                        )}
                        {!payment && (
                          <>
                            <img
                              src="./../assets/images/master-car-billing.svg"
                              alt="a"
                            />
                            {`****${card?.last4Digits ?? "NA"}`} &nbsp;
                            {value.primary === "Y" && (
                              <i className="italic-font-color">{`(Primary Card)`}</i>
                            )}
                          </>
                        )}
                      </span>
                      <span className="billig-card-exp">
                        {`Expires ${card?.expiryMonth ?? "NA"}/${
                          card?.expiryYear ?? "NA"
                        }`}
                        <img
                          src="./../assets/images/cross-billing-pay.svg"
                          alt="1"
                          onClick={() =>
                            RemoveCardHandler(value.registrationID)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </h6>
                  );
                })}
            </div>
            <Link to="#" className="add-more-payment" onClick={AddCardHandler}>
              <i className="fa fa-plus mr-2"></i>
              Add payment method
            </Link>
            <div className="current-plan-div bottom-fixed-sidebar">
              <h4>Current plan</h4>
              <h2>{`${name} Package`}</h2>
              <h5>{`${currency === "USD" ? "$" : "R"} ${amount} (${duration})`}</h5>
              {payment ? (
                <Link
                  to="#"
                  style={{ cursor: "pointer" }}
                  className="upgrade-btn-biling"
                  onClick={planRenewalPaymentHandler}
                >
                  Pay Now
                </Link>
              ) : (
                <Link
                  to="#"
                  style={{ cursor: "pointer" }}
                  className="upgrade-btn-biling"
                  onClick={choosePlanModal}
                >
                  Upgrade
                </Link>
              )}
            </div>
          </div>
        </>
      )}
    </div>
    {state?.saveCard && 
      <SaveCard 
        open={state?.saveCard} 
        HideModal = {()=>setState(prev=>({...prev,saveCard:false}))}
      />
    }
    </>
  );
}

export default PaymentMethodSlider;
