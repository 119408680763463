import React, { useEffect } from "react";

function IncomingMeet({ Accept, Reject, _meetInstance }) {
  useEffect(() => {
    if (_meetInstance && _meetInstance.status === "ringing") {
      window.$("#call-incoming-meet").addClass("show");
    } else {
        window.$("#call-incoming-meet").removeClass("show");
    }
  }, [_meetInstance]);

  return (
    <div
      className="modal dailer-modal fade modal_call_popup in"
      id="call-incoming-meet"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-sm justify-content-end"
        role="document"
        style={{ flexDirection: "column", alignItems: "flex-end" }}
      >
        <div
          className="modal-content"
          style={{
            width: "auto",
            position: "absolute",
            bottom: "130px",
          }}
        >
          <div className="row">
            <div className=" col-md-12">
              <div className="call_window">
                <div className="col-md-12 ellipse-div">
                  <div className="caller-img">
                    <img
                      src={
                        _meetInstance?.profile_picture ??
                        "./../assets/images/Image.png"
                      }
                      alt="a"
                    />
                  </div>
                  <div className="caller-details">
                    <h2>Incoming call</h2>
                    <span className="cl-name-meet"></span>
                    <p className="cl-no-meet"></p>
                  </div>
                </div>
                <h6 className="text-center answer_call in-call-action-btn">
                  <button className="btn btn-success" onClick={Accept}>
                    Audio
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-info"
                    style={{ borderRadius: "25px" }}
                    onClick={() => Accept(true)}
                  >
                    Video
                  </button>
                  &nbsp;
                  <button className="btn btn-danger" onClick={Reject}>
                    Reject
                  </button>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncomingMeet;
