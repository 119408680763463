import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { store } from "../../Services";
import { DashboardDetails } from "../../Services/Actions/dashboardActions";
import { naviagteToSetting } from "../../Services/Actions/userActions";
import authService from "../../Utils/authService";
import { convertUtcToLocalTime } from "../../Utils/Common";
import { withRouter } from "../../Utils/withRouter"; 
import MonthlyCallReportGraph from "./MonthlyCallReportGraph";  
import Sidebar from "./Sidebar";

const initialState = {};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async() => {
    DashboardDetails("",store.dispatch)
    let interval = setInterval(async()=>{ 
      if(localStorage.getItem("deviceToken")){ 
        let res = await DashboardDetails({deviceToken:localStorage.getItem("deviceToken"),request_from:"web"},store.dispatch)
        if(res === 1){  
          clearInterval(interval);
        }
      } 
    },2000) 
  }

  timeSince = (datetime) => {
    let time = new Date(datetime);
    var seconds = Math.floor((new Date() - time) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }
    return "few seconds ago";
  };

  handleNotification = () => {
    this.props.navigate("/settings");
    naviagteToSetting({ tab: "notification" }) 
  };

  render() {
    const {
      dashbaord_details,
      notification_listing,
      user_details: { did_numbers, user_plan },
    } = this.props;
    let recentNotificationData =
      notification_listing && notification_listing[0];
    let recentNotificationTime = convertUtcToLocalTime(
      recentNotificationData?.creationdate
    );
    return (
      <div className="right-side-wrapper pt-0 ">
        <div className="card">
          <div className="card-body p-0">
            <div className="row m-0">
              <Sidebar />
              <div className="col-md-8 col-lg-9">
                <div className="notification-main new-dash-board">
                  <h2>Welcome Back!</h2>
                  <div className="row">
                    <div className="col-md-12 col-lg-7">
                      <div className="row dash-data-bx-row">
                        <div className="col-md-4">
                          <div className="call-data">
                            <span className="dash-data-bx">
                              <img
                                src="./../assets/images/total_calls.svg"
                                alt="1"
                              />
                              <h5>
                                {dashbaord_details?.Total?.total_calls ?? 0}
                              </h5>
                            </span>
                            <h6>Total Calls</h6>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="call-data bg-pink">
                            <span className="dash-data-bx">
                              <img
                                src="./../assets/images/total_message_dash.svg"
                                alt="1"
                              />
                              <h5>0</h5>
                            </span>
                            <h6>Total Messages</h6>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="call-data bg-blue">
                            <span className="dash-data-bx">
                              <img
                                src="./../assets/images/total_video_calls_meet.svg"
                                alt="1"
                              />
                              <h5>
                                {dashbaord_details?.Total
                                  ?.total_meeting_count ?? 0}
                              </h5>
                            </span>
                            <h6>Total Meetings</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5"></div>
                  </div>
                  <div className="dash-graph-table-height">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="tab-content">
                          <div className="tab-pane show tab-pane-scroll active">
                            <table className="table table-hover table-center mb-0 dashboard-notification-table">
                              <tbody>
                                <tr>
                                  <td width="100%">
                                    <div
                                      className="row all-message-email-row"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => this.handleNotification()}
                                    >
                                      <div className="text-center align-self-center notification-dash-img">
                                        <img
                                          src="./../assets/images/dashboard-notification-bell-ico.svg"
                                          alt="1"
                                        />
                                      </div>
                                      <div className="align-self-center">
                                        <h3>Notifications</h3>
                                        <p>
                                          {notification_listing
                                            ? this.timeSince(
                                                recentNotificationTime
                                              )
                                            : "No Notifications"}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td width="15%" align="center"></td>
                                  <td
                                    className="dashboard-notification-dot"
                                    width="10%"
                                    align="center"
                                  >
                                    <Link to="#">
                                      {" "}
                                      <i className="fas fa-ellipsis-h"></i>
                                    </Link>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dash-chart-div">
                      <MonthlyCallReportGraph
                        callData={dashbaord_details?.monthly_call}
                      />
                    </div>
                  </div> 
                </div>
                {/* bottom */}
                {/* <div className="dash-board-bottom">
                  <div className="dashboard-bottom-line"></div>
                  <span className="dashboad-number-btn">
                    {did_numbers?.length ?? 0}
                    <span>Numbers</span>
                  </span>
                  <span className="search-dash-btn">
                    <span className="search-dash-btn-inner">
                      <img src="./../assets/images/search-dash.svg" alt="1" />
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Quick Search"
                      />
                    </span>
                  </span>
                  <span className="dashboad-user-btn">
                    {user_plan?.users ?? 0}
                    <span>Users</span>
                  </span>
                </div> */}
                {/* bottom end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashbaord_details: state?.dashboard?.dashbaord_details ?? [],
    notification_listing: state?.notification?.notification_listing ?? [],
    user_details: state?.user?.user_details?.userDetail ?? {},
  };
};
export default connect(mapStateToProps)(withRouter(Dashboard));
