import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { store } from "../../Services";
import { SelectedChatUser, SelectedGroupChatUser } from "../../Services/Actions/callActions";  
import { formatDIDNumber, sortNumericKeys } from "../../Utils/Common";

const initialState = {
    __activeKey: ""
};

class Sidebar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    StartChat = (__item,profile) => {
        const { ActiveTab } = this.props;
        const { type } = __item;
        if (type === "individual") {
            const { displayName, recipient, phone } = __item;
            let userData = {
                profile_picture: profile,
                phone,
                name: displayName,
                uniquecode: recipient
            }
            SelectedChatUser(userData,store.dispatch);
            ActiveTab("one_to_one_chat")
        } else {
            let { displayName: name, participants, roomName: uniquekey } = __item;
            let data = { participants, name, uniquekey, profile: "" };
            SelectedGroupChatUser(data,store.dispatch);
            ActiveTab("group_chat");
        } 
    }

    SMSHandler = (value)=>{
        this.props.ActiveTab("sms")
        this.props._UserChatHistory(value) 
    }

    render() {
        const { ActiveTab, __activeTab, __teams, __contacts, __favourites, sms_chat_list,OpenSmsModal,extension_listing } = this.props; 
        const { __activeKey } = this.state;
        return (
            <div className="col-md-4 col-lg-3 pl-0">
                <div className="mid-sidebar">
                    <div className="mid-nav-inner bg-gray p-0">
                        <div className="mid-nav pt-4 phone-system-menus">
                            <div className="online-store welcome-mid-side">
                                <h5 className={__activeTab === "chat_listing" ? "active" : ""} onClick={() => ActiveTab("chat_listing")}><img src="./../assets/images/message.png" alt="1" />Messages</h5>
                                <h5 className={__activeTab === "contacts" ? "active" : ""} onClick={() => ActiveTab("contacts")}><img src="./../assets/images/add-contact.png" alt="1" />Contacts</h5>
                                <h5 className={__activeTab === "team_list" ? "active" : ""} onClick={() => ActiveTab("team_list")}><img src="./../assets/images/team-chat.png" alt="1" />Teams</h5>
                                <h5 className={__activeTab === "sms" ? "active" : ""} onClick={() => ActiveTab("sms_list")}><img src="./../assets/images/sms.png" alt="1" />SMS</h5>
                            </div>
                            {/** BOTTOM BAR */}
                            <div className="online-store all-calls all-messages"> 
                                <ul className="list-unstyled mid-components m-0 calls-menu-ul">
                                    <li>
                                        <a
                                            href="#fav-Contacts"
                                            data-toggle="collapse"
                                            aria-expanded="false"
                                            className="dropdown-toggle collapsed"
                                        >
                                            <h5 className="">
                                                <span className="menu-img">
                                                    <img
                                                        src="./../assets/images/fav-green-star.svg"
                                                        alt="1"
                                                    />
                                                </span>
                                                FAVOURITES 
                                                <span className="collapse-arrow">
                                                    <img
                                                        src="./../assets/images/menu-arrow-up.svg"
                                                        alt="1"
                                                    />
                                                </span>
                                            </h5>
                                        </a>
                                        <ul
                                            className="list-unstyled collapse"
                                            id="fav-Contacts"
                                        >
                                            {__favourites && __favourites.map((value, index) => { 
                                                const {recipient} = value
                                                let profile = extension_listing && extension_listing.find(value=>value.uniquecode === recipient)?.profile_picture
                                                return (
                                                    <li key={`fav_${index}`}>
                                                        <a
                                                            className={__activeKey === value.roomName ? "active" : ""}
                                                            href={void 0}
                                                            onClick={() => this.StartChat(value,profile)}
                                                        >
                                                            {value.displayName}
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                    <li>
                                          <span
                                                className="menu-plus-ico webphone-btns"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                            >
                                                <img
                                                    src="./../assets/images/add-cont.svg"
                                                    alt="1"
                                                />
                                            </span>
                                        <a
                                            href="#direct-messages"
                                            data-toggle="collapse"
                                            aria-expanded="false"
                                            className="dropdown-toggle collapsed"
                                        >
                                            <h5 className="">
                                                <span className="menu-img">
                                                    <img
                                                        src="./../assets/images/direct-message-ico.svg"
                                                        alt="1"
                                                    />
                                                </span>
                                                DIRECT MESSAGES 
                                                <span className="collapse-arrow">
                                                    <img
                                                        src="./../assets/images/menu-arrow-up.svg"
                                                        alt="1"
                                                    />
                                                </span>
                                            </h5>
                                        </a>
                                        <ul
                                            className="list-unstyled collapse"
                                            id="direct-messages"
                                        >
                                            {__contacts && __contacts.map((value, index) => {
                                                const {recipient} = value
                                                let profile = extension_listing && extension_listing.find(value=>value.uniquecode === recipient)?.profile_picture
                                                return (
                                                    <li key={`ind_${index}`}>
                                                        <a
                                                            className={__activeKey === value.roomName ? "active" : ""}
                                                            href={void 0}
                                                            onClick={() => this.StartChat(value,profile)}
                                                        >
                                                            {value.displayName}
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                    <li>
                                        <span
                                            className="menu-plus-ico webphone-btns"
                                            data-toggle="modal"
                                            data-target="#exampleModal1"
                                        >
                                            <img
                                                src="./../assets/images/add-cont.svg"
                                                alt="1"
                                            />
                                        </span>
                                        <a
                                            href="#teams"
                                            data-toggle="collapse"
                                            aria-expanded="false"
                                            className="dropdown-toggle collapsed"
                                        >
                                            <h5 className="">
                                                <span className="menu-img">
                                                    <img
                                                        src="./../assets/images/call/team.svg"
                                                        alt="1"
                                                    />
                                                </span>
                                                TEAMS 
                                                <span className="collapse-arrow">
                                                    <img
                                                        src="./../assets/images/menu-arrow-up.svg"
                                                        alt="1"
                                                    />
                                                </span>
                                            </h5>
                                        </a>
                                        <ul
                                            className="list-unstyled collapse"
                                            id="teams"
                                        >
                                            {__teams && __teams.filter(value=>value.type==="group").map((value, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a
                                                            className={__activeKey === value.roomName ? "active" : ""}
                                                            href={void 0}
                                                            onClick={() => this.StartChat(value)}
                                                        >
                                                            {value.displayName}
                                                        </a>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    </li>
                                    <li>
                                        <a
                                            href="#meetings"
                                            data-toggle="collapse"
                                            aria-expanded="false"
                                            className="dropdown-toggle collapsed"
                                        >
                                            <h5 className="">
                                                <span className="menu-img">
                                                    <img
                                                        src="./../assets/images/video-meet-cio.svg"
                                                        alt="1"
                                                    />
                                                </span>
                                                Meetings 
                                                <span className="collapse-arrow">
                                                    <img
                                                        src="./../assets/images/menu-arrow-up.svg"
                                                        alt="1"
                                                    />
                                                </span>
                                            </h5>
                                        </a>
                                        <ul
                                            className="list-unstyled collapse"
                                            id="meetings"
                                        >
                                            {__teams && __teams.filter(value=>value.type==="meet").map((value, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a
                                                            className={__activeKey === value.roomName ? "active" : ""}
                                                            href={void 0}
                                                            onClick={() => this.StartChat(value)}
                                                        >
                                                            {value.displayName}
                                                        </a>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    </li>
                                    <li>
                                         <span
                                            className="menu-plus-ico webphone-btns" onClick={()=>OpenSmsModal()}
                                        >
                                            <img
                                                src="./../assets/images/add-cont.svg"
                                                alt="1"
                                            />
                                        </span>
                                        <a
                                            href="#sms"
                                            data-toggle="collapse"
                                            aria-expanded="false"
                                            className="dropdown-toggle collapsed"
                                        >
                                            <h5 className="">
                                                <span className="menu-img">
                                                    <img
                                                        src="./../assets/images/sms-min.png"
                                                        alt="1"
                                                    />
                                                </span>
                                                SMS 
                                                <span className="collapse-arrow">
                                                    <img
                                                        src="./../assets/images/menu-arrow-up.svg"
                                                        alt="1"
                                                    />
                                                </span>
                                            </h5>
                                        </a>
                                        <ul
                                            className="list-unstyled collapse"
                                            id="sms"
                                        >
                                            {sms_chat_list && sms_chat_list.map((value, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link
                                                            to="#"
                                                            onClick={()=>this.SMSHandler(value)}
                                                        >
                                                            {value.user_name ? value.user_name : formatDIDNumber(value.users)}
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Sidebar;
