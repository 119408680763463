import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RenderProp from "../../../Component/Modal/RenderProp";
import Schedule from "../../../Component/Modal/Schedule";
import Pagination from "../../../Component/Pagination";
import { store } from "../../../Services";
import {
  ForceCall,
  SelectedChatType,
  SelectedChatUser,
} from "../../../Services/Actions/callActions";
import { StartMeet } from "../../../Services/Actions/meetActions";
import { removeBackdrop } from "../../../Utils/Common";
import { withRouter } from "../../../Utils/withRouter";
import MessageContactModal from "../SMS/MessageContactModal";

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageOfItems: [],
      number: "",
      show: false,
      modal: "",
      member: "",
    };
  }

  FilterTeamMember = (member) => {
    const { pageOfItems } = this.state;
    const { participants } = this.props;
    let participantsListing = participants?.participants ?? [];
    let updatedList = pageOfItems.filter((value) => {
      return value.name.toLowerCase().search(member) !== -1;
    });
    this.setState({
      pageOfItems: member ? updatedList : participantsListing.slice(0, 5),
    });
  };

  closeModal = () => {
    this.setState({ show: false }, () => removeBackdrop());
  };

  UpdateData = (pageOfItems) => {
    this.setState({ pageOfItems });
  };

  startChat = (value) => {
    let userData = {
      phone: value?.phone ?? "",
      uniquecode: value?.uniquecode ?? "",
      name: value?.name ?? "",
      profile_picture: value?.profile_picture ?? "",
    };

    SelectedChatUser(userData,store.dispatch) 
    SelectedChatType("one_to_one_chat",store.dispatch)
    this.props.navigate("/chat");
  };

  FilterTeamMemberHandler = (e) => {
    this.setState(
      {
        member: e.target.value,
      },
      () => {
        this.FilterTeamMember(e.target.value);
      }
    );
  };

  render() {
    const { number, show, modal, pageOfItems, member } = this.state;
    const { participants,extension_listing } = this.props;
    let participantsListing = participants?.participants ?? [];

    return (
      <>
        <div className="col-md-8 col-lg-9">
          <div className="right-side-top-header">
            <h5>{participants?.name}</h5>
            <span className="filter-serach">
              <img src="./../assets/images/filter.svg" alt="1" />
              <input
                type="text"
                className="form-control"
                placeholder="Filter team member"
                name="member"
                value={member}
                onChange={(e) => this.FilterTeamMemberHandler(e)}
              />
            </span>
          </div>
          <div className="tab-content">
            <div className="tab-pane call-info-scroll single-table-height show active">
              <div className="table-responsive calls-table calls-table-hover">
                <table className="table table-hover table-center mb-0">
                  <tbody>
                    {pageOfItems &&
                      pageOfItems.map((value, key) => {
                        const profile_image = extension_listing?.find(val=>val.customerID === value.customerID)?.profile_picture??""
                        return (
                          <tr key={key}>
                            <td>
                              <div className="d-flex all-message-email-row">
                                <div className="text-center align-self-center caller-img nxa-contact">
                                  {!profile_image && (
                                    <h6>
                                    {value.name && value.name
                                      .match(/\b(\w)/g)
                                      .join("")
                                      .toUpperCase()}
                                  </h6>
                                  )}
                                  {profile_image && (
                                    <img src={profile_image} alt="a" />
                                  )} 
                                </div>
                                <div className="align-self-center caller-name-number">
                                  <h3>{value.name} <i className="italic-font-color">{`(${value.user_extension})`}</i></h3>
                                  <p>{value.phone}</p>
                                </div>
                              </div>
                            </td>
                            <td align="right">
                              <div className="recentcall-img-ico allcall-img-ico">
                                <Link
                                  to="#"
                                  title="Audio Call"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => ForceCall(value.phone,store.dispatch)}
                                >
                                  <img
                                    src="./../assets/images/calls-call-ico.svg"
                                    alt="1"
                                  />
                                </Link>
                                <Link
                                  to="#"
                                  title="Video Call"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => StartMeet(value.phone)}
                                >
                                  <img
                                    src="./../assets/images/call-video.svg"
                                    alt="1"
                                  />
                                </Link>
                                <Link
                                  to="#"
                                  title="Chat"
                                  onClick={() => this.startChat(value)}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src="./../assets/images/call-chat-ico.svg"
                                    alt="1"
                                  />
                                </Link>
                                <Link
                                  to="#"
                                  title="Schedule Meet"
                                  onClick={() =>
                                    this.setState(
                                      {
                                        modal: "",
                                      },
                                      () => {
                                        this.setState({
                                          modal: "schedule_meet",
                                          show: true,
                                        });
                                      }
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src="./../assets/images/call-schdule.svg"
                                    alt="1"
                                  />
                                </Link>
                                <Link
                                  to="#"
                                  title="Send SMS"
                                  onClick={() =>
                                    this.setState({
                                      show: true,
                                      number: value.phone,
                                      modal: "message-contact",
                                    })
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src="./../assets/images/email-contact.svg"
                                    alt="1"
                                  />
                                </Link>
                                <Link to="#">
                                  <img
                                    src="./../assets/images/call-menu-ico.svg"
                                    alt="1"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {participantsListing && participantsListing.length === 0 && (
                      <tr>
                        <td colSpan="2" className="text-center">
                          No members found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination
            data={participantsListing}
            currentData={this.UpdateData}
          />
        </div>

        {modal === "message-contact" && show && (
          <MessageContactModal
            OpenMessageModal={show}
            closeMessageContactModal={this.closeModal}
            destination_number={number}
          />
        )}

        {modal === "schedule_meet" && show && (
          <RenderProp>
            {({ UpdateTopic, extension_listing }) => (
              <Schedule
                UpdateTopic={UpdateTopic}
                extension_listing={extension_listing}
              />
            )}
          </RenderProp>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  extension_listing:state?.setting?.extension_listing ?? [],
});

export default connect(mapStateToProps)(withRouter(Team));
