import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { store } from "../../../../Services";
import { TeamListing } from "../../../../Services/Actions/callActions";
import { DidNumberListing } from "../../../../Services/Actions/reportActions";
import {
  BusinessHourListing,
  IVRGreetingList,
  IVRMenuList,
  NumberForwardCreateUpdate,
} from "../../../../Services/Actions/settingActions";
import { formatDIDNumber, removeBackdrop } from "../../../../Utils/Common";
import VoiceFileSlider from "./../VoiceFiles/VoiceFileSlider";

let keymap = {};
let forward = {};
let keyMapData = {};
[...Array(10).keys()].map((v, i) => {
  let n = "keymap" + v;
  keymap[n] = "";
  forward["forward_to" + n] = "";
  keyMapData["keyMapData" + n] = "";
  return null;
});
let initialState = {
  collapsed: false,
  onholdIvrId: "",
  afterhour_greeting: "",
  name: "",
  business_hour: "",
  did_number: "",
  business_type: "24hrs",
  show: false,
  ivrID: "",
  ivr_option: [],
  validateCallIVR: [0],
  uniquecode: null,
  leaveVoicemail: false,
  time_condition_uuid: null,
  id: null,
  beginningGreeting: false,
  hideIvrErrors: true,
};
Object.assign(initialState, keymap, forward, keyMapData);

class AddIVRCallFlow extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = async () => {
    BusinessHourListing(0, store.dispatch);
    IVRGreetingList(store.dispatch);
    TeamListing(0, store.dispatch);
    DidNumberListing(store.dispatch);
  };

  componentDidUpdate(prevProps) {
    const { editIVR } = this.props;
    const { editIVR: oldIVR } = prevProps;
    const { uniquecode } = this.state;
    if (editIVR && Object.keys(editIVR).length && oldIVR !== editIVR) {
      const {
        leaveVoicemail,
        name,
        business_hour_type: business_type,
        assigned_number: did_number,
        business_hour_id: business_hour,
        greeting: ivrID,
        uniquecode,
        afterhour_greeting,
        onholdIvrId,
        ivr_option,
        time_condition_uuid,
        id,
      } = editIVR;
      let RestStates = {};
      let validateCallIVR = [];
      if (ivr_option) {
        JSON.parse(ivr_option).forEach((value, index) => {
          let { ivr_option_digit, ivr_option_extension, forward_to } = value;
          RestStates = {
            ...RestStates,
            validateCallIVR: [...validateCallIVR, index],
            [`keymap${index}`]: ivr_option_digit,
            [`forward_to${index}`]: forward_to,
            [`keyMapData${index}`]: ivr_option_extension,
          };
        });
      }
      this.setState({
        collapsed: true,
        leaveVoicemail: leaveVoicemail === "Y" ? true : false,
        name,
        forward_to: "IVR",
        business_type,
        did_number,
        business_hour,
        ivrID,
        uniquecode,
        afterhour_greeting,
        time_condition_uuid,
        id,
        onholdIvrId,
        beginningGreeting: ivrID ? true : false,
        ...RestStates,
      });
    } else if (editIVR && !Object.keys(editIVR).length && uniquecode) {
      this.setState(initialState);
    }
  }

  CloseModal = () => {
    this.setState({ show: false }, () => removeBackdrop());
  };

  validateIvrForm = (e, id) => {
    let validate = this.validator;
    if (
      validate.fieldValid(`keymap${id}`) &&
      validate.fieldValid(`forward_to${id}`) &&
      validate.fieldValid(`keyMapData${id}`)
    ) {
    } else {
      validate.showMessages();
      this.forceUpdate();
    }

    if (e.target.name === "forward_to" + id) {
      this.setState({
        [e.target.name]: e.target.value,
        [`keyMapData${id}`]: "",
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  addKeyMapInput = (e, id) => {
    this.setState((prevState) => ({
      validateCallIVR: [...prevState.validateCallIVR, id],
    }));
    window.$("#" + id).removeClass("hide");
  };

  removeKeyMapInput = (e, id) => {
    window.$("#" + id).addClass("hide");
    this.state.validateCallIVR.splice(id);
    this.setState({
      [`keymap${id}`]: "",
      [`forward_to${id}`]: "",
      [`keyMapData${id}`]: "",
    });
  };

  createIVRHandler = () => {
    let validate = this.validator;
    const { closeAddIVR } = this.props;
    this.setState({ hideIvrErrors: false });
    if (validate.allValid()) {
      let ivr_option = [];
      [...Array(10)].map((e, i) => {
        if (this.state[`keymap${i}`] !== "") {
          ivr_option.push({
            ivr_option_digit: this.state[`keymap${i}`],
            forward_to: this.state[`forward_to${i}`],
            ivr_option_extension: this.state[`keyMapData${i}`],
          });
        }
        return null;
      });

      this.setState(
        {
          ivr_option: ivr_option.filter((item) => item),
        },
        async () => {
          const {
            onholdIvrId,
            name,
            ivrID,
            ivr_option,
            business_hour,
            business_type,
            did_number,
            afterhour_greeting,
            leaveVoicemail,
            uniquecode,
            time_condition_uuid,
            id, 
            beginningGreeting
          } = this.state;

          let postData = {
            name,
            ivrfileID: beginningGreeting ? ivrID : "",
            business_hour_type: business_type,
            business_hourID: business_hour,
            did_number,
            ivr_option,
            forward_to: "IVR",
            afterhour_greeting,
            leaveVoicemail: leaveVoicemail ? "Y" : "N",
            type: "ivr",
            onholdIvrId,
          }; 

          if (uniquecode && uniquecode !== "") {
            postData = {
              ...postData,
              uniquecode,
              time_condition_uuid,
              id,
              type: "ivr",
            };
          }
          let res = await NumberForwardCreateUpdate(
            postData,
            uniquecode && uniquecode !== "",
            "ivr",
            store.dispatch
          );
          if (res === 1) {
            IVRMenuList({}, store.dispatch);
            DidNumberListing(store.dispatch);
            closeAddIVR();
          }
        }
      );
    } else {
      validate.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    this.validator.purgeFields();
    const {
      viewOnly,
      closeAddIVR,
      addIVR,
      editIVR,
      businesshour_listing,
      unassigned_did_number_list,
      ivr_listing,
      extension_listing,
      team_listing,
    } = this.props;
    const {
      collapsed,
      onholdIvrId,
      name,
      business_hour,
      did_number,
      business_type,
      show,
      ivrID,
      afterhour_greeting,
      leaveVoicemail,
      beginningGreeting,
      hideIvrErrors,
    } = this.state;
    const filteredCustomBusinessHours =
      businesshour_listing &&
      businesshour_listing.filter(
        (value) => value.business_hour_type === "custom"
      ); 

    return (
      <>
        <div
          className={`manage-team-member-slide ${
            addIVR || (editIVR && Object.keys(editIVR).length)
              ? ""
              : "manage-team-member-slide-close"
          }`}
        >
          {(addIVR || (editIVR && Object.keys(editIVR).length)) && (
            <>
              <div className="member-slide-title">
                <h5>
                  {viewOnly
                    ? "IVR MENU"
                    : editIVR && Object.keys(editIVR).length
                    ? "Edit IVR Menu "
                    : "Add IVR Menu"}{" "}
                </h5>
                <img
                  src="./../assets/images/cross-add-modal.svg"
                  alt="1"
                  onClick={() => closeAddIVR()}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label"> </label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Name your IVR menu"
                  value={name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
                {this.validator.message("Name", name, "required")}
              </div>
              {/* add new ivr menu middle */}
              <div className="ivr-menu-side-mid-sec">
                <div className="collapse-ivr-list-div">
                  <a
                    className="ivr-collapse-anchor collapsed"
                    data-toggle="collapse"
                    href="#multiCollapseExample1"
                    role="button"
                    aria-expanded="false"
                    aria-controls="multiCollapseExample1"
                  >
                    Select business operation hours
                    <span className="collapse-arrow">
                      <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                    </span>
                  </a>
                  {business_type === "custom" &&
                    this.validator.message(
                      "Business operation hours",
                      business_hour,
                      "required"
                    )}
                  <div className="col p-1">
                    <div
                      className={`collapse ${collapsed ? "show" : ""}`}
                      id="multiCollapseExample1"
                    >
                      <div className="form-group business-hrs-tabs-side">
                        <div className="business-hrs-btn-group">
                          <Link
                            to="#"
                            className={`${
                              business_type === "24hrs" ? "active" : ""
                            }`}
                            onClick={() =>
                              this.setState({ business_type: "24hrs" })
                            }
                          >
                            Always Open
                          </Link>
                          <Link
                            to="#"
                            className={`${
                              business_type === "custom" ? "active" : ""
                            }`}
                            onClick={() =>
                              this.setState({ business_type: "custom" })
                            }
                          >
                            Custom
                          </Link>
                        </div>
                      </div>
                      {business_type === "custom" && (
                        <div className="card card-body">
                          <label>Select saved operating hours</label>
                          <select
                            className="form-control"
                            name="business_hour"
                            value={business_hour}
                            onChange={(e) =>
                              this.setState({ business_hour: e.target.value })
                            }
                          >
                            <option value="">Select Option</option>
                            {filteredCustomBusinessHours &&
                              filteredCustomBusinessHours.map(
                                (value, index) => {
                                  return (
                                    <option key={index} value={value.id}>
                                      {value.name}
                                    </option>
                                  );
                                }
                              )}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* ------ */}
                <div className="collapse-ivr-list-div">
                  <a
                    className="ivr-collapse-anchor collapsed"
                    data-toggle="collapse"
                    href="#multiCollapseExample2"
                    role="button"
                    aria-expanded="false"
                    aria-controls="multiCollapseExample2"
                  >
                    Select Number for the IVR
                    <span className="collapse-arrow">
                      <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                    </span>
                  </a>
                  {this.validator.message(
                    "Number for the IVR",
                    did_number,
                    "required"
                  )}
                  <div className="col p-1">
                    <div
                      className={`collapse ${collapsed ? "show" : ""}`}
                      id="multiCollapseExample2"
                    >
                      <div className="card card-body">
                        <label>Select IVR number</label> 
                          <select
                            className="form-control"
                            name="did_number"
                            value={did_number}
                            onChange={(e) =>
                              this.setState({ did_number: e.target.value })
                            }
                          >
                            <option value="">Select Option</option>
                            {editIVR && Object.keys(editIVR).length &&  
                              <option value={did_number}>{did_number}</option> 
                            }
                            {unassigned_did_number_list &&
                              unassigned_did_number_list.map((value, index) => {
                                return (
                                  <option key={index} value={value.did_number}>
                                    {value.did_number}
                                  </option>
                                );
                              })}
                          </select> 
                      </div>
                    </div>
                  </div>
                </div>
                {/* ------ */}
                <div className="collapse-ivr-list-div">
                  <a
                    className="ivr-collapse-anchor collapsed"
                    data-toggle="collapse"
                    href="#multiCollapseExample3"
                    role="button"
                    aria-expanded="false"
                    aria-controls="multiCollapseExample3"
                  >
                    Business Welcome Greeting
                    <span className="collapse-arrow">
                      <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                    </span>
                  </a>
                  {beginningGreeting
                    ? this.validator.message(
                        "Business welcome greeting",
                        ivrID,
                        "required"
                      )
                    : null}
                  <div className="col p-1">
                    <div
                      className={`collapse ${collapsed ? "show" : ""}`}
                      id="multiCollapseExample3"
                    >
                      <div className="card card-body">
                        <div className="form-check pl-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={beginningGreeting}
                            id="beginningGreeting"
                            onChange={(e) =>
                              this.setState({
                                beginningGreeting: e.target.checked,
                              })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="beginningGreeting"
                          >
                            This message will play at the beginning of the call
                          </label>
                        </div>
                        {beginningGreeting &&
                          <>
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="ivrID"
                              value={ivrID} 
                              onChange={(e) =>
                                this.setState({ ivrID: e.target.value })
                              }
                            >
                              <option value="">Select Option</option>
                              {ivr_listing &&
                                ivr_listing.map((value, index) => {
                                  return (
                                    <option key={index} value={value.id}>
                                      {value.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="text-custom-file-tabs">
                            <Link
                              to="#"
                              className="ivr-outline-btn"
                              onClick={() =>
                                beginningGreeting
                                  ? this.setState({ show: true })
                                  : null
                              }
                            >
                              Custom file
                            </Link>
                          </div>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                {/* ------ */}
                <div className="collapse-ivr-list-div">
                  <a
                    className="ivr-collapse-anchor collapsed"
                    data-toggle="collapse"
                    href="#multiCollapseExample6"
                    role="button"
                    aria-expanded="false"
                    aria-controls="multiCollapseExample6"
                  >
                    Select Music on hold
                    <span className="collapse-arrow">
                      <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                    </span>
                  </a> 
                  <div className="col p-1">
                    <div
                      className={`collapse ${collapsed ? "show" : ""}`}
                      id="multiCollapseExample6"
                    >
                      <div className="card card-body music-hold-card-body">
                        <h3>
                          The caller will hear this music while waiting for the
                          call to be answered.{" "}
                        </h3>
                        <div className="form-group">
                          <select
                            className="form-control"
                            name="onholdIvrId"
                            value={onholdIvrId}
                            onChange={(e) =>
                              this.setState({ onholdIvrId: e.target.value })
                            }
                          >
                            <option value=""> Select Option </option>
                            {ivr_listing &&
                              ivr_listing.map((value, index) => {
                                return (
                                  <option key={index} value={value.id}>
                                    {value.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="text-custom-file-tabs">
                          <Link
                            to="#"
                            className="ivr-outline-btn"
                            onClick={() => this.setState({ show: true })}
                          >
                            Custom file
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="collapse-ivr-list-div">
                  <a
                    className="ivr-collapse-anchor collapsed"
                    data-toggle="collapse"
                    href="#multiCollapseExample4"
                    role="button"
                    aria-expanded="false"
                    aria-controls="multiCollapseExample4"
                  >
                    IVR menu description
                    <span className="collapse-arrow">
                      <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                    </span>
                  </a>
                  <div className="col p-1">
                    <div
                      className={`collapse ${collapsed ? "show" : ""}`}
                      id="multiCollapseExample4"
                    >
                      <div className="card card-body">
                        <p>
                          When the caller presses 1, the call is forwarded to
                          the selected option. Select an option type and define
                          the selected number.
                        </p>
                        {[...Array(10).keys()].map((val, i) => (
                          <div
                            key={i}
                            className={`ivr-dis-menulist-repeat ${
                              i === 0
                                ? ""
                                : this.state[`keymap${i}`] !== ""
                                ? ""
                                : "hide"
                            }`}
                            id={i}
                          >
                            <div className="ivr-discription-contol-div">
                              <span className="key-no">
                                <label>Key No.</label>
                                <select
                                  className={`form-control ${
                                    this.state[`keymap${i}`] || hideIvrErrors
                                      ? ""
                                      : "ivr active"
                                  }`}
                                  value={this.state[`keymap${i}`]}
                                  name={`keymap${i}`}
                                  onChange={(e) => this.validateIvrForm(e, i)}
                                >
                                  <option value="#">#</option>
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                </select>
                              </span>
                              <span className="ivr-rule ml-2">
                                <label>Select IVR rule</label>
                                <select
                                  className={`form-control ${
                                    this.state[`forward_to${i}`] ||
                                    hideIvrErrors
                                      ? ""
                                      : "ivr active"
                                  }`}
                                  value={this.state[`forward_to${i}`]}
                                  name={`forward_to${i}`}
                                  onChange={(e) => this.validateIvrForm(e, i)}
                                >
                                  <option value="">Select Option</option>
                                  <option value="department">Team</option>
                                  <option value="extension">Team Member</option>
                                  <option value="phone">External Number</option>
                                </select>
                              </span>
                              <span className="plus-minus-span">
                                <i
                                  className="fas fa-plus"
                                  value={`${i + 1}`}
                                  onClick={(e) => this.addKeyMapInput(e, i + 1)}
                                ></i>

                                {i ? (
                                  <i
                                    className="fas fa-minus"
                                    value={`${i}`}
                                    to="#"
                                    onClick={(e) =>
                                      this.removeKeyMapInput(e, i)
                                    }
                                  ></i>
                                ) : null}
                              </span>
                            </div>
                            {/* ---- */}
                            {this.state[`forward_to${i}`] === "extension" ? (
                              <div className="ivr-discription-contol-div">
                                <span className="w-100 mt-2">
                                  <label>Select Team Member</label>
                                  <select
                                    className={`form-control  w-100 ${
                                      this.state[`keyMapData${i}`] ||
                                      hideIvrErrors
                                        ? ""
                                        : "ivr active"
                                    }`}
                                    value={this.state[`keyMapData${i}`]}
                                    name={`keyMapData${i}`}
                                    onChange={(e) => this.validateIvrForm(e, i)}
                                  >
                                    <option>Select Option</option>
                                    {extension_listing &&
                                      extension_listing.map((v, i) => (
                                        <option
                                          key={i}
                                          value={v.user_extension}
                                        >
                                          {" "}
                                          {`${v.name}(${v.user_extension})`}
                                        </option>
                                      ))}
                                  </select>
                                </span>
                              </div>
                            ) : this.state[`forward_to${i}`] ===
                              "department" ? (
                              <div className="ivr-discription-contol-div">
                                <span className="w-100 mt-2">
                                  <label>Select Team</label>
                                  <select
                                    className={`form-control  w-100 ${
                                      this.state[`keyMapData${i}`] ||
                                      hideIvrErrors
                                        ? ""
                                        : "ivr active"
                                    }`}
                                    value={this.state[`keyMapData${i}`]}
                                    name={`keyMapData${i}`}
                                    onChange={(e) => this.validateIvrForm(e, i)}
                                  >
                                    <option>Select Option</option>
                                    {team_listing &&
                                      team_listing.map((val, i) => (
                                        <option key={i} value={val.id}>
                                          {val.name}
                                        </option>
                                      ))}
                                  </select>
                                </span>
                              </div>
                            ) : this.state[`forward_to${i}`] === "phone" ? (
                              <div className="ivr-discription-contol-div">
                                <span className="w-100 mt-2">
                                  <label>External Number</label>
                                  <input
                                    className={`form-control  w-100 ${
                                      this.state[`keyMapData${i}`] ||
                                      hideIvrErrors
                                        ? ""
                                        : "ivr active"
                                    }`}
                                    value={this.state[`keyMapData${i}`]}
                                    name={`keyMapData${i}`}
                                    onChange={(e) => this.validateIvrForm(e, i)}
                                  />
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* ------ */}
                {business_type === "custom" && (
                  <div className="collapse-ivr-list-div">
                    <a
                      className="ivr-collapse-anchor collapsed"
                      data-toggle="collapse"
                      href="#multiCollapseExample5"
                      role="button"
                      aria-expanded="false"
                      aria-controls="multiCollapseExample5"
                    >
                      After-hours IVR message
                      <span className="collapse-arrow">
                        <img
                          src="./../assets/images/menu-arrow-up.svg"
                          alt="1"
                        />
                      </span>
                    </a>
                    {this.validator.message(
                      "Business welcome greeting",
                      afterhour_greeting,
                      "required"
                    )}
                    <div className="col p-1">
                      <div
                        className={`collapse ${collapsed ? "show" : ""}`}
                        id="multiCollapseExample5"
                      >
                        <div className="card card-body">
                          <p>
                            The caller will hear this message when they call
                            outside of this number’s business operating hours.
                          </p>
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="afterhour_greeting"
                              value={afterhour_greeting}
                              onChange={(e) =>
                                this.setState({
                                  afterhour_greeting: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Option</option>
                              {ivr_listing &&
                                ivr_listing.map((value, index) => {
                                  return (
                                    <option key={index} value={value.id}>
                                      {value.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="text-custom-file-tabs">
                            <Link
                              to="#"
                              className="ivr-outline-btn"
                              onClick={() => this.setState({ show: true })}
                            >
                              Custom file
                            </Link>
                          </div>
                          <h6 className="keep-recording-shot">
                            <img
                              src="./../assets/images/warning-ico.svg"
                              alt="1"
                            />
                            Keep the recording short as the caller has to listen
                            to the entire recording before the next stage.
                          </h6> 
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-check pl-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={leaveVoicemail}
                    id="defaultCheck2"
                    onClick={(e) =>
                      this.setState({
                        leaveVoicemail: e.target.checked,
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="defaultCheck2"
                  >
                    Allow the caller to leave a voicemail
                  </label>
                </div>
              </div>
              {!viewOnly && (
                <div className="bottom-btn-b-hrs bottom-btn-ivr">
                  <Link
                    to="#"
                    className="btn-draft btn"
                    onClick={() => closeAddIVR()}
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn-save btn"
                    onClick={this.createIVRHandler}
                  >
                    {editIVR && Object.keys(editIVR).length
                      ? "Update "
                      : "Add "}{" "}
                    your IVR
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
        <VoiceFileSlider addFile={show} closeFileSlider={this.CloseModal} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  businesshour_listing: state?.setting?.businesshour_listing ?? [],
  user_details: state?.user?.user_details?.userDetail ?? {},
  ivr_listing: state?.setting?.ivr_listing ?? [],
  extension_listing: state?.setting?.extension_listing ?? [],
  team_listing: state?.call?.team_listing ?? [],
  unassigned_did_number_list: state?.report?.unassigned_did_number_list ?? [],
});

export default connect(mapStateToProps)(AddIVRCallFlow);
