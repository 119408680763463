import React, { useEffect } from "react";
import { connect } from "react-redux";
import { store } from "../../Services";
import { CallDetails, RecentCallListing } from "../../Services/Actions/callActions"; 


const CallHistory = (props) => {
  const direction = {
    outbound: "cl-out.svg",
    inbound: "cl-in.svg",
    missed: "cl-cut.svg",
  };
  const dayName = {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    7: "Sun",
  };
  const { history, onChangeHandler, StartCall, DetectCaller, setInfoFrame } =
    props;

  useEffect(async() => {
     RecentCallListing(false,store.dispatch) 
  }, []);

  const callDetailsHandler = (value,Number)=>{ 
    setInfoFrame(Number);
    CallDetails(value,store.dispatch)
  }

  return (
    <div className="dialer-recent-list">
      <ul className="dialer-recent-list-ul">
        {history &&
          history.map((value, key) => {
            let nickName = `${
              value.contact_firstname ? value.contact_firstname?.toUpperCase()?.charAt(0) : "Unknown"?.charAt(0)
            }${value.contact_lastname?.toUpperCase()?.charAt(0) ?? ""}`;
            let fullName = value.contact_firstname
              ? `${value.contact_firstname} ${value.contact_lastname}`
               :"Unknown"
            let Number =
              value.direction === "outbound"
                ? value.destination_number
                : value.caller_id_number;
            let directionImg =
              value.direction === "outbound"
                ? direction.outbound
                : value.missed_call === "1"
                ? direction.missed
                : direction.inbound;
            let startDate = new Date(value.start_stamp);
            let getDayName = dayName[startDate.getDay()];
            return (
              <li
                key={`history${key}`}
                onClick={() =>
                  onChangeHandler({ target: { name: "number", value: Number } })
                }
                onDoubleClick={() => {
                  StartCall(Number);
                  DetectCaller();
                  onChangeHandler({
                    target: { name: "history", value: false },
                  });
                }}
              >
                <div className="contact-img mr-2">
                  {value.profile_picture ? (
                    <img src={value.profile_picture} alt="profile" />
                  ) : (
                    <h3>{nickName}</h3>
                  )}
                </div>
                <div className="contact-name-div">
                  <h4>{fullName}</h4>
                  <p>{Number}</p>
                </div>
                <div className="call-type-ico">
                  <img src={`./../assets/images/${directionImg}`} alt="1" />
                </div>
                <div className="call-time">
                  <h6>{getDayName}</h6>
                </div>
                <div className="call-time">
                  <a
                    href={void 0} 
                    onClick={()=>callDetailsHandler(value,Number)}
                  >
                    <i className="fa fa-ellipsis-v" />
                  </a>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    history: state?.call?.recent_call_list??[],
  };
};

export default connect(mapStateToProps)(CallHistory);
