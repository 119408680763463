import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom"; 
import {
  CallDetails,
  ForceCall,
  RecentCallListing,
  SelectedChatType,
  SelectedChatUser,
} from "../../../Services/Actions/callActions";
import { convertUtcToLocalTime, removeBackdrop, SecondsTohhmmss } from "../../../Utils/Common";
import Pagination from "../../../Component/Pagination";
import ContactSlider from "../NxaContacts/ContactSlider";
import TeamMemberSlider from "../Team/TeamMemberSlider";
import AssignedModal from "./AssignedModal";
import MessageContactModal from "../SMS/MessageContactModal";
import { connect } from "react-redux";
import { withRouter } from "../../../Utils/withRouter";
import { store } from "../../../Services";


class MissedCalls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageOfItems: [],
      show: false,
      number: "",
      modal_type: "",
      search_call: "",
      addContact: false,
      addTeamMember: false,
    };
  }

  closeModal = () => {
    this.setState({ show: false }, () => removeBackdrop());
  };

  UpdateData = (pageOfItems) => {
    this.setState({ pageOfItems });
  };

  FilterCallHistory = (search_call) => {
    const { pageOfItems } = this.state;
    const { recent_call_list } = this.props;
    const filtered = pageOfItems.filter((value) => {
      let number =
        value.direction === "inbound"
          ? value.caller_id_number
          : value.destination_number;
      let name = `${value.contact_firstname} ${value.contact_lastname}`;
      return (
        (number && number.trim().toLowerCase().includes(search_call)) ||
        (name && name.trim().toLowerCase().includes(search_call))
      );
    });
    this.setState({
      pageOfItems: search_call ? filtered : recent_call_list.slice(0, 5),
    });
  };

  addMemberModalOpen = () => {
    this.setState({
      addTeamMember: true,
    });
  };

  StartChat = async (value) => {
    let number =
      value.direction === "inbound"
        ? value.caller_id_number
        : value.destination_number;
    let name = value.contact_firstname
      ? `${value.contact_firstname} ${value.contact_lastname}`
      : "";
    let userData = {
      profile_picture: value?.profile_picture ?? "",
      phone: number,
      name,
      uniquecode: value?.contact_uuid ?? "",
    }; 
    SelectedChatUser(userData,store.dispatch)
    SelectedChatType("one_to_one_chat",store.dispatch) 
    this.props.navigate("/chat");
  };

  closeAddContactSlider = () => {
    this.setState({ addContact: false });
  };
  closeTeamMemberSlider = () => {
    this.setState({ addTeamMember: false });
  };

  callDetailHandler = (value) => {
    this.props.SetTab("call info");
    CallDetails(value,store.dispatch);
  };

  FilterCallHistoryHandler = (e)=>{
      this.setState({
        search_call: e.target.value,
      },()=>{
        this.FilterCallHistory(e.target.value);
      })    
  }

  render() {
    const {
      pageOfItems,
      show,
      number,
      modal_type,
      search_call,
      addContact,
      addTeamMember,
    } = this.state;
    const { SetTab, recent_call_list } = this.props;
    return (
      <>
        <div className="col-md-8 col-lg-9">
          <div className="right-side-top-header">
            <h5>Missed Calls</h5>
            <div className="call-filter-refresh-div">
              <Link to="#" title="Refresh" onClick={() => RecentCallListing(true,store.dispatch)}>
                <img src="./../assets/images/refresh-ico.svg" alt="1" />
              </Link>
              <span className="filter-serach">
                <img src="./../assets/images/filter.svg" alt="1" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter call history"
                  name="search_call"
                  value={search_call}
                  onChange={e=>this.FilterCallHistoryHandler(e)} 
                />
              </span>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane call-info-scroll single-table-height show active">
              <div className="table-responsive calls-table calls-table-hover">
                <table className="table table-hover table-center mb-0">
                  <tbody>
                    {pageOfItems &&
                      pageOfItems.map((value, index) => {
                        if (value) {
                          let date_time = convertUtcToLocalTime(
                            value.start_stamp
                          );
                          let formattedDate = moment(date_time)
                            .locale("en")
                            .format("ll");
                          let formattedTime = moment(date_time)
                            .locale("en")
                            .format("LT"); 
                          let toShow =
                            value.direction === "inbound"
                              ? value.caller_id_number
                              : value.destination_number;
                          return (
                            <tr key={index}>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => this.callDetailHandler(value)}
                              >
                                <div className="d-flex all-message-email-row">
                                  <div className="text-center align-self-center caller-img">
                                    <img
                                      src={
                                        value.profile_picture
                                          ? value.profile_picture
                                          : "./../assets/images/Mask.png"
                                      }
                                      alt="1"
                                    />
                                  </div>
                                  <div className="align-self-center caller-name-number">
                                    <h3>
                                      {`${
                                        value.contact_firstname
                                          ? `${value.contact_firstname} ${value.contact_lastname}`
                                          : "Unknown"
                                      }`}
                                    </h3>
                                    <p> {toShow} </p>
                                  </div>
                                </div>
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => this.callDetailHandler(value)}
                              >
                                <div className="align-self-center call-type-td">
                                  <h3 className={value.direction === "inbound" && value.billsec === 0 ? "text-red" : ""}>
                                    <img
                                      src={
                                        value.direction === "inbound" && value.billsec === 0
                                          ? "./../assets/images/cl-cut.svg"
                                          : value.direction === "inbound" && value.billsec > 0
                                          ? "./../assets/images/cl-in.svg"
                                          : "./../assets/images/cl-out.svg"
                                      }
                                      alt="1"
                                    />
                                    {value.direction === "inbound" && value.billsec === 0
                                      ? "Missed"
                                      : value.direction === "inbound" && value.billsec > 0
                                      ? "Inbound Call"
                                      : "Outbound Call"}
                                  </h3> 
                                  <p>
                                    {SecondsTohhmmss(value.billsec)}
                                  </p> 
                                </div>
                              </td>
                              <td align="center">
                                {/* {value.direction === "inbound" && value.billsec === 0 && (
                                  <Link
                                    to="#"
                                    title="Assign Contact"
                                    onClick={() =>
                                      this.setState({
                                        modal_type: "assigned_number",
                                        show: true,
                                        value: toShow,
                                      })
                                    }
                                  >
                                    <img
                                      src="./../assets/images/assign-to-ico.svg"
                                      alt="1"
                                    />
                                  </Link>
                                )} */}
                              </td>
                              <td
                                align="right"
                                style={{
                                  width: "20%",
                                }}
                              >
                                <Link
                                  to="#"
                                  className="text-grey calls-date-time"
                                >
                                  {`${formattedDate} ${formattedTime}`}
                                </Link>
                                <div className="recentcall-img-ico allcall-img-ico">
                                  {value.is_user === "N" && (
                                    <>
                                      <Link
                                        to="#"
                                        title="Add Contact"
                                        onClick={() =>
                                          this.setState({
                                            number: toShow,
                                            addContact: true,
                                          })
                                        }
                                      >
                                        <img
                                          src="./../assets/images/call-add-contact.svg"
                                          alt="1"
                                        />
                                      </Link>
                                      <Link
                                        to="#"
                                        title="SMS"
                                        onClick={() =>
                                          this.setState({
                                            number: toShow,
                                            show: true,
                                            modal_type: "send-sms",
                                          })
                                        }
                                      >
                                        <img
                                          src="./../assets/images/email-contact.svg"
                                          alt="1"
                                        />
                                      </Link>
                                    </>
                                  )}
                                  {value.is_user === "Y" && (
                                    <Link
                                      to="#"
                                      title="Chat"
                                      onClick={() => this.StartChat(value)}
                                    >
                                      <img
                                        src="./../assets/images/call-chat-ico.svg"
                                        alt="1"
                                      />
                                    </Link>
                                  )}
                                  <Link
                                    to="#"
                                    title="Audio Call"
                                    onClick={() => ForceCall(toShow,store.dispatch)}
                                  >
                                    <img
                                      src="./../assets/images/calls-call-ico.svg"
                                      alt="1"
                                    />
                                  </Link>
                                  <Link to="#">
                                    <img
                                      src="./../assets/images/call-menu-ico.svg"
                                      alt="1"
                                    />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}

                    {pageOfItems && pageOfItems.length === 0 && (
                      <div className="text-center w-100">
                        <h5>No missed calls</h5>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination data={recent_call_list} currentData={this.UpdateData} />
        </div>
        <ContactSlider
          addContact={addContact}
          closeAddContactSlider={this.closeAddContactSlider}
          phone={number}
          renderPage={"all_calls"}
        />
        <TeamMemberSlider
          addTeamMember={addTeamMember}
          closeTeamMemberSlider={this.closeTeamMemberSlider}
        />

        {modal_type === "assigned_number" && show && (
          <AssignedModal
            OpenAssignModal={show}
            destination_number={number}
            closeAssignModal={this.closeModal}
            addMemberModalOpen={this.addMemberModalOpen}
          />
        )}

        {modal_type === "send-sms" && show && (
          <MessageContactModal
            OpenMessageModal={show}
            closeMessageContactModal={this.closeModal}
            destination_number={number}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  recent_call_list: state?.call?.recent_call_list.filter(
    (value) => value?.direction === "inbound" && value.billsec === 0 || null
  ),
});

export default connect(mapStateToProps)(withRouter(MissedCalls));
