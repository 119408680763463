import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { store } from "../../Services";
import { OnBoardProcessStatics } from "../../Services/Actions/dashboardActions";
import { ForceOpenDialPad } from "../../Services/Actions/userActions";
import swal from "sweetalert";
import OnBoardProcess from "./OnBoardProcess";
import ContactSlider from "../Calls/NxaContacts/ContactSlider";
import TeamMemberSlider from "../Calls/Team/TeamMemberSlider";
import AddBusinessHour from "../Settings/PhoneSystem/BusinessHour/AddBusinessHour";
import CallFlowSlider from "../Settings/PhoneSystem/CallFlow/CallFlowSlider";
import authService from "../../Utils/authService";

function Sidebar({ onboard_process_statics }) {
  const [addContact, setAddContact] = useState(false);
  const [addTeamMember, setAddTeamMember] = useState(false);
  const [addBusinessHour, setAddBusinessHour] = useState(false);
  const [openCallFlowSlider, setOpenCallFlowSlider] = useState(false);

  const { extension, business_hour, call_flow, business_contact } =
    onboard_process_statics;

  const account_type = authService.getUserDetail()?.type ?? "";

  useEffect(async () => {
    OnBoardProcessStatics(store.dispatch);
  }, []);

  const onBoardProcessTypeHandler = (value) => {
    switch (value) {
      case "team-member": {
        setAddTeamMember(true);
        setAddContact(false);
        setAddBusinessHour(false);
        setOpenCallFlowSlider(false);
        break;
      }
      case "business-hour": {
        if (extension === "Y") {
          setAddBusinessHour(true);
          setAddTeamMember(false);
          setAddContact(false);
          setOpenCallFlowSlider(false);
        } else {
          swal({
            icon: "warning",
            title: "Warning!",
            text: "Please invite team member before set up operating hours.",
          });
        }
        break;
      }
      case "call-flow": {
        if (business_hour === "Y" && extension === "Y") {
          setAddBusinessHour(false);
          setAddTeamMember(false);
          setAddContact(false);
          setOpenCallFlowSlider(true);
        } else {
          swal({
            icon: "warning",
            title: "Warning!",
            text: "Please invite team member and set up operating hours before set up call routing.",
          });
        }
        break;
      }
      case "add-contact": {
        if (business_hour === "Y" && extension === "Y" && call_flow === "Y") {
          setAddContact(true);
          setAddTeamMember(false);
          setAddBusinessHour(false);
          setOpenCallFlowSlider(false);
        } else {
          swal({
            icon: "warning",
            title: "Warning!",
            text: "Please invite team member, set up operating hours and call routing before adding nxacontact.",
          });
        }
        break;
      }
      case "call": {
        if (
          business_hour === "Y" &&
          extension === "Y" &&
          call_flow === "Y" &&
          business_contact === "Y"
        ) {
          ForceOpenDialPad({ open: true }, store.dispatch);
          setAddTeamMember(false);
          setAddContact(false);
          setAddBusinessHour(false);
          setOpenCallFlowSlider(false);
        } else {
          swal({
            icon: "warning",
            title: "Warning!",
            text: "Please invite team member, set up operating hours, call routing and add nxacontact before making your first call.",
          });
        }

        break;
      }
    }
  };
  return (
    <>
      {" "}
      <div className="col-md-4 col-lg-3 pl-0">
        <div className="mid-sidebar">
          <div className="mid-nav-inner bg-gray p-0">
            <div className="mid-nav pt-4 phone-system-menus">
              <WelcomeMenu
                onBoardProcessType={onBoardProcessTypeHandler}
                account_type={account_type}
              />
              {account_type !== "E" && (
                <OnBoardProcess
                  onBoardProcessType={onBoardProcessTypeHandler}
                  onboard_process_statics={onboard_process_statics}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ContactSlider
        addContact={addContact}
        renderPage={"welcome"}
        closeAddContactSlider={() => {
          setAddContact(false);
        }}
      />
      {account_type !== "E" && (
        <>
          <TeamMemberSlider
            addTeamMember={addTeamMember}
            renderPage={"welcome"}
            closeTeamMemberSlider={() => {
              setAddTeamMember(false);
            }}
          />
          <AddBusinessHour
            addBusinessHour={addBusinessHour}
            renderPage={"welcome"}
            closeBusinessHour={() => {
              setAddBusinessHour(false);
            }}
          />
          <CallFlowSlider
            openCallFlowSlider={openCallFlowSlider}
            setOpenCallFlowSlider={setOpenCallFlowSlider}
            renderPage={"welcome"}
          />
        </>
      )}
    </>
  );
}

const WelcomeMenu = ({ onBoardProcessType, account_type }) => {
  const [activeTab, setActiveTab] = useState("dialer");
  return (
    <div className="online-store welcome-mid-side">
      <h5
        className={`${activeTab === "dialer" ? "active" : ""}`}
        onClick={() => {
          setActiveTab("dialer");
          onBoardProcessType("call");
        }}
      >
        <img src="./../assets/images/make-call.svg" alt="1" />
        Make a call
      </h5>
      {account_type !== "E" && (
        <h5
          className={`${activeTab === "team-member" ? "active" : ""}`}
          onClick={() => {
            setActiveTab("team-member");
            onBoardProcessType("team-member");
          }}
        >
          <img src="./../assets/images/create-team-ico-2.svg" alt="1" />
          Invite Team Member
        </h5>
      )}
      <h5
        className={`${activeTab === "add-contact" ? "active" : ""}`}
        onClick={() => {
          setActiveTab("add-contact");
          onBoardProcessType("add-contact");
        }}
      >
        <img src="./../assets/images/add-contact-ico.svg" alt="1" />
        Add Contact
      </h5>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    onboard_process_statics: state?.dashboard?.onboard_process_statics ?? {},
  };
};

export default connect(mapStateToProps)(Sidebar);
