import React, { useEffect, useState, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import TagModal from "./TagModal";
import swal from "sweetalert";
import moment  from 'moment'; 
import { TagListing } from '../../../../Services/Actions/callActions';
import { DeleteTag } from '../../../../Services/Actions/settingActions';
import { convertUtcToLocalTime } from '../../../../Utils/Common';
import { Link } from 'react-router-dom';
import Pagination from '../../../../Component/Pagination'; 


const TagsList: FC<{ SetTab: Function }> = ({ SetTab }) => {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [userObj, setUserObj] = useState({});
    const [pageofItems, setPageofItems] = useState([]);
    const [totalItems, setTotalItems] = useState([]);
    const dispatch = useDispatch();
    const tag_listing = useSelector(state => state?.call?.tag_listing??[]);

    useEffect(async() => {
        TagListing(dispatch);
    }, []);

    useEffect(() => {
        onSearch()
    }, [search]);

    useEffect((): void => {
        if (tag_listing) {
            setTotalItems(tag_listing);
        }
    }, [tag_listing]);

    const onSearch = (): void => {
        setTotalItems(tag_listing.filter(value => value.name.toLowerCase().includes(search.toLowerCase())));
    }
    const DeleteTagList = (uniquecode): String => {
        swal({ 
            text: "Are you sure want to delete this tag?",
            icon: "warning",
            title:"Warning!",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    DeleteTag({ uniquecode },dispatch);
                }
            });
    }

    return (
        <div className="phone-system-inner-main-div">
            <TagModal {...{ open, userObj, handleClose: () => { setOpen(!open); setUserObj({}); } }} />
            <div className="phone-system-inner-div">
                <div className="phone-system-inner-head">
                    <div className="ps-inner-head-left">
                        <h3>
                            <span
                                style={{ cursor: "pointer" }}
                                onClick={() => SetTab("phone")}
                            >
                                Phone System
                            </span>
                            <img
                                src="./../assets/images/menu-arrow-up.svg"
                                alt="1"
                            />
                            <span>Call Tags</span>
                        </h3>
                    </div>

                    <div className="ps-inner-head-right">
                        <span className="search-ps-inner">
                            <img
                                src="./../assets/images/search-dash.svg"
                                alt="1"
                            />
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search"
                                name="search"
                                value={search}
                                onChange={e => { setSearch(e.target.value) }}
                            />
                        </span>
                        <Link
                            to="#"
                            className="create-ps-num-btn webphone-btns"
                            onClick={() => setOpen(true)}
                        >
                            Create Tag
                        </Link>
                    </div>
                </div>
                {/* table start */}
                <div className="phone-system-inner-table">
                    <div className="table-responsive call-info-scroll single-table-height">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Creation Date</th>
                                    <th>Tag Name</th> 
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pageofItems && pageofItems.map((value, index) => {
                                    let date_time = convertUtcToLocalTime(value.creation_date)
                                    let formattedDate = moment(date_time).locale("en").format("ll");
                                    let formattedTime = moment(date_time).locale("en").format("LT");
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {`${formattedDate} ${formattedTime}`}
                                            </td>
                                            <td className="d-flex align-items-center"> 
                                                <span className="color-circle" style={{ background: `${value.color_code}` }} />
                                                {value.name} 
                                            </td> 
                                            <td className="right">
                                                <Link to="#" title="Edit Tag" className="edit-business-hrs" onClick={() => { setUserObj(value); setOpen(true); }}>
                                                    <img src="./../assets/images/edit-new.svg" alt="edit" />
                                                </Link>
                                                &nbsp;&nbsp;
                                                <Link to="#" title="Delete Tag" className="edit-business-hrs" onClick={() => DeleteTagList(value.uniquecode)}>
                                                    <img src="./../assets/images/trash-2.svg" alt="delete" />
                                                </Link>
                                            </td>
                                        </tr>
                                    );
                                }
                                )}

                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        data={totalItems}
                        currentData={data => setPageofItems(data)}
                    />
                </div> 
            </div>
        </div>
    )
}

export default TagsList;
