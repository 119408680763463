import React, { useState, useEffect } from "react";
import AudioPlayer from "../CallFlow/AudioPlayer";
import moment from "moment";
import {
  DeleteVoiceFile,
  IVRGreetingList, 
} from "../../../../Services/Actions/settingActions";
import { bytesToSize, convertUtcToLocalTime, SecondsTohhmmss } from "../../../../Utils/Common";
import Pagination from "../../../../Component/Pagination";
import VoiceFileSlider from "./VoiceFileSlider";
import { Link } from "react-router-dom";
import { store } from "../../../../Services";                                           
import swal from 'sweetalert';
import { connect } from "react-redux";

function VoiceFile({ SetTab, ivr_listing }) { 
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(""); 
  const [totalItems, setTotalItems] = useState([]);
  const [pageofItems, setPageOfItems] = useState([]);

  useEffect(async() => {
   IVRGreetingList(store.dispatch) 
  }, []);

  useEffect(() => {
    setTotalItems(ivr_listing);
  }, [ivr_listing]);

  const SearchDataHandler = (e) => {
    let search = e.target.value;
    setSearch(search);
    setTotalItems(
      ivr_listing.filter((value) => {
        return value.name.toLowerCase().includes(search.toLowerCase());
      })
    );
  };

  const sliceData = (pageofItems) => {
    setPageOfItems(pageofItems);
  };

  const DeleteVoiceFileHandler = (id) => {
    swal({
      text: "Are you sure want to delete this voice file?",
      icon: "warning",
      title:"Warning!",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await DeleteVoiceFile({ id },store.dispatch) 
        if (response === 1) {
          IVRGreetingList(store.dispatch) 
        }
      }
    });
  };  

  return (
    <>
      <div className="phone-system-inner-main-div">
        <div className="phone-system-inner-div">
          <div className="phone-system-inner-head">
            <div className="ps-inner-head-left">
              <h3>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => SetTab("phone")}
                  className="phone-system-name"
                >
                  Phone Settings
                </span>
                <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                <span>Voice Files</span>
              </h3>
            </div>

            <div className="ps-inner-head-right">
              <span className="search-ps-inner">
                <img src="./../assets/images/search-dash.svg" alt="1" />
                <input
                  className="form-control"
                  type="text"
                  name="search"
                  value={search}
                  placeholder=""
                  onChange={SearchDataHandler}
                />
              </span>
              <Link
                to="#"
                style={{ cursor: "pointer" }}
                className="create-ps-num-btn"
                onClick={() => setOpen(true)}
              >
                Add Voice File
              </Link>
            </div>
          </div>
          {/* table start */}
          <div className="phone-system-inner-table">
            {pageofItems && pageofItems.length === 0 && (
              <div className="team-user-add-div add-data-vactor">
                <img src="./../assets/images/IVR.svg" alt="1" />
                <h4>No Voice File added</h4>
                <Link
                  to="#"
                  className="btn btn-orange"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(true)}
                >
                  Add Voice File
                </Link>
              </div>
            )} 
            <div className="phone-system-inner-table">
              {pageofItems && pageofItems.length !== 0 && (
                <div className="table-responsive call-info-scroll single-table-height">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Uploaded</th>
                        <th>File Name</th>
                        <th>File Duration</th>
                        <th>File Size</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageofItems &&
                        pageofItems.map((value, index) => {
                          let date_time = convertUtcToLocalTime(
                            value.creationdate
                          );
                          let formattedDate = moment(date_time)
                            .locale("en")
                            .format("ll");
                          let formattedTime = moment(date_time)
                            .locale("en")
                            .format("LT");
                          return (
                            <tr key={index}>
                              <td>{`${formattedDate} ${formattedTime}`}</td>
                              <td>
                                <span className="business-hrs-service">
                                  <h3>{value.name}</h3>
                                </span>
                              </td>
                              <td>{SecondsTohhmmss(value.duration)}</td>
                              <td className="assign-to-add"> 
                                {bytesToSize(value.size) ?? "NA"}
                              </td>
                              <td align="right">
                                <span className="d-flex align-items-center">
                                  <Link to="#" className="edit-business-hrs">
                                    <AudioPlayer src={value.filename} />
                                  </Link>
                                  &nbsp;&nbsp;
                                  <Link
                                    to="#"
                                    className="edit-business-hrs"
                                    onClick={() =>
                                      DeleteVoiceFileHandler(value.id)
                                    }
                                  >
                                    <img
                                      src="./../assets/images/trash-2.svg"
                                      alt="delete"
                                    />
                                  </Link>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
              <Pagination data={totalItems} currentData={sliceData} />
            </div> 
          </div>
        </div>
      </div>
      <VoiceFileSlider addFile={open} closeFileSlider={() => setOpen(false)} />
    </>
  );
}

const mapStateToProps = (state) => ({
  ivr_listing: state?.setting?.ivr_listing ?? [],
});

export default connect(mapStateToProps)(VoiceFile);
