import * as PARAMS from "./parameters";
// PERFORM EVENTS

export const REGISTERED = (e) => {
  console.log("Registered");
  return { connection: true };
};

export const UNREGISTERED = (e) => {
  console.log("UNREGISTERED");
};

export const CONNECTED = (e) => {
  console.log("CONNECTED");
  return { connection: true };
};

export const DISCONNECTED = (e) => {
  console.log("DISCONNECTED");
  return { sessions: {}, support: false, connection: false };
};

export const REGISTRATION_FAILED = (e) => {
  console.log("REGISTRATION_FAILED");
  return { sessions: {}, support: false, connection: false };
};

export const INVITE = (session) => {
  if (localStorage.getItem("_webPhone")) {
    session.terminate();
    return false;
  }
  return true;
};

// PERFORM ACTIONS

export const UNREGISTER = (session) => {
  if (session) {
    session.unregister && session.unregister(PARAMS.OPTION_MEDIA);
  }
};

export const WEBRTC_SUPPORT = () => {
  if (navigator.webkitGetUserMedia) {
    return true;
  } else if (navigator.mozGetUserMedia) {
    return true;
  } else if (navigator.getUserMedia) {
    return true;
  } else {
    window.console.error("WebRTC support not found");
    return false;
  }
};

export const UUID = Math.random().toString(36).substr(2, 9);

export const ON_PROGRESS_OUTGOING = () => {};

export const NEWLY_INVITED = (session, direction) => {
  let remoteUserName = session.remoteIdentity.displayName ?? session.remoteIdentity.uri.user ?? "NA";
  let remoteUserNumber = session.remoteIdentity.uri.user ?? "NA";
  let uuid = UUID;
  let status;
  let starttime = new Date().getTime();
  direction = "inbound";
  status = "ringing";
  let callSession = { remoteUserName, remoteUserNumber, uuid, direction, status, session, starttime, endtime: "" };  
  return Promise.resolve(callSession);
};

export const UA_ACTIONS = (UA, callSession, UpdateStates, MainSession) => {
  let remoteUserName, remoteUserNumber, uuid, direction, status, session, starttime, endtime, sessions;
  if(UA.hasAnswer){ // IF answer button clicked
    status = "connected";
    session = MainSession;
    remoteUserName = session.remoteIdentity.displayName ?? session.remoteIdentity.uri.user ?? "NA";;
    remoteUserNumber = session.remoteIdentity.uri.user ?? "NA";
    direction = "inbound"
    starttime = new Date().getTime();
    sessions = { ...callSession, status, session,remoteUserName,remoteUserNumber,direction,starttime };
    UpdateStates(sessions);
  }  
  UA.on("progress", (InSession) => {
    console.log("progress");
    status = InSession.statusCode === 180 ? "ringing" : "connecting";
    remoteUserName = remoteUserNumber = InSession.to?.uri?.user ?? "unknown";
    uuid = InSession.statusCode === 100 ? UUID : sessions?.uuid;
    direction = "outbound";
    session = InSession;
    starttime = new Date().getTime();
    sessions = { remoteUserName, remoteUserNumber, uuid, direction, status, session, starttime, endtime: "" };
    UpdateStates(sessions);
  });  
  UA.on("accepted", (InSession) => {
    console.log("accepted");
    status = "connected";
    session = InSession;
    sessions = { ...sessions, status, session };
    UpdateStates(sessions);
    // In accepted status setting mediastream for remote device
    let domElement = document.getElementById("audioRemote");    
    const myPromise  = new Promise((resolve)=>{
      domElement.srcObject = null;
      domElement.pause();
      resolve();
    });
    myPromise.then(()=>{
      let pc = MainSession.sessionDescriptionHandler.peerConnection;
      let remoteStream = new MediaStream();
      pc.getReceivers().forEach(function (receiver) {
        if (receiver.track) {
          remoteStream.addTrack(receiver.track);        
        }
      }); 
      domElement.srcObject = remoteStream;
      domElement.play(); 
    });
  });
  UA.on("rejected", () => {
    console.log("rejected");
    UpdateStates({});
  });
  UA.on("failed", (response, cause) => {
    console.log("failed",response, cause);
    UpdateStates({});
  });
  UA.on("terminated", () => {
    console.log("terminated");
    UpdateStates({});
  });
  UA.on("cancel", () => {
    console.log("cancel");
    UpdateStates({});
  });
  UA.on("reinvite", () => {
    console.log("reinvite");
  });
  UA.on("bye", () => {
    console.log("bye");
    UpdateStates({});
  });
  UA.on("referRequested", () => {
    console.log("referRequested");
  });

  UA.on("invite", () => {
    console.log("INVITED SESSION");
  });
  UA.on('dtmf', (request, dtmf)=> {
    console.log("DTMF Recieved");
  })  
  UA.on('trackAdded', () => {
    console.error("Track Added")
  })  
  UA.on('referRequested', (context) => {
    console.error("context",context)
  })

};

export const HANGUP_CALL = (CallSession) => {
  CallSession.terminate();
  return undefined;
};

export const ANSWER_CALL = (CallSession) => {
  return Promise.resolve(CallSession.accept({
    sessionDescriptionHandlerOptions: {
      constraints: {
        audio: true,
        video: false,
      },
    },
    media: {
      stream: CallSession.Stream,
      constraints: PARAMS.OPTION_CONSTRAINT,
      render: {
        remote: { audio: window.$("#audioRemote").get()[0], video: false },
        local: { audio: true, video: false },
      },
      RTCConstraints: { optional: [{ DtlsSrtpKeyAgreement: "true" }] },
    },
  }));
};

export const START_CALL = (UA, number) => {
  return Promise.resolve(UA.invite(number, {
    sessionDescriptionHandlerOptions: PARAMS.SESSION_DESCRIPTION_HANDLER_OPTIONS,
    media: {
      ...PARAMS.SESSION_DESCRIPTION_HANDLER_OPTIONS,
      render: {
        remote: window.$("#audioRemote").get()[0],
      },
      RTCConstraints: { optional: [{ DtlsSrtpKeyAgreement: "true" }] },
    },
  }));
};

export const MUTE_CALL = async (CallSession,sessions,UpdateStates) => {
  let passed = false;
  let {status} = sessions;  
  if (status === "hold") {
    passed = await HOLD_CALL(CallSession, status);
  } else {
    passed = true;
  }
  if (passed) {
    try {
      var pc = CallSession.sessionDescriptionHandler.peerConnection;
      pc.getSenders().forEach((stream) => {
        if(stream.track){
          stream.track.enabled = status === "connected" ? false : true;
        }
      });
      sessions = {...sessions,status:status === "connected"?'mute':'connected'}
      UpdateStates(sessions);
      return true;
    } catch (err) {
      return false;
    }
  }
};

export const HOLD_CALL = async (CallSession,sessions,UpdateStates) => {
  let passed = false;
  let {status} = sessions;
  if (status === "mute") {
    passed = await MUTE_CALL(CallSession, status);
  } else {
    passed = true;
  }
  if (passed) {
    try {
      status === "connected" ? CallSession.hold() : CallSession.unhold();
      sessions = {...sessions,status:status === "connected"?'hold':'connected'}
      UpdateStates(sessions);
      return true;
    } catch (err) {
      return false;
    }
  }
};

export const TRANSFER_CALL = async (CallSession,target,UpdateStates,type) => { // type true -> attendent, false->blind 
  console.error('CallSession',CallSession,target)
  const getResponse = (value) => {
    console.error(value,'Transfer',type)
  }
  CallSession.session.refer(target,{activeAfterTransfer:type,receiveResponse:getResponse});
  if(!type){
    UpdateStates({});
  }
};

export const SendDTMF = (session,dtmf) => {
  console.error("DTMF PASSED",dtmf)
  session.dtmf(dtmf,{duration:100,interToneGap:100});
}

export const ToggleRecord = (session,dtmf,type) => {  
  console.error("DTMF FOR CALL RECORD",dtmf)
  session.dtmf(dtmf,{duration:100,interToneGap:100});
  if(type){
    var audio = new Audio('/sounds/recordingOn.mp3');
    audio.play();
  }else{
    var audio = new Audio('/sounds/recordingOff.mp3');
    audio.play();
  }
}
