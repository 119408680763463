import {
  accountBaseUrl,
  apiAdminUrl,
  apiBaseUrl,
  AppDomain,
  PaymentDomain,
} from "../../Utils/Common";
import * as constants from "../Constants/signupConstants";
import * as ACTIONS from "./../Constants/apiConstants.js";
import swal from "sweetalert";
import { FetchUserDetails, handleResponse, toastr } from "./userActions";
import { SHOW_LOADER } from "../Constants/userConstants";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const LoginFxn = (params, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}admin/login`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        let properties = {
          name: data.data.firstname,
          fullname: `${data.data.firstname} ${data.data.lastname}`,
        };
        localStorage.setItem("token", data.token);
        localStorage.setItem("type", data.data.type);
        localStorage.setItem("customerID", data.data.customerID);
        localStorage.setItem("properties", JSON.stringify(properties));
        localStorage.setItem("data", JSON.stringify(data.data));
        return data;
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const GetDidCityList = (data, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}${ACTIONS.DID_CITY_LIST}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.AVAILABLE_CITY_LIST,
          payload: data.data,
        });
      } else if (data.ResponseCode === "0") {
        dispatch({
          type: constants.AVAILABLE_CITY_LIST,
          payload: [],
        });
        return 0;
      }
    });
};

export const GetDidNumber = (data, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}${ACTIONS.DID_AVAILABLE}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.AVAILABLE_DID_NUMBERS,
          payload: data.data,
        });
      } else if (data.ResponseCode === "0") {
        dispatch({
          type: constants.AVAILABLE_DID_NUMBERS,
          payload: [],
        });
        return 0;
      }
    });
};

export const DidNumberBuy = (params, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}${ACTIONS.USERPANEL}${ACTIONS.DID_NUMBER_BUY}`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.CHOOSEN_NUMBER_INFO,
          payload: params,
        });
        toastr({
          duration: 1500,
          message: data.ResponseText,
          type: "success",
        });
        return 1;
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};

// GET PLAN LIST ACTION

export const PlansList = (dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}${ACTIONS.PLANS_LIST}`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.PLANS_LIST,
          payload: data.data,
        });
        return 1;
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};

//  GET CURRENCY LIST ACTION

export const CurrencyListing = (dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}${ACTIONS.CURRENCY_LIST}`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_CURRENCY_LISTING,
          payload: data.data,
        });
        return 1;
      } else if (data.ResponseCode === "0") {
        dispatch({
          type: constants.FETCH_CURRENCY_LISTING,
          payload: [],
        });
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};

export const BuyNxaPlan = (params, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}${ACTIONS.USERPANEL}${ACTIONS.BUY_NXA_PLAN}`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === 1) {
        dispatch({
          type: constants.GET_CALCULATED_PLAN_SUMMARY,
          payload: data.data,
        });
        return {code:1 , ResponseText : data?.ResponseText};
      } else if (data.ResponseCode === 0) {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    });
};

// PAYMENT ACTIONS

export const InitiatePayment = (data, order_details, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${PaymentDomain}${ACTIONS.PREPARE_PEACH_PAYMENT}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: new Headers({ "Content-Type": "application/json" }),
    }
  );
  return fetch(request)
    .then((res) => res.json())
    .then(async (response) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (response?.Data?.success) {
        let Result = JSON.parse(response.Data.results.data);
        let token = Result.id;
        let new_response = await dispatch(
          InitiatePaymentHandler({ ...order_details, token })
        );
        if (new_response === 1) {
          dispatch({
            type: constants.PAYMENT_TOKEN,
            payload: Result.id,
          });
          return 1;
        } else {
          dispatch({
            type: constants.PAYMENT_TOKEN,
            payload: "",
          });
          return 0;
        }
      } else {
        dispatch({ type: constants.PAYMENT_TOKEN, payload: "" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};

export const RepeatedPayment = (formData, order_details, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${PaymentDomain}${ACTIONS.PREPARE_PEACH_PAYMENT_CHECKOUT}`,
    {
      method: "POST",
      body: formData,
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      }),
    }
  );
  return fetch(request)
    .then((res) => res.json())
    .then(async (response) => {
      if (response?.Data?.success) {
        let Result = JSON.parse(response.Data.results.data);
        let token = Result.id;
        let new_response = await dispatch(
          InitiatePaymentHandler({ ...order_details, token })
        );
        if (new_response === 1) {
          dispatch({
            type: constants.PAYMENT_TOKEN,
            payload: Result.id,
          });
          return 1;
        } else {
          dispatch({
            type: constants.PAYMENT_TOKEN,
            payload: "",
          });
          return 0;
        }
      } else {
        dispatch({ type: constants.PAYMENT_TOKEN, payload: "" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      return 0;
    });
};

export const InitiatePaymentHandler = (params, dispatch) => {
  params = { ...params, callback_url: window.location.origin + "/" };
  return (dispatch) => {
    dispatch({ type: SHOW_LOADER, payload: true });
    const request = new Request(
      `${accountBaseUrl}${ACTIONS.USERPANEL}${ACTIONS.INITIATE_PAYMENT}`,
      {
        method: "POST",
        body: JSON.stringify(params),
        headers: new Headers({
          "Content-Type": "application/json",
          "X-Auth-Token": `${localStorage.getItem("token")}`,
        }),
      }
    );
    return fetch(request)
      .then(handleResponse)
      .then((data) => {
        dispatch({ type: SHOW_LOADER, payload: false });
        if (data.ResponseCode === "1") {
          return 1;
        } else if (data.ResponseCode === "0") {
          swal({
            title: "Error!",
            text: data.ResponseText,
            icon: "error",
          });
          return 0;
        }
      });
  };
};

export const NxaVoucherHandler = (data, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}${ACTIONS.USERPANEL}${ACTIONS.NXA_VOUCHER}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return {
          code: 1,
          amount: data?.amount,
          ResponseText: data?.ResponseText,
        };
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return { code: 0 };
      }
    });
};

export const OneVoucherHandler = (data, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${PaymentDomain}${ACTIONS.FETCH_ONE_VOUCHER}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then((res) => res.json())
    .then((response) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      return response;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      return 0;
    });
};

export const ShowPayment = (params, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${accountBaseUrl}${ACTIONS.SHOW_PAYMENT}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      dispatch({
        type: constants.PAYMENT_INFO,
        payload: data.data,
      });
      return { code: 1, payment_data: data.data };
    })
    .catch((error) => {
      dispatch({ type: constants.PAYMENT_INFO, payload: {} });
      dispatch({ type: SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return { code: 0 };
    });
};

export const createCustomerDatabase = (app_token,dispatch) => { 
  const token = app_token? app_token  : localStorage.getItem("token")  
  swal({
    icon:"warning",
    title:"Please Wait...", 
    text:"We are creating your data tables, this might take a while.", 
  }).then(async(willSuccess)=>{
    if(willSuccess){
      dispatch({ type: SHOW_LOADER, payload: true });
    }
  }) 
  const request = new Request(
    `${accountBaseUrl}${ACTIONS.USERPANEL}create-customersDB`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        localStorage.setItem("token",data?.token??"")
        return 1;
      } else if (data.ResponseCode === "0") { 
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) { 
            if(data?.data?.db_name){  
              window.location.href= `https://${AppDomain}/rica-process` 
            }else{ 
              return 0;
            }
          } else {
            console.log("you clicked on false");
          }
        }); 
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};

// **************************************************   RICA ACTIONS ********************************************************

export const RicaCompanyVerification = (data, dispatch) => {
  dispatch({
    type: constants.RICA_COMPANY_VERIFICATION,
    payload: data,
  });
};

export const RicaPortingProcess = (data, dispatch) => {
  dispatch({ type: constants.RICA_PORTING_PROCESS, payload: data });
};

export const RicaProcessSave = (data, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}${ACTIONS.USERPANEL}rica-process`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return 1;
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    });
};

export const UpdateAddressDetails = (body, dispatch) => {
  body = { ...body, action: "rica" };
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}${ACTIONS.USERPANEL}rica-address`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        FetchUserDetails(dispatch);
        return 1;
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    });
};

export const UpdateUserDetails = (body, dispatch) => {
  body = { ...body, action: "rica" };
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}${ACTIONS.USERPANEL}user-update`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": `${localStorage.getItem("token")}`,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        FetchUserDetails(dispatch);
        return { code: 1, data: data.data };
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return { code: 0 };
      }
    });
};

export const UploadRicaDocument = (params, dispatch) => {
  let request;
  request = {
    method: "POST",
    url: `${apiAdminUrl}userPanel/upload_document`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": `${localStorage.getItem("token")}`,
    },
    data: params,
  };

  return axios(request)
    .then((data) => {
      if (data.data.ResponseCode === "1") {
        return 1;
      } else if (data.data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      swal({
        title: "Error!",
        text: error.data.ResponseText,
        icon: "error",
      });
      return 0;
    });
};

export const mapAddress = (data, dispatch) => {
  dispatch({ type: constants.GOOGLE_MAP_ADDRESS, payload: data });
};

export const PortingProcessSave = (formData, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  let request;
  request = {
    method: "POST",
    url: `${apiAdminUrl}userPanel/update-porting`,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": `${localStorage.getItem("token")}`,
    },
    data: formData,
  };

  return axios(request)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.data.ResponseCode === "1") {
        return 1;
      } else if (data.data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (error && error.response && error.response.data) {
        swal({
          title: "Error!",
          text: error.data.ResponseText,
          icon: "error",
        });
        return 0;
      } else {
        swal({
          title: "Error!",
          text: error.data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    });
};
