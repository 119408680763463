import React, { Component } from "react";
import "./payments.css";
import { withRouter } from "../../Utils/withRouter";
import PageHeader from "./PageHeader";
import ProgressBar from "./ProgressBar";
import ContactBar from "./ContactBar";
import Fade from "@material-ui/core/Fade";
import { connect } from "react-redux";
import { ShowLoader } from "../../Services/Actions/userActions";
import { store } from "../../Services";
import {
  createCustomerDatabase,
  ShowPayment,
} from "../../Services/Actions/signupActions"; 

const account_token = localStorage.getItem("token");

class FetchPayment extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: false, app_token: "" };
  }

  componentDidMount() {
    this.setState({ checked: true });
    let token = window.location.pathname.split("/").pop();
    ShowPayment({ token }, store.dispatch);
    let data = window;
    if (navigator.userAgent.includes("Android")) {
      data = document;
    }
    data.addEventListener("message", (message) => {
      if (typeof message?.data === "string") {
        this.setState({
          app_token: message?.data,
        });
      }
    });
  }

  onNavigateHandler = async () => {
    const {
      payment_info: { status },
    } = this.props;
    const { app_token } = this.state; 
    const response = await createCustomerDatabase(app_token, store.dispatch);
    if (response === 1) {
      let token = localStorage.getItem("token");
      if (status === "S") {
        this.MobileMessageSend({ status: "success", token });
        this.props.navigate("/rica-process");
        setTimeout(() => {
          ShowLoader(false);
        }, 2000);
      } else if (status === "F") {
        this.MobileMessageSend({ status: "failure", token });
        this.props.navigate("/order-summary");
        setTimeout(() => {
          ShowLoader(false);
        }, 2000);
      } else {
        this.MobileMessageSend({ status: "processing", token });
        this.props.navigate("/order-summary");
        setTimeout(() => {
          ShowLoader(false);
        }, 2000);
      }
    }
  };

  MobileMessageSend = (value) => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify(value));
    } catch (error) {}
  };

  zohoLiveChat = () => {
    window.$(".order-contact-link").addClass("nxa-support");
  };

  componentWillUnmount = () => {
    window.$(".order-contact-link").removeClass("nxa-support");
  };

  render() {
    const { checked } = this.state;
    const {
      payment_info: {
        status,
        firstname = "unknown",
        lastname = "unknown",
        user_plan,
        currency,
        amount: plan_cost = 0,
        trial,
        trial_amount = 0,
        did_number = "",
        duration = 1,
        message = "",
      } = {},
    } = this.props;

    const {
      voice_minute = 0,
      participants_for_video_confrence = 0,
      storage = 0,
      annual_plan_discount = 0,
      two_year_plan_discount = 0,
    } = user_plan || {};

    return (
      <Fade in={checked} timeout={500}>
        <div className="pricing-step pb-5">
          {account_token && (
            <div className="sign-up-step-bar">
              <div className="container">
                <PageHeader />
                <ContactBar />
                <ProgressBar active="order-page" />
              </div>
            </div>
          )}
          <div className="business-number successful-img  text-center pt-5 pb-4">
            <h1 className="font-36 mb-4 color-7c2b fw-700">
              {status === "S" &&
                `Hi ${firstname} ${lastname} , Your Order was successful.`}
              {(status === "P" || status === "F") &&
                `Hi ${firstname} ${lastname} , Your Order was unsuccessful.`}
            </h1>
            {(status === "P" || status === "F") && (
              <>
                {message && (
                  <p className="order-contact">
                    {`${
                      message.charAt(0).toUpperCase() + message.slice(1)
                    }, please contact`}
                    <a
                      className="order-contact-link"
                      onClick={this.zohoLiveChat}
                    >
                      Nxatel Support
                    </a>
                  </p>
                )}
              </>
            )}
            {status === "S" && (
              <img
                src="./../assets/images/signup/success-payment.png"
                alt="1"
              />
            )}
            {(status === "P" || status === "F") && (
              <img src="./../assets/images/signup/sad-emoji.svg" alt="1" />
            )}
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4 text-center">
              <div className="payment-success-box">
                <div className="d-flex justify-content-center">
                  <span className="font-20 color-3b vertical-align-top">
                    {currency === "USD" ? "$" : "R"}
                  </span>
                  <h1 className="color-3b font-36 fw-700">
                    {trial === "Y" ? trial_amount : plan_cost}
                  </h1>
                  <div className="form-group w-20% currency mb-0 align-item-center d-flex">
                    <a className="color-293F p-0" href={void 0}>
                      <span
                        className={`color-7AFE lh-40 ${
                          trial === "Y" ? "payment-amount" : ""
                        }`}
                      >
                        /
                        {duration === 1
                          ? "Monthly"
                          : duration === 12
                          ? `12-month - discount upto ${parseInt(
                              annual_plan_discount * 100
                            )}%`
                          : `24-month - discount upto ${parseInt(
                              two_year_plan_discount * 100
                            )}%`}
                        <br />
                        {trial === "Y" && (
                          <small>after 7-Days trial ends</small>
                        )}
                      </span>
                    </a>
                  </div>
                </div>
                <p className="font-14 color-6666 fw-400">
                  <li>FREE Number {did_number}</li>
                  <li>{voice_minute} Minutes</li>
                  <li>50 SMS</li>
                  <li> Video Calling </li>
                  <li>{participants_for_video_confrence} Users</li>
                  <li>{storage} cloud Storage</li>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4 text-center">
            {status === "S" && (
              <button
                className="btn btn-pay"
                type="button"
                onClick={this.onNavigateHandler}
              >
                Continue
              </button>
            )}
            {(status === "P" || status === "F") && (
              <button
                className="btn btn-pay"
                type="button"
                onClick={this.onNavigateHandler}
              >
                Try Again
              </button>
            )}
          </div>
        </div>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payment_info: state?.signup?.payment_info ?? {},
  };
};

export default connect(mapStateToProps)(withRouter(FetchPayment));
