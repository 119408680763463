import React, { useEffect, useState } from "react";
import { store } from "../../../Services";
import { DeleteNotification } from "../../../Services/Actions/notificationActions";
import { convertUtcToLocalTime, IsJsonString } from "../../../Utils/Common";
import moment from "moment";
import Pagination from "../../../Component/Pagination";
import swal from "sweetalert";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function Notify({ notification_listing }) {
  const [__totalItems, setTotalItems] = useState([]);
  const [__pageOfItems, setPageOfItems] = useState([]);

  useEffect(() => {
    if (notification_listing) {
      setTotalItems(notification_listing);
    }
  }, [notification_listing]);

  const UpdateData = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  const deleteNotificationHandler = (value) => {
    swal({
      icon: "warning",
      title:"Warning!",
      text: "Are you sure want to delete this notification?",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        DeleteNotification(
          { id: value?.id, type: value?.type },
          store.dispatch
        );
      } else {
        console.log("you clicked on false");
      }
    });
  };
  return (
    <>
      <div className=" Find-Business-inner notification-page-li-div">
        <div className="notification-setting notification-setting-scroll ">
          {__pageOfItems &&
            __pageOfItems.map((value, key) => {
              let parsedObj = IsJsonString(value.content)
                ? JSON.parse(value.content)
                : {};
              let message =
                value.type === "meeting"
                  ? `You're invited in a meeting!`
                  : parsedObj.message;
              let time =
                value.type === "meeting"
                  ? `${parsedObj.date} ${parsedObj.start_time}`
                  : value.creationdate;
              return (
                <a
                  className="dropdown-item notification-page-dropdown-item"
                  key={`not${key}`}
                >
                  <div className="notification-drop-list">
                    <div className="notifiaction-ico">
                      <img
                        src={`./../assets/images/${
                          value.type === "Payment"
                            ? "payment-credit-card.svg"
                            : value.type === "missed_call"
                            ? "phone-missed.svg"
                            : "calendar-call-info.svg"
                        }`}
                        alt="1"
                      />
                    </div>
                    <div className="notifiaction-content">
                      <div className="notificaion-flx-div">
                        <span className="notifiaction-name">
                          <h4> {value.title} </h4>
                        </span>
                      </div>
                      <div className="notification-text-full">
                        <p> {message} </p>
                      </div>
                      <div className="notificaion-flx-div">
                        <span className="sent-message-text">
                          {moment(convertUtcToLocalTime(time))
                            .locale("en")
                            .calendar()}
                        </span>
                      </div>
                    </div>
                    <div className="notifiaction-action"> 
                      <div className=" number-collase-action">
                        <a
                          href={`#${key}`}
                          data-toggle="collapse"
                          aria-expanded="false"
                          className="dropdown-toggle collapsed"
                        >
                          <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul
                          className="list-unstyled number-dr-collapse collapse"
                          id={key}
                        > 
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => deleteNotificationHandler(value)}
                            >
                              <img
                                src="./../assets/images/number-drop-trash-2.svg"
                                alt="1"
                              />
                              Delete Notification
                            </Link>
                          </li> 
                        </ul>
                      </div> 
                    </div>
                  </div>
                </a>
              );
            })}
        </div>
        <Pagination data={__totalItems} currentData={UpdateData} />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  notification_listing: state?.notification?.notification_listing ?? [],
});

export default connect(mapStateToProps)(Notify);
