import React, { Component } from "react";
import moment from "moment";
import { store } from "../../Services";
import { CloudNotesListing } from "../../Services/Actions/powerappActions";
import { convertUtcToLocalTime } from "../../Utils/Common";
import NotesModal from "./NotesModal";
import { connect } from "react-redux";

const initialState = { openNotesModal: "", created_note: "" };

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async () => {
    CloudNotesListing({ type: "list" }, store.dispatch);
  };

  closeNotesModal = () => {
    this.setState({ openNotesModal: false });
  };

  editHandler = (value) => {
    this.props.SetTab("create_note");
    this.props.ParentFunc(value);
  };

  createhandler = () => {
    this.props.SetTab("create_note");
    this.props.ParentFunc({ isEdit: false });
  };

  render() {
    const { cloud_notes_listing } = this.props;
    const { openNotesModal, created_note } = this.state;

    return (
      <>
        <div className="card discover-inner">
          <div className="buy-card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="notes-inner notes-inner-2 p-1 tab-pane-scroll">
                  {cloud_notes_listing &&
                    cloud_notes_listing.map((value, index) => {
                      const localCreatedTime = convertUtcToLocalTime(
                        value.creationdate
                      );
                      let cdate = new Date(
                        moment(localCreatedTime).locale("en").format("ll")
                      );
                      let createdDate =
                        cdate.getDate() +
                        "-" +
                        (cdate.getMonth() + 1) +
                        "-" +
                        cdate.getFullYear();
                      let createdTime = moment(localCreatedTime)
                        .locale("en")
                        .format("LT");

                      const localModifiedTime = convertUtcToLocalTime(
                        value.lastupdate
                      );
                      let mdate = new Date(
                        moment(localModifiedTime).locale("en").format("ll")
                      );
                      let modifiedDate =
                        mdate.getDate() +
                        "-" +
                        (mdate.getMonth() + 1) +
                        "-" +
                        mdate.getFullYear();
                      let modifiedTime = moment(localModifiedTime)
                        .locale("en")
                        .format("LT");
                      return (
                        <p
                          className="d-flex justify-content-between"
                          key={`note_${index}`}
                        >
                          <span
                            className="content-notes"
                            onClick={() =>
                              this.setState({
                                openNotesModal: true,
                                created_note: value.note,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <h5 className="font-14 font-bold">{value.title}</h5>
                            {value.note}
                          </span>
                          <span className="align-self-center">
                            <h6 className="font-14 color-9">
                              {`Created: ${createdDate} ${createdTime}`}
                            </h6>
                            <h6 className="font-14 color-9">
                              {`Modified: ${modifiedDate} ${modifiedTime}`}
                            </h6>
                          </span>
                          <span
                            className="badge badge-danger align-self-center"
                            onClick={() => this.editHandler(value)}
                            style={{ cursor: "pointer" }}
                          >
                            Edit
                          </span>
                        </p>
                      );
                    })}

                  {cloud_notes_listing && cloud_notes_listing.length === 0 && (
                    <div className="text-center w-100">
                      <h5>No notes created</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <p
                className="btn btn-orange btn-note add-note-btn"
                onClick={() => this.createhandler()}
              >
                Create Note
              </p>
            </div>
          </div>
        </div>

        {openNotesModal && (
          <NotesModal
            openNotesModal={openNotesModal}
            notes={created_note}
            closeNotesModal={this.closeNotesModal}
            {...this.props}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cloud_notes_listing: state?.power_apps?.cloud_notes_listing ?? [],
});

export default connect(mapStateToProps)(Notes);
