import React, { useState, useEffect } from "react";
import moment from "moment";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { store } from "../../../Services";
import { ScheduleCallTime } from "../../../Services/Actions/callActions";
import Modal from "../../../Component/Modal";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ScheduleTimeArray } from "../../../Utils/Common";

function ScheduleModal(props) {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bookedDate, setBookedDate] = useState(
    moment().locale("en").format("l")
  );
  const { OpenScheduleModal, closeScheduleModal, destination_number } = props;

  useEffect(() => {
    window.$("#callback").removeClass("show");
  }, []);

  const handleDayClick = (day, { selected }) => {
    setSelectedDate(selected ? undefined : day);
    setBookedDate(moment(day).locale("en").format("l"));
  };

  const SaveScheduleCallTime = async () => {
    let _datetime = `${moment(bookedDate)
      .locale("en")
      .format("YYYY-MM-DD")} ${selectedTimeSlot}`;
    let response = await ScheduleCallTime(
      {
        number: destination_number,
        date: _datetime,
      },
      store.dispatch
    );
    if (response === 1) {
      closeScheduleModal();
    } else {
      swal({
        title: "Error!",
        text: "The number has already been taken.",
        icon: "error",
      });
      closeScheduleModal();
    }
  };

  return (
    <div>
      <Modal
        open={OpenScheduleModal}
        size={`sm`}
        CloseModal={closeScheduleModal}
        title={`Schedule Call back`}
      >
        <div className="schedule-call-back-calender">
          <DayPicker
            selectedDays={selectedDate}
            onDayClick={handleDayClick}
            disabledDays={[
              {
                before: new Date(),
              },
              { daysOfWeek: [0, 6] },
            ]}
          />
        </div>
        <div className="schedule-call-back-available-time-slote">
          <h4>Available Time</h4>
          <div className="available-slote-call-back">
            {ScheduleTimeArray && ScheduleTimeArray.map((val) => {
                let currentTime = moment().locale("en").format("HH:mm");
                let isCurrentDate = moment(bookedDate).isSame(
                  moment().locale("en").format("l")
                );
                let beforeCurrentTime = moment(
                  val._24hrformat,
                  "HH:mm"
                ).isBefore(moment(currentTime, "HH:mm"));
                return (
                  <>
                    <span
                      className={`${
                        isCurrentDate && beforeCurrentTime ? "hide" : ""
                      } ${
                        selectedTimeSlot === val._24hrformat ? "active" : ""
                      }`}
                      onClick={() => setSelectedTimeSlot(val._24hrformat)}
                    >
                      {val.time}
                    </span>
                  </>
                );
              })}
          </div>
          <div className="schedule-footer">
            <a className="save-slot-btn" onClick={SaveScheduleCallTime}>
              Save
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default connect()(ScheduleModal);
