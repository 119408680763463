import React, { Component } from "react";
import {
  NotificationListing,
  DeleteNotification,
} from "../../Services/Actions/notificationActions";
import moment from "moment";
import { store } from "../../Services";
import { convertUtcToLocalTime, IsJsonString } from "../../Utils/Common";
import { connect } from "react-redux";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Pagination from "../../Component/Pagination";

const initialState = {__pageOfItems:[],__totalItems:[]};

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async () => {
    NotificationListing(store.dispatch);
  };

  componentDidUpdate(prevProps) {
    const { notification_listing } = this.props;   
    if (prevProps.notification_listing !== notification_listing) {
      this.setState({ __totalItems: notification_listing });
    }
  }

  UpdateData = (pageOfItems) => {
    this.setState({
      __pageOfItems:pageOfItems
    }) 
  };

  deleteNotificationHandler = (value) => {
    swal({
      icon: "warning",
      title:"Warning!",
      text: "Are you sure want to delete this notification?",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        DeleteNotification(
          { id: value?.id, type: value?.type },
          store.dispatch
        );
      } else {
        console.log("you clicked on false");
      }
    });
  };

  render() {
    const { __totalItems , __pageOfItems } = this.state;
    return (
      <div className="col-md-12 p-0">
        <div className=" Find-Business-inner notification-page-li-div">
          <h3>Notifications</h3>
          <div className="notification-setting tab-pane-scroll single-table-height">
            {__pageOfItems && __pageOfItems.length > 0 ? (
              __pageOfItems.map((value, key) => {
                let parsedObj = IsJsonString(value.content)
                  ? JSON.parse(value.content)
                  : {};
                let message =
                  value.type === "meeting"
                    ? `You're invited in a meeting!`
                    : parsedObj.message;
                let time =
                  value.type === "meeting"
                    ? `${parsedObj.date} ${parsedObj.start_time}`
                    : value.creationdate;
                return (
                  <a
                    className="dropdown-item notification-page-dropdown-item"
                    key={`not${key}`}
                  >
                    <div className="notification-drop-list">
                      <div className="notifiaction-ico">
                        <img
                          src={`./../assets/images/${
                            value.type === "Payment"
                              ? "payment-credit-card.svg"
                              : value.type === "missed_call"
                              ? "phone-missed.svg"
                              : "calendar-call-info.svg"
                          }`}
                          alt="1"
                        />
                      </div>
                      <div className="notifiaction-content">
                        <div className="notificaion-flx-div"> 
                          <span class="notifiaction-name">
                            <h4> {value.title} </h4> 
                          </span>
                          <span className="sent-message-text"> 
                          </span>
                        </div>
                        <div className="notification-text-full">
                          <p> {message} </p>
                        </div>
                        <div className="notificaion-flx-div"> 
                          <span className="sent-message-text">
                            {moment(convertUtcToLocalTime(time))
                              .locale("en")
                              .calendar()}
                          </span>
                        </div>
                      </div>
                      <div className="notifiaction-action">
                        <div className=" number-collase-action">
                          <a
                            href={`#${key}`}
                            data-toggle="collapse"
                            aria-expanded="false"
                            className="dropdown-toggle collapsed"
                          >
                            <i className="fas fa-ellipsis-v"></i>
                          </a>
                          <ul
                            className="list-unstyled number-dr-collapse collapse"
                            id={key}
                          >
                            <li>
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={() => this.deleteNotificationHandler(value)}
                              >
                                <img
                                  src="./../assets/images/number-drop-trash-2.svg"
                                  alt="1"
                                />
                                Delete Notification
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })
            ) : (
              <a className="dropdown-item">
                <div className="notification-drop-list">
                  <div className="notification-content">
                    <div className="notificaion-flx-div">
                      <span className="notification-name">
                        <h4> No notification available... </h4>
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            )}
          </div>
          <Pagination data={__totalItems} currentData={this.UpdateData} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notification_listing: state?.notification?.notification_listing ?? [],
});

export default connect(mapStateToProps)(Notification);
