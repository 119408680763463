import React, { Component } from "react";
import { connect } from "react-redux"; 
import { store } from "../../../../Services";
import { FetchAssignNumber } from "../../../../Services/Actions/settingActions";
import Modal from "../../../../Component/Modal";
import { formatDIDNumber } from "../../../../Utils/Common";
class AssignToModal extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      selectedTeamMemberName: "",
      profilePic: "",
      team_member: [],
      departmentID: "",
    };
  }

  componentDidMount = ()=>{
    const { numberDetails: { assigner } } = this.props; 
    let assigner_array = []
      assigner && assigner.map(value=>{
        return assigner_array.push(value.customerID)
      })
      this.setState({
        team_member:assigner_array
      }) 
  }

  TeamMemberHandler = (value) => {
    this.setState({
      selectedTeamMemberName: value?.name,
      profilePic: value?.profile_picture,
      departmentID: value?.departmentID,
    });
  };

  assignNumberHandler = async (assignNumber) => {
    const { team_member } = this.state; 
    let data = {
      assign_to: "member",
      assignID: team_member,
      assign_number: assignNumber,
    };
    let res = await FetchAssignNumber(data,store.dispatch) 
    if (res === 1) {
      this.props.handleClose();
    } 
  };

  render() { 
    const {
      openModal,
      handleClose,
      nxatel: { extension_listing },
      numberDetails: { did_number, countryImage },
    } = this.props;
    const { team_member } = this.state;
    return (
      <>
        <Modal
          open={openModal}
          CloseModal={handleClose}
          title={""}
          showActiveClass={openModal}
          size={`md`}
        >
          <div className="assign-modal-div">
            <h3>
              <span>Assign phone number:</span>
              <span className="assign-to-num">
                <img
                  src={
                    countryImage
                      ? `./../assets/images/${countryImage}`
                      : "./../assets/images/mask.png"
                  }
                  alt="1"
                />
                {formatDIDNumber(did_number)}
              </span>
            </h3>
            <h5 className="assign-modal team-member-title">
              Select Team Members
            </h5>
            <div className="invite-via-member-list-div">
              {extension_listing &&
                extension_listing.map((value, key) => {
                  return (
                    <div
                      className="invite-via-member-list"
                      key={`members${key}`}
                    >
                      <div className="invite-member-name">
                        <span>
                          {value.profile_picture &&
                              <img
                                src={value.profile_picture}
                                alt="1"
                              />  
                          }
                          {!value.profile_picture && 
                            value.firstname
                            .match(/\b(\w)/g)
                            .join("")
                            .toUpperCase()
                          } 
                        </span>
                        <h5>{`${value.firstname}(${value.user_extension})`}</h5>
                      </div>
                      <div
                        className={`select-member-span ${
                          team_member.includes(value.customerID) ? "active" : ""
                        }`}
                        onClick={() => {
                          team_member.includes(value.customerID)
                            ? this.setState((prevState) => ({
                                team_member: prevState.team_member.filter(
                                  (val) => val != value.customerID
                                ),
                              }))
                            : this.setState((prevState) => ({
                                team_member: [
                                  ...prevState.team_member,
                                  value.customerID,
                                ],
                              }));
                        }}
                      >
                        <img src="./../assets/images/tick-assign.svg" alt="1" />
                      </div>
                    </div>
                  );
                })}
            </div> 
          </div>
          <div className="modal-footer add-modal-modal-footer">
            <button
              type="button"
              className="btn btn-meet-join"
              onClick={() => this.assignNumberHandler(did_number)}
            >
              Confirm
            </button>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  nxatel: state?.setting,
});

export default connect(mapStateToProps)(AssignToModal);
