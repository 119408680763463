import React, { useEffect, useState } from "react";
import { store } from "../../Services";
import { ShowLoader } from "../../Services/Actions/userActions";

function ChatSupport(props) {
  const ReturnBaseUrl = `https://pay-dev.nxa.tel/Webforms/ZohoCustomerServiceChat.aspx`;

  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setMounted(true);
    if (loading) {
      ShowLoader(true);
    }
  }, []);

  const hideLoader = () => {
    setLoading(false);
    ShowLoader(false);
  };

  return (
    <>
      {mounted && (
        <>
          <iframe src={`${ReturnBaseUrl}`} title="chat" onLoad={hideLoader} />
        </>
      )}
    </>
  );
}

export default ChatSupport;
