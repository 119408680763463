import React, { Component } from "react";
import moment from "moment";
import { TeamListing } from "../../../../Services/Actions/callActions";
import { DidNumberListing } from "../../../../Services/Actions/reportActions";
import {
  CallFlowListing,
  ExtensionListing,
  IVRMenuList,
  RuleListing,
} from "../../../../Services/Actions/settingActions";
import { convertUtcToLocalTime, countryList, formatDIDNumber, removeBackdrop } from "../../../../Utils/Common";
import Pagination from "../../../../Component/Pagination";
import { connect } from "react-redux";
import Tooltips from "../../../../Component/Tooltips";
import TeamMemberSlider from "../../../Calls/Team/TeamMemberSlider";
import TeamSlider from "../../../Calls/Team/TeamSlider";
import { Link } from "react-router-dom";
import CreateNumber from "./CreateNumber";
import { store } from "../../../../Services";
import CallFlowSlider from "../CallFlow/CallFlowSlider";
import AddIVRCallFlow from "../IVR/AddIVRCallFlow";
import AssignToModal from "./AssignToModal";
import ForwardToDeviceModal from "./ForwardToDeviceModal";
import DeleteNumberModal from "./DeleteNumberModal";
import NumberSettingModal from "./NumberSettingModal";

class Number extends Component {
  constructor(props) {
    super(props); 
    
    this.state = {
      addCallFlow: false,
      addTeam: false,
      addTeamMember: false,
      addIvr: false,
      modalType: "",
      openModal: false,
      assignNumber: "",
      countryImage: "",
      createNumber: false,
      didID: "",
      numberDetails: {},
      pageofItems: [],
      totalItems: [],
    };
  }

  componentDidMount = async () => { 
    DidNumberListing(store.dispatch, "phoneSettings");
    RuleListing(store.dispatch);
  };

  handleClose = () => {
    this.setState({ modalType: "", openModal: false }, () =>
      removeBackdrop()
    );
  };

  onSearch = (e) => {
    const { did_number_list } = this.props;
    const value = e.target.value.toString().toLowerCase();
    this.setState({
      search: value,
      totalItems: did_number_list.filter(
        (val) =>
          val.did_number.includes(value) ||
          val.name.toLowerCase().includes(value)
      ),
    });
  };

  componentDidUpdate(prevProps) {
    const { did_number_list } = this.props;
    if (prevProps.did_number_list !== did_number_list) {
      this.setState({ totalItems: did_number_list });
    }
  }

  closeCreateNumber = () => {
    this.setState({
      createNumber: false,
    });
  };

  UpdateData = (pageofItems) => {
    this.setState({ pageofItems });
  };

  ViewForwardToSlider = async (value) => {
    if (value.type === "call-flow") {
      let res = await CallFlowListing(value?.callflowID, store.dispatch);
      if (res === 1) {
        this.setState({
          addCallFlow: true,
        });
      }
    } else {
      switch (value?.forward_to) {
        case "EXTENSION": {
          let res = await ExtensionListing(
            value?.forward_to_id,
            store.dispatch
          );
          if (res === 1) {
            this.setState({
              addTeamMember: true,
            });
          }
          break;
        }
        case "DEPARTMENT": {
          let res = await TeamListing(value?.forward_to_id, store.dispatch);
          if (res === 1) { 
            this.setState({
              addTeam: true,
            });
          }
          break;
        }
        case "IVR": {
          let res = await IVRMenuList(value?.forward_to_id, store.dispatch);
          if (res === 1) {
            this.setState({
              addIvr: true,
            });
          }
          break;
        }
      }
    }
  };

  render() {
    const {
      addCallFlow,
      modalType,
      openModal,
      numberDetails,
      createNumber,
      pageofItems,
      totalItems,
      search,
      addIvr,
      addTeam,
      addTeamMember,
    } = this.state;
    let {
      call_flow_list,
      SetTab,
      rule_listing,
      individual_extension_detail,
      team_listing,
      ivr_menu_listing,
      extension_listing
    } = this.props;
    return (
      <>
        <div className="phone-system-inner-main-div">
          <div className="phone-system-inner-div">
            <div className="phone-system-inner-head">
              <div className="ps-inner-head-left">
                <h3>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => SetTab("phone")}
                    className="phone-system-name"
                  >
                    Phone Settings
                  </span>
                  <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                  <span>Numbers</span>
                </h3>
              </div>

              <div className="ps-inner-head-right">
                <span className="search-ps-inner">
                  <img src="./../assets/images/search-dash.svg" alt="1" />
                  <input
                    className="form-control"
                    type="text"
                    name="search"
                    value={search}
                    onChange={this.onSearch}
                    placeholder="Search"
                  />
                </span>
                <Link
                  to="#"
                  className="create-ps-num-btn"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ createNumber: true })}
                >
                  Create Number
                </Link>
              </div>
            </div>
            {/* table start */}
            <div className="phone-system-inner-table">
              <div className="table-responsive call-info-scroll single-table-height">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Creation Date</th>
                      <th>Name of Number</th>
                      <th>Forward To</th>
                      <th>Assigned To</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageofItems &&
                      pageofItems.map((value, index) => {
                        let date_time = convertUtcToLocalTime(
                          value.creationdate
                        );
                        let formattedDate = moment(date_time)
                          .locale("en")
                          .format("ll");
                        let formattedTime = moment(date_time)
                          .locale("en")
                          .format("LT");
                        let __countryImage =
                          countryList &&
                          countryList.filter(
                            (val) => val.name === value.did_country
                          );
                        __countryImage = __countryImage?.[0]?.country_image;
                        return (
                          <tr key={index}>
                            <td>{`${formattedDate} ${formattedTime}`} </td>
                            <td>
                              <span className="ps-name-number">
                                <img
                                  src={
                                    __countryImage
                                      ? `./../assets/images/${__countryImage}`
                                      : "./../assets/images/Mask.png"
                                  }
                                  alt="1"
                                />
                                <span>
                                  <h3>{formatDIDNumber(value.did_number)}</h3>
                                  <p>{value.did_name}</p>
                                </span>
                              </span>
                            </td>
                            <td>
                              {value.forward_to ? (
                                <Link
                                  to="#"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.ViewForwardToSlider(value)
                                  }
                                > 
                                  {value.type === "call-flow"
                                    ? <> Call Flow <i className="phone-system-i">({value.callflowName})</i></>
                                    : value.forward_to === "EXTENSION"
                                    ?<> TEAM MEMBER <i className="phone-system-i">({value.forward_to_name})</i></>
                                    : value.forward_to === "DEPARTMENT"
                                    ?  <> TEAM <i className="phone-system-i">({value.forward_to_name})</i></>
                                    : value.forward_to === "PHONE"
                                    ? <> PHONE <i className="phone-system-i">({value.forward_to_name})</i></>
                                    : value.forward_to === "IVR"
                                    ? <> IVR <i className="phone-system-i">({value.forward_to_name})</i></>
                                    : ""
                                  }
                                </Link>
                              ) : (
                                "NA"
                              )}
                            </td>
                            <td className="assign-to-td">
                              {value.assigner && value.assigner.length === 0 && `No Members`}
                              {value.assigner instanceof Array &&
                                value.assigner.map((data, keys) => {
                                  const profile_image = extension_listing?.find(val=>val.customerID === data.customerID)?.profile_picture??""
                                  let colors =
                                    keys % 3 === 0
                                      ? "yellow"
                                      : keys % 3 === 1
                                      ? "blue"
                                      : "pink";
                                  return (
                                    <>
                                      {profile_image && keys <= 2 && (
                                        <Tooltips
                                          placement="top"
                                          heading={data.firstname}
                                        >
                                          <span className={`assign-to-span`} key={keys}>
                                            <img
                                              src={ profile_image
                                                  ? profile_image
                                                  : "./../assets/images/Mask.png"
                                              }
                                              alt="1"
                                            />
                                          </span>
                                        </Tooltips>
                                      )}
                                      {!profile_image && keys <= 2 && (
                                        <Tooltips
                                          placement="top"
                                          heading={data.firstname}
                                        >
                                          <span
                                            key={keys}
                                            className={`assign-to-span assign-to-color-${colors}`}
                                          >
                                            <h5>
                                              {data.firstname
                                                .match(/\b(\w)/g)
                                                .join("")
                                                .toUpperCase()}
                                            </h5>
                                          </span>
                                        </Tooltips>
                                      )}

                                      {keys === 3 && (
                                        <Tooltips
                                          placement="top"
                                          heading={`+${
                                            value.assigner.length - 3
                                          } more`}
                                        >
                                          <span className="assign-to-span assign-to-color-yellow" key={keys}>
                                            <h5> M </h5>
                                          </span>
                                        </Tooltips>
                                      )}
                                    </>
                                  );
                                })}
                            </td>
                            <td align="right">
                              <div className=" number-collase-action">
                                <a
                                  href={`#${value.didID}`}
                                  data-toggle="collapse"
                                  aria-expanded="false"
                                  className="dropdown-toggle collapsed"
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </a>
                                <ul
                                  className="list-unstyled number-dr-collapse collapse"
                                  id={value.didID}
                                >
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      onClick={() =>
                                        this.setState({
                                          modalType: "AssignTo",
                                          openModal: true,
                                          numberDetails: {
                                            did_number: value.did_number,
                                            countryImage: __countryImage,
                                            assigner: value.assigner,
                                          },
                                        })
                                      }
                                    >
                                      <img
                                        src="./../assets/images/number-chat-ico.svg"
                                        alt="1"
                                      />
                                      Assign to
                                    </Link>
                                  </li>
                                  {value.type !== "call-flow" &&
                                    value.type !== "ivr" && (
                                      <li>
                                        <Link
                                          to="#"
                                          className="dropdown-item"
                                          onClick={() =>
                                            this.setState({
                                              modalType: "ForwardTo",
                                              openModal: true,
                                              numberDetails: {
                                                did_number: value.did_number,
                                                name: value.did_name,
                                                countryImage: __countryImage,
                                                assigner: value.assigner,
                                                phonesystemID: value.phonesystemID,
                                              },
                                            })
                                          }
                                        >
                                          <img
                                            src="./../assets/images/phone-forwarded.svg"
                                            alt="1"
                                          />
                                          Forward to Device
                                        </Link>
                                      </li>
                                    )}
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      onClick={() =>
                                        this.setState({
                                          modalType: "Settings",
                                          openModal: true,
                                          numberDetails: value
                                        })
                                      }
                                    >
                                      <img
                                        src="./../assets/images/number-settings-drop.svg"
                                        alt="1"
                                      />
                                      Settings
                                    </Link>
                                  </li> 
                                  {value.forward_to && <li>
                                    <Link
                                      to="#"
                                      className="dropdown-item" 
                                      onClick={() =>
                                        this.setState({
                                          modalType: "DeleteNumber", 
                                          openModal: true,
                                          numberDetails: {
                                            type:"remove-from-forward",
                                            did_number: value.did_number,
                                            didID: value.didID,
                                            countryImage: __countryImage,
                                          },
                                        })
                                      }
                                    >
                                      <img
                                        src="./../assets/images/x-square.svg"
                                        alt="1"
                                      />
                                      Remove from forwarding
                                    </Link>
                                  </li> }
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      onClick={() =>
                                        this.setState({
                                          modalType: "DeleteNumber", 
                                          openModal: true,
                                          numberDetails: {
                                            type:"delete-number",
                                            did_number: value.did_number,
                                            didID: value.didID,
                                            countryImage: __countryImage,
                                          },
                                        })
                                      }
                                    >
                                      <img
                                        src="./../assets/images/number-drop-trash-2.svg"
                                        alt="1"
                                      />
                                      Delete Number
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <Pagination data={totalItems} currentData={this.UpdateData} />
            </div> 
          </div>
        </div>

        <CreateNumber
          createNumber={createNumber}
          closeCreateNumber={this.closeCreateNumber}
        />

        <TeamMemberSlider
          viewOnly={true}
          addTeamMember={addTeamMember}
          closeTeamMemberSlider={() => this.setState({ addTeamMember: false })}
          extensionDetails={individual_extension_detail?.[0]}
        />

        <TeamSlider
          viewOnly={true}
          addTeam={addTeam}
          closeTeamSlider={() => this.setState({ addTeam: false })}
          team_details={team_listing?.[0]}
        />

        <AddIVRCallFlow
          viewOnly={true}
          addIVR={addIvr}
          editIVR={addIvr && ivr_menu_listing?.[0]}
          closeAddIVR={() => this.setState({ addIvr: false })}
        />

        <CallFlowSlider
          viewOnly={true}
          openCallFlowSlider={addCallFlow}
          editIVR={addCallFlow && call_flow_list?.[0]}
          setOpenCallFlowSlider={() => this.setState({ addCallFlow: false })}
        />

        {openModal && modalType === "AssignTo" && (
          <AssignToModal
            openModal={openModal}
            handleClose={this.handleClose}
            numberDetails={numberDetails}
          />
        )}

        {openModal && modalType === "ForwardTo" && (
          <ForwardToDeviceModal
            openModal={openModal}
            handleClose={this.handleClose}
            numberDetails={numberDetails}
            rule_listing={rule_listing}
          />
        )}

        {openModal && modalType === "DeleteNumber" && (
          <DeleteNumberModal
            openModal={openModal}
            handleClose={this.handleClose}
            numberDetails={numberDetails}
          />
        )}

        {openModal && modalType === "Settings" && (
          <NumberSettingModal
            openModal={openModal}
            handleClose={this.handleClose}
            extension_listing={extension_listing}
            numberDetails={numberDetails}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  did_number_list: state?.report?.did_number_list ?? [],
  rule_listing: state?.setting?.rule_listing ?? [],
  extension_listing:state?.setting?.extension_listing ?? [],
  individual_extension_detail:state?.setting?.individual_extension_detail ?? [],
  team_listing: state?.call?.team_listing ?? [],
  ivr_menu_listing: state?.setting?.ivr_menu_listing ?? [],
  call_flow_list: state?.setting?.call_flow_list ?? [],
});

export default connect(mapStateToProps)(Number);
