import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { store } from "../../Services";
import { 
    BuyNxaPlan, 
    CurrencyListing, 
    InitiatePayment, 
    InitiatePaymentHandler, 
    RepeatedPayment 
} from "../../Services/Actions/signupActions";
import authService from "../../Utils/authService";
import { random128Hex, randomStringFunc } from "../../Utils/Common";
import { withRouter } from "../../Utils/withRouter";
import CardPayment from "./CardPayment";
import ContactBar from "./ContactBar";
import PageHeader from "./PageHeader";
import ProgressBar from "./ProgressBar";
import VoucherPayment from "./VoucherPayment";

const initialState = { 
    verified: false,
    payment_type: "card",
    currency: " ZAR", 
};

function OrderSummary(props) {
    const [state, setState] = useState(initialState); 
 
    const {currency_list, calculated_plan_summary} = props
    const { 
        plan_expiration_date, 
        vatCost, 
        payable_amount, 
        amount, 
        did_number, 
        plan_users, 
        plan_name, 
        currency, 
        duration, 
        trial, 
        planID,
        voucher_code,
        voucher_amount
    } = calculated_plan_summary

    const { 
        firstname = "unknown", 
        lastname = "unknown", 
        email, 
        phone, 
        registrationId, 
        address, 
        city, 
        zipcode, 
        country_code 
    } = authService?.getUserDetail();
 

    useEffect(() => { 
        CurrencyListing(store.dispatch); 
    }, []);

    useEffect(() => {
        if (calculated_plan_summary) { 
        setState((prev) => ({
            ...prev,
            currency,
        }));
        }
    }, [calculated_plan_summary]); 

    const ConfirmAndPay = async (price) => { 
        const {payment_type,currency} = state
        let response;
        if (payment_type === "card" || payment_type === "voucher") {
        let order_details = {  type: "C", payment_type: "Sign up", path_name: "fetch-payment" };
            order_details = voucher_code ? 
                                { ...order_details, 
                                    voucherType: "NxaVoucher",
                                    voucher:voucher_code,
                                    voucher_discount: voucher_amount,
                                }
                            : order_details;
        if (registrationId) {
            let registerArray = [];
            registrationId.split(",").map((value) => {
            registerArray = [...registerArray, { id: value }];
            });
            let details = {
                amount: trial === "Y" || price === 0 ?  0 : price,
                currency: currency,
                paymentType: trial === "Y" || price === 0 ? "" : "DB",
                StandingInstruction_mode: "REPEATED",
                StandingInstruction_type: "UNSCHEDULED",
            };
            let formBody = [];
            for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            for (var i = 0; i < 5; i++) {
            var encodedKey = encodeURIComponent("registrations[" + i + "].id");
            var encodedValue = encodeURIComponent(registerArray?.[i]?.id ?? "");
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            response = await RepeatedPayment(formBody, order_details, store.dispatch);
        } else {
            let data = {
            Currency: currency,
            Price: trial === "Y" || price === 0 ? 0 : price,
            RecurringType: "INITIAL",
            CustomerName: firstname,
            CustomerSurname: lastname,
            CustomerEmail: email,
            CustomerMobile: phone,
            CreateRegistration: true,
            PaymentType: trial === "Y"  || price === 0 ? "" : "DB",
            ShippingStreet1: address,
            ShippingStreet2: address,
            ShippingCity: city,
            ShippingState: authService?.getUserDetail()?.state,
            ShippingPostcode: zipcode,
            ShippingCountry: country_code,
            };
            response = await InitiatePayment(data, order_details, store.dispatch);
        }
        } else {
            let data = {
                CountryCode: currency,
                CurrencyCode: currency,
                Amount: trial === "Y" ? 0.0 : price,
                TransactionReference: random128Hex(),  
                BankReference: randomStringFunc(16, "Aa"),
                isTest: true,
            };
            response = await InitiatePaymentHandler({ token: data.TransactionReference, type: "E" });
        }
        if (response === 1) {
            setState(prev=>({
                ...prev,
                verified: true
            })) 
        }
    }; 

    const CurrencyHandler = async(event) =>{ 
        let sendData = { planID, duration, plan_users, currency:event.target.value, trial}  
        let response = await BuyNxaPlan(sendData,store.dispatch) 
        if(response?.code === 1){
            setState(prev=>({
                ...prev,
                currency: event.target.value
            })) 
        }
    }

    const voucherPaymentHandler = ()=>{
        console.log("payment card type")
        setState(prev=>({
            ...prev,
            payment_type: "card"
        })) 
    }

    return (
        <div className="pricing-step pb-5">
        <div className="sign-up-step-bar">
            <div className="container">
            <PageHeader />
            <ContactBar />
            <ProgressBar active="select-package" />
            </div>
        </div>
        <div className="business-number  text-center payment-summary-head">
            <div className="container">
            <div className="payment-summary-head-inner">
                <h1 className="font-36 color-7c2b fw-400">
                Hi {`${firstname} ${lastname}`}, Order Summary
                </h1>
                {!state?.verified && (
                <div className="form-group w-30 currency pt-4 ml-auto mr-2">
                    <select
                        className="form-control  color-293F"
                        id="exampleFormControlSelect1"
                        value={state?.currency}
                        onChange = {event => CurrencyHandler(event)} 
                    >
                        {currency_list && currency_list.map((value, index) => {
                            return (
                                <option key={index} value={value.currencyCode}>
                                {value.currencyCode}
                                </option>
                            );
                        })}
                    </select>
                </div>
                )}
            </div>
            </div>
        </div>
        {!state?.verified && (
            <div className="payment-box-section">
            <div className="container">
                <div
                className={`payment-summary-main-box ${
                    state?.payment_type === "voucher" ? "main-voucher-div" : ""
                }`}
                >
                <div className="payment-summary-box">
                    <h4>Double check you have everything you need</h4>
                    <div className="order-list order-list-bottom-border">
                    <div className="order-items-bx">
                        <h5>Phone Number</h5>
                        <p>{did_number}</p>
                    </div>
                    <div className="order-items-price">
                        <h6>FREE</h6>
                    </div>
                    </div>
                    <div className="order-list order-list-bottom-border">
                    <div className="order-items-bx">
                        <h5>{`${plan_name} Plan`}</h5>
                        <p>{`${plan_users} Users`}</p>
                    </div>
                    <div className="order-items-price">
                        <h6>{`${state?.currency === "USD" ? "$" : "R"} ${amount}`}</h6>
                    </div>
                    </div>
                    <div className="order-list order-list-bottom-border">
                    <div className="order-items-bx">
                        <h5>Value Added items</h5>
                        <Link to="choose-plan" className="value-add-link">
                        Add
                        </Link>
                    </div>
                    <div className="order-items-price">
                        <h6>{`${state?.currency === "USD" ? "$" : "R"} 0.00`}</h6>
                    </div>
                    </div>
                    <div className="order-list">
                    <div className="order-items-bx">
                        <h5>Recurring Payment Type</h5>
                    </div>
                    <div className="order-items-price">
                        <h6 className="">
                            {duration === 12  ? `Yearly` : duration === 24 ? `2 Yearly` : `Monthly`}
                        </h6>
                    </div>
                    </div>
                    <div className="order-list">
                    <div className="order-items-bx">
                        <h5>Total Excl VAT</h5>
                        <p>VAT</p>
                    </div>
                    <div className="order-items-price">
                        <h6>{`${state?.currency === "USD" ? "$" : "R"} ${amount}`}</h6>
                        <h6 className="mt-2 float-right">{`${state?.currency === "USD" ? "$" : "R"} ${vatCost}`}</h6>
                    </div>
                    </div>
                    {trial === "N" && (
                    <div className="order-list order-list-total">
                        <div className="order-items-bx">
                        <h5>Discounts</h5>
                        <Link
                            to="#"
                            className="Apply-Voucher"
                            style={{
                            cursor: "pointer",
                            }}
                            onClick={() =>
                                setState(prev=>({
                                    ...prev,
                                    payment_type: "voucher",
        
                                })) 
                            }
                        >
                            Apply Voucher / Promotional Code
                        </Link>
                        </div>
                        {voucher_code && (
                        <div className="order-items-price">
                            <h6>{`${state?.currency === "USD" ? "$" : "R"} ${voucher_amount}`}</h6>
                        </div>
                        )}
                    </div>
                    )}
                    {trial === "Y" && (
                    <div className="order-list order-list-total">
                        <div className="order-items-bx">
                        <h5>Free trial expires:</h5>
                        </div>
                        <div className="order-items-price">
                        <h6>{plan_expiration_date}</h6>
                        </div>
                    </div>
                    )}
                    {trial === "N" && (
                    <div className="order-list order-list-total">
                        <div className="order-items-bx">
                        <h5>Total due today:</h5>
                        </div>
                        <div className="order-items-price">
                        <h6>{`${state?.currency === "USD" ? "$" : "R"} ${payable_amount}`}</h6>
                        </div>
                    </div>
                    )}
                    {trial === "Y" && (
                    <>
                        <div className="order-list order-list-total">
                        <div className="order-items-bx">
                            <h5>{`Total due after trial:`}</h5>
                        </div>
                        <div className="order-items-price">
                            <h6>{`${state?.currency === "USD" ? "$" : "R"} ${payable_amount}`}</h6>
                        </div>
                        </div>
                        <div className="order-list order-list-taxes">
                        <div className="order-items-bx">
                            <h5>Taxes & fees may apply.</h5>
                        </div>
                        </div>
                    </>
                    )}
                    <div className="order-list order-list-total order-list-total-discount">
                    <div className="order-items-bx">
                        <h5> {`Save upto 14% with an annual plan.`}{" "}
                        </h5>
                    </div>
                    <div className="order-items-price">
                        <Link to="/choose-plan" className="change-disscount-link">
                        Change
                        <i className="fas fa-chevron-right ml-2"></i>
                        </Link>
                    </div>
                    </div>

                    {/* ---- */}
                </div>
                <div className={`payment-card-section ${ state?.payment_type === "voucher" ? "voucher-bx-section" : "" }`} >
                    {state?.payment_type !== "voucher" && (
                        <>
                            <h3>Select a payment method below.</h3>
                            <div className="pay-card-box">
                            <Link
                                to="#"
                                className={`bg-yellow ${
                                    state?.payment_type === "card" ? "active" : ""
                                }`}
                                onClick={() =>
                                    setState(prev=>({
                                        ...prev,
                                        payment_type: "card",
            
                                    }))  
                                }
                            >
                                <img src="./../assets/images/card.svg" alt="card" />
                                card
                            </Link>
                            <Link
                                to="#"
                                className={`bg-dgray ${
                                    state?.payment_type === "debit_order" ? "active" : ""
                                }`}
                                onClick={() =>
                                    setState(prev=>({
                                        ...prev,
                                        payment_type: "debit_order",
            
                                    })) 
                                }
                            >
                                <img
                                src="./../assets/images/debit-order.svg"
                                alt="debit order"
                                />
                                Debit order
                            </Link>
                            </div>
                        </>
                    )}
                    {state?.payment_type === "voucher" && (
                        <VoucherPayment 
                            calculated_plan_summary = {calculated_plan_summary}  
                            voucherPaymentHandler = {voucherPaymentHandler}
                        />
                    )}
                    <div className="col-md-12 confirm-btn-div text-center">
                    {state?.payment_type === "voucher" && (
                        <Link
                            to="#"
                            className="btn btn-voucher-cancel"
                            onClick={() =>
                                setState(prev=>({
                                    ...prev,
                                    payment_type: "card",

                                })) 
                            }
                        >
                        Cancel
                        </Link>
                    )}
                    <Link
                        to="#"
                        className="btn btn-orange"
                        onClick={() =>ConfirmAndPay(payable_amount)}
                    >
                        {trial === "Y" || payable_amount === 0 ? "Confirm and Register" : "Confirm and Pay"}
                    </Link>
                    </div>
                </div>
                </div>
            </div>
            </div>
        )}
        {(state?.payment_type === "card" || state?.payment_type === "voucher") && state?.verified && (
            <CardPayment />
        )}
        </div>
    );
    }

    const mapStateToProps = (state) => {
    const { signup: {currency_list = [],calculated_plan_summary = {} } = []} = state;
    return {
        currency_list,
        calculated_plan_summary,
    };
    };

export default connect(mapStateToProps)(withRouter(OrderSummary));
