import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "../../../Component/Modal";
import { store } from "../../../Services";
import {
  AssignedNumberToExtension, 
} from "../../../Services/Actions/callActions";
import { ExtensionListing } from "../../../Services/Actions/settingActions";

function AssignedModal(props) {
  const {
    OpenAssignModal,
    closeAssignModal,
    destination_number,
    extension_listing,
    addMemberModalOpen,
    assigned_number_list,
  } = props;
  const [pageOfItems, setPageOfItems] = useState([]);
  const [member, setMember] = useState("");

  useEffect(async () => {
    ExtensionListing(0,store.dispatch);
  }, []);

  useEffect(() => {
    setPageOfItems(extension_listing);
  }, [extension_listing]);

  const AssignedNumber = async (user_extension, name) => {
    let array = [];
    let response = await AssignedNumberToExtension(
      {
        phone: destination_number,
        extension: [...array, user_extension],
      },
      store.dispatch
    );

    if (response === 1) {
      closeAssignModal();
    }
  };

  const FilterMember = (member) => {
    let updatedList = pageOfItems;
    updatedList = pageOfItems.filter((value) => {
      return value.name.toLowerCase().search(member) !== -1;
    });

    setPageOfItems(member ? updatedList : extension_listing);
  };

  const filterMemberHandler = (e) => {
    setMember(e.target.value);
    FilterMember(e.target.value);
  };

  return (
    <>
      <div>
        <Modal
          open={OpenAssignModal}
          size={`sm`}
          CloseModal={closeAssignModal}
          title={`Assign To`}
          showActiveClass={true}
        >
          {extension_listing && extension_listing.length === 0 && (
            <>
              <div className="row account-label account">
                <div className="assign-modal-blank">
                  <img
                    src="./../assets/images/assign-modal-vector.svg"
                    alt="1"
                  ></img>
                  <h4>
                    Currently you don’t have any team members added. Add
                    <Link
                      to="#"
                      className="ml-2"
                      style={{ cursor: "pointer" }}
                      onClick={addMemberModalOpen}
                    >
                      Team member
                    </Link>
                  </h4>
                  <Link
                    to="#"
                    className="add-team-member"
                    style={{ cursor: "pointer" }}
                    onClick={addMemberModalOpen}
                  >
                    Add Team Member
                  </Link>
                </div>
              </div>
            </>
          )}
          {extension_listing && extension_listing.length > 0 && (
            <>
              <div className="assign-modal-list">
                <div className="search-assign">
                  <img src="./../assets/images/search-dash.svg" alt="1" />
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    name="member"
                    value={member}
                    onChange={(e) => filterMemberHandler(e)}
                  />
                </div>
                <ul className="assign-modal-list-ul">
                  {pageOfItems &&
                    pageOfItems.map((value, index) => {
                      const name = value?.name ?? "";
                      const formattedName =
                        name !== "" ? name.match(/\b(\w)/g) : "";
                      const __AssignedMemberList = assigned_number_list && assigned_number_list.filter(
                        (assigned_number) =>
                          assigned_number.uniquecode === value.uniquecode
                      );
                      return (
                        <li
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            __AssignedMemberList?.length === 0
                              ? AssignedNumber(value.user_extension, name)
                              : ""
                          }
                        >
                          <span className="assign-li-profile">
                            {__AssignedMemberList?.length === 0 ? (
                              <>
                                {value.profile_picture && (
                                  <img src={value.profile_picture} alt="a" />
                                )}
                                {!value.profile_picture && <h4>{formattedName}</h4>}
                              </>
                            ) : (
                              <img
                                src="./../assets/images/select-assign-member.svg"
                                alt="a"
                              />
                            )}
                          </span>
                          <span className="assign-li-text">
                            <h5>{`${name}`} <i className="italic-font-color">{`(${value.user_extension})`}</i></h5> 
                            <p>{value.phone}</p>
                          </span>
                        </li>
                      );
                    })}
                </ul>
                <Link
                  to="#"
                  className="add-team-member"
                  style={{ cursor: "pointer" }}
                  onClick={addMemberModalOpen}
                >
                  Add Team Member
                </Link>
              </div>
            </>
          )}
        </Modal>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    extension_listing: state?.setting?.extension_listing ?? [],
  };
};
export default connect(mapStateToProps)(AssignedModal);
