import React, {Component} from 'react' 
import { connect } from 'react-redux'; 
import SimpleReactValidator from 'simple-react-validator';
import { store } from '../../Services'; 
import { SelectedChatType, SelectedChatUser } from '../../Services/Actions/callActions';

const initialState = {member:"",message:""};
class MemberChatPop extends Component {
    constructor(props) {
        super(props);
        this.state = initialState; 
        this.validator = new SimpleReactValidator();
    }

    onChangeHandler = e => {
        this.setState({[e.target.name]:e.target.value});
    }

    InitiateChat = () => {
        if(!this.validator.allValid()){
            this.validator.showMessages();
            this.forceUpdate();
            return ;
        }
        const {member,message} = this.state;
        const {extension_listing} = this.props;
        let index = extension_listing.findIndex(value=>value.uniquecode===member)
        let myextension = extension_listing?.[index];
        let userData = {
            customerID:myextension?.customerID??'',
            nxatel_user: "Y",
            profile_picture:myextension?.profile_picture??'',
            email:myextension?.email??'',
            phone:myextension?.phone??'',
            user_extension:myextension?.user_extension??'',
            name:myextension?.firstname??'',
            uniquecode:myextension?.uniquecode??'',
            message
        }
        this.setState({member:"",message:""},()=>{
            SelectedChatUser(userData,store.dispatch);
            SelectedChatType("one_to_one_chat",store.dispatch) 
            window.$("#exampleModal").modal("hide")
        })        
    }

    render() {
        const {member,message} = this.state;
        const {extension_listing} = this.props;
        return (
            <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md2" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">New Message</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body new-messge-body-modal">
                        <form className="new-message-form ">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group ">
                                    <select className="form-control" name="member" value={member} onChange={this.onChangeHandler}>
                                        <option value="">Select</option>
                                        {extension_listing && extension_listing.map((value,keys)=>{
                                            return (
                                                <option key={`extensions${keys}`} value={value.uniquecode}>{value.name}</option>
                                            )
                                        })}                                        
                                    </select> 
                                    {this.validator.message("Contact",member,"required")}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="col-form-label">Enter message</label>
                                    <textarea rows="4" placeholder="Type your message here..." className="form-control" value={message} onChange={e=>this.setState({message:e.target.value})}/>
                                    {this.validator.message("Message",message,"required")}
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                    <div className="modal-footer  new-message-modal-footer">
                        <button type="button" className="btn message-cancel-btn" data-dismiss="modal" onClick={()=>this.setState({initialState})}>Cancel</button>
                        <button type="button" className="btn btn-create-message" onClick={this.InitiateChat}>Start Chat</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        extension_listing: state?.setting?.extension_listing,
    }
}

export default connect(mapStateToProps)(MemberChatPop)
