import React, { useEffect, useState } from "react";
import TeamMembers from "./TeamMembers";
import General from "./General"; 
import Notify from "./Notify"; 
import BillingPlan from "./BillingPlan";
import { FetchBillingPlan } from "../../../Services/Actions/userActions";
import { connect } from "react-redux";
import { store } from "../../../Services";

const tabs = ["General", "Team Members", "Billing & Plan", "Notification"]    

function Account(props) {
    const [_tabs,setTabs] = useState("General")
    const {billing_plan} = props
 
    useEffect(()=>{
        if(billing_plan?.tab){ 
            setTabs("Billing & Plan")
            FetchBillingPlan({},store.dispatch)
        }

    },[billing_plan])

    const setTab = (tab) => {
        setTabs(tab) 
    };

  return (
    <div className="setting-page-tabs meeting-page-tabs settings-page-tabs">
      <h3>Account Settings</h3>
      <div className="meeting-page-tabs-inner">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {tabs &&
            tabs.map((value, key) => {
              return (
                <li className="nav-item" role="presentation" key={key}>
                  <a
                    className={`nav-link ${_tabs === value ? "active" : ""}`}
                    id={value}
                    data-toggle="tab"
                    href={void 0}
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    onClick={() =>setTab(value)}
                  >
                    {value}
                  </a>
                </li>
              );
            })}
        </ul>
        <div
          className="tab-content  account-setting-plan-tab-content"
          id="myTabContent"
        >
          <div
            className={`tab-pane fade mt-4 general-billing-height ${
                _tabs === "General" ? "show active" : ""
            } `}
            id="General"
            role="tabpanel"
            aria-labelledby="General"
          >
            <General {...props} />
          </div>
          <div
            className={`tab-pane fade mt-4 ${
                _tabs === "Team Members" ? "show active" : ""
            } `}
            id="Team Members"
            role="tabpanel"
            aria-labelledby="Team Members"
          >
            <TeamMembers {...props} />
          </div>
          <div
            className={`tab-pane fade mt-4 general-billing-height ${
                _tabs === "Billing & Plan" ? "show active" : ""
            } `}
            id="Billing & Plan"
            role="tabpanel"
            aria-labelledby="Billing & Plan"
          >
            <BillingPlan {...props} />
          </div> 
          <div
            className={`tab-pane fade mt-4 ${
                _tabs === "Notification" ? "show active" : ""
            } `}
            id="Notification"
            role="tabpanel"
            aria-labelledby="Notification"
          >
            <Notify {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    billing_plan: state?.user?.billing_plan,
  };
};

export default connect(mapStateToProps)(Account);
