import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { store } from "../../../Services";
import { ConferenceCallSessions, TransferCallSessions } from "../../../Services/Actions/callActions";
import { capitalizeFirstLetter } from "../../../Utils/Common";

function TransferScreen(props) {
  const [activeNumber, setActiveNumber] = useState(""); 
  const [customCallSessions,setCustomCallSessions] = useState({});
  const {
    list,
    renderFrom,
    transfer,
    conference,
    DTMF,
    PostTransfer,
    PostConference,
    TransferCall,
    ConferenceCall,
    CallTransfer,
    activeSession,
    ShowKeyPadHandler
  } = props; 

  const ActiveMemberHandler = (value)=>{ 
    const { name, user_extension } = value;
    let _TransferTo = {
      name,
      number: user_extension,
      status: "ringing",
      direction: "inbound",
    };
    let _TransferFrom = {
      name: activeSession?.name,
      number: activeSession?.number,
      status: "hold",
      direction: "inbound",
    };
    let customCallSessions = {}
    let transferObject = {to:_TransferTo,from:_TransferFrom}
    customCallSessions[activeSession?.uuid] =  transfer ? transferObject : {...transferObject,isMerged:false};
    setActiveNumber(user_extension);  
    setCustomCallSessions(customCallSessions)
  }  



  //  TRANSFER CALL BUTTONS
  const TransferBtnDiv = () => {

    const SpeakFirstHandler = () => {
      if(Object.keys(customCallSessions)?.length > 0){
        DTMF(`*1${activeNumber}#`);
        PostTransfer();
        TransferCall("warm");
        TransferCallSessions(customCallSessions, store.dispatch);
      }  
    };

    return (
      <div className="tranfer-speak-btn-div">
        <Link
          to="#"
          className="transfer-now-btn"
          onClick={() => {
            CallTransfer(activeNumber);
            PostTransfer();
          }}
        >
          <img src="./../assets/images/transfer-now-ico.svg" alt="1" />
          Transfer Now
        </Link>
        <Link
          to="#"
          className="speak-first-btn"
          onClick={() => SpeakFirstHandler()}
        >
          <img src="./../assets/images/speak-first-ico.svg" alt="1" />
          Speak First
        </Link>
      </div>
    );
  };

  //  CONFERENCE CALL BUTTONS
  const ConferenceBtnDiv = () => {
    const SpeakFirstHandler = () => {
      if(Object.keys(customCallSessions)?.length > 0){
        DTMF(`*1${activeNumber}#`);
        PostConference();
        ConferenceCall("call-separately");
        ConferenceCallSessions(customCallSessions, store.dispatch);
      }   
    };

    return (
      <div className="tranfer-speak-btn-div">
        <Link to="#" className="transfer-now-btn" onClick={SpeakFirstHandler}>
          Add to the call
        </Link>
        <Link to="#" className="speak-first-btn" onClick={SpeakFirstHandler}>
          Call Separately
        </Link>
      </div>
    );
  };

  const showDialerHandler = ()=>{
    ShowKeyPadHandler(true)
  }

  return (
    <div className="add-invite-contact-modal-div">
      <div className="add-contact-modal-div ml-1">
        <span className="circle-add-contact-ico">
          <img src="./../assets/images/add-number.png" alt="1" />
        </span>
        <span className="new-number" onClick={showDialerHandler}>Add Number</span>
      </div>
      <div className="invite-contact-modal-div">
        <ul
          className={`invite-contact-modal-ul ${
            renderFrom === "multiple_call_page" ? "h-294" : ""
          }`}
        >
          {list && list.map((value, key) => {
            if(value.user_extension === activeSession?.number){
              return null
            }
            let name = value.name ? capitalizeFirstLetter(value.name) : "Unknown";
            return (
              <li
                className={`webphone-btns ${
                  activeNumber === value.user_extension ? "active" : ""
                }`}
                key={`keys${key}`}
                id={`transfer_${key}`} 
                onClick={()=>ActiveMemberHandler(value)}
              >
                <span className="invite-contact-left-span">
                  <span className="invite-contact-img-sp">
                    {value.profile_picture ? (
                      <img src={value.profile_picture} alt={name} />
                    ) : (
                      <>
                        <p className="initials">
                          {name
                            ?.match(/\b(\w)/g)
                            .join("")
                            .toUpperCase()}
                        </p>
                      </>
                    )}
                  </span>
                  <span className="invite-contact-name-sp">
                    <h6 className="add-user-name">{name}</h6>
                    <p className="add-user-no">{value.user_extension}</p>
                  </span>
                </span>
                <span className="team-call-icon">
                  <img
                    src="./../assets/images/icons/multiple-calls/call-white.png"
                    alt="1"
                  />
                </span>
              </li>
            );
          })}
          {list instanceof Array && list?.length === 0 && (
            <li>
              <h5>No member added yet</h5>
            </li>
          )}
        </ul>
      </div>
      {activeNumber !== "" && transfer && <TransferBtnDiv />}
      {activeNumber !== "" && conference && <ConferenceBtnDiv />}
    </div>
  );
}

export default TransferScreen;
