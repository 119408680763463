import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import Modal from "../../../Component/Modal";
import { store } from "../../../Services";
import { SendMessage } from "../../../Services/Actions/callActions";
import PhoneInput, { getCountryCallingCode, parsePhoneNumber } from "react-phone-number-input";

function MessageContactModal(props) {
  const [callNumber, setCallNumber] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [, forceUpdate] = useState();
  const { OpenMessageModal, closeMessageContactModal, destination_number } =
    props;
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const textareaStyle = {
    marginTop: "0px",
    marginBottom: "0px",
    height: "144px",
  };

  useEffect(() => {
    if (destination_number) {
      setNumber(destination_number);
      setCallNumber(destination_number)
    }
  }, [destination_number]);

  const SendBusinessMessage = async () => {
    if (validator.current.allValid()) {
      let response = await SendMessage({ number : callNumber, message }, store.dispatch);
      if (response === 1) {
        closeMessageContactModal();
        setCallNumber("");
        setMessage("");
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  }; 

  const numberChange = (number) => {
    let phoneNumber = number ? parsePhoneNumber(number.toString()) : "";
    let prefix = ""; 
    let call_number = "";
    if (phoneNumber) {
      if (phoneNumber.nationalNumber && phoneNumber.country) {
        call_number = phoneNumber.nationalNumber; 
        if (getCountryCallingCode(phoneNumber.country)) {
          prefix = getCountryCallingCode(phoneNumber.country);
        }
      }
    }
    call_number = prefix.concat(call_number)
    setCallNumber(call_number); 
  };
 

  return (
    <div>
      <Modal
        open={OpenMessageModal}
        size={`md`}
        CloseModal={closeMessageContactModal}
        title={`Send Message`}
      >
        <div className="row account-label account">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Enter Number</label>
              <PhoneInput
                name="phone"
                placeholder="Phone Number"
                value={number}
                onChange={(number) => numberChange(number)}
                defaultCountry="ZA"
              />
              {validator.current.message("Number", callNumber, "required")}
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Message</label>
              <textarea
                className="form-control"
                placeholder="Type message here..."
                name="message"
                value={message}
                style={textareaStyle}
                onChange={(event) => setMessage(event.target.value)}
              />
              {validator.current.message("Message", message, "required")}
            </div>
          </div>
        </div>
        <div className="modal-footer display-footer text-right common-modal-footer">
          <button
            type="button"
            className="btn btn-orange"
            onClick={SendBusinessMessage}
          >
            Send
          </button>
        </div>
      </Modal>
    </div>
  );
}
export default connect()(MessageContactModal);
