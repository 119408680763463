import React, { Component } from "react";
import { connect } from "react-redux";
import { store } from "../../Services";
import {
  SMSChatListing,
  TeamListing,
} from "../../Services/Actions/callActions";
import { ExtensionListing } from "../../Services/Actions/settingActions";
import { ForceOpenDialPad } from "../../Services/Actions/userActions";
import { formatDIDNumber, removeBackdrop } from "../../Utils/Common";
import { withRouter } from "../../Utils/withRouter";
import ContactSlider from "./NxaContacts/ContactSlider";
import MessageContactModal from "./SMS/MessageContactModal";
import TeamSlider from "./Team/TeamSlider";

class CallSideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_type: "",
      show: false,
      active: "",
      addContact: false,
      teamSlider: false,
    };
  }

  componentDidMount = async () => {
    TeamListing(0, store.dispatch);
    ExtensionListing(0, store.dispatch);
    SMSChatListing(store.dispatch);
  };

  handleClose = () => {
    this.setState({ show: false }, () => {
      removeBackdrop();
    });
  };

  TeamParticipants = (data) => {
    this.setState(
      {
        active: data?.id,
      },
      () => {
        const { getTeamParticipants } = this.props;
        getTeamParticipants(data);
      }
    );
  };

  UserChatHistoryHandler = (data) => {
    const { _UserChatHistory } = this.props;
    this.setState(
      {
        active: data?.users,
      },
      () => {
        _UserChatHistory(data);
      }
    );
  };

  closeAddContactSlider = () => {
    this.setState({ addContact: false });
  };

  handleTeamParticipants = (value) => {
    this.TeamParticipants(value);
    this.props.SetTab("my_team");
  };

  smsChatHandler = (value) => {
    this.props.SetTab("sms");
    this.UserChatHistoryHandler(value);
  };

  render() {
    const { state, SetTab, team_listing, sms_chat_list } = this.props;
    const { modal_type, show, active, addContact } = this.state;
    return (
      <>
        <div className="col-md-4 col-lg-3 pl-0">
          <div className="mid-sidebar">
            <div className="mid-nav-inner bg-gray p-0">
              <div className="mid-nav calls-mid-nav pt-4">
                <h4 className="pl-4 pr-4">
                  Calls
                  <a
                    className="header-dial-ico"
                    href={void 0}
                    onClick={() =>
                      ForceOpenDialPad({ open: true }, store.dispatch)
                    }
                  >
                    <img src="./../assets/images/dialer-ico-head.svg" alt="1" />
                  </a>
                </h4>
                <div className="pl-4 pr-4">
                  <hr className="mt-0 mb-0 " />
                </div>
                <div className="online-store all-calls">
                  <ul className="list-unstyled mid-components m-0 calls-menu-ul">
                    <li>
                      <a
                        href="#Call-Histroy"
                        data-toggle="collapse"
                        aria-expanded="false"
                        className="dropdown-toggle collapsed"
                      >
                        <h5 className="">
                          <span className="menu-img">
                            <img
                              src="./../assets/images/call/call.svg"
                              alt="1"
                            />
                          </span>
                          Call History
                          <span className="collapse-arrow">
                            <img
                              src="./../assets/images/menu-arrow-up.svg"
                              alt="1"
                            />
                          </span>
                        </h5>
                      </a>
                      <ul
                        className="list-unstyled collapse show"
                        id="Call-Histroy"
                      >
                        <li>
                          <a
                            className={state === "all_calls" ? "active" : ""}
                            href={void 0}
                            onClick={() => SetTab("all_calls")}
                          >
                            All Calls
                          </a>
                        </li>
                        <li>
                          <a
                            className={state === "missed_calls" ? "active" : ""}
                            href={void 0}
                            onClick={() => SetTab("missed_calls")}
                          >
                            Missed Calls
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              state === "call_recordings" ? "active" : ""
                            }
                            href={void 0}
                            onClick={() => SetTab("call_recordings")}
                          >
                            Call Recordings
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        href="#Voicemails"
                        data-toggle="collapse"
                        aria-expanded="false"
                        className="dropdown-toggle collapsed"
                      >
                        <h5 className="">
                          <span className="menu-img">
                            <img
                              src="./../assets/images/call/voice.svg"
                              alt="1"
                              className="voice-mail-ico"
                            />
                          </span>
                          Voicemails
                          <span className="collapse-arrow">
                            <img
                              src="./../assets/images/menu-arrow-up.svg"
                              alt="1"
                            />
                          </span>
                        </h5>
                      </a>
                      <ul className="list-unstyled collapse" id="Voicemails">
                        <li>
                          <a
                            className={
                              state === "all_voicemails" ? "active" : ""
                            }
                            href={void 0}
                            onClick={() => SetTab("all_voicemails")}
                          >
                            All Voicemails
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        href="#NXA-Contacts"
                        data-toggle="collapse"
                        aria-expanded="false"
                        className="dropdown-toggle collapsed"
                      >
                        <h5 className="">
                          <span className="menu-img">
                            <img
                              src="./../assets/images/call/contact.svg"
                              alt="1"
                            />
                          </span>
                          NXA Contacts
                          <span className="collapse-arrow">
                            <img
                              src="./../assets/images/menu-arrow-up.svg"
                              alt="1"
                            />
                          </span>
                        </h5>
                      </a>
                      <span
                        className="menu-plus-ico"
                        onClick={() => this.setState({ addContact: true })}
                      >
                        <img src="./../assets/images/add-cont.svg" alt="1" />
                      </span>
                      <ul className="list-unstyled collapse" id="NXA-Contacts">
                        <li>
                          <a
                            className={state === "all_contacts" ? "active" : ""}
                            href={void 0}
                            onClick={() => SetTab("all_contacts")}
                          >
                            All Contacts
                          </a>
                        </li>
                        <li>
                          <a
                            className={state === "business" ? "active" : ""}
                            href={void 0}
                            onClick={() => SetTab("business")}
                          >
                            Business
                          </a>
                        </li>
                        <li>
                          <a
                            className={state === "personal" ? "active" : ""}
                            href={void 0}
                            onClick={() => SetTab("personal")}
                          >
                            Personal
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span
                        className="menu-plus-ico"
                        onClick={() => this.setState({ teamSlider: true })}
                      >
                        <img src="./../assets/images/add-cont.svg" alt="1" />
                      </span>
                      <a
                        href="#Team"
                        data-toggle="collapse"
                        aria-expanded="false"
                        className="dropdown-toggle collapsed"
                      >
                        <h5 className="">
                          <span className="menu-img">
                            <img
                              src="./../assets/images/call/team.svg"
                              alt="1"
                            />
                          </span>
                          Team
                          <span className="collapse-arrow">
                            <img
                              src="./../assets/images/menu-arrow-up.svg"
                              alt="1"
                            />
                          </span>
                        </h5>
                      </a>
                      <ul className="list-unstyled collapse" id="Team">
                        {team_listing &&
                          team_listing.map((value, index) => {
                            return (
                              <li key={index}>
                                <a
                                  className={
                                    active === value?.id
                                      ? "active"
                                      : ""
                                  }
                                  href={void 0}
                                  onClick={() =>
                                    this.handleTeamParticipants(value)
                                  }
                                >
                                  {value.name}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                    <li>
                      <span
                        className="menu-plus-ico"
                        onClick={() =>
                          this.setState({ modal_type: "sms", show: true })
                        }
                      >
                        <img src="./../assets/images/add-cont.svg" alt="1" />
                      </span>
                      <a
                        href="#sms"
                        data-toggle="collapse"
                        aria-expanded="false"
                        className="dropdown-toggle collapsed"
                      >
                        <h5 className="">
                          <span className="menu-img">
                            <img
                              src="./../assets/images/call/chat.svg"
                              alt="1"
                            />
                          </span>
                          SMS
                          <span className="collapse-arrow">
                            <img
                              src="./../assets/images/menu-arrow-up.svg"
                              alt="1"
                            />
                          </span>
                        </h5>
                      </a>
                      <ul className="list-unstyled collapse" id="sms">
                        {sms_chat_list &&
                          sms_chat_list.map((value, index) => {
                            return (
                              <li key={index}>
                                <a
                                  className={`${
                                    active === value?.users ? "active" : ""
                                  }`}
                                  onClick={() => this.smsChatHandler(value)}
                                >
                                  {value.user_name
                                    ? value.user_name
                                    : formatDIDNumber(value.users)}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactSlider
          addContact={addContact}
          closeAddContactSlider={this.closeAddContactSlider}
          SetTab={SetTab}
          renderPage={"all_contacts"}
        />

        <TeamSlider
          addTeam={this.state.teamSlider}
          closeTeamSlider={() => this.setState({ teamSlider: false })}
        />

        {modal_type === "sms" && show && (
          <MessageContactModal
            OpenMessageModal={show}
            closeMessageContactModal={this.handleClose}
            renderPage={"calls"}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  team_listing: state?.call?.team_listing ?? [],
  sms_chat_list: state?.call?.sms_chat_list ?? [],
});

export default connect(mapStateToProps)(withRouter(CallSideMenu));
