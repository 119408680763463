import React, { useEffect } from "react";

const Modal = (props) => {
    useEffect(() => {
        if (props.open) {
            window.$("#my_common_modal").modal({ show: true });
        } else {
            window.$("#my_common_modal").modal("hide");
        }
    }, [props.open]);

    return (
        <div
            className={`modal fade modal-scroller ${props.showActiveClass ? "show" : ""}`}
            id="my_common_modal"
            aria-labelledby="myModalLabel"
            data-backdrop="static"
        >
            <div
                className={`modal-dialog modal-${
                    props.size ? props.size : "lg"
                } ${props.modalType === "permission-modal" ?  "permission-modal-sm" : ""}`}
            >
                <div className="login-form w-100">
                    <div className="modal-content">
                        {props.modalType === "permission-modal" ? "" :
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                onClick={() => props.CloseModal()}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                            <h5 className="modal-title">{props.title}</h5>
                        </div>}
                        <div className="modal-body">
                            {props.children} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Modal;
