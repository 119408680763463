import React, { Component } from "react"; 
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { store } from "../../Services";
import { VideoRecordingList } from "../../Services/Actions/meetActions";
import { bytesToSize, SecondsTohhmmss } from "../../Utils/Common";
import JwPlayer from "../Meet/JWPlayer";

class VideoRecording extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageofitems: [],
      page: 1,
      open: false,
      file: "",
      title: "",
    };
  }

  onChangePage = (pageofitems, page) => {
    console.log(pageofitems, page);
  };

  componentDidMount = async() => {
   VideoRecordingList(store.dispatch) 
  };

  OpenModal = (file, title) => {
    this.setState({ open: true, file, title });
  };

  CloseModal = () => {
    this.setState({ open: false });
    setTimeout(() => {
      this.setState({ file: "", title: "" });
    }, 500);
  };

  render() {
    const { video_record_listing, recording_count, SetTab } = this.props;
    const { file, open, title } = this.state;
    return (
      <div className="col-md-12 p-0">
        <a
          className="chat-back-arrow chat-back-arrow-2 m-0"
          style={{ cursor: "pointer" }}
          onClick={() => SetTab("cloud_storage")}
        >
          <img src="./../assets/images/backA.svg" />
        </a>
        <div className="tab-pane show active powerapps tab-pane-scroll">
          <table className="table table-hover table-center mb-0">
            <tbody>
              {video_record_listing instanceof Array &&
                video_record_listing.map((value, key) => {
                  return (
                    <tr key={key}>
                      <td>
                        <div className="d-flex all-message-email-row">
                          <div className=" text-center align-self-center">
                            <img src={`${value.thumbnail}`} alt="1" />
                          </div>
                          <div className="align-self-center">
                            <h3>{value.topic}</h3>
                            <p>{value.created}</p>
                          </div>
                        </div>
                      </td>
                      <td>{bytesToSize(value.size)}</td>
                      <td align="center">
                        Duration {SecondsTohhmmss(value.duration)}
                      </td>
                      <td align="center">
                        <span
                          className="badge badge-success"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.OpenModal(value.file, value.topic);
                          }}
                        >
                          Play
                        </span>
                        &nbsp;&nbsp;
                      </td>
                    </tr>
                  );
                })}
              {video_record_listing && video_record_listing.length === 0 && (
                <div className="text-center w-100">
                  <h5>No video meeting recordings</h5>
                </div>
              )}
            </tbody>
          </table>
        </div>

        <Modal
          show={open}
          onHide={this.CloseModal}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <JwPlayer file={file} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    meet: { video_record_listing, recording_count },
  } = state;
  return {
    video_record_listing,
    recording_count,
  };
};
export default connect(mapStateToProps)(VideoRecording);
