import React, { Component } from "react";
import CallSupport from "./CallSupport";
import ChatSupport from "./ChatSupport";
import FAQ from "./FAQ";
import Feedback from "./Feedback";
import Sidebar from "./Sidebar";
import SupportQuery from "./SupportQuery";

const initialState = { tab: "call_support" };

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  SetTab = (tab) => {
    this.setState({ tab });
  };

  render() {
    const { tab } = this.state;

    return (
      <>
        <div className="right-side-wrapper">
          <div className="card">
            <div className="card-body p-0">
              <div className="row m-0">
                <Sidebar state={tab} SetTab={this.SetTab} />
                <div className="col-md-8 col-lg-9">
                  <div className=" account">
                    {tab === "call_support" && <CallSupport {...this.props} />}

                    {tab === "chat_support" && <ChatSupport {...this.props} />}
                    {tab === "support_query" && (
                      <SupportQuery {...this.props} />
                    )}
                    {tab === "feedback" && <Feedback {...this.props} />}
                    {tab === "faq" && <FAQ {...this.props} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Support;
