import React, { Component } from "react"; 
import SimpleReactValidator from "simple-react-validator"; 
import { store } from "../../Services";
import { SupportLogQuery, ZohoAccessToken } from "../../Services/Actions/supportActions";
import authService from "../../Utils/authService"; 
import swal from 'sweetalert';

const initialState = { subject: "", description: "" };

class SupportQuery extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.Validator = new SimpleReactValidator();
  }

  HandleSubmit = async () => {
    const { subject, description } = this.state;
    let user_detail = authService.getUserDetail();
    let phone = user_detail?.phone ?? "";
    let email = user_detail?.email ?? "";
    if (this.Validator.allValid()) {
      let access_token = await ZohoAccessToken(store.dispatch) 
      if (!access_token) {
        return false;
      }
      let data = {
        AccessToken: access_token,
        subject,
        description,
        email,
        phone,
        productId: "",
      };
      let support_response = await  SupportLogQuery(data,store.dispatch) 
      if (support_response === 1) {
        swal({
          title: "Success!",
          text: "Query submitted successfully",
          icon: "success",
        });
        this.setState({
          description: "",
          subject: "",
        });
      }
    } else {
      this.Validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { subject, description } = this.state;

    return (
      <div className="card discover-inner">
        <div className="card-body pl-0 pr-0">
          <div className="Product-sec discover-slide mb-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 p-0">
                  <div className="feedback-bottom log-feed">
                    <div className="row form-group">
                      <div className="col-md-6">
                        <div className="">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="color3B"
                          >
                            Subject
                          </label>
                          <input
                            type="text"
                            className="form-control color3B pt-0"
                            id="exampleFormControlInput1"
                            placeholder="Subject"
                            name="subject"
                            value={subject}
                            onChange={(e) =>
                              this.setState({ [e.target.name]: e.target.value })
                            }
                          />
                          {this.Validator.message(
                            "Subject",
                            subject,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="color3B"
                          >
                            Add detailed Description
                          </label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            placeholder="Type Description"
                            rows="7"
                            name="description"
                            value={description}
                            onChange={(e) =>
                              this.setState({ [e.target.name]: e.target.value })
                            }
                          ></textarea>
                          {this.Validator.message(
                            "Description",
                            description,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>
                    <p className="btn btn-orange float-right">
                      <a
                        herf={void 0}
                        onClick={this.HandleSubmit}
                        style={{ cursor: "pointer" }}
                      >
                        Submit
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SupportQuery;
