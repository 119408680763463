import React, { Component } from "react";
const initialState = {};

class BusinessInbox extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  render() {
    return (
      <div className="card discover-inner">
        <div className="card-body">
          <div className=" cloud-recording-mian  mb-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="text-center">Business Inbox</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BusinessInbox;
