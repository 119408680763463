import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import Modal from "../../../Component/Modal";
import { store } from "../../../Services";
import {
  MeetingsList,
  UpdateMeeting,
} from "../../../Services/Actions/meetActions";
import Select from "react-select";

function InviteMemberModal({
  inviteMembers,
  InitialModal,
  uniquekey,
  extension_listing,
  tab,
  participantsData,
}) {
  const [participants, setParticipants] = useState([]);

  const validator = new SimpleReactValidator(); 
  
  useEffect(() => {
    if (participantsData) {
      let members = [];
      participantsData &&
        participantsData.map((value) => {
          members = [
            ...members,
            {
              label: `${value.name}(${value.user_extension})`,
              value: value.email,
            }
          ];
        }); 
      setParticipants(members);
    }
  }, [participantsData]);

  const UpdateParticipants = async () => {
    if (validator.allValid()) {
      let members = [];
      participants &&
        participants.map(({ value }) => {
          members = [...members, value];
        });
      let response = await UpdateMeeting(
        { uniquekey, participants: members },
        store.dispatch
      );
      if (response) {
        InitialModal();
        setParticipants([]);
        MeetingsList({ type: tab }, store.dispatch);
        swal("Participants Updated Successfully!", {
          icon: "success",
        });
      }
    } else {
      console.log(validator);
      validator.showMessages();
      setParticipants([]);
    }
  };

  const handleChange = (selectedOption) => {
    setParticipants(selectedOption);
  };

  return (
    <Modal
      open={inviteMembers}
      CloseModal={InitialModal}
      title={`Invite Meeting Members`}
    >
      <div className="form-group doctor-setp-mid ad-articipant invite-members-md ">
        <label>Invite Meeting Members</label>
        <Select
          isMulti
          isSearchable
          name="participants"
          value={participants}
          placeholder="Add Members"
          onChange={handleChange}
          defaultValue={participants}
          options={
            extension_listing &&
            extension_listing.map((value) => ({
              label: `${value.name}(${value.user_extension})`,
              value: value.email,
            }))
          }
        />
        {validator.message("Participants", participants, "required")}
      </div>
      <div className="modal-footer display-footer text-center">
        <button
          type="button"
          className="btn btn-orange"
          onClick={UpdateParticipants}
        >
          Invite
        </button>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const { setting: { extension_listing } = {} } = state;
  return {
    extension_listing,
  };
};

export default connect(mapStateToProps)(InviteMemberModal);
