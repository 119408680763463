import React, { Component } from "react"; 
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../../../Component/Pagination";
import { store } from "../../../Services";
import {
  DeleteVoicemailFile,
  ForceCall,
  SelectedChatType,
  SelectedChatUser,
  VoicemailListing,
} from "../../../Services/Actions/callActions";
import { withRouter } from "../../../Utils/withRouter";
import ContactSlider from "../NxaContacts/ContactSlider";
import moment from "moment";
import { convertUtcToLocalTime } from "../../../Utils/Common";
import swal from "sweetalert";
import { getMediaDownloadUrl } from "../../../Services/Actions/settingActions";

class Voicemails extends Component {
  constructor(props) {
    super(props);
    let recording_url = {}; 
    [...Array(this.props.voicemail_list?.length).keys()].map((v, i) => {
      let n = "url" + v;
      recording_url[n] = ""; 
      return null;
    });

    const initialState = { 
      pageOfItems: [],
      show: false,
      number: "",
      search_num: "",
      addContact: false, 
    }
    this.state = {initialState}; 
    Object.assign(initialState,recording_url); 
  }

  componentDidMount = async () => {
    VoicemailListing(store.dispatch);
  };

  componentDidUpdate = (prevProps)=>{
    const {voicemail_list} = this.props;
    if(prevProps?.voicemail_list !== voicemail_list){
        this.FetchCallRecordingurl(voicemail_list);
    } 
  }

  FetchCallRecordingurl = (voicemail_list)=>{ 
    this.setState({loading:true})
    voicemail_list && voicemail_list.map(async(value,index)=>{ 
      let responseUrl = await getMediaDownloadUrl(value.recording_file)
      if(responseUrl){
        this.setState({
          [`recording_url${index}`]: responseUrl
        }) 
      }
    },()=>{
      this.setState({
        loading:false
      })
    })  
  }

  UpdateData = (pageOfItems) => {
    this.setState({ pageOfItems });
  };

  closeModal = () => {
    this.setState({ show: false });
  };

  closeAddContactSlider = () => {
    this.setState({ addContact: false });
  };

  StartChat = (value) => {
    let userData = {
      profile_picture: value?.profile_picture ?? "",
      phone: value?.caller_id_number,
      name: value.name,
      uniquecode: value?.uniquecode ?? "",
    };

    SelectedChatUser(userData,store.dispatch)
    SelectedChatType("one_to_one_chat",store.dispatch)
    store.navigate("/chat");
  };

  FilterVoicemail = (search_num) => {
    const { pageOfItems } = this.state;
    const { voicemail_list } = this.props;
    const filtered = pageOfItems.filter((value) => {
      return (
        (value.caller_id_number &&
          value.caller_id_number.trim().toLowerCase().includes(search_num)) ||
        (value.name && value.name.trim().toLowerCase().includes(search_num))
      );
    });
    this.setState({
      pageOfItems: search_num ? filtered : voicemail_list.slice(0, 5),
    });
  };

  deleteVoicemail = (value) => {
    swal({
      icon: "warning",
      title:"Warning!",
      text: `Are you sure you want to delete this voicemail?`,
      buttons: true,
    }).then(async (success) => {
      if (success) {
        let data = {
          voicemail_message_uuid: value.voicemail_message_uuid,
          voicemail_uuid: value.voicemail_uuid,
        };
        let response = await DeleteVoicemailFile(data, store.dispatch);
        if (response === 1) {
          VoicemailListing(store.dispatch);
        }
      }
    });
  };

  filterVoicemailHandler = (e) => {
    this.setState(
      {
        search_num: e.target.value,
      },
      () => {
        this.FilterVoicemail(e.target.value);
      }
    );
  };

  render() {
    const { pageOfItems, number, search_num, addContact } = this.state;
    const { voicemail_list } = this.props;
    return (
      <>
        <div className="col-md-8 col-lg-9">
          <div className="right-side-top-header">
            <h5>All Voicemail</h5>
            <div className="call-filter-refresh-div">
              <Link to="#" title="Refresh Voicemail" onClick={() => VoicemailListing(store.dispatch)}>
                <img src="./../assets/images/refresh-ico.svg" alt="1" />
              </Link>
              <span className="filter-serach">
                <img src="./../assets/images/filter.svg" alt="1" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter voicemail history"
                  name="search_num"
                  value={search_num}
                  onChange={(e) => this.filterVoicemailHandler(e)}
                />
              </span>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane call-info-scroll single-table-height show active">
              <div className="table-responsive calls-table calls-table-hover">
                <table className="table table-hover table-center mb-0">
                  <tbody>
                    {pageOfItems &&
                      pageOfItems.map((value, index) => {
                        if (value) {
                          const localCreatedTime = convertUtcToLocalTime(
                            value.created_epoch
                          );
                          let formattedDate = moment(localCreatedTime)
                            .locale("en")
                            .format("ll");
                          let formattedTime = moment(localCreatedTime)
                            .locale("en")
                            .format("LT");
                          return (
                            <tr key={index}>
                              <td>
                                <div className="d-flex all-message-email-row">
                                  <div className="text-center align-self-center caller-img">
                                    <img
                                      src="./../assets/images/Mask.png"
                                      alt="1"
                                    />
                                  </div>
                                  <div className="align-self-center caller-name-number">
                                    <h3>
                                      {" "}
                                      {value.name ? value.name : "Unknown"}{" "}
                                    </h3>
                                    <p>{value.caller_id_number}</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="align-self-center call-type-td">
                                  <audio
                                    src={this.state[`recording_url${index}`]}
                                    controls
                                  > 
                                  </audio>
                                </div>
                              </td>
                              <td></td>
                              <td align="right" style={{ width: "20%" }}>
                                <Link
                                  to="#"
                                  className="text-grey calls-date-time"
                                >
                                  {" "}
                                  {`${formattedDate} ${formattedTime}`}{" "}
                                </Link>
                                <div className="recentcall-img-ico allcall-img-ico">
                                  {!value.name && (
                                    <Link
                                      to="#"
                                      title="Add Contact"
                                      onClick={() =>
                                        this.setState({
                                          number: value.caller_id_number,
                                          addContact: true,
                                        })
                                      }
                                    >
                                      <img
                                        src="./../assets/images/call-add-contact.svg"
                                        alt="1"
                                      />
                                    </Link>
                                  )}

                                  {value.name && (
                                    <Link
                                      to="#"
                                      title="Chat"
                                      onClick={() => this.StartChat(value)}
                                    >
                                      <img
                                        src="./../assets/images/call-chat-ico.svg"
                                        alt="1"
                                      />
                                    </Link>
                                  )}
                                  <Link
                                    to="#"
                                    title="Audio Call"
                                    onClick={() =>
                                      ForceCall(value.caller_id_number,store.dispatch)
                                    }
                                  >
                                    <img
                                      src="./../assets/images/calls-call-ico.svg"
                                      alt="1"
                                    />
                                  </Link>
                                  <Link
                                    to="#"
                                    title="Delete Voicemail"
                                    onClick={() => this.deleteVoicemail(value)}
                                  >
                                    <img
                                      src="./../assets/images/delete_icon.svg"
                                      alt="1"
                                    />
                                  </Link>
                                  <Link to="#">
                                    <img
                                      src="./../assets/images/call-menu-ico.svg"
                                      alt="1"
                                    />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}

                    {pageOfItems && pageOfItems.length === 0 && (
                      <div className="text-center w-100">
                        <h5> No voicemails </h5>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination data={voicemail_list} currentData={this.UpdateData} />
        </div>
        <ContactSlider
          addContact={addContact}
          closeAddContactSlider={this.closeAddContactSlider}
          phone={number}
          renderPage={"voicemail"}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  voicemail_list: state?.call?.voicemail_list ?? [],
});

export default connect(mapStateToProps)(withRouter(Voicemails));
