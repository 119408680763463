import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom"; 
import SimpleReactValidator from "simple-react-validator";
import { store } from "../../../Services";
import { UploadFiles } from "../../../Services/Actions/callActions";
import { UpdateProfileDetails } from "../../../Services/Actions/settingActions";

class General extends Component {
  constructor(props) {
    super(props);
    this.userValidator = new SimpleReactValidator();
    this.state = {
      companyName: "",
      postalCode: "",
      billingAddress: "",
      location: "",
      phone: "",
      email: "",
      user_image: "",
      image: false,
    };
  }

  componentWillReceiveProps(newProps) {
    const { user_details } = newProps;
    if (user_details) {
      this.setState({
        companyName: user_details?.company,
        postalCode: user_details?.zipcode,
        billingAddress: user_details?.address,
        location: user_details?.country,
        phone: user_details?.phone,
        customerID: user_details?.customerID ?? "",
        user_image: user_details?.profile_picture ?? "",
        email: user_details?.email ?? "",
      });
    }
  }

  uploadImage = async () => {
    let imageFile = window.$("#profile_pic")[0].files[0]; 
    let response = await UploadFiles(imageFile, "profile",store.dispatch) 
    if (response.code === 1) {
      this.setState({
        user_image: response.file_path,
        image: true,
      });
    }
  };

  updateUserDetails = () => {
    let validator = this.userValidator;
    const {
      companyName,
      postalCode,
      billingAddress,
      location,
      user_image,
      customerID,
      image,
    } = this.state;
    if (validator.allValid()) {
      let data = {
        customerID,
        app_picture: image ? user_image : "",
        company: companyName,
        zipcode: postalCode,
        address: billingAddress,
        country: location,
        address: billingAddress,
      };
     UpdateProfileDetails(data,store.dispatch) 
    } else {
      validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const {
      companyName,
      postalCode,
      billingAddress,
      location,
      phone,
      email,
      user_image,
    } = this.state;
    const { user_details } = this.props;
    return (
      <div className="genral-page-form"> 
        <div className="gernal-form-header">
          <div className="gernal-form-left">
            <img
              src={
                user_image
                  ? user_image
                  : "./../assets/images/nxatel-logo-img.svg"
              } 
              alt="nxatel"
              onClick={(e) => this.uploadRef.click()}
              style={{ cursor: "pointer" }}
            />
            <input
              type="file"
              id="profile_pic"
              className="upload"
              name="user_image"
              ref={(input) => (this.uploadRef = input)}
              onChange={(e) => this.uploadImage(e)}
              accept="image/jpeg, image/png"
              hidden
            />
            <h2>{`${user_details?.company}`}</h2>
            <h6>{`${user_details?.user_plan?.users ?? 0} Employees`}</h6>
          </div>
          <div className="gernal-form-right">
            <div className="progress" data-percentage="85">
              <span className="progress-left">
                <span className="progress-bar"></span>
              </span>
              <span className="progress-right">
                <span className="progress-bar"></span>
              </span>
              <div className="progress-value">85%</div>
            </div>
            <div className="progress-content">
              <h3>Company information</h3>
              <Link to="#" className="complete-profile-btn">
                Complete your profile
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="gernal-form-input ">
              <div className="form-group">
                <label>Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="companyName"
                  value={companyName}
                  onChange={(e) =>
                    this.setState({ companyName: e.target.value })
                  }
                />
                {this.userValidator.message(
                  "Company name",
                  companyName,
                  "required|string"
                )}
                <img src="./../assets/images/company-gernal-ico.svg" alt="1" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="gernal-form-input">
              <div className="form-group">
                <label>Location</label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  value={location}
                  onChange={(e) => this.setState({ location: e.target.value })}
                />
                {this.userValidator.message(
                  "Location",
                  location,
                  "required|string"
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="gernal-form-input ">
              <div className="form-group">
                <label>Billing address</label>
                <input
                  type="text"
                  className="form-control"
                  name="billingAddress"
                  value={billingAddress}
                  onChange={(e) =>
                    this.setState({ billingAddress: e.target.value })
                  }
                />
                {this.userValidator.message(
                  "Billing Address",
                  billingAddress,
                  "required|string"
                )}
                <img src="./../assets/images/location-gernal-ico.svg" alt="1" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="gernal-form-input">
              <div className="form-group">
                <label>Postal Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="postalCode"
                  value={postalCode}
                  onChange={(e) =>
                    this.setState({ postalCode: e.target.value })
                  }
                />
                {this.userValidator.message(
                  "Postal Code",
                  postalCode,
                  "required"
                )}
                <img src="./../assets/images/envelp-genral-ico.svg" alt="1" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="gernal-form-input ">
              <div className="form-group">
                <label>Phone</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                  disabled={true}
                />
                {this.userValidator.message("Phone", phone, "required")}
                <img src="./../assets/images/phone-genral-ico.svg" alt="1" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="gernal-form-input ">
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  disabled={true}
                />
                {this.userValidator.message("Email", email, "required")}
                <img src="./../assets/images/user-gernal-ico.svg" alt="1" />
              </div>
            </div>
          </div>
        </div>
        <div className="gernal-form-buttons">
          <Link
            to="#"
            className="update-genral-setting-btn"
            onClick={this.updateUserDetails}
          >
            Update Settings
          </Link>
          <Link to="#" className="cancel-genral-btn ml-2">
            Cancel
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_details: state?.user?.user_details?.userDetail ?? {},
  };
};

export default connect(mapStateToProps)(General);
