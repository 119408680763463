import React, { Component } from "react";
// import ReactJWPlayer from "react-jw-player";

class JwPlayer extends Component {    
    render() {
        const {file} = this.props;
        return (
            <></>
            // <ReactJWPlayer
            //     playerId="player"
            //     playerScript="https://cdn.jwplayer.com/libraries/lqsWlr4Z.js"
            //     file={file}
            //     isAutoPlay={true}
            // />
        );
    }
}

export default JwPlayer;
