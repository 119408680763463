import React, { useEffect, useState } from 'react'; 
import { connect } from "react-redux";
import moment from "moment";
import { store } from "../../../../Services";
import {
  CardListing, 
  RecentTransactionListing,
} from "../../../../Services/Actions/settingActions"; 
import { Link } from "react-router-dom";
import SubscriptionModal from "./SubscriptionModal";
import ChangePlanModal from "./ChangePlanModal";
import { accountBaseUrl , removeBackdrop } from "../../../../Utils/Common"; 
import PaymentMethodSlider from './PaymentMethodSlider'; 


function BillingPlan({ user_details, card_listing, transaction_list }) {

    const initialState = {
        addPaymentMethod: false,
        open: false, 
        modalType: "",
        payment:false,
        bill_number:""
    } 
    const [state,setState] = useState(initialState)

    useEffect(()=>{ 
        CardListing(store.dispatch) 
        RecentTransactionListing(store.dispatch)
    },[])

    const handleClose = () => {
        setState(prev=>({
            ...prev,
            open:false
        }))
        removeBackdrop(); 
    };

    const closePaymentSlider = ()=>{
      setState(prev=> ({
        ...prev,
        addPaymentMethod: false,
        payment:false 
      })) 
    }
    
    const openContactModal = (value) => {
        setState(prev=>({
            ...prev,
            open: true,
            modalType: value,
        })) 
    }; 

    const getCalculatedDate = value =>{
        return moment(value, "YYYY-MM-DD") .add(
            plan_duration === 24 ? 2 : 1,
            plan_duration === 1 ? "M" : plan_duration === 12 || 24 ? "y" : ""
        ) .locale("en") .format("ll");
    }

    const choosePlanModal = ()=>{
      setState(prev=>({
        ...prev,
        open: true,
        modalType: "change-plan"
      }))
    }

    const { addPaymentMethod, open, modalType, payment ,bill_number} = state 
    const {user_plan : {users,plan_duration,currency,name, amount } = {}} = user_details  
    let selected_currency = currency === "USD" ? "$" : "R"
    let duration = plan_duration === 12 ? "annually" : plan_duration === 24 ? "2 yearly" : "monthly"; 
    let _primaryCardInfo = card_listing.length !== 0 ? card_listing.filter((value) => value.primary === "Y") : "";
        _primaryCardInfo = _primaryCardInfo && JSON.parse(_primaryCardInfo?.[0]?.card_details); 

    return (
        <> 
        <div className="billing-tab-main-div">
          <div className="billing-payment-details-box">
            <h2>Payment details</h2>
            <table className="table table-hover table-center mb-0">
              <tbody>
                <tr>
                  <td>
                    <span className="purple-light-sp">Payment method</span>
                  </td>
                  <td align="right">
                    <span className="balck-sp">
                      {`MasterCard ending in ${
                        _primaryCardInfo?.last4Digits ?? "NA"
                      }, exp: ${_primaryCardInfo?.expiryMonth ?? "NA"}/${
                        _primaryCardInfo?.expiryYear ?? "NA"
                      }`}
                    </span>
                    <span
                      className="dark-purple-sp ml-2"
                      style={{ cursor: "pointer" }} 
                      onClick={()=>setState(prev=>({...prev,addPaymentMethod:true,payment:false}))}
                    >
                      Update
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="purple-light-sp">Billing period</span>
                  </td>
                  <td align="right">
                    <span className="balck-sp">
                      {`Plan billed ${duration}`}
                    </span>
                    <span
                      className="dark-purple-sp ml-2"
                      onClick={()=>setState(prev=>({...prev,open: true,modalType: "subscription"}))} 
                      style={{ cursor: "pointer" }}
                    >
                      Update
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="purple-light-sp">
                      {`${
                        duration.charAt(0).toUpperCase() + duration.slice(1)
                      } Subscription Fee`}
                    </span>
                  </td>
                  <td align="right">
                    <span className="dark-purple-sp">
                      {`${selected_currency} ${amount} / ${duration}`}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="purple-light-sp">Subscription Plan</span>
                    <span className="orange-sp ml-2">
                      {`${name} - ${users} User License`}
                    </span>
                  </td>

                  <td align="right">
                    <span
                      className="dark-purple-sp"
                      style={{ cursor: "pointer" }}
                      onClick={choosePlanModal} 
                    >
                      Manage Plan
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="billing-recent-payment-box">
            <h2>Recent payments</h2>
            <table className="table table-hover table-center mb-0">
              <tbody>
                {transaction_list &&
                  transaction_list.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className="purple-light-sp">
                            {moment(value.creationdate, "YYYY-MM-DD")
                              .locale("en")
                              .format("ll")}
                          </span>
                        </td>
                        <td>
                          <span className="balck-sp">
                            {`${value.plan_name}(${moment(value.creationdate, "YYYY-MM-DD").locale("en").format("ll")} to
                                ${getCalculatedDate(value.creationdate)})`}
                          </span>
                        </td>
                        <td>
                          {value.status === "S" ? (
                            <span className="badge badge-success">
                              <i className="fas fa-circle"></i>
                              Successful
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              <i className="fas fa-circle"></i>
                              Payment failed -Overdue
                            </span>
                          )}
                        </td>
                        <td>
                          <span className="balck-sp">{`R${value.amount}`}</span>
                        </td>
                        <td align="right">
                          {value.status === "S" ? (
                            <a
                              href={`${accountBaseUrl}payment-invoice/${value.id}`}
                              className="dark-purple-sp"
                              download
                            >
                              Download Invoice
                            </a>
                          ) : (
                            <Link 
                              to="#" 
                              className="pay-now-billing-link" 
                              onClick={()=>setState(prev=>({
                                ...prev,
                                addPaymentMethod:true,
                                payment:true,
                                bill_number:value.bill_number
                              }))}
                            >
                              Pay Now
                            </Link>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                {transaction_list && transaction_list.length === 0 && (
                  <tr colSpan="5">
                    <td>No recent payments</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <PaymentMethodSlider  
          addPaymentMethod = {addPaymentMethod}
          getCalculatedDate = {getCalculatedDate} 
          closePaymentSlider = {closePaymentSlider}
          choosePlanModal={choosePlanModal}
          card_listing={card_listing}
          user_details={user_details}
          payment={payment}
          bill_number = {bill_number}
          
        />

        {open && modalType === "subscription" && (
          <SubscriptionModal
            open={open}
            handleClose={handleClose}
            openContactModal={openContactModal}
            user_details={user_details}
          />
        )}
        {open && modalType === "change-plan" && (
          <ChangePlanModal open={open} handleClose={handleClose} />
        )}
      </>
    );
}

const mapStateToProps = (state) => {
    return {
      user_details: state?.user?.user_details?.userDetail ?? {},
      card_listing: state?.setting?.card_listing ?? [],
      transaction_list: state?.setting?.transaction_list ?? [],
    };
  };
  
  export default connect(mapStateToProps)(BillingPlan);