import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CardDomain, loadScript, ReturnBaseUrl } from "../../Utils/Common"; 
import './cards.css';

function CardPayment({ payment_token }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true); 
    loadScript(
      `${CardDomain}/v1/paymentWidgets.js?checkoutId=${payment_token}`,
      "PeachPay"
    );
  }, []);

  return (
    <>
      {mounted && (
        <form
          action={`${ReturnBaseUrl}`}
          className="paymentWidgets"
          data-brands="VISA MASTER AMEX"
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    payment_token: state?.signup?.payment_token ?? "",
  };
};
export default connect(mapStateToProps)(CardPayment);
