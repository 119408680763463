import React from "react";

function LoaderPage({type}) {
    let message;
    switch (type){
        case "initial":
            message = "Preparing your App";
            break ;
        case "disconnecting":
            message = "Disconnecting";
            break ;
        case "locked":
            message = "Room Locked...";
            break ;
        case "unauthorized":
            message = "Not Authorized...";
            break ;
        case "waiting":
            message = "Please wait for the host to join the meet...";
            break ;
        case "not-found":
            message = "Room Not Found...";
            break ;
        default:
            message = "Preparing your Meeting room";
            break ;
    }
    return <>
        <div className="loader-page">
            <a href={void 0} className="loader-logo"> <img src="./../assets/images/loader-logo.svg" alt="1" /></a>
            <h3 className="loader-page-title">{message}</h3>
            {(!type || type==="initial") && <p>Please wait</p>}
            <a href={void 0} className="loader-img"> <img src="./../assets/images/loader-orange.gif" alt="1" /></a>
        </div>
    </>;
}

export default LoaderPage;
