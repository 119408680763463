import React, { Component } from "react";
import swal from "sweetalert";
import moment from "moment";
import { Link } from "react-router-dom";
import { store } from "../../../Services";
import {
  changeExtensionPassword,
  DeleteExtension,
  ExtensionListing,
  MemberRole,
} from "../../../Services/Actions/settingActions";
import { connect } from "react-redux";
import Pagination from "../../../Component/Pagination";
import TeamMemberSlider from "../../Calls/Team/TeamMemberSlider";

class TeamMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: "",
      pageType: "add-member",
      manageTeamMember: false,
      memberDetails: "",
      open: false,
      pageOfItems: [],
      member: "",
      changeRole: "",
      addTeamMember: false,
      extensionDetails: {},
      isEdit: false,
    };
  }

  componentDidMount = async () => {
    ExtensionListing(0, store.dispatch);
  };

  UpdateData = (pageOfItems) => {
    this.setState({ pageOfItems });
  };

  FilterMember = (member) => {
    const { pageOfItems } = this.state;
    const {
      nxatel: { extension_listing },
    } = this.props;
    let updatedList = pageOfItems;
    updatedList = pageOfItems.filter((value) => {
      return value.name.toLowerCase().search(member) !== -1;
    });
    this.setState({
      pageOfItems: member ? updatedList : extension_listing.slice(0, 5),
    });
  };

  RemoveMember = () => {
    const { memberDetails } = this.state;
    const wrapper = document.createElement("div");
    wrapper.innerHTML = `<p>
                                <span>- This Team member will no longer have access Nxatel Account</span>
                                <span>- Direct number or extension will be inaccessible</span>
                                <span>- Content in the Shared with Team will remain in the Account</span> 
                            </p>`;
    swal({
      title: `Remove ${memberDetails?.name} from Nxatel?`,
      buttons: [true, "Remove"],
      content: wrapper,
      className: "remove-member",
    }).then(async (action) => {
      if (action) {
        let res = await DeleteExtension(
          memberDetails?.customerID,
          store.dispatch
        );
        if (res === 1) {
          this.setState({ manageTeamMember: false });
          window.$(`#${memberDetails?.user_extension}`).removeClass("show");
        }
      }
    });
  };

  ResetPassword = async () => {
    const { memberDetails } = this.state;
    let res = await changeExtensionPassword(
      { uniquekey: memberDetails.uniquecode },
      store.dispatch
    );
    if (res === 1) {
      swal({
        icon: "./../assets/images/subscription-successfull.svg",
        text: `Email sent to your team member.`,
      }).then(async (action) => {
        if (action) {
          this.setState({ manageTeamMember: false });
        }
      });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  changeMemberRole = async () => {
    const { memberDetails, changeRole } = this.state;
    let res = await MemberRole({
      id: memberDetails?.customerID,
      role: changeRole,
    },store.dispatch);
    if (res === 1) {
      this.setState({ manageTeamMember: false });
      ExtensionListing(0, store.dispatch);
    }
  };

  closeTeamMemberSlider = () => {
    this.setState({ addTeamMember: false });
  };

  filterMemberHandler = (e) => {
    this.setState({
      member: e.target.value,
    });
    this.FilterMember(e.target.value);
  };

  render() {
    const {
      nxatel: { extension_listing },
    } = this.props;
    const {
      manageTeamMember,
      memberDetails,
      pageOfItems,
      member,
      changeRole,
      addTeamMember,
      extensionDetails,
      isEdit,
    } = this.state;
    const name = memberDetails?.name ?? "";
    let formattedName = name !== "" ? name.match(/\b(\w)/g) : "";
    return (
      <>
        <div className="team-user-tab-header">
          <h5>Team Members</h5>
          <span className="team-user-search">
            <input
              className="form-control"
              type="text"
              placeholder="Search Users by Name"
              name="member"
              value={member}
              onChange={(e) => this.filterMemberHandler(e)}
            />
            <img src="./../assets/images/team-user-search.svg" alt="1" />
          </span>
          <span className="team-user-btn">
            <select className="form-control">
              <option>All</option>
              <option>Disabled</option>
              <option>Import</option>
            </select>
            <Link
              to="#"
              title="Add Member"
              className="team-user-add-ico"
              onClick={() =>
                this.setState({ addTeamMember: true, extensionDetails: {} })
              }
            >
              <img src="./../assets/images/team-user-add-ico.svg" alt="1" />
            </Link>
          </span>
        </div>
        {extension_listing && extension_listing.length === 0 && (
          <div className="team-user-add-div">
            <img src="./../assets/images/assign-modal-vector.svg" alt="1" />
            <h4>
              Currently you don’t have any team members added. Add
              <Link
                to="#"
                className="ml-2"
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ addTeamMember: true })}
              >
                Team member
              </Link>
            </h4>
            <Link
              to="#"
              className="add-team-member"
              style={{ cursor: "pointer" }}
              onClick={() => this.setState({ addTeamMember: true })}
            >
              Add Team Member
            </Link>
          </div>
        )}
        {extension_listing && extension_listing.length > 0 && (
          <>
            <div className="add-member-listing member-listing-scroll">
              <table className="table table-hover table-center mb-0">
                <tbody>
                  {pageOfItems &&
                    pageOfItems.map((value, index) => {
                      let role =
                        value.type === "E"
                          ? "Member"
                          : value.type === "A"
                          ? "Admin"
                          : "";
                      return (
                        <tr key={index}>
                          <td>
                            <div className="d-flex member-list-row">
                              <div className="text-center align-self-center member-img">
                                <img
                                  alt="1"
                                  src={
                                    value.profile_picture
                                      ? value.profile_picture
                                      : "./../assets/images/Mask.png"
                                  }
                                />
                              </div>
                              <div className="align-self-center member-list-name">
                                <h3> {value.name} <i className="italic-font-color">{`(${value.user_extension})`}</i> </h3>
                                <Link
                                  to="#"
                                  className="team-member-list-anchor"
                                >
                                  {" "}
                                  {value.email}{" "}
                                </Link>
                              </div>
                            </div>
                          </td>
                          <td>
                            {value.type !== "C" && (
                              <>
                                {value.status === "A" && (
                                  <span
                                    className={`green-span`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        manageTeamMember: true,
                                        memberDetails: value,
                                        changeRole:
                                          value.type === "E"
                                            ? "member"
                                            : value.type === "A"
                                            ? "admin"
                                            : "",
                                      })
                                    }
                                  >
                                    Manage Team Member
                                  </span>
                                )}
                                {value.status === "D" && (
                                  <span className={`green-span text-danger `}>
                                    Inactive
                                  </span>
                                )}
                              </>
                            )}
                          </td>
                          <td>
                            <span>{value.type !== "C" ? role : "Owner"}</span>
                          </td>
                          <td>
                            <span className="ext-light-purple">{`ext ${value.user_extension}`}</span>
                          </td>
                          <td>
                            {value.type !== "C" && (
                              <div className=" number-collase-action">
                                <a
                                  href={`#${value.user_extension}`}
                                  data-toggle="collapse"
                                  aria-expanded="false"
                                  className="dropdown-toggle collapsed"
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </a>
                                <ul
                                  className="list-unstyled number-dr-collapse collapse"
                                  id={value.user_extension}
                                >
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      onClick={() =>
                                        this.setState({
                                          addTeamMember: true,
                                          extensionDetails: value,
                                          isEdit: true,
                                        })
                                      }
                                    >
                                      <img
                                        src="./../assets/images/number-settings-drop.svg"
                                        alt="1"
                                      />
                                      Edit Account
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      onClick={() =>
                                        this.setState(
                                          {
                                            memberDetails: value,
                                          },
                                          () => {
                                            this.RemoveMember();
                                          }
                                        )
                                      }
                                    >
                                      <img
                                        src="./../assets/images/number-drop-trash-2.svg"
                                        alt="1"
                                      />
                                      Delete Account
                                    </Link>
                                  </li>
                                  {!value.status === "A" && (
                                    <li>
                                      <Link className="dropdown-item" to="#">
                                        <img
                                          src="./../assets/images/attach-ico-link.svg"
                                          alt="1"
                                        />
                                        Copy Account Link
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <Pagination
              data={extension_listing}
              currentData={this.UpdateData}
            />
            <div
              className={`manage-team-member-slide ${
                manageTeamMember ? "" : "manage-team-member-slide-close"
              }`}
            >
              {manageTeamMember && (
                <>
                  <div className="member-slide-title">
                    <h5>Manage team member</h5>
                    <img
                      src="./../assets/images/cross-add-modal.svg"
                      alt="1"
                      onClick={() =>
                        this.setState({
                          manageTeamMember: false,
                        })
                      }
                    />
                  </div>
                  <div className="member-slide-img-name">
                    <div className="member-slide-img">
                      {memberDetails?.profile_picture ? (
                        <img alt="1" src={memberDetails?.profile_picture}></img>
                      ) : (
                        <h2>{formattedName}</h2>
                      )}
                    </div>
                    <div className="member-slide-name">
                      <h3>{memberDetails?.name} <i className="italic-font-color">{`(${memberDetails.user_extension})`}</i></h3>
                      <h5>{memberDetails?.email}</h5>
                    </div>
                  </div>
                  <div className="form-group form-group-role">
                    <label>Role</label>
                    <select
                      className="form-control"
                      name="changeRole"
                      value={changeRole}
                      onChange={(e) =>
                        this.setState({
                          changeRole: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Role</option>
                      <option value="member">Team Member</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                  <Link
                    to="#"
                    className="save-new-role"
                    onClick={this.changeMemberRole}
                  >
                    Save new role
                  </Link>
                  <ul className="member-slide-ul">
                    <li>
                      Join date{" "}
                      <span>
                        {moment(memberDetails?.creationdate)
                          .locale("en")
                          .format("ll")}
                      </span>
                    </li>
                    <li>
                      Status{" "}
                      <span>
                        {memberDetails?.status === "A" ? "Active" : "Inactive"}
                      </span>
                    </li>
                  </ul>
                  <Link
                    to="#"
                    className="send-reset-pass"
                    onClick={this.ResetPassword}
                  >
                    Send password reset
                  </Link>
                  <Link
                    to="#"
                    className="remove-from-nxa"
                    onClick={this.RemoveMember}
                  >
                    Remove from Nxatel
                  </Link>
                </>
              )}
            </div>
          </>
        )}
        <TeamMemberSlider
          addTeamMember={addTeamMember}
          closeTeamMemberSlider={this.closeTeamMemberSlider}
          extensionDetails={extensionDetails}
          isEdit={isEdit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  nxatel: state?.setting,
});

export default connect(mapStateToProps)(TeamMembers);
