import React, { useState } from "react";
import { store } from "../../Services";
import { BuyNxaPlan } from "../../Services/Actions/signupActions";
import swal from "sweetalert"; 

function VoucherPayment({calculated_plan_summary,voucherPaymentHandler }) {
  const [nxaVoucher, setNxaVoucher] = useState("");
  const [bluVoucher, setBluVoucher] = useState("");
  const [forYou, setForYou] = useState("");
  const [voucher, setVoucher] = useState("");
  const [clicked, setClicked] = useState(false);
  const [type, setType] = useState(""); 

  const onNxaVoucherHandler = async () => {
    const { planID, duration, plan_users, currency , trial}  = calculated_plan_summary 
    let sendData = { planID, duration, plan_users, currency , trial ,voucher_code: nxaVoucher}  
    let response = await BuyNxaPlan(sendData ,store.dispatch) 
    if (response?.code === 1) { 
      swal({
        title:"Success!",
        text:response?.ResponseText,
        icon: "success",
        buttons: true,
        dangerMode: true,
      }).then(async (resolve) => {
      if (resolve) {
        voucherPaymentHandler() 
      }
      }); 
    } else {
      setClicked(false);
    } 
  }; 

  return (
    <div className="voucher-type-main-div">
      <div className="main-voucher-box">
        <div className="voucher-type-field-bx">
          <div className="voucher-white-bx">
            <img
              src="./../assets/images/nxa-voucher.svg"
              alt="logo"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            />
            <a
              className={`btn`}
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              onClick={() => setType("nxavoucher")}
            >
              Redeem
            </a>
          </div>
        </div>
        <div
          id="collapseOne"
          className="collapse voucher-collapse-div "
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div className="card-body border-0 p-1">
            <div className="voucher-type-field-bx">
              <label>Enter your voucher number </label>
              <div className="voucher-orange-bx">
                <input
                  className="form-control"
                  type="text"
                  id="1ForYou"
                  value={nxaVoucher}
                  onChange={(e) => {
                    setNxaVoucher(e.target.value);
                    setVoucher("NxaVoucher");
                  }}
                />
                <a
                  className="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  onClick={() => {
                    setClicked(true);
                    onNxaVoucherHandler();
                  }}
                >
                  {clicked && voucher === "NxaVoucher" ? "Please Wait..." : "Apply"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-voucher-box">
        <div className="voucher-type-field-bx">
          <div className="voucher-white-bx">
            <img
              src="./../assets/images/voucher-log.svg"
              alt="logo"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            />
            <a
              className={`btn`}
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
              onClick={() => setType("foryou")}
            >
              Redeem
            </a>
          </div>
        </div>
        <div
          id="collapseTwo"
          className="collapse voucher-collapse-div "
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div className="card-body border-0 p-1">
            <div className="voucher-type-field-bx">
              <label>Enter your voucher number </label>
              <div className="voucher-orange-bx">
                <input
                  className="form-control"
                  type="text"
                  id="1ForYou"
                  value={forYou}
                  onChange={(e) => {
                    setForYou(e.target.value);
                    setVoucher("forYou");
                  }}
                />
                <a
                  className="btn"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                  onClick={() => setClicked(true)}
                >
                  {clicked && voucher === "forYou" ? "Please Wait..." : "Apply"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-voucher-box">
        <div className="voucher-type-field-bx">
          <div className="voucher-white-bx">
            <img
              src="./../assets/images/blue-voucher.svg"
              alt="logo"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
            />
            <a
              className={`btn`}
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
              onClick={() => setType("blue")}
            >
              Redeem
            </a>
          </div>
        </div>
        <div
          id="collapseThree"
          className="collapse voucher-collapse-div hide"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div className="card-body border-0 p-1">
            <div className="voucher-type-field-bx">
              <label>Enter your voucher number </label>
              <div className="voucher-orange-bx">
                <input
                  className="form-control"
                  type="text"
                  id="BluVoucher"
                  value={bluVoucher}
                  onChange={(e) => {
                    setBluVoucher(e.target.value);
                    setVoucher("bluVoucher");
                  }}
                />
                <a
                  className="btn"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                  onClick={() => setClicked(true) }
                >
                  {clicked && voucher === "bluVoucher" ? "Please Wait..." : "Apply"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoucherPayment;
