import React, { Component } from "react";
import { connect } from "react-redux";
import PageHeader from "./PageHeader";
import ProgressBar from "./ProgressBar";
import ContactBar from "./ContactBar";
import SimpleReactValidator from 'simple-react-validator'; 
import en from 'react-phone-number-input/locale/en.json'; 
import Fade from '@material-ui/core/Fade';
import { store } from "../../Services";
import { AccountSummary } from "../../Services/Actions/userActions"; 
import { withRouter } from "../../Utils/withRouter";
import { DidNumberBuy, GetDidCityList, GetDidNumber } from "../../Services/Actions/signupActions";
import { Link } from "react-router-dom";
import { countryList, countryPrefix, formatPhone, NumberOfLinesPorting } from "../../Utils/Common";
 


const initialState = {prefixType:"",selectedPrefix:"",country_prefix:'',country:"",type:"",city:null,number:"",name:"",clicked:false,port_number:false,service_provider:'',ported_number:"",no_lines_porting:''}
class CreateNumber extends Component {
    constructor(props){
        super(props);
        this.state = initialState;
        this.validator = new SimpleReactValidator();
    }

    componentDidMount = async()=>{
        window.scrollTo(0, 0) 
        let response =  await  AccountSummary(store.dispatch) 
        if(response.code===1){
            let {data:{status,user_status} = {}} = response || {};
            if(status === "P"){
                if(user_status === "UP"){
                    this.props.navigate("/choose-plan") 
                }else if(user_status === "UF"){
                    this.props.navigate("/order-summary") 
                }else if(user_status === "RE"){
                    this.props.navigate("/rica-process") 
                }else{
                    this.props.navigate("/choose-number") 
                }
            }else{
                this.props.navigate("/dashboard") 
            } 
        } 
        
    } 

    handlePrefix = (value,type,prefix)=>{
        this.PrefixDetails(type,prefix)
        this.handleChange("country_prefix",value)
    }

    PrefixDetails = (type,prefix)=>{
        this.setState({
            prefixType:type,
            selectedPrefix:prefix
        })
    }
    
    handleChange = async(fieldName,value) => {  
        if(fieldName === "country"){
            this.setState({
                country:value,
                type:""
            })
        }else if(fieldName==="type"){
            this.setState({type : value,number:"",city:"",name:"",country_prefix:''},async()=>{
                const {country,type,port_number} = this.state;
                if(type==="Local" && !port_number){
                   GetDidCityList({country:en[country]},store.dispatch) 
                }   
            });     
        }else if(fieldName==="city"){
            this.setState({city : value,number:"",name:""},async()=>{
                const {country,city,type} = this.state;
                if(type==="Local"){
                     GetDidNumber({country:en[country],city,type:"L"},store.dispatch) 
                } 
            }); 
        }else if(fieldName==="country_prefix"){
            this.setState({country_prefix : value},async()=>{
                const {country,type,country_prefix} = this.state;
                if(type==="National"){
                   GetDidNumber({country:en[country],prefix:country_prefix,type:"N"},store.dispatch) 
                } 
            }); 
        }else{
            this.setState({number:value})
        } 
    }

    onSubmitHandler = async () => {
        this.setState({clicked:true});
        if(!this.validator.allValid()){
            this.validator.showMessages();
            this.forceUpdate();    
            this.setState({clicked:false});   
            return null;     
        }
        const {country, type,number, name, port_number,service_provider,ported_number,no_lines_porting} = this.state;
        if(port_number){ 
            let portingDetails = {name,country,type,provider_name:service_provider,number:ported_number,porting:'Y',no_lines_porting,type:"signup"}
            let res = await DidNumberBuy(portingDetails,store.dispatch) 
            if(res === 1){ 
                this.props.navigate("/choose-plan")
                this.setState(initialState)
            } 
        }else{
            let DidDetails = {did_number:number,name,porting:'N',type:"signup"}
            let res = await DidNumberBuy(DidDetails,store.dispatch) 
            if(res === 1){
                this.props.navigate("/choose-plan")
                this.setState(initialState)
            } 
        }
        
    }   

    render() {
        this.validator.purgeFields();
        const {prefixType,selectedPrefix,country, type, city, number, name, port_number,service_provider,ported_number,no_lines_porting,country_prefix} = this.state;  
        const {available_did_numbers,available_city_list} = this.props; 
        return (
            <Fade in={true} timeout={500}>
            <div className="create-number">
                <div className="sign-up-step-bar">
                    <div className="container">
                        <PageHeader />
                        <ContactBar/>
                        <ProgressBar active="create-number"/>
                        <div className="business-number  text-center pt-5">
                            <h1 className="font-36 color-7c2b fw-400">
                                {port_number?"Business Landline Porting Details":"Create your business number"}
                            </h1>
                            {
                                port_number &&
                                (
                                    <Fade in={true} timeout={1000}>
                                        <React.Fragment>
                                            <h6 className="port-existing">It's easy to port your existing number to Nxatel.</h6>
                                            <p>We assign you with an initial phone number. Once your purchase is complete and the account is activated, Nxatel will be able to initiate the port out process. Track progress, Or if you have more than one line to port out log in to your account portal by going to the Settings -&gt; Phone System, Numbers tab.</p> 
                                        </React.Fragment>  
                                    </Fade>
                                ) 
                            }
                            <div className="existing-toggle">
                                <h6 className="font-18 color-6871 mt-4">
                                    Port your existing number
                                    <label className="switch mr-3">
                                        <input 
                                            type="checkbox"
                                            value={port_number?"checked":""} 
                                            name="port_number" 
                                            checked={port_number?"checked":""} 
                                            onChange={()=>this.setState({
                                                port_number:!port_number,
                                                country:'',
                                                type:'',
                                                city:''
                                            })}/>
                                        <span className="slider round" style={{width:"50px"}}></span>
                                    </label>
                                </h6>
                            </div>
                        </div>
                        <div className=" side-step-bar">
                            {!port_number && 
                                <p className="font-18 color-6871 pt-3">
                                    Choose from our number a FREE local or National
                                    for your company number
                                </p>
                            }
                            <div>
                                <ul className="StepProgress"> 
                                    <li className="StepProgress-item current d-flex">
                                        <div className="w-20 mlr number-flags"> 
                                            <div className="dropdown custom-select-dropdown"> 
                                                <button 
                                                    className="btn btn-transprent dropdown-toggle" 
                                                    type="button" 
                                                    id="dropdownMenuButton" 
                                                    data-toggle="dropdown" 
                                                    aria-haspopup="true" 
                                                    aria-expanded="false"
                                                >
                                                    {country ? en[country] : "Select Country"}
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton"> 
                                                    {countryList && countryList.map((value,index)=>{
                                                        return(
                                                            <Link 
                                                                className="dropdown-item" 
                                                                key={index} 
                                                                to="#" 
                                                                onClick={()=>this.handleChange("country",value.country_code)}
                                                            >
                                                                <span className="flag-img-span">
                                                                    <img 
                                                                        src={value.country_image ? 
                                                                            `./../assets/images/${value.country_image}` : ''
                                                                            } 
                                                                        alt="country" 
                                                                    /> 
                                                                    {value.name}
                                                                </span>
                                                            </Link> 
                                                        )
                                                    })} 
                                                </div>
                                            </div> 
                                            {this.validator.message("Country",country,"required")}
                                        </div>
                                        {country && <div className="w-20 mlr">
                                            <div className="dropdown custom-select-dropdown">
                                                <button 
                                                    className="btn btn-transprent dropdown-toggle" 
                                                    type="button" 
                                                    id="dropdownMenuButton" 
                                                    data-toggle="dropdown" 
                                                    aria-haspopup="true" 
                                                    aria-expanded="false"
                                                >
                                                    {type ? type :  "Select Number Type"}
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">   
                                                    <Link 
                                                        className="dropdown-item" 
                                                        to="#" 
                                                        onClick={()=>this.handleChange("type","Local")} 
                                                    >
                                                        Local
                                                    </Link>  
                                                    <Link 
                                                        className="dropdown-item" 
                                                        to="#" 
                                                        onClick={()=>this.handleChange("type","National")} 
                                                    >
                                                        National/Toll-Free
                                                    </Link>  
                                                </div>
                                            </div> 
                                            {this.validator.message("Number Type",type,"required|in:Local,National")}
                                        </div>}
                                        {!port_number && type==="Local" && <div className="w-20 mlr">
                                            <div className="dropdown custom-select-dropdown choose-city-drop">
                                                <button 
                                                    className="btn btn-transprent dropdown-toggle" 
                                                    type="button" 
                                                    id="dropdownMenuButton" 
                                                    data-toggle="dropdown" 
                                                    aria-haspopup="true" 
                                                    aria-expanded="false"
                                                >
                                                    {city? city : "Choose by City/Province"}
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton"> 
                                                    {available_city_list && available_city_list.map((value,key)=>{
                                                        return (
                                                                <Link 
                                                                    className="dropdown-item" 
                                                                    to="#" 
                                                                    key={key}
                                                                    onClick={()=>this.handleChange("city",value.did_city)} 
                                                                >
                                                                    {value.did_city}
                                                                    <span className="float-right">
                                                                        {value.did_city_prefix}
                                                                    </span> 
                                                                </Link> 
                                                            );
                                                        }
                                                    )} 
                                                </div>
                                            </div> 
                                            {this.validator.message("City Name",city,"required")}
                                        </div>}
                                        {!port_number && type==="National" && <div className="w-20 mlr">
                                            <div className="dropdown custom-select-dropdown choose-city-drop">
                                                <button 
                                                    className="btn btn-transprent dropdown-toggle" 
                                                    type="button" 
                                                    id="dropdownMenuButton" 
                                                    data-toggle="dropdown" 
                                                    aria-haspopup="true" 
                                                    aria-expanded="false"
                                                >
                                                    {country_prefix? `${prefixType} (${selectedPrefix})` : "Choose by Prefix"}
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton"> 
                                                    {countryPrefix && countryPrefix.map((val,key)=>{
                                                        return (
                                                            <Link 
                                                                className="dropdown-item" 
                                                                to="#" 
                                                                key={key} 
                                                                onClick={()=>this.handlePrefix(val.value,val.type,val.prefix)}
                                                                    
                                                            >
                                                                {val.type}
                                                                <span className="float-right">{val.prefix}</span> 
                                                            </Link> 
                                                        );
                                                    })} 
                                                </div>
                                            </div> 
                                            {this.validator.message("Prefix",country_prefix,"required")}
                                        </div>}
                                        {
                                            port_number && type==="Local" &&
                                            <>
                                                <div className="w-20 mlr"> 
                                                    <input 
                                                        type="text" 
                                                        className="form-control service-provider-height" 
                                                        placeholder="Service Provider Name"  
                                                        name="service_provider" 
                                                        value={service_provider} 
                                                        onChange={(e)=>this.setState({service_provider:e.target.value})} maxLength="15" 
                                                    /> 
                                                    {this.validator.message("Service Proivder Name",service_provider,"required")}
                                                </div> 
                                                <div className="w-20 mlr">
                                                    <div className="dropdown custom-select-dropdown">
                                                        <button 
                                                            className="btn btn-transprent dropdown-toggle" 
                                                            type="button" 
                                                            id="dropdownMenuButton" 
                                                            data-toggle="dropdown" 
                                                            aria-haspopup="true" 
                                                            aria-expanded="false"
                                                        >
                                                            {no_lines_porting ? no_lines_porting : "Number of Lines Porting"}
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton"> 
                                                            {NumberOfLinesPorting && NumberOfLinesPorting.map((val,key)=>{
                                                                return (
                                                                    <Link 
                                                                        className="dropdown-item text-center" 
                                                                        to="#" 
                                                                        key={key} 
                                                                        onClick={()=>this.setState({no_lines_porting:val.value})}
                                                                    >
                                                                        {val.text}
                                                                    </Link> 
                                                                )
                                                            })} 
                                                        </div>
                                                    </div> 
                                                </div>  
                                                { no_lines_porting === "1" && 
                                                    <div className="w-20 mlr"> 
                                                        <input 
                                                            type="number" 
                                                            className="form-control service-provider-height" 
                                                            placeholder="Number to be ported"  
                                                            name="ported_number" 
                                                            value={ported_number}  
                                                            onChange={(event) => {
                                                                if(event.target.value.length > 20) return false;    
                                                                this.setState({[event.target.name]: event.target.value })       
                                                            }} 
                                                        /> 
                                                        {this.validator.message("Number to be ported",ported_number, "required,num")}
                                                    </div> 
                                                }
                                            </>
                                        }  
                                        {!port_number && (country_prefix || city) && available_did_numbers?.length > 0 && <div className="w-20 mlr">
                                            <div className="dropdown custom-select-dropdown">
                                                <button 
                                                    className="btn btn-transprent dropdown-toggle" 
                                                    type="button" 
                                                    id="dropdownMenuButton" 
                                                    data-toggle="dropdown" 
                                                    aria-haspopup="true" 
                                                    aria-expanded="false"
                                                >
                                                    {number ? formatPhone(number?.substr(0,2)==="27" ? `0${number?.substr(2)}`:number) : "Select your Number"}
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton"> 
                                                    {available_did_numbers && available_did_numbers.map((value)=>{
                                                        let did_number = value.did_number?.substr(0,2)==="27"?`0${value.did_number?.substr(2)}`:value.did_number;
                                                        return (
                                                            <Link
                                                                className="dropdown-item" 
                                                                to="#" 
                                                                onClick={()=>this.handleChange("number",value.did_number)} 
                                                            > 
                                                                {formatPhone(did_number)}
                                                            </Link> 
                                                        );
                                                    })} 
                                                </div>
                                            </div> 
                                            {this.validator.message("Number",number,"required")}
                                        </div>}     
                                    </li> 
                                    <li className="StepProgress-item current">
                                        <div>
                                            <label className="font-18 color-6871">Name your number (by Team/Department)</label>
                                            <input 
                                                type="text" 
                                                className="form-control font-12 color-6871 w-20" 
                                                placeholder="E.g Your Company HQ or Sale Team" 
                                                value={name} 
                                                name="name" 
                                                onChange={(e)=>this.setState({name:e.target.value})}
                                            />
                                            {this.validator.message("Name of Number",name,"required|min:4,string|max:30,string")}
                                        </div>
                                    </li>
                                    <li className="StepProess-item mt-1">
                                        <button 
                                            type="button" 
                                            className="btn btn-orange" 
                                            onClick={this.onSubmitHandler}
                                        >
                                            Create Number
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Fade>
        );
    }
}

const mapStateToProps = state => { 
    return {
        available_city_list:state?.signup?.available_city_list??[],
        available_did_numbers:state?.signup?.available_did_numbers??[] 
    }
}
export default connect(mapStateToProps)(withRouter(CreateNumber));
