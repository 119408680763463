import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  CallReport,
  DidNumberListing,
} from "../../Services/Actions/reportActions";
import { Link } from "react-router-dom";
import TeamMemberSlider from "../Calls/Team/TeamMemberSlider";
import {
  convertUtcToLocalTime,
  formatDIDNumber,
  SecondsTohhmmss,
} from "../../Utils/Common";
import Pagination from "../../Component/Pagination";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import { store } from "../../Services";
import Sip from "../Sip";
import { ExtensionListing } from "../../Services/Actions/settingActions";
import AudioPlayer from "../Settings/PhoneSystem/CallFlow/AudioPlayer";

const __CallType = [
  { text: "Calls Made", value: "outbound" },
  { text: "Missed Calls", value: "missed" },
  { text: "Answered Calls", value: "inbound" },
  { text: "Voicemail", value: "voicemail" },
  { text: "Call Recording", value: "recording" },
];

const reduceDateToString = (unformated) => {
  const date = unformated ? unformated.toLocaleString().split(",")[0] : "";
  return moment(date).format("YYYY-MM-DD");
};

function Reports({
  call_report,
  call_count,
  extension_listing,
  did_number_list,
  user_details: { user_plan },
}) {
  const [pageOfItems, setPageOfItems] = useState([]);
  const [selectNumber, setSelectNumber] = useState([]);
  const [selectUser, setSelectUser] = useState([]);
  const [selectCallType, setSelectCallType] = useState([]);
  const [addTeamMember, setAddTeamMember] = useState(false);
  const [isDialerOpen, setDialerOpen] = useState(false);
  const [fromStartPage, setFromStartPage] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [activeMenu, setActiveMenu] = useState("all-calls");
  const [startDate, endDate] = dateRange;
  const voice_minute = user_plan?.voice_minute ?? "00";

  useEffect(async () => {
    ExtensionListing(0, store.dispatch);
    DidNumberListing(store.dispatch, "phoneSettings");
  }, []);

  useEffect(async () => {
    if (activeMenu === "all-calls") {
      refreshIconHandler();
    } else {
      setSelectCallType([activeMenu]);
      setSelectNumber([]);
      setSelectUser([]);
      setDateRange([null, null]);
      CallReport({ direction: [activeMenu] }, store.dispatch).then((res) => {
        if (res === 1) {
          window.$(".report-drop-collapse").removeClass("show");
          setFromStartPage(true);
        }
      });
    }
  }, [activeMenu]);

  const UpdateData = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  const SelectHandler = (event, value, type) => {
    switch (type) {
      case "number": {
        if (event.target.checked) {
          setSelectNumber([...selectNumber, value]);
        } else {
          let array = [...selectNumber];
          let index = array.indexOf(value);
          if (index !== -1) {
            array.splice(index, 1);
            setSelectNumber(array);
          }
        }
        break;
      }
      case "user": {
        if (event.target.checked) {
          setSelectUser([...selectUser, value]);
        } else {
          let array = [...selectUser];
          let index = array.indexOf(value);
          if (index !== -1) {
            array.splice(index, 1);
            setSelectUser(array);
          }
        }
        break;
      }
      case "call-type": {
        if (event.target.checked) {
          setSelectCallType([...selectCallType, value]);
        } else {
          let array = [...selectCallType];
          let index = array.indexOf(value);
          if (index !== -1) {
            array.splice(index, 1);
            setSelectCallType(array);
          }
        }
        break;
      }
      default:
        return false;
    }
  };

  const selectAllHandler = (type) => {
    let array = [];
    switch (type) {
      case "number": {
        did_number_list &&
          did_number_list.map(({ did_number }) => {
            return array.push(did_number);
          });
        setSelectNumber(array);
        break;
      }
      case "user": {
        extension_listing &&
          extension_listing.map(({ user_extension }) => {
            return array.push(user_extension);
          });
        setSelectUser(array);
        break;
      }
      case "call-type": {
        __CallType &&
          __CallType.map(({ value }) => {
            return array.push(value);
          });
        setSelectCallType(array);
        break;
      }
      default:
        return false;
    }
  };

  const applyFiltrationHandler = () => {
    let params = {
      number: selectNumber,
      ext_no: selectUser,
      direction: selectCallType,
      from: startDate ? reduceDateToString(startDate) : "",
      to: endDate ? reduceDateToString(endDate) : "",
    };
    CallReport(params, store.dispatch).then((res) => {
      if (res === 1) {
        window.$(".report-drop-collapse").removeClass("show");
        setFromStartPage(true);
      }
    });
  };

  const refreshIconHandler = () => {
    setSelectNumber([]);
    setSelectUser([]);
    setSelectCallType([]);
    setDateRange([null, null]);
    CallReport({}, store.dispatch).then((res) => {
      if (res === 1) {
        window.$(".report-drop-collapse").removeClass("show");
        setFromStartPage(true);
      }
    });
  };

  return (
    <>
      <div className="right-side-wrapper">
        <div className="card">
          <div className="new-message-tabs reports-tab">
            <div className="card-body p-0">
              <div className="row m-0">
                <Sidebar
                  setActiveMenu={setActiveMenu}
                  activeMenu={activeMenu}
                />
                <div className="col-md-8 col-lg-9">
                  <div className="report-page-div">
                    <div className="right-side-top-header reports-right-side-top-header">
                      <h5>Report</h5>
                      <div className="report-date-head-div">
                        <div className="call-report-refresh-icon">
                          <Link to="#" title="Refresh Call Report" onClick={refreshIconHandler}>
                            <img
                              className="call-report-refresh-img"
                              src="./../assets/images/refresh-ico.svg"
                              alt="1"
                            />
                          </Link>
                          <span className="filter-serach">
                            <img src="./../assets/images/filter.svg" alt="1" />
                            <DatePicker
                              placeholderText="Filter by Date Range"
                              className="form-control"
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              onChange={(update) => {
                                setDateRange(update);
                              }}
                              isClearable={true}
                            />
                            {dateRange?.[0] !== null && (
                              <img
                                src="./../assets/images/search-dash.svg"
                                className="search-date-ico"
                                alt="1"
                                style={{ cursor: "pointer" }}
                                onClick={applyFiltrationHandler}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* -------- */}
                    <CallStatsReport call_count={call_count} />
                    {/* --------- */}
                    <div className="report-filter-div">
                      <h4>Filtered Calls Report</h4>
                      <div className="form-group">
                        <label className="report-label">Filter by Number</label>
                        <div className="dropdown">
                          <a
                            className="btn btn-select-drop-report"
                            data-toggle="collapse"
                            data-target="#collapseExample"
                            aria-expanded="true"
                            aria-controls="collapseExample"
                          >
                            {`${
                              selectNumber?.length === 0
                                ? "No"
                                : selectNumber?.length
                            } number selected`}
                            <img
                              src="./../assets/images/menu-arrow-up.svg"
                              alt="1"
                            />
                          </a>
                          <div
                            className="collapse report-drop-collapse"
                            id="collapseExample"
                          >
                            <div className="card card-body">
                              <div className="report-filter-drop">
                                {did_number_list &&
                                  did_number_list.map(
                                    ({ did_number }, index) => {
                                      return (
                                        <a
                                          href={void 0}
                                          key={index}
                                          className={`${
                                            selectNumber.includes(did_number)
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <span>
                                            {formatDIDNumber(did_number)}
                                          </span>
                                          <span>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              name="selectNumber"
                                              value={did_number}
                                              id={did_number}
                                              onChange={(event) =>
                                                SelectHandler(
                                                  event,
                                                  did_number,
                                                  "number"
                                                )
                                              }
                                              checked={selectNumber.includes(
                                                did_number
                                              )}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={did_number}
                                            ></label>
                                          </span>
                                        </a>
                                      );
                                    }
                                  )}
                              </div>
                              <div className="reportfilter-drop-btn-div">
                                <span>
                                  <Link
                                    to="#"
                                    onClick={() => setSelectNumber([])}
                                  >
                                    {" "}
                                    Unselect All
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={() => selectAllHandler("number")}
                                  >
                                    {" "}
                                    Select All{" "}
                                  </Link>
                                </span>
                                <Link
                                  to="#"
                                  className="filter-drop-aply-btn"
                                  onClick={applyFiltrationHandler}
                                >
                                  Apply
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="report-label">Filter by User</label>
                        <div className="dropdown">
                          <a
                            className="btn btn-select-drop-report"
                            data-toggle="collapse"
                            data-target="#collapseExample2"
                            aria-expanded="true"
                            aria-controls="collapseExample2"
                          >
                            {`${
                              selectUser?.length === 0
                                ? "No"
                                : selectUser?.length
                            } user selected`}
                            <img
                              src="./../assets/images/menu-arrow-up.svg"
                              alt="1"
                            />
                          </a>
                          <div
                            className="collapse report-drop-collapse"
                            id="collapseExample2"
                          >
                            <div className="card card-body">
                              {extension_listing?.length > 0 && (
                                <>
                                  <div className="report-filter-drop">
                                    {extension_listing &&
                                      extension_listing.map((value, index) => {
                                        return (
                                          <a
                                            key={index}
                                            href={void 0}
                                            className={`${
                                              selectUser.includes(
                                                value.user_extension
                                              )
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            <span>
                                              {`${value.name}`}{" "}
                                              <i className="italic-font-color">{`(${value.user_extension})`}</i>
                                            </span>
                                            <span>
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="selectUser"
                                                value={value.user_extension}
                                                id={value.user_extension}
                                                onChange={(event) =>
                                                  SelectHandler(
                                                    event,
                                                    value.user_extension,
                                                    "user"
                                                  )
                                                }
                                                checked={selectUser.includes(
                                                  value.user_extension
                                                )}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={value.user_extension}
                                              ></label>
                                            </span>
                                          </a>
                                        );
                                      })}
                                  </div>
                                  <div className="reportfilter-drop-btn-div">
                                    <span>
                                      <Link
                                        to="#"
                                        onClick={() => setSelectUser([])}
                                      >
                                        Unselect All
                                      </Link>
                                      <Link
                                        to="#"
                                        onClick={() => selectAllHandler("user")}
                                      >
                                        Select All
                                      </Link>
                                    </span>
                                    <Link
                                      to="#"
                                      className="filter-drop-aply-btn"
                                      onClick={applyFiltrationHandler}
                                    >
                                      Apply
                                    </Link>
                                  </div>
                                </>
                              )}
                              {extension_listing?.length === 0 && (
                                <>
                                  <div className="not-data-fliter-drop">
                                    <img
                                      src="./../assets/images/assign-modal-vector.svg"
                                      alt="1"
                                    />
                                    <p>
                                      Currently you don’t have any team members
                                      added. Add{" "}
                                      <Link
                                        to="#"
                                        onClick={() => setAddTeamMember(true)}
                                      >
                                        <b>Team member</b>
                                      </Link>
                                    </p>
                                  </div>
                                  <div className="reportfilter-drop-btn-div">
                                    <Link
                                      to="#"
                                      className="filter-drop-aply-btn"
                                    >
                                      Cancel
                                    </Link>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="report-label">
                          Filter by call type
                        </label>
                        <div className="dropdown">
                          <a
                            className="btn btn-select-drop-report"
                            data-toggle="collapse"
                            data-target="#collapseExample3"
                            aria-expanded="true"
                            aria-controls="collapseExample3"
                          >
                            {`${
                              selectCallType?.length === 0
                                ? "No"
                                : selectCallType?.length
                            } call type selected`}
                            <img
                              src="./../assets/images/menu-arrow-up.svg"
                              alt="1"
                            />
                          </a>
                          <div
                            className="collapse report-drop-collapse"
                            id="collapseExample3"
                          >
                            <div className="card card-body">
                              <div className="report-filter-drop">
                                {__CallType &&
                                  __CallType.map((data, index) => {
                                    return (
                                      <a
                                        href={void 0}
                                        key={index}
                                        className={`${
                                          selectCallType.includes(data.value)
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <span>{data.text}</span>
                                        <span>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="selectCallType"
                                            value={data.value}
                                            id={data.value}
                                            onChange={(event) =>
                                              SelectHandler(
                                                event,
                                                data.value,
                                                "call-type"
                                              )
                                            }
                                            checked={selectCallType.includes(
                                              data.value
                                            )}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={data.value}
                                          ></label>
                                        </span>
                                      </a>
                                    );
                                  })}
                              </div>
                              <div className="reportfilter-drop-btn-div">
                                <span>
                                  <Link
                                    to="#"
                                    onClick={() => setSelectCallType([])}
                                  >
                                    Unselect All
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      selectAllHandler("call-type")
                                    }
                                  >
                                    Select All
                                  </Link>
                                </span>
                                <Link
                                  to="#"
                                  className="filter-drop-aply-btn"
                                  onClick={applyFiltrationHandler}
                                >
                                  Apply
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Link to="#" title="=Download Call Report" className="report-dwn-ico">
                        <img
                          src="./../assets/images/report-download.svg"
                          alt="1"
                        />
                      </Link>
                    </div>
                    {/* --------- */}
                    <div
                      className={`call-info-scroll height-auto ${
                        pageOfItems?.length === 0 ? "empty-data-scroll" : ""
                      }`}
                    >
                      <div className="table-responsive calls-table calls-table-hover h-100">
                        {/* report-pge-table */}
                        <table className="table table-center mb-0 ">
                          <thead>
                            <th>Type</th>
                            <th>Account</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Country</th>
                            <th>Description</th>
                            <th>Date/Time</th>
                            <th>
                              <span className="d-block">Charged Time,</span>min:
                              sec
                            </th>
                            <th>
                              <span className="d-block">Amount</span>Charged
                            </th>
                            <th>Play</th>
                          </thead>
                          <tbody>
                            {pageOfItems &&
                              pageOfItems.map((value, index) => {
                                let date_time = convertUtcToLocalTime(
                                  value.start_stamp
                                );
                                let filterName = value.contact_name.includes(
                                  "#"
                                )
                                  ? value.contact_name.replace("#", "")
                                  : value.contact_name;
                                let member_name =
                                  extension_listing &&
                                  extension_listing.find(
                                    ({ user_extension }) =>
                                      user_extension ===
                                      value.destination_number
                                  )?.name;
                                return (
                                  <tr key={index}>
                                    <td className="report-call-type-ico">
                                      <img
                                        src={`./../assets/images/${
                                          value.direction === "inbound" &&
                                          value.billsec === 0
                                            ? "report-missed-call.svg"
                                            : value.direction === "inbound" &&
                                              value.billsec > 0
                                            ? "report-incoming-call.svg"
                                            : value.direction === "outbound"
                                            ? "report-outgoing-call.svg"
                                            : "report-voice-mail.svg"
                                        }`}
                                        alt="1"
                                      />
                                    </td>
                                    <td>{value?.account ?? ""}</td>
                                    <td>
                                      {" "}
                                      {filterName
                                        ? filterName
                                        : value.direction === "outbound"
                                        ? `${value.destination_number} ${
                                            member_name
                                              ? `(${member_name})`
                                              : ""
                                          }`
                                        : value.caller_id_number}
                                    </td>
                                    <td>
                                      {value.direction === "outbound"
                                        ? value.caller_id_number
                                        : `${value.destination_number} ${
                                            member_name
                                              ? `(${member_name})`
                                              : ""
                                          }`}
                                    </td>
                                    <td>South Africa</td>
                                    <td>South Africa Mobile</td>
                                    <td>{date_time}</td>
                                    <td>{SecondsTohhmmss(value.billsec)}</td>
                                    <td>{value.cost}</td>
                                    {value.record_path && ( 
                                      <td className="report-action">
                                        <Link to="#" >
                                          <AudioPlayer src={value.record_path} />
                                        </Link> 
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}

                            {pageOfItems && pageOfItems?.length === 0 && (
                              <tr className="empty-tr">
                                <td colSpan="10">
                                  <img
                                    src="./../assets/images/make-call-first.png"
                                    alt="1"
                                  />
                                  <p>
                                    Currently you don’t have any call history
                                    synced on your report dashboard. Make the
                                    first call to start tracking your calls
                                    history!
                                  </p>
                                  <Link
                                    to="#"
                                    className="make-your-first-call-btn"
                                    onClick={() => setDialerOpen(true)}
                                  >
                                    Make your first Call
                                  </Link>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* ------ */}
                  <Pagination
                    data={call_report}
                    fromStartPage={fromStartPage}
                    setFromStartPage={setFromStartPage}
                    currentData={UpdateData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TeamMemberSlider
        addTeamMember={addTeamMember}
        closeTeamMemberSlider={() => setAddTeamMember(false)}
      />
      {isDialerOpen && (
        <Sip
          show={isDialerOpen}
          handleClose={() => setDialerOpen(false)}
          voice_minute={voice_minute}
        />
      )}
    </>
  );
}

const CallStatsReport = ({ call_count }) => {
  return (
    <div className="call-report-box-div">
      <div className="call-report-bx">
        <div className="call-report-bx-inner">
          <img src="./../assets/images/report-call-blue-ico.svg" alt="1" />
          <div className="call-report-bx-right">
            <span className="reports-calls">
              <h3>All Calls</h3>
              <h3>{call_count?.[0]?.total_calls ?? 0}</h3>
            </span>
            <span className="reports-calls report-calls-progress">
              <h3>Total time spent</h3>
              <div className="progress bg-success">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "40%" }}
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="call-report-bx">
        <div className="call-report-bx-inner">
          <img
            src="./../assets/images/report-missed-call-blue-ico.svg"
            alt="1"
          />
          <div className="call-report-bx-right">
            <span className="reports-calls">
              <h3>Missed Calls</h3>
              <h3 className="text-danger">
                {call_count?.[0]?.missed_call ?? 0}
              </h3>
            </span>
            <span className="reports-calls report-calls-progress">
              <h3>Total time spent</h3>
              <div className="progress  bg-warning">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "65%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="call-report-bx">
        <div className="call-report-bx-inner">
          <img
            src="./../assets/images/report-incomingcall-blue-ico.svg"
            alt="1"
          />
          <div className="call-report-bx-right">
            <span className="reports-calls">
              <h3>Incoming Calls</h3>
              <h3 className="text-success">
                {call_count?.[0]?.inbound_calls ?? 0}
              </h3>
            </span>
            <span className="reports-calls report-calls-progress">
              <h3>Total call participants</h3>
              <div className="progress bg-danger">
                <div
                  className="progress-bar "
                  role="progressbar"
                  style={{ width: "85%" }}
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    report: { call_report = [], call_count = 0, did_number_list = [] } = {},
    setting: { extension_listing = [] },
    user: { user_details: { userDetail: user_details = {} } = {} },
  } = state;
  return {
    call_report,
    call_count,
    extension_listing,
    user_details,
    did_number_list,
  };
};
export default connect(mapStateToProps)(Reports);
