import React, { Component } from "react";
import { store } from "../../Services";
import { ForceCall } from "../../Services/Actions/callActions";

class CallSupport extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="card discover-inner">
          <div className="card-body pl-0 pr-0">
            <div className="Product-sec discover-slide mb-0">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 p-0">
                    <div className="call-support">
                      <h5 className="colorh5">Call our support team</h5>
                      <img
                        src="./../assets/images/support/Icon.png"
                        alt="1"
                        style={{ cursor: "pointer" }}
                        onClick={() => ForceCall("0861692835",store.dispatch)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CallSupport;
