import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { store } from "../../../Services";
import {
  PortingProcessSave,
  RicaPortingProcess,
} from "../../../Services/Actions/signupActions";
import { withRouter } from "../../../Utils/withRouter";
import swal from "sweetalert";

class PortingProcess extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      letter: "",
      selectedLetter: false,
      id_number: "",
      full_name: "",
      id_proof: "",
      bill_proof: "",
      selectedID: false,
      selectedBill: false,
      terms_conditions: false,
      account_number: "",
      account_type: "personal",
      service_provider_account: "",
      business_name: "",
      ported_number: "",
      contact_name: "",
    };
  }

  componentDidMount() {
    const {
      web_registration: { rica_porting_process },
    } = this.props;
    if (rica_porting_process?.account_type) {
      const {
        id_number,
        full_name,
        business_type,
        account_number,
        account_type,
        service_provider_account,
        ported_number,
        contact_name,
      } = rica_porting_process;
      this.setState({
        id_number,
        full_name,
        business_type,
        account_number,
        account_type,
        service_provider_account,
        ported_number,
        contact_name,
      });
    }
  }

  uploadImage = () => {
    let id_proof = window.$("#upload_documents")[0].files[0];
    this.setState({
      id_proof,
      selectedID: true,
    });
  };

  uploadServiceImage = () => {
    let bill_proof = window.$("#upload_service")[0].files[0];
    this.setState({
      bill_proof,
      selectedBill: true,
    });
  };

  uploadLetterImage = () => {
    let letter = window.$("#upload_letter")[0].files[0];
    this.setState({
      letter,
      selectedLetter: true,
    });
  };

  PortingProcessHandler = async () => {
    const {
      id_proof,
      account_number,
      bill_proof,
      account_type,
      letter,
      service_provider_account,
      business_name,
      ported_number,
      contact_name,
      full_name,
      id_number,
    } = this.state;
    const {
      user_details: {
        userDetail: {
          porting_details: { id },
        },
      },
      isRicaCompleted,
    } = this.props;
    RicaPortingProcess(this.state, store.dispatch);
    if (this.validator.allValid()) {
      let formData = new FormData();
      if (account_type === "personal") {
        formData.append("name", full_name);
        formData.append("id_number", id_number);
        formData.append("id_proof", id_proof);
      } else {
        formData.append("business_name", business_name);
        formData.append("account_name ", contact_name);
        formData.append("id_proof", letter);
      }
      formData.append("id", id);
      formData.append("account_number", account_number);
      formData.append("account_with_sp", service_provider_account);
      formData.append("number", ported_number);
      formData.append("bill_proof", bill_proof);
      formData.append("account_type", account_type);
      if (isRicaCompleted) {
        let response = await PortingProcessSave(formData, store.dispatch);
        if (response === 1) {
          this.props.navigate("/welcome");
        }
      } else {
        swal({
          icon: "error",
          title: "Error!",
          text: "Please Complete Account Verification Process first.",
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const {
      selectedID,
      selectedBill,
      selectedLetter,
      terms_conditions,
      full_name,
      id_number,
      account_number,
      account_type,
      service_provider_account,
      business_name,
      ported_number,
      contact_name,
      bill_proof,
      id_proof,
      letter,
    } = this.state;
    this.validator.purgeFields();
    return (
      <div className="col-md-8 col-lg-9">
        <div className="row">
          <div className="col-md-12">
            <div className="porting-inner porting-process">
              <h1 className="font-43 color-191B fw-700">Porting Process</h1>
              <p>
                Number Porting or Number Transfer allows you to keep your number
                from a previous service provider to be ported over to Nxatel.
                Submitting a Number Port Request for non-Nxatel phone numbers
                can be done through your online account.
                <br></br>
                <br></br>
                Note: Before submitting a number port request, you’ll need to
                meet specific requirements. See Requirements for Number
                Porting for more information.
              </p>
              <h5 className="see-exp-doc-link">
                See examples of required docs
                <i className="fas fa-chevron-circle-right ml-2 color-7C2B"></i>
              </h5>
              <div className="id-verifications-radio id-verifications-porting-process">
                <div className="radio-1 radio-common-cls d-flex">
                  <input
                    type="radio"
                    name="account_type"
                    value="personal"
                    checked={account_type === "personal" ? "checked" : ""}
                    onChange={(e) =>
                      this.setState({
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <span>Personal</span>
                </div>
                <div className="radio-2 radio-common-cls d-flex">
                  <input
                    type="radio"
                    name="account_type"
                    value="business"
                    checked={account_type === "business" ? "checked" : ""}
                    onChange={(e) =>
                      this.setState({
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <span>Business Account</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      {account_type === "personal" && (
                        <>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              aria-describedby=""
                              placeholder="Full Name"
                              name="full_name"
                              value={full_name}
                              onChange={(e) =>
                                this.setState({
                                  [e.target.name]: e.target.value,
                                })
                              }
                              maxLength="20"
                            />
                            {account_type === "personal"
                              ? this.validator.message(
                                  "Full Name",
                                  full_name,
                                  "required"
                                )
                              : ""}
                          </div>
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              aria-describedby=""
                              placeholder="ID Number/Passport No"
                              name="id_number"
                              value={id_number}
                              onChange={(event) => {
                                if (event.target.value.length > 13)
                                  return false;
                                this.setState({
                                  [event.target.name]: event.target.value,
                                });
                              }}
                            />
                            {account_type === "personal"
                              ? this.validator.message(
                                  "ID Number/Passport No",
                                  id_number,
                                  "required|min:13"
                                )
                              : ""}
                          </div>
                        </>
                      )}
                      {account_type === "business" && (
                        <>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Business Name"
                              name="business_name"
                              value={business_name}
                              maxLength="20"
                              onChange={(e) =>
                                this.setState({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                            {account_type === "business"
                              ? this.validator.message(
                                  "Business Name",
                                  business_name,
                                  "required"
                                )
                              : ""}
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              aria-describedby=""
                              placeholder="Account Contact Name"
                              name="contact_name"
                              value={contact_name}
                              maxLength="20"
                              onChange={(e) =>
                                this.setState({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                            {account_type === "business"
                              ? this.validator.message(
                                  "Account Contact Name",
                                  contact_name,
                                  "required"
                                )
                              : ""}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Number to be ported"
                          name="ported_number"
                          value={ported_number}
                          onChange={(event) => {
                            if (event.target.value.length > 20) return false;
                            this.setState({
                              [event.target.name]: event.target.value,
                            });
                          }}
                        />
                        {this.validator.message(
                          "Number to be ported",
                          ported_number,
                          "required|max:20"
                        )}
                      </div>
                      <div className="form-group">
                        <select
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          name="service_provider_account"
                          value={service_provider_account}
                          maxLength="20"
                          onChange={(e) =>
                            this.setState({
                              [e.target.name]: e.target.value,
                            })
                          }
                        >
                          <option value="">
                            Account Up to date with Service Provider
                          </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        {this.validator.message(
                          " Account Up to date with Service Provider",
                          service_provider_account,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Account Number"
                          name="account_number"
                          value={account_number}
                          onChange={(e) =>
                            this.setState({
                              [e.target.name]: e.target.value,
                            })
                          }
                          maxLength="20"
                        />
                        {this.validator.message(
                          "Account Number",
                          account_number,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="col-md-5"></div>
                    <div className="col-md-1"></div>
                  </div>
                  <p className="color-7AFE font-14 fw-400">
                    Provide the required documents to complete the porting
                    process
                  </p>
                </div>
              </div>
              <div className="d-flex mt-3">
                {account_type === "personal" && (
                  <>
                    <div className="validation-handler">
                      <div
                        className={`${
                          selectedID ? "tick" : "porting"
                        }-box p-4 text-center`}
                        onClick={(e) => this.uploadRef.click()}
                      >
                        <input
                          type="file"
                          id="upload_documents"
                          name="documents"
                          ref={(input) => (this.uploadRef = input)}
                          accept="*"
                          onChange={(e) => this.uploadImage(e)}
                          hidden
                        />
                        <img
                          src={`./../assets/images/${
                            selectedID
                              ? "icons/tick.svg"
                              : "signup/COMPANY-REGISTRATION-IMG.svg"
                          }`}
                          alt="1"
                        />
                        <h6 className="font-14 color-fff lh-17">
                          UPLOAD <br></br>ID/PASSPORT<br></br>
                        </h6>
                      </div>
                      {this.validator.message(
                        "Upload ID/PASSPORT",
                        id_proof,
                        "required"
                      )}
                    </div>
                  </>
                )}
                {account_type === "business" && (
                  <>
                    <div className="validation-handler">
                      <div
                        className={`${
                          selectedLetter ? "tick" : "porting"
                        }-box p-4 text-center`}
                        onClick={(e) => this.uploadLetterRef.click()}
                      >
                        <input
                          type="file"
                          id="upload_letter"
                          name="documents"
                          ref={(input) => (this.uploadLetterRef = input)}
                          accept="*"
                          onChange={(e) => this.uploadLetterImage(e)}
                          hidden
                        />
                        <img
                          src={`./../assets/images/${
                            selectedLetter
                              ? "icons/tick.svg"
                              : "signup/letter.svg"
                          }`}
                          alt="1"
                        />
                        <h6 className="font-14 color-fff lh-17">
                          COMPANY <br></br>LETTERHEAD <br></br>
                        </h6>
                      </div>
                      {this.validator.message(
                        "Company Letterhead",
                        letter,
                        "required"
                      )}
                    </div>
                  </>
                )}
                <div className="validation-handler">
                  <div
                    className={`${
                      selectedBill ? "tick" : "porting"
                    }-box p-4 text-center`}
                    onClick={(e) => this.uploadServiceRef.click()}
                  >
                    <input
                      type="file"
                      id="upload_service"
                      name="documents"
                      ref={(input) => (this.uploadServiceRef = input)}
                      accept="*"
                      onChange={(e) => this.uploadServiceImage(e)}
                      hidden
                    />
                    <img
                      src={`./../assets/images/${
                        selectedBill
                          ? "icons/tick.svg"
                          : "signup/ic_outline-location-on.svg"
                      }`}
                      alt="1"
                    />
                    <h6 className="font-14 color-fff lh-17">
                      SERVICE <br></br>PROVIDER BILL
                    </h6>
                  </div>
                  {this.validator.message(
                    "Service Provider Bill",
                    bill_proof,
                    "required"
                  )}
                </div>
              </div>
              <div className="d-flex mt-3">
                <div className="form-check text-center mt-1 p-0">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    value={terms_conditions ? "checked" : ""}
                    name="terms_conditions"
                    checked={terms_conditions ? "checked" : ""}
                    onChange={() =>
                      this.setState({
                        terms_conditions: !terms_conditions,
                      })
                    }
                  />
                  <label
                    className="form-check-label font-14 color-191b fw-500"
                    htmlFor="exampleCheck1"
                  >
                    By proceeding you agree to the porting terms of service.
                  </label>
                  {this.validator.message(
                    "Terms & conditions",
                    terms_conditions,
                    "required|accepted"
                  )}
                </div>
                <button
                  type="button"
                  className="btn btn-new-sign ml-3"
                  onClick={this.PortingProcessHandler}
                >
                  Start Porting
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user_details: state?.user?.user_details ?? {},
    web_registration: state?.signup,
  };
};
export default connect(mapStateToProps)(withRouter(PortingProcess));
