import React, { useEffect, useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator"; 
import PhoneInput, {
  parsePhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input"; 
import swal from "sweetalert";
import en from "react-phone-number-input/locale/en.json";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "../../../Utils/withRouter";
import { store } from "../../../Services"; 
import { OnBoardProcessStatics } from "../../../Services/Actions/dashboardActions";
import { CreateUpdateExtension, UploadFiles } from "../../../Services/Actions/callActions";
import { countryList } from "../../../Utils/Common";
import { DidNumberListing } from "../../../Services/Actions/reportActions";

function TeamMemberSlider(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [assign_number, setAssignNumber] = useState([]);
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [prefix, setPrefix] = useState("");
  const [callNumber, setCallNumber] = useState("");
  const [image, setImage] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [role, setRole] = useState("");
  const [customerID, setCustomerId] = useState("");
  const [userExtension, setUserExtension] = useState("");
  const [, forceUpdate] = useState();
  const { 
    addTeamMember,
    closeTeamMemberSlider,
    extensionDetails, 
    renderPage,
    viewOnly,
    did_number_list
  } = props;
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const uploadRef = useRef(null);

  useEffect(() => {
    DidNumberListing(store.dispatch, "phoneSettings");
  }, []);

  useEffect(() => { 
    if (extensionDetails && Object.keys(extensionDetails).length) { 
      const {name,email,profile_picture,country,address,type,phone,customerID,user_extension,prefix,assign_callerID = []} =  extensionDetails  
      
      let array = []
      assign_callerID && assign_callerID.map(value=>{
        return array.push(value.callerID)
      }) 
      setName(name);
      setEmail(email);
      setUserImage(profile_picture);
      setImage(true);
      setCountry(country);
      setAddress(address);
      setRole(type === "A" ? "admin" : "member");
      setNumber(phone);
      setCustomerId(customerID);
      setUserExtension(user_extension);
      setPrefix(prefix);
      setAssignNumber(array);
      setCallNumber(phone && phone.replace("+","").replace(prefix,""));
    } else if (extensionDetails && !Object.keys(extensionDetails).length) {
      setName("");
      setEmail("");
      setUserImage("");
      setImage(false);
      setCountry("");
      setAddress("");
      setRole("");
      setNumber("");
      setCustomerId("");
      setUserExtension("");
      setPrefix("");
      setAssignNumber([]);
      setCallNumber("");
    }
  }, [extensionDetails]);

  const numberChange = (number) => {
    let phoneNumber = number ? parsePhoneNumber(number.toString()) : "";
    let prefix = "";
    let country_code = "";
    let call_number = "";
    if (phoneNumber) {
      if (phoneNumber.nationalNumber && phoneNumber.country) {
        call_number = phoneNumber.nationalNumber;
        country_code = phoneNumber.country;
        if (getCountryCallingCode(phoneNumber.country)) {
          prefix = getCountryCallingCode(phoneNumber.country);
        }
      }
    }
    setCallNumber(call_number);
    setPrefix(prefix);
  };

  const addUpdateNxaMember = async () => {
    if (validator.current.allValid()) {
      let data = {
        firstname: name,
        email,
        prefix,
        country,
        callingnumber: callNumber,
        phone: callNumber,
        address,
        assign_number,
        role,
        user_extension: customerID
          ? userExtension
          : String(Math.floor(100000 + Math.random() * 900000)).substring(0, 4),
        password: 111111,
        app_picture: image ? userImage : "",
      };
      let postData = customerID ? { ...data, customerID } : data;
      let response = await 
        CreateUpdateExtension(postData, customerID,store.dispatch) 
      if (response === 1) {
        if (renderPage === "welcome") {
         OnBoardProcessStatics(store.dispatch);
        }
        closeTeamMemberSlider();
        window.$(`#${userExtension}`).removeClass("show");
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const uploadImage = async () => {
    let imageFile = window.$("#member_image")[0].files[0];
    let response = await  UploadFiles(imageFile, "member_profile",store.dispatch) 
    if (response.code === 1) {
      setUserImage(response.file_path);
      setImage(true);
    }
  };

  const handleRemoveImage = () => {
    swal({
      text: "Are you sure you want remove this image?",
      icon: "warning",
      title:"Warning!",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setUserImage(null);
        setImage(true);
      }
    });
  };

  const AssignNumberHandler = (event, value) => { 
    if (event.target.checked) {
      setAssignNumber([...assign_number, value]) 
    } else {
      let array = [...assign_number];
      let index = array.indexOf(value);
      if (index !== -1) {
        array.splice(index, 1);
        setAssignNumber(array)
      }
    }  
  };   
  
  return (
    <>
      <div
        className={`manage-team-member-slide account ${
          addTeamMember ? "" : "manage-team-member-slide-close"
        }`}
      >
        {addTeamMember && (
          <>
            <div className="member-slide-title">
              <h5>
                {viewOnly
                  ? "Team Member"
                  : extensionDetails && Object.keys(extensionDetails).length
                  ? "Update Team Member"
                  : "Add Team Member"}
              </h5>
              <img
                src="./../assets/images/cross-add-modal.svg"
                alt="1"
                onClick={() => closeTeamMemberSlider()}
              />
            </div>
            <div className="add-contact-slide">
              <div className="nxa-contact-upload-img">
                <label className="label-upload-img" htmlFor="upload">
                  <img
                    src={
                           userImage
                        ? userImage
                        : "./../assets/images/upload-add-contact.svg"
                    }
                    alt="1"
                  />
                </label>
                <input
                  type="file"
                  hidden
                  id="member_image"
                  name="user_image"
                  ref={(input) => (uploadRef.current = input)}
                  onChange={(e) => uploadImage(e)}
                  accept="image/jpeg, image/png"
                />
                {!viewOnly && (
                  <label
                    className="label-upload-text"
                    htmlFor="upload"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      (userImage === "") | !userImage
                        ? uploadRef.current.click()
                        : handleRemoveImage()
                    }
                  >
                    {(userImage === "") | !userImage
                      ? "Upload Image"
                      : "Remove Image"}
                  </label>
                )}
              </div>
              <div className="form-group form-group-role">
                <label>Full Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {validator.current.message(
                  "Name",
                  name,
                  "required|string|max:20,string"
                )}
              </div>
              <div className="form-group form-group-role">
                <label>Mobile Number</label>
                <PhoneInput
                  name="phone"
                  placeholder="Phone Number"
                  value={number}
                  onChange={(number) => numberChange(number)}
                  defaultCountry="ZA"
                />
                {validator.current.message("Phone", callNumber, "required")}
              </div>
              <div className="form-group form-group-role">
                <label>Work Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {validator.current.message("Work Email", email, "required")}
              <div className="form-group form-group-role">
                <label>Team Member Role</label>
                <select
                  className="form-control"
                  name="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="">Select Option</option>
                  <option value="member"> Team Member </option>
                  <option value="admin">Admin</option>
                </select>
              </div>
              <div className="form-group form-group-role number-setting-sub-header multi-select-number-common-drop">
                <label>Assign Number</label>  
                <span className="num-setting-assign">
                  <div className="dropdown drop-assign" >
                    <a 
                      className="dropdown-toggle assing-drop-control collapsed" 
                      data-toggle="collapse" 
                      data-target="#collapseExample3" 
                      aria-expanded="false" 
                      aria-controls="collapseExample3"
                    >
                      {`${assign_number?.length > 0 ? `${assign_number?.length} Assigned Number` : "Select Assign Number" }`} 
                    </a>
                    <div className="dropdown-menu team-member-collapse collapse" id="collapseExample3" >
                      {did_number_list && did_number_list.map((value,index)=>{
                        return(
                          <a className="dropdown-item" href="#" key={index}>
                            <span>
                              {value.did_number}
                            </span>
                            <span>
                              <input 
                                className="form-check-input" 
                                type="checkbox" 
                                name="did_number" 
                                id={value.did_number}
                                value={value.did_number}
                                onChange={(event) => AssignNumberHandler( event, value.did_number)}
                                checked={assign_number.includes(value.did_number)}
                              />
                              <label className="form-check-label" htmlFor={value.did_number}></label>
                            </span>
                          </a>
                        )
                      })}
                    </div>
                  </div>
                </span>
                {validator.current.message("Assign number", assign_number, "required")}
              </div>
              <div className="form-group form-group-role">
                <label>Address</label>
                <input
                  className="form-control"
                  type="text"
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div> 
              <div className="form-group form-group-role">
                <div className=" number-flags local-national-drop-side">
                  <div className="dropdown custom-select-dropdown">
                    <button
                      className="btn btn-transprent dropdown-toggle country-border"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {country ? en[country] : "Select Country"}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {countryList &&
                        countryList.map((value, index) => {
                          return (
                            <Link
                              className="dropdown-item"
                              key={index}
                              to="#"
                              onClick={() => setCountry(value.country_code)}
                            >
                              <span className="flag-img-span">
                                <img
                                  src={
                                    value.country_image
                                      ? `./../assets/images/${value.country_image}`
                                      : ""
                                  }
                                  alt="country"
                                />
                                {value.name}
                              </span>
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!viewOnly && (
              <Link
                to="#"
                className="remove-from-nxa"
                onClick={addUpdateNxaMember}
              >{`${extensionDetails && Object.keys(extensionDetails).length ? "Update" : "Save"} Team Member`}</Link>
            )}
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({ 
  did_number_list: state?.report?.did_number_list ?? [],
});

export default connect(mapStateToProps)(withRouter(TeamMemberSlider));
