import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import authService from "../../../Utils/authService";

function CallListing(props) {
  const {
    categorizedReferences,
    categories,
    AnswerCall,
    SetActiveCall,
    HangUpCall, 
    uuid,
    inactiveCallStatus,
  } = props;
 

  const conference_sessions = useSelector(
    (state) => state?.call?.conference_sessions??{}
  ); 
  
  const userDetail = authService.getUserDetail();

  const { isMerged: mergedCall = false } = conference_sessions[uuid] || {};

  const multiCallActionButtons = (type, callLine) => {
    const { uuid } = callLine;
    if (type === "ringing") {
      return (
        <>
          <li>
            <a class="dropdown-item" href={void 0}>
              <img src="./../assets/images/icons/forward.svg" alt="1" />
              Forward
            </a>
          </li>
          <li>
            <a class="dropdown-item" href={void 0}>
              <img src="./../assets/images/icons/message.svg" alt="1" />
              Voicemail
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              href={void 0}
              onClick={() => HangUpCall(uuid)}
            >
              <img src="./../assets/images/icons/ignore.svg" alt="ig" />
              Ignore
            </a>
          </li>
        </>
      );
    } else if (type === "hold") {
      return (
        <>
          <li>
            <a class="dropdown-item" href={void 0}>
              <img src="./../assets/images/icons/merge.svg" alt="1" />
              Merge with current call
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              href={void 0}
              onClick={() => HangUpCall(uuid)}
            >
              <img src="./../assets/images/icons/active-call-cut.svg" alt="1" />
              End Call
            </a>
          </li>
        </>
      );
    } else {
      return (
        <li>
          <a
            class="dropdown-item"
            href={void 0}
            onClick={() => HangUpCall(uuid)}
          >
            <img src="./../assets/images/icons/active-call-cut.svg" alt="1" />
            End Call
          </a>
        </li>
      );
    }
  };

  return (
    <div className="active-incoming-hold-call-listing">
      {mergedCall &&
      Object.keys(inactiveCallStatus)?.length === 0 &&
      conference_sessions[uuid] ? (
        <MergedCallSessionsListing
          conference_sessions={conference_sessions}
          HangUpCall={HangUpCall}
          userDetail={userDetail}
          uuid={uuid}
        />
      ) : (
        <div class="dialer-recent-list">
          {["connected", "ringing", "hold"].map((value, key) => {
            const icon =
              value === "ringing"
                ? "incoming"
                : value === "hold"
                ? "carbon_pause-filled"
                : "active-call-cut";
            if (!categorizedReferences[value]) {
              return null;
            }
            return (
              <ul class="dialer-recent-list-ul" key={key}>
                <p className="active-heading ">{categories[value]}</p>
                {categorizedReferences[value]?.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className="name-listing-row">
                        <div className="d-flex align-items-center">
                          <div class="contact-img">
                            <h3>
                              {item?.name
                                ?.match(/\b(\w)/g)
                                ?.join("")
                                ?.toUpperCase()}
                            </h3>
                          </div>
                          <div class="contact-name-div">
                            <h4 className="active-cl-name">{item.name}</h4>
                            <p className="active-cl-no">{item.number}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div class="call-type-ico">
                            <img
                              src={`./../assets/images/icons/${icon}.svg`}
                              alt="1"
                              onClick={() =>
                                value === "ringing"
                                  ? AnswerCall(item.uuid)
                                  : value === "hold"
                                  ? SetActiveCall(item.uuid)
                                  : HangUpCall(item.uuid)
                              }
                            />
                          </div>

                          <div class=" number-collase-action">
                            <a
                              href={`#i_${item.uuid}`}
                              data-toggle="collapse"
                              aria-expanded="false"
                              class="dropdown-toggle collapsed"
                            >
                              <i class="fas fa-ellipsis-v"></i>
                            </a>
                            <ul
                              class="list-unstyled number-dr-collapse collapse"
                              id={`i_${item.uuid}`}
                            >
                              {multiCallActionButtons(value, item)}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default CallListing;

function MergedCallSessionsListing({
  conference_sessions,
  HangUpCall,
  userDetail,
  uuid,
}) {
  return (
    <div class="dialer-recent-list">
      <ul class="dialer-recent-list-ul">
        <p className="active-heading">Active Calls</p>
        <li>
          <div className="name-listing-row">
            <div className="d-flex align-items-center">
              <div class="contact-img">
                <h3>
                  {userDetail?.firstname
                    ?.match(/\b(\w)/g)
                    ?.join("")
                    ?.toUpperCase()}
                </h3>
              </div>
              <div class="contact-name-div">
                <h4 className="active-cl-name">{userDetail.firstname}</h4>
                <p className="active-cl-no">{userDetail.user_extension}</p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div class="call-type-ico">
                <img
                  src={`./../assets/images/icons/active-call-cut.svg`}
                  alt="1"
                  onClick={() => HangUpCall(uuid)}
                />
              </div>
            </div>
          </div>
        </li>
        {Object.values(conference_sessions[uuid]).map((item, index) => {
          if (typeof item !== "object") {
            return null;
          }
          return (
            <li key={index}>
              <div className="name-listing-row">
                <div className="d-flex align-items-center">
                  <div class="contact-img">
                    <h3>
                      {item?.name
                        ?.match(/\b(\w)/g)
                        ?.join("")
                        ?.toUpperCase()}
                    </h3>
                  </div>
                  <div class="contact-name-div">
                    <h4 className="active-cl-name">{item.name}</h4>
                    <p className="active-cl-no">{item.number}</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div class="call-type-ico">
                    <img
                      src={`./../assets/images/icons/active-call-cut.svg`}
                      alt="1"
                      onClick={() => HangUpCall(uuid)}
                    />
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
