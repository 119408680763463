import React, { useState } from "react";
import swal from "sweetalert";
import { store } from "../../../../Services";
import { BuyNxaPlan } from "../../../../Services/Actions/signupActions";
import Modal from "../../../../Component/Modal";
import { REACT_APP_VAT } from "../../../../Utils/Common";

function SubscriptionModal(props) {
  const {
    open,
    handleClose,
    openContactModal,
    user_details: {
      user_plan: {
        planID,
        users,
        annual_plan_discount,
        plan_duration,
        two_year_plan_discount,
        plan_cost,
        currency,
        trial,
      },
    },
  } = props;
  const [billingType, setBillingType] = useState(plan_duration);  
  let selected_currency = currency === "ZAR" ? "R" : "$"
  const planCost =
  selected_currency === "$"
      ? plan_cost && JSON.parse(plan_cost).$
      : selected_currency === "R"
      ? plan_cost && JSON.parse(plan_cost).R
      : "";
  const MarginalCost =
    billingType === 12
      ? planCost * 12 * users
      : billingType === 24
      ? planCost * 24 * users
      : planCost * users;
  const PlanDiscount =
    billingType === 12
      ? MarginalCost - MarginalCost * annual_plan_discount
      : billingType === 24
      ? MarginalCost - MarginalCost * two_year_plan_discount
      : MarginalCost; // DISCOUNT SCENARIO
  let newPlanCost = PlanDiscount + PlanDiscount * REACT_APP_VAT; // ADDING VAT SCENARIO
  newPlanCost = newPlanCost.toFixed ? newPlanCost.toFixed(2) : newPlanCost; // DECIMAL SCENARIO
  let vatCost = (PlanDiscount * REACT_APP_VAT).toFixed(2);
  const paidOff =
    billingType === 12
      ? (MarginalCost * annual_plan_discount).toFixed(2)
      : billingType === 24
      ? (MarginalCost * two_year_plan_discount).toFixed(2)
      : ""; // DISCOUNT SCENARIO

  const updateSubscriptionHandler = async () => {
    let sendData = {
      planID,
      duration: billingType,
      plan_users: users,
      currency: selected_currency,
      trial: trial,
    };
    let res = await BuyNxaPlan(sendData, store.dispatch);
    if (res?.code === 1) {
      handleClose();
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p>Card has been successfully added to the card list and user can make transactions with this asap</ p>`;
      swal({
        icon: "./../assets/images/subscription-successfull.svg",
        title: `Subscription Successfully Changed`,
        buttons: "Proceed to Account",
        content: wrapper,
        className: "add-subscription",
      }).then(async (action) => {
        if (action) {
          console.log("true");
        }
      });
    }
  };

  return (
    <>
      <Modal
        open={open}
        CloseModal={handleClose}
        title={"Manage your current Subscription"}
        showActiveClass={open}
        size={`lg`}
      >
        <div className="add-member-modal-body  subscription-modal-body">
          <div className="col-md-12 p-0">
            <div className="subscription-type-radio">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio1"
                  name="billingType"
                  value={1}
                  checked={billingType === 1 ? "checked" : ""}
                  onChange={() => setBillingType(1)}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Billed monthly
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio2"
                  name="billingType"
                  value={12}
                  checked={billingType === 12 ? "checked" : ""}
                  onChange={() => setBillingType(12)}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  {`Billed annually (${parseInt(
                    annual_plan_discount * 100
                  )}% Savings)`}
                </label>
              </div>
            </div>
            <div className="subscription-summary-div">
              <ul>
                <li>
                  <span className="pac-name">
                    {`${selected_currency}${planCost} x ${users} Team Members x ${
                      billingType === 1
                        ? "1 month"
                        : billingType === 12
                        ? "1 year"
                        : ""
                    }`}
                  </span>
                  <span className="pac-price">
                    {`${selected_currency}${MarginalCost.toFixed(2)} ${currency}`}
                  </span>
                </li>
                <li>
                  <span className="pac-name">VAT</span>
                  <span className="pac-price">
                    {`${selected_currency}${vatCost} ${currency}`}
                  </span>
                </li>
                {billingType === 12 && (
                  <>
                    <li>
                      <span className="pac-name">{`${parseInt(
                        annual_plan_discount * 100
                      )}% off paid annually`}</span>
                      <span className="pac-price">
                        {`${selected_currency}${paidOff} ${currency}`}
                      </span>
                    </li>
                    <li>
                      <span className="pac-name">Prorated adjustment</span>
                      <span className="pac-price">R00.00 ZAR</span>
                    </li>
                  </>
                )}
                <li>
                  <span className="pac-name">Total due today</span>
                  <span className="pac-price">
                    {`${selected_currency}${newPlanCost} ${currency}`}
                  </span>
                </li>
              </ul>
            </div>
            <p className="manage-subscription-discription">
              {` Each ${
                plan_duration === 1
                  ? "month"
                  : plan_duration === 12
                  ? "year"
                  : ""
              } 
                            you will be charged at a rate of ${selected_currency}${planCost}/mo per Team 
                            Member account until you cancel your subscription. You can add 
                            new team members to your Account at any time,and you will be 
                            charged for those Team mebers on a pro-rated basis on your next
                            bill.`}{" "}
              <a href="#" className="billing-faq-lin">
                Billing FAQ
              </a>
            </p>
            <h4 className="cancel-subscription">Cancel Subscription</h4>
            <h5 className="not-right">
              Something not right? Want to keep using Nxatel but something is
              not working out for you? Contact us.
              <a
                href={void 0}
                style={{ cursor: "pointer", color: "rgb(0, 82, 204)" }}
                onClick={() => openContactModal("contact")}
              >
                Contact Us
              </a>
            </h5>
          </div>
        </div>
        <div className="modal-footer add-modal-modal-footer">
          <button
            type="button"
            className="btn add-member-cancel-btn"
            onClick={handleClose}
          >
            I’m sure i want to cancel
          </button>
          <button
            type="button"
            className="btn add-member-cancel-btn"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-meet-join"
            onClick={updateSubscriptionHandler}
          >
            Update Subscription
          </button>
        </div>
      </Modal>
    </>
  );
}

export default SubscriptionModal;
