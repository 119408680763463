import {
  accountBaseUrl,
  apiAdminUrl,
  apiBaseUrl,
  apiMediaUrl,
} from "../../Utils/Common";
import * as constants from "../Constants/settingConstants";
import * as ACTIONS from "./../Constants/apiConstants.js";
import swal from "sweetalert";
import authService from "../../Utils/authService";
import { FetchUserDetails, handleResponse } from "./userActions";
import { SHOW_LOADER } from "../Constants/userConstants";
import { TagListing, TeamListing } from "./callActions";
import { DidNumberListing } from "./reportActions";

// ***************************************** ACCOUNT ACTIONS *************************************************************

export const UpdateProfileDetails = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/api-dashboard-details`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        FetchUserDetails(dispatch);
        swal({
          title: "Success!",
          text: data.ResponseText,
          icon: "success",
        });
        return 1;
      } else {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

//********************************************************* EXTENSION ACTIONS ***********************************************

export const ExtensionListing = (id, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}userPanel/extension/${id ? id : 0}`,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === 1) { 
        const { data: extensionList } = data; 
        extensionList.forEach(async (element, index) => {
          const { app_picture } = element;
          if(app_picture){
            const url = await getMediaDownloadUrl(app_picture);
            element.profile_picture = url;
            extensionList[index] = element;
          }else{
            element.profile_picture = "";
            extensionList[index] = element;
          }  
          if(id){
            dispatch({
              type: constants.FTECH_INDIVIDUAL_EXTENSION_DETAIL,
              payload: [...extensionList],
            });
          }else{
            dispatch({
              type: constants.FTECH_EXTENSION_LISTING,
              payload: [...extensionList],
            });
          }   
        }); 
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const DeleteExtension = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/extension/${params}`, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        ExtensionListing(0, dispatch);
        FetchUserDetails(dispatch);
        swal({
          title: "Success!",
          text: "Member Deleted Successfully.",
          icon: "success",
        });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const changeExtensionPassword = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}userPanel/change-extension-password`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const MemberRole = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/change-role`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

// ************************************************** CARD PAYMENT ACTIONS ****************************************************

export const CardListing = (dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${accountBaseUrl}${ACTIONS.USERPANEL}${ACTIONS.CARD_LISTING}`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_CARD_LISTING,
          payload: data.data,
        });
        return 1;
      } else if (data.ResponseCode === "0") {
        dispatch({
          type: constants.FETCH_CARD_LISTING,
          payload: [],
        });
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};

export const RecentTransactionListing = (dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${accountBaseUrl}${ACTIONS.USERPANEL}${ACTIONS.PAYMENT_LISTING}`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_PAYMENT_LISTING,
          payload: data.data,
        });
        return 1;
      } else if (data.ResponseCode === "0") {
        dispatch({
          type: constants.FETCH_PAYMENT_LISTING,
          payload: [],
        });
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};


export const PlanRenewalPayment = (params,dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${accountBaseUrl}${ACTIONS.USERPANEL}${ACTIONS.PLAN_RENEWAL_PAYMENT}`,
    {
      method: "POST",
      body:JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") { 
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        return 1;
      } else if (data.ResponseCode === "0") { 
        RecentTransactionListing(dispatch)
        swal({
          title: "Payment Unsuccessfull!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};


export const DeleteRegisteredCard = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${accountBaseUrl}${ACTIONS.USERPANEL}${ACTIONS.DELETE_REGISTERED_CARD}`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        CardListing(dispatch);
        swal({
          title: "Success!",
          text: data.ResponseText,
          icon: "success",
        });
        return 1;
      } else {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

//  ************************************************ PLAN ACTIONS **********************************************************

export const CancelPlanHandler = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  return (dispatch) => {
    dispatch({ type: SHOW_LOADER, payload: true });
    const request = new Request(
      `${apiBaseUrl}${ACTIONS.USERPANEL}${ACTIONS.CANCEL_PLAN}`,
      {
        method: "POST",
        body: JSON.stringify(params),
        headers: new Headers({
          "Content-Type": "application/json",
          "X-Auth-Token": token,
        }),
      }
    );
    return fetch(request)
      .then(handleResponse)
      .then((data) => {
        dispatch({ type: SHOW_LOADER, payload: false });
        if (data.ResponseCode === "1") {
          return 1;
        } else {
          swal({
            title: "Error!",
            text: data.ResponseText,
            icon: "error",
          });
          return 0;
        }
      })
      .catch((error) => {
        dispatch({ type: SHOW_LOADER, payload: false });
      });
  };
};

export const DowngradePlanHandler = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${accountBaseUrl}${ACTIONS.USERPANEL}${ACTIONS.CHANGE_PLAN}`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return { code: 1, response: data.ResponseText };
      } else {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return { code: 0 };
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const UpgradePlanHandler = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${accountBaseUrl}${ACTIONS.USERPANEL}${ACTIONS.UPGRADE_PLAN}`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return { code: 1, response: data.ResponseText };
      } else {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return { code: 0 };
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

// ******************************************** PHONE SYSTEM ACTIONS ****************************************************

export const RuleListing = (dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}${ACTIONS.USERPANEL}show-rules`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      dispatch({
        type: constants.FETCH_RULE_LISTING,
        payload: data.data,
      });
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const CallFlowListing = (id, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/call-flow-listing`, {
    method: "POST",
    body: JSON.stringify({ id: id }),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_CALL_FLOW_LISTING,
          payload: data.data,
        });
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const IVRMenuList = (id, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/ivr-menu-list`, {
    method: "POST",
    body: JSON.stringify({ id }),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === 1) {
        dispatch({
          type: constants.FETCH_IVR_MENU_LISTING,
          payload: data.data,
        });
        return 1;
      } else {
        dispatch({
          type: constants.FETCH_IVR_MENU_LISTING,
          payload: [],
        });
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const TimezoneList = (dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}timezone_listing`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      dispatch({
        type: constants.TIMEZONE_LIST,
        payload: data.data,
      });
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const DeleteBusinessHour = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}/userPanel/delete-businesshour/${params}`,
    {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        BusinessHourListing(0, dispatch);
        swal({
          title: "Success!",
          text: "Business Hour Deleted successfully.",
          icon: "success",
        });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const CreateUpdateBusinessHour = (params, isEdit, dispatch) => {
  let data = isEdit ? "update-business-hour" : "add-business-hour";
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}/userPanel/${data}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        let message = isEdit
          ? "Business Hour Updated Successfully"
          : "Business Hour Created Successfully";
        swal({ title: "Success!", text: message, icon: "success" });
        BusinessHourListing(0, dispatch);
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const BusinessHourListing = (id, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}userPanel/business-hours/${id ? id : 0}`,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_BUSINESS_HOUR_LISTING,
          payload: data.data,
        });
        return 1;
      } else {
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const DeleteNumberForwardCreate = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/NumberForwardDelete `, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        DidNumberListing(dispatch);
        let message =
          params?.type === "ivr"
            ? "IVR Greeting deleted successfully"
            : params?.type === "call-flow"
            ? "Call Workflow deleted successfully"
            : "";
        swal({ title: "Success!", text: message, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const NumberForwardCreateUpdate = (
  params,
  isEdit = false,
  type,
  dispatch
) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/NumberForwardCreate`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        let message =
          type === "ivr" && !isEdit
            ? "IVR Greeting added successfully"
            : type === "ivr" && isEdit
            ? "IVR Greeting updated successfully"
            : type === "call-flow" && !isEdit
            ? "Call Workflow added successfully"
            : type === "call-flow" && isEdit
            ? "Call Workflow updated successfully"
            : type === "number_forward"
            ? "Number forwarded successfully"
            : "";
        swal({ title: "Success!", text: message, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const IVRGreetingList = (dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/ivr-list`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_IVR_GREETING_LISTING,
          payload: data.data,
        });
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const getMediaDownloadUrl = (filename, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch?.({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiMediaUrl}userPanel/image_signed_url?objectName=${filename}`,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch?.({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return data?.url;
      } else {
        return "";
      }
    })
    .catch((error) => {
      dispatch?.({ type: SHOW_LOADER, payload: false });
      return "";
    });
};

export const DeleteVoiceFile = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/delete-ivr `, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const uploadIVR = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/ivr-upload`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        IVRGreetingList(dispatch);
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const DeleteTag = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/delete-nxa-tag`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        TagListing(dispatch);
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const DeleteTeam = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/team/${params}`, {
    method: "DELETE",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        TeamListing(0, dispatch);
        swal({
          title: "Success!",
          text: "Team Deleted Successfully.",
          icon: "success",
        });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const FetchAssignNumber = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/assign-callerID`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        DidNumberListing(dispatch, "phoneSettings");
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const CallRecordingSettings = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/update-setting`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const DeleteDidNumber = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/delete-did`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return data;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const RemoveFromForwarding = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/remove-forwarding`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return data;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};
