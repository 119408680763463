import { apiAdminUrl, apiBaseUrl } from "../../Utils/Common";
import * as constants from "../Constants/dashboardConstants"; 
import authService from "../../Utils/authService";
import { handleResponse } from "./userActions";
import { SHOW_LOADER } from "../Constants/userConstants";
 


// ********************************************* DASHBOARD ACTIONS **************************************************

export const DashboardDetails = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/api-dashboard-details`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_DASHBOARD_DETAILS,
          payload: data,
        });
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const OnBoardProcessStatics = (dispatch) => {
  const token = authService.getUserAccessToken();
  const request = new Request(`${apiAdminUrl}userPanel/welcome-screen-api`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_ONBOARD_PROCESS_STATICS,
          payload: data.data,
        });
      }
      return 1;
    })
    .catch((error) => {});
};
