import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider"; 
import './slider.css';

function Sliders(props) {
    const {max,min,onSliderChange,value} = props;

    const PrettoSlider = withStyles({
        root: {
            color: "#EA7315",
            height: 8,
        },
        thumb: {
            height: 24,
            width: 24,
            backgroundColor: "#fff",
            border: "2px solid currentColor",
            marginTop: -8,
            marginLeft: -12,
            "&:focus, &:hover, &$active": {
                boxShadow: "inherit",
            },
        },
        active: {},
        valueLabel: {
            left: "calc(-50% + 4px)",
        },
        track: {
            height: 8,
            borderRadius: 4,
        },
        rail: {
            height: 8,
            borderRadius: 4,
        },
    })(Slider);

    return (
        <>
            
            <PrettoSlider
                valueLabelDisplay="on"
                aria-label="pretto slider"
                defaultValue={min}
                max={max}
                min={min}
                value={value} 
                onChange={onSliderChange}
                marks={[{value: min,label: min},{value: max,label: max}]}
            />
        </>
    );
}

export default Sliders;
