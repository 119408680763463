import React, { useState, useRef, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import {
  CreateUpdateTeam, 
} from "../../../Services/Actions/callActions";
import { connect } from "react-redux";
import { store } from "../../../Services"; 
function TeamSlider({
  extension_listing,
  addTeam,
  closeTeamSlider,
  team_details, 
  viewOnly,
}) {
  const [name, setName] = useState("");
  const [teamMember, setTeamMember] = useState("");
  const [teamID, setTeamID] = useState("");
  const [collapsedSlider,setCollapsedSlider] = useState(false);
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  // useEffect(async () => {
  //   ExtensionListing(0, store.dispatch);
  // }, []);

  useEffect(() => {
    if (team_details && Object.keys(team_details).length) {
      let selectedAgents = []; 
      const {participants = []} = team_details
      participants && participants.map(value => {
        return selectedAgents?.push(value.user_extension) 
      });
      setName(team_details?.name);
      setTeamID(team_details?.id);
      setTeamMember(selectedAgents);
      setCollapsedSlider(true)
    } else if (team_details && !Object.keys(team_details).length) {
      setName("");
      setTeamID("");
      setTeamMember("");
      setCollapsedSlider(false)
    }
  }, [team_details]);
 
  const addUpdateNxaTeam = async () => {
    let selectedAgents = [];
    if (validator.current.allValid()) {
      teamMember && teamMember.map(user_extension => {
        let customerID = extension_listing?.find(value=>value.user_extension === user_extension)?.customerID
        selectedAgents.push({
          extension_num :user_extension,
          destination: customerID,
          timeout: 0,
          destination_prompt: 0,
        });
      });
      if (teamID) {
        const response = await CreateUpdateTeam(
          { name, selectedAgents, request_from: "web", editId: teamID},
          teamID,
          store.dispatch
        ); 
        if (response === 1) {
          closeTeamSlider(); 
        }
      } else {
        const response = await CreateUpdateTeam(
          { name, selectedAgents, request_from: "web" },
          teamID,
          store.dispatch
        );

        if (response === 1) {
          closeTeamSlider();
        }
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  }; 

  
  const handleChange = (event, value) => { 
    const {user_extension} = value
    if (event.target.checked) {
      setTeamMember([...teamMember, user_extension]) 
    } else {
      let array = [...teamMember];
      let index = array.indexOf(user_extension);
      if (index !== -1) {
        array.splice(index, 1);
        setTeamMember(array)  
      }
    }  
  }; 
  
 

  return (
    <div
      className={`manage-team-member-slide account ${
        addTeam ? "" : "manage-team-member-slide-close"
      }`}
    >
      {addTeam && (
        <>
          <div className="member-slide-title">
            <h5>{viewOnly ? "Team" : teamID ? "Update Team" : "Add Team"}</h5>
            <img
              src="./../assets/images/cross-add-modal.svg"
              alt="1"
              onClick={() => closeTeamSlider()}
            />
          </div>
          <div className="add-contact-slide">
            <div className="form-group form-group-role">
              <label>Team Name</label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {validator.current.message("Name", name, "required")}
            </div>
            <div className="form-group form-group-role number-setting-sub-header multi-select-number-common-drop">
              <label>Select Team Members</label>
              <span className="num-setting-assign">
                <div className="dropdown drop-assign"> 
                  <a className=" dropdown-toggle assing-drop-control collapsed" data-toggle="collapse" data-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample3">   
                    {`${teamMember?.length > 0 ? teamMember?.length : "Select" }`} Team Member
                  </a>
                  <div
                    className={`collapse dropdown-menu team-member-collapse ${collapsedSlider ? "show" : ""}`}
                    id="collapseExample3"
                  >
                    {extension_listing && extension_listing.map((value, index) => {
                        return (
                          <a
                            className="dropdown-item"
                            href="#"
                            key={index} 
                          >
                            <span>
                              <img
                                src={`${
                                  value.profile_picture
                                    ? value.profile_picture
                                    : "./../assets/images/Mask.png"
                                }`}
                                alt="1"
                              />
                              {`${value.name}`}
                              <i className="italic-font-color">{`(${value.user_extension})`}</i>
                            </span> 
                            <span>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="selectUser"
                                value={value.user_extension}
                                id={value.user_extension}
                                onChange={(event) =>handleChange(event ,value)}
                                checked={teamMember.includes(value.user_extension)}  
                              />
                              <label className="form-check-label" htmlFor={value.user_extension} ></label>
                            </span>
                          </a>
                        );
                      })}  
                  </div>
                </div>
              </span> 
              {validator.current.message("Team member", teamMember, "required")}
            </div>
          </div>
          {!viewOnly && (
            <a className="remove-from-nxa" onClick={addUpdateNxaTeam}>
              {teamID ? "Update Team" : "Save Team"}
            </a>
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  extension_listing:state?.setting?.extension_listing?? [],
});

export default connect(mapStateToProps)(TeamSlider);
