import React, { useState } from "react";

function ChangePlanDetail(props) {
  const {
    plans_list,
    OnSelectPlanHanlder,
    planID,
    user_details,
    onCancelPlan,
    openSubscriptionPage,
  } = props; 
  const plan_name = user_details?.user_plan?.name;
  const current_planID = user_details?.user_plan?.planID;
  const plan_request_id = user_details?.plan_request?.planID ?? "";
  let selected_currency = user_details?.user_plan?.currency;
      selected_currency = selected_currency === "USD" ? "$" : "R"

  return (
    <div className="pricing pricing-bx pricing-palden pricing-palden-signup">
      {plans_list instanceof Array &&
        plans_list.map((value, key) => {
          let planCost = JSON.parse(value.plan_cost);
          let planType =
            value.planID === current_planID
              ? "Update Member"
              : value.planID > current_planID
              ? "Upgrade Plan"
              : "Downgrade Plan";
          if (value.name.includes("Enterprise")) {
            return (
              <div
                className={` pricing-item ${
                  value.planID === planID ? "pricing__item--featured" : ""
                } ${
                  value.planID === plan_request_id
                    ? "plan_requested_pricing"
                    : ""
                }`}
                onClick={() => OnSelectPlanHanlder(value)}
              >
                <div className="pricing-min-ht">
                  <div className="pricing-deco">
                    <div className="pricing-price">
                      <h6 className="fw-700 start-up-head color-66  mb-0">
                        {value.name}
                      </h6>
                    </div>
                    <p className="font-16 fw-400 color-66  mb-1">
                      All our enterprise-ready features.
                    </p>
                    <p className="ideal-text">
                      Ideal for Medium and Large teams
                    </p>
                    <h5 className="team-size ">
                      Team Size: {`${value.min_user}-${value.max_user}`}
                    </h5>
                    <h6 className="every-text">Everything in Professional, PLUS</h6>
                  </div>
                  <ul className="pricing-feature-list">
                    <li className="pricing-feature custom-pricing-feature">
                      <h6 className="font-20 color-66 fw-700 mb-0">
                        Custom pricing
                      </h6>
                      <p className="font-16 mt-2 color-66  mb-4">
                        Trial Available
                      </p>
                    </li>
                  </ul>
                </div>
                <div
                  className={`d-flex justify-content-center ${
                    value.planID === planID ? "mt-53" : "mt-3"
                  }`}
                >
                  <p className="upgrade-downgrade-btn m-0">Speak to Sales</p>
                </div>
              </div>
            );
          }
          return (
            <div
              key={`plans_${key}`}
              className={` pricing-item ${
                value.planID === planID ? "pricing__item--featured" : ""
              } ${
                value.planID === plan_request_id ? "plan_requested_pricing" : ""
              }`}
              onClick={() => OnSelectPlanHanlder(value)}
            >
              <div className="pricing-min-ht">
                <div className="pricing-deco">
                  <div className="pricing-price">
                    {value.planID === current_planID && (
                      <span className="recomended-text">Current Plan</span>
                    )}
                    {value.planID === plan_request_id && (
                      <span className="recomended-text">Requested Plan</span>
                    )}
                    <h6 className="fw-700 start-up-head color-66 mt-3 mb-0">
                      {value.name}
                    </h6>
                  </div>
                  <div className="price-div-2">
                    <p className=" pay-as-you">
                      {`${
                        value.name.includes("Start Up") ? "(Pay as you Go)" : ""
                      }`}
                    </p>
                    <span className="pricing-currency color-3b">
                      {selected_currency}
                    </span>
                    <span className="color-b3 font-42 fw-400 cost-span">
                      {selected_currency === "$"
                        ? planCost.$
                        : selected_currency === "R"
                        ? planCost.R
                        : "00"}
                    </span>
                  </div>
                  <h3 className="pricing-title">per user/per mo</h3>.
                  <h5 className="team-size ">
                    Team Size: {`${value.min_user}-${value.max_user}`}
                  </h5>
                  <p className="ideal-text">
                    {value.name === "Start Up"
                      ? "Ideal for Start-up’s or Small teams"
                      : value.name === "Scale Up"
                      ? "Ideal Small teams"
                      : value.name === "Professional"
                      ? "Ideal for Small  or Medium teams"
                      : ""}
                  </p>
                  <h6 className="every-text">
                    {value.name === "Start Up"
                      ? "Ideal for Start-up’s or Small teams"
                      : value.name === "Scale Up"
                      ? "Everything in Start-Up, PLUS"
                      : value.name === "Professional"
                      ? "Everything in Scale-Up, PLUS"
                      : ""}
                  </h6>
                </div>
                <ul className="pricing-feature-list pricing-feature-list-new">
                  <li>
                    <i className="fas fa-check"></i>
                    {`${
                      value.name.includes("Professional")
                        ? "Business Landline or toll free numbers"
                        : "Business Landline or National numbers"
                    }`}
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    {` 
                        ${
                            value.voice_minute
                        } Call Anywhere minutes
                        ${
                            value.name !== "Professional"
                            ? "(Local Country)"
                            : ""
                        }
                    `}
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    {`Host up to ${value.max_user} participants`}
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    {`${
                      value.name.includes("Start Up")
                        ? `Group meetings for up to ${value.video_calling_minutes} minutes`
                        : `Video Calling ${value.video_calling_minutes} minutes`
                    }`}
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    {`${
                      value.name.includes("Start Up")
                        ? "Unlimited Team Calling, Video Meeting & Messaging"
                        : "Unlimited Team Calling & Messaging"
                    }`}
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    Business SMS *
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    {`Cloud Storage ${value.storage}`}
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    Mobile & Web App
                  </li>
                </ul>
              </div>
              <div
                className={`d-flex justify-content-around ${
                  value.planID === planID ? "mt-3" : ""
                }`}
              >
                {plan_request_id !== value.planID && (
                  <p
                    className="upgrade-downgrade-btn"
                    onClick={() =>
                      value.planID === planID
                        ? openSubscriptionPage(planType)
                        : OnSelectPlanHanlder(value)
                    }
                  >
                    {planType}
                  </p>
                )}

                {!value.name.includes(plan_name) &&
                  plan_request_id === value.planID && (
                    <p
                      className="upgrade-downgrade-btn"
                      onClick={() =>
                        value.planID === planID
                          ? onCancelPlan(value)
                          : OnSelectPlanHanlder(value)
                      }
                    >
                      Cancel Plan
                    </p>
                  )}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ChangePlanDetail;
