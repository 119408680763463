import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useSelector } from "react-redux";
import Chat from "../../Utils/Chat";
import {
  SelectedChatType,
  SelectedGroupChatUser,
} from "../../Services/Actions/callActions";
import { store } from "../../Services";
import Select from "react-select"; 
import { uuidv4 } from "../../Utils/Common";

function TeamChatPop() { 
  const extension_list = useSelector(
    (state) => state?.setting?.extension_listing??[]
  );
  const {
    firstname,
    lastname,
    user_extension,
    phone,
    profile_picture: profile,
    uniquecode,
  } = useSelector(
    (state) => state?.user?.user_details?.userDetail ?? {}
  );
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [clicked, setClicked] = useState(false);
  let [participant, setParticipant] = useState([]);
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate } })
  );
  const startChat = () => {
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setClicked(true);
    let __team_uuid = `TM-${uuidv4()}-CTM`;
    let __team_exists = true;
    (async () => {
      do {
        let __response = await Chat.fetch_if_ref_exists(
          `/Groups/${__team_uuid}`
        );
        __team_exists = __response;
        if (__team_exists === false) {
          let participants = [
            { firstname, lastname, user_extension, phone, profile, uniquecode },
          ];
          extension_list.map((value) => {
            if (participant.includes(value.uniquecode)) {
              participants = [
                ...participants,
                {
                  name: value.name,
                  uniquecode: value.uniquecode,
                  phone: value.phone,
                  profile: value.profile_picture ?? "",
                  extension: value.user_extension,
                },
              ];
            }
          });
          let data = {
            participants,
            name,
            uniquekey: __team_uuid,
            profile: "",
            message,
          };
          SelectedGroupChatUser(data, store.dispatch);
          SelectedChatType("group_chat", store.dispatch);
          window.$("#exampleModal1").modal("hide");
          setName("");
          setClicked(false);
          setParticipant([]);
        }
        __team_uuid = `TM-${uuidv4()}-CTM`;
      } while (__team_exists);
    })();
  };


  const cancelTeamChat = ()=>{
    setName("")
    setParticipant([])
  }

  const handleChange = (selectedOption) => { 
    setParticipant(selectedOption);
  }; 

  return (
    <div
      className="modal fade"
      id="exampleModal1"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md2" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel1">
              Create Team
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body new-messge-body-modal">
            <form className="new-message-form ">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group ">
                    <label className="col-form-label">Team Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="enter team name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {validator.current.message("Team Name", name, "required")}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group doctor-setp-mid">
                    <label className="col-form-label">Team Members</label>
                    <Select
                        isMulti
                        isSearchable  
                        name="participant"
                        value={participant}
                        placeholder="Choose Team Members"
                        onChange={handleChange}
                        defaultValue={participant}
                        options={
                            extension_list &&
                            extension_list.map((value) => ({
                            label: `${value.name}(${value.user_extension})`,
                            value: value.uniquecode, 
                        }))
                        }
                    /> 
                    {validator.current.message(
                      "Participants",
                      participant,
                      "required"
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="col-form-label">Message</label>
                    <textarea
                      rows="4"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="form-control"
                      placeholder="Enter your message here"
                    />
                    {validator.current.message("Message", message, "required")}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer  new-message-modal-footer">
            <button
              type="button"
              className="btn message-cancel-btn"
              data-dismiss="modal"
              disabled={clicked}
              onClick={cancelTeamChat}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-create-message"
              disabled={clicked}
              onClick={startChat}
            >
              {clicked ? "Please wait..." : "Start Chat"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamChatPop;
