import React, { Component } from "react";
import moment from "moment";
import Picker, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import ReactTooltip from "react-tooltip";
import authService from "../../Utils/authService";
import Chat from "../../Utils/Chat";
import {
  ForceCall,
  SelectedChatType,
  UploadFiles,
} from "../../Services/Actions/callActions";
import { store } from "../../Services";
import { firebaseApp } from "../../Utils/FirebaseService";
import { StartMeet } from "../../Services/Actions/meetActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MeetDomain } from "../../Utils/Common";

class OneToOneChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      room_id: "",
      selectedFile: "",
      message: "",
      chat: [],
      type: "",
      _FilePath: "",
      favourite: false,
      isOnline: "offline",
      showEmoji: false,
      last_seen: false,
    };
  }

  componentDidMount() {
    const user_detail = authService.getUserDetail();
    this.setState({ myData: user_detail });
    this.ChatChange(user_detail);
  }

  componentDidUpdate(prevProps) {
    const { selected_chat_user: old_props } = prevProps;
    const { selected_chat_user } = this.props;
    if (selected_chat_user !== old_props && selected_chat_user) {
      const user_detail = authService.getUserDetail(); 
      this.ChatChange(user_detail);
    }
  }

  FetchOnlineStatus = (value) => {
    if (value && value.online) {
      this.setState({ isOnline: "online" });
    } else if (value && value.online === false) {
      this.setState({
        isOnline: `Last online at ${moment(value.last_changed)
          .locale("en")
          .format("lll")}`,
      });
    } else {
      this.setState({ isOnline: "offline" });
    }
  };

  FetchLastSeen = (value) => {
    if (value?.last_seen) {
      const { last_seen } = value;
      this.setState({ last_seen });
    } else {
      this.setState({ last_seen: false });
    }
  };

  ChatChange = (user_detail) => {
    let {
      selected_chat_user,
      user_details: { profile_picture, phone },
    } = this.props;
    {
      /** TO CHECK IF USERS CHAT EXISTING  */
    }
    let txnID = user_detail.uniquecode;
    let rcvID = selected_chat_user.uniquecode;
    Chat.fetch_online_status(rcvID, this.FetchOnlineStatus);
    const room_id = Chat.room_id(txnID, rcvID);
    let TxnExists = Chat.if_room_exists(txnID);
    let RcvExists = Chat.if_room_exists(rcvID);
    this.setState({ room_id }, () => {
      Chat.fetch_message_data_on_update(room_id, this.UpdateChat);
    });
    let IndividualStructure = {
      createdBy: txnID,
      last_message: "",
      updated_at: firebaseApp.database.ServerValue.TIMESTAMP,
      last_message_type: "", 
      last_message_sender: "",
      sender_name: "",
      roomName: room_id,
      phone: "",
      type: "individual",
      favourite: false,
    };
    Promise.all([TxnExists, RcvExists]).then((value) => {
      value.map((val, key) => {
        let __parentId = key === 0 ? txnID : rcvID;
        let displayName =
          key === 0
            ? selected_chat_user.name
            : `${user_detail.firstname} ${user_detail.lastname}`;
        let recipient = key === 0 ? rcvID : txnID;
        let profile = key === 0 ? selected_chat_user?.profile_picture : profile_picture;
        phone = key === 0 ? selected_chat_user?.phone : phone;
        let __instanceToSend = {
          ...IndividualStructure,
          displayName,
          profile,
          phone,
          recipient,
          last_seen: false,
        };
        if (val === false) {
          Chat.update_ref_with_children(
            `/Users/${__parentId}/${room_id}/`,
            __instanceToSend
          ).then(() => {
            if (key === 1 && selected_chat_user.message) {
              this.setState({ message: selected_chat_user.message }, () =>
                this.handleSend()
              );
            }
          });
          Chat.fetch_ref_on_update(
            `/Users/${rcvID}/${room_id}`,
            this.FetchLastSeen
          );
        } else {
          Chat.if_room_exists_return_value(key === 0 ? txnID : rcvID).then(
            (values) => {
              if (!Object.keys(values).includes(room_id)) {
                Chat.update_ref_with_children(
                  `/Users/${__parentId}/${room_id}/`,
                  __instanceToSend
                ).then(() => {
                  if (key === 1 && selected_chat_user.message) {
                    this.setState({ message: selected_chat_user.message }, () =>
                      this.handleSend()
                    );
                  }
                });
                Chat.fetch_ref_on_update(
                  `/Users/${rcvID}/${room_id}`,
                  this.FetchLastSeen
                );
              } else {
                if (key === 0) {
                  this.setState({ favourite: values?.[room_id]?.favourite });
                  Chat.fetch_ref_on_update(
                    `/Users/${rcvID}/${room_id}`,
                    this.FetchLastSeen
                  );
                }
              }
            }
          );
        }
      });
    }); 
  };

  UpdateChat = (chat) => {
    chat = chat ? Object.values(chat) : [];
    this.setState({ chat }, () => {
      console.log("REF", this.messagesEnd);
      if (this.messagesEnd?.current?.offsetTop) {
        window.scrollTo(0, this.messagesEnd.current.offsetTop);
      }
    });
  };

  selectfileHandler = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.setState(
        {
          selectedFile: file,
        },
        () => {
          this.onChooseFile();
        }
      );
    }
  };

  onChooseFile = () => {
    const { selectedFile } = this.state;
    if (selectedFile) {
      const prefixFiletype = selectedFile.type.toString();
      if (
        prefixFiletype.includes("image") ||
        prefixFiletype.includes("video") ||
        prefixFiletype.includes("application") ||
        prefixFiletype.includes("audio") ||
        prefixFiletype.includes("text")
      ) {
        let type = prefixFiletype.includes("image")
          ? "image"
          : prefixFiletype.includes("video")
          ? "video"
          : prefixFiletype.includes("application")
          ? "file"
          : prefixFiletype.includes("audio")
          ? "audio"
          : "textfile";
        this.setState({ type }, () => this.uploadfile());
      }
    }
  };

  uploadfile = async () => {
    const { selectedFile, type } = this.state;
    let response = await UploadFiles(selectedFile, "chat", store.dispatch);
    if (response.code === 1) {
      this.setState(
        {
          _FilePath: response.file_path,
        },
        () => {
          this.handleSend(type);
        }
      );
    }
  };

  handleSend = (type = "text") => {
    let { room_id, message, _FilePath: media, chat } = this.state;
    const {
      selected_chat_user: { uniquecode: rcv_uniquecode, name: rcv_name },
    } = this.props;
    const {
      firstname: txn_firstname,
      lastname: txn_lastname,
      customerID: txn_customerID,
      uniquecode: txn_uniquecode,
    } = authService.getUserDetail();
    let dataToSend = {
      message,
      date: firebaseApp.database.ServerValue.TIMESTAMP,
      from: txn_uniquecode,
      type,
      media,
      to: rcv_uniquecode,
      from_name: `${txn_firstname} ${txn_lastname}`,
      to_name: rcv_name,
    };
    Chat.update_message_data(room_id, dataToSend);
    Chat.if_room_exists_return_value(txn_uniquecode).then((value) => {
      Chat.update_room_data(txn_uniquecode, {
        ...value,
        [room_id]: {
          ...value[room_id],
          last_message: message,
          updated_at: firebaseApp.database.ServerValue.TIMESTAMP,
          last_message_type: type,
          last_message_sender: txn_uniquecode,
          last_seen: true,
        },
      });
    });
    Chat.if_room_exists_return_value(rcv_uniquecode).then((value) => {
      Chat.update_room_data(rcv_uniquecode, {
        ...value,
        [room_id]: {
          ...value[room_id],
          last_message: message,
          updated_at: firebaseApp.database.ServerValue.TIMESTAMP,
          last_message_type: type,
          last_message_sender: txn_uniquecode,
          last_seen: false,
        },
      });
    });
    this.setState({
      message: "",
      selectedFile: "",
      _FilePath: "",
      showEmoji: false,
    });
  };

  // drag drop function start
  dragOver = (e) => {
    e.preventDefault();
  };

  dragEnter = (e) => {
    e.preventDefault();
  };

  dragLeave = (e) => {
    e.preventDefault();
  };

  fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.setState(
      {
        selectedFile: files[0],
      },
      () => {
        this.onChooseFile();
      }
    );
  };

  __AddToFavourite = () => {
    const user_detail = authService.getUserDetail();
    let { room_id, favourite } = this.state;
    {
      /** TO CHECK IF USERS CHAT EXISTING  */
    }
    let txnID = user_detail.uniquecode;
    Chat.if_room_exists_return_value(txnID).then((value) => {
      if (Object.keys(value).includes(room_id)) {
        Chat.update_ref_with_children(`/Users/${txnID}/${room_id}/`, {
          ...value[room_id],
          favourite: !value[room_id].favourite,
        }).then(() => this.setState({ favourite: !favourite }));
      }
    });
  };

  onEmojiClick = (event, emojiObject) => {
    const { emoji } = emojiObject;
    const { message } = this.state;
    this.setState({ message: `${message}${emoji}` });
  };

  UpdateLastSeen = (type) => {
    const { uniquecode } = authService.getUserDetail();
    const { room_id } = this.state;
    Chat.if_room_exists_return_value(uniquecode).then((values) => {
      if (Object.keys(values).includes(room_id)) {
        let { last_seen } = values[room_id];
        if (last_seen === false) {
          Chat.update_ref_with_children(`/Users/${uniquecode}/${room_id}`, {
            ...values[room_id],
            last_seen: true,
          });
        }
      }
    });
  };

  onKeyPressHandler = (e)=>{
      if(e.key === "Enter"){
        this.handleSend();
      } 
  }

  render() {
    const { selected_chat_user: chatUser,OpenSmsModal } = this.props;
    const { message, chat, favourite, isOnline, showEmoji, last_seen } =
      this.state;
    let profile_picture =
      chatUser && chatUser.profile_picture && chatUser.profile_picture !== " "
        ? chatUser.profile_picture
        : "./../assets/images/Mask.png";
    let myData = authService.getUserDetail();
    let length_chat = chat.length;
    return (
      <div className="col-md-8 col-lg-9">
        <div className="open-chat message-chat-body">
          <div className="open-chat22 ">
            <div className="d-flex chat-profile p-2 justify-content-between">
              <div className="d-flex chat-page-top-dt">
                <Link
                  to="#"
                  className="chat-back-arrow chat-wind-back-arrow chat-back-arrow-2"
                  onClick={() =>
                    SelectedChatType("all_recent_chats", store.dispatch)
                  } 
                >
                  <img src="./../assets/images/backA.svg" />
                </Link>

                <div className="chat-pro-img chat-pro-new-img">
                    {!profile_picture && 
                      <div className="one-to-one-chat">
                        <h5>
                          {chatUser.name.match(/\b(\w)/g).join("").toUpperCase()}
                        </h5>
                    </div>}
                  {profile_picture && <img src={profile_picture} alt="1" />}
                </div>
                <div className="chat-pro-name align-self-center">
                  <h5 className="chat-name mb-0">
                    {chatUser && chatUser.name
                      ? chatUser.name
                      : chatUser.number}
                  </h5>
                  <p>{isOnline}</p>
                  <ul className="chat-find-media-sms-ul">
                    <li>
                      <a href={void 0} onClick={(e) => e.stopPropagation()}>
                        <img src="./../assets/images/find-search.svg" />
                        Find
                      </a>
                    </li>
                    <li> 
                      <Link
                        to="#" 
                        onClick={() => this.mediaRef.click()}
                      >
                        <img src="./../assets/images/media-ico-chat.svg" />
                        Media
                      </Link>
                      <input
                        className="file-cont hide"
                        type="file"
                        ref={(input) => {
                          this.mediaRef = input;
                        }}
                        onChange={this.selectfileHandler} 
                      /> 
                    </li>
                    <li>
                      <Link to="#" onClick={()=>OpenSmsModal({number: chatUser?.phone ? chatUser.phone.replace("+", "") : ""})}>
                        <img src="./../assets/images/mail-chat-ico.svg" />
                        SMS
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="chat-icons chat-icons-new-wind align-self-center">
                  <a
                    href={void 0}
                    onClick={() => this.__AddToFavourite()}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={`./../assets/images/${
                        favourite ? "fav-green-star.svg" : "fav-greystar.svg"
                      }`}
                    />
                  </a>
                  <a
                    href={void 0}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.setState(
                        {
                          call: true,
                          number: chatUser?.phone
                            ? chatUser.phone.replace("+", "")
                            : "",
                        },
                        () => ForceCall(this.state.number, store.dispatch)
                      )
                    }
                  >
                    <img src="./../assets/images/icons/call-ico.png" />
                  </a>
                  <a
                    href={void 0}
                    onClick={() => StartMeet(chatUser.phone)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src="./../assets/images/icons/video-ico.png" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="mid-sec message-chat-height"
              ref={(input) => {
                this.messagesEnd = input;
              }}
              onDragOver={this.dragOver}
              onDragEnter={this.dragEnter}
              onDragLeave={this.dragLeave}
            >
              {chat instanceof Array &&
                chat.map((values, keys) => {
                  let time = values.date;
                  return (
                    <div
                      className={`w-100 float-${
                        myData.uniquecode === values.from ? "right" : "left"
                      }`}
                      key={`messages_${keys}`}
                      onMouseEnter={(e) =>
                        length_chat === keys + 1 &&
                        myData.uniquecode != values.from
                          ? this.UpdateLastSeen("Mouse")
                          : e.preventDefault()
                      }
                    >
                      <div
                        className={`msg-inbox ${
                          values.type !== "text" ? "bubble-remove" : ""
                        } ${
                          myData.uniquecode === values.from
                            ? "send"
                            : `recive${
                                ["instant", "scheduled"].includes(values.type)
                                  ? " meet-box"
                                  : ""
                              }`
                        }`}
                        key={`messages_${keys}`}
                      >
                        {values.type === "text" ? (
                          <div
                            className={`chats-text ${
                              myData.uniquecode === values.from
                                ? "bg-orang"
                                : ""
                            }`}
                          >
                            <p className="m-0 chat-font">{values.message}</p>
                          </div>
                        ) : values.type === "image" ? (
                          <img src={values.media} className="msg-img-video" />
                        ) : values.type === "video" ? (
                          <video
                            controls
                            src={`${values.media}`}
                            className="msg-img-video"
                          >
                            Your browser does not support the video tag.
                          </video>
                        ) : values.type === "audio" ? (
                          <audio controls className="audio-custom">
                            <source src={values.media} />
                            Your browser does not support the audio element.
                          </audio>
                        ) : values.type === "file" ? (
                          <>
                            {values.media.includes(".pdf") === true ? (
                              <a
                                href={values.media}
                                type="application/pdf"
                                download
                                target="_blank"
                              >
                                <i
                                  className="fa fa-file-pdf"
                                  style={{ fontSize: "48px" }}
                                ></i>
                              </a>
                            ) : values.media.includes(".zip") ? (
                              <a href={values.media}>
                                <i
                                  className="fa fa-file-archive"
                                  style={{ fontSize: "48px" }}
                                ></i>
                              </a>
                            ) : null}
                          </>
                        ) : values.type === "textfile" ? (
                          <a href={values.type} download target="_blank">
                            <i
                              className="fa fa-file-text-o"
                              style={{ fontSize: "48px" }}
                            ></i>
                          </a>
                        ) : ["instant", "scheduled"].includes(values.type) ? (
                          <div
                            className={`chats-text chat-invite-box ${
                              myData.uniquecode === values.from
                                ? "bg-orang"
                                : ""
                            }`}
                          >
                            <span className="calender-img-chat">
                              <img
                                src="./../assets/images/invite-calender.svg"
                                alt="1"
                              />
                            </span>
                            <h3>{values.media}</h3>
                            <h5>
                              {values.type === "instant"
                                ? "Instant"
                                : "Scheduled"}{" "}
                              Call
                            </h5>
                            <h6>{moment(time).locale("en").format("lll")}</h6>
                            <div className="invite-request-chat-div">
                              <span className="invite-user-img-chat">
                                <img src={profile_picture} alt="1" />
                              </span>
                              <a
                                className="invite-user-link-chat"
                                target="_blank"
                                href={`https://${MeetDomain}/${values.message}`}
                              >
                                {myData.uniquecode === values.from
                                  ? "You've Invited!"
                                  : "You're Invited!"}
                                <img
                                  src="./../assets/images/arrow-thin-right.svg"
                                  alt="1"
                                />
                              </a>
                            </div>
                          </div>
                        ) : null}
                        <div className="chat-time text-right">
                          <p
                            className={`text-black ${
                              myData.uniquecode === values.from
                                ? ""
                                : "float-left"
                            }`}
                            style={{ paddingRight: "30px" }}
                          >
                            {last_seen &&
                              myData.uniquecode === values.from &&
                              keys + 1 === length_chat && (
                                <a
                                  href={void 0}
                                  className="seen-anchor"
                                  data-tip
                                  data-for="recipientName"
                                >
                                  <img src={profile_picture} alt="a" />
                                  <ReactTooltip id="recipientName" type="error">
                                    <span>
                                      {chatUser && chatUser.name
                                        ? chatUser.name
                                        : chatUser.number}
                                    </span>
                                  </ReactTooltip>
                                </a>
                              )}
                            <small className="chat-date-time">
                              {moment(time).locale("en").format("lll")}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {chat && chat.length === 0 && (
                <div className="start-meesage-div">
                  <img src="./../assets/images/start-chat-img-v.png" />
                  <h2>Start by saying Hi ✌️</h2>
                  <p>Start new conversation with an existing contact.</p>
                </div>
              )}
            </div>
          </div>
          <div className="form-group msg-input new-wind-msg-input  m-0">
            {/* p-2 d-flex bg-white */}
            <div className="btn-file btn align-self-center">
              {/* <i className="fas fa-paperclip" /> */}
              <i className="msg-paperclip">
                <img src="./../assets/images/message-attch-file.svg" />
              </i>
              <input
                className="file-cont"
                type="file"
                onChange={this.selectfileHandler}
              />
            </div>
            <input
              type="text"
              className="form-control chat-input"
              name="message"
              value={message}
              placeholder="Type something here..."
              onChange={(e) => this.setState({ message: e.target.value })}
              onKeyPress={(e) =>this.onKeyPressHandler(e) }
              onFocus={() => this.UpdateLastSeen("Focus")}
              onDrop={this.fileDrop}
            />
            <a
              href={void 0}
              className="paper-plane"
              onClick={() => this.handleSend()}
            >
              {/* <i className="fas fa-paper-plane"></i> */}
              <i className="msg-paper-plane">
                <img src="./../assets/images/message-send-new.svg" />
              </i>
            </a>
            <ul className="message-action-new-ico">
              <li>
                <a
                  href={void 0}
                  className="webphone-btns"
                  onClick={() => this.imageRef.click()}
                >
                  <img src="./../assets/images/chat-add-img-ico.svg" />
                </a>
                <input
                  className="file-cont hide"
                  ref={(input) => {
                    this.imageRef = input;
                  }}
                  type="file"
                  onChange={this.selectfileHandler}
                  accept="image/*"
                />
              </li>
              <li>
                <a
                  href={void 0}
                  className="webphone-btns"
                  onClick={() => this.gifRef.click()}
                >
                  <img src="./../assets/images/chat-file-gif-line.svg" />
                </a>
                <input
                  className="file-cont hide"
                  type="file"
                  ref={(input) => {
                    this.gifRef = input;
                  }}
                  onChange={this.selectfileHandler}
                  accept="image/gif"
                />
              </li>
              <li>
                <a
                  href={void 0}
                  className="webphone-btns"
                  onClick={() => this.fileRef.click()}
                >
                  <img src="./../assets/images/chat-file-attch.svg" />
                </a>
                <input
                  className="file-cont hide"
                  type="file"
                  ref={(input) => {
                    this.fileRef = input;
                  }}
                  onChange={this.selectfileHandler}
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"
                />
              </li>
              <li>
                <a href={void 0} className="webphone-btns">
                  <img src="./../assets/images/chat@ico.svg" />
                </a>
              </li>
              <li>
                <a
                  href={void 0}
                  className="webphone-btns"
                  onClick={() => this.setState({ showEmoji: !showEmoji })}
                >
                  <img src="./../assets/images/chat-smile-ico.svg" />
                </a>
                {showEmoji && (
                  <div className="chat-page-emoji-div">
                    <Picker
                      onEmojiClick={this.onEmojiClick}
                      skinTone={SKIN_TONE_NEUTRAL}
                    />
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selected_chat_user: state?.call?.selected_chat_user ?? {},
  user_details: state?.user?.user_details?.userDetail ?? {},
});

export default connect(mapStateToProps)(OneToOneChat);
