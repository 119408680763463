import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RenderProp from "../../../Component/Modal/RenderProp";
import Schedule from "../../../Component/Modal/Schedule";
import Pagination from "../../../Component/Pagination";
import { store } from "../../../Services";
import { 
  ForceCall,
  SelectedChatType,
  SelectedChatUser,
} from "../../../Services/Actions/callActions";
import { StartMeet } from "../../../Services/Actions/meetActions"; 
import { removeBackdrop } from "../../../Utils/Common";
import { withRouter } from "../../../Utils/withRouter";
import MessageContactModal from "../SMS/MessageContactModal";
 

class TeamContact extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, number: "", modal: "", pageOfItems: [] };
  } 

  UpdateData = (pageOfItems) => {
    this.setState({ pageOfItems });
  };

  closeModal = () => {
    this.setState({ show: false }, () => removeBackdrop());
  };

  startChat = (value) => {
    console.log("clickee")
    let userData = {
      profile_picture: value?.profile_picture ?? "",
      phone: value?.phone ?? "",
      name: value?.firstname ?? "",
      uniquecode: value?.uniquecode ?? "",
    };

    console.log("userData=======>",userData)
    
    SelectedChatUser(userData,store.dispatch)
    SelectedChatType("one_to_one_chat",store.dispatch) 
    this.props.navigate("chat");
  };

  render() {
    const { teams_list, layoutType ,page } = this.props;
    const { show, number, modal, pageOfItems } = this.state;
    return (
      <>
        {layoutType === "grid" && (
          <div className="grid-view-contact">
            <div className="row">
              {pageOfItems &&
                pageOfItems.map((value, index) => {
                  return (
                    <div className="col-md-3 nxa-call-gird-box" key={index}>
                      <div className="nxa-call-gird-box-inner">
                        <span className="nxa-call-contact-img">
                          {!value.profile_picture && (
                            <h5>
                              {value.name
                                .match(/\b(\w)/g)
                                .join("")
                                .toUpperCase()}
                            </h5>
                          )}
                          {value.profile_picture && (
                            <img src={value.profile_picture} alt="a" />
                          )}
                        </span>
                        <h4>{value.name} <i className="italic-font-color">{`(${value.user_extension})`}</i></h4>
                        <p>{value.email}</p>
                        <div className="nxa-call-grid-action">
                          {this.DisplayIcons(value)}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {layoutType === "list" && (
          <div className="tab-content">
            <div className={`tab-pane height-auto show active ${page === "setting" ? "contact-info-scroll" : "call-info-scroll"}`}>
              <div className="table-responsive calls-table calls-table-hover">
                <table className="table table-hover table-center mb-0">
                  <tbody>
                    {pageOfItems &&
                      pageOfItems.map((value, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <div className="d-flex all-message-email-row">
                                <div className="text-center align-self-center caller-img nxa-contact">
                                  {!value.profile_picture && (
                                    <h6>
                                      {value.name
                                        .match(/\b(\w)/g)
                                        .join("")
                                        .toUpperCase()}
                                    </h6>
                                  )}
                                  {value.profile_picture && (
                                    <img src={value.profile_picture} alt="a" />
                                  )}
                                </div>
                                <div className="align-self-center caller-name-number">
                                  <h3>{value.name} <i className="italic-font-color">{`(${value.user_extension})`}</i></h3>
                                  <p>{value.phone}</p>
                                </div>
                              </div>
                            </td>
                            <td align="right">{this.DisplayIcons(value)}</td>
                          </tr>
                        );
                      })}
                    {teams_list && teams_list.length === 0 && (
                      <tr>
                        <td colSpan="2" className="text-center">
                          No team contacts found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        <Pagination data={teams_list} currentData={this.UpdateData} />
        {modal === "message-contact" && show && (
          <MessageContactModal
            OpenMessageModal={show}
            closeMessageContactModal={this.closeModal}
            destination_number={number}
          />
        )}

        {modal === "schedule_meet" && show && (
          <RenderProp>
            {({ UpdateTopic, extension_listing }) => (
              <Schedule
                UpdateTopic={UpdateTopic}
                extension_listing={extension_listing}
              />
            )}
          </RenderProp>
        )}
      </>
    );
  }

  DisplayIcons = (value) => {
    const { layoutType } = this.state;
    return (
      <div className="recentcall-img-ico allcall-img-ico">
        <Link
          to="#"
          title="Audio Call"
          style={{ cursor: "pointer" }}
          onClick={() => ForceCall(value.phone,store.dispatch)}
        >
          <img src="./../assets/images/calls-call-ico.svg" alt="1" />
        </Link>
        <Link
          to="#"
          title="Video Call"
          style={{ cursor: "pointer" }}
          onClick={() => StartMeet(value.phone)}
        >
          <img src="./../assets/images/call-video.svg" alt="1" />
        </Link>
        <Link
          to="#"
          title="Chat"
          onClick={() => this.startChat(value)}
          style={{ cursor: "pointer" }}
        >
          <img src="./../assets/images/call-chat-ico.svg" alt="1" />
        </Link>
        <Link
          to="#"
          style={{ cursor: "pointer" }}
          title="Schedule Meet"
          onClick={() =>
            this.setState(
              {
                modal: "",
              },
              () => {
                this.setState({
                  modal: "schedule_meet",
                  show: true,
                });
              }
            )
          }
        >
          <img src="./../assets/images/call-schdule.svg" alt="1" />
        </Link>
        <Link
          to="#"
          title="Send SMS"
          onClick={() =>
            this.setState({
              show: true,
              number: value.phone,
              modal: "message-contact",
            })
          }
          style={{ cursor: "pointer" }}
        >
          <img src="./../assets/images/email-contact.svg" alt="1" />
        </Link>
        {layoutType === "list" && (
          <Link to="#">
            <img src="./../assets/images/call-menu-ico.svg" alt="1" />
          </Link>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  teams_list: state?.setting?.extension_listing?? [],
});

export default connect(mapStateToProps)(withRouter(TeamContact));
