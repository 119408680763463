import React, { Component } from "react";
import { Link } from "react-router-dom";

const initialState = {};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  render() {
    const { state, SetTab } = this.props;

    return (
      <div className="col-md-4 col-lg-3 pl-0">
        <div className="mid-sidebar">
          <div className="mid-nav-inner bg-gray p-0">
            <div className="mid-nav pt-4 phone-system-menus">
              <div className="pl-4 pr-4">
                <h4>Power Apps</h4>
                <hr className="mb-0 mt-0"></hr>
              </div>

              <div>
                <ul className="list-unstyled mid-components m-0">
                  <li>
                    <Link
                        to="#"
                        className={` ${state === "all" ? "active-sidebar" : ""}`}
                        onClick={() => SetTab("all")}
                    >
                      <span className="color-gray font-12">All</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                        to="#"
                        className={`
                            ${
                            state === "cloud_storage" ||
                            state === "call_recording" ||
                            state === "video_recording"
                                ? "active-sidebar"
                                : ""
                            }
                        `}
                        onClick={() => SetTab("cloud_storage")}
                    >
                      <span className="color-gray font-12">Cloud Storage</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                        to="#"
                        className={`
                            ${
                              state === "notes" || state === "create_note"
                                ? "active-sidebar"
                                : ""
                            }
                        `}
                        onClick={() => SetTab("notes")}
                    >
                      <span className="color-gray font-12">Notes</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                        to="#"
                        className={` ${
                            state === "business_inbox" ? "active-sidebar" : ""
                        }`}
                        onClick={() => SetTab("business_inbox")}
                    >
                      <span className="color-gray font-12">Business Inbox</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                        to="#"
                        className={` ${
                            state === "more_apps" ? "active-sidebar" : ""
                        }`}
                        onClick={() => SetTab("more_apps")}
                    >
                      <span className="color-gray font-12">
                        More Apps Coming Soon
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
