import React from "react";
import { Link } from "react-router-dom";

export default function Sidebar({ setActiveMenu, activeMenu }) {
  return (
    <div className="col-md-4 col-lg-3 pl-0">
      <div className="mid-sidebar">
        <div className="mid-nav-inner bg-gray p-0">
          <div className="mid-nav pt-4 phone-system-menus">
            <div className="pl-4 pr-4">
              <h4>Reports</h4>
              <hr className="mb-0 mt-0"></hr>
            </div>
            <div>
              <ul className="list-unstyled mid-components m-0">
                <li>
                  <Link
                    to="#"
                    className={`${
                      activeMenu === "all-calls" ? "active-sidebar" : ""
                    }`}
                    onClick={() => setActiveMenu("all-calls")}
                  >
                    <span className="color-gray font-12">All calls</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={`${
                      activeMenu === "missed" ? "active-sidebar" : ""
                    }`}
                    onClick={() => setActiveMenu("missed")}
                  >
                    <span className="color-gray font-12">Missed calls</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={`${
                      activeMenu === "recording" ? "active-sidebar" : ""
                    }`}
                    onClick={() => setActiveMenu("recording")}
                  >
                    <span className="color-gray font-12">Call recordings</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
