import * as constants from "../Constants/settingConstants";

 

const initalState = {  
    extension_listing:[], 
    individual_extension_detail:[],
    card_listing:[],
    transaction_list:[],
    rule_listing:[],
    call_flow_list:[],
    ivr_menu_listing:[],
    businesshour_listing:[],
    ivr_listing:[]

}

const settingReducer = (state = initalState, action) => {
    switch (action.type) {
        case constants.FTECH_EXTENSION_LISTING:   
            return {
                ...state,
                extension_listing: action.payload,
            }
        case constants.FTECH_INDIVIDUAL_EXTENSION_DETAIL:   
            return {
                ...state,
                individual_extension_detail: action.payload,
            }
        case constants.FETCH_CARD_LISTING:
            return {
                ...state,
                card_listing: action.payload
            };
        case constants.FETCH_PAYMENT_LISTING:
            return {
                ...state,
                transaction_list: action.payload
            };  
        case constants.FETCH_RULE_LISTING:
            return {
                ...state,
                rule_listing: action.payload,
            }
        case constants.FETCH_CALL_FLOW_LISTING: 
            return {
                ...state,
                call_flow_list: action.payload,
            };
        case constants.FETCH_IVR_MENU_LISTING: 
            return {
                ...state,
                ivr_menu_listing: action.payload,
            };
        case constants.FETCH_BUSINESS_HOUR_LISTING: 
            return {
                ...state,
                businesshour_listing: action.payload,
            };
        case constants.FETCH_IVR_GREETING_LISTING:
            return {
                ...state,
                ivr_listing: action.payload,
            };
        case constants.TIMEZONE_LIST:
            return {
                ...state,
                timezone_list: action.payload,
            };
        default:
            return state
    }
}

export default settingReducer;
