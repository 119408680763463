import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Flag from "react-world-flags";
import CallHistory from "../CallHistory";
import CallInfo from "../../Calls/CallHistory/CallInfo";
import {
  ConferenceCallSessions,
  TransferCallSessions,
} from "../../../Services/Actions/callActions";
import { store } from "../../../Services"; 

const IdleFrame = (props) => {
  const [infoFrame, setInfoFrame] = useState("");
  const callNow = useRef(null);
  const {
    countryCode,
    number,
    onChangeHandler,
    did_number,
    KeyPadBtn,
    voice_minute,
    StartCall,
    DetectCaller,
    did_list,
    history,
    ShowKeyPadHandler,
    showKeypad,
    transfer,
    conference,
    activeSession,
    PostTransfer,
    PostConference,
    TransferCall,
    ConferenceCall,
    DTMF,
    CallTransfer,
    ExternalNumberHandler,
  } = props; 

  const onkeyPressHandler = (e) => {
    if (e.key === "Enter") {
      callNow.current.click();
    }
  };

  const BackHandler = () => {
    setInfoFrame("");
  };

  const getExternalNumber = () => {
    let _TransferTo = {
      name: "Unknown Caller",
      number,
      status: "ringing",
      direction: "inbound",
    };
    let _TransferFrom = {
      name: activeSession?.name,
      number: activeSession?.number,
      status: "hold",
      direction: "inbound",
    };
    let customCallSessions = {};
    let transferObject = { to: _TransferTo, from: _TransferFrom };
    customCallSessions[activeSession?.uuid] = transfer
      ? transferObject
      : { ...transferObject, isMerged: false };
    DTMF(`*1${number}#`);
    if (transfer) {
      PostTransfer();
      TransferCall("warm");
      TransferCallSessions(customCallSessions, store.dispatch);
    } else {
      PostConference();
      ConferenceCall("call-separately");
      ConferenceCallSessions(customCallSessions, store.dispatch);
    }
    ShowKeyPadHandler(false);
    ExternalNumberHandler();
  };

  //  TRANSFER CALL BUTTONS
  const TransferBtnDiv = () => {
    return (
      <div className="tranfer-speak-btn-div ml-4">
        <Link
          to="#"
          className="transfer-now-btn"
          onClick={() => {
            CallTransfer(number);
            PostTransfer();
          }}
        >
          <img src="./../assets/images/transfer-now-ico.svg" alt="1" />
          Transfer Now
        </Link>
        <Link
          to="#"
          className="speak-first-btn mr-2"
          onClick={() => getExternalNumber()}
        >
          <img src="./../assets/images/speak-first-ico.svg" alt="1" />
          Speak First
        </Link>
      </div>
    );
  };

  //  CONFERENCE CALL BUTTONS
  const ConferenceBtnDiv = () => {
    return (
      <div className="tranfer-speak-btn-div">
        <Link
          to="#"
          className="transfer-now-btn ml-4"
          onClick={getExternalNumber}
        >
          Add to the call
        </Link>
        <Link
          to="#"
          className="speak-first-btn mr-2"
          onClick={getExternalNumber}
        >
          Call Separately
        </Link>
      </div>
    );
  };

  return (
    <div className="col-md-12 mt-2">
      <div className="dailer-key">
        <div className="dailer-top-bar">
          <div className="d-flex justify-content-between align-items-center">
            {number?.length === 0 || number?.length >= 6 ? (
              <Flag
                code={
                  countryCode !== "" && countryCode !== "unknown"
                    ? countryCode
                    : "ZA"
                }
                style={{ marginRight: "32 px" }}
                height="20 px"
                fallback={<span></span>}
              />
            ) : (
              <span></span>
            )}
            {infoFrame === "" ? (
              <span
                className="min-mize-div webphone-btns"
                onClick={() => {
                  if (showKeypad) {
                    ShowKeyPadHandler(false, {});
                  } else {
                    window.$("#diallerModal").modal("hide");
                  }
                }}
              >
                <Link to="#" className="min-mize" />
              </span>
            ) : (
              <a
                href={void 0}
                onClick={BackHandler}
                className="back-arrow-dial webphone-btns"
              >
                <i className="fa fa-arrow-left" />
              </a>
            )}
          </div>
          <div className="input-group ">
            <input
              type="text"
              className="form-control"
              name="number"
              value={number}
              placeholder="Type Number"
              onChange={onChangeHandler}
              onKeyPress={(e) => onkeyPressHandler(e)}
            />
          </div>
        </div>
        <div className="number-dropdown d-flex">
          <span className="call-from">Call From:</span>
          <select
            className="form-control"
            name="did_number"
            value={did_number}
            onChange={onChangeHandler}
          >
            {did_list instanceof Array &&
              did_list.map((value) => {
                return (
                  <option value={value.did_number} key={value.did_number}>
                    {value.did_number}
                  </option>
                );
              })}
          </select>
          {!showKeypad && (
            <Link
              to="#"
              onClick={() =>
                onChangeHandler({
                  target: { name: "history", value: !history },
                })
              }
              className="webphone-btns text-center"
            >
              {!history && <i className="far fa-clock dialer-clock-ico" />}
              {/* ADD YOUR IMAGE TAG HERE TO SHOW KEYPAD */}
              {history && (
                <i className="dialer-ico">
                  <img src="./../assets/images/cl-dial-pad.svg" alt="1" />
                </i>
              )}
            </Link>
          )}
        </div>
        {history && !infoFrame && (
          <CallHistory
            onChangeHandler={onChangeHandler}
            StartCall={StartCall}
            DetectCaller={DetectCaller}
            setInfoFrame={setInfoFrame}
          />
        )}
        {history && infoFrame && (
          <CallInfo
            setInfoFrame={setInfoFrame}
            number={infoFrame}
            fromDialer={true}
          />
        )}
        {!history && (
          <>
            {KeyPadBtn("number")}
            {!showKeypad && (
              <div className="number  ">
                <div className="d-flex justify-content-between">
                  <Link to="#" className="webphone-btns r100">
                    <div className=" pt-1">{voice_minute}</div>
                  </Link>
                  <Link
                    to="#"
                    className="phone webphone-btns"
                    onClick={() => {
                      if (number) {
                        StartCall(number);
                        DetectCaller();
                      }
                    }}
                    ref={callNow}
                  >
                    <div className="text ">
                      <i className="fas fa-phone-alt "></i>
                    </div>
                  </Link>
                  <Link
                    to="#"
                    className="arrow webphone-btns"
                    onClick={() =>
                      onChangeHandler({
                        target: {
                          name: "number",
                          value: number.slice(0, -1),
                        },
                      })
                    }
                  >
                    <div className="text">
                      <i className="fas fa-arrow-left "></i>
                    </div>
                  </Link>
                </div>
              </div>
            )}

            {showKeypad && transfer && <TransferBtnDiv />}
            {showKeypad && conference && <ConferenceBtnDiv />}
          </>
        )}
      </div>
    </div>
  );
};

export default IdleFrame;
