import React, { useEffect } from "react";

function Dialler(props) {
  const { show, handleClose } = props;
  useEffect(() => {
    window.$("#diallerModal").modal("hide");
    handleClose();
  }, []);
  useEffect(() => {
    if (props.show) {
      window.$("#diallerModal").modal("show");
    } else {
      window.$("#diallerModal").modal("hide");
      handleClose();
    }
  }, [show]);

  return (
    <div
      className={`modal`}
      id="diallerModal"
      aria-labelledby="exampleModalLabel"
      style={{ backgroundColor: "transparent" }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content bg-transparent dialer-cust-modal">
          <div className="modal-body dailer-mobile">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default Dialler;
