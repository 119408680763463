import * as constants from "../Constants/signupConstants";

 

const initalState = {  
    available_city_list:[],
    available_did_numbers:[],
    choosen_number_info:{},
    plans_list:[],
    currency_list:[],
    payment_token:'',
    payment_info:{},
    rica_company_verification:{},
    rica_porting_process:{},
    google_maps_address:{},
    calculated_plan_summary:{}

}

const signupReducer = (state = initalState, action) => {
    switch (action.type) {
        case constants.AVAILABLE_CITY_LIST: 
            return {
                ...state,
                available_city_list: action.payload,
            };
        case constants.AVAILABLE_DID_NUMBERS:
            return {
                ...state,
                available_did_numbers: action.payload,
            };
        case constants.CHOOSEN_NUMBER_INFO:
            return {
                ...state,
                choosen_number_info: action.payload,
            };
        case constants.PLANS_LIST:
            return {
                ...state,
                plans_list: action.payload,
            };
        case constants.FETCH_CURRENCY_LISTING:
            return {
                ...state,
                currency_list: action.payload,
            };
        case constants.PAYMENT_TOKEN:
            return {
                ...state,
                payment_token: action.payload,
            };
        case constants.PAYMENT_INFO: 
            return {
                ...state,
                payment_info: action.payload,
            };
        case constants.RICA_COMPANY_VERIFICATION:
            return {
                ...state,
                rica_company_verification: action.payload,
            };
        case constants.RICA_PORTING_PROCESS:
            return {
                ...state,
                rica_porting_process: action.payload,
            };
        case constants.GOOGLE_MAP_ADDRESS:            
            return {
                ...state, 
                google_maps_address:action.payload
            }
        case constants.GET_CALCULATED_PLAN_SUMMARY:            
            return {
                ...state, 
                calculated_plan_summary:action.payload
            }
        default:
            return state
    }
}

export default signupReducer;
