import { apiAdminUrl, apiBaseUrl, WebDomain } from "../../Utils/Common";
import * as constants from "../Constants/userConstants";
import * as ACTIONS from "./../Constants/apiConstants.js";
import swal from "sweetalert";
import { store } from "../../Services";
import authService from "../../Utils/authService";
import {getMediaDownloadUrl} from "./settingActions";

export const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401 || response.status === 400) {
        swal({
          title: "Expired!",
          text: "Your session is expired!",
          icon: "error",
          closeOnClickOutside: false,
        }).then(async (value) => {
          if (value) {
            localStorage.removeItem("token");
            window.location.href = `https://${WebDomain}`;
          }
        });
      }
      const error =
        (data && data.message) ||
        (data && data.ResponseText) ||
        (data && data.ExceptionMessage) ||
        (data && data.Message) ||
        response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
};

// SHOW LOADER ACTIONS

export function ShowLoader(value) {
  return (dispatch) => {
    dispatch({ type: constants.SHOW_LOADER, payload: value });
  };
}

export const toastr = (data) => {
  return (dispatch) => {
    dispatch({ type: constants.TOASTR, payload: data });
    return 1;
  };
};

// GET NXATEL USER DETAILS

export const FetchUserDetails = (dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}${ACTIONS.USERPANEL}user-detail`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": `${localStorage.getItem("token")}`,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then(async(data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") { 
        const {data : info} = data 
        const {userDetail:{app_picture}} = info 
        dispatch({
          type: constants.FETCH_USER_DETAILS,
          payload: info,
        });
        if(app_picture){
          const url = await getMediaDownloadUrl(app_picture);
          info.userDetail.profile_picture = url
          dispatch({
            type: constants.FETCH_USER_DETAILS,
            payload: {...info},
          });
        } 
        return 1;
      } else if (data.ResponseCode === "0" || data.ResponseCode === "2") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: constants.FETCH_USER_DETAILS, payload: {} });
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};

export const UpdateCustomerCallerID = (params,dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}${ACTIONS.USERPANEL}update-callerID`, {
    method: "POST",
    body:JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": `${localStorage.getItem("token")}`,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") { 
        return 1;
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => { 
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};

export const naviagteToSetting = (data,dispatch) => { 
  dispatch({ type: constants.NAVIGATE_TO_SETTING, payload: data }); 
};

export const FetchBillingPlan = (data,dispatch)=>{
  dispatch({ type: constants.FETCH_BILLING_PLAN, payload: data });
}

export const ForceOpenDialPad = (data, dispatch) => {
  dispatch({
    type: constants.FORCE_OPEN_DIALPAD,
    payload: data,
  });
};

export const AccountSummary = (dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}${ACTIONS.USERPANEL}${ACTIONS.ACCOUNT_SUMMARY}`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return { code: 1, data: data.data };
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return { code: 0 };
      }
    });
};

export const AvailabilityStatus = (dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}userPanel/availability-status-listing`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_STATUS_LIST,
          payload: data.data,
        });
        return 1;
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch(() => {
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      return 0;
    });
};

export const UpdateAvailabilityStatus = (status, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}userPanel/update-availability-status`,
    {
      method: "POST",
      body: JSON.stringify({ status }),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      if (data.ResponseCode === "1") {
        swal({
          title: "Success!",
          text: data.ResponseText,
          icon: "success",
        });
        return 1;
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch(() => {
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      return 0;
    });
};

export const logOut = (dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}${ACTIONS.USERPANEL}logout`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": `${localStorage.getItem("token")}`,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return 1;
      } else if (data.ResponseCode === "0" || data.ResponseCode === "2") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};


//   ******************************* GET USER CREDENTIALS *********************


export const getUserCredentials = (dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiAdminUrl}userPanel/user-credential`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_USER_CREDENTIALS,
          payload: data.data,
        });
        return 1;
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch(() => {
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      return 0;
    });
};

