import React, { useState } from "react";
import BusinessContact from "./BusinessContact";
import ContactSlider from "./ContactSlider"; 

function Business() {
    const [searchTerm, setSearchTerm] = useState("");
    const [editContact, setEditContact] = useState({});
    const [addContact, setAddContact] = useState(false);

    const getEditContactDetails = (value) => {
        setEditContact(value);
        setAddContact(true);
    };

    const closeAddContactSlider = () => {
        window.$(`#${editContact?.uuid}`).removeClass("show");
        setEditContact({});
        setAddContact(false);
    };

    return (
        <>
            <div className="col-md-9">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="appointment-tab">
                            <div className="right-side-top-header">
                                <h5>Business</h5>
                                <span className="filter-serach">
                                    <img
                                        src="./../assets/images/filter.svg"
                                        alt="1"
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filter business contact"
                                        name="searchTerm"
                                        value={searchTerm}
                                        onChange={(e) =>
                                            setSearchTerm(e.target.value)
                                        }
                                    />
                                </span>
                            </div>
                            <BusinessContact
                                layoutType={"list"}
                                searchTerm={searchTerm}
                                getEditContactDetails={getEditContactDetails}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ContactSlider
                addContact={addContact}
                closeAddContactSlider={closeAddContactSlider}
                renderPage={"business_personal"}
                _editContactDetails={editContact}
            />
        </>
    );
}

export default Business;
