import React, { useState, useRef, useEffect } from "react";
// import Modal from "./../Widgets/Modal"; 
// import { TagListing, AddTag } from "./../Call/call_actions";
import SimpleReactValidator from 'simple-react-validator';
import { useDispatch } from "react-redux";
import { AddTag } from "../../../../Services/Actions/callActions";
import Modal from "../../../../Component/Modal";
import { Link } from "react-router-dom";
import { store } from "../../../../Services";

interface TagType {
    open: Boolean;
    handleClose: () => void;
    userObj?: Object;
}
const TagModal: FC<TagType> = (props) => {
    const { open, handleClose, userObj } = props;
    const [colorCode, setColorCode] = useState("#1569a8")
    const [tagName, setTagName] = useState("")
    const [uniquecode, setUniquecode] = useState(null);
    const [, forceUpdate] = useState()
    const validator: React.MutableRefObject<any> = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }));
    const dispatch: Dispatch<any> = useDispatch();

    useEffect(():void=>{
        if(Object.keys(userObj).length){
            setUniquecode(userObj.uniquecode);
            setColorCode(userObj.color_code);
            setTagName(userObj.name);
        }else{
            setUniquecode(null);
            setColorCode("#1569a8");
            setTagName("");
        }
    },[userObj])

    const SaveTagHandler = async () => {
        if (validator.current.allValid()) {
            console.log("outside")
            let res = await AddTag({ color_code: colorCode, tag_name: tagName,uniquecode },store.dispatch)
            console.log("res",res)
            if (res === 1) { 
                handleClose();
            }
        }
        else {
            validator.current.showMessages()
            forceUpdate(1)
        }
    }

    return (
        <div>
            <Modal
                open={open}
                size={`md`}
                CloseModal={handleClose}
                title={`${Object.keys(userObj).length ? "Edit Tag" : "Add Tag"}`}
                showActiveClass={open}
            > 
                    <React.Fragment>
                        <div className="add-color-input">
                            <input
                                className="form-control"
                                type='text'
                                name="tagName"
                                value={tagName}
                                onChange={e => setTagName(e.target.value)}
                                placeholder="Enter Tag Name"
                            />
                            <label>
                                <span className="color-circle" style={{ background: colorCode }} />
                                <input
                                    type="color"
                                    value={colorCode}
                                    onChange={e => setColorCode(e.target.value)}
                                    className="hidden-input"
                                />
                            </label>
                            {validator.current.message("Tag Name", tagName, "required")}
                        </div>
                        <div className="assign-modal-blank">
                            <Link
                                to="#"
                                className="add-team-member"
                                style={{ cursor: "pointer" }}
                                onClick={SaveTagHandler}
                            >
                                Save Tag
                            </Link>
                        </div>
                    </React.Fragment> 
            </Modal>
        </div>
    );
}
export default TagModal;
