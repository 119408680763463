export const AVAILABLE_CITY_LIST        =           "AVAILABLE_CITY_LIST"
export const AVAILABLE_DID_NUMBERS      =           "AVAILABLE_DID_NUMBERS"
export const CHOOSEN_NUMBER_INFO        =           "CHOOSEN_NUMBER_INFO"
export const PLANS_LIST                 =           "PLANS_LIST"
export const FETCH_CURRENCY_LISTING     =           "FETCH_CURRENCY_LISTING"
export const PAYMENT_TOKEN              =           'PAYMENT_TOKEN'
export const PAYMENT_INFO               =           'PAYMENT_INFO'
export const RICA_COMPANY_VERIFICATION  =           'RICA_COMPANY_VERIFICATION'
export const RICA_PORTING_PROCESS       =           "RICA_PORTING_PROCESS"
export const GOOGLE_MAP_ADDRESS         =           'GOOGLE_MAP_ADDRESS';



export const GET_CALCULATED_PLAN_SUMMARY    =       "GET_CALCULATED_PLAN_SUMMARY"
