import React, { Component } from "react"; 
import moment from "moment";
import { store } from "../../../../Services";
import { BusinessHourListing, DeleteBusinessHour } from "../../../../Services/Actions/settingActions";
import { convertUtcToLocalTime, formatDIDNumber } from "../../../../Utils/Common";
import AddBusinessHour from "./AddBusinessHour";
import { connect } from "react-redux"; 
import swal from "sweetalert";
import Pagination from "../../../../Component/Pagination";

class BusinessHour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addBusinessHour: false,
            isEdit:false,
            businessData:{},
            pageOfItems:[],
            totalItems:[],
            search:""
        };
    }

    componentDidMount= async()=>{
         BusinessHourListing(0,store.dispatch) 
    }
 
    componentDidUpdate(prevProps) {
        const { businesshour_listing } = this.props;   
        if (prevProps.businesshour_listing !== businesshour_listing) {
          this.setState({ totalItems: businesshour_listing });
        }
      }

    onSearch = (e) => {
        const { businesshour_listing } = this.props; 
        const value = e.target.value.toString().toLowerCase();
        this.setState({
          search: value,
          totalItems: businesshour_listing.filter(
            (val) => 
              val.name.toLowerCase().includes(value)
          ),
        });
      };

    sliceData = pageOfItems => {
        this.setState({ pageOfItems });
    }

    closeBusinessHour = () => {
        this.setState({
            addBusinessHour: false,
        });
    };


    ViewBusinessHour = (value) => {
        this.setState({
            isEdit: true, 
            addBusinessHour:true,
            businessData: value,
        });
    };

    DeleteBusinessHourHandler = (businessID) => {  
        swal({
            text: "Are you sure want to delete this business hour?",
            buttons: true,
            icon: "warning",
            title:"Warning!",
            dangerMode: true,
        }).then(async(willDelete) => {
            if (willDelete) {
                DeleteBusinessHour(businessID,store.dispatch)  
            } else {
                console.log("you clicked on false");
            }
        });
    };

    render() {
        const { addBusinessHour ,isEdit ,businessData,pageOfItems,totalItems,search} = this.state; 
        const { SetTab } = this.props;
        return (
            <>
                <div className="phone-system-inner-main-div">
                    <div className="phone-system-inner-div">
                        <div className="phone-system-inner-head">
                            <div className="ps-inner-head-left">
                                <h3>
                                    <span 
                                        style={{cursor:'pointer'}}
                                        onClick={()=>SetTab("phone")}
                                        className="phone-system-name"
                                    >
                                        Phone Settings
                                    </span>
                                    <img
                                        src="./../assets/images/menu-arrow-up.svg"
                                        alt="1"
                                    />
                                    <span>Business Hours</span>
                                </h3>
                            </div>

                            <div className="ps-inner-head-right">
                                <span className="search-ps-inner">
                                    <img
                                        src="./../assets/images/search-dash.svg"
                                        alt="1"
                                    />
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="search"
                                        value={search}
                                        onChange={this.onSearch}
                                        placeholder="Search"
                                    />
                                </span>
                                <a
                                    href={void 0}
                                    onClick={() =>
                                        this.setState({ addBusinessHour: true,isEdit:false,businessData:{} })
                                    }
                                    style={{ cursor: "pointer" }}
                                    className="create-ps-num-btn"
                                >
                                    Add Business Hours
                                </a>
                            </div>
                        </div>
                        {/* table start */}
                        <div className="phone-system-inner-table">
                            <div className="table-responsive call-info-scroll single-table-height">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Creation Date</th>
                                            <th>Name of Number</th> 
                                            <th>Assigned Numbers</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            pageOfItems && pageOfItems.map((value,index)=>{
                                                let date_time = convertUtcToLocalTime(value.creationdate)
                                                let formattedDate = moment(date_time).locale("en").format("ll");
                                                let formattedTime = moment(date_time).locale("en").format("LT");
                                                return(
                                                    <tr key={index}>
                                                        <td>{`${formattedDate} ${formattedTime}`}</td>
                                                        <td>
                                                            <span className="business-hrs-service">
                                                                <h3>
                                                                   {value.name}
                                                                </h3> 
                                                            </span>
                                                        </td> 
                                                        <td className="assign-to-add"> {formatDIDNumber(value.did_assigned)}</td>
                                                        <td>
                                                            <a 
                                                                className="edit-business-hrs" 
                                                                title="Edit Business Hours"
                                                                onClick={()=>this.ViewBusinessHour(value)}
                                                            >
                                                                <img
                                                                    src="./../assets/images/edit-new.svg"
                                                                    alt="1"
                                                                />
                                                            </a>
                                                            &nbsp;&nbsp;
                                                            <a 
                                                                className="edit-business-hrs" 
                                                                title="Delete Business Hours"
                                                                onClick={()=>this.DeleteBusinessHourHandler(value.id)}
                                                            >
                                                                <img src="./../assets/images/trash-2.svg" alt="delete" />
                                                            </a> 
                                                        </td>
                                                    </tr>
                                                )
                                            }) 
                                        } 
                                    </tbody>
                                </table>
                            </div>
                            <Pagination data={totalItems} currentData={this.sliceData}  />
                        </div>
                    </div>
                </div> 
                <AddBusinessHour
                    addBusinessHour={addBusinessHour}
                    businessData={businessData}
                    closeBusinessHour={this.closeBusinessHour}
                    isEdit = {isEdit}
                /> 
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    businesshour_listing: state?.setting?.businesshour_listing,
});

export default connect(mapStateToProps)(BusinessHour); 
