import * as constants from "../Constants/powerappConstants";

 

const initalState = {   
    navigate_to_power_apps:{},
    documents_listing:[]
}

const powerappReducer = (state = initalState, action) => {
    switch (action.type) {
        case constants.NAVIGATE_TO_POWER_APPS:   
            return {
                ...state,
                navigate_to_power_apps: action.payload,
            }; 
        case constants.CLOUD_NOTES_LISTING:
            return {
                ...state,
                cloud_notes_listing: action.payload,
            };
        case constants.DOCUMENTS_LIST:
            return {
                ...state,
                documents_listing: action.payload,
                }; 
        default:
            return state
    }
}

export default powerappReducer;
