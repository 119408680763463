import React from "react";
import { withRouter } from "../../Utils/withRouter";

function PlanDetail(props) {
  const {
    plans_list,
    OnSelectPlanHanlder,
    planID,
    onBuyNow,
    selected_currency,
    setDemoPlan,
    duration, 
  } = props;

  const AddTrial = (value) => {
    OnSelectPlanHanlder(value);
  };

  return (
    <div className="container">
      <div className="pricing pricing-bx pricing-palden pricing-palden-signup">
        {plans_list instanceof Array &&
          plans_list.map((value, key) => {
            let planCost = JSON.parse(value.plan_cost);
            if (value.name.includes("Enterprise")) {
              return (
                <div
                  className={`pricing-item justify-content-between ${
                    value.planID === planID ? "pricing__item--featured" : ""
                  }`}
                  onClick={() => OnSelectPlanHanlder(value)}
                  key={key}
                >
                  <div className="pricing-min-ht">
                    <div className="pricing-deco">
                      <div className="pricing-price">
                        <h6 className="fw-700 start-up-head color-66  mb-2">
                          {value.name}
                        </h6>
                      </div>
                      <p className="font-16 fw-400 color-66  mb-1">
                        All our enterprise-ready features.
                      </p>
                      <p className="ideal-text">
                        Ideal for Medium and Large teams
                      </p>
                      <h5 className="team-size ">
                        Team Size: {`${value.max_user}`}
                      </h5>
                      <h6 className="every-text">
                        Everything in Professional, PLUS
                      </h6>
                      <h6 className="every-text">Custom Pricing</h6>
                    </div>
                    <ul className="pricing-feature-list">
                      <li className="pricing-feature custom-pricing-feature">
                        <h6 className="font-20 color-66 fw-700 mb-0">
                          Customization Available
                        </h6>
                        <p className="font-16 mt-2 color-66  mb-4">
                          Trial Available
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            }
            return (
              <div
                key={`plans_${key}`}
                className={`pricing-item justify-content-between ${
                  value.planID === planID ? "pricing__item--featured" : ""
                }`}
                onClick={() =>
                  value.planID === 1
                    ? AddTrial(value)
                    : OnSelectPlanHanlder(value)
                }
              >
                <div className="pricing-min-ht">
                  <div className="pricing-deco">
                    <div className="pricing-price">
                      {value.name.includes("Professional") && (
                        <span className="recomended-text">Recommended</span>
                      )}
                      <h6 className="fw-700 start-up-head color-66   mt-3 mb-0">
                        {value.name}
                      </h6>
                      <p className=" pay-as-you start-up-head color-66">
                        {`${
                          value.name.includes("Start Up")
                            ? "(Pay as you Go)"
                            : ""
                        }`}
                      </p>
                    </div>
                    <div className="price-div-2">
                      <span className="pricing-currency color-3b">
                        {selected_currency === "USD" ? "$" : "R"}
                      </span>
                      {duration === 1 && (
                        <span className="color-b3 font-42 fw-400 cost-span">
                          {selected_currency === "USD"
                            ? planCost.$
                            : selected_currency === "ZAR"
                            ? planCost.R
                            : "00"}
                        </span>
                      )}
                      {duration === 12 && (
                        <span className="color-b3 font-42 fw-400 cost-span">
                          {selected_currency === "USD"
                            ? parseInt(planCost.$ * 12)
                            : selected_currency === "ZAR"
                            ? parseInt(planCost.R * 12)
                            : "00"}
                        </span>
                      )}
                      {duration === 24 && (
                        <span className="color-b3 font-42 fw-400 cost-span">
                          {selected_currency === "USD"
                            ? parseInt(planCost.$ * 24)
                            : selected_currency === "ZAR"
                            ? parseInt(planCost.R * 24)
                            : "00"}
                        </span>
                      )}
                    </div>
                    <h3 className="pricing-title">per user/per mo</h3>.
                    <h5 className="team-size ">
                      Team Size: {`${value.min_user}-${value.max_user}`}
                    </h5>
                    <p className="ideal-text">
                      {value.name === "Start Up"
                        ? "Ideal for Start-up’s or Small teams"
                        : value.name === "Scale Up"
                        ? "Ideal Small teams"
                        : value.name === "Professional"
                        ? "Ideal for Small  or Medium teams"
                        : ""}
                    </p>
                    <h6 className="every-text">
                      {value.name === "Start Up"
                        ? "Ideal for Start-up’s or Small teams"
                        : value.name === "Scale Up"
                        ? "Everything in Start-Up, PLUS"
                        : value.name === "Professional"
                        ? "Everything in Scale-Up, PLUS"
                        : ""}
                    </h6>
                  </div>
                  <ul className="pricing-feature-list pricing-feature-list-new">
                    <li>
                      <i className="fas fa-check"></i>
                      {`${
                        value.name.includes("Professional")
                          ? "Business Landline or toll free numbers"
                          : "Business Landline or National numbers"
                      }`}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                        {` 
                            ${
                                value.voice_minute
                            } Call Anywhere minutes
                            ${
                                value.name !== "Professional"
                                ? "(Local Country)"
                                : ""
                            }
                        `}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      {`Host up to ${value.max_user} participants`}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      {`${
                        value.name.includes("Start Up")
                          ? `Group meetings for up to ${value.video_calling_minutes} minutes`
                          : `Video Calling ${value.video_calling_minutes} minutes`
                      }`}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      {`${
                        value.name.includes("Start Up")
                          ? "Unlimited Team Calling, Video Meeting & Messaging"
                          : "Unlimited Team Calling & Messaging"
                      }`}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      Business SMS *
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      {`Cloud Storage ${value.storage}`}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      Mobile & Web App
                    </li>
                  </ul>
                </div>
                <div
                  className={`d-flex justify-content-around ${
                    value.planID === planID ? "mt-3" : ""
                  }`}
                >
                  {value.planID === 1 && (
                    <button
                      className={`pricing-action  d-none d-md-block `}
                      onClick={(e) =>
                        value.planID === 1
                          ?( value.planID === planID
                            ? onBuyNow(value, "trial")
                            : AddTrial(value))
                          : setDemoPlan(value.name)
                      }
                    >
                      Trial
                    </button>
                  )}

                  <button
                    className="pricing-action d-none d-md-block"
                    onClick={() =>
                      value.planID === planID
                        ? onBuyNow(value)
                        : OnSelectPlanHanlder(value)
                    }
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default withRouter(PlanDetail);
