import React, { Component } from "react";
import { store } from "../../Services";
import {
  ProblemCategorySkillsList,
  SendSupportFeedback,
  ZohoAccessToken,
} from "../../Services/Actions/supportActions";
import authService from "../../Utils/authService";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import Select from "react-select";

const initialState = {
  name: "",
  problem_category: "",
  description: [],
  problemCategories: [],
};

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.Validator = new SimpleReactValidator();
  }

  componentDidMount = async () => {
    let access_token = await ZohoAccessToken(store.dispatch);
    if (!access_token) {
      return false;
    }
    let response = await ProblemCategorySkillsList(
      { access_token },
      store.dispatch
    );
    if (response?.Data?.success) {
      this.setState({
        problemCategories: response?.Data?.data,
      });
    }
    let myInfo = authService.getUserDetail();
    let name = myInfo ? `${myInfo.firstname} ${myInfo.lastname}` : "";
    this.setState({
      name,
      access_token,
      email: myInfo?.email ?? "",
      phone: myInfo?.phone ?? "",
    });
  };

  SubmitHandler = async () => {
    const { name, problem_category, description, email, phone, subject } =
      this.state;
    if (this.Validator.allValid()) {
      let access_token = await ZohoAccessToken(store.dispatch);
      if (!access_token) {
        return false;
      }
      let problems_description = [];
      description &&
        description.map(({ value }) => {
          problems_description.push({ value });
        });
      let data = {
        entitySkills: problem_category,
        subject,
        description: problems_description,
        phone,
        email,
        name,
        access_token,
      };
      let support_response = await this.props.dispatch(
        SendSupportFeedback(data)
      );
      if (support_response === 1) {
        swal({
          title: "Success!",
          text: "Feedback sent successfully",
          icon: "success",
        });
        this.setState({
          description: "",
          subject: "",
        });
      }
    } else {
      this.Validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = (selectedOption) => {
    this.setState({
      description: selectedOption,
    });
  };

  render() {
    const { name, problem_category, description, problemCategories } =
      this.state;
    return (
      <div className="card discover-inner">
        <div className="feed-back tab-pane-scroll mb-0 height-auto">
          <div className="feedback-inner">
            <h5 className="font-18 color3B">
              Get help from a Nxatel Support Specialist
            </h5>
            <h6 className="color3B font-14">
              Get help with Nxatel product- including Calls, Meetings, Chats,
              Internet, Billing and much more. All our emails have a 24 hours
              turnaround, our support hours are 7am till 7pm daily.
            </h6>
          </div>
          <hr></hr>
          <div className="feedback-bottom">
            <div className="row form-group">
              <div className="col-md-6">
                <div className="">
                  <label htmlFor="exampleFormControlInput1" className="color3B">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control color3B pt-0"
                    id="exampleFormControlInput1"
                    name="name"
                    value={name}
                    onChange={(e) =>
                      this.setState({
                        name: e.target.value,
                      })
                    }
                  />
                  {this.Validator.message("Name", name, "required")}
                </div>
              </div>
              <div className="col-md-6">
                <div className="doctor-setp-mid">
                  <label
                    htmlFor="exampleFormControlSelect1"
                    className="color3B"
                  >
                    Choose problem category
                  </label>
                  <Select
                    isMulti
                    isSearchable
                    name="problem_category"
                    value={problem_category}
                    placeholder="--- Choose problem category ---"
                    onChange={this.handleChange}
                    defaultValue={problem_category}
                    options={
                      problemCategories &&
                      problemCategories.map((value) => ({
                        label: value.description,
                        value: value.id,
                      }))
                    }
                  />
                  {this.Validator.message(
                    "Problem category",
                    problem_category,
                    "required"
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="color3B"
                  >
                    Add detailed Description
                  </label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="7"
                    name="description"
                    value={description}
                    onChange={(e) =>
                      this.setState({
                        description: e.target.value,
                      })
                    }
                  ></textarea>
                  {this.Validator.message(
                    "Description",
                    description,
                    "required"
                  )}
                </div>
              </div>
            </div>

            <h6 className="color3B font-14">
              If you have any screenshots that help illustrate the issue, please
              attach them using Choose file below.
            </h6>

            <div className="chose-file-btn d-flex mt-4">
              <label>
                {" "}
                Enter Your File
                <input type="file" size="60" />
              </label>
              <h6 className="color3B font-14 align-self-center mb-0">
                No file choosen
              </h6>
            </div>
            <p className="btn btn-orange float-left">
              <a herf={void 0} onClick={this.SubmitHandler}>
                Save
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Feedback;
