import React from "react";
import moment from "moment";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { store } from "../../Services";
import { convertUtcToLocalTime, IsJsonString } from "../../Utils/Common";
import { DeleteNotification } from "../../Services/Actions/notificationActions";

function NotificationList({ notification_listing, show, navigateTo, status }) {
  const deleteNotificationHandler = (value) => {
    swal({
      icon: "warning",
      title:"Warning!",
      text: "Are you sure want to delete this notification?",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        DeleteNotification(
          { id: value?.id, type: value?.type },
          store.dispatch
        );
      } else {
        console.log("you clicked on false");
      }
    });
  };
  return (
    <div
      className={`dropdown-menu ${show ? "show" : ""}`}
      id="notification_popup"
      aria-labelledby="navbarDropdown-notifications"
    >
      <div className="notification-drop-heading">
        <h3>NOTIFICATIONS</h3>
      </div>
      <div className="notification-list-div">
        {notification_listing && notification_listing.length > 0 ? (
          notification_listing.slice(0,10).map((value, key) => {
            let parsedObj = IsJsonString(value.content)
              ? JSON.parse(value.content)
              : {}; 
            let message =
              value.type === "meeting"
                ? `You're invited in a meeting!`
                : parsedObj.message;
            let time =
              value.type === "meeting"
                ? `${parsedObj.date} ${parsedObj.start_time}`
                : value.creationdate;
            return (
              <a className="dropdown-item" key={`not${key}`}>
                <div className="notification-drop-list">
                  <div className="notifiaction-ico">
                    <img
                      src={`./../assets/images/${
                        value.type === "Payment"
                          ? "payment-credit-card.svg"
                          : value.type === "missed_call"
                          ? "phone-missed.svg"
                          : "calendar-call-info.svg"
                      }`}
                      alt="1"
                    />
                  </div>
                  <div className="notifiaction-content">
                    <div className="notificaion-flx-div">
                      <span className="notifiaction-name">
                        <h4>{value.title}</h4>
                      </span>
                      <span className="sent-message-text">
                        {/* Sent you a message */}
                      </span>
                    </div>
                    <div className="notification-text-full">
                      <p>{message}</p>
                    </div>
                    <div className="notificaion-flx-div">
                      <span className="notifiaction-name">
                        {/* <p>Started</p>   */}
                      </span>
                      <span className="sent-message-text">
                        {moment(convertUtcToLocalTime(time))
                          .locale("en")
                          .calendar()}
                      </span>
                    </div>
                  </div>
                  <div className="notifiaction-action">
                    <div className="notification-action-drop dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id={`navbarDropdown-action${key}`}
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="./../assets/images/dot-action.svg"
                          alt="1"
                        />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby={`navbarDropdown-action${key}`}
                      >
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={() => deleteNotificationHandler(value)}
                        >
                          Delete
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            );
          })
        ) : (
          <a className="dropdown-item">
            <div className="notification-drop-list">
              <div className="notifiaction-content">
                <div className="notificaion-flx-div">
                  <span className="notifiaction-name">
                    <h4>No notification available...</h4>
                  </span>
                </div>
              </div>
            </div>
          </a>
        )}
      </div>
      {status === "A" && (
        <div className="notification-send-feedback">
          <Link to="/settings" onClick={navigateTo}>
            See All
          </Link>
        </div>
      )}
    </div>
  );
}

export default NotificationList;
