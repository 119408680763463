import React from "react";
import moment from "moment";

function Notes(props) {
  const { notes_list, ShowNotes } = props;
  return (
    <div className="notes-bottom dialer-notes-screen pt-3 dialler-notes tab-pane-scroll">
      {notes_list.length > 0 ? (
        notes_list.map((value, key) => {
          return (
            <div
              className="d-flex border-bottom cards payment_box"
              key={key}
              onClick={() => ShowNotes(value.notes)}
            >
              <img src="./../assets/images/info/cil_notes2.svg" alt="1" />
              <div className="align-self-center">
                <h6 style={{ fontSize: "14px", textAlign: "start" }}>
                  {value.notes.substring(0, 20)}
                </h6>
                <p className="mb-0">
                  {moment(value.creationdate).locale("en").format("lll")}
                </p>
              </div>
            </div>
          );
        })
      ) : (
        <div className="col-md-12 mt-2">No record found...</div>
      )}
    </div>
  );
}

export default Notes;
