import React from "react";
import Linkify from "react-linkify"; 
import moment from "moment";
import $ from "jquery";
import { store } from "../Services";
import { ShowLoader, toastr } from "../Services/Actions/userActions";
import * as constants from "../Services/Constants/userConstants";

const host                    =     window.location.origin;

export const SiteUrl          =     "https://dev.nxa.tel/";

export const WebDomain        =     "dev.nxa.tel";

export const AppLogin         =     window.location.hostname === "localhost" ? 
                                    "http://localhost:3000/login-via-token/" : 
                                    "https://app-dev.nxa.tel/login-via-token/"; 

export const MeetDomain       =     "meet-dev.nxa.tel"; 

export const AppDomain        =     window.location.hostname === "localhost"  ? "localhost:3000" : "app-dev.nxa.tel";  

export const CardDomain       =     "https://test.oppwa.com";

export const TRANSLATEAPIKEY  =     "AIzaSyDt-3XHXFklIVB3AXV92YASo2y5uXq8yzc"; 

export const translateApiUrl  =     `https://translation.googleapis.com/language/translate/v2?key=${TRANSLATEAPIKEY}`;

export const get_a_demo       =     "https://nxatel-product-demo.zohobookings.com/portal-embed#/customer/demobookings";

export const ReturnBaseUrl    =     "https://pay-dev.nxa.tel/WebForms/PeachPayResponse.aspx";

export const PaymentDomain    =     "https://pay-dev.nxa.tel/"; 

export const apiBaseUrl       =     "https://api-dev.nxa.tel/"; 

export const apiAdminUrl      =     "https://admin-api-dev.nxa.tel/";

export const apiMediaUrl      =     "https://media-api-dev.nxa.tel/"; 

export const accountBaseUrl   =     "https://account-dev.nxa.tel/";
  
 

export const formatDate = (date) => {
  let newDate =
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "/" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "/" +
    date.getFullYear();
  return newDate;
}; 

export const TextSubstring = (text, length, startPoint = 0) => {
  let newText = text.length > length ? `${text.substring(startPoint, length)}...` : text.substring(startPoint, length);
  return newText;
}

export const timezone_list = [
  {gmtzone:"(GMT +02:00) South Africa Standard Time (Africa/Johannesburg)", gmtname:"Africa/Johannesburg"},
  {gmtzone:"(GMT +02:00) South Africa Standard Time (Africa/Maseru)" ,gmtname:"Africa/Maseru"},
  {gmtzone:"(GMT +02:00) South Africa Standard Time (Africa/Mbabane)", gmtname:"Africa/Mbabane"},
  {gmtzone:"(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",gmtname:"Asia/Kolkata"}
]  
 

export function convert24hrsTO12hrs (time) { 
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join (''); // return adjusted time or original string
} 
 

/*
For check user internet speed
*/
const testUploadSize = 2000000;

const randomString = (length) => {
  var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const TestUpload = () => {
  return new Promise((resolve, reject) => {
    const randData = {
      image: `data:image/png;base64,${randomString(testUploadSize)}`,
    };
    const uploadSize = testUploadSize;
    const sendDate = new Date().getTime();
    $.ajax({
      type: "POST",
      url: `${apiBaseUrl}upload/st`,
      data: randData,
      timeout: 60000,
      cache: false,
      success: () => {
        const receiveDate = new Date().getTime();
        const duration = (receiveDate - sendDate) / 1000;
        const bitsLoaded = uploadSize * 8;
        const speedBps = (bitsLoaded / duration).toFixed(2);
        const speedKbps = (speedBps / 1024).toFixed(2);
        const speedMbps = (speedKbps / 1024).toFixed(1);
        // const speedGbps = (speedMbps / 1024).toFixed(2);

        return resolve(speedMbps);
      },
      complete: (xhr, status, error) => {
        if (status === "timeout") {
          return resolve(0);
        }
        return reject(error);
      },
    });
  });
};

const imageAddr = `${host}/st.png`;
const downloadSize = 4995374; //bytes

export const downloadSpeed = () => {
  return new Promise((resolve, reject) => {
    window.setTimeout(() => {
      let startTime, endTime;
      const download = new Image();
      download.onload = () => {
        endTime = new Date().getTime();
        resolve(showResults());
      };

      download.onerror = (err, msg) => {
        reject(err);
      };

      startTime = new Date().getTime();
      let cacheBuster = "?nnn=" + startTime;
      download.src = imageAddr + cacheBuster;

      function showResults() {
        const duration = (endTime - startTime) / 1000;
        const bitsLoaded = downloadSize * 8;
        const speedBps = (bitsLoaded / duration).toFixed(2);
        const speedKbps = (speedBps / 1024).toFixed(2);
        const speedMbps = (speedKbps / 1024).toFixed(1);
        return speedMbps;
      }
    }, 1);
  });
};

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (name) => {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const manageCookieUser = (data) => {
  let dataArr = [];
  if (getCookie("login_as_guest")) {
    let dataArr = JSON.parse(getCookie("login_as_guest"));
    const existUserIndex = dataArr
      .map(function (e) {
        return e.email;
      })
      .indexOf(data.email);
    if (existUserIndex !== -1) {
      dataArr[existUserIndex] = data;
      let filterUser = dataArr
        .map((elem, i, arr) => {
          if (arr.map((v) => v.email).indexOf(elem.email) === i) {
            return elem;
          }
        })
        .filter((v) => v);
      setCookie("login_as_guest", JSON.stringify(filterUser), "100");
    } else {
      if (dataArr.length < 3) {
        dataArr.push(data);
        setCookie("login_as_guest", JSON.stringify(dataArr), "100");
      } else {
        dataArr.shift(); //remove first user from array
        dataArr.push(data);
        setCookie("login_as_guest", JSON.stringify(dataArr), "100");
      }
    }
  } else {
    dataArr.push(data);
    setCookie("login_as_guest", JSON.stringify(dataArr), "100");
  }
};
 
 

export const startHourOptions = () => (
  <React.Fragment>
    <option value="01">1</option>
    <option value="02">2</option>
    <option value="03">3</option>
    <option value="04">4</option>
    <option value="05">5</option>
    <option value="06">6</option>
    <option value="07">7</option>
    <option value="08">8</option>
    <option value="09">9</option>
    <option value="10">10</option>
    <option value="11">11</option>
    <option value="12">12</option>
  </React.Fragment>
);

export const startMinOptions = () => (
  <React.Fragment>
    <option>00</option>
    <option>05</option>
    <option>10</option>
    <option>15</option>
    <option>20</option>
    <option>25</option>
    <option>30</option>
    <option>35</option>
    <option>40</option>
    <option>45</option>
    <option>50</option>
    <option>55</option>
  </React.Fragment>
);

export const durationOptions = () => (
  <React.Fragment>
    <option value="15">15 Minutes</option>
    <option value="30">30 Minutes</option>
    <option value="60">1 Hour</option>
    <option value="120">2 Hours</option>
    <option value="240">4 Hours</option>
  </React.Fragment>
);

export const recurrenceOptions = () => (
  <React.Fragment>
    <option value="daily">Daily</option>
    <option value="weekly">Weekly</option>
    <option value="monthly">Monthly</option>
    <option value="yearly">Yearly</option>
  </React.Fragment>
);

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};


export const Nourishb64toBlob = (ImageURL) => {
  // Split the base64 string in data and contentType
  var block = ImageURL.split(";");
  // Get the content type of the image
  var contentType = block[0].split(":")[1];// In this case "image/gif"
  // get the real base64 content of the file
  var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

  // Convert it to a blob to upload
  return b64toBlob(realData, contentType);

}

export const dataType64toFile = (dataurl, filename = "NewFile") => {
  //Convert base64 to file
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  let newFile = new File([u8arr], filename, {
    type: mime,
  })
  return newFile
}

export const renderText = (message) => {
  return <Linkify>{message}</Linkify>;
};

export const renderPercentageUpload = (percentUploaded) => (
  <div className="progress">
    <div
      className="progress-bar progress-bar-success"
      role="progressbar"
      aria-valuenow={`${percentUploaded}`}
      aria-valuemin="0"
      aria-valuemax="100"
      style={{ width: `${percentUploaded}%` }}
    >
      {percentUploaded}%
    </div>
  </div>
); 

export const convertUtcToLocalTime = (utcTime) =>
  moment.utc(utcTime).local().format("YYYY-MM-DD HH:mm:ss");

const decodeHTML = (str) => {
  return str.replace(/&#([0-9]+);/g, function (full, int) {
    return String.fromCharCode(parseInt(int));
  });
};

export const textToSpeechPlay = (lang, message) => {
  var msg = new SpeechSynthesisUtterance();
  msg.text = decodeHTML(message);
  msg.lang = lang;
  speechSynthesis.speak(msg);
};

export function loadCssFile(filename, id) { 
  var fileref = document.createElement("link");
  fileref.rel = "stylesheet";
  fileref.type = "text/css";
  fileref.id = id;
  fileref.href = filename; 
  document.getElementsByTagName("head")[0].appendChild(fileref);
}

export const SecondsTohhmmss = (totalSeconds) => {
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds = totalSeconds - hours * 3600 - minutes * 60;
  // round seconds
  seconds = Math.round(seconds * 100) / 100;
  let result = hours < 10 ? "0" + hours : hours;
  result += ":" + (minutes < 10 ? "0" + minutes : minutes);
  result += ":" + (seconds < 10 ? "0" + seconds : seconds);

  let [hour, minute, second] = result.split(":");
  result = hour === "00" ? `${minute}:${second}` : result;
  return result;
};

export const ApiRequest = (params) => {
  {
    params.loader &&  ShowLoader(true) 
  }
  let headers = { "Content-Type": "application/json" };
  if (params.token) {
    headers = { ...headers, "X-Auth-Token": localStorage.getItem("token") };
  }
  let input = { method: params.method };
  if (params.body) {
    input = { ...params, body: JSON.stringify(params.body) };
  }
  input = { ...input, headers: new Headers(headers) };
  const request = new Request(`${apiBaseUrl}${params.path}`, input);
  // const result    = await fetch(request);
  return fetch(request).then(async (apiresponse) => {
    let response = await apiresponse.json();
    {
      params.loader &&
        setTimeout(() => {
           ShowLoader(false) 
        }, params.loader_time);
    }
    let status = apiresponse.status;
    if (apiresponse.ok) {
      if (response.ResponseCode === "0") {
        {
          params.alert &&
            toastr.error(response.ResponseText, { timeOut: 1500 });
        }
      }
      if (response.ResponseCode === "1") {
        {
          params.alert &&
            toastr.success(response.ResponseText, { timeOut: 1500 });
        }
        {
          params.reducer &&
            store.dispatch({ type: constants[params.reducer], payload: [] });
        }
        {
          params.reducer &&
            store.dispatch({
              type: constants[params.reducer],
              payload: response.data,
            });
        }
         ShowLoader(false) 
        if (params.isLogin) {
          if (
            params?.body?.token &&
            params.body.token === true &&
            response.token
          ) {
            window.location.href = `${AppLogin}${response.token}`;
          } else {
            let properties = {
              name: response.data.firstname,
              fullname: `${response.data.firstname} ${response.data.lastname}`,
            };
            localStorage.setItem("token", response.token);
            localStorage.setItem("type", response.data.type);
            localStorage.setItem("customerID", response.data.customerID);
            localStorage.setItem("properties", JSON.stringify(properties));
            console.error("🚀 ~ file: Common.js ~ line 402 ~ returnfetch ~ properties")
            localStorage.setItem("data", JSON.stringify(response.data));
          }
        }
      }
      return response;
    } else {
      if (status === 500 || status === 405) {
        {
          params.alert &&
            toastr.error("Internal Server error!", { timeOut: 1500 });
        }
        return status;
      }
      if (status === 401 || status === 400) {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = "/";
        return;
      }
      if (status !== 200) {
        {
          params.alert &&
            toastr.error("unknown error occured!", { timeOut: 1500 });
        }
        return status;
      }
    }
  });
};

export const apiClient = async (url = "", options = {}, type = null) => {
  return null;
};

export const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401 || response.status === 400) {
        localStorage.removeItem("token");
        window.location.href = "/";
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};

export const getTimezones = (offset) => {
  let allTimeZones = {
    3600: "(GMT+01:00) West Central Africa",
    19800: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "-28800": "(GMT-08:00) Pacific Time (US & Canada) Tijuana",
    "-25200": "(GMT-07:00) Mountain Time(US & Canada)",
    "-21600": "(GMT-06:00) Central Time (US & Canada)",
    "-18000": "(GMT-05:00) Eastern Time (US & Canada)",
  };
  return allTimeZones?.[offset] ?? "";
};

export const loadScript = (src, ID = "myscript") => {
  let script = document.createElement("script");
  script.src = src;
  script.id = ID;
  document.head.append(script);
};

export const getDateDifferenceWithToday = (date) => {
  let dateofvisit = moment(date, "YYYY-MM-DD");
  let today = moment();
  let days = today.diff(dateofvisit, "days");
  return days;
};


export const isValidHttpUrl = (string)=> {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
} 

export const IsJsonString = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const IdentifyLinkUrl=(str)=>{
  const identifyLink = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  if(identifyLink.test(str)){
      return true
  }else{
      return false
  }
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}


export const countryList = [
  { name: 'South Africa', country_image: 'south_africa.svg', country_code: 'ZA' },
  { name: 'Botswana', country_image: 'botswana.svg', country_code: 'BW' },
  { name: 'Kenya', country_image: 'kenya.svg', country_code: 'KE' },
  { name: 'Uganda', country_image: 'uganda.svg', country_code: 'UG' },
  { name: 'Zambia', country_image: 'zambia.svg', country_code: 'ZM' },
  { name: 'Zimbabwe', country_image: 'zimbabwe.svg', country_code: 'ZW' },
  { name: 'Rwanda', country_image: 'rwanda.svg', country_code: 'RW' },
]

export const countryPrefix = [
  { type: 'National', value: '2787', prefix: '87' },
  { type: 'Toll-Free', value: '27800', prefix: '800' },
  { type: 'Toll-Free', value: '27860', prefix: '860' },
  { type: 'Toll-Free', value: '27861', prefix: '861' },
]

export const NumberOfLinesPorting = [
  { text: "1 FREE PORT INCL", value: "1" },
  { text: "2-10", value: "2" },
  { text: "10-30", value: "10" },
  { text: "31-50", value: "31" },
  { text: "51-100", value: "51" },
  { text: "100+", value: "100" }
]


export const industryLists = ["Agriculture, plantations,other rural sectors","Basic Metal Production", "Chemical industries", "Commerce", "Education ","Financial services","professional services" ,"Food, drink, tobacco" ,"Forestry, wood, pulp and paper ","Health services" ,"Hotels, tourism,catering" ,"Information Technology","Mining (coal, other mining)" ,"Mechanical and electrical engineering" ,"Media; culture, graphical" ,"Oil and gas production, oil refining" ,"Postal and telecommunications services" ,"Public service" ,"Shipping, ports, fisheries, inland waterways","Textiles, clothing, leather, footwear ","Transport (including civil aviation, railways, road transport)" ,"Transport equipment manufacturing" ,"Utilities (water, gas, electricity)" ]

export const capitalizeFirstLetter = (string) => {
  string = string.trim()
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export const ScheduleTimeArray = [
  { time: '9:00 AM', _24hrformat: '9:00' },
  { time: '9:30 AM', _24hrformat: '9:30' },
  { time: '10:00 AM', _24hrformat: '10:00' },
  { time: '10:30 AM', _24hrformat: '10:30' },
  { time: '11:00 AM', _24hrformat: '11:00' },
  { time: '11:30 AM', _24hrformat: '11:30' },
  { time: '12:00 PM', _24hrformat: '12:00' },
  { time: '12:30 PM', _24hrformat: '12:30' },
  { time: '1:00 PM', _24hrformat: '13:00' },
  { time: '1:30 PM', _24hrformat: '13:30' },
  { time: '2:00 PM', _24hrformat: '14:00' },
  { time: '2:30 PM', _24hrformat: '14:30' },
  { time: '3:00 PM', _24hrformat: '15:00' },
  { time: '3:30 PM', _24hrformat: '15:30' },
  { time: '4:00 PM', _24hrformat: '16:00' },
  { time: '4:30 PM', _24hrformat: '16:30' },
  { time: '5:00 PM', _24hrformat: '17:00' },
  { time: '5:30 PM', _24hrformat: '17:30' },
  { time: '6:00 PM', _24hrformat: '18:00' },
]

export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const dateDiff = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  let diffTime = Math.abs(date2 - date1) / 1000;
  return diffTime;
} 


export const FetchPlanCostCalc = (data) => {
  const { user_allowed, duration, two_year_plan_discount, annual_plan_discount, plan_cost, currency } = data
  let amount = currency === "$" ? plan_cost && JSON.parse(plan_cost).$ : currency === "R" ? plan_cost && JSON.parse(plan_cost).R : ""
  amount = duration === 12 ? (amount * 12 * user_allowed) : duration === 24 ? (amount * 24 * user_allowed) : amount * user_allowed;
  amount = duration === 12 ? amount - (amount * annual_plan_discount) : (duration === 24 ? amount - (amount * two_year_plan_discount) : amount);
  let payable_amount = amount + (amount * REACT_APP_VAT)
  payable_amount = payable_amount ? payable_amount.toFixed(2) : payable_amount;
  let tax = (amount * REACT_APP_VAT).toFixed(2);
  let postData = { amount, payable_amount, tax }
  return postData;
};

export const FetchRenewalPlanCostCalc = (data) => {
  const { planCost, amount, plan_duration, usedPlanDays } = data
  // get days from the current plan duration
  let days = plan_duration === 1 ? 30 : plan_duration === 12 ? 365 : plan_duration === 24 ? 730 : ''
  // get current plan per day cost
  let perDayPlanCost = (parseFloat(amount / days)).toFixed(2)
  // get used current plan cost amount as per used plan days 
  let usedPlanCost = (parseFloat(perDayPlanCost * usedPlanDays)).toFixed(2)
  // get remaining cost from the current plan cost
  let remainingPlanCost = (parseFloat(amount - usedPlanCost)).toFixed(2)
  // check if ugrade plan cost is greater than remaining Plan Cost then take diff else 0
  let renewalPayableAmount = parseFloat(planCost) > parseFloat(remainingPlanCost) ? (parseFloat(planCost - remainingPlanCost)).toFixed(2) : 0.00
  return renewalPayableAmount;
};
 


export const formatPhone = phone => {
  var num;
  if (phone.indexOf('@')) {
      num = phone.split('@')[0];
  } else {
      num = phone;
  }
  num = num.toString().replace(/[^0-9]/g, '');
  if (num.length === 10) {
      return '(' + num.substr(0, 3) + ') ' + num.substr(3, 3) + '-' + num.substr(6, 4);
  } else if (num.length === 11) {
      return '(' + num.substr(0, 3) + ') ' + num.substr(3, 3) + '-' + num.substr(7, 5);
  } else {
      return num;
  }
}

export const formatDIDNumber = phone => {
  if(phone){
    var num;
    if (phone.indexOf('@')) {
        num = phone.split('@')[0];
    } else {
        num = phone;
    }
    num = num.toString().replace(/[^0-9]/g, '');
    if (num.length === 10) {
        return '(' + num.substr(0, 3) + ') ' + num.substr(3, 7);
    } else {
        return '+' + num.substr(0, 2) + num.substr(2, num.length - 1);
    } 
  } 
}


export const random128Hex = () => {
  function random16Hex() { return (0x10000 | Math.random() * 0x10000).toString(16).substr(1); }
  return random16Hex() + random16Hex() + "-" + random16Hex() + "-" + random16Hex() + "-" + random16Hex() + "-" + random16Hex() + random16Hex() + random16Hex();
}


export const randomStringFunc = (length, chars) => {
  var mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('@') > -1) mask += '-_.';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
  var result = '';
  for (var i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
  return result;
}


export const removeBackdrop = () => {
  $('body').removeClass('modal-open');
  $('.modal-backdrop').remove();
}

export const REACT_APP_VAT = 0.15; 

export const  sortNumericKeys = (item1: number, item2: number, order: 'ascending' | 'descending'): number => {
  if (order === "ascending") {
      if (item1 > item2) {
          return 1
      }
      else if (item1 < item2) {
          return -1
      }
      else {
          return 0
      }
  }
  else if (order === "descending") {
      if (item1 < item2) {
          return 1
      }
      else if (item1 > item2) {
          return -1
      }
      else {
          return 0
      }
  }
  throw 'Please mention ascending or descending order.'
}

