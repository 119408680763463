import React, { Component } from "react";

class PhoneSystem extends Component {
  render() {
    const { SetTab } = this.props;
    return (
      <>
        <div className="phoneSystemDiv phoneSystemDiv-new">
          <h3>Phone System</h3>
          <div className="row">
            <div className="col phoneSystemBXmain col-md-6">
              <div className="phoneSystemBX-outer">
                <div
                  className="phoneSystemBX cursor-pointer"
                  onClick={() => SetTab("numbers")}
                >
                  <div className="phoneSystemBX-left">
                    <h5>
                      <img src="./../assets/images/ps-number-ico.svg" alt="1" />
                      Numbers
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col phoneSystemBXmain col-md-6">
              <div className="phoneSystemBX-outer">
                <div
                  className="phoneSystemBX cursor-pointer"
                  onClick={() => SetTab("business-hours")}
                >
                  <div className="phoneSystemBX-left">
                    <h5>
                      <img
                        src="./../assets/images/ps-business-hours.svg"
                        alt="1"
                      />
                      Business Hours
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* row 2 */}
          <div className="row">
            <div className="col phoneSystemBXmain col-md-6">
              <div className="phoneSystemBX-outer">
                <div
                  className="phoneSystemBX cursor-pointer"
                  onClick={() => SetTab("tag-list")}
                >
                  <div className="phoneSystemBX-left">
                    <h5>
                      <img src="./../assets/images/ps_tags-fill.svg" alt="1" />
                      Call Tags
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col phoneSystemBXmain col-md-6">
              <div className="phoneSystemBX-outer">
                <div
                  className="phoneSystemBX cursor-pointer"
                  onClick={() => SetTab("sms")}
                >
                  <div className="phoneSystemBX-left">
                    <h5>
                      <img
                        src="./../assets/images/ps-message-ico.svg"
                        alt="1"
                      />
                      SMS
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col phoneSystemBXmain col-md-6"
              onClick={() => SetTab("ivr-greeting")}
            >
              <div className="phoneSystemBX-outer">
                <div className="phoneSystemBX cursor-pointer">
                  <div className="phoneSystemBX-left">
                    <h5>
                      <img
                        src="./../assets/images/ps-team-member-ico.svg"
                        alt="1"
                      />
                      IVR
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col phoneSystemBXmain col-md-6"
              onClick={() => SetTab("team-members")}
            >
              <div className="phoneSystemBX-outer">
                <div className="phoneSystemBX cursor-pointer">
                  <div className="phoneSystemBX-left">
                    <h5>
                      <img
                        src="./../assets/images/ps-team-member-status.svg"
                        alt="1"
                      />
                      Team Members
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col phoneSystemBXmain col-md-6">
              <div className="phoneSystemBX-outer">
                <div className="phoneSystemBX cursor-pointer">
                  <div className="phoneSystemBX-left">
                    <h5 onClick={() => SetTab("ip-devices")}>
                      <img
                        src="./../assets/images/ps-sip-phone-ico.svg"
                        alt="1"
                      />
                      IP Devices
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col phoneSystemBXmain col-md-6">
              <div className="phoneSystemBX-outer">
                <div className="phoneSystemBX cursor-pointer">
                  <div className="phoneSystemBX-left">
                    <h5 onClick={() => SetTab("call-workflows")}>
                      <img
                        src="./../assets/images/ps-call-workflow.svg"
                        alt="1"
                      />
                      Call Workflows
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col phoneSystemBXmain col-md-6">
              <div className="phoneSystemBX-outer">
                <div
                  className="phoneSystemBX cursor-pointer"
                  onClick={() => SetTab("teams")}
                >
                  <div className="phoneSystemBX-left">
                    <h5>
                      <img src="./../assets/images/ps-team-ico.svg" alt="1" />
                      Teams
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col phoneSystemBXmain col-md-6">
              <div className="phoneSystemBX-outer">
                <div
                  className="phoneSystemBX cursor-pointer"
                  onClick={() => SetTab("voice-files")}
                >
                  <div className="phoneSystemBX-left">
                    <h5>
                      <img
                        src="./../assets/images/ps-team-member-ico.svg"
                        alt="1"
                      />
                      Voice Files
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PhoneSystem;
