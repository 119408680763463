import React,{ useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Pagination = ({ data = [], currentData ,fromStartPage ,setFromStartPage}) => {
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [begin, setBegin] = useState(1)
    const [end, setEnd] = useState(5)
    const maxPage = Math.ceil(data.length / itemsPerPage);
    
    useEffect(() => {
        const begin = (currentPage - 1) * itemsPerPage
        const end = parseInt(begin) + parseInt(itemsPerPage)
        currentData(data.slice(begin, end))
        setBegin(parseInt(begin));
        setEnd(parseInt(end));
    }, [currentPage, itemsPerPage, data]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(fromStartPage){
            setCurrentPage(1)
            setFromStartPage(false)
        }
    },[fromStartPage]) 

    const next = () =>
        setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage))

    const prev = () =>
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))

     
    return (
        <>
        {data.length>0 && <div className="page-pagination">
            <div className="row-per-page">
                <span>Rows per page</span>
                <select className="form-control" name="itemsPerPage" value={itemsPerPage} onChange={e=>{setItemsPerPage(e.target.value);setCurrentPage(1);}}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                </select>
            </div>
            <div className="page-arrow">
                <span>{begin+1}-{data.length<end?data.length:end} of {data.length} items</span>
                <span>
                    <Link to="#" onClick={prev}><i className="fas fa-chevron-left"></i></Link>
                    <Link to="#" onClick={next}><i className="fas fa-chevron-right"></i></Link>
                </span>
            </div>
        </div>}
        </>
    )
}

export default Pagination
