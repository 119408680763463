import * as constants from "../Constants/notificationConstants";

 

const initalState = {  
    notification_listing:[]

}

const notificationReducer = (state = initalState, action) => {
    switch (action.type) {
        case constants.FETCH_NOTIFICATION_LISTING:
            return {
                ...state,
                notification_listing:action.payload,
            } 
         
        default:
            return state
    }
}

export default notificationReducer;
