import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { store } from "../../../Services";
import { 
  NxaContactListing,
} from "../../../Services/Actions/callActions";
import { ExtensionListing } from "../../../Services/Actions/settingActions";
import { removeBackdrop } from "../../../Utils/Common";
import BusinessContact from "./BusinessContact";
import ContactSlider from "./ContactSlider";
import PersonalContact from "./PersonalContact";
import TeamContact from "./TeamContact";

class NxaContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactTab: "team-contact",
      show: false,
      layoutType: "list",
      addContact: false,
      _editContactDetails: {},
    };
  }

  handleClose = () => {
    this.setState({ show: false }, () => {
      removeBackdrop();
    });
  };

  fetchContactType = (value) => {
    this.setState({
      contactTab: value,
    });
  };

  closeAddContactSlider = () => {
    const {
      _editContactDetails: { uuid },
    } = this.state;
    window.$(`#${uuid}`).removeClass("show");
    this.setState({
      addContact: false,
      _editContactDetails: {},
    });
  };

  getEditContactDetails = (value) => {
    this.setState({
      _editContactDetails: value,
      addContact: true,
    });
  };

  refreshHandler = () => {
    const { contactTab } = this.state;
    if (contactTab === "business-contact") {
      NxaContactListing({ type: "business" }, store.dispatch);
    } else if (contactTab === "personal-contact") {
      NxaContactListing({ type: "personal" }, store.dispatch);
    } else {
      ExtensionListing(0,store.dispatch);
    }
  };

  render() {
    const { contactTab, layoutType, addContact, _editContactDetails } =
      this.state;
    const {page} = this.props
    return (
      <>
        <div className={page === "settings" ? "" : "col-md-8 col-lg-9"}>
          <div className="right-side-top-header">
            <h5>
              {contactTab === "team-contact"
                ? "Team Contacts"
                : contactTab === "business-contact"
                ? "Business Contact"
                : contactTab === "personal-contact"
                ? "Personal Contact"
                : "All Contacts"}
            </h5>
          </div>
          <div className="all-contacts-tabs">
            <div
              className="tabs"
              onClick={() => this.setState({ addContact: true })}
              style={{ cursor: "pointer" }}
            >
              <div className={` tab-circle `}>
                <img src="./../assets/images/new-contact.svg" alt="1" />
              </div>
              <h4>New Contact</h4>
            </div>
            <div
              className="tabs"
              onClick={() => this.setState({ contactTab: "team-contact" })}
              style={{ cursor: "pointer" }}
            >
              <div
                className={`tab-circle ${
                  contactTab === "team-contact" ? "tab-circle-hover" : ""
                }`}
              >
                <img src="./../assets/images/@4.svg" alt="1" />
              </div>
              <h4>Team Contact</h4>
            </div>
            <div
              className="tabs"
              onClick={() => this.setState({ contactTab: "business-contact" })}
              style={{ cursor: "pointer" }}
            >
              <div
                className={` tab-circle ${
                  contactTab === "business-contact" ? "tab-circle-hover" : ""
                }`}
              >
                <img
                  src="./../assets/images/business-contact-icon.svg"
                  alt="1"
                />
              </div>
              <h4>Business Contact</h4>
            </div>
            <div
              className="tabs"
              onClick={() => this.setState({ contactTab: "personal-contact" })}
              style={{ cursor: "pointer" }}
            >
              <div
                className={` tab-circle ${
                  contactTab === "personal-contact" ? "tab-circle-hover" : ""
                }`}
              >
                <img src="./../assets/images/icons/10.svg" alt="1" />
              </div>
              <h4>Personal Contact</h4>
            </div>
            <div className="gird-list-ico">
              <Link to="#" title="Refresh Contact" onClick={this.refreshHandler}>
                <img src="./../assets/images/refresh-ico.svg" alt="1" />
              </Link>
              <Link
                to="#"
                title="Grid View"
                onClick={() => this.setState({ layoutType: "grid" })}
              >
                <img src="./../assets/images/grid-view-ico.svg" alt="1" />
              </Link>
              <Link
                to="#"
                title="List View"
                onClick={() => this.setState({ layoutType: "list" })}
              >
                <img src="./../assets/images/list-view-ico.svg" alt="1" />
              </Link>
            </div>
          </div>
          {contactTab === "team-contact" && (
            <TeamContact layoutType={layoutType} page={page} />
          )}
          {contactTab === "business-contact" && (
            <BusinessContact
              page={page}
              layoutType={layoutType}
              getEditContactDetails={this.getEditContactDetails}
            />
          )}
          {contactTab === "personal-contact" && (
            <PersonalContact
              page={page}
              layoutType={layoutType}
              getEditContactDetails={this.getEditContactDetails}
            />
          )}
        </div>
        <ContactSlider
          addContact={addContact}
          closeAddContactSlider={this.closeAddContactSlider}
          fetchContactType={this.fetchContactType}
          renderPage={"business_personal"}
          _editContactDetails={_editContactDetails}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(NxaContacts);
