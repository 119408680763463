import React, { useState, useEffect } from "react";
import { store } from "../../../Services";
import {
  RicaCompanyVerification,
  RicaPortingProcess,
} from "../../../Services/Actions/signupActions";
import AddressVerification from "./AddressVerification";
import CompanyVerification from "./CompanyVerification";
import IdVerification from "./IdVerification";
import PortingProcess from "./PortingProcess";
import Sidebar from "./Sidebar";

function Rica() {
  const [state, setState] = useState("company_detail_verification");
  const [isRicaCompleted, setIsRicaCompleted] = useState(false);

  useEffect(async () => {
    return () => {
      RicaCompanyVerification({}, store.dispatch);
      RicaPortingProcess({}, store.dispatch);
    };
  }, []);

  const OnChangeState = (state) => {
    setState(state);
  };

  const isRicaCompletedHandler = (value) => {
    setIsRicaCompleted(value);
  };

  return (
    <div className="right-side-wrapper pt-0 ">
      <div className="card">
        <div className="card-body p-0">
          <div className="row m-0">
            <Sidebar state={state} Selecttab={OnChangeState} />
            {state === "company_detail_verification" && (
              <CompanyVerification
                state={state}
                Selecttab={OnChangeState}
                isRicaCompletedHandler={isRicaCompletedHandler}
              />
            )}
            {state === "id_verification" && (
              <IdVerification Selecttab={OnChangeState} />
            )}
            {state === "address_verification" && (
              <AddressVerification Selecttab={OnChangeState} />
            )}
            {state === "porting-process" && (
              <PortingProcess isRicaCompleted={isRicaCompleted} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rica;
