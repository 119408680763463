import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../../../../Component/Pagination";
import { store } from "../../../../Services";
import { TeamListing } from "../../../../Services/Actions/callActions";
import {
  BusinessHourListing,
  CallFlowListing,
  DeleteNumberForwardCreate,
  ExtensionListing,
} from "../../../../Services/Actions/settingActions";
import TeamMemberSlider from "../../../Calls/Team/TeamMemberSlider";
import TeamSlider from "../../../Calls/Team/TeamSlider";
import CallFlowSlider from "./CallFlowSlider";
import AddBusinessHour from "./../BusinessHour/AddBusinessHour";
import swal from "sweetalert";

function CallFlow({
  SetTab,
  call_flow_list,
  businesshour_listing,
  extension_listing,
  team_listing,
}) {
  const [openCallFlowSlider, setOpenCallFlowSlider] = useState(false);
  const [editIVR, setEditIvr] = useState({});
  const [totalItems, setTotalItems] = useState([]);
  const [pageofItems, setPageOfItems] = useState([]);
  const [search, setSearch] = useState("");
  const [addBusinessHour, setAddBusinessHour] = useState(false);
  const [addTeamMember, setAddTeamMember] = useState(false);
  const [addTeam, setAddTeam] = useState(false);

  useEffect(() => {
    (async () => {
      await CallFlowListing({}, store.dispatch);
    })();
  }, []);

  useEffect(() => {
    setTotalItems(call_flow_list);
  }, [call_flow_list]);

  useEffect(() => {
    if (search) {
      const filteredData = call_flow_list.filter((value) =>
        value.name.toLowerCase().includes(search.toLowerCase())
      );
      setTotalItems(filteredData);
    } else {
      setTotalItems(call_flow_list);
    }
  }, [search, call_flow_list]);

  const SearchDataHandler = (e) => {
    let search = e.target.value;
    setSearch(search);
  };

  const sliceData = (pageofItems) => {
    setPageOfItems(pageofItems);
  };

  const DeleteCallFlowHandler = ({ switch_uuid, time_condition_uuid, id }) => {
    swal({
      text: "Are you sure want to delete this call flow?",
      icon: "warning",
      title:"Warning!",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await DeleteNumberForwardCreate(
          {
            type: "call-flow",
            switch_uuid,
            time_condition_uuid,
            id,
          },
          store.dispatch
        );
        if (response === 1) {
          CallFlowListing({}, store.dispatch);
        }
      }
    });
  };

  const ViewBusinessHourHandler = async (id) => {
    let res = await BusinessHourListing(id, store.dispatch);
    if (res === 1) {
      setAddBusinessHour(true);
    }
  };

  const ViewTeamAndExtensionHandler = async (value) => {
    switch (value?.forward_to) {
      case "EXTENSION": {
        let res = await ExtensionListing(value?.forward_to_id, store.dispatch);
        if (res === 1) {
          setAddTeamMember(true);
        }
        break;
      }
      case "DEPARTMENT": {
        let res = await TeamListing(value?.forward_to_id, store.dispatch);
        if (res === 1) {
          ExtensionListing(0, store.dispatch);
          setAddTeam(true);
        }
        break;
      }
    }
  };

  const AddCallFlowHandler = () => {
    setOpenCallFlowSlider(true);
    setEditIvr({});
  };

  const EditCallFlowHandler = (value) => {
    setOpenCallFlowSlider(true);
    setEditIvr(value);
  };

  return (
    <>
      <div className="phone-system-inner-main-div">
        <div className="phone-system-inner-div">
          <div className="phone-system-inner-head">
            <div className="ps-inner-head-left">
              <h3>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => SetTab("phone")}
                  className="phone-system-name"
                >
                  Phone Settings
                </span>
                <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                <span>Call Workflows</span>
              </h3>
            </div> 
            <div className="ps-inner-head-right">
              <span className="search-ps-inner">
                <img src="./../assets/images/search-dash.svg" alt="1" />
                <input
                  className="form-control"
                  type="text"
                  name="search"
                  value={search}
                  placeholder=""
                  onChange={SearchDataHandler}
                />
              </span>
              <Link
                to="#"
                style={{ cursor: "pointer" }}
                className="create-ps-num-btn"
                onClick={() => AddCallFlowHandler(" ")}
              >
                Add Call Flow
              </Link>
            </div>
          </div>
          {/* table start */} 
          {pageofItems && pageofItems.length === 0 && (
            <div className="phone-system-inner-table">
              <div className="team-user-add-div add-data-vactor">
                <img src="./../assets/images/IVR.svg" alt="1" />
                <h4>No (Call Flow) Auto Attendant added</h4>
                <Link
                  to="#"
                  className="btn btn-orange"
                  style={{ cursor: "pointer" }}
                  onClick={() => AddCallFlowHandler(" ")}
                >
                  Add Call Flow
                </Link>
              </div>
            </div>
          )} 
          {pageofItems && pageofItems.length !== 0 && (
            <div className="phone-system-inner-table">
              <div className="table-responsive call-info-scroll single-table-height">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Business Hour</th>
                      <th>Forward To(TEAM/TEAM MEMBER)</th>
                      <th>Assigned Number</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageofItems &&
                      pageofItems.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <span className="business-hrs-service">
                                <h3>{value.name}</h3>
                              </span>
                            </td>
                            <td>
                              {value.business_hour_name ? (
                                <Link
                                  style={{ cursor: "pointer" }}
                                  to="#"
                                  onClick={() =>
                                    ViewBusinessHourHandler(
                                      value.business_hourID
                                    )
                                  }
                                >
                                  {value.business_hour_name}
                                </Link>
                              ) : (
                                <Link to="#">{`NA`}</Link>
                              )}
                            </td>
                            <td>
                              <Link
                                style={{ cursor: "pointer" }}
                                to="#"
                                onClick={() =>
                                  ViewTeamAndExtensionHandler(value)
                                }
                              >
                                {value.forward_to === "EXTENSION" ? (
                                  <>
                                    {" "}
                                    TEAM MEMBER{" "}
                                    <i className="phone-system-i">
                                      ({value?.forward_to_name ?? null})
                                    </i>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    TEAM{" "}
                                    <i className="phone-system-i">
                                      ({value?.forward_to_name ?? null})
                                    </i>
                                  </>
                                )}
                              </Link>
                            </td>
                            <td className="assign-to-add">
                              {value.assigned_number ?? "NA"}
                            </td>
                            <td>
                              <Link
                                to="#"
                                title="Edit CallFlow"
                                className="edit-business-hrs"
                                onClick={() => EditCallFlowHandler(value)}
                              >
                                <img
                                  src="./../assets/images/edit-new.svg"
                                  alt="1"
                                />
                              </Link>
                              &nbsp;&nbsp;
                              <Link
                                to="#"
                                title="Delete CallFlow"
                                className="edit-business-hrs"
                                onClick={() => DeleteCallFlowHandler(value)}
                              >
                                <img
                                  src="./../assets/images/trash-2.svg"
                                  alt="delete"
                                />
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <Pagination data={totalItems} currentData={sliceData} />
        </div>
      </div> 
      <CallFlowSlider
        openCallFlowSlider={openCallFlowSlider}
        editIVR={editIVR}
        setEditIvr={setEditIvr}
        setOpenCallFlowSlider={setOpenCallFlowSlider}
      />

      <AddBusinessHour
        viewOnly={true}
        addBusinessHour={addBusinessHour}
        businessData={businesshour_listing?.[0]}
        closeBusinessHour={() => setAddBusinessHour(false)}
      />

      <TeamMemberSlider
        viewOnly={true}
        addTeamMember={addTeamMember}
        closeTeamMemberSlider={() => setAddTeamMember(false)}
        extensionDetails={extension_listing?.[0]}
      />

      <TeamSlider
        viewOnly={true}
        addTeam={addTeam}
        closeTeamSlider={() => setAddTeam(false)}
        team_details={team_listing?.[0]}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  call_flow_list: state?.setting?.call_flow_list ?? [],
  businesshour_listing: state?.setting?.businesshour_listing ?? [],
  extension_listing: state?.setting?.extension_listing ?? [],
  team_listing: state?.call?.team_listing ?? [],
});

export default connect(mapStateToProps)(CallFlow);
