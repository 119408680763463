import React from "react";
import { useSelector } from "react-redux";
import Modal from "../../../../Component/Modal";
import CardPayment from "../../../Signup/CardPayment";

function SaveCard({open,HideModal}) {
  const payment_token = useSelector((state) => state?.signup?.payment_token); 
  return (
    <Modal
    open={open}
    CloseModal={HideModal}
    title={"Save Payment Card"} 
    size={`md`}
  > 
    {payment_token && <CardPayment payment_token={payment_token} />} 
  </Modal> 
  );
}

export default SaveCard;
