import React, { Component } from "react";
import { Link } from "react-router-dom";
import { store } from "../../Services";
import { CreateUpdateCloudNotes } from "../../Services/Actions/powerappActions";

const initialState = {
  note: "",
  title: "",
  bold: false,
  italic: false,
  underline: false,
};
class CreateUpdateNote extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const { EditNotesDetails, isEdit } = this.props;
    if (EditNotesDetails && isEdit) {
      this.setState({
        title: EditNotesDetails?.title ?? "",
        note: EditNotesDetails?.note ?? "",
      });
    } else {
      this.setState({ title: "", note: "" });
    }
  }

  CreateUpdateNotes = async () => {
    const { note, title } = this.state;
    const { EditNotesDetails, isEdit, SetTab } = this.props;
    if (isEdit) {
      let data = {
        note,
        title,
        type: "update",
        uniquekey: EditNotesDetails?.uniquekey,
      };
      let response = await CreateUpdateCloudNotes(data, isEdit, store.dispatch);
      if (response === 1) {
        SetTab("notes");
      }
    } else {
      let data = { note, title, type: "create" };
      let response = await CreateUpdateCloudNotes(data, isEdit, store.dispatch);
      if (response === 1) {
        SetTab("notes");
      }
    }
  };

  render() {
    const { note, title, bold, italic, underline } = this.state;
    return (
      <>
        <div className="card discover-inner">
          <div className="buy-card-body">
            <div className="list-search create-note">
              <div className="form-control d-flex justify-content-between">
                <span className="align-self-center">
                  <Link
                    to="#"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.CreateUpdateNotes()}
                  >
                    <img
                      src="./../assets/images/tabbing/click.svg"
                      alt="1"
                    ></img>
                  </Link>
                </span>
                <span className="align-self-center">
                  <Link to="#">
                    {" "}
                    <img
                      src="./../assets/images/tabbing/back.svg"
                      alt="1"
                    ></img>
                  </Link>
                  <Link to="#">
                    {" "}
                    <img
                      src="./../assets/images/tabbing/forword.svg"
                      alt="1"
                    ></img>
                  </Link>
                  <Link to="#">
                    {" "}
                    <img
                      src="./../assets/images/tabbing/file-attech.svg"
                      alt="1"
                    ></img>
                  </Link>
                  <Link to="#">
                    {" "}
                    <img
                      src="./../assets/images/tabbing/camera.svg"
                      alt="1"
                    ></img>
                  </Link>
                </span>
              </div>
              <hr></hr>
              <div className="note-title">
                <div className="form-group">
                  <label>Note Title</label>
                  <input
                    className="form-control mb-0"
                    id="exampleFormControlTextarea1"
                    name="title"
                    value={title}
                    onChange={(event) =>
                      this.setState({
                        [event.target.name]: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Note</label>
                  <textarea
                    className="form-control mb-0"
                    id="exampleFormControlTextarea1"
                    rows="9"
                    name="note"
                    value={note}
                    onChange={(event) =>
                      this.setState({
                        [event.target.name]: event.target.value,
                      })
                    }
                    style={
                      bold
                        ? { fontWeight: "bold" }
                        : italic
                        ? { fontStyle: "italic" }
                        : underline
                        ? { textDecorationLine: "underline" }
                        : {}
                    }
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="bottom-tabbing">
              <hr></hr>
              <div className="tabbing-icons">
                <Link to="#">
                  <img
                    src="./../assets/images/tabbing/Vector.svg"
                    alt="1"
                  ></img>
                </Link>
                <Link to="#">
                  <img
                    src="./../assets/images/tabbing/menu-list.svg"
                    alt="1"
                  ></img>
                </Link>
                <Link to="#">
                  <img
                    src="./../assets/images/tabbing/attech.svg"
                    alt="1"
                  ></img>
                </Link>
                <Link to="#">
                  <img src="./../assets/images/tabbing/text.svg" alt="1"></img>
                </Link>
                <Link
                  to="#"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      bold: true,
                      italic: false,
                      underline: false,
                    })
                  }
                >
                  <img src="./../assets/images/tabbing/bold.svg" alt="1" />
                </Link>
                <Link
                  to="#"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      italic: true,
                      bold: false,
                      underline: false,
                    })
                  }
                >
                  <img src="./../assets/images/tabbing/italic.svg" alt="1" />
                </Link>
                <Link
                  to="#"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      underline: true,
                      bold: false,
                      italic: false,
                    })
                  }
                >
                  <img src="./../assets/images/tabbing/underline.svg" alt="1" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CreateUpdateNote;
