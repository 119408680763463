import React from "react";
import { Outlet } from "react-router-dom";

function EmptyLayout(props) {
  return (
    <>
      <Outlet {...props} />
    </>
  );
}

export default EmptyLayout;
