import React, { Component } from "react";
import moment from "moment";  
import { AllRecordingListing } from "../../Services/Actions/callActions";
import { bytesToSize, SecondsTohhmmss } from "../../Utils/Common";
import { connect } from "react-redux";
import { store } from "../../Services";
import AudioPlayer from "../Settings/PhoneSystem/CallFlow/AudioPlayer"; 

class CallRecording extends Component {
  constructor(props) {
    super(props); 
  }

  componentWillMount = async () => {
    AllRecordingListing(store.dispatch) 
  }

  render() {
    const { nxatel: { all_recording_list } ,SetTab } = this.props; 
    return (
      <>
        <a
          className="chat-back-arrow chat-back-arrow-2 m-0"
          style={{ cursor: "pointer" }}
          onClick={() => SetTab("cloud_storage")}
        >
          <img src="./../assets/images/backA.svg" />
        </a>
        <div className="tab-pane show active powerapps tab-pane-scroll">
          <table className="table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>Date</th>
                <th>Duration</th>
                <th>Size</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {all_recording_list instanceof Array &&
                all_recording_list.map((value, index) => {
                  return (
                    <tr key={`${index}`}>
                      <td>
                        {moment(value.start_stamp).format(
                          "DD-MM-YYYY hh:mm:ss"
                        )}
                      </td>
                      <td>{SecondsTohhmmss(value.duration)}</td>
                      <td>{bytesToSize(value.size)}</td>
                      <td align="center">
                        <AudioPlayer src={value.recording_file} /> 
                      </td>
                    </tr>
                  );
                })}

              {all_recording_list && all_recording_list.length === 0 && (
                <tr>
                  <td className="text-center" colSpan="4">
                    No call recordings
                  </td>
                </tr>
              )}
            </tbody>
          </table> 
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  nxatel: state?.call,
});

export default connect(mapStateToProps)(CallRecording);
