import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator"; 
import InputMask from 'react-input-mask'; 
import { store } from "../../../Services";
import {
  RicaCompanyVerification,
  RicaProcessSave,
} from "../../../Services/Actions/signupActions";
import { FetchUserDetails } from "../../../Services/Actions/userActions";
import { connect } from "react-redux";
import { withRouter } from "../../../Utils/withRouter";
import { Link } from "react-router-dom";
import { industryLists, isValidHttpUrl } from "../../../Utils/Common";

class CompanyVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIdAndAddressNotVerified: true,
      existOfficalCodes: false,
      full_name: "",
      selfie: "",
      business_type: "R",
      business_name: "",
      registration_number: "",
      trading_name: "",
      website: "",
      industry: "",
      id_number: "",
      add_business: true,
      same_register_name: true,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        in: "The last two digits of company registration number must be 06,07,08,21,23,30",
        url: "Company website is not a valid HTTP or HTTPS url.",
      },
    });
  }

  componentDidMount() {
    const {
      user_details,
      web_registration: { rica_company_verification },
    } = this.props;
    if (user_details?.ricaProcess?.selfie) {
      const {
        ricaProcess: { selfie },
      } = user_details;
      this.setState({ selfie });
    }

    if (user_details?.company) {
      const { company: business_name } = user_details;
      this.setState({ business_name, trading_name: business_name });
    }

    if (user_details?.ricaProcess?.city) {
      const {
        ricaProcess: { city },
      } = user_details; 
      this.setState({ city });
    }

    if (rica_company_verification?.business_type) {
      const {
        add_business,
        business_name,
        business_type,
        id_number,
        industry,
        same_register_name,
        registration_number,
        trading_name,
        full_name,
        website,
      } = rica_company_verification;
      this.setState({
        add_business,
        business_name,
        business_type,
        id_number,
        industry,
        same_register_name,
        registration_number,
        trading_name,
        full_name,
        website,
        existOfficalCodes: registration_number.toString().slice(-2),
      });
    }

    if (user_details?.ricaProcess?.business_type) {
      const {
        ricaProcess: {
          business_type,
          business_name,
          registration_number,
          id_number,
          industry,
          website,
          add_business,
          trading_name,
          full_name,
        },
      } = user_details;
      this.setState({
        add_business,
        business_name,
        business_type,
        id_number,
        industry,
        registration_number,
        trading_name,
        full_name,
        website,
        existOfficalCodes: registration_number
          ? registration_number.toString().slice(-2)
          : "",
      });
    }

    if (user_details?.ricaProcess?.city && user_details?.ricaProcess?.selfie) {
      this.setState({
        isIdAndAddressNotVerified: false,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { user_details } = newProps;
    if (user_details?.company) {
      const { company: business_name } = user_details;
      this.setState({ business_name });
    }
    if (user_details?.ricaProcess?.selfie) {
      const {
        ricaProcess: { selfie },
      } = user_details;
      this.setState({ selfie });
    }

    if (user_details?.ricaProcess?.city) {
      const {
        ricaProcess: { city },
      } = user_details;
      this.setState({ city });
    }

    if (user_details?.ricaProcess?.city && user_details?.ricaProcess?.selfie) {
      this.setState({
        isIdAndAddressNotVerified: false,
      });
    }

    if (user_details?.ricaProcess?.business_type) {
      const {
        ricaProcess: {
          business_type,
          business_name,
          registration_number,
          id_number,
          industry,
          website,
          add_business,
          trading_name,
          full_name,
        },
      } = user_details;
      this.setState({
        add_business,
        business_name,
        business_type,
        id_number,
        industry,
        registration_number,
        trading_name,
        full_name,
        website,
        existOfficalCodes: registration_number.toString().slice(-2),
      });
    }
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeRegistrationHandler = (e) => {
    this.setState({ registration_number: e.target.value }, () => {
      const { registration_number } = this.state;
      if (registration_number.length > 10) {
        this.setState({
          existOfficalCodes: registration_number.toString().slice(-2),
        });
      }
    });
  };

  StartVerification = async () => {
    const {
      same_register_name,
      full_name,
      business_name,
      registration_number,
      trading_name,
      id_number,
      add_business,
      industry,
      business_phone,
      business_email,
      business_type,
      website,
    } = this.state;
    const { user_details, Selecttab, isRicaCompletedHandler } = this.props;
    let data = {
      same_register_name,
      trading_name,
      id_number,
      add_business,
      industry,
      business_phone,
      business_email,
      business_type,
      website,
    };
    data =
      business_type === "R"
        ? { ...data, business_name, registration_number }
        : { ...data, full_name };
    if (this.validator.allValid()) {
      if (!user_details?.ricaProcess?.business_type) {
         RicaCompanyVerification(data,store.dispatch) 
      }
      // SAVING RICA PROCESS IN OUR DATABASE      ***************INTERNAL API***************
      let response = await  RicaProcessSave(data,store.dispatch);
      if (response === 1) {
        Selecttab("porting-process");
        isRicaCompletedHandler(true);
         FetchUserDetails(store.dispatch) 
        this.props.navigate(
          user_details?.porting === "Y" ? "/porting-process" : "/welcome"
        );
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { Selecttab } = this.props;
    const {
      isIdAndAddressNotVerified,
      existOfficalCodes,
      full_name,
      business_type,
      business_name,
      registration_number,
      same_register_name,
      trading_name,
      website,
      industry,
      id_number,
      add_business,
      selfie,
      city,
    } = this.state;
    this.validator.purgeFields();
    return (
      <>
        <div className="col-md-8 col-lg-9">
          <div className="row">
            <div className="col-md-12">
              <div className="porting-process">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="porting-inner id-verifications">
                        <h3>Online Business Account Verification Process</h3>

                        <div className="unverified-box">
                          <div className="validation-handler">
                            <div
                              className={`${
                                selfie
                                  ? "tick-box"
                                  : "porting-box blinking-animation"
                              } p-4 d-flex porting-box-rica flex-column justify-content-center align-items-center webphone-btns`}
                              onClick={() => Selecttab("id_verification")}
                            >
                              <img
                                src={`./../assets/images/${
                                  selfie
                                    ? "icons/tick-white-circle.svg"
                                    : "signup/COMPANY-REGISTRATION-IMG.svg"
                                }`}
                                alt="1"
                              />
                              <h6 className="font-14 color-fff lh-17">
                                {selfie
                                  ? "ID/PASSPORT SUCCESS,PENDING VERIFCATION"
                                  : "ID/PASSPORT UNVERIFIED"}
                              </h6>
                            </div>
                            {this.validator.message(
                              "Id Verification",
                              selfie,
                              "required"
                            )}
                          </div>
                          <div className="validation-handler">
                            <div
                              className={`${
                                city
                                  ? "tick-box"
                                  : "porting-box  blinking-animation"
                              }   p-4 d-flex porting-box-rica-address flex-column justify-content-center align-items-center webphone-btns`}
                              onClick={() => Selecttab("address_verification")}
                            >
                              <img
                                src={`./../assets/images/${
                                  city
                                    ? "icons/tick-white-circle.svg"
                                    : "signup/ic_outline-location-on.svg"
                                }`}
                                alt="1"
                              />
                              <h6 className="font-14 color-fff lh-17 text-center">
                                {city
                                  ? " ADDRESS VERIFIED"
                                  : `ADDRESS UNVERIFIED`}
                              </h6>
                            </div>
                            {this.validator.message(
                              "Address",
                              city,
                              "required"
                            )}
                          </div>
                        </div>
                        {!city && !selfie && (
                          <div className="mb-3" style={{ color: "red" }}>
                            You need to first verify your ID/password and
                            address.
                          </div>
                        )}
                        <Link to="#">
                          See examples of required docs
                          <img
                            src="./../assets/images/orange-arrow-right.svg"
                            alt="1"
                          />
                        </Link>
                        <p className="">
                          This is a KYC Verification, we require you to take a
                          selfie with your identification
                          <br />
                          documents and confirm your business address for your
                          account to be verified
                        </p>
                        <div className="id-verifications-radio">
                          <div className="radio-2 d-flex">
                            <input
                              type="radio"
                              name="business_type"
                              value="R"
                              checked={business_type === "R" ? "checked" : ""}
                              onChange={() =>
                                this.setState({ business_type: "R" })
                              }
                              disabled={isIdAndAddressNotVerified}
                            />
                            <span>Registered Business</span>
                          </div>
                          <div className="radio-1 d-flex">
                            <input
                              type="radio"
                              name="business_type"
                              value="S"
                              checked={business_type === "S" ? "checked" : ""}
                              onChange={() =>
                                this.setState({ business_type: "S" })
                              }
                              disabled={isIdAndAddressNotVerified}
                            />
                            <span>Sole Proprietor</span>
                          </div>
                        </div>
                        <div className="id-verification-form">
                          <div className="row">
                            <div className="col-md-6">
                              {business_type === "R" && (
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    aria-describedby=""
                                    placeholder="Company Registered Name"
                                    name="business_name"
                                    value={business_name}
                                    onChange={this.onChangeHandler}
                                    disabled={isIdAndAddressNotVerified}
                                    maxLength="20"
                                  />
                                  {this.validator.message(
                                    "Company Registered Name",
                                    business_name,
                                    "required"
                                  )}
                                </div>
                              )}
                              {business_type === "S" && (
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    aria-describedby=""
                                    placeholder="Full Name"
                                    name="full_name"
                                    value={full_name}
                                    onChange={this.onChangeHandler}
                                    disabled={isIdAndAddressNotVerified}
                                    maxLength="20"
                                  />
                                  {this.validator.message(
                                    "Full Name",
                                    full_name,
                                    "required"
                                  )}
                                </div>
                              )}
                              <div className="form-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="ID number/Passport No"
                                  name="id_number"
                                  value={id_number}
                                  onChange={(event) => {
                                    if (event.target.value.length > 13)
                                      return false;
                                    this.setState({
                                      [event.target.name]: event.target.value,
                                    })
                                  }}
                                  disabled={isIdAndAddressNotVerified}
                                />
                                {this.validator.message(
                                  "ID number/Passport No",
                                  id_number,
                                  "required|min:13"
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              {business_type === "R" && (
                                <div className="form-group">
                                  <InputMask 
                                    mask="9999/999999/99" 
                                    placeholder="Company Registration No" 
                                    className="form-control"
                                    name="registration_number"
                                    value={registration_number}
                                    onChange={this.onChangeRegistrationHandler}  
                                    disabled={isIdAndAddressNotVerified}
                                  />
                                   
                                  {this.validator.message(
                                    "",
                                    existOfficalCodes,
                                    "required|in:06,07,08,21,23,30"
                                  )}
                                </div>
                              )}
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Company Website"
                                  name="website"
                                  value={website}
                                  onChange={this.onChangeHandler}
                                  disabled={isIdAndAddressNotVerified}
                                />
                                {this.validator.message(
                                  "",
                                  website,
                                  `url: ${isValidHttpUrl(website)}`
                                )}
                              </div>
                              {business_type === "S" && (
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    name="industry"
                                    value={industry}
                                    onChange={this.onChangeHandler}
                                    disabled={isIdAndAddressNotVerified}
                                  >
                                    <option value=""> Industry </option>
                                    {industryLists && industryLists.map((value,key) => {
                                      return (
                                        <option key={key} value={value}>{value}</option>
                                      )
                                    })}
                                  </select>
                                  {this.validator.message(
                                    "Industry",
                                    industry,
                                    "required"
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <p className="color-7AFE font-14 fw-400 d-flex">
                                Same as registered business name
                                <label className="switch ml-3">
                                  <input
                                    type="checkbox"
                                    value={same_register_name ? "checked" : ""}
                                    name="same_register_name"
                                    checked={
                                      same_register_name ? "checked" : ""
                                    }
                                    onChange={() =>
                                      this.setState({
                                        same_register_name: !same_register_name,
                                        trading_name: !same_register_name
                                          ? business_name
                                          : "",
                                      })
                                    }
                                    disabled={isIdAndAddressNotVerified}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p className="color-7AFE font-14 fw-400 d-flex">
                                Add Business to Thriving Network Business
                                Directory
                                <label className="switch ml-3">
                                  <input
                                    type="checkbox"
                                    value={add_business ? "checked" : ""}
                                    name="add_business"
                                    checked={add_business ? "checked" : ""}
                                    onChange={() =>
                                      this.setState({
                                        add_business: !add_business,
                                      })
                                    }
                                    disabled={isIdAndAddressNotVerified}
                                  />
                                  <span className="slider round Thriving"></span>
                                </label>
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Trading as Name"
                                  name="trading_name"
                                  value={trading_name}
                                  onChange={this.onChangeHandler}
                                  disabled={isIdAndAddressNotVerified}
                                  maxLength="20"
                                />
                              </div>
                            </div>
                            {business_type === "R" && (
                              <div className="col-md-6">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    name="industry"
                                    value={industry}
                                    onChange={this.onChangeHandler}
                                    disabled={isIdAndAddressNotVerified}
                                  >
                                    <option value=""> Industry </option>
                                    {industryLists && industryLists.map((value,key) => {
                                      return (
                                        <option key={key} value={value}>{value}</option>
                                      )
                                    })}
                                  </select>
                                  {this.validator.message(
                                    "Industry",
                                    industry,
                                    "required"
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="id-verification-btn-div">
                          <button
                            type="button"
                            className="btn start-verification ml-3"
                            onClick={this.StartVerification}
                          >
                            Verify Account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    web_registration: state?.signup,
    user_details: state?.user?.user_details?.userDetail ?? {},
  };
};
export default connect(mapStateToProps)(withRouter(CompanyVerification));
