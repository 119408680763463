import React, { useEffect, useState } from "react";
import moment from "moment";
import { store } from "../../../Services";
import {
  DeleteAssignedNumber,
  DeleteTagFromNumber,
  FetchAssignedNumberList,
  FetchProfileInfoData,
  ForceCall,
  IndividualContactCallHistory,
} from "../../../Services/Actions/callActions";
import { Link } from "react-router-dom";
import ContactSlider from "../NxaContacts/ContactSlider";
import TeamMemberSlider from "../Team/TeamMemberSlider";
import MessageContactModal from "../SMS/MessageContactModal";
import AssignedModal from "./AssignedModal";
import { connect } from "react-redux";
import { withRouter } from "../../../Utils/withRouter";
import NotesModal from "../Notes/NotesModal";
import TagModal from "./TagModal";
import ScheduleModal from "./ScheduleModal";
import swal from "sweetalert";
import {
  capitalizeFirstLetter,
  convertUtcToLocalTime,
  removeBackdrop,
  SecondsTohhmmss,
} from "../../../Utils/Common";

function CallInfo(props) {
  const [modalType, setModalType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [addTeamMember, setAddTeamMember] = useState(false);
  const [limit, setLimit] = useState(2);
  const {
    profile_info,
    call_details: {
      direction,
      caller_id_number,
      destination_number,
      nxatel_user,
      customerID,
      contact_firstname,
      contact_lastname,
      profile_picture,
    },
    assigned_number_list,
    individual_call_history,
    call_history,
    extension_listing,
    fromDialer
  } = props; 
  const notesList       = profile_info?.notes;
  const assignedTagList = profile_info?.tags; 
  const call_number     = direction === "inbound" ? caller_id_number : destination_number;
  const name            = contact_firstname ? `${contact_firstname} ${contact_lastname}` : "";

  useEffect(() => {
    if (call_history) {
      IndividualContactCallHistory({ number: call_number }, store.dispatch);
      FetchProfileInfoData(
        nxatel_user === "Y" ? { customerID } : { number: call_number },
        store.dispatch
      );
      FetchAssignedNumberList({ number: call_number }, store.dispatch);
    }
  }, [call_history]);

  const onLoadMore = () => {
    if (limit === 2) {
      let length = individual_call_history?.length;
      setLimit(length);
    } else {
      setLimit(2);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    removeBackdrop();
  };

  const addMemberModalOpen = () => {
    setAddTeamMember(true);
    handleClose();
  };

  const DeleteNumber = (value) => {
    let data = { user_extension: value.user_extension, phone: value.phone };
    swal({
      text: "Are you sure want to delete this assigned number?",
      icon: "warning",
      title:"Warning!",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        DeleteAssignedNumber(data, store.dispatch);
      } else {
        console.log("you clicked on false");
      }
    });
  };

  const closeAddContactSlider = () => {
    setAddContact(false);
  };

  const closeTeamMemberSlider = () => {
    setAddTeamMember(false);
  };

  const deleteTagHandler = (tag) => {
    swal({
      icon: "warning",
      title:"Warning!",
      buttons: [true, "Remove"],
      text: "Are you sure want to remove this tag?",
    }).then(async (action) => {
      if (action) {
        let res = await DeleteTagFromNumber(
          { number: tag?.number, tagID: tag?.tagID },
          store.dispatch
        );
        if (res === 1) {
          FetchProfileInfoData({ number: tag?.number }, store.dispatch);
        }
      }
    });
  };

  const NotesHandler = () => {
    setModalType("notes");
    setShowModal(true);
  };

  const ScheduleHandler = () => {
    setModalType("schedule");
    setShowModal(true);
  };
  const MessageContactHandler = () => {
    setModalType("message_contact");
    setShowModal(true);
  };

  const AssignToHandler = () => {
    setModalType("assign");
    setShowModal(true);
  };

  const TagHandler = () => {
    setModalType("tag");
    setShowModal(true);
  };

  return (
    <>
      <div className={`${fromDialer ? "col-md-12" : "col-md-8 col-lg-9"}`}>
        <div className={`tab-pane call-info-tabPane show active ${fromDialer ? "dialer-page-call-info" : ""}`}>
          <div className="row">
            <div className="col-md-12 add-number-div">
              {!fromDialer && <h5>
                <Link
                  to="#"
                  className="chat-back-arrow chat-back-arrow-2"
                  onClick={() => props.SetTab("all_calls")}
                >
                  <img src="./../assets/images/backA.svg" />
                </Link>
                Callee Info
              </h5>}
              <div className="call-info-header">
                <div className="calling-info-left">
                  <span className="circle-img">
                    <img
                      src={
                        profile_picture
                          ? profile_picture
                          : "./../assets/images/user+.png"
                      }
                      alt="1"
                    />
                  </span>
                  <span className="new-number">
                    {name ? capitalizeFirstLetter(name) : call_number}
                    <span className="d-flex">
                      {assignedTagList &&
                        assignedTagList.map((value) => {
                          return (
                            <Link
                              to="#"
                              className="call-info-follow-up mr-2"
                              style={{ background: value.color_code }}
                            >
                              {value.name}
                              <i
                                className="fas fa-times close-call-tag"
                                onClick={() => deleteTagHandler(value)}
                              ></i>
                            </Link>
                          );
                        })}
                    </span>
                  </span>
                </div>
                {(!name && !fromDialer) ? (
                  <div className="calling-info-right">
                    <Link
                      to="#"
                      className="call-info-add-number"
                      style={{ cursor: "pointer" }}
                      onClick={() => setAddContact(true)}
                    >
                      Add to Contacts
                    </Link>
                  </div>
                ):(!name && fromDialer) ? (                         // call info on dialer
                  <div className="calling-info-right call-back-info-page">
                    <Link
                      to="#"
                      className="call-info-add-number"
                      style={{ cursor: "pointer" }}
                      onClick={() => setAddContact(true)}
                    >
                      Add 
                    </Link>
                  </div>
                ):""
                }
              </div>
            </div>
          </div>
          <div className="call-info-tab height-auto">
            <div className="tab-pane-scroll scroll-call-info height-auto">
              <div className="table-responsive calls-table calls-table-hover">
                <table className="table table-hover table-center mb-0">
                  <h5 className="col-md-12 call-info-head pl-0">
                    Call Information
                  </h5>
                  <tbody>
                    {individual_call_history &&
                      individual_call_history
                        .slice(0, limit)
                        .map((value, index) => {
                          let date_time = convertUtcToLocalTime(
                            value.start_stamp
                          );
                          let formattedDate = moment(date_time)
                            .locale("en")
                            .format("ll");
                          let formattedTime = moment(date_time)
                            .locale("en")
                            .format("LT"); 
                          
                          let user_extension  =  extension_listing && extension_listing.find(({ user_extension }) =>      user_extension === value.destination_number )?.user_extension;
                          return (
                            <tr key={`call_${index}`}>
                              <td>
                                <div className="d-flex all-message-email-row">
                                  <div className="text-center align-self-center call-type-img"> 
                                    {(value.direction === "inbound" && value.billsec === 0) ? (  
                                      <img
                                        src="./../assets/images/missed-call-ico.png"
                                        alt="1"
                                      />
                                    ) : (value.direction === "inbound" && value.billsec > 0) ? (
                                      <img
                                        src="./../assets/images/incoming-call-ico.png"
                                        alt="1"
                                      />
                                    ) : value.direction === "outbound" ? (
                                      <img
                                        src="./../assets/images/outgoing-call-ico.png"
                                        alt="1"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="align-self-center pl-0"> 
                                    {
                                      value.direction === "inbound" && value.billsec === 0 ?  
                                      <> <h3>Missed call</h3>  </> :
                                      value.direction === "inbound" && value.billsec > 0 ? 
                                      <>
                                        <h3>Incoming call answered</h3>
                                        <p>
                                          {value.caller_name && `Received By ${value.caller_name}`}
                                          {user_extension && <i class="italic-font-color">{`(${user_extension})`}</i>}
                                        </p>
                                      </>
                                      :  
                                      <>
                                        <h3>Outgoing call answered</h3>
                                        <p>
                                          {`Made by ${value.caller_name}`}
                                          {user_extension && <i class="italic-font-color">{`(${user_extension})`}</i>}
                                        </p>
                                      </> 
                                    } 
                                  </div>
                                </div>
                              </td>
                              <td className="text-right pr-4">
                                <Link
                                  to="#"
                                  className="text-grey calls-date-time"
                                >{`${formattedDate} - ${formattedTime}`}</Link>
                                <div className="align-self-center call-type-td allcall-img-ico">
                                  <h3 className={value.direction === "inbound" && value.billsec === 0 ? "text-red" : ""}>
                                    <img
                                      src={
                                        value.direction === "inbound" && value.billsec === 0
                                          ? "./../assets/images/cl-cut.svg"
                                          : value.direction === "inbound" && value.billsec > 0
                                          ? "./../assets/images/cl-in.svg"
                                          : "./../assets/images/cl-out.svg"
                                      }
                                      alt="1"
                                    />
                                    {value.direction === "inbound" && value.billsec === 0
                                      ? "Missed"
                                      : value.direction === "inbound" && value.billsec > 0
                                      ? "Inbound Call"
                                      : "Outbound Call"}
                                  </h3> 
                                  <p> {SecondsTohhmmss(value.billsec)} </p> 
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table> 
              </div> 
            </div>
            {individual_call_history?.length > 2 && limit === 2 && (
                <Link
                  to="#"
                  className="load-more-call-info mt-2"
                  onClick={onLoadMore}
                >
                  Load more calls
                </Link>
              )}
              {individual_call_history?.length > 2 && limit !== 2 && (
                <Link
                  to="#"
                  className="load-more-call-info mt-2"
                  onClick={onLoadMore}
                >
                  Load less calls
                </Link>
              )}
            {assigned_number_list && assigned_number_list.length > 0 && (
              <div className="tab-pane-scroll scroll-call-info height-auto">
                <div className="table-responsive calls-table calls-table-hover">
                  <table className="table table-hover table-center mb-0">
                    <h5 className="col-md-12 call-info-head pl-0">
                      {" "}
                      Assigned To{" "}
                    </h5>
                    <tbody>
                      {assigned_number_list &&
                        assigned_number_list.map((value, index) => {
                          const name = value?.name ?? "";
                          const formattedName =
                            name !== "" ? name.match(/\b(\w)/g) : "";
                          let date_time = convertUtcToLocalTime(
                            value.creationdate
                          );
                          let formattedDate = moment(date_time)
                            .locale("en")
                            .format("ll");
                          let formattedTime = moment(date_time)
                            .locale("en")
                            .format("LT");
                          return (
                            <tr key={`call_${index}`}>
                              <td>
                                <div className="d-flex all-message-email-row">
                                  <div className="text-center align-self-center call-type-img">
                                    <span className="label-name">
                                      {formattedName}
                                    </span>
                                  </div>
                                  <div className="align-self-center pl-0">
                                    <h3>{value.name}<i className="italic-font-color">{`(${value.user_extension})`}</i></h3>
                                  </div>
                                </div>
                              </td>
                              <td className="text-right pr-4">
                                <Link
                                  to="#"
                                  className="text-grey calls-date-time"
                                >{`${formattedDate} - ${formattedTime}`}</Link>
                                <div className="recentcall-img-ico allcall-img-ico">
                                  <Link
                                    to="#"
                                    title="Delete"
                                    onClick={() => DeleteNumber(value)}
                                  >
                                    <img
                                      src="./../assets/images/delete_icon.svg"
                                      alt="1"
                                    />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {notesList && notesList.length > 0 && (
              <div className="tab-pane-scroll scroll-call-info height-auto">
                <div className="table-responsive">
                  <table className="table table-hover table-center mb-0">
                    <h5 className="col-md-12 call-info-head pl-0"> Notes </h5>
                    <tbody>
                      {notesList &&
                        notesList.map((value, index) => {
                          let date_time = convertUtcToLocalTime(
                            value.creationdate
                          );
                          let formattedDate = moment(date_time)
                            .locale("en")
                            .format("ll");
                          let formattedTime = moment(date_time)
                            .locale("en")
                            .format("LT");
                          const name = value?.created_by_name ?? "";
                          const formattedName =
                            name !== "" ? name.match(/\b(\w)/g) : "";
                          return (
                            <tr key={`call_${index}`}>
                              <td>
                                <div className="d-flex all-message-email-row">
                                  <div className="text-center align-self-center call-type-img">
                                    <span className="label-name">
                                      {formattedName}
                                    </span>
                                  </div>
                                  <div className="align-self-center pl-0">
                                    <h3>{name}</h3>
                                    <p>{value.notes}</p>
                                  </div>
                                </div>
                              </td>
                              <td className="text-right pr-4">{`${formattedDate} - ${formattedTime}`}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div className="assign-div">
            <div className={`d-flex flex-wrap-xs justify-content-between ${fromDialer ? "flex-wrap" : ""}`}>
              <div className={`d-flex ${fromDialer ? "justify-content-between w-100 pt-2" : ""}`}>
                <Link to="#" className="btn btn-notes" onClick={NotesHandler}>
                  <img src="./../assets/images/darkNotes.svg" alt="1" />
                  Notes
                </Link>
                <Link
                  to="#"
                  className="btn btn-notes bg-blue"
                  onClick={TagHandler}
                >
                  <img src="./../assets/images/tag.svg" alt="1" />
                  Tags
                </Link>
                <Link
                  to="#"
                  className="btn btn-assign"
                  onClick={AssignToHandler}
                >
                  <img src="./../assets/images/assign.svg" alt="1" />
                  Assign To
                </Link>
              </div>
              <div className={`d-flex ${fromDialer ? "justify-content-around w-100 mt-2" : ""}`}>
                <Link
                  to="#"
                  className="btn btn-message-contact"
                  onClick={MessageContactHandler}
                >
                  <img src="./../assets/images/sms-contact.svg" alt="1" />
                  Message Contact
                </Link>
                <span className="call-back">
                  <a
                    href="#callback"
                    data-toggle="collapse"
                    aria-expanded="true"
                    className="dropdown-toggle btn btn-call-back"
                  >
                    <img src="./../assets/images/call-back.svg" alt="1" />
                    Call Back
                  </a>

                  <ul
                    className="list-unstyled collapse call-btn-collapse"
                    id="callback"
                  >
                    <li>
                      <Link
                        to="#"
                        onClick={() => ForceCall(call_number, store.dispatch)}
                      >
                        <img src="./../assets/images/call-now.svg" alt="1" />{" "}
                        Call Now
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={ScheduleHandler}>
                        <img
                          src="./../assets/images/calendar-call-info.svg"
                          alt="1"
                        />
                        Schedule Later
                      </Link>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactSlider
        addContact={addContact}
        closeAddContactSlider={closeAddContactSlider}
        phone={call_number}
        renderPage={"all_calls"}
      />

      <TeamMemberSlider
        addTeamMember={addTeamMember}
        closeTeamMemberSlider={closeTeamMemberSlider}
      />

      {modalType === "message_contact" && showModal && (
        <MessageContactModal
          OpenMessageModal={showModal}
          closeMessageContactModal={handleClose}
          destination_number={call_number}
        />
      )}

      {modalType === "schedule" && showModal && (
        <ScheduleModal
          OpenScheduleModal={showModal}
          closeScheduleModal={handleClose}
          destination_number={call_number}
        />
      )}

      {modalType === "assign" && showModal && (
        <AssignedModal
          OpenAssignModal={showModal}
          destination_number={call_number}
          closeAssignModal={handleClose}
          addMemberModalOpen={addMemberModalOpen}
          assigned_number_list={assigned_number_list}
        />
      )}

      {modalType === "tag" && showModal && (
        <TagModal
          open={showModal}
          handleClose={handleClose}
          destination_number={call_number}
          assignedTagList={assignedTagList}
        />
      )}

      {modalType === "notes" && showModal && (
        <NotesModal
          open={showModal}
          handleClose={handleClose}
          call_number={call_number}
          previous_note={notesList && notesList.slice(0, 1)}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  const {
    call: {
      call_details = {},
      assigned_number_list = {},
      individual_call_history = {},
      isTagged = "",
      profile_info = {},
    } = {},
    setting:{extension_listing = []} = {}
  } = state; 
  return {
    call_details,
    call_history: call_details,
    assigned_number_list,
    individual_call_history,
    isTagged,
    profile_info,
    extension_listing
  };
};
export default connect(mapStateToProps)(withRouter(CallInfo));
