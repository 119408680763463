import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { store } from "../../Services";
import { naviagteToSetting } from "../../Services/Actions/userActions";
import Account from "./Account";
import Notification from "./Notification";
import PhoneSystem from "./PhoneSystem";
import Number from "./PhoneSystem/Number";
import BusinessHour from "./PhoneSystem/BusinessHour";
import IPDevices from "./PhoneSystem/IPDevices";
import Team from "./PhoneSystem/Team";
import Member from "./PhoneSystem/Member";
import VoiceFiles from "./PhoneSystem/VoiceFiles";
import Tags from "./PhoneSystem/Tags";
import CallFlow from "./PhoneSystem/CallFlow";
import IVR from "./PhoneSystem/IVR";
import Sidebar from "./Sidebar";  
import SMS from "./PhoneSystem/SMS";
import NxaContacts from "../Calls/NxaContacts";

function Settings(props) {  
    const [tab,setTab ] = useState("account")
    const [subtab,setSubTab] = useState("")
    const { nxatel: { user_details: { userDetail },navigate_to_setting }} = props;
    let status = userDetail?.status ?? "P";
 

    useEffect(()=>{
        if(navigate_to_setting?.tab){
            setTab("notification") 
            naviagteToSetting({},store.dispatch)
        }
    },[navigate_to_setting?.tab]) 

    const  SetTabHandler = (tab, subtab = "") => {
        setTab(tab)
        setSubTab(subtab) 
    };

    return (
        <>
        <div className="right-side-wrapper">
            <div className="card">
                <div className="card-body p-0">
                    <div className="row m-0">
                        <Sidebar
                            state={tab}
                            SetTab={SetTabHandler}
                            subtab={subtab}
                            status={status}
                            payment_status = {userDetail?.payment_status}
                        />
                        <div className="col-md-8 col-lg-9 setting-profile tab-pane-scroll height-auto">
                            <div className="account">
                                {((tab,subtab) => {
                                    switch (tab) {
                                        case "account":
                                            return <Account {...props} />;
                                        case "phone":
                                            return <PhoneSystem  state={tab} SetTab={SetTabHandler} />; 
                                        case "notification":
                                            return <Notification {...props} SetTab={SetTabHandler} />;
                                        case "numbers":
                                            return <Number SetTab={SetTabHandler} />;
                                        case "business-hours":
                                            return <BusinessHour SetTab={SetTabHandler} />;
                                        case "team-members":
                                            return <Member SetTab={SetTabHandler} />;
                                        case "teams":
                                            return <Team SetTab={SetTabHandler} />;
                                        case "ip-devices":
                                            return <IPDevices SetTab={SetTabHandler} />;
                                        case "ivr-greeting":
                                            return <IVR SetTab={SetTabHandler} />;
                                        case "voice-files":
                                            return <VoiceFiles SetTab={SetTabHandler} />;
                                        case "tag-list":
                                            return <Tags SetTab={SetTabHandler}/>;
                                        case "call-workflows":
                                            return <CallFlow SetTab={SetTabHandler}/>;
                                        case "sms":
                                            return <SMS SetTab={SetTabHandler}/>;
                                        case "contacts":
                                            return <NxaContacts page = {"settings"} />;
                                        default:
                                            return null;
                                    }
                                })(tab,subtab)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

const mapStateToProps = (state) => ({
    nxatel: state?.user,
});

export default connect(mapStateToProps)(Settings);
