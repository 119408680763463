import React, { PureComponent } from "react";
import moment from "moment";
import swal from "sweetalert";
import Chat from "../../Utils/Chat";
import authService from "../../Utils/authService";
import { withRouter } from "../../Utils/withRouter";
import { store } from "../../Services";
import {
  SelectedChatType,
  SelectedChatUser,
  SelectedGroupChatUser,
} from "../../Services/Actions/callActions";
import { Link } from "react-router-dom";
import { currentUser } from "../../Firebase/firebaseActions";
import { sortNumericKeys } from "../../Utils/Common";

const __initialState = {
  __totalItems: [],
  __totalItemsGroup: [],
  __pageofItems: [],
  __initiated: false,
};

class AllRecentChat extends PureComponent {
  constructor(props) {
    super(props);
    this.state = __initialState;
  }

  componentDidMount() {
    let checkIfLoggedIn = currentUser();
    if (!checkIfLoggedIn) {
      let interval = setInterval(() => {
        checkIfLoggedIn = currentUser();
        if (checkIfLoggedIn) {
          this.FetchRoomInfo();
          clearInterval(interval);
        }
      }, 1000);
    } else {
      this.FetchRoomInfo();
    }
    this.FetchRoomInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    const { __activeTab: __oldActiveTab } = prevProps;
    const { __initiated } = this.state;
    const { __activeTab } = this.props;
    if (__initiated === false && __activeTab !== __oldActiveTab) {
      this.OneToOneFetch();
    }
  }

  FetchRoomInfo = () => {
    Chat.fetch_ref_on_update(`/Groups/`, this.UpdateGroups);
    this.OneToOneFetch();
  };

  OneToOneFetch = () => {
    {
      /**
            @param __fetchToken to get uniquecode
            @param __fetchUserChat to chat list from firebase
        */
    }
    const __fetchToken = authService.getUserDetail().uniquecode;
    const __fetchUserChat = Chat.if_room_exists(__fetchToken);
    __fetchUserChat.then((value) => {
      if (value) {
        Chat.fetch_room_data_on_update(__fetchToken, this.UpdateList);
        this.setState({ __initiated: true });
      }
    });
  };

  UpdateList = (__value) => {
    let __updateList = {};
    let __chatList = __value ? Object.values(__value) : [];
    __updateList = { __totalItems: __chatList };
    if (__chatList.length === 0)
      __updateList = { ...__updateList, __initiated: false };
    this.setState(__updateList);
  };

  UpdateGroups = (__groups) => {
    let __uniquecode = authService.getUserDetail().uniquecode;
    __groups = __groups ? Object.values(__groups) : [];
    let __myGroups = [];
    __groups.map((__value) => {
      let { joinee } = __value;
      if (joinee && joinee.includes(__uniquecode)) {
        __myGroups = [...__myGroups, __value];
      }
    });
    this.setState({ __totalItemsGroup: __myGroups });
  };

  UpdateData = (__pageofItems) => {
    this.setState({ __pageofItems });
  };

  startChat = (__item) => {
    const { type } = __item;
    if (type === "individual") {
      const { displayName, profile, recipient, phone } = __item;
      let userData = {
        profile_picture: profile,
        phone,
        name: displayName,
        uniquecode: recipient,
      };
      SelectedChatUser(userData, store.dispatch);
      SelectedChatType("one_to_one_chat", store.dispatch);
      this.props.navigate("/chat");
    } else {
      let { displayName: name, participants, roomName: uniquekey } = __item;
      let data = { participants, name, uniquekey, profile: "" };
      SelectedGroupChatUser(data, store.dispatch);
      SelectedChatType("group_chat", store.dispatch);
      this.props.navigate("/chat");
    }
  };

  DeleteRoom = (__item) => {
    const { roomName, type } = __item;
    swal({
      text: "Are you sure want to delete this chat?",
      buttons: true,
      icon: "warning",
      title:"Warning!",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (type === "individual") {
          const { recipient } = __item;
          const { uniquecode } = authService.getUserDetail();
          Chat.remove_room_list(recipient, roomName);
          Chat.remove_room_list(uniquecode, roomName);
          Chat.remove_message_data(roomName);
        } else {
          Chat.remove_group_list(roomName);
          Chat.remove_message_data(roomName);
        }
        this.FetchRoomInfo();
      } else {
        swal("Your records are safe");
      }
    });
  };

  render() {
    let { __totalItems, __totalItemsGroup } = this.state;
    const {extension_listing} = this.props
    __totalItems = __totalItems.concat(__totalItemsGroup);
    __totalItems.sort((item1, item2) =>
      sortNumericKeys(item1.updated_at, item2.updated_at, "descending")
    );
    const { __activeTab, OpenModal } = this.props;
    return (
      <div
        className={`col-md-8 col-lg-9 ${
          __activeTab === "chat_listing" ? "" : "hide"
        }`}
      >
        <div className="row">
          <div className="col-lg-10 offset-lg-1  col-md-12 mb-2">
            <div className="meeting-tabbing all-recive-chat-tab">
              <ul
                className="nav justify-content-center"
                id="myTab"
                role="tablist"
              >
                <li
                  className=""
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <Link to="#">
                    <div className="mlr-0-15">
                      <div className="message-box meeting-right bg-light-orange">
                        <div className="message-icons">
                          <span className="message-icons-sp">
                            <img
                              src="./../assets/images/invitecus.svg"
                              alt="1"
                            />
                          </span>
                          <h6 className="mt-3 mb-0">Start new chat</h6>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
                <li
                  className=""
                  data-toggle="modal"
                  data-target="#exampleModal1"
                >
                  <Link to="#">
                    <div className="mlr-0-15">
                      <div className="message-box meeting-right bg-pink">
                        <div className="message-icons">
                          <span className="message-icons-sp">
                            <img src="./../assets/images/cretgrp.svg" alt="1" />
                          </span>
                          <h6 className="mt-3 mb-0">Create a Team</h6>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link to="#" onClick={() => OpenModal(false)}>
                    <div className="mlr-0-15">
                      <div className="message-box meeting-right bg-blue">
                        <div className="message-icons">
                          <span className="message-icons-sp">
                            <img src="./../assets/images/invite.svg" alt="1" />
                          </span>
                          <h6 className="mt-3 mb-0">NxaContact</h6>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>

                <li className="">
                  <Link to="#" onClick={() => OpenModal(true)}>
                    <div className="mlr-0-15">
                      <div className="message-box meeting-right bg-purple">
                        <div className="message-icons">
                          <span className="message-icons-sp">
                            <img
                              src="./../assets/images/schedule.svg"
                              alt="1"
                            />
                          </span>
                          <h6 className="mt-3 mb-0">Schedule Meeting </h6>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="appointment-tab allrecent">
          <div className="tab-content">
            <div className="tab-pane show tab-pane-scroll active tab-pane-chat-scroll">
              <div className="table-responsive">
                <table className="table table-hover table-center mb-0">
                  <tbody>
                    {__totalItems.length > 0 ? (
                      __totalItems.map((__item, __index) => {
                        const {
                          displayName,
                          // profile,
                          last_message,
                          updated_at,
                          recipient
                        } = __item; 
                        let profile = extension_listing && extension_listing.find(value=>value.uniquecode === recipient)?.profile_picture
                        let __datetime = moment(updated_at)
                          .locale("en")
                          .format("lll");
                        let __getTime = __datetime.slice(12, 21);
                        let __getDate = __datetime.slice(0, 12);
                        return (
                          <tr
                            key={`all_${__index}`}
                            style={{ cursor: "pointer" }}
                          >
                            <td width="75%">
                              <div
                                className="d-flex all-message-email-row"
                                onClick={() => this.startChat({...__item,profile})}
                              >
                                <div className="text-center align-self-center">
                                  {!profile && <div className="chat-avatar">
                                      <h5>
                                        {displayName.match(/\b(\w)/g).join("").toUpperCase()}
                                      </h5>
                                  </div>}
                                  {profile && <img
                                    src={
                                      profile && profile !== ""
                                        ? profile
                                        : "./../assets/images/Mask.png"
                                    }
                                    alt="1"
                                  />}
                                </div>
                                <div className="align-self-center">
                                  <h3>{displayName}</h3>
                                  <p>
                                    {last_message && last_message !== ""
                                      ? last_message
                                      : "No Message"}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td width="15%" align="center">
                              {__getDate ?? "NA"}
                            </td>
                            <td width="10%" align="center">
                              {__getTime ?? "NA"}
                            </td>
                            <td width="10%" align="center">
                              <div className=" number-collase-action">
                                <a
                                  href={`#${__index}`}
                                  data-toggle="collapse"
                                  aria-expanded="false"
                                  className="dropdown-toggle collapsed"
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </a>
                                <ul
                                  className="list-unstyled number-dr-collapse collapse"
                                  id={__index}
                                >
                                  <li>
                                    <Link 
                                        className="dropdown-item"  
                                        to="#" 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.DeleteRoom(__item);
                                        }}
                                    >
                                        <img
                                            src="./../assets/images/number-drop-trash-2.svg"
                                            alt="1"
                                        />
                                        Delete Chat
                                    </Link>
                                  </li>
                                </ul>
                              </div> 
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div className="text-center w-100 no-recent-chat-div">
                        <img
                          src="./../assets/images/no-recent-chat-vector.svg"
                          alt="1"
                        />
                        <h4>
                          Currently you don’t have any messages or groups
                          created on your dashboard. Send message
                          <Link to="#" className="ml-1">
                            click here!
                          </Link>
                        </h4>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AllRecentChat);
