import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "../../../Component/Modal";

function ParticipantsModal({ openModal, onClose, participantsData,extension_listing }) {
  const [members, setMembers] = useState("");
  const [pageOfItems, setPageOfItems] = useState([]);

  useEffect(() => {
    setPageOfItems(participantsData);
  }, [participantsData]);

  const FilterMember = (member) => {
    let updatedList = pageOfItems;
    updatedList = pageOfItems.filter((value) => {
      return value.name.toLowerCase().search(member) !== -1;
    });
    setPageOfItems(member ? updatedList : participantsData);
  };

  const filterMemberHandler = (e) => {
    setMembers(e.target.value);
    FilterMember(e.target.value);
  };

  return (
    <>
      <div>
        <Modal
          open={openModal}
          size={`sm`}
          CloseModal={onClose}
          title={`Meeting Participants`}
        >
          {" "}
          <div className="assign-modal-list">
            <div className="search-assign">
              <img src="./../assets/images/search-dash.svg" alt="1" />
              <input
                className="form-control"
                type="text"
                placeholder="Search participants..."
                name="members"
                value={members}
                onChange={(e) => filterMemberHandler(e)}
              />
            </div>
            <ul className="assign-modal-list-ul">
              {pageOfItems &&
                pageOfItems.map((value, index) => {
                  const name = value?.name ?? "";
                  const formattedName = name !== "" ? name.match(/\b(\w)/g) : "";
                  const profile_image = extension_listing?.find(data=>data.customerID === value.customerID)?.profile_picture??""
                  return (
                    <li key={index}>
                      <span className="assign-li-profile">
                        {profile_image && (
                          <img src={profile_image} alt="a" />
                        )}
                        {!profile_image && <h4>{formattedName}</h4>}
                      </span>
                      <span className="assign-li-text">
                        <h5>{name? name: value.email} <i className="italic-font-color">{name ? `(${value.user_extension})` : ""}</i></h5> 
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </Modal>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({ 
  extension_listing:state?.setting?.extension_listing ?? [], 
});

export default connect(mapStateToProps)(ParticipantsModal);
 