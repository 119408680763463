import React from "react";
import { Link } from "react-router-dom";

export default function Sidebar(props) {
  const { state, Selecttab } = props;
  return (
    <div className="col-md-4 col-lg-3 pl-0">
      <div className="mid-sidebar">
        <div className="mid-nav-inner bg-gray p-0">
          <div className="mid-nav pt-4">
            <h4 className="pl-4 pr-4">Meetings</h4>
            <div className="pl-4 pr-4">
              <hr className="mt-0 mb-0 " />
            </div>
            <div className="online-store all-calls">
              <ul className="list-unstyled sidebar-meeting-ul mid-components m-0 calls-menu-ul">
                <li>
                  <a
                    href="#Meet-Drop"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle collapsed"
                  >
                    <h5 className="">
                      <span className="menu-img">
                        <img
                          src="./../assets/images/video-meet-cio.svg"
                          alt="1"
                        />
                      </span>
                      Video Meetings
                      <span className="collapse-arrow">
                        <img
                          src="./../assets/images/menu-arrow-up.svg"
                          alt="1"
                        />
                      </span>
                    </h5>
                  </a>
                  <ul className="list-unstyled collapse show" id="Meet-Drop">
                    <li>
                      <Link
                        className={state === "meet" ? "active" : ""}
                        to="#"
                        onClick={() => Selecttab("meet", "start_meet")}
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={state === "upcoming" ? "active" : ""}
                        to="#"
                        onClick={() => Selecttab("upcoming")}
                      >
                        Upcoming Meetings
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="#Voicemails"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle collapsed"
                  >
                    <h5 className="">
                      <span className="menu-img">
                        <img
                          src="./../assets/images/record-meet-ico.svg"
                          alt="1"
                          className="voice-mail-ico"
                        />
                      </span>
                      Recordings
                      <span className="collapse-arrow">
                        <img
                          src="./../assets/images/menu-arrow-up.svg"
                          alt="1"
                        />
                      </span>
                    </h5>
                  </a>
                  <ul className="list-unstyled collapse" id="Voicemails">
                    <li>
                      <Link
                        className={state === "All Recording" ? "active" : ""}
                        to="#"
                        onClick={() => Selecttab("All Recording")}
                      >
                        All Recordings
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={state === "All Recording" ? "active" : ""}
                        to="#"
                        onClick={() => Selecttab("All Recording")}
                      >
                        My Recordings
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={state === "All Recording" ? "active" : ""}
                        to="#"
                        onClick={() => Selecttab("All Recording")}
                      >
                        Shared With Me
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              {/* upgrade image div */}
              {/* <div className="meet-upgrade-img">
                <img
                  src="./../assets/images/meeting-upgarde-img.svg"
                  className="img-fluid"
                  alt="1"
                />
                <Link to="#" className="btn btn-upgrade">
                  Upgrade your plan
                </Link>
              </div> */}
              {/* upgrade image div end */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
