import React from "react";
import { Modal } from "react-bootstrap";

function NotesModal(props) {
  const { openNotesModal, notes, closeNotesModal } = props;

  return (
    <>
      <Modal
        show={openNotesModal}
        onHide={closeNotesModal}
        backdrop="static"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title-size">
            <b>Note</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row account-label account">
            <div className="col-md-12 choose-ext-div">
              <p className="d-flex justify-content-between">
                <span>{notes}</span>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NotesModal;
