import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { store } from "../../../../Services";
import { TeamListing } from "../../../../Services/Actions/callActions";
import { OnBoardProcessStatics } from "../../../../Services/Actions/dashboardActions";
import { DidNumberListing } from "../../../../Services/Actions/reportActions";
import {
  BusinessHourListing,
  CallFlowListing,
  ExtensionListing,
  IVRGreetingList,
  NumberForwardCreateUpdate,
} from "../../../../Services/Actions/settingActions";
import { formatDIDNumber, removeBackdrop } from "../../../../Utils/Common";
import VoiceFileSlider from "../VoiceFiles/VoiceFileSlider";

function CallFlowSlider(props) {
  const {
    viewOnly,
    renderPage,
    setEditIvr,
    editIVR,
    businesshour_listing,
    unassigned_did_number_list,
    ivr_listing,
    openCallFlowSlider,
    setOpenCallFlowSlider,
    extension_listing,
    team_listing,
  } = props;

  const [business_hour, setBusinessHour] = useState("");
  const [callflowName, setCallflowName] = useState("");
  const [afterhour_greeting, setAfterHourIvrId] = useState("");
  const [did_number, setDidNumber] = useState("");
  const [business_type, setBusinessType] = useState("24hrs");
  const [show, setShow] = useState(false);
  const [ivrID, setIvrId] = useState("");
  const [forwardTo, setForwardTo] = useState("");
  const [forwardToValue, setForwardToValue] = useState("");
  const [ringTime, setRingTime] = useState("");
  const [uniquecode, setUniquecode] = useState(null);
  const [onholdIvrId, setOnHoldIvrId] = useState("");
  const [leaveVoicemail, setLeaveVoicemail] = useState(false);
  const [time_condition_uuid, setTimeConditionUuid] = useState(null);
  const [id, setId] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [beginningGreeting, setBeginningGreeting] = useState(false);
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  ); 

  useEffect(()=>{
    if(openCallFlowSlider){
      BusinessHourListing(0, store.dispatch);
      IVRGreetingList(store.dispatch);
      TeamListing(0, store.dispatch);
      ExtensionListing(0, store.dispatch);
      DidNumberListing(store.dispatch);
    } 
  },[openCallFlowSlider])

  useEffect(() => {
    if (editIVR && Object.keys(editIVR).length) {
      const {
        timeout,
        forward_to_id,
        forward_to,
        name,
        business_hour_type = "24hrs",
        assigned_number,
        business_hour_id,
        ivrfileID,
        uniquecode,
        afterhour_greeting,
        leaveVoicemail,
        time_condition_uuid,
        id, 
        onholdIvrId
      } = editIVR;
      setOnHoldIvrId(onholdIvrId);
      setBusinessHour(business_hour_id);
      setCallflowName(name);
      setAfterHourIvrId(afterhour_greeting);
      setDidNumber(assigned_number);
      setBusinessType(business_hour_type);
      setIvrId(ivrfileID);
      setUniquecode(uniquecode);
      setId(id);
      setTimeConditionUuid(time_condition_uuid);
      setLeaveVoicemail(leaveVoicemail === "Y" ? true : false);
      setForwardTo(
        forward_to === "EXTENSION"
          ? "extension"
          : forward_to === "DEPARTMENT"
          ? "department"
          : ""
      );
      setForwardToValue(forward_to_id);
      setRingTime(timeout);
      setCollapsed(true);
      setBeginningGreeting(ivrfileID ? true : false);
    } else if (editIVR && !Object.keys(editIVR).length && uniquecode) {
      setBusinessHour("");
      setCallflowName("");
      setAfterHourIvrId("");
      setDidNumber("");
      setBusinessType("");
      setIvrId("");
      setUniquecode("");
      setForwardTo("");
      setForwardToValue("");
      setRingTime("");
      setId("");
      setTimeConditionUuid("");
      setLeaveVoicemail(false);
      setCollapsed(false);
      setBeginningGreeting(false);
    }
  }, [editIVR]);

  const SaveCallFlowHandler = async () => {
    if (validator.current.allValid()) {
      let data = {
        name: callflowName,
        ivrfileID: ivrID,
        business_hour_type: business_type,
        business_hourID: business_hour,
        did_number,
        forward_to:
          forwardTo === "extension"
            ? "EXTENSION"
            : forwardTo === "department"
            ? "DEPARTMENT"
            : "",
        forward_to_id: forwardToValue,
        timeout: ringTime,
        afterhour_greeting,
        leaveVoicemail: leaveVoicemail ? "Y" : "N", 
        onholdIvrId,
        type: "call-flow",
      };
      if (uniquecode && uniquecode !== "") {
        data = {
          ...data,
          uniquecode,
          time_condition_uuid,
          id,
          type: "call-flow",
        };
      }

      let response = await NumberForwardCreateUpdate(
        data,
        uniquecode && uniquecode !== "",
        "call-flow",
        store.dispatch
      );
      if (response === 1) {
        CallFlowListing({}, store.dispatch);
        DidNumberListing(store.dispatch);
        if (renderPage === "welcome") {
          OnBoardProcessStatics(store.dispatch);
        }
        setOpenCallFlowSlider(false);
        setEditIvr(false);
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const openCallFlowHandler = () => {
    setOpenCallFlowSlider(false);
    setEditIvr({});
  };

  const closeModalHandler = () => {
    setShow(false);
    removeBackdrop();
  };

  validator.current.purgeFields();

  const customHours =
    businesshour_listing &&
    businesshour_listing.filter(
      (value) => value.business_hour_type === "custom"
    ); 
 

  return (
    <>
      <div
        className={`manage-team-member-slide ${
          openCallFlowSlider || (editIVR && Object.keys(editIVR).length)
            ? ""
            : "manage-team-member-slide-close"
        }`}
      >
        {(openCallFlowSlider || (editIVR && Object.keys(editIVR).length)) && (
          <>
            <div className="member-slide-title">
              <h5>
                {viewOnly
                  ? "Call Flow"
                  : editIVR && Object.keys(editIVR).length
                  ? "Edit Call Flow"
                  : "Add New Call Flow"}{" "}
              </h5>
              <img
                src="./../assets/images/cross-add-modal.svg"
                alt="1"
                onClick={openCallFlowHandler}
              />
            </div>
            <div className="form-group">
              <label className="col-form-label"></label>
              <input
                className="form-control"
                type="text"
                name="callflowName"
                value={callflowName}
                onChange={(e) => setCallflowName(e.target.value)}
                placeholder="Name your Call Flow"
              />
              {validator.current.message(
                "Call flow name",
                callflowName,
                "required"
              )}
            </div>
            <div className="ivr-menu-side-mid-sec">
              <div className="collapse-ivr-list-div">
                <a
                  className="ivr-collapse-anchor collapsed"
                  data-toggle="collapse"
                  href="#multiCollapseExample8"
                  role="button"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample8"
                >
                  Select business operation hours
                  <span className="collapse-arrow">
                    <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                  </span>
                </a>
                {business_type === "custom" &&
                  validator.current.message(
                    "Select saved operating hours",
                    business_hour,
                    "required"
                  )}
                <div className="col p-1">
                  <div
                    className={`collapse ${collapsed ? "show" : ""}`}
                    id="multiCollapseExample8"
                  >
                    <div className="form-group business-hrs-tabs-side">
                      <div className="business-hrs-btn-group">
                        <Link
                          to="#"
                          className={`${
                            business_type === "24hrs" ? "active" : ""
                          }`}
                          onClick={() => setBusinessType("24hrs")}
                        >
                          Always Open
                        </Link>
                        <Link
                          to="#"
                          className={`${
                            business_type === "custom" ? "active" : ""
                          }`}
                          onClick={() => setBusinessType("custom")}
                        >
                          Custom
                        </Link>
                      </div>
                    </div>
                    {business_type === "custom" && (
                      <div className="card card-body">
                        <label>Select saved operating hours</label>
                        <select
                          className="form-control"
                          name="business_hour"
                          value={business_hour}
                          onChange={(e) => setBusinessHour(e.target.value)}
                        >
                          <option value=""> Select Option </option>
                          {customHours &&
                            customHours.map((value, index) => {
                              return (
                                <option
                                  key={index}
                                  value={value.id}
                                >
                                  {value.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* ------ */}
              <div className="collapse-ivr-list-div">
                <a
                  className="ivr-collapse-anchor collapsed"
                  data-toggle="collapse"
                  href="#multiCollapseExample2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample2"
                >
                  Select Number for the Flow
                  <span className="collapse-arrow">
                    <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                  </span>
                </a>
                {validator.current.message(
                  "Assign Number to Flow",
                  did_number,
                  "required"
                )}
                <div className="col p-1">
                  <div
                    className={`collapse ${collapsed ? "show" : ""}`}
                    id="multiCollapseExample2"
                  >
                    <div className="card card-body">
                      <label>Assign Number to Flow</label>
                      <select
                        className="form-control"
                        name="did_number"
                        value={did_number}
                        onChange={(e) => setDidNumber(e.target.value)}
                      >
                        <option value=""> Select Option </option>
                        {editIVR && Object.keys(editIVR).length &&  
                          <option value={did_number}>{did_number}</option> 
                        }
                        {unassigned_did_number_list &&
                          unassigned_did_number_list.map((value, index) => {
                            return (
                              <option key={index} value={value.did_number}>
                                {value.did_number}
                              </option>
                            );
                          })}
                      </select> 
                    </div>
                  </div>
                </div>
              </div>
              {/* ------ */}
              <div className="collapse-ivr-list-div">
                <a
                  className="ivr-collapse-anchor collapsed"
                  data-toggle="collapse"
                  href="#multiCollapseExample3"
                  role="button"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample3"
                >
                  Business Welcome Greeting
                  <span className="collapse-arrow">
                    <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                  </span>
                </a>
                {beginningGreeting
                  ? validator.current.message(
                      "Business Welcome Greeting",
                      ivrID,
                      "required"
                    )
                  : null}
                <div className="col p-1">
                  <div
                    className={`collapse ${collapsed ? "show" : ""}`}
                    id="multiCollapseExample3"
                  >
                    <div className="card card-body music-hold-card-body">
                      <div className="form-check pl-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={beginningGreeting}
                          id="defaultCheck1"
                          onChange={(e) =>
                            setBeginningGreeting(e.target.checked)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          This message will play at the beginning of the call
                        </label>
                      </div>
                      {beginningGreeting && 
                        <>
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="ivrID"
                              value={ivrID}
                              onChange={(e) => setIvrId(e.target.value)} 
                            >
                              <option value=""> Select Option </option>
                              {ivr_listing &&
                                ivr_listing.map((value, index) => {
                                  return (
                                    <option key={index} value={value.id}>
                                      {value.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="text-custom-file-tabs">
                            <Link
                              to="#"
                              className="ivr-outline-btn"
                              onClick={() =>
                                beginningGreeting ? setShow(true) : null
                              }
                            >
                              Custom file
                            </Link>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* ------ */}
              {/* ------ */}
              <div className="collapse-ivr-list-div">
                <a
                  className="ivr-collapse-anchor collapsed"
                  data-toggle="collapse"
                  href="#multiCollapseExample6"
                  role="button"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample6"
                >
                  Select Music on hold
                  <span className="collapse-arrow">
                    <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                  </span>
                </a> 
                <div className="col p-1">
                  <div
                    className={`collapse ${collapsed ? "show" : ""}`}
                    id="multiCollapseExample6"
                  >
                    <div className="card card-body music-hold-card-body">
                      <h3>
                        The caller will hear this music while waiting for the
                        call to be answered.{" "}
                      </h3>
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="onholdIvrId"
                          value={onholdIvrId}
                          onChange={(e) => setOnHoldIvrId(e.target.value)}
                        >
                          <option value=""> Select Option </option>
                          {ivr_listing &&
                            ivr_listing.map((value, index) => {
                              return (
                                <option key={index} value={value.id}>
                                  {value.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="text-custom-file-tabs">
                        <Link
                          to="#"
                          className="ivr-outline-btn"
                          onClick={() => setShow(true)}
                        >
                          Custom file
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ------ */}
              <div className="collapse-ivr-list-div">
                <a
                  className="ivr-collapse-anchor collapsed"
                  data-toggle="collapse"
                  href="#multiCollapseExample4"
                  role="button"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample4"
                >
                  Assign Team member/team to flow
                  <span className="collapse-arrow">
                    <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                  </span>
                </a>
                {validator.current.message(
                  "Assign Team member/team to flow",
                  forwardTo,
                  "required"
                )}
                <div className="col p-1">
                  <div
                    className={`collapse ${collapsed ? "show" : ""}`}
                    id="multiCollapseExample4"
                  >
                    <div className="card card-body">
                      <p>
                        When the caller, calls in the call will be forwarded to
                        the selected team member or team group.{" "}
                      </p>
                      <div className={`ivr-dis-menulist-repeat `}>
                        <div className="ivr-discription-contol-div">
                          <span className="assign-select-team-member call-flow-width">
                            <label>Select Team member / Team</label>
                            <select
                              className="form-control"
                              value={forwardTo}
                              name="forwardTo"
                              onChange={(e) => setForwardTo(e.target.value)}
                            >
                              <option value="">Select Option</option>
                              <option value="extension">Team Member</option>
                              <option value="department">Team</option>
                            </select>
                          </span>
                        </div>
                        {forwardTo === "extension" && (
                          <div className="ivr-discription-contol-div call-flow-margin">
                            <span className="assign-select-team-member">
                              <label>Select Team Member</label>
                              <select
                                className={`form-control`}
                                value={forwardToValue}
                                name={`forwardToValue`}
                                onChange={(e) =>
                                  setForwardToValue(e.target.value)
                                }
                              >
                                <option>Select Option</option>
                                {extension_listing &&
                                  extension_listing.map((v, i) => (
                                    <option key={i} value={v.user_extension}>
                                      {`${v.name}(${v.user_extension})`}
                                    </option>
                                  ))}
                              </select>
                              {validator.current.message(
                                "Team Member",
                                forwardToValue,
                                "required"
                              )}
                            </span>
                            <span className="ring-time-assign ml-2">
                              <label>Ring Time</label>
                              <select
                                className="form-control"
                                name="ringTime"
                                value={ringTime}
                                onChange={(e) => setRingTime(e.target.value)}
                              >
                                <option value=""> Select </option>
                                <option value="10"> 10 sec </option>
                                <option value="20"> 20 sec </option>
                                <option value="30"> 30 sec </option>
                                <option value="40"> 40 sec </option>
                                <option value="50"> 50 sec </option>
                              </select>
                              {validator.current.message(
                                "Ring Time",
                                ringTime,
                                "required"
                              )}
                            </span>
                          </div>
                        )}
                        {forwardTo === "department" && (
                          <div className="ivr-discription-contol-div call-flow-margin">
                            <span className="assign-select-team-member">
                              <label>Select Team</label>
                              <select
                                className={`form-control`}
                                value={forwardToValue}
                                name={`forwardToValue`}
                                onChange={(e) =>
                                  setForwardToValue(e.target.value)
                                }
                              >
                                <option>Select Option</option>
                                {team_listing &&
                                  team_listing.map((val, i) => (
                                    <option key={i} value={val.id}>
                                      {val.name}
                                    </option>
                                  ))}
                              </select>
                              {validator.current.message(
                                "Team",
                                forwardToValue,
                                "required"
                              )}
                            </span>
                            <span className="ring-time-assign ml-2">
                              <label>Ring Time</label>
                              <select
                                className="form-control"
                                name="ringTime"
                                value={ringTime}
                                onChange={(e) => setRingTime(e.target.value)}
                              >
                                <option value=""> Select </option>
                                <option value="10"> 10 sec </option>
                                <option value="20"> 20 sec </option>
                                <option value="30"> 30 sec </option>
                                <option value="40"> 40 sec </option>
                                <option value="50"> 50 sec </option>
                              </select>
                              {validator.current.message(
                                "Ring Time",
                                ringTime,
                                "required"
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ------ */}
              {business_type === "custom" && (
                <div className="collapse-ivr-list-div">
                  <a
                    className="ivr-collapse-anchor collapsed"
                    data-toggle="collapse"
                    href="#multiCollapseExample5"
                    role="button"
                    aria-expanded="false"
                    aria-controls="multiCollapseExample5"
                  >
                    After-hours call flow message
                    <span className="collapse-arrow">
                      <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                    </span>
                  </a>
                  {validator.current.message(
                    "After-hours call flow message",
                    afterhour_greeting,
                    "required"
                  )}
                  <div className="col p-1">
                    <div
                      className={`collapse ${collapsed ? "show" : ""}`}
                      id="multiCollapseExample5"
                    >
                      <div className="card card-body">
                        <p>
                          The caller will hear this message when they call
                          outside of this number’s business operating hours.
                        </p>
                        <div className="form-group">
                          <select
                            className="form-control"
                            name="afterhour_greeting"
                            value={afterhour_greeting}
                            onChange={(e) => setAfterHourIvrId(e.target.value)}
                          >
                            <option value="">Select Option</option>
                            {ivr_listing &&
                              ivr_listing.map((value, index) => {
                                return (
                                  <option key={index} value={value.id}>
                                    {value.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="text-custom-file-tabs">
                          <Link
                            to="#"
                            className="ivr-outline-btn"
                            onClick={() =>setShow(true)}
                          >
                            Custom file
                          </Link>
                        </div>
                        <h6 className="keep-recording-shot">
                          <img
                            src="./../assets/images/warning-ico.svg"
                            alt="1"
                          />
                          Keep the recording short as the caller has to listen
                          to the entire recording before the next stage
                        </h6> 
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="form-check pl-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={leaveVoicemail}
                  id="defaultCheck2"
                  onChange={(e) =>
                    setLeaveVoicemail(e.target.checked)
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="defaultCheck2"
                >
                  Allow the caller to leave a voicemail
                </label>
              </div>
            </div>
            {/* add new ivr menu middle end */}
            {!viewOnly && (
              <div className="bottom-btn-b-hrs bottom-btn-ivr">
                <Link
                  to="#"
                  className="btn-draft btn"
                  onClick={openCallFlowHandler}
                >
                  {/* Draft your Flow */}
                  Cancel
                </Link>
                <Link
                  to="#"
                  className="btn-save btn"
                  onClick={SaveCallFlowHandler}
                >
                  {editIVR && Object.keys(editIVR).length ? "Update" : "Create"}
                </Link>
              </div>
            )}
          </>
        )}
      </div>
      <VoiceFileSlider
        addFile={show}
        closeFileSlider={() => closeModalHandler()}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  businesshour_listing: state?.setting?.businesshour_listing ?? [],
  user_details: state?.user?.user_details?.userDetail ?? {},
  ivr_listing: state?.setting?.ivr_listing ?? [],
  extension_listing: state?.setting?.extension_listing ?? [],
  team_listing: state?.call?.team_listing ?? [],
  unassigned_did_number_list: state?.report?.unassigned_did_number_list ?? [],
});

export default connect(mapStateToProps)(CallFlowSlider);
