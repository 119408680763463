import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function MultiTopDialerCallList(props) {
  const {
    categorizedReferences,
    categories,
    AnswerCall,
    HangUpCall, 
    inactiveCallStatus,
    SetActiveCall,
    uuid, 
  } = props;

  const { conference_sessions, transfer_sessions } = useSelector((state) => {
    const {conference_sessions,transfer_sessions} = state?.call??{}
    return { conference_sessions, transfer_sessions };
  });

  return (
    <div className="">
      <div className="call-tranfer-list-left d-flex  justify-content-between flex-column">
        {Object.entries(categorizedReferences).map((value) => {
          if (value[0] === "ringing") {
            return (
              <>
                <h5>{categories[value[0]]}</h5>
                {value[1].slice(0, 1).map((item, index) => {
                  return (
                    <div className="d-flex align-items-center mb-2" key={index}>
                      <h6 className="select-h6">
                        <span>
                          {item.direction === "inbound"
                            ? item.name
                            : item.number}
                        </span>
                        <span style={{ cursor: "pointer" }}>
                          <span className={`stopwatch_${item.uuid}`}>
                            00:00
                          </span>
                          <img
                            src="./../assets/images/trsnfer-call.svg"
                            alt="1"
                            onClick={() => AnswerCall(item.uuid)}
                          />
                        </span>
                      </h6>
                      <div className="call-tranfer-list-right">
                        <Link
                          to="#"
                          className="transfer-end"
                          onClick={() => HangUpCall(item.uuid)}
                        >
                          <img
                            className="webphone-btns"
                            src="./../assets/images/icons/transfer-hang.svg"
                            alt="1"
                          />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </>
            );
          }
        })}

        {/* MULTIPLE CALL CASE LISTING */}
        {((!transfer_sessions[uuid] && !conference_sessions[uuid]) ||
          inactiveCallStatus?.length > 0) && (
          <div className="call-tranfer-list-left list-bottom">
            {<h5>Call Listing</h5>}
            {Object.entries(categorizedReferences).map((value, key) => {
              const icon =
                value[0] === "ringing"
                  ? "incoming"
                  : value[0] === "hold"
                  ? "hold_icon"
                  : "call-cut-icon";
              if (value[0] !== "ringing") {
                return (
                  <>
                    {value[1].slice(0, 1).map((item, index) => {
                      return (
                        <div className="d-flex align-items-center " key={index}>
                          <div className="select-h6 unselect-h6">
                            <span>
                              {item.direction === "inbound"
                                ? item.name
                                : item.number}
                            </span>
                            <span style={{ cursor: "pointer" }}>
                              <span className={`stopwatch_${item.uuid}`}>
                                00:00
                              </span>
                              <img
                                src={`./../assets/images/icons/multiple-calls/${icon}.png`}
                                alt="1"
                                onClick={() =>
                                  value[0] === "ringing"
                                    ? AnswerCall(item.uuid)
                                    : value[0] === "hold"
                                    ? SetActiveCall(item.uuid)
                                    : HangUpCall(item.uuid)
                                }
                              />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              }
            })}
          </div>
        )}

        {/* TRANSFER CALL CASE LISTING */}
        {transfer_sessions[uuid] && inactiveCallStatus?.length === 0 && (
          <div className="call-tranfer-list-left list-bottom">
            <h5>Transfer Call</h5>
            {Object.values(transfer_sessions[uuid]).map((item, index) => {
              const icon =
                item.status === "ringing"
                  ? "trsnfer-call.svg"
                  : item.status === "hold"
                  ? "icons/multiple-calls/hold_icon.png"
                  : "icons/multiple-calls/call-cut-icon.png";
              return (
                <div className="d-flex align-items-center " key={index}>
                  <div
                    className={`select-h6 ${
                      item.status === "hold" ? "unselect-h6" : ""
                    }`}
                  >
                    <span>
                      {item.direction === "inbound" ? item.name : item.number}
                    </span>
                    <span style={{ cursor: "pointer" }}>
                      <img src={`./../assets/images/${icon}`} alt="1" />
                    </span>
                  </div>
                  {item.status !== "hold" && (
                    <div className="call-tranfer-list-right">
                      <Link
                        to="#"
                        className="transfer-end"
                        onClick={() => HangUpCall(uuid)}
                      >
                        <img
                          className="webphone-btns"
                          src="./../assets/images/icons/transfer-hang.svg"
                          alt="1"
                        />
                      </Link>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {/* CONFERENCE CALL CASE LISTING */}
        {conference_sessions[uuid] && inactiveCallStatus?.length === 0 && (
          <div className="call-tranfer-list-left list-bottom">
            {<h5>Conference Call</h5>}
            {Object.values(conference_sessions[uuid]).map((item, index) => {
              if (typeof item !== "object") {
                return null;
              }
              const icon =
                item.status === "ringing"
                  ? "trsnfer-call.svg"
                  : item.status === "hold"
                  ? "icons/multiple-calls/hold_icon.png"
                  : "icons/multiple-calls/call-cut-icon.png";

              return (
                <div className="d-flex align-items-center " key={index}>
                  <div
                    className={`select-h6 ${
                      item.status !== "ringing" ? "unselect-h6" : ""
                    }`}
                  >
                    <span>
                      {item.direction === "inbound" ? item.name : item.number}
                    </span>
                    <span style={{ cursor: "pointer" }}>
                      <img src={`./../assets/images/${icon}`} alt="1" />
                    </span>
                  </div>
                  {item.status === "ringing" && (
                    <div className="call-tranfer-list-right">
                      <Link
                        to="#"
                        className="transfer-end"
                        onClick={() => HangUpCall(uuid)}
                      >
                        <img
                          className="webphone-btns"
                          src="./../assets/images/icons/transfer-hang.svg"
                          alt="1"
                        />
                      </Link>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default MultiTopDialerCallList;
