import React, { useEffect, useState, useRef } from "react"; 
import { useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator"; 
import { store } from "../../Services"; 
import { InstantMeeting } from "../../Services/Actions/meetActions";
import { MeetDomain } from "../../Utils/Common";

function StartMeet({ UpdateTopic }) {
    const [meeting_title, setMeetingTitle] = useState("");
    const [duration, setDuration] = useState(0);
    const [meetingDuration,setMeetingDuration] = useState([]) 
    const [customDuration,setCustomDuration] = useState([]) 
    const [clicked,setClicked] = useState(false) 
    const [, forceUpdate] = useState();
    const validator = useRef(
        new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
    );

    let video_calling_minutes = useSelector(
        (state) => state?.user?.user_details?.userDetail?.user_plan?.video_calling_minutes ?? 0
    ); 
    let moment = require('moment-timezone'); 
    const MAX_DURATION_GAP = 15 


    useEffect(() => {
        UpdateTopic("start_meet");
        getTimesInterval();
        return function cleanup() {
        UpdateTopic("");
        };
    }, []);

    const getTimesInterval = ()=>{  
        let meetingDuration = [] 
        video_calling_minutes = video_calling_minutes === "unlimited" ? 4*60 : video_calling_minutes
        for (let i = 1; i <= video_calling_minutes / MAX_DURATION_GAP; i++) { 
            meetingDuration.push(MAX_DURATION_GAP * i)
        }
        if (video_calling_minutes % MAX_DURATION_GAP > 0) { 
            meetingDuration.push(video_calling_minutes)
        }  
        if(meetingDuration){  
            setMeetingDuration([...meetingDuration])
        }  
    } 
    
    const StartMeeting = async () => { 
        // new Date().getTimezoneOffset()*(-60)  timezone
        setClicked(true)
        let dataParams = {
            title:meeting_title,
            duration:duration === "custom" ? customDuration : duration,
            timezone_offset:moment.tz.guess()
        }
        let response = await InstantMeeting(dataParams,store.dispatch)
        if (response.ResponseCode === "1") { 
            let login_via_token = localStorage.getItem("login-via-token");   
            let url = `https://${MeetDomain}/${response.roomID}?token=${(login_via_token)}`  
            window.open(url, "_blank");
            setClicked(false);
            UpdateTopic("");
        }
    };

  return (
    <>
      <div className="modal-body join-modal-body">
        <div className="col-md-12">
          <div className="meeting-modal-heading">
            <h2>Start meeting</h2>
          </div>
        </div>
        <form>
          <div className="form-group join-group">
            <label htmlFor="join-meet-id" className="col-form-label join-meet-id">
              Meeting Title
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter meeting title"
              name="meeting_title"
              value={meeting_title}
              onChange={(event) => setMeetingTitle(event.target.value)}
            />
            {validator.current.message("Meeting title", meeting_title, "required")}
          </div>
        </form>
        <form>
          <div className="form-group join-group">
            <label htmlFor="join-meet-id" className="col-form-label join-meet-id meeting-custom-duration">
              Meeting Duration
                {duration === "custom" && 
                    <img 
                        src="./../assets/images/clear-title.svg" 
                        alt="1" 
                        onClick={()=>setDuration("")}
                    />
                } 
            </label>
            {duration !== "custom" && <select
                className="form-control"
                name="duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)} 
            >   
                <option value="custom">Custom Duration</option>
                {meetingDuration && meetingDuration.map((value) => { 
                    return (
                    <option key={value} value={value}>
                        {value} mins
                    </option>
                    );
                }  
                )}
            </select> 
            }
            {duration === "custom" && 
                <input
                type="number"
                className="form-control" 
                name="customDuration"
                value={customDuration}
                placeHolder="Custom duration(in mins)"
                onChange={(e) => setCustomDuration(e.target.value)}
                autoComplete="off"
                />
            }
            {validator.current.message("Meeting duration", duration , "required")}
          </div>
        </form>
      </div>
      <div className="modal-footer meeting-modal-footer">
        <button
          type="button"
          className="btn btn-blue-text"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-meet-join"
          onClick={StartMeeting}
        >
          {clicked ? "Please Wait..." : "Join"}
        </button>
      </div>
    </>
  );
}
export default StartMeet;
