import { apiAdminUrl, apiBaseUrl } from "../../Utils/Common";
import * as constants from "../Constants/meetConstants";
import swal from "sweetalert";
import authService from "../../Utils/authService";
import { handleResponse } from "./userActions";
import { SHOW_LOADER } from "../Constants/userConstants";
import { firebaseDatabase } from "../../Utils/FirebaseService";
import { USERPANEL } from "../Constants/apiConstants";





//  ************************************************* MEET ACTIONS **********************************************************

export const ScheduleMeeting = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/create-meeting`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return 1;
      } else {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const StartMeet = async (toNumber, dispatch) => {
  let { phone, domain } = authService.getUserDetail();
  toNumber = toNumber.toString().replace("+", "");
  phone = phone.toString().replace("+", "");
  let roomID =
    toNumber > phone ? `${toNumber}_${phone}` : `${phone}_${toNumber}`;
  dispatch(makeCall(toNumber, phone, roomID, domain));
};

export const makeCall = (toNumber, phone, roomID, domain, dispatch) => {
  dispatch(PushNotifications({ roomID, toNumber, fromNumber: phone, domain },dispatch));
  firebaseDatabase.ref("Call").child(roomID).set({
    from: phone,
    to: toNumber,
    status: "ringing",
  });
}; 

export const MeetingsList = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/meeting-listing`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.MEETINGS_LIST,
          payload: data.data,
        });
        dispatch({
          type: constants.MEETINGS_COUNT,
          payload: data.count,
        });
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const UpdateMeeting = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/update-meeting`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return data;
      }
    });
};

export const DeleteMeeting = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/delete-meeting`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({
          title: "Success!",
          text: data.ResponseText,
          icon: "success",
        }); 
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    });
};

export const InstantMeeting = (params, dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}${USERPANEL}instant-meeting`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return data;
      }
    });
};

export const AuthorizeMeeting = (params, dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}authorize-meeting`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return { code: 1 };
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return { code: 0, response: data.ResponseText };
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      return { code: 0, response: error };
    });
};



// ******************************************** NOTIFICATION ACTIONS *********************************************************

export const PushNotifications = (data,dispatch) => { 
  const request = new Request(
    `${apiBaseUrl}Nxatel/push.php?service=apns&tonumber=${data.toNumber}&type=video&number=${data.fromNumber}&message=${data.roomID}&domain=${data.domain}&NotificationType=JitsiCall`,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }
  );
  fetch(request); 
};

// ********************************************* RECORDING ACTIONS ********************************************************

export const VideoRecordingList = (dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/recording-url-listing`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.VIDEO_RECORDING_LIST,
          payload: data.data,
        });
        dispatch({
          type: constants.RECORDING_COUNT,
          payload: data.count,
        });
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

