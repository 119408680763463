import React, { Component } from "react";
import { withRouter } from "./../../Utils/withRouter"; 
import Tooltips from "../Tooltips"; 
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { WebDomain } from "./../../Utils/Common";
import authService from "./../../Utils/authService";
import { firebaseDatabase } from "./../../Utils/FirebaseService"; 
import { store } from "../../Services";
import { logOut } from "../../Services/Actions/userActions"; 
import CallAlertPopup from "../../Pages/Sip/CallFrames/CallAlertPopup";

class NavBar extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            callStatusRef: firebaseDatabase.ref("Call"),
            callData: { callStatusType: "Connecting..." },
            showCallAlertPopup: false,
        }
    }

    componentDidMount() {
        const { callStatusRef } = this.state;
        callStatusRef.on("child_added", (snapshot) => {
            this.manageCall(snapshot, callStatusRef);
        });

        callStatusRef.on("child_changed", (snapshot) => {
            this.manageCall(snapshot, callStatusRef);
        });
    }

    manageCall = (snapShot, callStatusRef) => {
        let { phone } = authService.getUserDetail();
        const key = snapShot.key;
        const callStatus = key.split("_").includes(phone.replace('+', ''));
        const callData = snapShot.val();
        if (callStatus) {
            if (callData.from === phone.replace("+", "")) {
                callData.callType = "oc";
                callData.conf = key;
                callData.name = callData.to;
                callData.callStatusType = "Ringing...";
                if (callData.status === "accepted") {
                    window.open(key, "_blank");
                    setTimeout(() => {
                        callStatusRef.child(key).remove();
                        this.closeCallAlertPopup();
                    }, 1000);
                }
                if (callData.status === "rejected" || callData.status === "terminated") {
                    callData.callStatusType = callData.status === "terminated" ? "Call Terminated" : "Call Rejected";
                    setTimeout(() => {
                        this.closeCallAlertPopup();
                        callStatusRef.child(key).remove();
                    }, 2000);
                }
            }

            if (callData.from !== phone.replace("+", "")) {
                console.error("Ringing");
                callData.callType = "ic";
                callData.conf = key;
                callData.name = callData.from;
                callData.callStatusType = "Incoming...";
                if (callData.status === "accepted") {
                    if (localStorage.fingerPrint === this.state.fingerPrint) {
                        window.open(key, "_blank"); 
                        setTimeout(() => {
                            this.closeCallAlertPopup();
                            callStatusRef.child(key).remove();
                        }, 10);
                    }
                }
                if (callData.status === "rejected" || callData.status === "terminated") {
                    callData.callStatusType = callData.status === "terminated" ? "Call Terminated" : "Call Rejected";
                    setTimeout(() => {
                        callStatusRef.child(key).remove();
                        this.closeCallAlertPopup();
                    }, 2000);
                }
            }
            this.setState({ callData, showCallAlertPopup: true });
        }
    }

    closeCallAlertPopup = () => {
        this.setState({ callData: {}, showCallAlertPopup: false });
    }
 
    LogOut = async() => {
        swal({
            text: "Are you sure want to logout?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (Cancel) => {
        if (Cancel) {
            await logOut(store.dispatch) 
            authService.logout();
            window.location.href = `https://${WebDomain}/`;
        }
        });
    }; 
    

    render() {  
        const { user_details: { type: user_type, status,payment_status } } = this.props; 
        const {showCallAlertPopup , callData} = this.state 
        let path = window.location.pathname.replace("/","")
        return (
            <> 
                <nav id="sidebar" className="active">
                    <div className="sidebar-header ">
                        <h3>
                            <Link 
                                to={status && status === "P" ? "rica-process" : "dashboard"} 
                            >
                                <img src="./../assets/images/logo.png" alt="1" />
                            </Link>
                        </h3>
                        <div className="Name">
                            <div className="profile-img">
                                <img src="./../assets/images/logo.png" alt="1" /> 
                            </div>
                        </div>
                    </div> 
                    <div className="side-icons d-flex flex-column justify-content-between">
                        <ul className="list-unstyled components pt-3"> 
                            {payment_status === "A" && <li className="">
                                <Tooltips heading={"Dashboard"} placement={"top"}>
                                    <Link
                                        to={status && status === "P" ? "rica-process" : "dashboard"} 
                                        className={["dashboard", "welcome"].includes(path) ? "dropdown-toggle active" : "dropdown-toggle"}
                                    >
                                        <img src="./../assets/images/icons/1.svg" alt="1" />
                                    </Link>
                                </Tooltips>
                            </li>}
                            {(status === "A" && payment_status === "A") && 
                            <li className="authorized-user">
                                <Tooltips heading={"Call"} placement={"top"}>
                                    <Link
                                        to="/call" 
                                        className={path === "call" ? "dropdown-toggle active" : "dropdown-toggle"}
                                    >
                                        <img src="./../assets/images/icons/2.svg" alt="1" />
                                        <span>Call</span>
                                    </Link>
                                </Tooltips>
                            </li>}
                            {(status === "A" && payment_status === "A") && 
                            <li className="authorized-user">
                                <Tooltips heading={"Meet"} placement={"top"}>
                                    <Link
                                        to="/meet" 
                                        className={path === "meet" ? "dropdown-toggle active" : "dropdown-toggle"} 
                                    >
                                        <img src="./../assets/images/icons/3.svg" alt="1" />
                                        <span className="ml-1">Meet</span>
                                    </Link>
                                </Tooltips>
                            </li>}
                            {(status === "A" && payment_status === "A") && 
                            <li className="authorized-user">
                                <Tooltips heading={"Message"} placement={"top"}>
                                    <Link
                                        to="/chat" 
                                        className={path === "chat" ? "dropdown-toggle active" : "dropdown-toggle"}
                                    >
                                        <img src="./../assets/images/icons/4.svg" alt="1" />
                                        <span>Message</span>
                                    </Link>
                                </Tooltips>
                            </li>} 
                            {((user_type === 'C' || user_type === 'A') && status === "A" && payment_status === "A") &&  
                                <>
                                    <li className="authorized-user">
                                        <Tooltips heading={"Power Apps"} placement={"top"}>
                                            <Link
                                                to="/power-apps" 
                                                className={path === "power-apps" ? "dropdown-toggle active" : "dropdown-toggle"}
                                            >
                                                <img src="./../assets/images/icons/9.svg" alt="1" />
                                                <span>Power Apps</span>
                                            </Link>
                                        </Tooltips>
                                    </li>
                                    <li className="authorized-user">
                                        <Tooltips heading={"Settings"} placement={"top"}>
                                            <Link
                                                to="/settings" 
                                                className={path === "settings" ? "dropdown-toggle active" : "dropdown-toggle"} 
                                            >
                                                <img src="./../assets/images/icons/10.svg" alt="1" />
                                                <span>My Account</span>
                                            </Link>
                                        </Tooltips>
                                    </li>
                                </> 
                            }
                        </ul>
                        <div className="progress-sec">
                            <ul className="list-unstyled components">
                                {((user_type === 'C' || user_type === 'A') && status === "A" && payment_status === "A") && <li className="authorized-user">
                                    <Tooltips heading={"Reports"} placement={"top"}>
                                        <Link
                                            to="/reports" 
                                            className={path === "reports" ? "dropdown-toggle active" : "dropdown-toggle"}
                                        >
                                            <img src="./../assets/images/icons/14.svg" alt="1" />
                                            <span>Reports</span>
                                        </Link>
                                    </Tooltips>
                                </li>}
                                <li> <Tooltips heading={"Support"} placement={"top"}>
                                    <Link
                                        to="/support"
                                        className={path === "support" ? "dropdown-toggle active" : "dropdown-toggle"} 
                                    >
                                        <img src="./../assets/images/icons/12.svg" alt="1" />
                                        <span>Support</span>
                                    </Link>
                                </Tooltips>
                                </li>
                                <li>
                                    <Tooltips heading={"Sign out"} placement={"top"}>
                                        <Link to="#" onClick={this.LogOut}>
                                            <img src="./../assets/images/icons/13.svg" alt="1" />
                                            <span>Sign out</span>
                                        </Link>
                                    </Tooltips>
                                </li>
                            </ul>
                        </div>
                    </div> 
                </nav>   
                <CallAlertPopup show={showCallAlertPopup} handleClose={this.closeCallAlertPopup} callData={callData} />
            </>
        );
    }
}

const mapStateToProps = (state) => {  
    return {
        user_details: state?.user?.user_details?.userDetail ?? {}
    } 
};

export default connect(mapStateToProps)(withRouter(NavBar));