import { WebDomain } from "../Utils/Common";

  
const AuthProvider = ({ children }) => { 
  let isAuthenticated = localStorage.getItem("token");  

  if (!isAuthenticated) {
     return window.location.href = `https://${WebDomain}` 
  }

  return children;
};
export default AuthProvider;
