import firebase from "firebase";
import "firebase/auth";
import "firebase/database";
import "firebase/storage"; 

const config = {
  apiKey: "AIzaSyByQ0uZ6o2AAhmF9zogzdhcLn7rKRUOyJc",
  authDomain: "nxatel-dev.firebaseapp.com",
  databaseURL: "https://nxatel-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "nxatel-dev",
  storageBucket: "nxatel-dev.appspot.com",
  messagingSenderId: "697631177184",
  appId: "1:697631177184:web:546c20268e3dc0bbb8d385"
};

firebase.initializeApp(config);
export const firebaseApp = firebase;
export const firebaseAuth = firebase.auth();
export const firebaseDatabase = firebase.database();
export const isFCMSupported = firebaseApp.messaging.isSupported();
export const myStorage = firebase.storage()


export default firebase;
