import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Pagination from "../../../../Component/Pagination";
import { store } from "../../../../Services";
import { SMSChatListing } from "../../../../Services/Actions/callActions";
import {
  convertUtcToLocalTime,
  formatDIDNumber,
} from "../../../../Utils/Common";
import MessageContactModal from "../../../Calls/SMS/MessageContactModal";
import moment from "moment";
import { connect } from "react-redux";
import { OPEN_NXATEL_USER_CHAT } from "../../../../Services/Constants/callConstants";
import { withRouter } from "../../../../Utils/withRouter";

function SMS({ SetTab, sms_chat_list ,navigate }) {
  const [__smsModal, setSmsModal] = useState(false);
  const [__searchSMS, setSearchSMS] = useState("");
  const [__totalItems, setTotalItems] = useState([]);
  const [__pageOfItems, setPageOfItems] = useState([]);

  useEffect(() => {
    SMSChatListing(store.dispatch);
  }, []);

  useEffect(() => {
    setTotalItems(sms_chat_list);
  }, [sms_chat_list]);

  const onSearchHandler = (e) => {
    const value = e.target.value.toString().toLowerCase();
    setSearchSMS(value);
    setTotalItems(
      sms_chat_list.filter(
        (val) => 
          val.text.toLowerCase().includes(value) ||
          val.direction.toLowerCase().includes(value)
      )
    );
  };

  const UpdateData = (pageofItems) => {
    setPageOfItems(pageofItems);
  };

  const chatHandler = (value) =>{ 
    navigate("/chat")
    store.dispatch({ type: OPEN_NXATEL_USER_CHAT, payload: {...value,ActiveTab:"sms"}});   
  }

  return (
    <>
      <div className="phone-system-inner-main-div">
        <div className="phone-system-inner-div">
          <div className="phone-system-inner-head">
            <div className="ps-inner-head-left">
              <h3>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => SetTab("phone")}
                  className="phone-system-name"
                >
                  Phone Settings
                </span>
                <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                <span>SMS</span>
              </h3>
            </div>

            <div className="ps-inner-head-right">
              <span className="search-ps-inner">
                <img src="./../assets/images/search-dash.svg" alt="1" />
                <input
                  className="form-control"
                  type="text"
                  name="search"
                  value={__searchSMS}
                  onChange={onSearchHandler}
                  placeholder="Search"
                />
              </span>
              <Link
                to="#"
                className="create-ps-num-btn"
                style={{ cursor: "pointer" }}
                onClick={() => setSmsModal(true)}
              >
                Send SMS
              </Link>
            </div>
          </div>
          {/* table start */}
          <div className="phone-system-inner-table">
            <div className="table-responsive call-info-scroll single-table-height">
              <table className="table">
                <thead>
                  <tr>
                    <th>Creation Date</th>
                    <th>Name</th>
                    <th>Message</th>
                    <th>Direction</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {__pageOfItems &&
                    __pageOfItems.map((value, index) => {
                      let date_time = convertUtcToLocalTime(value.creationdate);
                      let formattedDate = moment(date_time)
                        .locale("en")
                        .format("ll");
                      let formattedTime = moment(date_time)
                        .locale("en")
                        .format("LT");
                      return (
                        <tr key={index}>
                          <td>{`${formattedDate} ${formattedTime}`} </td>
                          <td>
                            <span className="ps-name-number">
                              <span className="avtar-text-sp">
                                <h5 >
                                  {value.user_name
                                  ? value.user_name
                                      .match(/\b(\w)/g)
                                      .join("")
                                      .toUpperCase()
                                  : "NA"}
                                </h5>
                              </span> 
                              <span>
                                <h3>
                                  {" "}
                                  {value.user_name
                                    ? value.user_name
                                    : formatDIDNumber(value.to)}
                                </h3>
                              </span>
                            </span>
                          </td>
                          <td>{value.text}</td>
                          <td>{value.direction}</td>
                          <td>
                            <a title="Chat" className="edit-business-hrs" onClick={()=>chatHandler(value)}>
                              <img
                                src="./../assets/images/edit-new.svg"
                                alt="1"
                              />
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <Pagination data={__totalItems} currentData={UpdateData} />
          </div>
        </div>
      </div>
      {__smsModal && (
        <MessageContactModal
          OpenMessageModal={__smsModal}
          closeMessageContactModal={() => setSmsModal(false)}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  sms_chat_list: state?.call?.sms_chat_list ?? [],
});

export default connect(mapStateToProps)(withRouter(SMS));
