
import React from 'react';
import { Outlet } from 'react-router-dom'; 
import Alerts from '../Alerts';
import Loader from '../Loader';
import Footer from './Footer';

function SignupLayout(props) { 
    const activeUrl = window.location.pathname.replace("/","")
    return (
        <>
            <Outlet {...props}  />
            {!activeUrl.includes("fetch-payment") && <Footer />}
            <Loader />
            <Alerts />
        </>
    );
}

export default SignupLayout; 