import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { store } from "../../../../Services";
import { OnBoardProcessStatics } from "../../../../Services/Actions/dashboardActions";
import {
  CreateUpdateBusinessHour,
  DeleteBusinessHour,
  TimezoneList,
} from "../../../../Services/Actions/settingActions";
import swal from "sweetalert";

const daysOfWeeks = [
  { name: "Monday", value: "M" },
  { name: "Tuesday", value: "T" },
  { name: "Wednesday", value: "W" },
  { name: "Thursday", value: "T" },
  { name: "Friday", value: "F" },
  { name: "Saturday", value: "S" },
  { name: "Sunday", value: "S" },
];

const initialState = {
  dayName: [],
  business_name: "",
  selected_timezone: "",
  business_type: "custom",
  Monday_start: "",
  Tuesday_start: "",
  Wednesday_start: "",
  Thursday_start: "",
  Friday_start: "",
  Saturday_start: "",
  Sunday_start: "",
  Monday_end: "",
  Tuesday_end: "",
  Wednesday_end: "",
  Thursday_end: "",
  Friday_end: "",
  Saturday_end: "",
  Sunday_end: "",
  showAddTimeSlot: false,
};
class AddBusinessHour extends Component {
  constructor(props) {
    super(props);
    this.formValidator = new SimpleReactValidator();
    this.state = initialState;
  }

  componentDidMount = async () => {
    TimezoneList(store.dispatch);
  };

  componentDidUpdate(prevProps) {
    const { addBusinessHour, businessData } = this.props;
    if (prevProps.addBusinessHour !== addBusinessHour) {
      let days = [];
      let hours = businessData?.businessHourSetting;
      hours &&
        hours.map((val, i) => {
          days.push(val.weekday);
          this.setState({
            [`${val.weekday}_start`]: val.start_hour,
            [`${val.weekday}_end`]: val.end_hour,
          });
        });
      this.setState({
        business_name: businessData?.name ?? "",
        dayName: days,
        businessID: businessData?.id ?? "",
        showAddTimeSlot: businessData ? true : false,
        selected_timezone: businessData?.timezone,
        business_type: businessData?.business_hour_type ?? "custom",
      });
    }
  }

  selectWeekDays = (day, id) => {
    const { dayName } = this.state;
    if (dayName && !dayName.includes(day)) {
      this.setState(
        {
          randomdayName: [...dayName, day],
        },
        () => {
          const { randomdayName } = this.state;
          let order = {
            Monday: 1,
            Tuesday: 2,
            Wednesday: 3,
            Thursday: 4,
            Friday: 5,
            Saturday: 6,
            Sunday: 7,
          };
          randomdayName.sort(function (a, b) {
            return order[a] - order[b];
          });
          this.setState({ dayName: randomdayName });
        }
      );
    }
  };

  handleChange = (e, name) => {
    this.setState({ [name]: e.target.value });
  };

  addTimeSlotHandler = () => {
    this.setState({
      showAddTimeSlot: true,
    });
  };

  removeAllSlotsHanlder = () => {
    this.setState({
      dayName: [],
      showAddTimeSlot: false,
    });
  };

  DeleteBusinessHour = () => {
    const { businessID } = this.state;
    swal({
      text: "Are you sure want to delete this business hour?",
      buttons: true,
      icon: "warning",
      title:"Warning!",
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let res = await DeleteBusinessHour(businessID, store.dispatch);
        if (res === 1) {
          this.props.closeBusinessHour();
        }
      } else {
        console.log("you clicked on false");
      }
    });
  };

  CreateUpdateBusinessHours = async () => {
    const { isEdit, closeBusinessHour, renderPage } = this.props;
    const {
      dayName,
      business_type,
      selected_timezone,
      businessID,
      business_name,
    } = this.state;
    let validate = this.formValidator;
    let data = [];
    let response;
    dayName &&
      dayName.map((val) => {
        return data.push({
          isSelected: true,
          day: val,
          starttime: this.state[`${val}_start`],
          endtime: this.state[`${val}_end`],
        });
      });
    let postData = {
      name: business_name,
      business_hour_type: business_type,
      business_days: business_type === "custom" ? data : [],
      timezone: selected_timezone,
    };
    if (validate.allValid()) {
      if (isEdit) {
        response = await CreateUpdateBusinessHour(
          { ...postData, id: businessID },
          isEdit,
          store.dispatch
        );
        if (response === 1) {
          this.setState({ dayName: [] });
          closeBusinessHour();
        }
      } else {
        response = await CreateUpdateBusinessHour(
          postData,
          isEdit,
          store.dispatch
        );
        if (response === 1) {
          if (renderPage === "welcome") {
            OnBoardProcessStatics(store.dispatch);
          }
          this.setState({ dayName: [] });
          closeBusinessHour();
        }
      }
    } else {
      validate.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const {
      addBusinessHour,
      closeBusinessHour,
      timezone_list,
      isEdit,
      viewOnly,
    } = this.props;
    const {
      business_name,
      selected_timezone,
      business_type,
      dayName,
      showAddTimeSlot,
    } = this.state;
    return (
      <>
        <div
          className={`manage-team-member-slide ${
            addBusinessHour ? "" : "manage-team-member-slide-close"
          }`}
        >
          {addBusinessHour && (
            <>
              <div className="member-slide-title">
                <h5>Business Hours</h5>
                <img
                  src="./../assets/images/cross-add-modal.svg"
                  alt="1"
                  onClick={() => closeBusinessHour()}
                />
              </div>
              <div className="business-hrs-side-form">
                <div className="form-group">
                  <label className="col-form-label">
                    Name your business hours
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="business_name"
                    value={business_name}
                    onChange={(e) =>
                      this.setState({ business_name: e.target.value })
                    }
                  />
                  {this.formValidator.message(
                    "Business Name",
                    business_name,
                    "required"
                  )}
                </div>
                <div className="form-group">
                  <label className="col-form-label">Time Zone</label>
                  <select
                    className="form-control"
                    name="selected_timezone"
                    value={selected_timezone}
                    onChange={(e) =>
                      this.setState({ selected_timezone: e.target.value })
                    }
                  >
                    <option value="">Select Time Zone</option>
                    {timezone_list instanceof Array &&
                      timezone_list.map((value) => {
                        return (
                          <option key={value.timezoneID} value={value.gmtoffset}>
                            {`${value.gmtzone} (${value.gmtoffset})`}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="form-group business-hrs-tabs-side">
                  <div className="business-hrs-btn-group">
                    <Link
                      to="#"
                      className={`${business_type === "24hrs" ? "active" : ""}`}
                      onClick={() => this.setState({ business_type: "24hrs" })}
                    >
                      Always Open
                    </Link>
                    <Link
                      to="#"
                      className={`${
                        business_type === "custom" ? "active" : ""
                      }`}
                      onClick={() => this.setState({ business_type: "custom" })}
                    >
                      Custom
                    </Link>
                  </div>
                </div>
                {business_type === "custom" && (
                  <div className="form-group business-hrs-calender-side">
                    <ul className="business-hrs-day-ul">
                      {daysOfWeeks &&
                        daysOfWeeks.map((days) => {
                          return (
                            <li
                              className={`${
                                dayName && dayName.includes(days.name)
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                this.selectWeekDays(days.name, days.id)
                              }
                            >
                              {days.value}
                            </li>
                          );
                        })}
                    </ul>
                    <div className="business-hrs-slot-list">
                      {showAddTimeSlot &&
                        dayName &&
                        dayName.map((value, i) => {
                          return (
                            <h4 key={i}>
                              <span>{value}</span>
                              <input
                                className="business-hour-slot"
                                type="time"
                                placeholder="09:00 AM"
                                onChange={(e) =>
                                  this.handleChange(e, `${value}_start`)
                                }
                                value={this.state[`${value}_start`]}
                              />
                              <input
                                className="business-hour-slot"
                                type="time"
                                placeholder="10:00 PM"
                                onChange={(e) =>
                                  this.handleChange(e, `${value}_end`)
                                }
                                value={this.state[`${value}_end`]}
                              />
                            </h4>
                          );
                        })}
                    </div>
                    <div className="add-note-div">
                      <Link
                        to="#"
                        className="add-time-slote-sidebar-b-hrs"
                        onClick={this.addTimeSlotHandler}
                      >
                        <i className="fas fa-plus"></i>Add a time slot
                      </Link>
                      <Link
                        to="#"
                        className="close-b-hrs-calender"
                        onClick={this.removeAllSlotsHanlder}
                      >
                        <i className="fas fa-times"></i>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              {!viewOnly && (
                <div className="bottom-btn-b-hrs">
                  <Link
                    to="#"
                    className="btn-save btn"
                    onClick={this.CreateUpdateBusinessHours}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Link>
                  {isEdit ? (
                    <Link
                      to="#"
                      className="btn-delete btn"
                      onClick={this.DeleteBusinessHour}
                    >
                      Delete
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      className="btn-delete btn"
                      onClick={() =>
                        this.setState({
                          dayName: [],
                          business_type: "custom",
                          business_name: "",
                          selected_timezone: "",
                        })
                      }
                    >
                      Cancel
                    </Link>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  timezone_list: state?.setting?.timezone_list ?? [],
});

export default connect(mapStateToProps)(AddBusinessHour);
