import React, { Component } from "react";
import ChangePlanDetails from "./ChangePlanDetails";
import moment from "moment";
import {
  InitiatePayment,
  PlansList,
} from "../../../../Services/Actions/signupActions";
import { FetchUserDetails } from "../../../../Services/Actions/userActions";
import {
  CancelPlanHandler,
  DowngradePlanHandler,
  RecentTransactionListing,
  UpgradePlanHandler,
} from "../../../../Services/Actions/settingActions";
import { FetchPlanCostCalc, FetchRenewalPlanCostCalc, getDateDifferenceWithToday } from "../../../../Utils/Common";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Sliders from "../../../Signup/Sliders";
import { store } from "../../../../Services";
import swal from "sweetalert";
import Modal from "../../../../Component/Modal";

const initialState = {
  user_allowed: 0,
  planID: 0,
  name: "",
  min_user: 0,
  max_user: 0,
  duration: 1,
  plan_cost: "",
  video_calling_minutes: 0,
  voice_minute: 0,
  free_did_number: 0,
  participants_for_video_confrence: 0,
  annual_plan_discount: 0,
  two_year_plan_discount: 0,
  pageType: "plan-page",
  currency: "R",
  storage: "",
  planType: "",
  PlanCostDetails: {},
  renewalPlanAmount: 0,
  selectedCard: "",
};

class ChangePlanModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async () => {
    PlansList(store.dispatch);
  };

  componentDidUpdate = () => {
    const {
      plans_list,
      user_details: {
        user_plan: { name, plan_duration, users, currency },
      },
    } = this.props;
    const { planID: plan_id } = this.state;
    if (plans_list instanceof Array && plan_id === 0) {
      // Current Plan scenerio here
      plans_list.map((value) => {
        if (value.name.includes(name)) {
          const {
            planID,
            name,
            plan_cost,
            video_calling_minutes,
            voice_minute,
            free_did_number,
            min_user,
            max_user,
            participants_for_video_confrence,
          } = value;
          this.setState({
            planID,
            name,
            plan_cost,
            video_calling_minutes,
            voice_minute,
            free_did_number,
            min_user,
            max_user,
            participants_for_video_confrence,
            user_allowed: users,
            duration: plan_duration,
            currency,
          });
        }
      });
    }
  };

  onSliderChange = (e, user_allowed) => {
    // renewal plan details in states
    const { planType } = this.state;
    // current plan details in props
    const {
      user_details: {
        user_plan: { users },
        active_user_count,
      },
    } = this.props;
    const wrapper = document.createElement("div");
    let planUsers = planType === "Upgrade Plan" ? users : active_user_count;
    if (user_allowed <= planUsers) {
      let text =
        planType === "Upgrade Plan"
          ? `Members can't be reduced while upgrading the plan.You must change the Plan.`
          : `You seems to have ${active_user_count} active members, You can't reduce less than ${active_user_count} active members .`;
      wrapper.innerHTML = `<p>${text}</p>`;
      swal({
        icon: "error",
        title: `Error`,
        buttons: [true, "Change Plan"],
        content: wrapper,
        className: "add-subscription",
      }).then(async (action) => {
        if (action) {
          this.setState({
            pageType: "plan-page",
            user_allowed: users,
          });
        }
      });
    } else {
      this.setState({ user_allowed }, () => {
        this.renewalPlanHandler();
      });
    }
  };

  OnSelectPlanHanlder = (value) => {
    const {
      planID,
      name,
      plan_cost,
      video_calling_minutes,
      voice_minute,
      free_did_number,
      min_user,
      max_user,
      participants_for_video_confrence,
      annual_plan_discount,
      two_year_plan_discount,
      storage,
    } = value;
    this.setState({
      planID,
      name,
      plan_cost,
      video_calling_minutes,
      voice_minute,
      free_did_number,
      min_user,
      max_user,
      participants_for_video_confrence,
      two_year_plan_discount,
      annual_plan_discount,
      storage,
    });
  };

  onCancelPlan = async () => {
    const {
      user_details: {
        plan_request: { requestID },
      },
      handleClose,
    } = this.props;
    let plan_res = await CancelPlanHandler({ id: requestID }, store.dispatch);
    if (plan_res === 1) {
      FetchUserDetails(store.dispatch);
      handleClose();
      swal({
        icon: "./../assets/images/subscription-successfull.svg",
        title: "Plan Cancelled Successfully.",
        className: "add-subscription",
      });
    }
  };

  switchPlanDuration = () => {
    const { duration, planType } = this.state;
    const {
      user_details: {
        user_plan: { plan_duration },
      },
    } = this.props;
    if (planType === "Upgrade Plan") {
      switch (plan_duration) {
        case 1: {
          this.setState(
            { duration: duration === 1 ? 12 : duration === 12 ? 24 : 1 },
            () => {
              this.renewalPlanHandler();
            }
          );
          break;
        }
        case 12: {
          this.setState({ duration: duration === 12 ? 24 : 12 }, () => {
            this.renewalPlanHandler();
          });
          break;
        }
        default:
          return false;
      }
    } else if (planType === "Downgrade Plan") {
      this.setState(
        { duration: duration === 1 ? 12 : duration === 12 ? 24 : 1 },
        () => {
          this.renewalPlanHandler();
        }
      );
    }
  };

  openSubscriptionPage = (planType) => {
    this.setState(
      {
        pageType: "payment-page",
        planType,
      },
      () => {
        const { planType, name } = this.state;
        const {
          user_details: { active_user_count },
          card_listing,
        } = this.props;
        let primaryCard =
          card_listing && card_listing.filter((value) => value.primary === "Y");
        this.setState({ selectedCard: primaryCard?.[0]?.registrationID });
        if (planType === "Downgrade Plan") {
          if (name === "Start Up" && active_user_count > 3) {
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p>You seems to have ${active_user_count} member,You can't have more then 3 member to subscribe for pay as you go plan.</p>`;
            swal({
              icon: "error",
              title: `Error`,
              content: wrapper,
              className: "add-subscription",
            });
            this.setState({ pageType: "plan-page" });
          }
          this.renewalPlanHandler();
        } else {
          this.renewalPlanHandler();
        }
      }
    );
  };

  renewalPlanHandler = () => {
    const {
      user_allowed,
      duration,
      two_year_plan_discount,
      annual_plan_discount,
      plan_cost,
      currency,
    } = this.state; //renewal plan details
    const {
      user_details: {
        user_plan: { amount, plan_duration, plan_start_date },
      },
    } = this.props; // current plan details
    let data = {
      user_allowed,
      duration,
      two_year_plan_discount,
      annual_plan_discount,
      plan_cost,
      currency,
    };
    let PlanCostDetails = FetchPlanCostCalc(data);
    let renewalPayableAmount = FetchRenewalPlanCostCalc({
      planCost: PlanCostDetails?.payable_amount,
      amount,
      plan_duration,
      usedPlanDays: getDateDifferenceWithToday(plan_start_date),
    });
    this.setState({
      renewalPlanAmount: renewalPayableAmount,
      PlanCostDetails,
    });
  };

  AddCardHandler = async () => {
    const {
      user_details: { firstname, lastname, email, phone },
      handleClose,
    } = this.props;
    let data = {
      Currency: "ZAR",
      Price: 0,
      RecurringType: "INITIAL",
      CustomerName: firstname,
      CustomerSurname: lastname,
      CustomerEmail: email,
      CustomerMobile: phone,
      CreateRegistration: true,
      PaymentType: "",
    };
    let order_details = {
      save_card: "Y",
      type: "C",
      payment_type: "save-card",
      path_name: "add-card",
    };
    let response = await InitiatePayment(
      data,
      order_details, 
      store.dispatch
    );
    if (response === 1) {
      handleClose();
      window.$("#payment-modal").modal("show");
    }
  };

  confirmSubscriptionPlan = async () => {
    const {
      user_details: {
        user_plan: { currency,users},
      },
      handleClose,
    } = this.props;
    const {
      planType,
      planID,
      user_allowed,
      duration,
      PlanCostDetails,
      renewalPlanAmount,
      name,
      selectedCard,
    } = this.state;
    let postData = {
      planID,
      duration,
      plan_users: user_allowed,
      newely_added_user:parseInt(user_allowed)-parseInt(users),
      currency,
      tax: PlanCostDetails?.tax,
      amount: PlanCostDetails?.amount,
      payable_amount: renewalPlanAmount,
      registrationID: selectedCard,
    };
    let _PlanAPI =
      planType === "Downgrade Plan"
        ? DowngradePlanHandler(postData, store.dispatch)
        : UpgradePlanHandler({ ...postData, plan_name: name }, store.dispatch);
    let plan_res = await _PlanAPI;
    if (plan_res?.code === 1) {
      FetchUserDetails(store.dispatch);
      RecentTransactionListing(store.dispatch);
      handleClose();
      swal({
        icon: "./../assets/images/subscription-successfull.svg",
        title: plan_res?.response,
        className: "add-subscription",
      });
    }
  };

  render() {
    const {
      planType,
      name,
      storage,
      video_calling_minutes,
      voice_minute,
      max_user,
      planID,
      pageType,
      user_allowed,
      min_user,
      duration,
      PlanCostDetails,
      renewalPlanAmount,
      currency,
      selectedCard,
    } = this.state;
    const { plans_list, card_listing, user_details, open, handleClose } =
      this.props;
    const CollectionList = {
      planID,
      plans_list,
      user_details,
      OnSelectPlanHanlder: this.OnSelectPlanHanlder,
      onCancelPlan: this.onCancelPlan,
      openSubscriptionPage: this.openSubscriptionPage,
    };
    let currentPlanDuration = user_details?.user_plan?.plan_duration;
    let renewalPlanDate = moment(
      user_details?.user_plan?.plan_start_date,
      "YYYY-MM-DD"
    )
      .add(
        duration === 24 ? 2 : 1,
        duration === 1 ? "M" : duration === 12 || 24 ? "y" : ""
      )
      .locale("en")
      .format("ll");

    return (
      <>
        <Modal
          open={open}
          CloseModal={handleClose}
          title={""}
          showActiveClass={open}
          size={`custom-xxl`}
        >
          {pageType === "plan-page" && (
            <div className="billing-tab-inner">
              <div className="pricing-step billing-pricing-steps pricing-signup pb-5">
                <div className="row">
                  <div className="pricing pricing-palden">
                    <ChangePlanDetails {...CollectionList} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {pageType === "payment-page" && (
            <>
              <div className="plansubscription-modal-body">
                <div className="subscription-plan-modal-body-inner">
                  <div className="subscription-plan-modal-left">
                    <h3>Subscription Plan: Payment Details</h3>
                    <div className="subscription-plan-range-slider">
                      <div className="Size-team">
                        {parseInt(max_user) > 0 && (
                          <div className="row">
                            <Sliders
                              onSliderChange={this.onSliderChange}
                              max={parseInt(max_user)}
                              min={parseInt(min_user)}
                              value={user_allowed}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="plan-move-div">
                      <div className="plan-move-div-left">
                        <h5>
                          {planType === "Update Member" ? "" : "Current Plan"}
                        </h5>
                        <h5>{`${user_details?.user_plan?.users} Team Members`}</h5>
                        <h5>
                          {`${
                            currentPlanDuration === 1
                              ? "Monthly"
                              : currentPlanDuration === 12
                              ? "Yearly"
                              : currentPlanDuration === 24
                              ? "2 Yearly"
                              : ""
                          } payments
                                                    `}
                        </h5>
                        <h5>
                          {`
                                                    ${
                                                      user_details?.user_plan
                                                        ?.currency
                                                    } ${
                            user_details?.user_plan?.amount
                          } per
                                                    ${
                                                      currentPlanDuration === 1
                                                        ? "month"
                                                        : currentPlanDuration ===
                                                          12
                                                        ? "year"
                                                        : currentPlanDuration ===
                                                          24
                                                        ? "2 year"
                                                        : ""
                                                    }
                                                    `}
                        </h5>
                        <i className="fas fa-chevron-right"></i>
                      </div>
                      <div className="plan-move-div-right">
                        <h5>
                          {planType === "Update Member" ? "" : "New Plan"}
                        </h5>
                        <h5>{`${user_allowed} Team Members`}</h5>
                        <h5>
                          {`${
                            duration === 1
                              ? "Monthly"
                              : duration === 12
                              ? "Yearly"
                              : duration === 24
                              ? "2 yearly"
                              : ""
                          } 
                                                        payments
                                                    `}
                        </h5>
                        <h5>
                          {`
                                                    ${currency} ${
                            PlanCostDetails?.payable_amount
                          } per
                                                    ${
                                                      duration === 1
                                                        ? "month"
                                                        : duration === 12
                                                        ? "year"
                                                        : duration === 24
                                                        ? "2 year"
                                                        : ""
                                                    }
                                                    `}
                        </h5>
                      </div>
                      {planType === "Upgrade Plan" &&
                      currentPlanDuration === 12 ? (
                        <Link
                          to="#"
                          className="switch-yearly-link"
                          onClick={this.switchPlanDuration}
                        >
                          {" "}
                          {`${
                            duration === 12
                              ? "Switch to 2 yearly"
                              : duration === 24
                              ? "Switch to yearly"
                              : ""
                          }
                                                    `}
                        </Link>
                      ) : planType === "Upgrade Plan" &&
                        currentPlanDuration === 1 ? (
                        <Link
                          to="#"
                          className="switch-yearly-link"
                          onClick={this.switchPlanDuration}
                        >
                          {" "}
                          {`${
                            duration === 1
                              ? "Switch to yearly"
                              : duration === 12
                              ? "Switch to 2 yearly"
                              : "Switch to monthly"
                          }
                                                    `}
                        </Link>
                      ) : (
                        ""
                      )}
                      {planType === "Downgrade Plan" && (
                        <Link
                          to="#"
                          className="switch-yearly-link"
                          onClick={this.switchPlanDuration}
                        >
                          {" "}
                          {`${
                            duration === 1
                              ? "Switch to yearly"
                              : duration === 12
                              ? "Switch to 2 yearly"
                              : "Switch to monthly"
                          }
                                                    `}
                        </Link>
                      )}
                      {}
                    </div>
                    <div className="subscription-type-radio payment-method-plan-modal">
                      <h4>Payments Method</h4>
                      {card_listing &&
                        card_listing.map((value, index) => {
                          let card = JSON.parse(value.card_details);
                          return (
                            <div className="form-check" key={index}>
                              <input
                                className="form-check-input"
                                type="radio"
                                id="inlineRadio1"
                                name="selectedCard"
                                value={value.registrationID}
                                onChange={() =>
                                  this.setState({
                                    selectedCard: value.registrationID,
                                  })
                                }
                                defaultChecked={
                                  selectedCard === value.registrationID
                                    ? "checked"
                                    : undefined
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                {`Card **** ${card?.last4Digits ?? "NA"}`}
                              </label>
                            </div>
                          );
                        })}
                      <Link
                        to="#"
                        className="have-voucher-code-link"
                        onClick={this.AddCardHandler}
                      >
                        <i className="fa fa-plus mr-2"></i>
                        Add Card
                      </Link>
                    </div>
                    <a
                      className="have-voucher-code-link collapsed"
                      data-toggle="collapse"
                      href="#multiCollapseExample1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="multiCollapseExample1"
                    >
                      Have a voucher code?
                    </a>
                    <div className="voucher-collapse-modal">
                      <div className="collapse" id="multiCollapseExample1">
                        <div className="card card-body">
                          <div className="modal-voucher-list-outer">
                            <Link to="#" className="redeem-select-v-img">
                              <img
                                src="./../assets/images/voucher-log.svg"
                                alt="logo"
                              />
                            </Link>
                            <h4>Enter your voucher number </h4>
                            <div className="modal-voucher-list  modal-voucher-list-select">
                              <span className="voucher-img-modal">
                                <input className="form-control" type="text" />
                              </span>
                              <Link to="#" className="redeem-modal-v">
                                Redeem
                              </Link>
                            </div>
                          </div>
                          <div className="modal-voucher-list-outer">
                            <div className="modal-voucher-list">
                              <span className="voucher-img-modal">
                                <img
                                  src="./../assets/images/blue-voucher.svg"
                                  alt="logo"
                                />
                              </span>
                              <Link to="#" className="redeem-modal-v">
                                Redeem
                              </Link>
                            </div>
                          </div>
                          <div className="modal-voucher-list-outer">
                            <div className="modal-voucher-list">
                              <span className="voucher-img-modal">
                                <img
                                  src="./../assets/images/nxa-voucher.svg"
                                  alt="logo"
                                />
                              </span>
                              <Link to="#" className="redeem-modal-v">
                                Redeem
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6>
                      {` Your plan will renew on 
                                            ${renewalPlanDate}.
                                            charging your credit card  ${
                                              planType === "Downgrade Plan"
                                                ? `on ${renewalPlanDate}`
                                                : "today"
                                            } ${currency}${renewalPlanAmount}
                                            `}
                    </h6>
                  </div>
                  <div className="subscription-plan-modal-right">
                    <h3>What you’ll get</h3>
                    <ul className="what-u-ul">
                      <li>
                        <i className="fas fa-check"></i>
                        {`${
                          name.includes("Professional")
                            ? "Business Landline or toll free numbers"
                            : "Business Landline or National numbers"
                        }`}
                      </li>
                      <li>
                        <i className="fas fa-check"></i>
                        {` 
                                                    ${voice_minute} Call Anywhere minutes
                                                    ${
                                                      name !== "Professional"
                                                        ? "(Local Country)"
                                                        : ""
                                                    }
                                                `}
                      </li>
                      <li>
                        <i className="fas fa-check"></i>
                        {`Host up to ${max_user} participants`}
                      </li>
                      <li>
                        <i className="fas fa-check"></i>
                        {`${
                          name.includes("Start Up")
                            ? `Group meetings for up to ${video_calling_minutes} minutes`
                            : `Video Calling ${video_calling_minutes} minutes`
                        }`}
                      </li>
                      <li>
                        <i className="fas fa-check"></i>
                        {`${
                          name.includes("Start Up")
                            ? "Unlimited Team Calling, Video Meeting & Messaging"
                            : "Unlimited Team Calling & Messaging"
                        }`}
                      </li>
                      <li>
                        <i className="fas fa-check"></i>
                        Business SMS *
                      </li>
                      <li>
                        <i className="fas fa-check"></i>
                        {`Cloud Storage ${storage}`}
                      </li>
                      <li>
                        <i className="fas fa-check"></i>
                        Mobile & Web App
                      </li>
                    </ul>
                    <p>
                      {`We have calculated the price
                                            considering your current
                                            subscription prorated price. Your
                                            next renewal payment is ${renewalPlanDate}.
                                            To learn more, see FAQ or
                                            submit a question`}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer subscription-plan-modal-footer">
                <button
                  type="button"
                  className="btn btn-meet-join"
                  onClick={this.confirmSubscriptionPlan}
                >
                  Confirm Subscription
                </button>
                <div className="plan-due-price">
                  <h4>
                    {`Due ${
                      planType === "Downgrade Plan"
                        ? `on ${renewalPlanDate}`
                        : "Today"
                    } : ${currency}${renewalPlanAmount}`}
                  </h4>
                  {planType === "Upgrade Plan" && (
                    <p>
                      This price reflects the difference between your previous
                      plan and your upgrade plan.
                    </p>
                  )}
                  {planType === "Update Member" && (
                    <p>
                      This price reflects the difference between your previous
                      team members and your current updated team members.
                    </p>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    card_listing: state?.setting?.card_listing ?? [],
    plans_list: state?.signup?.plans_list ?? [],
    user_details: state?.user?.user_details?.userDetail ?? {},
  };
};
export default connect(mapStateToProps)(ChangePlanModal);
