import React, { Component } from "react"; 
import { store } from "../../Services";
import { SelectedChatType, SMSChatListing, TeamListing } from "../../Services/Actions/callActions";
import authService from "../../Utils/authService";
import { ExtensionListing } from "../../Services/Actions/settingActions";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import Chat from "../../Utils/Chat";
import AllRecentChat from "./AllRecentChat";
import RenderProp from '../../Component/Modal/RenderProp';
import Schedule from '../../Component/Modal/Schedule';
import MessageContactModal from "../Calls/SMS/MessageContactModal";
import MemberChatPop from "./MemberChatPop";
import TeamChatPop from "./TeamChatPop";
import ContactSlider from "../Calls/NxaContacts/ContactSlider"; 
import NxaContacts from "../Calls/NxaContacts";
import SMS from "../Calls/SMS";
import SmsList from "./SmsList";
import OneToOneChat from "./OneToOneChat";
import GroupChat from "./GroupChat";
import TeamList from "./TeamList"; 
import { OPEN_NXATEL_USER_CHAT } from "../../Services/Constants/callConstants";

const initialState = {
  __activeTab: "chat_listing",
  __teams: [],
  __contacts: [],
  __addContact: false,
  __schedule: false,
  _UserChat: {},
  __smsModal: false,
  __chatUserNumber:""
};

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async () => {
    const {
      nxatel: { selected_chat_type },
    } = this.props; 
    TeamListing(0,store.dispatch);
    ExtensionListing(0,store.dispatch);
    if (selected_chat_type === "one_to_one_chat") {
      this.ActiveTab("one_to_one_chat");
      SelectedChatType("",store.dispatch);
    } else if (selected_chat_type === "all_recent_chats") {
      this.ActiveTab("chat_listing");
      SelectedChatType("",store.dispatch);
    } else if (selected_chat_type === "group_chat") {
      this.ActiveTab("group_chat");
      SelectedChatType("",store.dispatch);
    } else if (selected_chat_type === "team_list") {
      this.ActiveTab("team_list");
      SelectedChatType("",store.dispatch);
    }
    this.FetchContactsChat();
    this.FetchGroups();
    SMSChatListing(store.dispatch);
  };

  FetchContactsChat = () => {
    let __uniquecode = authService.getUserDetail().uniquecode;
    Chat.fetch_room_data_on_update(__uniquecode, this.UpdateFxn);
  };

  FetchGroups = () => {
    Chat.fetch_ref_on_update(`/Groups/`, this.UpdateGroups);
  };

  UpdateGroups = (__groups) => {
    let __uniquecode = authService.getUserDetail().uniquecode;
    __groups = __groups ? Object.values(__groups) : [];
    let __myGroups = [];
    __groups.map((__value) => {
      let { joinee } = __value;
      if (joinee && joinee.includes(__uniquecode)) {
        __myGroups = [...__myGroups, __value];
      }
    });
    this.setState({ __teams: __myGroups });
  };

  UpdateFxn = (__whole_chat) => {
    let __one2one_chat = __whole_chat ? Object.values(__whole_chat) : [];
    this.setState({ 
      __contacts: __one2one_chat
    });
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    const {
      nxatel: { selected_chat_type,open_nxatel_chat },
    } = newProps;
    console.log("open_nxatel_chat",open_nxatel_chat)
    if (selected_chat_type === "one_to_one_chat") {
      this.ActiveTab("one_to_one_chat");
      SelectedChatType("",store.dispatch);
    } else if (selected_chat_type === "all_recent_chats") {
      this.ActiveTab("chat_listing");
      SelectedChatType("",store.dispatch);
    } else if (selected_chat_type === "group_chat") {
      this.ActiveTab("group_chat");
      SelectedChatType("",store.dispatch);
    } else if (selected_chat_type === "team_list") {
      this.ActiveTab("team_list");
      SelectedChatType("",store.dispatch);
    }

    if(open_nxatel_chat && open_nxatel_chat.ActiveTab === "sms"){ 
      this.ActiveTab("sms");
      this.setState({
        _UserChat: open_nxatel_chat,
      },()=>{
        store.dispatch({ type: OPEN_NXATEL_USER_CHAT, payload: {}});  
      });
    }
  }

  ActiveTab = (__activeTab) => {
    this.setState({ __activeTab });
  };

  OpenModal = (__isScheduled = false) => {
    this.setState({ __addContact: !__isScheduled, __schedule: __isScheduled });
  };

  handleClose = () => {
    this.setState({
      __addContact: false,
      __schedule: false,
      __smsModal: false,
    });
  };

  _UserChatHistory = (value) => {
    this.setState({
      _UserChat: value,
    });
  };

  OpenSmsModal = (value) => {
    this.setState({ __smsModal: true , __chatUserNumber : value?.number });
  };

  render() {
    const {
      __activeTab,
      __teams,
      __contacts,
      __addContact,
      __schedule,
      _UserChat,
      __smsModal,
      __chatUserNumber
    } = this.state;
    const { sms_chat_list,extension_listing } = this.props;
    let __favourites = __teams
      .filter((value) => value.favourite)
      .concat(__contacts.filter((value) => value.favourite));
    return (
      <div className="right-side-wrapper pt-0 ">
        <div className="card">
          <div className="card-body p-0">
            <div className="row m-0">
              <Sidebar
                {...{
                  ActiveTab: this.ActiveTab,
                  __activeTab,
                  __teams,
                  __contacts,
                  __favourites,
                  OpenModal: this.OpenModal,
                  sms_chat_list,
                  _UserChatHistory: this._UserChatHistory,
                  OpenSmsModal: this.OpenSmsModal,
                  extension_listing : extension_listing 
                }}
              />
              <AllRecentChat {...{ __activeTab, OpenModal: this.OpenModal ,extension_listing : extension_listing }} />
              {__activeTab === "contacts" ? (
                <NxaContacts />
              ) : __activeTab === "one_to_one_chat" ? (
                <OneToOneChat {...{OpenSmsModal: this.OpenSmsModal}} />
              ) : __activeTab === "group_chat" ? (
                <GroupChat  {...{OpenSmsModal: this.OpenSmsModal}} />
              ) : __activeTab === "team_list" ? (
                <TeamList ActiveTab={this.ActiveTab} />
              ) : __activeTab === "sms" ? (
                <SMS _UserChat={_UserChat} />
              ) : __activeTab === "sms_list" ? (
                <SmsList
                  sms_chat_list={sms_chat_list}
                  _UserChatHistory={this._UserChatHistory}
                  ActiveTab={this.ActiveTab}
                />
              ) : null}
            </div>
          </div>
        </div>
        <MemberChatPop />
        <TeamChatPop />
        <ContactSlider
          addContact={__addContact}
          closeAddContactSlider={this.handleClose} 
        />   
        <MessageContactModal
          destination_number = {__chatUserNumber}
          OpenMessageModal={__smsModal}
          closeMessageContactModal={this.handleClose}
        />
        {__schedule && (
          <RenderProp>
            {({ UpdateTopic, extension_list }) => (
              <Schedule
                UpdateTopic={UpdateTopic}
                extension_list={extension_list}
              />
            )}
          </RenderProp>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  nxatel: state?.call,
  extension_listing: state?.setting?.extension_listing??[],
  business_contact: state?.call?.contact_listing??[],
  sms_chat_list: state?.call?.sms_chat_list??[],
});

export default connect(mapStateToProps)(Message);
