import * as constants from "../Constants/meetConstants";

 

const initalState = {  
    meetings_list:[],
    meetings_count:0,
    recording_count:0
}

const meetReducer = (state = initalState, action) => {
    switch (action.type) {  
        case constants.MEETINGS_LIST:
            return {
                ...state,
                meetings_list: action.payload,
            };
        case constants.MEETINGS_COUNT:
            return {
                ...state,
                meetings_count: action.payload,
            };
        case constants.VIDEO_RECORDING_LIST:
            return {
                ...state,
                video_record_listing: action.payload,
            };
        case constants.RECORDING_COUNT:
            return {
                ...state,
                recording_count: action.payload,
            };
        default:
            return state
    }
}

export default meetReducer;
