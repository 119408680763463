import React, { Component } from "react";
import PageHeader from "./PageHeader";
import ProgressBar from "./ProgressBar";
import ContactBar from "./ContactBar"; 
import Sliders from "./Sliders";  
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import { withRouter } from "../../Utils/withRouter";
import { Link } from "react-router-dom";
import { AccountSummary } from "../../Services/Actions/userActions"; 
import { store } from "../../Services";
import { BuyNxaPlan, CurrencyListing, PlansList } from "../../Services/Actions/signupActions";
import NxaFAQ from "./NxaFAQ";
import PlanDetail from "./PlanDetail";
import swal from "sweetalert";

const initialState = {
    user_allowed: 0,
    planID: 0,
    name: "",
    min_user: 0,
    max_user: 0,
    duration: 1,
    plan_cost: "",
    video_calling_minutes: 0,
    voice_minute: 0,
    free_did_number: 0, 
    participants_for_video_confrence: 0,
    trial:"N",
    selected_currency:"ZAR",
    plan_zoho_id:{},
    demo_plan:false,
    checked:false,
    plan_uniquecode : ""

};

class ChoosePlan extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = async()=> { 
        window.scrollTo(0, 0)  
        let response = await  AccountSummary(store.dispatch) 
            if (response.code === 1) {
                let { data: { status, user_status } } = response;
                if(status === "P"){
                    if(user_status === "UR"){
                        this.props.navigate("/choose-number")  
                    }else if(user_status === "RE"){
                        this.props.navigate("/rica-process")  
                    }else{
                        this.props.navigate("/choose-plan") 
                    }
                }else{
                    this.props.navigate("/dashboard") 
                } 
            } 
        PlansList(store.dispatch)
        CurrencyListing(store.dispatch)   
    }

    componentDidUpdate = () => {
        const {
            web_registration: { plans_list},  
        } = this.props;
        const { planID: plan_id } = this.state;
        if (plans_list instanceof Array && plan_id === 0) {
            // recommended plan scenerio here
            plans_list.map((value) => {
                if (value.name.includes("Professional")) {
                    const {
                        planID,
                        name,
                        plan_cost,
                        video_calling_minutes,
                        voice_minute,
                        free_did_number,
                        min_user,
                        max_user,
                        participants_for_video_confrence, 
                        plan_zoho_id,
                        uniquecode
                    } = value;
                    this.setState({
                        planID,
                        name,
                        plan_cost,
                        video_calling_minutes,
                        voice_minute,
                        free_did_number,
                        min_user,
                        max_user,
                        participants_for_video_confrence,
                        user_allowed:min_user,  
                        plan_zoho_id,
                        plan_uniquecode:uniquecode
                        
                    });
                }
            });
        }  
        
    };

    onSliderChange = (e, user_allowed) => { 
        this.setState({ user_allowed });
    };

    OnDurationChange = (duration) => {
        this.setState({ duration });
    };

    OnSelectPlanHanlder = (value,trial="N") => {        
        const {
            planID,
            name,
            plan_cost,
            video_calling_minutes,
            voice_minute,
            free_did_number,
            min_user,
            max_user,
            participants_for_video_confrence, 
            plan_zoho_id,
            uniquecode
        } = value;
        this.setState({
            planID,
            name,
            plan_cost,
            video_calling_minutes,
            voice_minute,
            free_did_number,
            min_user,
            max_user,
            participants_for_video_confrence, 
            trial,
            plan_zoho_id,
            user_allowed:min_user,
            plan_uniquecode:uniquecode
        });
    }; 
     

    onBuyNow = async({},data) => {  
        const {user_allowed,duration,selected_currency,trial,plan_uniquecode} = this.state   
        let sendData = {
            planID:plan_uniquecode,
            duration,
            plan_users:user_allowed,
            currency:selected_currency,
            trial:data === "trial" ? "Y" : trial
        }  
        let res = await BuyNxaPlan(sendData,store.dispatch) 
        if(res?.code === 1){
            swal({
                title:"Success!",
                text:res?.ResponseText,
                icon: "success",
                buttons: true,
                dangerMode: true,
            }).then(async (resolve) => {
            if (resolve) {
                this.props.navigate("/order-summary") 
                this.setState(initialState) 
            }
            }); 
        }else{
            this.props.navigate("/choose-number") 
            this.setState(initialState)
        }  
    };  
     

    render() {
        const { duration,  planID, name, plan_cost, video_calling_minutes, voice_minute, free_did_number, min_user, max_user, participants_for_video_confrence, user_allowed, selected_currency, checked } = this.state;
        const { web_registration: { plans_list, currency_list },user_details } = this.props; 
        
        const CollectionList = {user_details, duration,  planID , name, plan_cost, video_calling_minutes, voice_minute, free_did_number, min_user, max_user, participants_for_video_confrence, plans_list, selected_currency, OnSelectPlanHanlder: this.OnSelectPlanHanlder, onBuyNow: this.onBuyNow};
        return (
            <Fade in={checked} timeout={500}>
                <React.Fragment>
                    <div className = "pricing-step pricing-signup pb-5">
                        <div className="sign-up-step-bar">
                            <div className="container">
                                <PageHeader />
                                <ContactBar />
                                <ProgressBar active="select-package" />
                            </div>
                        </div>
                        <div className="pricing-after pricing-box-section pt-5">
                            <div className="container">
                                <div className="Steps-price pricing-page">
                                    <span className="font-16 color-00 fw-700 pl-90">
                                        Size of team
                                    </span>
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="Size-team">
                                                {parseInt(max_user) > 0 && (
                                                    <div className="row">
                                                        <Sliders
                                                            onSliderChange={
                                                                this.onSliderChange
                                                            }
                                                            max={parseInt(max_user)}
                                                            min={parseInt(min_user)}
                                                            value={user_allowed}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-5 text-right">
                                            {/* btn-group-monthly */}
                                            <div
                                                className="btn-group btn-group-pricing "
                                                role="group"
                                                aria-label="Basic example"
                                            >
                                                <button
                                                    type="button"
                                                    className={`btn btn-secondary ${
                                                        duration === 1 ? "active" : ""
                                                    }`}
                                                    onClick={() =>
                                                        this.OnDurationChange(1)
                                                    }
                                                >
                                                    Monthly
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`fw-700 btn btn-secondary ${
                                                        duration === 12 ? "active" : ""
                                                    }`}
                                                    onClick={() =>
                                                        this.OnDurationChange(12)
                                                    }
                                                >
                                                    12-Month Save Up to 18%
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`fw-700 btn btn-secondary ${
                                                        duration === 24 ? "active" : ""
                                                    }`}
                                                    onClick={() =>
                                                        this.OnDurationChange(24)
                                                    }
                                                >
                                                    24-Month Save Up to 20%
                                                </button>
                                            </div>
                                            <div className="form-group  currency currency-div pt-3 ml-auto mr-2">
                                                <select
                                                    className="form-control  color-293F"
                                                    id="exampleFormControlSelect1"
                                                    name="selected_currency"
                                                    value={selected_currency}
                                                    onChange={(e)=>this.setState({selected_currency:e.target.value})}
                                                >  
                                                    {
                                                        currency_list && currency_list.map((value,index)=>{
                                                            return (
                                                                <option key={index} value={value.currencyCode}>
                                                                    {value.currencyCode}
                                                                </option>
                                                            )
                                                        })
                                                    } 
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pricing pricing-palden">
                                    <PlanDetail {...CollectionList} />
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 free-trial-div">
                                    <h5 className="12 color-696871 fw-400">
                                        Your 7-day Free Trial includes:
                                    </h5>
                                    <span className="font-10">
                                        - Full use of the Nxatel cloud phone system for
                                        up to 7 days
                                        <br /> - Maximum of 3 users <br />- 10 min talk
                                        time per user
                                        <br /> - 3 SMS
                                    </span>
                                </div>
                                <div className="col-md-6 not-sure-div">
                                    <h3>Still not Sure?</h3>
                                    <Link  to="#" className="compare-features btn">Compare All Features </Link>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <NxaFAQ />
                </React.Fragment>
            </Fade>
        );
    }
}

const mapStateToProps = (state) => { 
    return {
        web_registration: state?.signup,
        user_details:state?.user?.user_details?.userDetail??{}
    };
};
export default connect(mapStateToProps)(withRouter(ChoosePlan));
