import React from "react"; 
import swal from 'sweetalert'; 
import { withRouter } from "../../Utils/withRouter";
import { Link } from "react-router-dom";
import { WebDomain } from "../../Utils/Common";
import authService from "../../Utils/authService";  
import { logOut } from "../../Services/Actions/userActions";
import { store } from "../../Services";

function PageHeader(props) {
    const {activeClass} = props; 
 
    const LogOut = async() => { 
        swal({
            text: "Are you sure want to logout?",
            icon: "warning",
            title:"Warning!",
            buttons: true,
            dangerMode: true,
          }).then(async (Cancel) => {
            if (Cancel) {
              await logOut(store.dispatch) 
              authService.logout();
              window.location.href = `https://${WebDomain}/`;
            }
          });
    };

    return (
        <nav className="navbar navbar-expand-lg">
            <Link
                to="#"
                className="navbar-brand" 
                style={{cursor:'pointer'}} 
                onClick={()=>window.location.href = `https://${WebDomain}/`}
            >
                <img src="./../assets/images/homepagelogo.png" alt="logo" />
            </Link>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
            </button>

            <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
            >
                <ul className="navbar-nav ml-auto navigation">
                    {localStorage.token &&  
                    <li className={`nav-item ${activeClass && activeClass==="login"?"active":""}`}>
                        <Link
                            className={`nav-link see-pricing-btn`} 
                            to="#"
                            onClick={LogOut}
                        >
                            Logout
                            <span className="sr-only">(current)</span>
                        </Link>
                    </li>} 
                    {!localStorage.token &&  
                    <li className={`nav-item ${activeClass && activeClass==="login"?"active":""}`}>
                        <Link
                            to="login"
                            className={`nav-link see-pricing-btn`} 
                        >
                            Login
                            <span className="sr-only">(current)</span>
                        </Link>
                    </li>}
                    {!localStorage.token && (
                        <li className={`nav-item ${!activeClass?"active":""}`}>
                            <Link
                                to="pricing"
                                className="nav-link" 
                            >
                                Sign Up
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    );
}

export default withRouter(PageHeader);
