import React, { useEffect } from "react";  
import { Outlet } from "react-router-dom";
import { loadCssFile } from "../../Utils/Common";
import Alerts from "../Alerts";
import Loader from "../Loader";
import Header from "./Header";
import Navbar from "./Navbar";

function PortalLayout(props) {
    useEffect(()=>{
        loadCssFile("./../assets/css/nxa-inner-sub.css", "nxa-inner-sub");
        loadCssFile("./../assets/css/nxa-inner.css", "nxa-inner");
        return ()=>{
            window.$("#nxa-inner-sub").remove();
            window.$("#nxa-inner").remove();
        }
    },[]) 

    return (
        <>
            <div className="wrapper wrapper-height">
                <Navbar {...props} />
                <div id="content" className="pt-0">
                    <div className="container mx-w-100 p-0">
                        <Header {...props} />
                        <Outlet {...props}  />
                    </div>
                </div>
                <Alerts />
                <Loader />
            </div>
        </>
    );
}

export default PortalLayout;
