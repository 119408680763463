import React, { Component } from "react";
import { connect } from "react-redux";

class RenderProp extends Component {
  state = { topic: "" };

  UpdateTopic = (topic) => {
    window.$("#meet-modal").modal(
      topic
        ? { backdrop: "static", keyboard: false, focus: true, show: true }
        : "hide"
    );
    this.setState({ topic });
  };
  render() {
    const { topic } = this.state;
    const { timezone_list, extension_listing } = this.props;
    return (
      <div
        className={`modal fade bd-example-modal-${
          topic === "join"  ||  topic === "start_meet" ? "sm" : "lg"
        }`}
        id="meet-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div
          className={`modal-dialog meeting-modal-dialog modal-${
            topic === "join" ||  topic === "start_meet"? "md" : "lg"
          }`}
        >
          <div className="modal-content meeting-modal-content">
            {this.props.children({
              UpdateTopic: this.UpdateTopic,
              timezone_list,
              extension_listing,
            })}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    timezone_list: state?.call?.timezone_list ?? [],
    extension_listing: state?.setting?.extension_listing ?? [],
  };
};
export default connect(mapStateToProps)(RenderProp); 
