import React, { useRef, useEffect, useState } from "react";
import moment from "moment";
import { store } from "../../../Services";
import {
  ForceCall,
  SelectedChatType,
  SendMessage,
  SMSChatHistory,
} from "../../../Services/Actions/callActions";
import { Link } from "react-router-dom";
import { StartMeet } from "../../../Services/Actions/meetActions";
import { convertUtcToLocalTime } from "../../../Utils/Common";
import { connect } from "react-redux";
import Picker, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";

function SMS(props) {
  const { _UserChat, sms_chat_history } = props;
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  let messagesEnd = useRef(null); 
  let activeUrl = window.location.pathname.replace("/","")

  useEffect(() => {
    let msg_screen = messagesEnd;
    msg_screen.scrollTop = msg_screen.scrollHeight;
  }, []);

  useEffect(() => {
    if (_UserChat && _UserChat?.users) {
      SMSChatHistory({ number: _UserChat?.users }, store.dispatch);
    }
    let msg_screen = messagesEnd;
    msg_screen.scrollTop = msg_screen.scrollHeight;
  }, [_UserChat]);

  const handleSend = async () => {
    const data = {
      message,
      number: _UserChat?.users?.replace("+", ""),
    };
    let res = await SendMessage(data , store.dispatch);
    if (res === 1) {
      SMSChatHistory({ number: _UserChat?.users }, store.dispatch);
      setMessage("");
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    const { emoji } = emojiObject;
    setMessage(`${message}${emoji}`);
  };

  const callActionHanlder = (_UserChat, number) => {
    setNumber(_UserChat?.users ? _UserChat.users.replace("+", "") : "");
    ForceCall(number,store.dispatch);
  };

  const onKeyPressHandler = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  return (
    <>
      <div className="col-md-8 col-lg-9">
        <div className="open-chat message-chat-body">
          <div className="open-chat22 ">
            <div className="d-flex chat-profile p-2 justify-content-between">
              <div className="d-flex  chat-page-top-dt">
                {activeUrl === "chat" && <Link
                  to="#"
                  className="chat-back-arrow chat-wind-back-arrow chat-back-arrow-2"
                  onClick={()=>SelectedChatType("all_recent_chats", store.dispatch)} 
                >
                  <img src="./../assets/images/backA.svg" />
                </Link>}
                <div className="chat-pro-img chat-pro-new-img">
                  <img src="./../assets/images/Mask.png" alt="1" />
                </div>
                <div className="chat-pro-name align-self-center">
                  <h5 className="chat-name mb-0">
                    {_UserChat.user_name
                      ? _UserChat.user_name
                      : `+${_UserChat.users}`
                    }
                  </h5>
                </div>
                {_UserChat.user_name && (
                  <div className="chat-icons chat-icons-new-wind align-self-center">
                    <Link
                      to="#" 
                      onClick={() => callActionHanlder(_UserChat, number)}
                    >
                      <img src="./../assets/images/icons/call-ico.png" />
                    </Link>
                    <Link
                      to="#"
                      onClick={() => StartMeet(_UserChat.users)} 
                    >
                      <img src="./../assets/images/icons/video-ico.png" />
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div
              className=" mid-sec"
              ref={(input) => {
                messagesEnd = input;
              }}
            >
              {sms_chat_history instanceof Array &&
                sms_chat_history.map((values, keys) => {
                  let date_time = convertUtcToLocalTime(values.creation_date);
                  let formattedDate = moment(date_time)
                    .locale("en")
                    .format("ll");
                  let formattedTime = moment(date_time)
                    .locale("en")
                    .format("LT");
                  return (
                    <div
                      className={`w-100 float-${
                        values.direction === "Outbound" ? "right" : "left"
                      }`}
                      key={`messages_${keys}`}
                    >
                      <div
                        className={`msg-inbox ${
                          values.media_type !== "text" ? "bubble-remove" : ""
                        } ${
                          values.direction === "Outbound" ? "send" : "recive"
                        }`}
                        key={`messages_${keys}`}
                      >
                        <div
                          className={`chats-text ${
                            values.direction === "Outbound" ? "bg-orang" : ""
                          }`}
                        >
                          <p className="m-0 chat-font">{values.text}</p>
                        </div>
                        <div className="chat-time text-right">
                          <p
                            className={`text-black ${
                              values.direction === "Outbound"
                                ? ""
                                : "float-left"
                            }`}
                            style={{ paddingRight: "30px" }}
                          >
                            <small className="chat-date-time">{`${formattedDate} ${formattedTime}`}</small>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="form-group msg-input new-wind-msg-input  m-0">
            <input
              type="text"
              className="form-control chat-input"
              name="message"
              value={message}
              placeholder="Type something here..."
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => onKeyPressHandler(e)}
            />
            <Link 
              to="#"
              className="chat-emoji-smile"
              onClick={() => setShowEmoji(!showEmoji)}
            >
              <img src="./../assets/images/chat-smile-ico.svg" />
            </Link>
            {showEmoji && (
              <div className="chat-page-emoji-div">
                <Picker
                  onEmojiClick={onEmojiClick}
                  skinTone={SKIN_TONE_NEUTRAL}
                />
              </div>
            )}
            <Link
              to="#"
              className="paper-plane"
              onClick={() => handleSend()}
            >
              <i className="msg-paper-plane">
                <img src="./../assets/images/message-send-new.svg" />
              </i>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  sms_chat_history: state?.call?.sms_chat_history ?? [],
});

export default connect(mapStateToProps)(SMS);
