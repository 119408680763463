import React, { Component } from "react";
import { connect } from "react-redux"; 

const initialState = {};

class AllApps extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  render() {
    const { SetTab } = this.props;
    return (
      <div className="card discover-inner">
        <div className="card-body pl-0 pr-0">
          <div className="power-apps mb-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 p-0">
                  <div className="powerapp-inner">
                    <div className="row">
                      <div
                        className="col-md-3"
                        style={{ cursor: "pointer" }}
                        onClick={() => SetTab("cloud_storage")}
                      >
                        <div className="powerapp-box p-4 bg-yellow">
                          <img
                            src="./../assets/images/cloud/carbon_recording.svg"
                            alt="1"
                          />
                          <h6 className="font-16 color3c">
                            Cloud <br></br>Storage
                          </h6>
                        </div>
                      </div>
                      <div
                        className="col-md-3"
                        style={{ cursor: "pointer" }}
                        onClick={() => SetTab("notes")}
                      >
                        <div className="powerapp-box p-4 bg-blue">
                          <img
                            src="./../assets/images/cloud/cil_notes.svg"
                            alt="1"
                          />
                          <h6 className="font-16 color3c">Notes</h6>
                        </div>
                      </div>
                      <div
                        className="col-md-3"
                        style={{ cursor: "pointer" }}
                        onClick={() => SetTab("more_apps")}
                      >
                        <div className="powerapp-box p-4 bg-purple">
                          <img
                            src="./../assets/images/cloud/clarity_vmw-app-line.svg"
                            alt="1"
                          />
                          <h6 className="font-16 color3c">
                            More Apps<br></br>Coming soon
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AllApps);
