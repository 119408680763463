import React, { useEffect } from "react";  
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { store } from "../../../../Services";
import { ShowPayment } from "../../../../Services/Actions/signupActions";
import { withRouter } from "../../../../Utils/withRouter";

function SaveCardSuccess(props) {
  const payment_info = useSelector((state) => state?.signup?.payment_info);
  useEffect(() => {
    let token = window.location.pathname.split("/").pop();
    ShowPayment({ token }, store.dispatch);
  }, []);

  useEffect(() => {
    if (payment_info?.status === "S") {
      AddCardSuccessHandler();
    } else if (payment_info?.status === "P" || payment_info?.status === "F") {
      onCardFailureHandler();
    }
  }, [payment_info]);

  const onCardFailureHandler = () => {
    let message = payment_info?.message ?? "";
    const wrapper = document.createElement("div");
    wrapper.innerHTML =
      `<p>` +
      message +
      `<p class="order-contact">Please Contact<a class="swal-order-contact-link" href="https://support.nxa.tel/portal/en/home">Nxatel Support</a></p>`;
    swal({
      icon: "error",
      title: `Card Registration Failed!`,
      buttons: "Back to Account",
      content: wrapper,
      className: "swal-custom-design",
    }).then(async (action) => {
      if (action) {
        props.navigate("/settings");
      }
    });
  };

  const AddCardSuccessHandler = () => {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = `<p>
                            Card has been successfully added to the card list and user can make transactions with this asap!
                        </p>`;
    swal({
      icon: "./../assets/images/subscription-successfull.svg",
      title: `Card Successfully Added`,
      buttons: "Proceed to Account",
      content: wrapper,
      className: "add-subscription",
    }).then(async (action) => {
      if (action) {
        props.navigate("/settings");
      }
    });
  };

  return true;
}

export default withRouter(SaveCardSuccess);
