import { apiAdminUrl, apiBaseUrl ,apiMediaUrl} from "../../Utils/Common";
import * as constants from "../Constants/callConstants";
import * as ACTIONS from "./../Constants/apiConstants.js";
import swal from "sweetalert"; 
import authService from "../../Utils/authService";
import { FetchUserDetails, handleResponse } from "./userActions";
import { SHOW_LOADER, TOASTR } from "../Constants/userConstants";
import axios from "axios";
import { ExtensionListing, getMediaDownloadUrl } from "./settingActions"; 

export const ForceCall = (number,dispatch) => {
  return dispatch({ type: constants.FORCE_CALL, payload: number });
};

// ***************************************************  CONTACTS ACTIONS  *************************************************

export const NxaContactListing = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/contacts-listing`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        const { data: contactsList = [] } = data;
        console.log("contactsList=====>",contactsList)
        if(contactsList?.length > 0){
          contactsList.forEach(async (element, index) => {
            const { app_picture } = element;
            if(app_picture){
              const url = await getMediaDownloadUrl(app_picture);
              element.profile_picture = url;
              contactsList[index] = element;
            }else{
              element.profile_picture = "";
              contactsList[index] = element;
            }
            dispatch({
              type: constants.NXA_CONTACT_LISTING,
              payload: [...contactsList],
            }); 
          });
        }else{
          dispatch({
            type: constants.NXA_CONTACT_LISTING,
            payload: [],
          });
        } 
        return 1;
      } else {
        return 0;
      }
    })
    .catch(() => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const CreateNxaContact = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/create-contact`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({
          title: "Success!",
          text: data.ResponseText,
          icon: "success",
        });
        return 1;
      } else {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch(() => {
      dispatch({ type: SHOW_LOADER, payload: false });
      return 0;
    });
};

export const UpdateNxaContact = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/update-contact`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        NxaContactListing({ type: params?.type },dispatch);
        swal({
          title: "Success!",
          text: data.ResponseText,
          icon: "success",
        });
        return 1;
      } else {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch(() => {
      dispatch({ type: SHOW_LOADER, payload: false });
      return 0;
    });
};

export const DeleteNxaContact = (contactID, type,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/delete-contacts`, {
    method: "POST",
    body: JSON.stringify({ contactID }),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        NxaContactListing({ type },dispatch);
        swal({
          title: "Success!",
          text: data.ResponseText,
          icon: "success",
        });
        return 1;
      } else {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch(() => {
      dispatch({ type: SHOW_LOADER, payload: false });
      return 0;
    });
};

// ************************************************************  CALL ACTIONS  ************************************************

export const RecentCallListing = (reload = true,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: reload });
  const request = new Request(`${apiBaseUrl}userPanel/call-history-new`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_RECENT_CALL_LISTING,
          payload: data.data,
        });
        dispatch({
          type: constants.CALL_LISTING_COUNT,
          payload: data.count,
        });
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const AllRecordingListing = (dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/call-recording-list`, {
    method: "POST", 
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_ALL_RECORDING_LISTING,
          payload: data.data,
        });
        dispatch({
          type: constants.ALL_RECORDING_COUNT,
          payload: data.count,
        });
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const IndividualContactCallHistory = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiBaseUrl}userPanel/Individual-contact-call-history`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.INDIVIDUAL_CONTACT_CALL_HISTORY,
          payload: data.data,
        });
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const FetchProfileInfoData = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  const user_details = authService.getUserDetail(); 
  // dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}${ACTIONS.USERPANEL}profile-info_data`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      // dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_PROFILE_INFO_DATA,
          payload: data.data,
        });
      }
      return 1;
    })
    .catch((error) => {
      // dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const DeleteAssignedNumber = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/delete-assign`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({
          title: "Success!",
          text: "Number Assigned Deleted Successfully",
          icon: "success",
        });
        FetchAssignedNumberList({ number: params.phone },dispatch);
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const FetchOutNumber = (number, loader = true,dispatch) => {
  const token = authService.getUserAccessToken(); 
  const request = new Request(`${apiAdminUrl}userPanel/contact-info`, {
    method: "POST",
    body: JSON.stringify({ number }),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => { 
      if (data.ResponseCode === 1) {
        return data.data[0].phone;
      } else {
        return number;
      }
    })
    .catch(() => { 
      return number;
    });
};

export const FetchHistoryIndividual = (number, loader = true,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: loader });
  const request = new Request(
    `${apiBaseUrl}userPanel/Individual-contact-call-history `,
    {
      method: "POST",
      body: JSON.stringify({ number }),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return { code: 1, response: data.data };
      } else {
        return { code: 0, response: data.ResponseText };
      }
    })
    .catch(() => {
      dispatch({ type: SHOW_LOADER, payload: false });
      return { code: 0 };
    });
};

export const CallDetails = (value,dispatch) => { 
  dispatch({
    type: constants.FETCH_CALL_DETAILS,
    payload: value,
  }); 
};


export const TransferCallSessions = (value,dispatch) => { 
  dispatch({
    type: constants.FETCH_TRANSFER_CALL_SESSIONS,
    payload: value,
  }); 
};

export const ConferenceCallSessions = (value,dispatch) => { 
  dispatch({
    type: constants.FETCH_CONFERENCE_CALL_SESSIONS,
    payload: value,
  }); 
};

export const AssignedNumberToExtension = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/assign-to-extension`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({
          title: "Success!",
          text: "Number Assigned Successfully",
          icon: "success",
        });
        FetchAssignedNumberList({ number: params.phone },dispatch);
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const FetchAssignedNumberList = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(
    `${apiBaseUrl}userPanel/assign-to-extension-list`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_ASSIGNED_NUMBER_LIST,
          payload: data.data,
        });
      }
      return 1;
    })
    .catch(() => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

// *************************************************************   VOICEMAIL ACTIONS  ******************************************

export const VoicemailListing = (dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/voicemail-list`, {
    method: "POST", 
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_VOICEMAIL_LISTING,
          payload: data.data,
        });
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const DeleteVoicemailFile = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/delete-voicemail`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};




// *********************************************** MEDIA UPLOAD ACTIONS *************************************************

export const UploadFiles = (imageFile, value,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  let request;
  let formData = new FormData();
  formData.append("recording_filename", imageFile);
  formData.append("upload_type", value);
  request = {
    method: "POST",
    url: `${apiMediaUrl}userPanel/image_upload`,
    headers: { "Content-Type": "application/json", "X-Auth-Token": token },
    data: formData,
  };

  return axios(request)
    .then((data) => {
      const {data:{url  = "", duration=0 , size = 0} = {}} = data
      dispatch({ type: SHOW_LOADER, payload: false });
      return { code: 1, file_path: url, duration, size};
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (error && error.response && error.response.data) {
        swal({
          title: "Error!",
          text: error.data.ResponseText,
          icon: "error",
        });
        return 0;
      } else {
        swal({
          title: "Error!",
          text: error.data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    });
};

// ***********************************************  TEAM AND TEAM MEMBERS ACTIONS   ********************************************

export const TeamListing = (id,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/team/${id ? id : 0}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      
      if (data.ResponseCode === 1) {
        dispatch({
          type: constants.FETCH_TEAM_LISTING,
          payload: data.data,
        });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
}; 

export const CreateUpdateTeam = (params, teamID,dispatch) => {
  let data = teamID ? "edit-existing-team" : "create-team";
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/${data}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        let message = teamID
          ? "Team Updated Successfully"
          : "Team Created Successfully";
        swal({ title: "Success!", text: message, icon: "success" });
        TeamListing(0,dispatch);
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const CreateUpdateExtension = (params, customerID,dispatch) => {
  let data = customerID ? "update-extension" : "extension";
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiAdminUrl}userPanel/${data}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        let message = customerID
          ? "Member Updated Successfully"
          : "Member Created Successfully";
        swal({ title: "Success!", text: message, icon: "success" });
        ExtensionListing(0,dispatch)
        FetchUserDetails(dispatch)
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

// ****************************************************  SMS ACTIONS   *********************************************************

export const SMSChatListing = (dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/sms-chat-list`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_SMS_CHAT_LISTING,
          payload: data.data,
        });
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const SMSChatHistory = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/sms-chat-history`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_SMS_CHAT_HISTORY,
          payload: data.data,
        });
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const SendMessage = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/send-sms`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then(async (data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") { 
          swal({
            title: "Success!",
            text: "Message sent successfully.",
            icon: "success",
          }); 
        SMSChatListing(dispatch);
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const SendSms = (params, loader = true,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: loader });
  const request = new Request(`${apiBaseUrl}userPanel/send-message`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: TOASTR,
          payload: {
            duration: 1500,
            message: data.ResponseText,
            type: "success",
          },
        });
        return 1;
      } else {
        dispatch({
          type: TOASTR,
          payload: {
            duration: 1500,
            message: data.ResponseText,
            type: "error",
          },
        });
        return 0;
      }
    })
    .catch(() => {
      dispatch({
        type: TOASTR,
        payload: {
          duration: 1500,
          message: "Error in sending message",
          type: "error",
        },
      });
    });
};


// ****************************************************  CHAT ACTIONS  *****************************************************

export function SelectedChatUser(data,dispatch) { 
  dispatch({ type: constants.SELECTED_CHAT_USER, payload: data }); 
}

export function SelectedChatType(data,dispatch) { 
  dispatch({ type: constants.SELECTED_CHAT_TYPE, payload: data }); 
}

export function SelectedGroupChatUser(data,dispatch) { 
  dispatch({ type: constants.SELECTED_GROUP_CHAT_USER, payload: data }); 
}



// ****************************************** TAG ACTIONS ******************************************************************

export const TagListing = (dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/nxa-tag-list`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.GET_TAG_LISTING,
          payload: data.data,
        });
      }
      return 1;
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      return 0;
    });
};

export const AddTag = async (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/add-nxa-tag`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        TagListing(dispatch);
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      console.log("error",error)
      dispatch({ type: SHOW_LOADER, payload: false }); 
      return 0;
    });
};

export const AssignTag = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/assign-tag`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const DeleteTagFromNumber = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/delete-assign-tag`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({
          title: "Success!",
          text: "Tag deleted successfully",
          icon: "success",
        });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

// *****************************************************    NOTES ACTIONS ***********************************************

export const NotesListing = (params, loader = true,dispatch) => {
  const token = authService.getUserAccessToken();  
  const request = new Request(`${apiAdminUrl}${ACTIONS.USERPANEL}contact-info`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => { 
      if (data.ResponseCode === 1) {
        dispatch({
          type: constants.FETCH_NOTES_LISTING,
          payload: data.data?.notes ?? [],
        });
        dispatch({
          type: constants.FETCH_PROFILE_INFO_DATA,
          payload: data.data ?? {},
        });
        return 1;
      } else { 
        dispatch({ type: constants.FETCH_NOTES_LISTING, payload: [] });
        dispatch({
          type: constants.FETCH_PROFILE_INFO_DATA,
          payload: {},
        });
        return 0
      }
    })
    .catch((error) => { 
      dispatch({ type: constants.FETCH_NOTES_LISTING, payload: [] });
      dispatch({ type: constants.FETCH_PROFILE_INFO_DATA, payload: {} });
    });
};

export const CreateNotes = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/create-notes`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({
          title: "Success!",
          text: "Notes Created Successfully",
          icon: "success",
        });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

//  *************************************** SCHEDULE ACTIONS ************************************************************

export const ScheduleCallTime = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/create-schedule-call`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({
          title: "Success!",
          text: "Call Scheduled Successfully",
          icon: "success",
        });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};
