import React, { useEffect, useState } from "react";
import Modal from "../../../../Component/Modal";
import { store } from "../../../../Services";
import { DidNumberListing } from "../../../../Services/Actions/reportActions";
import { CallRecordingSettings } from "../../../../Services/Actions/settingActions";

const initialState = {
  _assignToUserExtension: [],
  _outgoingRecording: false,
  _incomingRecording: false,
  _outgoingRecordingAccess: false,
  _incomingRecordingAccess: false,
  _voicmemailsByEmail: false,
  _mailOnCallAssigned: false,
  _callSummary: false,
  _activityReport: false,
};

function NumberSettingModal(props) {
  const {
    openModal,
    handleClose,
    extension_listing,
    numberDetails: {
      did_number,
      assigner,
      incoming_call_recording,
      outgoing_call_recording,
    },
  } = props;

  const [state, setState] = useState(initialState);

  const {
    _assignToUserExtension,
    _incomingRecording,
    _outgoingRecording,
    _voicmemailsByEmail,
    _mailOnCallAssigned,
    _callSummary,
    _activityReport,
  } = state;

  useEffect(() => {
    if (assigner) {
      let assigner_array = [];
      assigner &&
        assigner.map((value) => {
          return assigner_array.push(value.user_extension);
        });
      setState((prev) => ({
        ...prev,
        _assignToUserExtension: assigner_array,
        _incomingRecording:incoming_call_recording === "Y" ? true : false,
        _outgoingRecording:outgoing_call_recording === "Y" ? true : false
      }));
    }
  }, [assigner]);

  const SelectHandler = (event, value) => {
    if (event.target.checked) {
      setState((prev) => ({
        ...prev,
        _assignToUserExtension: [..._assignToUserExtension, value],
      }));
    } else {
      let array = [..._assignToUserExtension];
      let index = array.indexOf(value);
      if (index !== -1) {
        array.splice(index, 1);
        setState((prev) => ({
          ...prev,
          _assignToUserExtension: array,
        }));
      }
    }
  };

  const CallRecordingSettingsHandler = async () => {
    let response = await CallRecordingSettings(
      {
        did_number,
        outgoing_call_recording: _outgoingRecording ? "Y" : "N",
        incoming_call_recording: _incomingRecording ? "Y" : "N",
      },
      store.dispatch
    );
    if (response === 1) {
      DidNumberListing(store.dispatch, "phoneSettings");
      handleClose();
      setState(initialState);
    }
  };

  return (
    <Modal
      open={openModal}
      CloseModal={handleClose}
      title={"Settings"}
      showActiveClass={openModal}
      size={`lg`}
    >
      <div className="number-setting-modal">
        <div className="number-setting-sub-header">
          <h4>
            <span className="num-setting">Phone number: {did_number}</span>
            <span className="num-setting-assign">
              Assigned To: &nbsp;
              <div className="dropdown drop-assign">
                <a
                  className=" dropdown-toggle assing-drop-control collapsed"
                  data-toggle="collapse"
                  data-target="#collapseExample3"
                  aria-expanded="false"
                  aria-controls="collapseExample3"
                >
                  {`${
                    _assignToUserExtension?.length > 0
                      ? _assignToUserExtension?.length
                      : "Select"
                  }`}{" "}
                  Team Member
                </a>
                <div
                  className="collapse dropdown-menu team-member-collapse"
                  id="collapseExample3"
                >
                  {extension_listing &&
                    extension_listing.map((value, index) => {
                      return (
                        <a className="dropdown-item" href="#" key={index}>
                          <span>
                            <img
                              src={`${
                                value.profile_picture
                                  ? value.profile_picture
                                  : "./../assets/images/Mask.png"
                              }`}
                              alt="1"
                            />
                            {`${value.name}`}
                            <i className="italic-font-color">{`(${value.user_extension})`}</i>
                          </span>
                          <span>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="selectUser"
                              value={value.user_extension}
                              id={value.user_extension}
                              onChange={(event) =>
                                SelectHandler(event, value.user_extension)
                              }
                              checked={_assignToUserExtension.includes(
                                value.user_extension
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={value.user_extension}
                            ></label>
                          </span>
                        </a>
                      );
                    })}
                </div>
              </div>
            </span>
          </h4>
        </div>
        <div className="number-setting-modal-inner">
          <h2>Call Recording</h2>
          <p>
            Call recordings will be available to review and share in the
            Activity Feed on the Dashboard and in the phone app.
          </p>
          <div className="num-setting-call-recording-box">
            <h3>Outgoing Calls</h3>
            <h6>
              <label className="switch mr-3">
                <input
                  type="checkbox"
                  name="_outgoingRecording"
                  value={_outgoingRecording ? "checked" : ""}
                  checked={_outgoingRecording ? "checked" : ""}
                  onChange={() =>
                    setState((prev) => ({
                      ...prev,
                      _outgoingRecording: !prev?._outgoingRecording,
                    }))
                  }
                />
                <span className="slider round"></span>
              </label>
              Start recording automatically
            </h6>
            {/* <h6>
              <label className="switch mr-3">
                <input
                  type="checkbox"
                  name="_outgoingRecordingAccess"
                  value={_outgoingRecordingAccess ? "checked" : ""}
                  checked={_outgoingRecordingAccess ? "checked" : ""}
                  onChange={() =>
                    setState((prev) => ({
                      ...prev,
                      _outgoingRecordingAccess: !prev?._outgoingRecordingAccess,
                    }))
                  }
                />
                <span className="slider round"></span>
              </label>
              Allow team members to start and pause recording
            </h6> */}
            <h3>Incoming Calls</h3>
            <h6>
              <label className="switch mr-3">
                <input
                  type="checkbox"
                  name="_incomingRecording"
                  value={_incomingRecording ? "checked" : ""}
                  checked={_incomingRecording ? "checked" : ""}
                  onChange={() =>
                    setState((prev) => ({
                      ...prev,
                      _incomingRecording: !prev?._incomingRecording,
                    }))
                  }
                />
                <span className="slider round"></span>
              </label>
              Start recording automatically
            </h6>
            {/* <h6>
              <label className="switch mr-3">
                <input
                  type="checkbox"
                  name="_incomingRecordingAccess"
                  value={_incomingRecordingAccess ? "checked" : ""}
                  checked={_incomingRecordingAccess ? "checked" : ""}
                  onChange={() =>
                    setState((prev) => ({
                      ...prev,
                      _incomingRecordingAccess: !prev?._incomingRecordingAccess,
                    }))
                  }
                />
                <span className="slider round"></span>
              </label>
              Allow team members to start and pause recording
            </h6> */}
          </div>
          <p className="mt-2">
            We recommend you mention that the call will be recorded in the
            Welcome message If you wish to delete recorded calls, contact our
            support.
          </p>

          <h2 className="mt-3">Email Notifications</h2>
          <div className="num-setting-call-recording-box num-setting-call-recording-box-2">
            <span className="email-notifi-line">
              <h6>
                <label className="switch mr-3">
                  <input
                    type="checkbox"
                    name="_voicmemailsByEmail"
                    value={_voicmemailsByEmail ? "checked" : ""}
                    checked={_voicmemailsByEmail ? "checked" : ""}
                    onChange={() =>
                      setState((prev) => ({
                        ...prev,
                        _voicmemailsByEmail: !prev?._voicmemailsByEmail,
                      }))
                    }
                  />
                  <span className="slider round"></span>
                </label>
                <span>
                  Voicemails by mail
                  <h5>Want a mail for each voicemail?</h5>
                </span>
              </h6>
            </span>
            <span className="email-notifi-line">
              <h6>
                <label className="switch mr-3">
                  <input
                    type="checkbox"
                    name="_mailOnCallAssigned"
                    value={_mailOnCallAssigned ? "checked" : ""}
                    checked={_mailOnCallAssigned ? "checked" : ""}
                    onChange={() =>
                      setState((prev) => ({
                        ...prev,
                        _mailOnCallAssigned: !prev?._mailOnCallAssigned,
                      }))
                    }
                  />
                  <span className="slider round"></span>
                </label>
                <span>
                  Mail on calls assigned to you
                  <h5>
                    Do you want to receive a mail with each call assigned?
                  </h5>
                </span>
              </h6>
            </span>
            <span className="email-notifi-line">
              <h6>
                <label className="switch mr-3">
                  <input
                    type="checkbox"
                    name="_callSummary"
                    value={_callSummary ? "checked" : ""}
                    checked={_callSummary ? "checked" : ""}
                    onChange={() =>
                      setState((prev) => ({
                        ...prev,
                        _callSummary: !prev?._callSummary,
                      }))
                    }
                  />
                  <span className="slider round"></span>
                </label>
                <span>
                  Daily summary of calls to follow up
                  <h5>
                    Do you want to receive a list of call follow ups daily?
                  </h5>
                </span>
              </h6>
            </span>
            <span className="email-notifi-line">
              <h6>
                <label className="switch mr-3">
                  <input
                    type="checkbox"
                    name="_activityReport"
                    value={_activityReport ? "checked" : ""}
                    checked={_activityReport ? "checked" : ""}
                    onChange={() =>
                      setState((prev) => ({
                        ...prev,
                        _activityReport: !prev?._activityReport,
                      }))
                    }
                  />
                  <span className="slider round"></span>
                </label>
                <span>
                  Nxatel activity report
                  <h5>
                    Receive report on call activity? &nbsp;
                    {_activityReport && (
                      <select className="form-control">
                        <option>Every Day</option>
                        <option>Every Week</option>
                        <option>Every Month</option>
                        <option>Every Year</option>
                      </select>
                    )}
                  </h5>
                </span>
              </h6>
            </span>
          </div>
        </div>
      </div>
      <div className="modal-footer add-modal-modal-footer">
        <button
          type="button"
          className="btn btn-meet-join"
          onClick={CallRecordingSettingsHandler}
        >
          Save Settings
        </button>
      </div>
    </Modal>
  );
}

export default NumberSettingModal;
