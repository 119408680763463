import React, { Component, useEffect, useState, useRef } from "react"; 
import { useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import GoogleMap from "../../../Component/GoogleMapComp/GoogleMap";  
import { store } from "../../../Services";
import { UpdateAddressDetails } from "../../../Services/Actions/signupActions"; 

export const VerificationTicks = ({ Selecttab }) => {
  const selfie = useSelector(
    (state) => state?.user?.user_details?.userDetail?.ricaProcess?.selfie ?? ""
  );
  const address_verified = useSelector(
    (state) => state?.user?.user_details?.userDetail?.ricaProcess?.city ?? ""
  );
  return (
    <div className="d-flex mt-3 justify-content-center">
      <div className="validation-handler">
        <div
          className={`${
            selfie ? "tick" : "porting"
          }-box p-4 d-flex porting-box-rica flex-column justify-content-center align-items-center webphone-btns`}
          onClick={() => Selecttab("id_verification")}
        >
          <img
            src={`./../assets/images/${
              selfie
                ? "icons/tick-white-circle.svg"
                : "signup/COMPANY-REGISTRATION-IMG.svg"
            }`}
            alt="1"
          />
          <h6 className="font-14 color-fff lh-17">
            {selfie
              ? "ID/PASSPORT SUCCESS,PENDING VERIFCATION"
              : "ID VERIFICATION"}
          </h6>
        </div>
      </div>
      <div className="validation-handler">
        <div
          className={`${
            address_verified ? "tick" : "porting"
          }-box p-4 d-flex porting-box-rica-address flex-column justify-content-center align-items-center webphone-btns`}
          onClick={() => Selecttab("address_verfication")}
        >
          <img
            src={`./../assets/images/${
              address_verified
                ? "icons/tick-white-circle.svg"
                : "signup/ic_outline-location-on.svg"
            }`}
            alt="1"
          />
          <h6 className="font-14 color-fff lh-17">
            {address_verified ? "ADDRESS CONFIRMED" : `CONFIRM YOUR ADDRESS`}
          </h6>
        </div>
      </div>
    </div>
  );
};

export const GoogleMapsComp = () => {
  return (
    // <div id="map-id">
    //   <GoogleMapComp
    //     center={{ lat: 18.5204, lng: 73.8567 }}
    //     height="250px"
    //     width="100%"
    //     zoom={16}
    //   /> 
    // </div>
    <div className="main-wrapper">
      <GoogleMap />
    </div>
  );
};

export const AddressComp = () => {
  const [address, setAddress] = useState("");
  const google_maps_address = useSelector(
    (state) => state?.signup?.google_maps_address??{}
  );
  useEffect(() => {
    if (google_maps_address?.address) {
      setAddress(google_maps_address.address);
    }
  }, [google_maps_address]);

  return (
    <div className="map-form">
      <div className="row">
        <div className="col-md-6">
          <div className="map-form-bx">
            <h3>Select your location form the map</h3>
            {/* <div className="form-group">
                <label>Address</label>
                <input 
                    type="text" 
                    className="form-control" 
                    name="address"
                    value={address}
                    onChange={(e)=>setAddress(e.target.value)}
                />
                <a href={void 0}><img src="./../assets/images/send-plain.svg" alt="1"/></a>
            </div> */}
          </div>
        </div>
        <div className="col-md-6">
          <div className="map-form-bx">
            <p>
              Move the pin on the map to find your location and select the
              delivery address.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FullAddressComp = ({ Selecttab }) => {
  const google_maps_address = useSelector(
    (state) => state?.signup?.google_maps_address??{}
  );
  const [line1, setLine] = useState("");
  const [city, setCity] = useState("");
  const [line2, setLine2] = useState("");
  const [code, setCode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [default_address, setDefaultAddress] = useState(false);
  // const [terms_conditions,setTerms] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [, forceUpdate] = useState();

  useEffect(() => {
    if (
      typeof google_maps_address === "object" &&
      Object.keys(google_maps_address).length > 0
    ) {
      const { country, address, city, state, zip } = google_maps_address;
      setLine(address);
      setCity(city);
      setCode(zip);
      setState(state);
      setCountry(country);
    }
  }, [google_maps_address]);
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const onSubmitHanlder = async () => {
    if (!validator.current.allValid()) {
      setClicked(false);
      validator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setClicked(true);
    let sendData = {
      zipcode: code,
      country,
      city,
      state,
      address: `${line1} ${line2}`,
      default_address,
    };
    let response = await UpdateAddressDetails(sendData,store.dispatch) 
    if (response === 1) {
      Selecttab("company_detail_verification");
    }
    setClicked(false);
  };

  return (
    <div className="add-address-form">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Address 1"
              name="line1"
              value={line1}
              onChange={(e) => setLine(e.target.value)}
            />
            {validator.current.message("Address Line 1", line1, "required")}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="City"
              name="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            {validator.current.message("City", city, "required")}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Address 2"
              name="line2"
              value={line2}
              onChange={(e) => setLine2(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Zip Code"
              name="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            {validator.current.message("Code", code, "required")}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="State"
              name="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            {validator.current.message("State", state, "required")}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Country"
              name="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
            {validator.current.message("Country", country, "required")}
          </div>
        </div>
      </div>
      <p className="color-7AFE font-14 fw-400 d-flex">
        Default business address
        <label className="switch ml-3">
          <input
            type="checkbox"
            checked={default_address}
            onChange={() => setDefaultAddress(!default_address)}
          />
          <span className="slider round"></span>
        </label>
      </p>
      <div className="d-flex by-clicking">
        {/* <div className="form-check p-0">
            <input 
                type="checkbox" 
                className="form-check-input" id="exampleCheck1"
                value={terms_conditions?"checked":""} 
                name="terms_conditions" 
                checked={terms_conditions?"checked":""} 
                onChange={()=>setTerms(!terms_conditions)}
            /> 
            <label className="form-check-label font-14 color-191b fw-500" htmlFor="exampleCheck1">By signing up, you agree to our &nbsp;
            <a className="color-7414 fw-500">Terms</a>&nbsp;&amp;&nbsp;<a className="color-7414 fw-500">Privacy Policy</a>
            </label>
            {validator.current.message("Terms & conditions", terms_conditions, "required|accepted")}
        </div>  */}
        <button
          type="button"
          className="btn start-verification ml-3"
          onClick={onSubmitHanlder}
        >
          {clicked ? "Please Wait" : "Verify Address"}
        </button>
      </div>
    </div>
  );
};

class AddressVerification extends Component {
  render() {
    const { Selecttab } = this.props;
    return (
      <div className="col-md-8 col-lg-9">
        <div className="row">
          <div className="col-md-12">
            <div className="porting-process">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="porting-inner id-verifications">
                      <VerificationTicks Selecttab={Selecttab} />
                      <div className="add-address-map">
                        <GoogleMapsComp />
                        <AddressComp />
                      </div>
                      <FullAddressComp Selecttab={Selecttab} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddressVerification;
