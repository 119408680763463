import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Notes from "./Design/Notes";
import TransferScreen from "./Design/TransferScreen";

import {
  parsePhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import IdleFrame from "./CallFrames/IdleFrame";
import MultipleCalls from "./CallFrames/MultipleCalls";
import KeypadFrame from "./CallFrames/KeypadFrame";
import { 
  CreateNotes,
  FetchOutNumber,
  ForceCall,
  NotesListing, 
} from "../../Services/Actions/callActions";
import { connect } from "react-redux";
import { store } from "../../Services";
import { ExtensionListing } from "../../Services/Actions/settingActions";
import {
  FetchUserDetails,
  UpdateCustomerCallerID,
} from "../../Services/Actions/userActions";
import { capitalizeFirstLetter } from "../../Utils/Common";
import Flag from "react-world-flags";
import authService from "../../Utils/authService";

const initialState = {
  number: "",
  speaker: true,
  transfer: false,
  conference: false,
  target: null,
  transfer_type: "",
  conference_type: "",
  keyPad: false,
  phone_dtmf: "",
  notes: false,
  notes_list: true,
  notes_text: "",
  showNote: "",
  speakFirst: false,
  did_number: "",
  history: false,
  countryCode: "",
  callerNumber: "",
  caller_picture: "",
  showKeypad:false
};
class WebPhone extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = async () => {
    window.$(".dailer-mobile").draggable({
      handle: ".dailer-key",
      containment: "#diallerModal",
    });
    if (localStorage.did_number) {
      this.setState({ did_number: localStorage.did_number });
    }
  };

  SpeakerOff = () => {
    this.setState({ speaker: !this.state.speaker }, () => {
      const { speaker } = this.state;
      document.querySelectorAll("audio").forEach((e) => {
        e.volume = speaker;
      });
    });
  };

  TransferHandler = () => {
    this.setState({ transfer: true, target: "" }, () => {
      ExtensionListing(0, store.dispatch);
    });
  };

  ConferenceHandler = () => {
    this.setState({ conference: true, target: "" }, () => {
      ExtensionListing(0, store.dispatch);
    });
  };

  TransferCall = (transfer_type) => {
    this.setState({ transfer_type });
  };

  ConferenceCall = (conference_type) => {
    this.setState({ conference_type });
  };

  ShowKeyPadHandler=(value)=>{
    this.setState({
      showKeypad:value 
    })
  }

  ExternalNumberHandler = ()=>{
    this.setState({
      number:""
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      keypad_tone,
      force_call,
      StartCall,
      user_details: { did_numbers: did_list, callerID },
      reference,
    } = this.props;
    const { did_number } = this.state;
    const { force_call: old_force, reference: old_reference } = prevProps;
    if (Object.keys(old_reference).length !== Object.keys(reference).length) {
      this.setState({
        keyPad: false,
        phone_dtmf: "",
        notes: false,
        notes_list: true,
        notes_text: "",
      });
    }
    if (old_force !== force_call && force_call) {
      this.setState({ number: force_call }, () => {
        StartCall(force_call);
        ForceCall("", store.dispatch);
      });
    }
    const { number: old_number } = prevState;
    const { number } = this.state;
    if (old_number !== number) {
      NotesListing(number, false, store.dispatch);
      keypad_tone.play();
      this.DetectCountry();
    }
    /**
     * @param did_list listing of did's available
     * @localstorage did_number to send it into headers
     */
    if (
      did_list &&
      did_list.length > 0 &&
      !localStorage.did_number &&
      did_number === ""
    ) {
      this.setState({ did_number: did_list[0].did_number }, () => {
        localStorage.setItem("did_number", did_list[0].did_number);
      });
    }

    if (
      prevProps.user_details !== this.props.user_details &&
      this.props.user_details !== ""
    ) {
      this.setState({
        did_number: callerID,
      });
    }
  }

  PassDTMF = (value, getClass) => {
    const { DTMF } = this.props;
    if (getClass === "phone_dtmf") {
      DTMF(value);
    }
  };

  DetectCountry = () => {
    let { number } = this.state;
    number = number.includes("+") ? number : `+${number}`;
    if (number.length <= 3) {
      this.setState({ countryCode: "" });
      return false;
    }
    const intFormat = formatPhoneNumberIntl(number);
    const phoneNumber = parsePhoneNumber(intFormat);
    let countryCode;
    if (phoneNumber) {
      countryCode = phoneNumber.country
        ? phoneNumber.country
        : phoneNumber?.metadata?.country_calling_codes?.[
            phoneNumber?.countryCallingCode
          ]?.[0];
    } else {
      countryCode = "unknown";
    }
    this.setState({ countryCode });
  };

  SubmitNotes = () => {
    const { notes_text } = this.state;
    const {
      sessions: { number },
    } = this.props;
    if (this.validator.allValid()) {
      CreateNotes(
        { note: notes_text, number: number.replace(/[^0-9]/g, "") },
        store.dispatch
      );
      this.setState(
        { notes_text: "", notes: true, notes_list: true },
        this.NotesList
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  NotesList = () => {
    const { reference, _activeCall } = this.props;
    if (reference[_activeCall]) {
      NotesListing(
        { number: reference[_activeCall].number },
        false,
        store.dispatch
      );
    }
  };

  ShowNoteDiv = (showNote) => {
    this.setState({ showNote });
  };

  BackHandler = () => {
    const { notes, notes_list, showNote, transfer, conference } = this.state;
    if (notes && notes_list && showNote === "") {
      this.setState({ notes: false, notes_text: "" });
    } else if (notes && notes_list && showNote !== "") {
      this.setState({ showNote: "", notes_text: "" });
    } else if (notes && !notes_list) {
      this.setState({ notes_list: true, showNote: "", notes_text: "" });
      this.NotesList();
    } else if (transfer) {
      this.setState({ transfer: false });
    } else if (conference) {
      this.setState({ conference: false });
    }
  };

  EditHandler = () => {
    const { notes, notes_list } = this.state;
    if (notes && notes_list) {
      this.setState({ notes_list: false });
    }
  };

  PostTransfer = () => {
    this.setState({ transfer: false, target: null });
  };

  PostConference = () => {
    this.setState({ conference: false, target: null });
  };

  DetectCaller = () => {
    this.setState({ callerNumber: "loading..." }, async () => {
      const { number } = this.state;
      const response = await FetchOutNumber(number, false);
      this.setState({ callerNumber: response });
    });
  };

  onChangeHandler = (e) => {
    const name = e.target.name;
    if (name === "number") {
      this.setState({
        number: e.target.value.toString().replace(/[^0-9*#]/g, ""),
      });
    } else {
      this.setState({ [name]: e.target.value }, async () => {
        if (name === "did_number") {
          let res = await UpdateCustomerCallerID(
            { callerID: this.state.did_number },
            store.dispatch
          );
          if (res === 1) {
            FetchUserDetails(store.dispatch);
            localStorage.setItem("did_number", this.state.did_number);
          }
        }
      });
    }
  };

  render() {
    // prettier-ignore
    const {keyPad,notes,transfer,conference,callerNumber,speaker,notes_text,notes_list,showNote,transfer_type,conference_type,countryCode,number,did_number,history,caller_picture,showKeypad} = this.state; 

    // prettier-ignore
    const {reference,_activeCall,HangUpCall,MuteCall,HoldCall,DTMF,call_notes,extension_listing,CallTransfer,user_details:{did_numbers:did_list},voice_minute,StartCall,transfer_sessions,conference_sessions,CallRecording} = this.props; 
    let value = _activeCall ? _activeCall : Object.keys(reference)[0];
    const activeSession = reference[value]; 
    const idleCallHandler = {
      countryCode,
      number,
      did_number,
      voice_minute,
      did_list,
      history,
    }; 
    const {
      name = "",
      direction = "",
      number: activeNumber = "",
      uuid = "",
    } = activeSession || {};
    let filterName = name.includes("#")
      ? name.split("#")
      : isNaN(name)
      ? capitalizeFirstLetter(name)
      : "Unknown Caller";
    filterName = name.includes("#") ? filterName[1] : filterName;
    let calling_number = direction === "inbound" ? activeNumber : callerNumber; 

    return (
      <div className="row ">
        {/** IdleFrame when no call */}

        {(Object.keys(reference)?.length === 0 || showKeypad) && (
          // || (activeSession?.status === 'ringing' && activeSession?.direction === 'inbound')
          // when dialer is open and another incoming call ringing
          <IdleFrame
            onChangeHandler={this.onChangeHandler}
            KeyPadBtn={this.KeyPadBtn}
            StartCall={StartCall}
            DetectCaller={this.DetectCaller} 
            ShowKeyPadHandler={this.ShowKeyPadHandler}
            showKeypad={showKeypad}
            transfer={transfer}
            conference={conference}
            PostTransfer={this.PostTransfer}
            PostConference={this.PostConference}
            TransferCall={this.TransferCall}
            ConferenceCall={this.ConferenceCall}
            DTMF={DTMF}
            activeSession={activeSession}
            ExternalNumberHandler={this.ExternalNumberHandler}
            {...idleCallHandler}
          />
        )}

        {/**SINGLE CALL CASE */}

        {Object.keys(reference)?.length === 1 &&
          !transfer_sessions[uuid] &&
          !conference_sessions[uuid] && !showKeypad && (
            // && reference[_activeCall]
            <div className="col-md-12 mt-2">
              <div
                className={`dailer-key dailer-key-2 ${
                  keyPad ? "" : "calling-pad connected-call-dialer"
                } m-auto`}
              >
                <div className="dailer-top-bar dailer-top-connecting-bar duration-on-call-bar">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex  align-items-center ">
                        <span className="call-duration-pro-pic">
                          <img
                            src={
                              caller_picture
                                ? caller_picture
                                : "./../assets/images/pro-pic.png"
                            }
                            alt="1"
                          />
                        </span>
                        <div className="d-flex flex-column">
                          <span>
                            {activeSession?.status?.[0]?.toUpperCase() +
                              activeSession?.status?.substring(1)}
                            {activeSession?.status !== "connecting" && (
                              <span className={`ml-1 stopwatch_${_activeCall}`}>
                                00:00
                              </span>
                            )}
                          </span>
                          <p className="on-call-user-name">{filterName}</p>
                        </div>
                      </div>
                      {(notes || transfer || conference) && (
                        <a
                          href={void 0}
                          onClick={this.BackHandler}
                          className="back-arrow-dial webphone-btns"
                        >
                          <i className="fa fa-arrow-left" />
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="calling-user-div">
                    <div className="calling-user-img">
                      {calling_number?.length === 0 ||
                      calling_number?.length >= 6 ? (
                        <Flag
                          code={
                            countryCode !== "" && countryCode !== "unknown"
                              ? countryCode
                              : "ZA"
                          }
                          style={{ marginRight: "32px" }}
                          height="20 px"
                          fallback={<span></span>}
                        />
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div className="calling-user-name">
                      <h3>{calling_number}</h3>
                    </div>
                  </div>
                </div>
                {/* )}  */}
                {notes && notes_list && (
                  <a
                    className="notes-edit"
                    href={void 0}
                    onClick={this.EditHandler}
                  >
                    <i className="far fa-edit" />
                  </a>
                )}
                {notes && (
                  <>
                    {notes_list && showNote === "" && (
                      <Notes
                        notes_list={call_notes}
                        ShowNotes={this.ShowNoteDiv}
                      />
                    )}
                    {notes_list && showNote !== "" && (
                      <div className="notes-show">{showNote}</div>
                    )}
                    {!notes_list && (
                      <div className="input-group notes-input-group">
                        <textarea
                          name="notes_text"
                          className="form-control"
                          value={notes_text}
                          rows={5}
                          placeholder="Enter Notes"
                          onChange={(e) =>
                            this.setState({
                              notes_text: e.target.value,
                            })
                          }
                        />
                        {this.validator.message(
                          "Notes",
                          notes_text,
                          "required"
                        )}
                      </div>
                    )}
                    {!notes_list && (
                      <div className="number mb-3">
                        <div className="d-flex justify-content-around">
                          <a
                            href={void 0}
                            onClick={this.SubmitNotes}
                            className="webphone-btns mt-0"
                          >
                            <div className="text">
                              <i className={`fas fa-plus`} />
                            </div>
                            <p>Add</p>
                          </a>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {activeSession?.status !== "ringing" &&
                  (transfer || conference) && (
                    <TransferScreen
                      list={extension_listing}
                      DTMF={DTMF}
                      PostTransfer={this.PostTransfer}
                      PostConference={this.PostConference}
                      TransferCall={this.TransferCall}
                      ConferenceCall={this.ConferenceCall}
                      CallTransfer={CallTransfer}
                      activeSession={activeSession}
                      transfer={transfer}
                      conference={conference}
                      ShowKeyPadHandler={this.ShowKeyPadHandler} 
                    />
                  )}
                {keyPad && this.KeyPadBtn("phone_dtmf")}
                {!transfer &&
                  !conference &&
                  !keyPad &&
                  !notes &&
                  ((activeSession?.status !== "ringing" &&
                    activeSession?.direction === "inbound") ||
                    activeSession?.direction === "outbound") && (
                    <KeypadFrame 
                      activeSession={activeSession} 
                      transfer_type={transfer_type}
                      conference_type={conference_type}  
                      speaker={speaker}
                      MuteCall={MuteCall}
                      SpeakerOff={this.SpeakerOff}
                      HangUpCall={HangUpCall}
                      ShowKeyPad={() => this.setState({ keyPad: true })}
                      HoldCall={HoldCall}
                      TransferHandler={this.TransferHandler}
                      ConferenceHandler={this.ConferenceHandler} 
                      DTMF={DTMF}
                      CallRecording={CallRecording} 
                      NotesClickHanlder={() =>
                        this.setState(
                          { notes: true, notes_list: true, showNote: "" },
                          () => {
                            this.NotesList();
                          }
                        )
                      }
                    />
                  )}
              </div>
            </div>
          )}

        {/**MULTIPLE CALL CASE */}

        {(Object.keys(reference).length > 1 ||
          transfer_sessions[uuid] ||
          conference_sessions[uuid]) && !showKeypad && (
          <MultipleCalls
            {...this.props}
            speaker={speaker}
            keyPad={keyPad}
            SpeakerOff={this.SpeakerOff}
            ShowKeyPad={() => this.setState({ keyPad: true })}
            NotesClickHandler={() =>
              this.setState(
                { notes: true, notes_list: true, showNote: "" },
                () => {
                  this.NotesList();
                }
              )
            }
            transfer={transfer}
            conference={conference}  
            PostTransfer={this.PostTransfer}
            PostConference={this.PostConference}
            TransferCall={this.TransferCall}
            ConferenceCall={this.ConferenceCall}
            transfer_type={transfer_type}
            conference_type={conference_type}
            TransferHandler={this.TransferHandler}
            ConferenceHandler={this.ConferenceHandler}
            BackHandler={this.BackHandler}
            HoldCall={HoldCall}
            activeSession={activeSession}
            ShowKeyPadHandler={this.ShowKeyPadHandler} 
            CallRecording={CallRecording} 
          >
            {keyPad && this.KeyPadBtn("phone_dtmf")}
          </MultipleCalls>
        )}
      </div>
    );
  }

  KeyPadBtn = (getClass) => {
    let { number, phone_dtmf, target, keyPad } = this.state;
    number =
      getClass === "number"
        ? number
        : getClass === "phone_dtmf"
        ? phone_dtmf
        : target;
    return (
      <>
        {keyPad && (
          <div className="input-group keypad-input-group">
            <input
              type="text"
              className="form-control text-center"
              name="phone_dtmf"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={phone_dtmf}
              onChange={(e) =>
                this.setState({
                  phone_dtmf: e.target.value,
                })
              }
            />
          </div>
        )}
        <div className={`number `}>
          <div className={`d-flex justify-content-between`}>
            <a
              href={void 0}
              className="webphone-btns"
              onClick={(e) =>
                this.setState(
                  {
                    [getClass]: `${number}${1}`,
                  },
                  () => {
                    this.PassDTMF("1", getClass);
                  }
                )
              }
            >
              <div className="text">1</div>
            </a>
            <a
              href={void 0}
              className="webphone-btns"
              onClick={(e) =>
                this.setState(
                  {
                    [getClass]: `${number}${2}`,
                  },
                  () => {
                    this.PassDTMF("2", getClass);
                  }
                )
              }
            >
              <div className="text">
                2<span className="alpha-bet">ABC</span>
              </div>
            </a>
            <a
              href={void 0}
              className="webphone-btns"
              onClick={(e) =>
                this.setState(
                  {
                    [getClass]: `${number}${3}`,
                  },
                  () => {
                    this.PassDTMF("3", getClass);
                  }
                )
              }
            >
              <div className="text">
                3<span className="alpha-bet">DEF</span>
              </div>
            </a>
          </div>
        </div>
        <div className={`number `}>
          <div className={`d-flex justify-content-between`}>
            <a
              href={void 0}
              className="webphone-btns"
              onClick={(e) =>
                this.setState(
                  {
                    [getClass]: `${number}${4}`,
                  },
                  () => {
                    this.PassDTMF("4", getClass);
                  }
                )
              }
            >
              <div className="text">
                4<span className="alpha-bet">GHI</span>
              </div>
            </a>
            <a
              href={void 0}
              className="webphone-btns"
              onClick={(e) =>
                this.setState(
                  {
                    [getClass]: `${number}${5}`,
                  },
                  () => {
                    this.PassDTMF("5", getClass);
                  }
                )
              }
            >
              <div className="text">
                5<span className="alpha-bet">JKL</span>
              </div>
            </a>
            <a
              href={void 0}
              className="webphone-btns"
              onClick={(e) =>
                this.setState(
                  {
                    [getClass]: `${number}${6}`,
                  },
                  () => {
                    this.PassDTMF("6", getClass);
                  }
                )
              }
            >
              <div className="text">
                6<span className="alpha-bet">MNO</span>
              </div>
            </a>
          </div>
        </div>
        <div className={`number `}>
          <div className={`d-flex justify-content-between`}>
            <a
              href={void 0}
              className="webphone-btns"
              onClick={(e) =>
                this.setState(
                  {
                    [getClass]: `${number}${7}`,
                  },
                  () => {
                    this.PassDTMF("7", getClass);
                  }
                )
              }
            >
              <div className="text">
                7<span className="alpha-bet">PQRS</span>
              </div>
            </a>
            <a
              href={void 0}
              className="webphone-btns"
              onClick={(e) =>
                this.setState(
                  {
                    [getClass]: `${number}${8}`,
                  },
                  () => {
                    this.PassDTMF("8", getClass);
                  }
                )
              }
            >
              <div className="text">
                8<span className="alpha-bet">TUV</span>
              </div>
            </a>
            <a
              href={void 0}
              className="webphone-btns"
              onClick={(e) =>
                this.setState(
                  {
                    [getClass]: `${number}${9}`,
                  },
                  () => {
                    this.PassDTMF("9", getClass);
                  }
                )
              }
            >
              <div className="text">
                9<span className="alpha-bet">WXYZ</span>
              </div>
            </a>
          </div>
        </div>
        <div className={`number `}>
          <div className={`d-flex justify-content-between`}>
            <a
              href={void 0}
              className="webphone-btns"
              onClick={(e) =>
                this.setState(
                  {
                    [getClass]: `${number}#`,
                  },
                  () => {
                    this.PassDTMF("#", getClass);
                  }
                )
              }
            >
              <div className="text">#</div>
            </a>
            <a
              href={void 0}
              className="webphone-btns"
              onClick={(e) =>
                this.setState(
                  {
                    [getClass]: `${number}${0}`,
                  },
                  () => {
                    this.PassDTMF("0", getClass);
                  }
                )
              }
            >
              <div className="text">0</div>
            </a>
            <a
              href={void 0}
              className="webphone-btns"
              onClick={(e) =>
                this.setState(
                  {
                    [getClass]: `${number}*`,
                  },
                  () => {
                    this.PassDTMF("*", getClass);
                  }
                )
              }
            >
              <div className="text start-circle">*</div>
            </a>
          </div>
        </div>
        {keyPad && (
          <div className="number close-dial mb-1">
            <div className="d-flex justify-content-around">
              <div className="calling-icons">
                <a
                  href={void 0}
                  className=" webphone-btns close-dialer-pad"
                  onClick={() =>
                    this.setState({
                      phone_dtmf: "",
                      keyPad: false,
                    })
                  }
                >
                  <div className="text">
                    <i className="fas fa-times" />
                  </div>
                </a>
                <p>Cancel</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    call_notes: state?.call?.notes_list ?? [],
    extension_listing: state?.setting?.extension_listing?.filter(value=>value.customerID !== authService?.getUserDetail()?.customerID) ?? [],
    user_details: state?.user?.user_details?.userDetail ?? {},
    webphone_shown: window.$("#diallerModal").hasClass("show"),
    force_call: state?.call?.force_call ?? {},
    nxatel_call: state?.call?.profile_info,
    transfer_sessions: state?.call?.transfer_sessions,
    conference_sessions: state?.call?.conference_sessions,
  };
};

export default connect(mapStateToProps)(WebPhone);
