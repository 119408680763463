import React, { Component } from "react";
import { connect } from "react-redux";
import AddIVRCallFlow from "./AddIVRCallFlow"; 
import swal from "sweetalert";
import Pagination from "../../../../Component/Pagination";
import { store } from "../../../../Services";
import {
  BusinessHourListing,
  DeleteNumberForwardCreate,
  IVRMenuList,
} from "../../../../Services/Actions/settingActions"; 
import AddBusinessHour from "./../BusinessHour/AddBusinessHour";
import { Link } from "react-router-dom"; 

class PhoneIPDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addIVR: false,
      editIVR: {},
      totalItems: [],
      pageofItems: [],
      search: "",
      addBusinessHour: false,
    };
  }

  componentDidMount = async () => {
    IVRMenuList({},store.dispatch) 
  };

  componentDidUpdate(prevProps) {
    const { ivr_menu_listing: old_list } = prevProps;
    const { ivr_menu_listing } = this.props;
    if (ivr_menu_listing && ivr_menu_listing !== old_list) {
      this.setState({ totalItems: ivr_menu_listing });
    }
  }

  closeAddIVR = () => {
    this.setState({ addIVR: false, editIVR: {} });
  };

  DeleteIVR = ({ switch_uuid, time_condition_uuid, id }) => {
    swal({
      text: "Are you sure want to delete this IVR?",
      icon: "warning",
      title:"Warning!",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await  
          DeleteNumberForwardCreate({
            type: "ivr",
            switch_uuid,
            time_condition_uuid,
            id,
          },store.dispatch) 
        if (response === 1) { 
            IVRMenuList({},store.dispatch) 
        }
      }
    });
  };

  sliceData = (pageofItems) => {
    this.setState({ pageofItems });
  };

  SearchData = (e) => {
    this.setState({ search: e.target.value }, () => {
      const { search } = this.state;
      const { ivr_menu_listing } = this.props;
      this.setState({
        totalItems: ivr_menu_listing.filter((value) => {
          return (
            value.name.toLowerCase().includes(search.toLowerCase()) ||
            value.assigned_number
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          );
        }),
      });
    });
  };

  ViewBusinessHourHandler = async (id) => {
    let res = await  BusinessHourListing(id,store.dispatch) 
    if (res === 1) {
      this.setState({
        addBusinessHour: true,
      });
    }
  };

  render() {
    const {
      addIVR,
      editIVR,
      totalItems,
      pageofItems,
      search,
      addBusinessHour,
    } = this.state;
    const { SetTab, businesshour_listing } = this.props;
    return (
      <>
        <div className="phone-system-inner-main-div">
          <div className="phone-system-inner-div">
            <div className="phone-system-inner-head">
              <div className="ps-inner-head-left">
                <h3>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => SetTab("phone")}
                    className="phone-system-name"
                  >
                    Phone Settings
                  </span>
                  <img src="./../assets/images/menu-arrow-up.svg" alt="1" />
                  <span>IVR Greeting</span>
                </h3>
              </div>
              <div className="ps-inner-head-right">
                <span className="search-ps-inner">
                  <img src="./../assets/images/search-dash.svg" alt="1" />
                  <input
                    className="form-control"
                    name="search"
                    value={search}
                    type="text"
                    placeholder=""
                    onChange={this.SearchData}
                  />
                </span>
                <Link
                  to="#"
                  style={{ cursor: "pointer" }}
                  className="create-ps-num-btn"
                  onClick={() => this.setState({ addIVR: true, editIVR: {} })}
                >
                  Add Auto Attendant
                </Link>
              </div>
            </div>
            <div className="phone-system-inner-table">
              {pageofItems && pageofItems.length === 0 && (
                <div className="team-user-add-div add-data-vactor">
                  <img src="./../assets/images/IVR.svg" alt="1" />
                  <h4>No (IVR) Auto Attendant added</h4>
                  <Link
                    to="#"
                    className="btn btn-orange"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ addIVR: true, editIVR: {} })}
                  >
                    Add Auto Attendant
                  </Link>
                </div>
              )} 
                <div className="phone-system-inner-table">
                {pageofItems && pageofItems.length !== 0 && (
                  <div className="table-responsive call-info-scroll single-table-height">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Business Hour</th>
                          <th>Assigned Number</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pageofItems &&
                          pageofItems.map((value, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <span className="business-hrs-service">
                                    <h3>{value.name}</h3>
                                  </span>
                                </td>
                                <td>
                                  {value.business_hour_name ? (
                                    <Link
                                      style={{ cursor: "pointer" }}
                                      to="#"
                                      onClick={() =>
                                        this.ViewBusinessHourHandler(
                                          value.business_hourID
                                        )
                                      }
                                    >
                                      {value.business_hour_name}
                                    </Link>
                                  ) : (
                                    <Link to="#">{`NA`}</Link>
                                  )}
                                </td>
                                <td className="assign-to-add"> 
                                  {value.assigned_number ?? "NA"}
                                </td>
                                <td>
                                  <Link
                                    to="#"
                                    title="Edit IVR"
                                    className="edit-business-hrs"
                                    onClick={() =>
                                      this.setState({
                                        addIVR: false,
                                        editIVR: value,
                                      })
                                    }
                                  >
                                    <img
                                      src="./../assets/images/edit-new.svg"
                                      alt="1"
                                    />
                                  </Link>
                                  &nbsp;&nbsp;
                                  <Link
                                    to="#"
                                    title="Delete IVR"
                                    className="edit-business-hrs"
                                    onClick={() => this.DeleteIVR(value)}
                                  >
                                    <img
                                      src="./../assets/images/trash-2.svg"
                                      alt="delete"
                                    />
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
                  <Pagination data={totalItems} currentData={this.sliceData} />
                </div> 
            </div>
          </div>
        </div>
        <AddIVRCallFlow
          addIVR={addIVR}
          editIVR={editIVR}
          closeAddIVR={this.closeAddIVR}
        />

        <AddBusinessHour
          viewOnly={true}
          addBusinessHour={addBusinessHour}
          businessData={businesshour_listing?.[0]}
          closeBusinessHour={() => this.setState({ addBusinessHour: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ivr_menu_listing: state?.setting?.ivr_menu_listing ?? [],
  businesshour_listing: state?.setting?.businesshour_listing ?? [],
});

export default connect(mapStateToProps)(PhoneIPDevices);
