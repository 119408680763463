import * as constants from "../Constants/callConstants";

 

const initalState = {  
    force_call:{},
    team_listing:[], 
    sms_chat_list:[],
    nxa_contacts:[],
    recent_call_list:[],
    recent_call_count:0,
    all_recording_count:0,
    all_recording_list:[],
    voicemail_list:[], 
    selected_chat_user:{},
    selected_chat_type:"",
    call_details:[],
    assigned_number_list:[],
    individual_call_history:[],
    tag_listing:[],
    profile_info:{},
    notes_list:{},
    sms_chat_history:[],
    selected_group_chat_user:{},
    open_nxatel_chat:{},
    transfer_sessions:{},
    conference_sessions:{}
}

const callReducer = (state = initalState, action) => {
    switch (action.type) {
        case constants.FORCE_CALL:
            return {
                ...state,
                force_call: (action.payload).replace("+",''),
            };
        case constants.FETCH_TEAM_LISTING:  
            return {
                ...state,
                team_listing: action.payload,
            };
        case constants.FETCH_SMS_CHAT_LISTING:
            return {
                ...state,
                sms_chat_list:action.payload,
            } 
        case constants.NXA_CONTACT_LISTING:
            return {
                ...state,
                nxa_contacts: action.payload,
            }; 
        case constants.FETCH_RECENT_CALL_LISTING:
            return {
                ...state,
                recent_call_list: action.payload,
            };
        case constants.CALL_LISTING_COUNT:
            return {
                ...state,
                recent_call_count: action.payload,
            }
        case constants.FETCH_ALL_RECORDING_LISTING:
            return {
                ...state,
                all_recording_list: action.payload,
            }
        case constants.ALL_RECORDING_COUNT:
            return {
                ...state,
                all_recording_count: action.payload,
            };
        case constants.FETCH_VOICEMAIL_LISTING:
            return {
                ...state,
                voicemail_list: action.payload,
            } 
        case constants.SELECTED_CHAT_USER:
            return {
                ...state,
                selected_chat_user: action.payload,
            };
        case constants.SELECTED_CHAT_TYPE: 
            return {
                ...state,
                selected_chat_type: action.payload,
            }; 
        case constants.FETCH_CALL_DETAILS:
            return {
                ...state,
                call_details: action.payload,
            };
        case constants.FETCH_ASSIGNED_NUMBER_LIST:
            return {
                ...state,
                assigned_number_list: action.payload,
            };
        case constants.INDIVIDUAL_CONTACT_CALL_HISTORY:
            return {
                ...state,
                individual_call_history: action.payload,
            };
        case constants.GET_TAG_LISTING:
            return {
                ...state,
                tag_listing: action.payload,
            }; 
        case constants.FETCH_PROFILE_INFO_DATA:
            console.log("profile_info====>",action.payload)
            return {
                ...state,
                profile_info: action.payload,
            };
        case constants.FETCH_NOTES_LISTING:
            console.log("note listing====>",action.payload)
            return {
                ...state,
                notes_list: action.payload,
            };
        case constants.FETCH_SMS_CHAT_HISTORY:
            return {
                ...state,
                sms_chat_history:action.payload,
            }
        case constants.SELECTED_GROUP_CHAT_USER:
            return {
                ...state,
                selected_group_chat_user: action.payload,
            }; 
        case constants.OPEN_NXATEL_USER_CHAT: 
            return {
                ...state,
                open_nxatel_chat: action.payload,
            };
        case constants.FETCH_TRANSFER_CALL_SESSIONS: 
            return {
                ...state,
                transfer_sessions: action.payload,
            };
        case constants.FETCH_CONFERENCE_CALL_SESSIONS: 
            return {
                ...state,
                conference_sessions: action.payload,
            };
        default:
            return state
    }
}

export default callReducer;
