import React, { useEffect, useState } from "react"; 
import swal from "sweetalert";
import Modal from "../../Component/Modal";

const initialState = {
    microphonePermission: false,
    cameraPermission: false,
    notificationPermission: false,
};

function CreatePermissionModal({ showModal, closeModal }) {
    const [values, setValues] = useState(initialState);

    useEffect(() => {
        if (Notification.permission === "granted") {
            setValues((prev) => ({
                ...prev,
                notificationPermission: true,
                cameraPermission: true,
                notificationPermission: true,
            }));
            closeModal();
        }
    }, []);

    const checkPermissions = (value) => {
        let params =
            value === "camera"
                ? { audio: false, video: true }
                : { audio: true, video: false };
        const permissions = navigator.mediaDevices.getUserMedia(params);
        permissions
            .then((stream) => {
                if (value === "camera") {
                    setValues((prev) => ({
                        ...prev,
                        cameraPermission: true,
                    }));
                } else {
                    setValues((prev) => ({
                        ...prev,
                        microphonePermission: true,
                    }));
                }
            })
            .catch((err) => {
                if (value === "camera") {
                    swal({
                        icon: "warning",
                        title:"Warning!",
                        text: "Camera permission has been blocked, please allow permission!",
                        buttons: true,
                    });
                    setValues((prev) => ({
                        ...prev,
                        cameraPermission: false,
                    }));
                } else {
                    swal({
                        icon: "warning",
                        title:"Warning!",
                        text: "Microphone permission has been blocked, please allow permission!",
                        buttons: true,
                    });
                    setValues((prev) => ({
                        ...prev,
                        microphonePermission: false,
                    }));
                }
            });
    };

    const NotificationPermission = () => {
        setValues((prev) => ({
            ...prev,
            notificationPermission: true,
        }))
        // if (Notification.permission !== "denied") {
        //     Notification.requestPermission().then((permission) => {
        //         if (permission === "granted") {
        //             setValues((prev) => ({
        //                 ...prev,
        //                 notificationPermission: true,
        //             }));
        //         }
        //     });
        // } 
        // else if (Notification.permission === "denied") {
        //     swal({
        //         icon: "warning",
                // title:"Warning!",
        //         text: "Notification permission has been blocked, please allow permission!",
        //         buttons: true,
        //     });
        //     setValues((prev) => ({
        //         ...prev,
        //         notificationPermission: false,
        //     }));
        // }
    };

    const grantPermissionHandler = () => {
        const {
            microphonePermission,
            cameraPermission,
            notificationPermission,
        } = values;
        if (
            microphonePermission &&
            cameraPermission &&
            notificationPermission
        ) {
            closeModal();
        } else {
            swal({
                icon: "error",
                title: "Error!",
                text: "All permissions are mandatory, please allow the permissions!",
                buttons: true,
            });
        }
    };

    return (
        <Modal
            open={showModal}
            size={`sm`}
            modalType={`permission-modal`}
            showActiveClass={showModal}
        >
            <div className="permission-modal">
                <h3 className="text-center">We need your permission!</h3>
                <p className="text-center">
                    To enjoy using Nxatel we need you to allow access
                    <br /> to some permission.
                </p>

                <div className="Mandatory-div ">
                    <span className="d-flex justify-content-between align-items-center">
                        <h6>
                            {" "}
                            Microphone <span>(Mandatory)</span>
                        </h6>
                        <div class="form-check d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="microphone"
                                name="checkMicrophone"
                                className="form-check-input"
                                value="checkMicrophone"
                                onChange={() => checkPermissions("microphone")}
                                checked={
                                    values?.microphonePermission ? true : false
                                }
                            />
                            <label
                                class="form-check-label"
                                for="microphone"
                            ></label>
                        </div>
                    </span>
                    <p>Be heard by callee</p>
                </div>
                <div className="Mandatory-div ">
                    <span className="d-flex justify-content-between align-items-center">
                        <h6>
                            Call Notifications <span>(Mandatory)</span>
                        </h6>
                        <div class="form-check d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="notifications"
                                onChange={NotificationPermission}
                                checked={
                                    values?.notificationPermission
                                        ? true
                                        : false
                                }
                            />
                            <label
                                class="form-check-label"
                                for="notifications"
                            ></label>
                        </div>
                    </span>
                    <p>Get incoming calls notifictaions</p>
                </div>
                <div className="Mandatory-div ">
                    <span className="d-flex justify-content-between align-items-center">
                        <h6>
                            {" "}
                            Video Camera <span>(Mandatory)</span>
                        </h6>
                        <div class="form-check d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="camera"
                                onChange={() => checkPermissions("camera")}
                                checked={
                                    values?.cameraPermission ? true : false
                                }
                            />
                            <label
                                class="form-check-label"
                                for="camera"
                            ></label>
                        </div>
                    </span>
                    <p>Be seen by participants on NxaMeets</p>
                </div>
                <span className="w-100 text-center">
                    <button
                        className="btn btn-orange"
                        onClick={grantPermissionHandler}
                    >
                        Grant Permission
                    </button>
                </span>
            </div>
        </Modal>
    );
}

export default CreatePermissionModal;
