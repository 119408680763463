import React, { useState } from "react";  
import { Link } from "react-router-dom";
import { removeBackdrop } from "../../Utils/Common";
import CreatePermissionModal from "./CreatePermissionModal";
import Sidebar from "./Sidebar";

function Welcome() { 
    const [showModal,setShowModal] = useState(true) 

    const closeModal = ()=>{
        setShowModal(false)
        removeBackdrop()
    }
    return (
        <>
            <div className="right-side-wrapper pt-0 ">
                <div className="card">
                    <div className="card-body p-0">
                        <div className="row m-0">
                             <Sidebar />
                            <div className="col-md-8 col-lg-9">
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <div className="dashboard-inner-div dashboard-inner-div-2">
                                            <div className="dashboard-top-box">
                                                <h2>
                                                    Welcome, Let's set up your
                                                    account!
                                                </h2>
                                            </div>
                                            <div className="dash-board-img-div">
                                                <h4>
                                                    Quick Admin guide to setting
                                                    up Nxatel
                                                </h4>
                                                <div className="welcome-video-section">
                                                    <div className="welcome-page-video">
                                                        <video
                                                            width="100%"
                                                            height="100%"
                                                            controls
                                                        >
                                                            <source
                                                                src="./../assets/videos/nxatel_dreamers.mp4"
                                                                type="video/mp4"
                                                            />
                                                        </video>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dwn-app-div">
                                                <Link
                                                    to="#"
                                                    className="skip-btn"
                                                >
                                                    Skip{" "}
                                                    <i className="fa fa-caret-right ml-1"></i>
                                                </Link>
                                                <h5>Download our app!</h5>
                                                <p>
                                                    and start making and
                                                    receiving calls
                                                </p>
                                                <div className="welcome-page-dwn-btn">
                                                    <Link to="#">
                                                        <img
                                                            src="./../assets/images/Google Play - eng.svg"
                                                            className="img-fluid"
                                                            alt="1"
                                                        />
                                                    </Link>
                                                    <Link to="#">
                                                        <img
                                                            src="./../assets/images/App Store - eng.svg"
                                                            className="img-fluid"
                                                            alt="1"
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div> 

            <CreatePermissionModal  showModal={showModal} closeModal={closeModal} />
        </>
    );
} 

export default Welcome ;
