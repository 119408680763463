import React, { Component } from "react";
import Modal from "../../../../Component/Modal";
import { store } from "../../../../Services";
import { DidNumberListing } from "../../../../Services/Actions/reportActions";
import {
  DeleteDidNumber,
  RemoveFromForwarding,
} from "../../../../Services/Actions/settingActions";
import swal from "sweetalert";
import { formatDIDNumber } from "../../../../Utils/Common";

class DeleteNumberModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  DeleteNumber = async () => {
    const {
      handleClose,
      numberDetails: { didID, type, did_number },
    } = this.props;

    switch (type) {
      case "delete-number": {
        DeleteDidNumber({ didID }, store.dispatch).then((res) => {
          if (res?.ResponseCode === "1") {
            handleClose();
            DidNumberListing(store.dispatch, "phoneSettings");
            const wrapper = document.createElement("div");
            swal({
              icon: "./../assets/images/subscription-successfull.svg",
              title: `${res?.ResponseText}`,
              content: wrapper,
              className: "add-subscription",
            });
          }
        });
        break;
      }
      case "remove-from-forward": {
        RemoveFromForwarding({ did_number }, store.dispatch).then((res) => { 
          if (res?.ResponseCode === "1") {
            handleClose();
            DidNumberListing(store.dispatch, "phoneSettings");
            const wrapper = document.createElement("div");
            swal({
              icon: "./../assets/images/subscription-successfull.svg",
              title: `${res?.ResponseText}`,
              content: wrapper,
              className: "add-subscription",
            });
          }
        });
        break;
      }
      default : {
        return false;
      }
    }
  };

  render() {
    const {
      openModal,
      handleClose,
      numberDetails: { did_number, countryImage, type },
    } = this.props;
    return (
      <>
        <Modal
          open={openModal}
          CloseModal={handleClose}
          title={""}
          showActiveClass={openModal}
          size={`sm`}
        >
          <div className="assign-modal-div delete-num-modal">
            <h3>
              <span>
                {type === "delete-number"
                  ? `Delete phone number:`
                  : `Remove from forwarding:`}
              </span>
              <span className="assign-to-num">
                {countryImage && (
                  <img src={`./../assets/images/${countryImage}`} alt="1" />
                )}{" "}
                {formatDIDNumber(did_number)}
              </span>
            </h3>
            {
              type === "delete-number" && 
              <p>
                You will lose all the data associated with this number, including
                call history and call recordings.
              </p>
            }
            {
              type === "remove-from-forward" && 
              <p>
                Are you sure you want to remove this number from forwarding to device?
              </p>
            }
            
            <div className="modal-footer number-dl-footer text-center">
              <button
                type="button"
                className="btn assign-del-btn"
                onClick={this.DeleteNumber}
                style={{ cursor: "pointer" }}
              >
                {type === "delete-number" ? `Delete` : `Remove`}
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default DeleteNumberModal;
