export const URI = "1255@tel.clemcomltd.net";
export const ALLOW_LEGACY_NOTIFICATIONS = false;
export const ALLOW_OUT_OF_DIALOG_REFERS = false;
export const AUTHORIZATION_USER = "1255";
export const AUTOSTART = true;
export const DISPLAY_NAME = "ALICE";
export const DTMF_TYPE = "";
export const HACK_IP_IN_CONTACT = true;
export const HACK_VIA_TCP = true;
export const HACK_WSS_IN_TRANSPORT = true;
export const BUILT_IN_ENABLED = true;
export const LEVEL = '2';
export const LOG = { level: LEVEL };
export const NO_ANSWER_TIMEOUT = 120;
export const PASSWORD = "9db06bcff9248837f86d1a6bcf41c9e7";
export const REGISTER_OPTIONS = "";
export const REGISTER = true;
export const REL100 = "";
export const REPLACES = "";
export const SESSION_DESCRIPTION_HANDLER_FACTORY = "";
export const SESSION_DESCRIPTION_HANDLER_FACTORY_OPTIONS = "";
export const SESSION_DESCRIPTION_HANDLER_OPTIONS = { constraints: { audio: true, video: false } };
export const TRANSPORT_CONSTRUCTOR = "";
export const TRANSPORT_OPTIONS =  {wsServers: "wss://sip-dev.nxa.tel:8080"}; 
export const PC_CONFIG = { iceServers: [{ urls: "turn:dvfs1.dialvista.com:3478?transport=tcp", credential: "dvfs123", username: "dvfs1" }] };
export const USER_AGENT_STRING = "Nxatel 0.1";

export const SIP_STATUS_DISCONNECTED = "disconnected";
export const SIP_STATUS_CONNECTING = "connecting";
export const SIP_STATUS_CONNECTED = "connected";
export const SIP_STATUS_REGISTERED = "registered";
export const SIP_CALL_STATUS = [SIP_STATUS_DISCONNECTED, SIP_STATUS_CONNECTING, SIP_STATUS_CONNECTED, SIP_STATUS_REGISTERED];

export const CALL_DIRECTION_OUTGOING = "outgoing";
export const CALL_DIRECTION_INCOMING = "incoming";
export const CALL_DIRECTION = [CALL_DIRECTION_OUTGOING, CALL_DIRECTION_INCOMING];

export const SIP_STATUS_ERROR = "error";
export const SIP_ERROR_TYPE_CONFIGURATION = "configuration error";
export const SIP_ERROR_TYPE_CONNECTION = "connection failed";
export const SIP_ERROR_TYPE_REGISTRATION = "registration error";
export const SIP_ERROR_TYPE = [SIP_STATUS_ERROR, SIP_ERROR_TYPE_CONFIGURATION, SIP_ERROR_TYPE_CONNECTION, SIP_ERROR_TYPE_REGISTRATION];

export const CALL_STATUS_IDLE = "";
export const CALL_STATUS_RINGING = "ringing";
export const CALL_STATUS_CONNECTING = "connecting";
export const CALL_STATUS_CALLING = "calling";
export const CALL_STATUS_ANSWERED = "answered";
export const CALL_STATUS_CANCELED = "canceled";
export const CALL_STATUS_MUTE = "mute";
export const CALL_STATUS_HOLD = "hold";
export const CALL_STATUS_INCALL = "In Call";
export const CALL_STATUS = [CALL_STATUS_IDLE, CALL_STATUS_RINGING, CALL_STATUS_CONNECTING, CALL_STATUS_CALLING, CALL_STATUS_ANSWERED, CALL_STATUS_CANCELED, CALL_STATUS_MUTE, CALL_STATUS_HOLD, CALL_STATUS_INCALL];

export const OPTION_CONSTRAINT = { audio: true, video: false };
export const INITIAL_STATE = { call_status: "", caller_name: "", call_type: "", start_at: "", call_duration: "00:00:00", session: null, caller_number: "", calling_number: "", perform_action: "", open: false };
export const OPTION_MEDIA = { media: { constraints: OPTION_CONSTRAINT } };

export const INCOMING_RING      = "./../assets/sounds/incoming-call2.ogg";

export const OUTGOING_RING      = "./../assets/sounds/outgoing-call2.ogg";

export const KEYPAD_RING        = "./../assets/sounds/dtmf.mp3";

export const DISCONNECT_RING    = "./../assets/sounds/disconnect_mp3.mp3";
