import React from "react";
import {OverlayTrigger,Tooltip} from 'react-bootstrap';

function Tooltips(props) {
    const {placement,heading} = props;
    return (
        <OverlayTrigger
            key={placement}
            placement={placement}
            overlay={
                <Tooltip id={`tooltip-${placement}`}>
                    {heading}
                </Tooltip>
            }
        >
            {props.children}
        </OverlayTrigger>
    );
}

export default Tooltips;