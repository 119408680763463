import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { store } from "../../Services";
import {
  AccountSummary,
  UpdateAvailabilityStatus,
  FetchUserDetails,
  ForceOpenDialPad,
  naviagteToSetting,
  AvailabilityStatus,
  logOut,
  FetchBillingPlan,
} from "../../Services/Actions/userActions";
import authService from "../../Utils/authService";
import { MeetDomain, WebDomain } from "../../Utils/Common";
import { withRouter } from "../../Utils/withRouter";
import { firebaseDatabase } from "./../../Utils/FirebaseService";
import { isFirebaseUser } from "./../../Firebase/firebaseActions";
import { Howl } from "howler";
import {
  NotificationListing,
  NotificationSeen,
} from "../../Services/Actions/notificationActions";
import NotificationList from "../../Pages/Notification";
import swal from "sweetalert";
import Sip from "../../Pages/Sip";
import IncomingMeet from "../../Pages/Meet/IncomingMeet";
import * as PARAMS from "../../Pages/Sip/config/parameters";
import { ExtensionListing } from "../../Services/Actions/settingActions";
import TeamMemberSlider from "../../Pages/Calls/Team/TeamMemberSlider";

const initialState = {
  _activeCall: "",
  read: false,
  show: false,
  _meetInstance: undefined,
  showLoader: false,
  availability_status: "Available",
  showDrop: false,
  showTree: false,
  notifications: false,
  unReadNotification: [],
  viewProfile: false,
};
let incoming_tone = new Howl({
  src: [`${window.location.origin}/${PARAMS.INCOMING_RING}`],
  loop: true,
});
const AllStatus = {
  Available: "available",
  Away: "away",
  "Out of office": "outoffice",
  "In a meeting": "meeting",
  "Out sick": "sick",
  "Working remotely": "working-remotly",
  Vacationing: "vacationing",
  Travelling: "traveling",
  "Offline/Out": "offline",
};

function Header(props) {
  const [state, setState] = useState(initialState);

  const {
    user_details,
    force_call,
    force_open_dialpad,
    notification_listing,
    onboard_process_statics,
    status_list,
    individual_extension_detail,
  } = props;
  const {
    _activeCall,
    read,
    show,
    _meetInstance,
    availability_status,
    showDrop,
    showTree,
    notifications,
    unReadNotification,
    viewProfile,
  } = state;

  const {
    company,
    firstname,
    lastname,
    user_plan,
    profile_picture,
    user_extension,
    status,
    type,
  } = user_details;
  let voice_minute = user_plan?.voice_minute ?? "00";
  let lastStatus = "";

  useEffect(async () => {
    if (Object.keys(authService.getUserDetail()).length === 0) {
      LogOutHandler();
    } else {
      AvailabilityStatus(store.dispatch);
      NotificationListing(store.dispatch);
      FetchUserDetails(store.dispatch);
      navigator.serviceWorker.addEventListener("message", async (message) => {
        if (Object.keys(message).length) {
          let res = await NotificationListing(store.dispatch);
          if (res === 1) {
            setState((prev) => ({ ...prev, read: false }));
          }
        }
      });
      let response = await AccountSummary(store.dispatch);
      if (response && response.code === 1) {
        isFirebaseUser();
        InitiateScreen();
      }
    }
  }, []);

  useEffect(() => {
    if (Object.keys(user_details).length) {
      const {
        availability_status,
        payment_status,
        status,
        user_plan = {},
      } = user_details;
      if (availability_status) {
        setState((prev) => ({
          ...prev,
          availability_status,
        }));
        lastStatus = availability_status;
      }
      if (payment_status !== "A" || status === "E" || status === "S") {
        const { currency, amount, name } = user_plan;
        swal({
          icon: "warning",
          title: "Account Deactivated!",
          text: `Your plan payment of ${
            currency === "USD" ? "$" : "R"
          }${amount} for ${name?.toUpperCase()} subscription has been expired. Avoid STOPPAGE of services, please pay now to activate your account.`,
          buttons: [true, "Pay Now"],
        }).then(async (willSuccess) => {
          if (willSuccess) {
            props.navigate("/settings");
            FetchBillingPlan({ tab: "billing" }, store.dispatch);
          }
        });
      }
    }
    if (force_call && Object.keys(force_call).length) {
      OpenPop();
    }
    if (
      force_open_dialpad &&
      Object.keys(force_open_dialpad).length &&
      force_open_dialpad.open === true
    ) {
      OpenPop();
      ForceOpenDialPad({}, store.dispatch);
    }
    if (Object.keys(notification_listing).length) {
      let unReadNotification =
        notification_listing &&
        notification_listing.filter((data) => data.is_read === "N");
      setState((prev) => ({
        ...prev,
        unReadNotification,
      })); 
    } 
  }, [user_details, force_call, force_open_dialpad, notification_listing]);

  const LogOutHandler = async () => {
    swal({
      text: "Are you sure want to logout?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (Cancel) => {
      if (Cancel) {
        await logOut(store.dispatch);
        authService.logout();
        window.location.href = `https://${WebDomain}/`;
      }
    });
  };

  const InitiateScreen = () => {
    let phone = authService.getUserDetail().phone.replace("+", "");
    let CallInstances = firebaseDatabase.ref("InviteMembers");
    CallInstances.on("value", (snapshot) => {
      if (snapshot.hasChild(phone)) {
        let getVal = snapshot.val();
        if (getVal?.[phone]?.status === "ringing") {
          setState((prev) => ({
            ...prev,
            _meetInstance: getVal[phone],
          }));
          let { topic, host } = getVal[phone];
          topic = `You're Invited for Meeting (${topic}) by ${host}`;
          incoming_tone.play();
          window.$(".cl-name-meet").text("Meeting Invitation");
          window.$(".cl-no-meet").text(topic);
        }
      } else {
        if (_meetInstance) {
          incoming_tone.pause();
          window.$(".cl-name-meet").text("");
          window.$(".cl-no-meet").text("");
          setState((prev) => ({
            ...prev,
            _meetInstance: undefined,
          }));
        }
      }
    });
  };

  const CallingScreen = (action, video = false) => {
    let phone = authService.getUserDetail().phone.replace("+", "");
    let CallInstances = firebaseDatabase.ref("InviteMembers");
    CallInstances.once("value", (snapshot) => {
      if (snapshot.hasChild(phone)) {
        let getVal = snapshot.val();
        if (getVal?.[phone]?.status === "ringing") {
          if (action === "reject") {
            incoming_tone.pause();
            window.$(".cl-name-meet").text("");
            window.$(".cl-no-meet").text("");
            let destructred = {
              ...getVal[phone],
              status: "Rejected",
            };
            CallInstances.child(phone).update(destructred);
            setState((prev) => ({ ...prev, _meetInstance: undefined }));
            let timeoutHandler = setTimeout(() => {
              CallInstances.child(phone).remove();
              clearTimeout(timeoutHandler);
            }, 2000);
            return false;
          } else if (action === "accept") {
            const { roomID } = getVal[phone];
            incoming_tone.pause();
            let destructred = {
              ...getVal[phone],
              status: "Connecting",
            };
            CallInstances.child(phone).update(destructred);
            setState((prev) => ({ ...prev, _meetInstance: undefined }));
            localStorage.setItem("_isInvitedByHost", video ? "video" : "audio");
            let login_via_token = localStorage.getItem("login-via-token");
            let url = `https://${MeetDomain}/${roomID}?token=${login_via_token}`;
            window.open(url, "_blank");
            return false;
          }
        }
      }
    });
  };

  const rejectCall = () => {
    CallingScreen("reject");
  };

  const acceptCall = (video = false) => {
    setState((prev) => ({ ...prev, showLoader: true }));
    CallingScreen("accept", video);
  };

  const getActiveCallUuid = (uuid) => {
    setState((prev) => ({ ...prev, _activeCall: uuid }));
  };

  const OpenNotificationPage = () => { 
    naviagteToSetting({ tab: "notification" }, store.dispatch);
  }; 

  const OpenPop = () => {
    const {
      user_details: { status, payment_status },
    } = props;
    if (status === "A" && payment_status === "A") {
      setState((prev) => ({ ...prev, show: false }));
      setTimeout(() => {
        setState((prev) => ({ ...prev, show: true }));
      }, 100);
    }
  };

  const handleClose = () => {
    setState((prev) => ({ ...prev, show: false }));
  };

  const UpdateAvailabilityTest = async () => {
    const { availability_status: status } = state;
    const response = await UpdateAvailabilityStatus(status, store.dispatch);
    if (response === 1) {
      setState((prev) => ({
        ...prev,
        availability_status: status,
        showDrop: false,
        showTree: false,
      }));
      lastStatus = status;
    }
  };

  const notificationSeenhandler = () => {
    setState((prev) => ({
      ...prev,
      notifications: !notifications,
      read: true,
    }));
    if (read) {
      NotificationSeen(store.dispatch);
    }
  };

  const ViewExtensionProfile = async () => {
    let res = await ExtensionListing(user_extension, store.dispatch);
    if (res === 1) {
      setState((prev) => ({
        ...prev,
        viewProfile: true,
      }));
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg nexa-header">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="col-md-12 header-cal-md">
            <Link className="navbar-brand" to="#">
              <img src="./../assets/images/logo_2.svg" alt="1" />
            </Link>
            <ul className="navbar-nav d-flex align-items-baseline float-right">
              <span
                id="ongoing-timer"
                className="ongoing-call webphone-btns hide"
                onClick={OpenPop}
              ></span>
              <li className="nav-item">
                <h4 className="company-name mb-0">{company}</h4>
              </li>
              <li className="nav-item notification-dropdown">
                <Link
                  to="#"
                  className="nav-link"
                  id="navbarDropdown-notifications"
                  onClick={notificationSeenhandler}
                >
                  <i className="fas fa-bell"></i>
                  {unReadNotification?.length !== 0 && !read && (
                    <span className="badge badge-header-notification">
                      {unReadNotification?.length}
                    </span>
                  )}
                </Link>
                <NotificationList
                  {...{
                    notification_listing,
                    show: notifications,
                    status: status,
                    navigateTo: () => {
                      setState((prev) => ({
                        ...prev,
                        notifications: false,
                      }));
                      OpenNotificationPage();
                    },
                  }}
                />
              </li>
              <li className="nav-item pr-2 webphone-btns">
                <Link to="#" onClick={OpenPop} className="header-dial-ico">
                  <img src="./../assets/images/dialer-ico-head.svg" alt="1" />
                  {!window.$("#diallerModal").hasClass("show") &&
                    _activeCall && (
                      <span
                        className={`call-duration-header stopwatch_${_activeCall}`}
                      >
                        00:00
                      </span>
                    )}
                </Link>
              </li>
              <li className="nav-item header-new-btn">
                <Link to="#" className="btn btn-orange">
                  {" "}
                  New <i className="fas fa-plus ml-1"></i>
                </Link>
              </li>
              <li className="nav-item dropdown profile-menu-li ">
                <a
                   
                  className="nav-link dropdown-toggle pl-0"
                  onClick={(e) => {
                    e.stopPropagation();
                    setState((prev) => ({
                      ...prev,
                      showDrop: !showDrop,
                      showTree: false,
                    }));
                  }}
                  href="#" 
                  id="navbarDropdown" 
                  role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                >
                  {profile_picture && (
                    <img src={profile_picture} alt={firstname} />
                  )}
                  {!profile_picture && (
                    <span className="profile-alpha">
                      <p>
                        {`${firstname} ${lastname}`
                          .match(/\b(\w)/g)
                          .join("")
                          .toUpperCase()}
                      </p>
                    </span>
                  )}
                </a>{" "}
                <div
                  className={`dropdown-menu ${showDrop ? "show" : ""}`}
                  aria-labelledby="navbarDropdown"
                >
                  <div className="drop-profile-div">
                    <div className="drop-profile-left">
                      <span className="status-img-profile"></span>
                      {profile_picture && (
                        <img src={profile_picture} alt={firstname} />
                      )}
                      {!profile_picture && (
                        <span className="profile-alpha">
                          <p>
                            {`${firstname} ${lastname}`
                              .match(/\b(\w)/g)
                              .join("")
                              .toUpperCase()}
                          </p>
                        </span>
                      )}
                    </div>
                    <div className="drop-profile-right">
                      <h3>{`${firstname} ${lastname}`}</h3>
                      <h5>Exten: {user_extension}</h5>
                      {status === "A" && type === "C" && (
                        <Link to="/settings" className="webphone-btns">
                          View Profile
                        </Link>
                      )}
                      {status === "A" && type === "E" && (
                        <Link
                          to="#"
                          className="webphone-btns"
                          onClick={ViewExtensionProfile}
                        >
                          View Profile
                        </Link>
                      )}
                    </div>
                  </div>
                  <li className="sub-profile-drop">
                    <Link
                      className="dropdown-item dropdown-toggle sub-profile-drop-link webphone-btns"
                      to="#"
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          showTree: !showTree,
                        }))
                      }
                    >
                      {availability_status}
                      <span className="status-li-img">
                        <img
                          src={`./../assets/images/status-${AllStatus[availability_status]}.svg`}
                          alt="1"
                        />
                      </span>
                    </Link>
                    <ul className={`dropdown-menu ${showTree ? "show" : ""}`}>
                      <div className="status-search-bar">
                        <input
                          type="text"
                          placeholder="Share custom status"
                          className="form-control"
                        />
                        <i className="far fa-smile" aria-hidden="true"></i>
                      </div>
                      {status_list &&
                        status_list.map((value, key) => {
                          return (
                            <li key={`status${key}`}>
                              <Link
                                className={`dropdown-item webphone-btns ${
                                  availability_status === value.status
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  setState((prev) => ({
                                    ...prev,
                                    availability_status: value.status,
                                  }))
                                }
                                to="#"
                              >
                                <img
                                  src={`./../assets/images/status-${
                                    AllStatus[value.status]
                                  }.svg`}
                                  alt="1"
                                  className=""
                                />
                                {value.status}
                              </Link>
                            </li>
                          );
                        })}
                      <div className="status-btn-div">
                        <Link
                          to="#"
                          className="cancel-status webphone-btns"
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              availability_status: lastStatus,
                            }))
                          }
                        >
                          Cancel
                        </Link>
                        <Link
                          to="#"
                          className="save-status webphone-btns"
                          onClick={UpdateAvailabilityTest}
                        >
                          Save
                        </Link>
                      </div>
                    </ul>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      <img
                        src="./../assets/images/whats-new.svg"
                        alt="1"
                        className=""
                      />
                      What’s New
                      <span className="menu-span-arrow-right">
                        <img
                          src="./../assets/images/menu-right-arrow.svg"
                          alt="1"
                        />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      <img
                        src="./../assets/images/about-menu-ico.svg"
                        alt="1"
                        className=""
                      />
                      About Us
                      <span className="menu-span-arrow-right">
                        <img
                          src="./../assets/images/menu-right-arrow.svg"
                          alt="1"
                        />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      <img
                        src="./../assets/images/Arrow - Up Square.svg"
                        alt="1"
                        className=""
                      />
                      Upgrade
                      <span className="menu-span-arrow-right">
                        <img
                          src="./../assets/images/menu-right-arrow.svg"
                          alt="1"
                        />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      <img
                        src="./../assets/images/account-setting-menu.svg"
                        alt="1"
                        className=""
                      />
                      Access Account Settings
                      <span className="menu-span-arrow-right">
                        <img
                          src="./../assets/images/menu-right-arrow.svg"
                          alt="1"
                        />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      <img
                        src="./../assets/images/access-portal.svg"
                        alt="1"
                        className=""
                      />
                      Access Web Portal
                      <span className="menu-span-arrow-right">
                        <img
                          src="./../assets/images/menu-right-arrow.svg"
                          alt="1"
                        />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      <img
                        src="./../assets/images/help-info.svg"
                        alt="1"
                        className=""
                      />
                      Help & Support
                      <span className="menu-span-arrow-right">
                        <img
                          src="./../assets/images/menu-right-arrow.svg"
                          alt="1"
                        />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={LogOutHandler}
                    >
                      <img
                        src="./../assets/images/logout-menu.svg"
                        alt="1"
                        className=""
                      />
                      Log-Out
                      <span className="menu-span-arrow-right">
                        <img
                          src="./../assets/images/menu-right-arrow.svg"
                          alt="1"
                        />
                      </span>
                    </Link>
                  </li>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Sip
        show={show}
        handleClose={handleClose}
        voice_minute={voice_minute}
        getActiveCallUuid={getActiveCallUuid}
        onboard_process_statics={onboard_process_statics}
      />
      <IncomingMeet
        Accept={acceptCall}
        Reject={rejectCall}
        _meetInstance={_meetInstance}
      />

      <TeamMemberSlider
        viewOnly={true}
        addTeamMember={viewProfile}
        closeTeamMemberSlider={() =>
          setState((prev) => ({
            ...prev,
            viewProfile: false,
          }))
        }
        extensionDetails={individual_extension_detail?.[0]}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    force_call: state?.call?.force_call,
    force_open_dialpad: state?.user?.force_open_dialpad ?? {},
    user_details: state?.user?.user_details?.userDetail ?? {},
    notification_listing: state?.notification?.notification_listing,
    onboard_process_statics: state?.dashboard?.onboard_process_statics ?? [],
    status_list: state?.user?.status_list ?? [],
    individual_extension_detail:
      state?.setting?.individual_extension_detail ?? [],
  };
};

export default connect(mapStateToProps)(withRouter(Header));
