import { apiBaseUrl } from "../../Utils/Common";
import * as constants from "../Constants/powerappConstants"; 
import swal from "sweetalert"; 
import authService from "../../Utils/authService";
import { handleResponse } from "./userActions";
import { SHOW_LOADER } from "../Constants/userConstants";

export const NavigatetoPowerApps = (data) => {
  return (dispatch) => {
    dispatch({ type: constants.NAVIGATE_TO_POWER_APPS, payload: data });
  };
};

export const CloudNotesListing = (params,dispatch) => {
  const token = authService.getUserAccessToken(); 
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/user-notes`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.CLOUD_NOTES_LISTING,
          payload: data.data,
        });
        return 1;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const CreateUpdateCloudNotes = (params, isEdit,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/user-notes`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        CloudNotesListing({ type: "list" },dispatch);
        let text = isEdit
          ? "Notes Updated Successfully"
          : "Notes Created Successfully";
        swal({ title: "Success!", text: text, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};

export const DocumentsList = (dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/user-files-listing`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({ type: constants.DOCUMENTS_LIST, payload: data.data });
        return 1;
      } else {
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};
