import React from "react";
import Modal from "../../../Component/Modal";
import { MeetDomain } from "../../../Utils/Common";
import moment from "moment";

function MeetingInfoModal({
  meetingInfo: { room_name, topic, date, start_time, meeting_time },
  openInfoModal,
  onInfoClose,
}) {
  const url = `https://${MeetDomain}/${room_name}`;
  let date_time = `${date} ${start_time}`;
  let formattedDate = moment(date_time).locale("en").format("ll");
  let formattedTime = moment(date_time).locale("en").format("LT");

  const copyMeetingDetails = () => {
    if (url) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          alert(`Copied the text: ${url}`);
        })
        .catch((error) => {
          console.error(`Copy failed!`, error);
        });
    }
  };
  return (
    <Modal
      open={openInfoModal}
      size={`sm`}
      CloseModal={onInfoClose}
      title={`Meeting Info`}
    >
      {" "}
      <div class="form-group  meeting-info-group ">
        <label>
          <span> Meeting Title</span>
          {topic}
        </label>
        <label>
          <span> Meeting Time </span>
          {`${formattedDate} ${formattedTime}`}
        </label>
        <label>
          <span>Meeting Duration</span>
          {`${meeting_time} mins`}
        </label>
        <label>
          <span>Meeting ID</span>
          {room_name}
        </label>
        <label>
          <span>Meeting URL</span>
          <div class="copy-link">
            <input
              class="form-control invite-link-control"
              type="text"
              value={url}
              onChange={(e) => e.preventDefault()}
            />
            <img
              src="./../assets/images/copy.svg"
              alt="1"
              onClick={copyMeetingDetails}
            />
          </div>
        </label>
      </div>
      <div className="modal-footer display-footer text-center">
        <button type="button" className="btn btn-orange">
          Cancel
        </button>
      </div>
    </Modal>
  );
}

export default MeetingInfoModal;
