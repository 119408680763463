 
import authService from "../Utils/authService";
import firebase from "../Utils/FirebaseService";

const firebaseAuth = firebase.auth();
const firebaseDatabase = firebase.database();

export const firebaseLogin = (data) => {
    const { email, password } = data;
    firebaseAuth.signInWithEmailAndPassword(email, password).then(async(userCredential) => {
        // Signed in
        console.log("Firebase login success",userCredential) 
        console.log("refresh_token",userCredential.user.refreshToken)
        // console.log("auth_token",  (await userCredential.user.getIdTokenResult()).token)
        var user = userCredential.user;
        if(user.uid){
            setFirebaseUserOnlineStatus();
        }
        return userCredential;
    }).catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("errorCode ====== ", errorCode);
        console.log("errorMessage ====== ", errorMessage);
        if (errorCode === "auth/user-not-found") {
            return firebaseSignup(data);
        }
        return false;
    });
};

export const firebaseSignup = (data) => {
    const { email, password } = data;
    firebaseAuth.createUserWithEmailAndPassword(email, password).then((userCredential) => {
        // Signed in 
        console.log("Firebase signup success")
        var user = userCredential.user;
        if(user.uid){
            setFirebaseUserOnlineStatus();
        }
        return userCredential;
    }).catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error("errorCode ====== ", errorCode);
        console.error("errorMessage ====== ", errorMessage);
        return false;
    });
};

export const SignInWIthToken = (token) => {
    firebaseAuth.signInWithCustomToken(token).then((userCredential) => {
        // Signed in
        // var user = userCredential.user;
        return userCredential;
    }).catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error("errorCode ====== ", errorCode);
        console.error("errorMessage ====== ", errorMessage);
        return false;
    });
}

export function setFirebaseUserOnlineStatus() {
    const user = authService.getUserDetail();
    const userStat = user.uniquecode ?? currentUser();
    let userStatusDatabaseRef = firebaseDatabase.ref("/status/" + userStat);
    firebaseDatabase.ref(".info/connected").on("value", (snapshot) => {
        if (snapshot.val() === false) {
            return false;
        }
        const isOnlineForDatabase = {
            online: true,
            last_changed: firebase.database.ServerValue.TIMESTAMP
        };
        userStatusDatabaseRef.set(isOnlineForDatabase);
        userStatusDatabaseRef.onDisconnect().update({...isOnlineForDatabase,online:false});
    });
}

export const isFirebaseUser = () => {
    firebaseAuth.onAuthStateChanged(async (user) => {
        if (user) {
            setFirebaseUserOnlineStatus();
        } else {
            console.error("User logged Out");
        }
    });
};

export const currentUser = () => {
    var user = firebaseAuth.currentUser?.uid;
    return user??false;
}


 

export const SignoutUser = () => {
    if(currentUser()){
        firebaseAuth.signOut().then(() => {
            return true;
        }).catch((error) => {
            // An error happened.
            return false;
        });
    }
}

export const __UserCredentialsUpdate = (__uid,__profile) => {
    firebaseDatabase.ref("/status/" + __uid).once('value').then((snapshot) => {
        if (snapshot.val() === false) {
            return false;
        }
        const data = snapshot.val();        
        console.log("Changes",data)
    });
}
