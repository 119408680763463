import React, { Component } from "react";
import AllCalls from "./CallHistory/AllCalls";
import CallInfo from "./CallHistory/CallInfo";
import CallRecordings from "./CallHistory/CallRecordings";
import MissedCalls from "./CallHistory/MissedCalls";
import CallSideMenu from "./CallSideMenu";
import NxaContacts from "./NxaContacts";
import Business from "./NxaContacts/Business";
import Personal from "./NxaContacts/Personal";
import SMS from "./SMS";
import Team from "./Team";
import Voicemails from "./Voicemails";

class Calls extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "all_calls", participants: [], _UserChat: {} };
  }

  SetTab = (tab) => {
    this.setState({ tab });
  };

  getTeamParticipants = (participants) => {
    this.setState({ participants });
  };

  _UserChatHistory = (value) => {
    this.setState({
      _UserChat: value,
    });
  };

  render() {
    const { tab, participants, _UserChat } = this.state;
    return (
      <div>
        <div className="right-side-wrapper pt-0 ">
          <div className="card">
            <div className="card-body p-0">
              <div className="row m-0">
                <CallSideMenu
                  state={tab}
                  SetTab={this.SetTab}
                  getTeamParticipants={this.getTeamParticipants}
                  _UserChatHistory={this._UserChatHistory}
                />
                {tab === "all_calls" && (
                  <AllCalls state={tab} SetTab={this.SetTab} />
                )}
                {tab === "missed_calls" && <MissedCalls SetTab={this.SetTab} />}
                {tab === "call_recordings" && <CallRecordings />}
                {tab === "all_voicemails" && <Voicemails />}
                {tab === "all_contacts" && <NxaContacts />}
                {tab === "business" && <Business />}
                {tab === "personal" && <Personal />}
                {tab === "my_team" && <Team participants={participants} />}
                {tab === "call info" && <CallInfo SetTab={this.SetTab} />}
                {tab === "sms" && <SMS _UserChat={_UserChat} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Calls;
