import React, { Component } from 'react'; 
import * as PARAMS from './config/parameters';
import * as ACTION from './config/actions';
import WebPhone from './WebPhone';
import IncomingPop from './IncomingPop';
import { Howl } from 'howler'; 
import { SecondsTohhmmss } from '../../Utils/Common';
import { OnBoardProcessStatics } from '../../Services/Actions/dashboardActions';
import { store } from '../../Services';
import Dialler from './Dialler';
import { FetchUserDetails, getUserCredentials } from '../../Services/Actions/userActions';
import { connect } from 'react-redux';
import { ConferenceCallSessions, TransferCallSessions } from '../../Services/Actions/callActions';
const SIPJS = require('sip.js');

const SiteUrl = 'https://app-dev.nxa.tel/';

var UA = {};

let keypad_tone = new Howl({ src: [`${SiteUrl}${PARAMS.KEYPAD_RING}`] });

let outgoing_tone = new Howl({ src: [`${SiteUrl}${PARAMS.OUTGOING_RING}`], loop: true });

let incoming_tone = new Howl({ src: [`${SiteUrl}${PARAMS.INCOMING_RING}`], loop: true });

class Sip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectHandler: '',
      number: '',
      _rStatus: '',
      _activeCall: '',
      sessions: {},
      reference: {},
      browser: false,
      recording: false, 
    };
  }

  componentDidMount = async() => {
    let response = await getUserCredentials(store.dispatch)
    if(response === 1){
      const {user_credentials:{extension,domain,password,stun_url,turn_url,turn_password,turn_username}={}} = this.props 
      this.setState({ browser: ACTION.WEBRTC_SUPPORT() }, () => {
        let configuration = { 
          userAgentString: PARAMS.USER_AGENT_STRING,
          transportOptions: PARAMS.TRANSPORT_OPTIONS, // wss://fs-nxatel.cap-tek.com:7443
          register: PARAMS.REGISTER, //true
          log: PARAMS.LOG, // 1
          sessionDescriptionHandlerFactoryOptions: {
            peerConnectionOptions: {
              rtcConfiguration: {
                iceServers: [
                  { urls: stun_url }, 
                  {
                    urls: turn_url,
                    username: turn_username,
                    credential: turn_password
                  }
                ]
              }
            }
          },
          uri: `${extension}@${domain}`,
          authorizationUser: extension,
          password:password,
        };
        console.log('configuration', configuration);
        UA = new SIPJS.UA(configuration);
        UA.register({ extraHeaders: ['xyz: xyz====>'] });
        UA.start();
        this.RegisterationEventHandler(UA);
      });
    } 
  }; 
  

  // componentDidUpdate() {
  //   const { reference, _activeCall } = this.state;
  //   console.log("_activeCall componentDidUpdate",_activeCall)
  //   if (Object.keys(reference).length === 1 && !_activeCall) {
  //     this.setState({ _activeCall: Object.keys(reference)[0] });
  //     this.props.getActiveCallUuid(Object.keys(reference)[0]);
  //   }
  // }

  RegisterationEventHandler = (UA) => {
    UA.on('connected', () => {
      /**contain event */
      console.log('connected');
      this.setState({ _rStatus: 'connected' });
    });
    UA.on('disconnected', () => {
      /**contain event */
      console.log('disconnected');
      this.setState({ _rStatus: 'disconnected', sessions: {}, reference: {} });
    });
    UA.on('registered', () => {
      /**contain event */
      console.log('registered');
      this.setState({ _rStatus: 'registered', sessions: {}, reference: {} });
    });
    UA.on('registrationFailed', () => {
      /**contain event */
      console.log('registrationFailed');
      this.setState({
        _rStatus: 'registration failed',
        sessions: {},
        reference: {},
      });
    });

    UA.on('unregistered', (e) => {
      console.log('unregistered');
      this.setState({ _rStatus: 'unregistered', sessions: {}, reference: {} });
    });
    UA.on('transportCreated', function (transport) {
      console.log('transportCreated');
      console.log('TRANSPORT CREATED', transport);
    });
    {
      /** 
        @param incomingSession incoming call session initiator
      */
    }
    UA.on('invite', (incomingSession) => {
      const { sessions } = this.state;
      if (sessions.length === 2) {
        incomingSession.terminate();
      } 
      incomingSession.direction = 'inbound';
      this.CallInitiateHandler(incomingSession); 

    });
  };

  CallInitiateHandler = (incomingSession) => {
    const { sessions, reference } = this.state;
    let name, number, status, uuid, direction;
    const regExp = new RegExp(`^\\s*${'X-Callrecord'}\\s*:`, 'i')
    let recording = incomingSession?.request?.data?.split('\n')?.find(((item) => regExp.test(item))) 
    name = incomingSession.remoteIdentity.displayName || incomingSession.remoteIdentity.uri.user;
    number = this.FormatPhone(incomingSession.remoteIdentity.uri.user);
    direction = incomingSession.direction;
    status = direction === 'inbound' ? 'ringing' : 'connecting';
    uuid = this.UUID();
    recording = recording?.substring(recording?.indexOf(':') + 1)?.trim() || '' === "true" 
    
    if (direction === 'inbound') {
      if (Object.keys(reference).length === 0) {
        if (!window.$('#diallerModal').hasClass('show')) {
          window.$('#call-incoming').addClass('show');
        }
        incoming_tone.play();
      } else {
        window.$('#call-incoming').addClass('show');
      }
    } else {
      this.setState({ _activeCall: uuid });
      this.props.getActiveCallUuid(uuid);
    }
    reference[uuid] = { name, number, direction, status, uuid, recording };
    sessions[uuid] = incomingSession;
    this.setState({ reference, sessions, redirectHandler: this.UUID() }, () => {
      this.CallEventHandler(incomingSession, uuid, status);
    });
  };

  CallEventHandler = (incomingSession, uuid, status) => {
    let interval = 0;
    let trackRing = 0;
    const { direction } = incomingSession;
    const {conference_sessions,transfer_sessions} = this.props

    incomingSession.on('connecting', (e) => {
      if (direction === 'outbound') {
        const { sessions, reference } = this.state;
        status = 'connecting';
        reference[uuid] = { ...reference[uuid], status };
        sessions[uuid] = incomingSession;
        this.setState({ sessions, reference, redirectHandler: this.UUID() });
      }
      console.log('Connecting===>', direction, status); 
    });

    incomingSession.on('progress', (e) => {
      if (direction === 'outbound') {
        console.log('incomingSession', incomingSession);
        const { sessions, reference } = this.state;
        status = 'ringing';
        reference[uuid] = { ...reference[uuid], status };
        sessions[uuid] = incomingSession;
        this.setState({ sessions, reference, redirectHandler: this.UUID() }, () => {
          if (trackRing === 0) {
            outgoing_tone.play();
          }
          trackRing++;
        });
      } 
      console.log('PROGRESS===>', direction, status);
    });

    incomingSession.on('accepted', (e) => {
      const { sessions, _activeCall, reference } = this.state;
      console.log('ACCEPTED', uuid, _activeCall);
      // If there is another active call, hold it
      if (_activeCall && uuid !== _activeCall) {
        this.HoldCall(_activeCall);
      }
      let domElement = document.getElementById(`audioRemote`);
      const myPromise = new Promise((resolve) => {
        domElement.srcObject = null;
        domElement.pause();
        resolve();
      });
      /**
       * @property MediaStream Included
       */
      myPromise.then(() => {
        this.AttachMediaStream(incomingSession);
      });
      /**
       * @property MediaStream Passed
       */
      status = 'connected';
      reference[uuid] = { ...reference[uuid], status };
      sessions[uuid] = incomingSession;
      this.setState(
        {
          _activeCall: uuid,
          sessions,
          reference,
          redirectHandler: this.UUID(),
        },
        () => {
          incoming_tone.pause();
          outgoing_tone.pause();
        }
      );
      this.props.getActiveCallUuid(uuid);
      let seconds = 0;
      interval = setInterval(() => {
        seconds++;
        if (window.$(`.stopwatch_${uuid}`).length) {
          window.$(`.stopwatch_${uuid}`).text(SecondsTohhmmss(seconds));
        }
      }, 1000);
    });

    incomingSession.on('cancel', (e) => {
      status = 'cancel';
      if (direction === 'outbound') {
        const { sessions, reference , _activeCall } = this.state;
        delete reference[uuid];
        delete sessions[uuid];
        this.setState({
          _activeCall: uuid === _activeCall ? null : _activeCall ,
          sessions,
          reference,
          redirectHandler: this.UUID(),
        });
        this.props.getActiveCallUuid(null);
      } else {
        const { reference } = this.state;
        reference[uuid] = { ...reference[uuid], status };
        this.setState({ reference: {} }, () => {
          this.setState({ reference, redirectHandler: this.UUID() }, () => {
            incoming_tone.pause();
            outgoing_tone.pause();
          });
        }); 
      }
      delete conference_sessions[uuid];
      delete transfer_sessions[uuid];
      TransferCallSessions(transfer_sessions,store.dispatch)
      ConferenceCallSessions(conference_sessions,store.dispatch)
      FetchUserDetails(store.dispatch)
      console.log('CANCEL===>'); 
    });

    incomingSession.on('bye', (e) => {
      const { sessions, reference ,_activeCall} = this.state;
      delete reference[uuid];
      delete sessions[uuid];
      this.setState(
        {
          _activeCall: uuid === _activeCall ? null : _activeCall ,
          sessions,
          reference,
          redirectHandler: this.UUID(),
        },
        () => {
          incoming_tone.pause();
          outgoing_tone.pause();
        }
      );
      this.props.getActiveCallUuid(null);
      if (interval > 0) {
        clearInterval(interval);
      }
      delete conference_sessions[uuid];
      delete transfer_sessions[uuid];
      TransferCallSessions(transfer_sessions,store.dispatch)
      ConferenceCallSessions(conference_sessions,store.dispatch)
      FetchUserDetails(store.dispatch)
      console.log('BYE===>');
    });

    incomingSession.on('failed', (response, cause) => {
      const { sessions, reference } = this.state;
      status = 'terminated';
      delete reference[uuid];
      delete sessions[uuid];
      this.setState({ reference, redirectHandler: this.UUID() }, () => {
        incoming_tone.pause();
        outgoing_tone.pause();
      });
      if (interval > 0) {
        clearInterval(interval);
      }
      delete conference_sessions[uuid];
      delete transfer_sessions[uuid];
      TransferCallSessions(transfer_sessions,store.dispatch)
      ConferenceCallSessions(conference_sessions,store.dispatch)
      FetchUserDetails(store.dispatch)
      console.log('FAILED===>', response, cause);
    });

    incomingSession.on('rejected', (e) => {
      const { sessions, reference,_activeCall} = this.state;
      delete reference[uuid];
      delete sessions[uuid];
      this.setState(
        {
          _activeCall: uuid === _activeCall ? null : _activeCall,
          sessions,
          reference,
          redirectHandler: this.UUID(),
        },
        () => {
          incoming_tone.pause();
          outgoing_tone.pause();
        }
      );
      this.props.getActiveCallUuid(null);
      if (interval > 0) {
        clearInterval(interval);
      }
      delete conference_sessions[uuid];
      delete transfer_sessions[uuid];
      TransferCallSessions(transfer_sessions,store.dispatch)
      ConferenceCallSessions(conference_sessions,store.dispatch)
      FetchUserDetails(store.dispatch)
      console.log('REJECTED===>');
    });
  };

  AttachMediaStream = (incomingSession) => {
    let domElement = document.getElementById(`audioRemote`);
    let pc = incomingSession.sessionDescriptionHandler.peerConnection;
    let remoteStream = new MediaStream();
    pc.getReceivers().forEach(function (receiver) {
      var track = receiver.track;
      if (track) {
        remoteStream.addTrack(track);
      }
    });
    domElement.srcObject = remoteStream;
    domElement.play();
  };

  StartCall = (num) => {
    let number = num.charAt(0) === '0' ? num.replace(num.charAt(0), '+27') : num.includes('+') ? num : `+${num}`;
    try { 
      const {user_credentials :{turn_url,stun_url,turn_password,turn_username} = {}} = this.props
      const callParams = {
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: false,
          },
          rtcConfiguration: {
            iceServers: [
              {
                urls: stun_url,    
              },
              {
                urls: turn_url,  
                username: turn_username,
                credential: turn_password,  
              },
            ],
          },
        },
        media: {
          constraints: PARAMS.OPTION_CONSTRAINT,
          render: {
            remote: document.getElementById(`audioRemote`),
            local: { audio: true, video: false },
          },
          RTCConstraints: { optional: [{ DtlsSrtpKeyAgreement: 'true' }] },
        },
      };
      var s = UA.invite(number, callParams);
      s.direction = 'outbound';
      s.isMuted = false;
      s.isHold = false;
      console.log('---makecall-params---', number, '---', callParams);
      /**
       * @param s outgoing call session
       */
      this.CallInitiateHandler(s);
      if (this.props.onboard_process_statics?.call_made === 'N') {
        OnBoardProcessStatics(store.dispatch);
      }
    } catch (e) {
      throw e;
    }
  };

  AnswerCall = (uuid) => {
    const { sessions } = this.state;
    var detect_session = sessions[uuid];
    if (detect_session) {
      let fetchResponse = Promise.resolve(
        detect_session.accept({
          sessionDescriptionHandlerOptions: {
            constraints: {
              audio: true,
              video: false,
            },
          },
          media: {
            stream: detect_session.Stream,
            constraints: PARAMS.OPTION_CONSTRAINT,
            render: {
              remote: {
                audio: document.getElementById(`audioRemote`),
                video: false,
              },
              local: { audio: true, video: false },
            },
            RTCConstraints: { optional: [{ DtlsSrtpKeyAgreement: 'true' }] },
          },
        })
      );
      fetchResponse.then((session) => {
        if (session.hasAnswer) {
          const { reference } = this.state;
          let status = 'connecting';
          reference[uuid] = Object.keys(reference).length === 1 ? { ...reference[uuid], status, _activeCall: uuid } : { ...reference[uuid], status };
          this.setState({ reference, redirectHandler: this.UUID() }, () => incoming_tone.pause());
        }
      });
    }
  };

  HangUpCall = (sessionid) => {
    const { sessions } = this.state; 
    const {conference_sessions,transfer_sessions} = this.props
    var detect_session = sessions[sessionid];
    if (detect_session) {
      detect_session.terminate();
      delete conference_sessions[sessionid];
      delete transfer_sessions[sessionid];
      TransferCallSessions(transfer_sessions,store.dispatch)
      ConferenceCallSessions(conference_sessions,store.dispatch) 
    }
  };

  HoldCall = (uuid) => {
    const { sessions, reference } = this.state;
    const currentSess = sessions[uuid];
    const currentRef = reference[uuid];
    let status;
    if (currentRef.status === 'hold') {
      currentSess.unhold();
      status = 'connected';
      this.AttachMediaStream(currentSess);
    } else if (currentRef.status === 'connected') {
      currentSess.hold();
      status = 'hold';
    }
    reference[uuid] = { ...reference[uuid], status };
    this.setState({ reference, redirectHandler: this.UUID() });
  };

  CallRecording = (uuid) => {
    const { reference } = this.state; 
    const currentRef = reference[uuid];
    let recording;
    if (currentRef.recording) {
      this.DTMF("*3")
      recording = false; 
    } else {
      this.DTMF("*2")
      recording = true;
    }
    reference[uuid] = { ...reference[uuid], recording };
    this.setState({ reference });
  };

  MuteCall = (uuid) => {
    const { sessions, reference } = this.state;
    const currentSess = sessions[uuid];
    const currentRef = reference[uuid];
    console.log('MUTE PRESSED', uuid, currentRef.status);
    try {
      var pc = currentSess.sessionDescriptionHandler.peerConnection;
      pc.getSenders().forEach((stream) => {
        if (stream.track) {
          stream.track.enabled = currentRef.status === 'connected' ? false : true;
          reference[uuid] = {
            ...reference[uuid],
            status: currentRef.status === 'connected' ? 'mute' : 'connected',
          };
          this.setState({ reference, redirectHandler: this.UUID() });
        }
      });
    } catch (err) {
      return false;
    }
  };

  Transfer = (target, type = '') => {
    console.log("target====>",target)
    const { sessions, _activeCall } = this.state;
    const detect_session = sessions[_activeCall];
    detect_session.refer(target);
  };

  DTMF = (digit) => {
    const { sessions, _activeCall } = this.state;
    const detect_session = sessions[_activeCall];
    detect_session?.dtmf(digit);
  };

  ToggleRecord = (dtmf) => {
    const { recording, sessions, _activeCall } = this.state;
    this.setState({ recording: !recording }, () => {
      sessions[_activeCall].dtmf(dtmf, { duration: 100, interToneGap: 100 });
      if (!recording) {
        var audio = new Audio('/sounds/recordingOn.mp3');
        audio.play();
      } else {
        var audio = new Audio('/sounds/recordingOff.mp3');
        audio.play();
      }
    });
  };

  componentWillUnmount() {
    this.setState({
      redirectHandler: '',
      number: '',
      _rStatus: '',
      _activeCall: '',
      sessions: {},
      reference: {},
      browser: false,
      recording: false, 
    })
    ACTION.UNREGISTER(UA);
  }

  SetActiveCall = (uuid) => {  
    const { reference, _activeCall } = this.state;  
    if (_activeCall) {  
      this.HoldCall(_activeCall);
    }
    if (reference[uuid]) { 
      this.setState({ _activeCall: uuid }, () => this.HoldCall(uuid));
    } 
  };

  render() {
    const { voice_minute, show, handleClose } = this.props;
    const { reference, _activeCall } = this.state;
    let RingingCalls = Object.values(reference).filter((value) => value.status === 'ringing')?.[0] ?? {};
    return (
      <>
        <audio id='audioRemote' />
        <Dialler 
          show={show} 
          handleClose={handleClose}
        >
          <WebPhone 
            {...this.state} 
            keypad_tone={keypad_tone} 
            StartCall={this.StartCall} 
            HangUpCall={this.HangUpCall} 
            AnswerCall={this.AnswerCall} 
            MuteCall={this.MuteCall} 
            HoldCall={this.HoldCall} 
            CallTransfer={this.Transfer} 
            DTMF={this.DTMF} 
            ToggleRecord={this.ToggleRecord} 
            voice_minute={voice_minute} 
            SetActiveCall={this.SetActiveCall} 
            sessions={reference?.[_activeCall]}  
            CallRecording={this.CallRecording}
          />
        </Dialler>
        {Object.keys(reference).length <= 1 &&    // incoming popup shows when active call session less than or equal to 1
          <IncomingPop 
            ActiveCall={RingingCalls} 
            HangUpCall={this.HangUpCall} 
            AnswerCall={this.AnswerCall} 
            DTMF={this.DTMF} 
            Transfer={this.Transfer} 
          />
        }
      </>
    );
  }

  FormatPhone = (phone) => {
    var num;

    if (phone.indexOf('@')) {
      num = phone.split('@')[0];
    } else {
      num = phone;
    }

    num = num.toString().replace(/[^0-9]/g, '');

    if (num.length === 10) {
      return '(' + num.substr(0, 3) + ') ' + num.substr(3, 3) + '-' + num.substr(6, 4);
    } else if (num.length === 11) {
      return '(' + num.substr(0, 4) + ') ' + num.substr(4, 3) + '-' + num.substr(7, 4);
    } else {
      return num;
    }
  };

  UUID = () => {
    return Math.random().toString(36).substr(2, 9);
  };
}


const mapStateToProps = (state) => {
  return { 
    user_credentials: state?.user?.user_credentials??{}, 
    conference_sessions:state?.call?.conference_sessions,
    transfer_sessions:state?.call?.transfer_sessions
  };
};

export default connect(mapStateToProps)(Sip); 
