 import { combineReducers } from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 



import userReducer from "./userReducer";
import dashboardReducer from "./dashboardReducer";
import notificationReducer from './notificationReducer';
import callReducer from './callReducer';
import signupReducer from './signupReducer';
import meetReducer from './meetReducers';
import reportReducer from './reportReducer';
import powerappReducer from './powerappReducer';
import settingReducer from './settingReducer';

const persistConfig = {key:'root',storage,whitelist:['signup']};

const rootReducer = combineReducers({
    user:userReducer,
    dashboard:dashboardReducer,
    notification:notificationReducer,
    call:callReducer,
    signup:signupReducer,
    meet:meetReducer,
    report:reportReducer,
    power_apps : powerappReducer,
    setting : settingReducer

});

export default persistReducer(persistConfig,rootReducer);
