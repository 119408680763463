import React, { Component } from 'react'; 
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import RenderProp from '../../../Component/Modal/RenderProp';
import Schedule from '../../../Component/Modal/Schedule';
import Pagination from '../../../Component/Pagination';
import { store } from '../../../Services';
import { DeleteNxaContact, ForceCall, NxaContactListing, SelectedChatType, SelectedChatUser } from '../../../Services/Actions/callActions';
import { StartMeet } from '../../../Services/Actions/meetActions';
import { withRouter } from '../../../Utils/withRouter';
import MessageContactModal from '../SMS/MessageContactModal'; 
import swal from 'sweetalert'
import { removeBackdrop } from '../../../Utils/Common';

class BusinessContact extends Component {
    constructor(props) {
        super(props);
        this.state={show:false,number:'',modal:'',pageOfItems: []}
    }

    componentDidMount= async()=> {
         NxaContactListing({type:'business'},store.dispatch) 
    } 

    UpdateData = (pageOfItems) => {
        this.setState({ pageOfItems });
    };

    componentDidUpdate = (prevProps) => { 
        const {searchTerm,nxa_contacts} = this.props 
        if (prevProps.searchTerm !== searchTerm) { 
            const { pageOfItems } = this.state; 
            const filtered = pageOfItems.filter( (value) =>{ 
                let name = `${value.firstname} ${value.lastname}`
                return  (value.phone &&value.phone.trim().toLowerCase().includes(searchTerm)) ||
                  (name && name.trim().toLowerCase().includes(searchTerm))
            }); 
            this.setState({
                pageOfItems: searchTerm ? filtered : nxa_contacts.slice(0, 5),
            }); 
        }
    }; 
     
    closeModal = () => {
        this.setState({ show: false },()=>removeBackdrop());
    };

    startChat = async(value)=>{
        let userData = { 
            profile_picture:value?.profile_picture??'', 
            phone:value?.phone??'', 
            name:value?.firstname??'',
            uniquecode:value?.uniquecode??''
        }
        SelectedChatUser(userData,store.dispatch) 
        SelectedChatType("one_to_one_chat",store.dispatch) 
        this.props.navigate("/chat")
    } 

    deleteContact=(contactID)=>{
        swal({
            icon:'warning',
            title:'Warning!',
            text: "Are you sure want to delete this business contact?",
            buttons: true,
            dangerMode: true
        }).then(willDelete => {
            if (willDelete) {
                DeleteNxaContact(contactID,'business',store.dispatch) 
                window.$(`#${contactID}`).removeClass("show")
            } else {
            console.log("you clicked on false");
            }
        }); 
    }
    
    render() {
        const { nxa_contacts, getEditContactDetails,layoutType,page} = this.props;
        const {show,number,modal,pageOfItems} = this.state 
        return (
            <>
            {layoutType === "grid" &&
                <div className="grid-view-contact"> 
                    <div className="row">
                        {pageOfItems && pageOfItems.map((value,index)=>{
                            let name = `${value.firstname} ${value.lastname}`
                            return(
                            <div className="col-md-3 nxa-call-gird-box" key={index}>
                                <div className="nxa-call-gird-box-inner">
                                    <span className="nxa-call-contact-img">
                                        {!value.profile_picture && 
                                        <h5> 
                                            {(name).match(/\b(\w)/g).join('').toUpperCase()}
                                        </h5>
                                        }
                                        {value.profile_picture &&<img src={value.profile_picture} alt="a"/>}
                                    </span>
                                    <h4 >{name}</h4>
                                    <p>{value.phone}</p>
                                    <div className="nxa-call-grid-action">
                                        {this.DisplayIcon(value)} 
                                    </div>
                                    <div className="nxa-call-grid-action action-edit-del">
                                        <div className="recentcall-img-ico allcall-img-ico align-items-center justify-content-end"> 
                                            <Link
                                                className="m-0"
                                                to="#" 
                                                onClick={()=>getEditContactDetails(value)}
                                            >
                                                <img src="./../assets/images/edit-new.svg" alt="1" /> 
                                            </Link> 
                                            <Link 
                                                className="ml-0"
                                                to="#" 
                                                onClick={()=>this.deleteContact(value.uuid)}
                                            >
                                                <img src="./../assets/images/trash-2.svg" alt="1" /> 
                                            </Link> 
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            )})
                        }
                    </div> 
                </div>
            }

            {layoutType === "list" && 
            <div className="tab-content business">
                <div  className={`tab-pane show active ${page === "setting" ? "contact-info-scroll" : "call-info-scroll"}`}>
                    <div className="table-responsive calls-table calls-table-hover">
                        <table className="table table-hover table-center mb-0">
                            <tbody>
                                {pageOfItems &&
                                    pageOfItems.map(
                                        (value, key) => {
                                            let name = `${value.firstname} ${value.lastname}`
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        <div className="d-flex all-message-email-row">
                                                            <div className="text-center align-self-center caller-img nxa-contact">
                                                            {!value.profile_picture && 
                                                            <h6> 
                                                                {(name).match(/\b(\w)/g).join('').toUpperCase()}
                                                            </h6>
                                                            }
                                                            {value.profile_picture &&<img src={value.profile_picture} alt="a"/>}
                                                            </div>
                                                            <div className="align-self-center caller-name-number">
                                                                <h3>
                                                                    {name} 
                                                                </h3>
                                                                <p>
                                                                    {value.phone}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td
                                                        align="right"
                                                    > 
                                                        {this.DisplayIcon(value)}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                     {nxa_contacts &&
                                        nxa_contacts.length === 0 && (
                                            <tr>
                                                <td colSpan="2" className="text-center">
                                                    No business contacts found.
                                                </td>
                                            </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>}
            <Pagination
                data={nxa_contacts}
                currentData={this.UpdateData}
            />

            {modal === "message-contact" && show &&  
                <MessageContactModal
                    OpenMessageModal = {show}
                    closeMessageContactModal = {this.closeModal}
                    destination_number = {number} 
                /> 
            }

            {modal === "schedule_meet" && show &&  (
                <RenderProp>
                    {({ UpdateTopic, extension_listing }) => (
                        <Schedule
                            UpdateTopic={UpdateTopic}
                            extension_listing={extension_listing}
                        />
                    )}
                </RenderProp>
            )}
            </>
        );
    }

    DisplayIcon=(value)=>{ 
        const {getEditContactDetails,layoutType}= this.props
        return (
            <div className="recentcall-img-ico allcall-img-ico align-items-center justify-content-end">
                <Link 
                    to="#" 
                    title="Audio Call"
                    style={{cursor: "pointer"}}  
                    onClick={()=>ForceCall(value.phone,store.dispatch)}
                >
                    <img
                        src="./../assets/images/calls-call-ico.svg"
                        alt="1"
                    />
                </Link>
                {value.uniquecode.includes("CU-") && 
                    <Link 
                        to="#" 
                        title="Chat"
                        onClick={()=>this.startChat(value)}
                        style={{cursor:'pointer'}}
                    >
                        <img
                            src="./../assets/images/call-chat-ico.svg"
                            alt="1"
                        />
                    </Link>
                }
                <Link 
                    to="#" 
                    title="Schedule Meet"
                    style={{cursor: "pointer"}} 
                    onClick={() =>
                        this.setState(
                            {
                                modal: "",
                            },
                            () => {
                                this.setState(
                                    {
                                        modal: "schedule_meet",
                                        show:true
                                    }
                                );
                            }
                        )
                    }  
                >
                    <img
                        src="./../assets/images/call-schdule.svg"
                        alt="1"
                    />
                </Link>
                {value.nxatel_user === "Y" && 
                    <>
                    <Link 
                        to="#" 
                        title="Video Call"
                        style={{cursor:'pointer'}}
                        onClick={()=>StartMeet(value.phone)}
                    >
                        <img
                            src="./../assets/images/call-video.svg"
                            alt="1"
                        />
                    </Link> 
                    <Link 
                        to="#" 
                        title="Send SMS"
                        onClick={()=>this.setState({show:true,number:value.phone,modal:'message-contact'})}
                        style={{cursor:'pointer'}}
                    >
                        <img
                            src="./../assets/images/email-contact.svg"
                            alt="1"
                        />
                    </Link>
                    </>
                }
                {layoutType === "list" &&  
                    <div className="number-collase-action">
                        <a
                            href={`#${value.uuid}`}
                            data-toggle="collapse"
                            aria-expanded="false"
                            className="dropdown-toggle collapsed team-member-elipis-drop"
                        >
                             <img
                                src="./../assets/images/call-menu-ico.svg"
                                alt="1"
                            />
                        </a>
                        <ul
                            className="list-unstyled number-dr-collapse collapse"
                            id={ value.uuid }
                        >
                            <li>
                                <Link
                                    className="dropdown-item"
                                    to="#"  
                                    onClick={()=>getEditContactDetails(value)}
                                >
                                    <img src="./../assets/images/number-settings-drop.svg" alt="1" />
                                    Edit Contact
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="dropdown-item"
                                    to="#"  
                                    onClick={()=>this.deleteContact(value.uuid)}
                                >
                                    <img src="./../assets/images/number-drop-trash-2.svg" alt="1" />
                                    Delete Contact
                                </Link>
                            </li> 
                        </ul>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    nxa_contacts: state?.call?.nxa_contacts??[],
});

export default connect(mapStateToProps)(withRouter(BusinessContact)); 