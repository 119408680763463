import React, { useEffect, useRef, useState } from "react";
import { store } from "../../../../Services";
import { getMediaDownloadUrl } from "../../../../Services/Actions/settingActions";

const RADIUS = 15.5;
const STROKE_WIDTH = 1;

function clamp(number, min, max) {
  return Math.max(min, Math.min(number, max));
}

const Play = ({ fill }) => (
  <path
    d="M20.4751 15.9464L12.5789 21.2106C12.5228 21.2479 12.4576 21.2693 12.3903 21.2725C12.323 21.2757 12.2561 21.2607 12.1967 21.2289C12.1373 21.197 12.0876 21.1497 12.053 21.0919C12.0183 21.0341 12 20.968 12 20.9006V10.3723C12 10.3049 12.0183 10.2388 12.053 10.181C12.0876 10.1232 12.1373 10.0759 12.1967 10.0441C12.2561 10.0123 12.323 9.99721 12.3903 10.0004C12.4576 10.0036 12.5228 10.0251 12.5789 10.0624L20.4751 15.3266C20.5261 15.3606 20.568 15.4067 20.5969 15.4607C20.6258 15.5148 20.641 15.5752 20.641 15.6365C20.641 15.6978 20.6258 15.7582 20.5969 15.8122C20.568 15.8663 20.5261 15.9124 20.4751 15.9464V15.9464Z"
    fill={fill}
  />
);

const Pause = ({ fill }) => (
  <path
    d="M17.9583 12.7448C17.9583 12.5473 18.0368 12.3578 18.1765 12.2181C18.3162 12.0785 18.5056 12 18.7031 12C18.9007 12 19.0901 12.0785 19.2298 12.2181C19.3694 12.3578 19.4479 12.5473 19.4479 12.7448V20.1927C19.4479 20.3902 19.3694 20.5797 19.2298 20.7194C19.0901 20.859 18.9007 20.9375 18.7031 20.9375C18.5056 20.9375 18.3162 20.859 18.1765 20.7194C18.0368 20.5797 17.9583 20.3902 17.9583 20.1927V12.7448ZM12 12.7448C12 12.5473 12.0785 12.3578 12.2181 12.2181C12.3578 12.0785 12.5473 12 12.7448 12C12.9423 12 13.1318 12.0785 13.2714 12.2181C13.4111 12.3578 13.4896 12.5473 13.4896 12.7448V20.1927C13.4896 20.3902 13.4111 20.5797 13.2714 20.7194C13.1318 20.859 12.9423 20.9375 12.7448 20.9375C12.5473 20.9375 12.3578 20.859 12.2181 20.7194C12.0785 20.5797 12 20.3902 12 20.1927V12.7448Z"
    fill={fill}
  />
);

const initialState = {
  duration: 0,
  currentTime: 0,
};

const calculatePercent = ({ duration, currentTime }) => {
  if (duration === 0 || currentTime === 0) {
    return 0;
  }
  return (currentTime / duration) * 100;
};


const AudioPlayer = ({ src, style }) => {
  const audioRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [playerInfo, setPlayerInfo] = useState(initialState);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const intervalRef = useRef(null);

  const percent = calculatePercent(playerInfo);
  const strokeDashArray = Math.PI * 2 * RADIUS;
  const clampedPercent = clamp(percent, 0, 100);
  const strokeDashoffset = ((100 - clampedPercent) / 100) * strokeDashArray;

  useEffect(() => {
    if (downloadUrl) {
      const audioEl = audioRef.current;
      let audioEndedHandler = (event) => {
        setIsPlaying(false);
        audioEl.currentTime = 0;
        setPlayerInfo((prev) => ({
          ...prev,
          currentTime: audioRef.current?.currentTime ?? 0,
        }));
      };

      if (audioEl) {
        audioEl.addEventListener("ended", audioEndedHandler);
      }

      return () => audioEl.removeEventListener("ended", audioEndedHandler);
    }
  }, [downloadUrl]);

  useEffect(() => {
    if (isPlaying) {
      intervalRef.current = setInterval(() => {
        setPlayerInfo((prev) => ({
          ...prev,
          currentTime: audioRef.current?.currentTime ?? 0,
          duration: audioRef.current?.duration,
        }));
      }, 1000);
      return () => clearInterval(intervalRef.current);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [isPlaying]);

  const togglePlaying = async() => {
    setIsPlaying((prev) => !prev);
    if(isPlaying){
      audioRef.current.pause()
    }else{
      let responseUrl = await getMediaDownloadUrl(src,store.dispatch)
      if(responseUrl){
        setDownloadUrl(responseUrl)
        audioRef.current.play()
      }
    }
  }

  return (
    <>
      <button
        onClick={togglePlaying}
        style={{
          appearance: "none",
          border: "none",
          backgroundColor: "transparent",
          outline: "none",
        }}
      >
        <svg
          height="32"
          width="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            r={RADIUS}
            cx="16"
            cy="16"
            strokeWidth={STROKE_WIDTH}
            stroke="#D5D9DC"
          />
          <circle
            style={{
              ...style,
              transform: "rotate(-90deg)",
              transformOrigin: "center",
            }}
            r={RADIUS}
            cx="16"
            cy="16"
            strokeWidth={STROKE_WIDTH}
            stroke="#f17c2b"
            strokeDasharray={strokeDashArray}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
          />
          {isPlaying ? <Pause fill="#f17c2b" /> : <Play fill="#D5D9DC" />}
        </svg>
        {downloadUrl && (
          <audio ref={audioRef}>
            <source src={downloadUrl} type="audio/mpeg" />
          </audio>
        )}
      </button>
    </>
  );
};

export default AudioPlayer;
