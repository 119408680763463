import * as constants from "../Constants/userConstants";

 

const initalState = { 
    loader:false, 
    toastr:{},
    user_details:{},
    navigate_to_setting:{},
    force_open_dialpad:{},
    status_list:[],
    billing_plan:{},
    user_credentials:{}

}

const userReducer = (state = initalState, action) => {
    switch (action.type) {
        case constants.SHOW_LOADER:  
            return {
                ...state,
                loader: action.payload,
            };
        case constants.TOASTR:
            return {
                ...state,
                toastr: action.payload,
            };  
        case constants.FETCH_USER_DETAILS: 
            return {
                ...state,
                user_details: action.payload,
            }; 
        case constants.NAVIGATE_TO_SETTING:
            return {
                ...state,
                navigate_to_setting: action.payload,
            };
        case constants.FORCE_OPEN_DIALPAD:
            return {
                ...state,
                force_open_dialpad: action.payload,
            };
        case constants.FETCH_STATUS_LIST:
            return {
                ...state,
                status_list: action.payload,
            };
        case constants.FETCH_BILLING_PLAN:
            return {
                ...state,
                billing_plan: action.payload,
            };
        case constants.FETCH_USER_CREDENTIALS:
            return {
                ...state,
                user_credentials: action.payload,
            };
        default:
            return state
    }
}

export default userReducer;
