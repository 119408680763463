import React, { Component } from "react"; 
import moment from "moment";
import ViewPDF from "./ViewPDF";
import { store } from "../../Services";
import { DocumentsList } from "../../Services/Actions/powerappActions";
import { connect } from "react-redux";
import { bytesToSize, removeBackdrop } from "../../Utils/Common";

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageofitems: [],
      page: 1,
      open: false,
      file: "",
      title: "",
      openViewPDF: false,
    };
  }

  componentDidMount = async () => {
    DocumentsList(store.dispatch) 
  };

  OpenModal = (file, title) => {
    this.setState({ open: true, file, title });
  };

  CloseModal = () => {
    this.setState({ open: false });
    setTimeout(() => {
      this.setState({ file: "", title: "" });
    }, 500);
  };

  closeViewPDF = () => {
    this.setState(
      {
        openViewPDF: false,
      },
      () => {
        removeBackdrop();
      }
    );
  };

  render() {
    const { documents_listing, SetTab } = this.props;
    const { pageofitems, openViewPDF, baseURL } = this.state;

    return (
      <div>
        <a
          className="chat-back-arrow chat-back-arrow-2 m-0"
          style={{ cursor: "pointer" }}
          onClick={() => SetTab("cloud_storage")}
        >
          <img src="./../assets/images/backA.png" />
        </a>
        <div className="tab-pane show active powerapps tab-pane-scroll">
          <table className="table table-hover table-center mb-0">
            <tbody>
              {pageofitems instanceof Array &&
                pageofitems.map((value, key) => {
                  return (
                    <tr key={key}>
                      <td className="document-icons text-center">
                        <i className={`fas fa-file-${value.file_type}`} />
                      </td>
                      <td>
                        {moment(value.created).format("DD-MM-YYYY hh:mm:ss")}
                      </td>
                      <td>{bytesToSize(value.size)}</td>
                      <td align="center">
                        <span
                          className="badge badge-success"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.setState({
                              openViewPDF: true,
                              baseURL: value.file,
                            })
                          }
                        >
                          View
                        </span>
                      </td>
                    </tr>
                  );
                })}
              {documents_listing && documents_listing.length === 0 && (
                <div className="text-center w-100">
                  <h5>No documents</h5>
                </div>
              )}
            </tbody>
          </table>
        </div>

        {openViewPDF && (
          <ViewPDF
            openViewPDF={openViewPDF}
            closeViewPDF={this.closeViewPDF}
            baseURL={baseURL}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    documents_listing: state?.power_apps?.documents_listing ?? [],
  };
};
export default connect(mapStateToProps)(Documents);
