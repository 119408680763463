import React, { Component } from "react";
const initialState = {};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  render() {
    const { state, SetTab } = this.props;

    return (
      <div className="col-md-4 col-lg-3 pl-0">
        <div className="mid-sidebar">
          <div className="mid-nav-inner bg-gray p-0">
            <div className="mid-nav pt-4 phone-system-menus">
              <div className="pl-4 pr-4">
                <h4>Support & Feedback</h4>
                <hr className="mb-0 mt-0"></hr>
              </div>
              <div>
                <ul className="list-unstyled mid-components m-0">
                  <li>
                    <a
                      className={` ${
                        state === "call_support" ? "active-sidebar" : ""
                      }`}
                      onClick={() => SetTab("call_support")}
                    >
                      <img
                        src="./../assets/images/support/bx_bxs-phone-call.svg"
                        alt="1"
                        className="mr-2 w-12"
                      />
                      <span className="color-gray font-12">
                        {" "}
                        Call our support team
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className={` ${
                        state === "support_query" ? "active-sidebar" : ""
                      }`}
                      onClick={() => SetTab("support_query")}
                    >
                      <img
                        src="./../assets/images/support/bx_bx-support.svg"
                        alt="1"
                        className="mr-2 w-12"
                      />
                      <span className="color-gray font-12">
                        {" "}
                        Log a support query
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className={` ${
                        state === "feedback" ? "active-sidebar" : ""
                      }`}
                      onClick={() => SetTab("feedback")}
                    >
                      <img
                        src="./../assets/images/support/feedback.svg"
                        alt="1"
                        className="mr-2 w-12"
                      />
                      <span className="color-gray font-12"> Send Feedback</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className={` ${state === "faq" ? "active-sidebar" : ""}`}
                      onClick={() => SetTab("faq")}
                    >
                      <img
                        src="./../assets/images/support/Qa.svg"
                        alt="1"
                        className="mr-2 w-12"
                      />
                      <span className="color-gray font-12"> FAQ</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
