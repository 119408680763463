import * as constants from "../Constants/reportConstants";

 

const initalState = {  
    did_number_list:[], 
    unassigned_did_number_list:[],
    call_report:[],
    call_count:0

}

const reportReducer = (state = initalState, action) => {
    switch (action.type) {
        case constants.FETCH_DID_NUMBER_LISTING: 
            return {
                ...state,
                did_number_list: action.payload,
            } 
        case constants.FETCH_UNASSIGNED_DID_NUMBER_LISTING: 
            return {
                ...state,
                unassigned_did_number_list: action.payload,
            } 
        case constants.CALL_REPORT:
            return {
                ...state,
                call_report: action.payload.data,
                call_count:action.payload.call_count
            };
        default:
            return state
    }
}

export default reportReducer;
