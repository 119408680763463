import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../Component/Pagination";
import { formatDIDNumber } from "../../Utils/Common";

function SmsList({ sms_chat_list, _UserChatHistory, ActiveTab }) {
  const [search_call, setSearchCall] = useState("");
  const [pageOfItems, setPageItems] = useState([]); 
  const UpdateData = (value) => {
    setPageItems(value);
  };
  useEffect(() => {
    if (search_call !== "") {
      var filtered = setPageItems.filter((el) => el.to.includes(search_call));
      setPageItems(filtered);
    }
  }, [search_call]);

  const chatHandler = (value) => {
    ActiveTab("sms");
    _UserChatHistory(value);
  };

  return (
    <div className="col-md-8 col-lg-9">
      <div className="right-side-top-header">
        <h5>SMS</h5>
        <span className="filter-serach">
          <img src="./../assets/images/filter.svg" alt="1" />
          <input
            type="text"
            className="form-control"
            placeholder="Filter sms history"
            name="search_call"
            value={search_call}
            onChange={(e) => setSearchCall(e.target.value)}
          />
        </span>
      </div>
      <div className="tab-content">
        <div className="tab-pane call-info-scroll single-table-height show active">
          <div className="table-responsive calls-table calls-table-hover">
            <table className="table table-hover table-center mb-0">
              <tbody>
                {pageOfItems &&
                  pageOfItems.map((value, key) => {
                    return (
                      <tr key={key}>
                        <td width="30%">
                          <div className="d-flex all-message-email-row">
                            <div className="text-center align-self-center caller-img nxa-contact">
                              <h6>
                                {value.user_name
                                  ? value.user_name
                                      .match(/\b(\w)/g)
                                      .join("")
                                      .toUpperCase()
                                  : "NA"}
                              </h6>
                            </div>
                            <div className="align-self-center caller-name-number">
                              <h3>
                                {value.user_name
                                  ? value.user_name
                                  : formatDIDNumber(value.to)}
                              </h3>
                            </div>
                          </div>
                        </td>
                        <td width="30%" align="center">
                          {value.text}
                        </td>
                        <td width="30%" align="center">
                          {value.direction}
                        </td>
                        <td width="10%" align="center">
                          <div className="recentcall-img-ico allcall-img-ico">
                            <Link
                              to="#"
                              onClick={() => chatHandler(value)}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src="./../assets/images/call-chat-ico.svg"
                                alt="1"
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                {pageOfItems && pageOfItems.length === 0 && (
                  <tr>
                    <td colSpan="2" className="text-center">
                      No sms found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination data={sms_chat_list} currentData={UpdateData} />
    </div>
  );
}
export default SmsList;
