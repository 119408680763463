import React, { useState, useRef } from "react"; 
import SimpleReactValidator from "simple-react-validator"; 
import { store } from "../../../../Services";
import { uploadIVR } from "../../../../Services/Actions/settingActions"; 
import { UploadFiles } from "../../../../Services/Actions/callActions";

function VoiceFileSlider({ addFile, closeFileSlider }) {
  const [name, setName] = useState("");
  const [filename, setFileName] = useState("");
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
 

  const handleUpload = async() => {
    if (validator.current.allValid()) {
      let recording_filename = window.$("#Upload_filename")[0].files[0];
      let response = await UploadFiles(
        recording_filename,
        "ivr",
        store.dispatch
      ); 
      if (response.code === 1) {
        const {file_path,duration,size} = response
        let postData = {
          filename: file_path,
          name,
          duration,
          size
        };
        let res = await uploadIVR(postData, store.dispatch); 
        if (res === 1) {
          closeFileSlider();
          setName("");
          setFileName("");
        }
      }  
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const validateForm = (e) => {
    var objectUrl;
    if (e.target.name === "filename") {
      objectUrl = URL.createObjectURL(e.target.files[0]);
      window.$("#audio").prop("src", objectUrl);
      setFileName(e.target.files[0]);
    } else {
      setFileName(e.target.value);
    }
  };

  return (
    <div
      className={`manage-team-member-slide account ${
        addFile ? "" : "manage-team-member-slide-close"
      }`}
    >
      {addFile && (
        <>
          <div className="member-slide-title">
            <h5>{`Add Voice File`}</h5>
            <img
              src="./../assets/images/cross-add-modal.svg"
              alt="1"
              onClick={() => closeFileSlider()}
            />
          </div>
          <div className="add-contact-slide">
            <div className="form-group form-group-role">
              <label>File Name</label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {validator.current.message("Name", name, "required")}
            </div>
            <div className="form-group form-group-role">
              <label htmlFor="exampleInputEmail1">Upload File</label>
              <input
                type="file"
                id="Upload_filename"
                name="filename"
                accept=".mp3,.wav,audio/*"
                className="form-control"
                placeholder="Choose file..."
                onChange={(e) => validateForm(e)}
              />
              <audio id="audio"></audio>
              <p className="upload-voice">Please upload .mp3 and .wav file.</p>
              {validator.current.message("filename", filename, "required")}
            </div>
          </div>
          <a className="remove-from-nxa" onClick={handleUpload}>
            {"Save"}
          </a>
        </>
      )}
    </div>
  );
}

export default VoiceFileSlider;
