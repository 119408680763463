import React, { useEffect, useState } from "react";
import { store } from "../../Services";
import { ShowLoader } from "../../Services/Actions/userActions";
import Modal from "../../Component/Modal";

function ViewPDF(props) {
  const { openViewPDF, closeViewPDF, baseURL } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      ShowLoader(true);
    }
  }, []);

  const hideLoader = () => {
    setLoading(false);
    ShowLoader(false);
  };

  return (
    <div>
      <Modal
        open={openViewPDF}
        size={`lg`}
        CloseModal={closeViewPDF}
        title={`Documents`}
        showActiveClass={true}
      >
        <iframe src={`${baseURL}`} title="Documents" onLoad={hideLoader} />
      </Modal>
    </div>
  );
}

export default ViewPDF;
