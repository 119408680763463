import React from "react"; 
import { Link } from "react-router-dom";


function OnBoardProcess({ onBoardProcessType, onboard_process_statics }) {  
    const activeUrl = window.location.pathname.replace("/","")   
    const onBoardProcessPercentage = (value)=>{
        let count = value?.extension === 'Y' ? 20  : 0
        count += value?.call_flow ==="Y" ? 20 : 0
        count += value?.call_made ==="Y" ? 20 : 0
        count += value?.business_hour ==="Y" ? 20 : 0
        count += value?.business_contact ==="Y" ? 20 : 0 
        return count
    }

    const statics = onBoardProcessPercentage(onboard_process_statics) 
    return (
        <>
        {((activeUrl === "dashboard" && statics !== 100) || activeUrl === "welcome") && 
        <div className="side-bar-join-webinar bottom-fixed-sidebar">
            <h2>On-Boarding Training for the Nxatel App</h2>
            <div className="side-bar-join-webinar-inner onboarding-progress-sidebar">
                <div className="onboarding-process-div">
                    <span className="onboarding-span">
                        <div className="onboarding-img-circle">
                            <img
                                src="./../assets/images/star-onboarding.svg"
                                alt="1"
                            />
                        </div>
                        <div className="onboarding-text">
                            <h4>Onboarding Progress:</h4>
                            <Link to="#">{`${statics}% Completed`}</Link>
                            <h6>
                                Please complete all the task to get more
                                familiar with Nxatel!
                            </h6>
                        </div>
                    </span>
                    <a
                        className="onboarding-collapse-anchor"
                        data-toggle="collapse"
                        href="#multiCollapseExample1"
                        role="button"
                        aria-expanded="false"
                        aria-controls="multiCollapseExample1"
                    >
                        <span className="collapse-arrow">
                            <img
                                src="./../assets/images/arrow-onboarding.svg"
                                alt="1"
                            />
                        </span>
                    </a>
                </div>
            </div>
            <div className="onboarding-collapse-sidebar">
                <div className="collapse show" id="multiCollapseExample1">
                    <div className="card card-body">
                        <h5>
                            <span
                                className={`onboard-step-number ${
                                    onboard_process_statics?.extension === "Y"
                                        ? " "
                                        : "onboard-step-number-disable"
                                }`}
                            >
                                1
                            </span>
                            <span
                                className="onboard-step-text"
                                onClick={() =>
                                    onBoardProcessType("team-member")
                                }
                            >
                                Invite Team Member
                            </span>
                        </h5>
                        <h5>
                            <span
                                className={`onboard-step-number ${
                                    onboard_process_statics?.business_hour ===
                                    "Y"
                                        ? " "
                                        : "onboard-step-number-disable"
                                }`}
                            >
                                2
                            </span>
                            <span
                                className="onboard-step-text"
                                onClick={() =>
                                    onBoardProcessType("business-hour")
                                }
                            >
                                Set-Up Operating Hours
                            </span>
                        </h5>
                        <h5>
                            <span
                                className={`onboard-step-number ${
                                    onboard_process_statics?.call_flow === "Y"
                                        ? " "
                                        : "onboard-step-number-disable"
                                }`}
                            >
                                3
                            </span>
                            <span
                                className="onboard-step-text"
                                onClick={() => onBoardProcessType("call-flow")}
                            >
                                Set Up Call Routing
                            </span>
                        </h5>
                        <h5>
                            <span
                                className={`onboard-step-number ${
                                    onboard_process_statics?.business_contact ===
                                    "Y"
                                        ? " "
                                        : "onboard-step-number-disable"
                                }`}
                            >
                                4
                            </span>
                            <span
                                className="onboard-step-text"
                                onClick={() =>
                                    onBoardProcessType("add-contact")
                                }
                            >
                                Add NxaContact
                            </span>
                        </h5>
                        <h5>
                            <span
                                className={`onboard-step-number ${
                                    onboard_process_statics?.call_made === "Y"
                                        ? " "
                                        : "onboard-step-number-disable"
                                }`}
                            >
                                5
                            </span>
                            <span
                                className="onboard-step-text"
                                onClick={() => onBoardProcessType("call")}
                            >
                                Make your first call
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>}
        </>
    );
}

export default OnBoardProcess;
