import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { store } from "../../../Services";
import { ConferenceCallSessions } from "../../../Services/Actions/callActions";

function KeypadFrame(props) {
  const { 
    speaker,
    MuteCall,
    SpeakerOff,
    HangUpCall,
    ShowKeyPad,
    HoldCall,
    TransferHandler,
    ConferenceHandler,
    NotesClickHanlder,
    DTMF,  
    CallRecording, 
    activeSession
  } = props;

  const {status,uuid,recording,number} = activeSession
  const { conference_sessions, transfer_sessions } = useSelector((state) => {
    const { conference_sessions, transfer_sessions } = state?.call ?? {};
    return { conference_sessions, transfer_sessions };
  });
 
  const MergeHandler = () => {
    DTMF(0);
    conference_sessions[uuid].from.status = "connected";
    conference_sessions[uuid].to.status = "connected";
    conference_sessions[uuid].isMerged = true;
    ConferenceCallSessions(conference_sessions, store.dispatch);
  };

  const { isMerged: mergedCall = false } = conference_sessions[uuid] || {};
  return (
    <>
      <div className="number mb-2">
        <div className="d-flex justify-content-between">
          <div className="calling-icons">
            <Link
              to="#"
              onClick={() => MuteCall(uuid)}
              className={`webphone-btns ${
                status === "connecting" ? "disabled" : ""
              }`}
            >
              <div className="text">
                <i
                  className={`fas fa-microphone${
                    status === "mute" ? "-slash" : ""
                  }`}
                ></i>
              </div>
            </Link>
            <p>{status === "mute" ? "Unmute" : "Mute"}</p>
          </div>
          {!mergedCall ? (
            <div className="calling-icons">
              <Link
                to="#"
                className={`webphone-btns ${
                  status === "connecting" ? "disabled" : ""
                }`}
                onClick={() => ShowKeyPad(true)}
              >
                <div className="text">
                  <span className="d-flex">
                    <img src="./../assets/images/dialer-keypad.svg" alt="1" />
                  </span>
                </div>
              </Link>
              <p>Keypad</p>
            </div>
          ) : (
            <div className={`calling-icons`}>
              <Link
                to="#"
                className={`call-cut mt-0 webphone-btns`}
                onClick={() => HangUpCall(uuid)}
              >
                <div className="text">
                  <i className="fas fa-phone-alt"></i>
                </div>
              </Link>
              <p>End Call</p>
            </div>
          )}
          <div className="calling-icons">
            <Link
              to="#"
              onClick={SpeakerOff}
              className={`webphone-btns ${
                status === "connecting" ? "disabled" : ""
              }`}
            >
              <div className="text">
                <img
                  src={`./../assets/images/speaker-${
                    speaker ? "up" : "down"
                  }.svg`}
                  alt="speaker-volume"
                />
              </div>
            </Link>
            <p>Audio</p>
          </div>
        </div>
      </div>
      {!transfer_sessions[uuid] && !conference_sessions[uuid] && !mergedCall && (
        <div className="number  mb-2">
          <div className="d-flex justify-content-between">
            <div className="calling-icons">
              <Link
                to="#"
                className={`webphone-btns ${
                  status === "connecting" ? "disabled" : ""
                }`}
                onClick={() => HoldCall(uuid)}
              >
                <div className="text">
                  <img
                    src={`./../assets/images/hold-${
                      status === "hold" ? "up" : "down"
                    }.svg`}
                    alt="speaker-volume"
                  />{" "}
                </div>
              </Link>
              <p>Hold</p>
            </div>
            <div className="calling-icons">
              <Link
                to="#"
                className={`webphone-btns ${
                  status === "connecting" ? "disabled" : ""
                }`}
                onClick={TransferHandler}
              >
                <div className="text">
                  <img
                    src="./../assets/images/dialer-transfer-ico.svg"
                    alt="1"
                  />
                </div>
              </Link>
              <p>Transfer</p>
            </div>
            <div className="calling-icons">
              <Link
                to="#"
                className={`webphone-btns ${
                  status === "connecting" ? "disabled" : ""
                }`}
                onClick={ConferenceHandler}
              >
                <div className="text">
                  <img src="./../assets/images/add.svg" alt="1" />
                </div>
              </Link>
              <p>Add</p>
            </div>
          </div>
        </div>
      )}
      {!transfer_sessions[uuid] && !mergedCall && (
        <div className="number mb-2">
          <div className="d-flex justify-content-center">
            {conference_sessions[uuid] ? (
              <div className="calling-icons mr-4">
                <Link
                  to="#"
                  className={`webphone-btns bg-merge-gray`}
                  onClick={MergeHandler}
                >
                  <div className="text">
                    <img src="./../assets/images/merge.png" alt="1" />
                  </div>
                </Link>
                <p>Merge</p>
              </div>
            ) : (
              <div className="calling-icons mr-4">
                <Link
                  to="#"
                  className={`webphone-btns ${
                    status === "connecting" ? "disableds" : ""
                  }`}
                  onClick={NotesClickHanlder}
                >
                  <div className="text">
                    <img
                      src="./../assets/images/dialer-notes-ico.svg"
                      alt="1"
                    />
                  </div>
                </Link>
                <p>Notes</p>
              </div>
            )}
            <div className="calling-icons">
              <Link
                to="#"
                onClick={()=>CallRecording(uuid)}
                className={`webphone-btns m-auto ${
                  status === "connecting" || number?.length <= 4 ? "disabled" : ""
                } ${recording  ? "record-ico" : ""}`}
              >
                <div className="text">
                  <img
                    src="./../assets/images/dialer-recording-ico.svg"
                    alt="1"
                  />
                </div>
              </Link>
              <p>Record</p>
            </div>
          </div>
        </div>
      )}
      {!mergedCall && (
        <div className="number mb-2">
          <div className="d-flex justify-content-center">
            {transfer_sessions[uuid] && (
              <div className="calling-icons mr-4">
                <Link
                  to="#"
                  className={`webphone-btns record-ico`}
                  onClick={() => HangUpCall(uuid)}
                >
                  <div className="text">
                    <img src="./../assets/images/transfer-icon.png" alt="1" />
                  </div>
                </Link>
                <p>Transfer Now</p>
              </div>
            )}
            <div className={`calling-icons`}>
              <Link
                to="#"
                className={`call-cut mt-0 webphone-btns`}
                onClick={() => HangUpCall(uuid)}
              >
                <div className="text">
                  <i className="fas fa-phone-alt"></i>
                </div>
              </Link>
              <p>End Call</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default KeypadFrame;
