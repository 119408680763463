import React, { Component } from "react";
import { Link } from "react-router-dom";
import { removeBackdrop } from "../../../../Utils/Common";
import DeleteIPDevices from "./DeleteIPDevices";

class IPDevices extends Component {
    constructor(props) {
        super(props);
        this.state={
            pageType : 'add-device',
            openModal:false
        }
    }

    handleClose = ()=>{
        this.setState({
            openModal:false
        },()=>{
            removeBackdrop()
        })
    }
    
    render() {
        const {pageType,openModal} = this.state
        const { SetTab } = this.props;
        return (
            <>   
            <div className="phone-system-inner-main-div">
                <div className="phone-system-inner-div">
                    <div className="phone-system-inner-head">
                        <div className="ps-inner-head-left">
                            <h3>
                                <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => SetTab("phone")}
                                    className="phone-system-name"
                                >
                                    Phone Settings
                                </span>
                                <img
                                    src="./../assets/images/menu-arrow-up.svg"
                                    alt="1"
                                />
                                <span>IP Devices</span>
                            </h3>
                        </div> 
                        <div className="ps-inner-head-right">
                            <span className="search-ps-inner">
                                <img
                                    src="./../assets/images/search-dash.svg"
                                    alt="1"
                                />
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                />
                            </span>
                            <Link
                                to="#" 
                                style={{ cursor: "pointer" }}
                                className="create-ps-num-btn"
                            >
                                Add IP Device
                            </Link>
                        </div>
                    </div>   
                    <div className="phone-system-inner-table">
                        <div className="table-responsive ">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Name of IP Device </th>
                                        <th>Receive Calls</th>
                                        <th>Assigned to</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pageType === "list" && 
                                    <tr>
                                        <td></td>
                                        <td>Customer Service Phone</td>
                                        <td>Yes</td>
                                        <td>Lerato Donatche</td>
                                        <td align="right">
                                            <div className=" number-collase-action">
                                                <a
                                                    href={`#1`}
                                                    data-toggle="collapse"
                                                    aria-expanded="false"
                                                    className="dropdown-toggle collapsed"
                                                >
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </a>
                                                <ul
                                                    className="list-unstyled number-dr-collapse collapse"
                                                    id="1"
                                                >
                                                    <li>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#" 
                                                        > 
                                                            Assign to
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#" 
                                                        > 
                                                            Copy Credentials
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#" 
                                                        > 
                                                            Email Credentials
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#" 
                                                            onClick={()=>this.setState({openModal:true})}
                                                        > 
                                                            Delete 
                                                        </Link>{" "}
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                    }
                                    {pageType === 'add-device' &&
                                        <tr  className="add-data-vactor">
                                            <td colSpan={5}>
                                                <div className="team-user-add-div add-data-vactor">
                                                    <img
                                                        src="./../assets/images/ip-device.svg"
                                                        alt="1"
                                                    ></img>
                                                    <h4>
                                                        No IP Device added
                                                    </h4>
                                                    <Link
                                                        to="#"
                                                        className="btn btn-orange"
                                                        style={{ cursor: "pointer" }} 
                                                        onClick={()=>this.setState({pageType:'list'})}
                                                    >
                                                        Add IP Device
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div> 
                </div>
            </div>

            {
                openModal && <DeleteIPDevices openModal={openModal} handleClose={this.handleClose} />
            }
        </>
        );
    }
}

export default IPDevices;
