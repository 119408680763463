import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "../../../Utils/withRouter";

function Sidebar(props) {
  const { state, user_details, Selecttab } = props;
  let port_number = user_details?.porting_details?.porting;

  const NavigateToPage = (value) => {  
    Selecttab(value);
  };


  return (
    <div className="col-md-4 col-lg-3 pl-0">
      <div className="mid-sidebar">
        <div className="mid-nav-inner bg-gray p-0">
          <div className="mid-nav pt-4">
            <h4 className="pl-4 pr-4">Account verification process</h4>
            <div className="pl-4 pr-4">
              <hr className="mt-0 mb-0 " />
            </div>
            <ul className="list-unstyled mid-components m-0">
              <li>
                <a
                  href="#contacts"
                  data-toggle="collapse"
                  aria-expanded="true"
                  className="dropdown-toggle"
                >
                  <h5 className="ric-process-final">
                    {" "}
                    <span className="circle-grey"></span>
                    Rica Process - Final Step
                  </h5>
                </a>
                <ul className="list-unstyled collapse show" id="contacts">
                  <li
                    onClick={() =>
                      NavigateToPage("company_detail_verification")
                    }
                  >
                    <Link
                      to="/rica-process"
                      className={
                        state === "company_detail_verification" ? "active" : ""
                      }
                    >
                      Company Detail Verification
                    </Link>
                  </li>
                  <li onClick={() => NavigateToPage("id_verification")}>
                    <Link
                      to="/rica-process"
                      className={state === "id_verification" ? "active" : ""}
                    >
                      ID Verification
                    </Link>
                  </li>
                  <li onClick={() => NavigateToPage("address_verification")}>
                    <Link
                      to="/rica-process"
                      className={
                        state === "address_verification" ? "active" : ""
                      }
                    >
                      Address Verification
                    </Link>
                  </li>
                  {port_number === "Y" && (
                    <li>
                      <Link
                        to="/porting-process"
                        className={state === "porting-process" ? "active" : ""}
                      >
                        Porting Process
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user_details: state?.user?.user_details?.userDetail ?? {},
  };
};

export default connect(mapStateToProps)(withRouter(Sidebar));
