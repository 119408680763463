import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator"; 
import RenderProp from "../../../Component/Modal/RenderProp";
import Schedule from "../../../Component/Modal/Schedule";
import Join from "../../../Component/Modal/Join";
import { store } from "../../../Services";
import { withRouter } from "../../../Utils/withRouter";
import { ExtensionListing } from "../../../Services/Actions/settingActions"; 
import StartMeet from "../../../Component/Modal/StartMeet";

//prettier-ignore
const initialState = { modal: '', topic: '', password: '', description: '', estimation_time: 15, participants: [],timezone_offset:'' };


class VideoMeetings extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = async () => {
    ExtensionListing(0,store.dispatch);
    let timezone_offset = new Date().getTimezoneOffset() * -60;
    this.setState({ timezone_offset });
  }; 

  handleClose = () => {
    this.setState(initialState);
  };

  scheduleMeetClose = () => {
    this.setState({ modal: "upcoming_meeting" });
  }; 

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onTimeChange = (value) => {
    this.setState({ end_time: value });
  };

  onSelect = (value) => {
    this.setState({ participants: window.$("#participantsID").val() });
  };

  render() {
    const { modal } = this.state; 
    return (
      <div className="col-md-8 col-lg-9">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12  meet-tabing tab-pane-scroll">
                <div className=" row">
                  <div className="col-lg-9 offset-lg-2  col-md-12">
                    <div className="meeting-tabbing">
                      <ul className="nav" id="myTab" role="tablist">
                        <li className="">
                          <a
                            className="active"
                            id="home-tab"
                            data-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            <div className="mlr-0-15">
                              <div
                                className="message-box meeting-right bg-light-orange pb-4"
                                onClick={() =>
                                  this.setState(
                                    {
                                      modal: "",
                                    },
                                    () => {
                                      this.setState({
                                        modal: "start_meet",
                                      });
                                    }
                                  )
                                }
                              >
                                <div className="message-icons">
                                  <img
                                    src="./../assets/images/start-meeting.svg"
                                    alt="1"
                                  />
                                  <h6 className="mt-3 mb-0">
                                    Start
                                    <br></br>
                                    Meeting
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li className="">
                          <a
                            className=""
                            id="profile-tab"
                            data-toggle="tab"
                            href="#profile"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                            y
                          >
                            <div className="mlr-0-15">
                              <div
                                className="message-box meeting-right bg-blue"
                                onClick={() =>
                                  this.setState(
                                    {
                                      modal: "",
                                    },
                                    () => {
                                      this.setState({
                                        modal: "join_meet",
                                      });
                                    }
                                  )
                                }
                              >
                                <div className="message-icons">
                                  <img
                                    src="./../assets/images/join.svg"
                                    alt="1"
                                  />
                                  <h6 className="mt-3 mb-0">
                                    Join
                                    <br></br>
                                    Meeting
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li className="">
                          <a
                            className=""
                            id="contact-tab"
                            data-toggle="tab"
                            href="#contact"
                            role="tab"
                            aria-controls="contact"
                            aria-selected="false"
                          >
                            <div className="mlr-0-15">
                              <div
                                className="message-box bg-dgray meeting-right"
                                onClick={() =>
                                  this.setState(
                                    {
                                      modal: "",
                                    },
                                    () => {
                                      this.setState({
                                        modal: "schedule_meet",
                                      });
                                    }
                                  )
                                }
                              >
                                <div className="message-icons">
                                  <img
                                    src="./../assets/images/schedule.svg"
                                    alt="1"
                                  />
                                  <h6 className="mt-3 mb-0">
                                    Schedule
                                    <br></br>
                                    Meeting
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {modal === "join_meet" && (
                  <RenderProp>
                    {({ UpdateTopic }) => <Join UpdateTopic={UpdateTopic} />}
                  </RenderProp>
                )}
                {modal === "start_meet" && (
                  <RenderProp>
                    {({ UpdateTopic }) => <StartMeet UpdateTopic={UpdateTopic} />}
                  </RenderProp>
                )}
                {modal === "schedule_meet" && (
                  <RenderProp>
                    {({ UpdateTopic, extension_listing }) => (
                      <Schedule
                        UpdateTopic={UpdateTopic}
                        extension_listing={extension_listing}
                      />
                    )}
                  </RenderProp>
                )}
                {/* new */}
                <div className="row google-div">
                  <div className="col-lg-5 col-md-12">
                    <div className="google-microsoft-btn-div">
                      <p>
                        Connect your calendar
                        <br />
                        connecting your calendar allows you <br />
                        to see all your meetings from this page.
                      </p>
                      <a href={void 0} className="btn google-micro-btn">
                        <img src="./../assets/images/google-ico.svg" alt="1" />
                        Connect your Google Calendar
                      </a>
                      <a href={void 0} className="btn google-micro-btn">
                        <img
                          src="./../assets/images/microsoft-ico.svg"
                          alt="1"
                        />
                        Connect your Microsoft Calendar
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12">
                    <div className="meet-vector-1">
                      <img
                        src="./../assets/images/meet-vector-1.svg"
                        className="img-fluid"
                        alt="1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(withRouter(VideoMeetings));
