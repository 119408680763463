import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import en from "react-phone-number-input/locale/en.json";
import { store } from "../../../../Services";
import {
  DidNumberBuy,
  GetDidCityList,
  GetDidNumber,
} from "../../../../Services/Actions/signupActions";
import { DidNumberListing } from "../../../../Services/Actions/reportActions";
import { connect } from "react-redux";
import swal from "sweetalert";
import { countryList, countryPrefix, formatPhone } from "../../../../Utils/Common";

const initialState = {
  prefixType: "",
  selectedPrefix: "",
  country_prefix: "",
  country: "",
  type: "Local",
  city: null,
  number: "",
  name: "",
  clicked: false,
  port_number: false,
  service_provider: "",
  ported_number: "",
  no_lines_porting: "",
};

class CreateNumber extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator();
  }

  PrefixDetails = (type, prefix) => {
    this.setState({
      prefixType: type,
      selectedPrefix: prefix,
    });
  };

  handleChange = (fieldName, value) => {
    if (fieldName === "country") {
      this.setState(
        {
          country: value,
        },
        async () => {
          const { country, type } = this.state;
          if (type === "Local") {
            GetDidCityList({ country: en[country] }, store.dispatch);
          }
        }
      );
    } else if (fieldName === "type") {
      this.setState(
        { type: value, number: "", city: "", name: "", country_prefix: "" },
        async () => {
          const { country, type } = this.state;
          if (type === "Local") {
            GetDidCityList({ country: en[country] }, store.dispatch);
          }
        }
      );
    } else if (fieldName === "city") {
      this.setState({ city: value, number: "", name: "" }, async () => {
        const { country, city, type } = this.state;
        if (type === "Local") {
          GetDidNumber(
            { country: en[country], city, type: "L" },
            store.dispatch
          );
        }
      });
    } else if (fieldName === "country_prefix") {
      this.setState({ country_prefix: value }, async () => {
        const { country, type, country_prefix } = this.state;
        if (type === "National") {
          GetDidNumber(
            { country: en[country], prefix: country_prefix, type: "N" },
            store.dispatch
          );
        }
      });
    } else {
      this.setState({ number: value });
    }
  };

  onSubmitHandler = async () => {
    this.setState({ clicked: true });
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ clicked: false });
      return null;
    }
    const { number, name } = this.state;
    let DidDetails = { did_number: number, name, type: "phoneSystem" ,type:"login"};
    let res = await DidNumberBuy(DidDetails, store.dispatch);
    if (res === 1) {
      this.props.closeCreateNumber();
      this.setState(initialState);
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p> Number has been successfully added to the number list and can assign to team member or team! </p>`;
      swal({
        icon: "./../assets/images/subscription-successfull.svg",
        title: `Number Successfully Purchased`,
        content: wrapper,
        className: "add-subscription",
      }).then(async (action) => {
        if (action) {
          DidNumberListing(store.dispatch,"phoneSettings");
        }
      });
    }
  };

  prefixHandler = (val) => {
    this.handleChange("country_prefix", val.value);
    this.PrefixDetails(val.type, val.prefix);
  };

  render() {
    this.validator.purgeFields();
    const {
      prefixType,
      selectedPrefix,
      country,
      type,
      city,
      number,
      name,
      country_prefix,
    } = this.state;
    const {
      web_registration: { available_did_numbers, available_city_list },
      createNumber,
      closeCreateNumber,
    } = this.props;
    return (
      <>
        <div
          className={`manage-team-member-slide ${
            createNumber ? "" : "manage-team-member-slide-close"
          }`}
        >
          {createNumber && (
            <>
              <div className="buy-local-national-number-slide">
                <div className="member-slide-title">
                  <h5>Buy a local or National number for your Business </h5>
                  <img
                    src="./../assets/images/cross-add-modal.svg"
                    alt="1"
                    onClick={() => closeCreateNumber()}
                  />
                </div>
                <div className="business-hrs-side-form">
                  <div className="form-group">
                    <div className=" number-flags local-national-drop-side">
                      <div className="dropdown custom-select-dropdown">
                        <button
                          className="btn btn-transprent dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {country ? en[country] : "Select Country"}
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {countryList &&
                            countryList.map((value, index) => {
                              return (
                                <a
                                  className="dropdown-item"
                                  key={index}
                                  href={void 0}
                                  onClick={() =>
                                    this.handleChange(
                                      "country",
                                      value.country_code
                                    )
                                  }
                                >
                                  <span className="flag-img-span">
                                    <img
                                      src={
                                        value.country_image
                                          ? `./../assets/images/${value.country_image}`
                                          : ""
                                      }
                                      alt="country"
                                    />
                                    {value.name}
                                  </span>
                                </a>
                              );
                            })}
                        </div>
                      </div>
                      {this.validator.message("Country", country, "required")}
                    </div>
                  </div>
                  <div className="form-group business-hrs-tabs-side">
                    <div className="business-hrs-btn-group">
                      <a
                        className={`${type === "Local" ? "active" : ""}`}
                        href={void 0}
                        onClick={() => this.handleChange("type", "Local")}
                      >
                        Local
                      </a>
                      <a
                        className={`${type === "National" ? "active" : ""}`}
                        href={void 0}
                        onClick={() => this.handleChange("type", "National")}
                      >
                        National
                      </a>
                    </div>
                  </div>
                  {type === "Local" && (
                    <div className="form-group">
                      <div className=" number-flags local-national-drop-side">
                        <div className="dropdown custom-select-dropdown">
                          <button
                            className="btn btn-transprent dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {city ? city : "Choose by City/Province"}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {available_city_list &&
                              available_city_list.map((value, key) => {
                                return (
                                  <a
                                    className="dropdown-item"
                                    href={void 0}
                                    onClick={() =>
                                      this.handleChange("city", value.did_city)
                                    }
                                  >
                                    {value.did_city}
                                    <span className="float-right">
                                      {value.did_city_prefix}
                                    </span>
                                  </a>
                                );
                              })}
                          </div>
                        </div>
                        {this.validator.message("City Name", city, "required")}
                      </div>
                    </div>
                  )}
                  {type === "National" && (
                    <div className="form-group">
                      <div className=" number-flags local-national-drop-side">
                        <div className="dropdown custom-select-dropdown">
                          <button
                            className="btn btn-transprent dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {country_prefix
                              ? `${prefixType} (${selectedPrefix})`
                              : "Choose by Prefix"}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {countryPrefix &&
                              countryPrefix.map((val, key) => {
                                return (
                                  <a
                                    className="dropdown-item"
                                    href={void 0}
                                    key={key}
                                    onClick={() => this.prefixHandler(val)}
                                  >
                                    {val.type}
                                    <span className="float-right">
                                      {val.prefix}
                                    </span>
                                  </a>
                                );
                              })}
                          </div>
                        </div>
                        {this.validator.message(
                          "Prefix",
                          country_prefix,
                          "required"
                        )}
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <div className=" number-flags local-national-drop-side">
                      <div className="dropdown custom-select-dropdown">
                        <button
                          className="btn btn-transprent dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {number
                            ? formatPhone(
                                number?.substr(0, 2) === "27"
                                  ? `0${number?.substr(2)}`
                                  : number
                              )
                            : "Select your Number"}
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {available_did_numbers &&
                            available_did_numbers.map((value, key) => {
                              let did_number =
                                value.did_number?.substr(0, 2) === "27"
                                  ? `0${value.did_number?.substr(2)}`
                                  : value.did_number;
                              return (
                                <a
                                  className="dropdown-item"
                                  href={void 0}
                                  onClick={() =>
                                    this.handleChange(
                                      "number",
                                      value.did_number
                                    )
                                  }
                                >
                                  {formatPhone(did_number)}
                                </a>
                              );
                            })}
                        </div>
                      </div>
                      {this.validator.message(
                        "Number",
                        number,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Name Your Number"
                      value={name}
                      name="name"
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                    {this.validator.message(
                      "Name of Number",
                      name,
                      "required|min:4,string|max:30,string"
                    )}
                  </div>
                  {number && (
                    <div className="form-group local-national-number-sumary-bx">
                      <p>
                        {` ${formatPhone(number)} is ready for purchase`}
                      </p>
                      <p>
                        This price reflects the difference between your previous
                        plan and your upgrade plan.
                      </p>
                      <h5>Due Today : R49.33</h5>
                    </div>
                  )}
                </div>
                <div className="bottom-btn-b-hrs">
                  <a
                    className="confirm-create-num btn"
                    onClick={this.onSubmitHandler}
                    style={{ cursor: "pointer" }}
                  >
                    Confirm and Create Number
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    web_registration: state?.signup,
    user_details: state?.user?.user_details?.userDetail ?? {},
  };
};
export default connect(mapStateToProps)(CreateNumber);
