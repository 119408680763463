import { apiBaseUrl } from "../../Utils/Common";
import * as constants from "../Constants/notificationConstants"; 
import swal from "sweetalert"; 
import authService from "../../Utils/authService";
import { handleResponse } from "./userActions";
import { SHOW_LOADER } from "../Constants/userConstants";

// ********************************** NOTIFICATION ACTIONS ************************************************************

export const NotificationListing = (dispatch) => {
  const token = authService.getUserAccessToken(); 
  const request = new Request(`${apiBaseUrl}userPanel/notification_listing`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => { 
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_NOTIFICATION_LISTING,
          payload: data.data ?? [],
        });
        return 1;
      } 
    })
    .catch((error) => { 
    });
};

export const NotificationSeen = (dispatch) => {
  const token = authService.getUserAccessToken();
  const request = new Request(`${apiBaseUrl}userPanel/notification-seen`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      if (data.ResponseCode === "1") {
        return 1;
      }
      return 0;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const DeleteNotification = (params,dispatch) => {
  const token = authService.getUserAccessToken();
  dispatch({ type: SHOW_LOADER, payload: true });
  const request = new Request(`${apiBaseUrl}userPanel/delete-notification`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        NotificationListing(dispatch);
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_LOADER, payload: false });
    });
};
