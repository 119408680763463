import React, { useState, useRef, useEffect } from "react";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en.json";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { store } from "../../../Services";
import {
  AllRecordingListing,
  CreateNxaContact,
  RecentCallListing,
  UpdateNxaContact,
  UploadFiles,
  VoicemailListing,
} from "../../../Services/Actions/callActions";
import { OnBoardProcessStatics } from "../../../Services/Actions/dashboardActions";
import { ExtensionListing } from "../../../Services/Actions/settingActions";
const __ContactType = ["Customer", "Supplier", "Partners", "Others"];

function ContactSlider(props) {
  const {
    addContact,
    closeAddContactSlider,
    extension_listing,
    user_details: { did_numbers },
    phone,
    fetchContactType,
    SetTab,
    renderPage,
    _editContactDetails,
  } = props;

  const [type, setType] = useState("business");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [contactType, setContactType] = useState("");
  const [assignTeamMember, setAssignTeamMember] = useState("");
  const [assignNumber, setAssignNumber] = useState("");
  const [callNumber, setCallNumber] = useState("");
  const [number, setNumber] = useState("");
  const [country, setCountry] = useState("South Africa");
  const [isNumDisabled, setIsNumDisabled] = useState(false);
  const [image, setImage] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [contactID, setContactID] = useState("");
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const uploadRef = useRef(null);


  useEffect(()=>{
    if(extension_listing?.length === 0){
      ExtensionListing(0, store.dispatch);
    }
  },[])

  useEffect(() => {
    if (phone) {
      let call_number = phone.includes("+") ? phone : `+${phone}`;
      setCallNumber(call_number);
      setNumber(call_number);
      setIsNumDisabled(true);
    }
  }, [phone]);

  useEffect(() => {
    if (_editContactDetails && Object.keys(_editContactDetails).length) {
      const {
        firstname,
        lastname,
        address,
        profile_picture,
        assignNumber,
        assignTeamMember,
        contactType,
        country,
        email,
        phone,
        type,
        uuid,
      } = _editContactDetails;
      setName(`${firstname} ${lastname}`);
      setAddress(address);
      setUserImage(profile_picture);
      setAssignNumber(assignNumber);
      setAssignTeamMember(assignTeamMember);
      setContactType(contactType);
      setCountry(country);
      setEmail(email);
      setCallNumber(phone);
      setType(type);
      setNumber(phone);
      setContactID(uuid);
      setImage(true);
    } else if (
      _editContactDetails &&
      !Object.keys(_editContactDetails).length
    ) {
      setName("");
      setAddress("");
      setUserImage("");
      setAssignNumber("");
      setAssignTeamMember("");
      setContactType("");
      setCountry("");
      setEmail("");
      setCallNumber("");
      setType("business");
      setNumber("");
      setImage(false);
    }
  }, [_editContactDetails]);

  const numberChange = (number) => {
    let phoneNumber = number ? parsePhoneNumber(number.toString()) : "";
    setCallNumber(number);
    setCountry(en[phoneNumber?.country]);
  };

  const onSaveContact = async () => {
    let personal_data = {
      name,
      email,
      phone: callNumber,
      type,
      app_picture: image ? userImage : "",
    };
    let business_data = {
      name,
      email,
      phone: callNumber,
      address,
      contactType,
      assignNumber,
      assignTeamMember,
      type,
      country,
      app_picture: image ? userImage : "",
    };
    if (validator.current.allValid()) {
      let data = type === "personal" ? personal_data : business_data;
      let response;
      if (_editContactDetails && Object.keys(_editContactDetails).length) {
        response = await 
          UpdateNxaContact({ ...data, contactID },store.dispatch) 
      } else {
        response = await  CreateNxaContact(data,store.dispatch) 
      }
      if (response === 1) {
        closeAddContactSlider();
        if (renderPage === "all_contacts") {
          SetTab("all_contacts");
        } else if (
          renderPage === "business_personal" &&
          contactType === "personal-contact"
        ) {
          fetchContactType("personal-contact");
        } else if (
          renderPage === "business_personal" &&
          contactType === "business-contact"
        ) {
          fetchContactType("business-contact");
        } else if (renderPage === "all_calls") {
          RecentCallListing(true,store.dispatch) 
        } else if (renderPage === "call_recording") {
           AllRecordingListing(store.dispatch) 
        } else if (renderPage === "voicemail") {
           VoicemailListing(store.dispatch) 
        } else if (renderPage === "welcome") {
          OnBoardProcessStatics(store.dispatch) 
        }
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const uploadImage = async () => {
    let imageFile = window.$("#profile_pic")[0].files[0];
    let response = await  UploadFiles(imageFile, "profile",store.dispatch) 
    if (response.code === 1) {
      setUserImage(response.file_path);
      setImage(true);
    }
  };

  validator.current.purgeFields();

  return (
    <>
      <div
        className={`manage-team-member-slide account ${
          addContact ? "" : "manage-team-member-slide-close"
        }`}
      >
        {addContact && (
          <>
            <div className="member-slide-title">
              <h5>{`${
                _editContactDetails && Object.keys(_editContactDetails).length
                  ? "Edit"
                  : "Add"
              } NxaContact`}</h5>
              <img
                src="./../assets/images/cross-add-modal.svg"
                alt="1"
                onClick={() => closeAddContactSlider()}
              />
            </div>
            <div className="add-contact-slide">
              <span className="type-add-contact">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="type"
                    id="inlineRadio1"
                    value="business"
                    onChange={() => setType("business")}
                    checked={type === "business" ? true : false}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Business
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="type"
                    id="inlineRadio2"
                    value="personal"
                    onChange={() => setType("personal")}
                    checked={type === "personal" ? true : false}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Personal
                  </label>
                </div>
              </span>
              <div className="nxa-contact-upload-img">
                <div className="nxa-contact-upload-img">
                  <label className="label-upload-img" htmlFor="upload">
                    <img
                      src={
                        userImage
                          ? userImage
                          : "./../assets/images/upload-add-contact.svg"
                      }
                      alt="1"
                    />
                  </label>
                  <input
                    type="file"
                    hidden
                    id="profile_pic"
                    name="user_image"
                    ref={(input) => (uploadRef.current = input)}
                    onChange={(e) => uploadImage(e)}
                    accept="image/jpeg, image/png"
                  />
                  <label
                    className="label-upload-text"
                    htmlFor="upload"
                    onClick={() => uploadRef.current.click()}
                  >
                    Upload Image
                  </label>
                </div>
              </div>
              <div className="form-group form-group-role">
                <label>Full Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {validator.current.message(
                  "Name",
                  name,
                  "required|string|max:20,string"
                )}
              </div>
              <div className="form-group form-group-role">
                <label>Mobile Number</label>
                <PhoneInput
                  name="phone"
                  placeholder="Phone Number"
                  value={number}
                  onChange={(number) => numberChange(number)}
                  defaultCountry="ZA"
                  disabled={isNumDisabled ? true : false}
                />
                {validator.current.message("Phone", callNumber, "required")}
              </div>
              <div className="form-group form-group-role">
                <label>Work Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {type === "business" && (
                <>
                  <div className="form-group form-group-role">
                    <label>Contact Type</label>
                    <select
                      className="form-control"
                      name="contactType"
                      value={contactType}
                      onChange={(e) => setContactType(e.target.value)}
                    >
                      <option value="">Select Option</option>
                      {__ContactType &&
                        __ContactType.map((value, index) => {
                          return (
                            <option key={index} value={value}>
                              {value}
                            </option>
                          );
                        })}
                    </select>
                    {validator.current.message(
                      "Contact Type",
                      contactType,
                      "required"
                    )}
                  </div>
                  <div className="form-group form-group-role">
                    <label>Assign to Team Member</label>
                    <select
                      className="form-control"
                      name="assignTeamMember"
                      value={assignTeamMember}
                      onChange={(e) => setAssignTeamMember(e.target.value)}
                    >
                      <option value="">Select Option</option>
                      {extension_listing &&
                        extension_listing.map((value, index) => {
                          return (
                            <option key={index} value={value.customerID}>
                              {value.name}
                            </option>
                          );
                        })}
                    </select>
                    {validator.current.message(
                      "Assign to Team Member",
                      assignTeamMember,
                      "required"
                    )}
                  </div>
                  <div className="form-group form-group-role">
                    <label>Assign Number</label>
                    <select
                      className="form-control"
                      name="assignNumber"
                      value={assignNumber}
                      onChange={(e) => setAssignNumber(e.target.value)}
                    >
                      <option value="">Select Option</option>
                      {did_numbers &&
                        did_numbers.map((value, index) => {
                          return (
                            <option key={index} value={value.did_number}>
                              {" "}
                              {value.did_number}
                            </option>
                          );
                        })}
                    </select>
                    {validator.current.message(
                      "Assign Number",
                      assignNumber,
                      "required"
                    )}
                  </div>
                  <div className="form-group form-group-role">
                    <label>Address</label>
                    <input
                      className="form-control"
                      type="text"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div className="form-group form-group-role">
                    <label>Country*</label>
                    <input
                      className="form-control"
                      type="text"
                      name="country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                </>
              )}
            </div>
            <a className="remove-from-nxa" onClick={onSaveContact}>{`${
              _editContactDetails && Object.keys(_editContactDetails).length
                ? "Update"
                : "Save"
            } NxaContact`}</a>
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  extension_listing: state?.setting?.extension_listing,
  user_details: state?.user?.user_details?.userDetail ?? {},
});

export default connect(mapStateToProps)(ContactSlider);
