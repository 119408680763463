import React, { Component } from "react";
import { NavigatetoPowerApps } from "../../Services/Actions/powerappActions";
import AllApps from "./AllApps";
import CloudStorage from "./CloudStorage";
import Sidebar from "./Sidebar";
import Notes from "./Notes";
import BusinessInbox from "./BusinessInbox";
import CallRecording from "./CallRecording";
import VideoRecording from "./VideoRecording";
import CreateUpdateNote from "./CreateUpdateNote";
import MoreApps from "./MoreApps";
import Documents from "./Documents";
import { connect } from "react-redux"; 
import { store } from "../../Services";

const initialState = { tab: "all", EditNotesDetails: "", isEdit: false };

class PowerApps extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    let {
      nxatel: { navigate_to_power_apps },
    } = this.props;
    if (navigate_to_power_apps) {
      const tab = navigate_to_power_apps && navigate_to_power_apps.tab;
      if (tab) {
        this.setState({ tab });
      }
       NavigatetoPowerApps({}) 
    }
  }

  SetTab = (tab) => {
    this.setState({ tab });
  };

  ParentFunc = (value) => {
    let isCreated = value?.isEdit ?? "";
    if (isCreated === false) {
      this.setState({
        isEdit: false,
      });
    } else {
      this.setState({
        EditNotesDetails: value,
        isEdit: true,
      });
    }
  };

  render() {
    const { tab } = this.state;

    return (
      <>
        <div className="right-side-wrapper">
          <div className="card">
            <div className="card-body p-0">
              <div className="row m-0">
                <Sidebar state={tab} SetTab={this.SetTab} />
                <div className="col-md-8 col-lg-9">
                  <div className="account">
                    {tab === "all" && (
                      <AllApps SetTab={this.SetTab} {...this.props} />
                    )}
                    {tab === "cloud_storage" && (
                      <CloudStorage SetTab={this.SetTab} {...this.props} />
                    )}

                    {tab === "notes" && (
                      <Notes
                        SetTab={this.SetTab}
                        ParentFunc={this.ParentFunc}
                        {...this.props}
                      />
                    )}

                    {tab === "business_inbox" && (
                      <BusinessInbox {...this.props} />
                    )}

                    {tab === "more_apps" && <MoreApps {...this.props} />}

                    {tab === "call_recording" && (
                      <CallRecording SetTab={this.SetTab} {...this.props} />
                    )}

                    {tab === "video_recording" && (
                      <VideoRecording SetTab={this.SetTab} {...this.props} />
                    )}

                    {tab === "create_note" && (
                      <CreateUpdateNote
                        EditNotesDetails={this.state.EditNotesDetails}
                        isEdit={this.state.isEdit}
                        SetTab={this.SetTab}
                        {...this.props}
                      />
                    )}
                    {tab === "documents" && (
                      <Documents SetTab={this.SetTab} {...this.props} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  nxatel: state?.power_apps,
});

export default connect(mapStateToProps)(PowerApps);
