import React, { Component } from "react"; 
import {Howl} from 'howler'  
import { INCOMING_RING, OUTGOING_RING } from "../config/parameters";
import { firebaseDatabase } from "../../../Utils/FirebaseService";

const callTypes = {
    oc: "Outgoing Video Call",
    ic: "Incoming Video Call",
};

let incoming_tone = new Howl({src: [`${window.location.origin}/${INCOMING_RING}`],loop: true});

let outgoing_tone = new Howl({src: [`${window.location.origin}/${OUTGOING_RING}`],loop: true});


class CallAlertPopup extends Component {
    state = {
        callStatusRef: firebaseDatabase.ref("Call"),
        disableBtn: false,
    };

    componentDidUpdate(oldProps){
        const {show,callData: { callType,status } } = this.props;
        if(show){ 
            window.$('#video-call-pop').modal({show:true, backdrop: 'static',keyboard: false}); 
            if(callType==="oc" && status==="ringing"){
                outgoing_tone.play();
            }else if(callType==="ic" && status==="ringing"){
                incoming_tone.play();
            }else{
                incoming_tone.pause();
                outgoing_tone.pause();
            }              
        }else{
            window.$('#video-call-pop').modal('hide'); 
            window.$('body').removeClass('modal-open');
            window.$('.modal-backdrop').remove();
            incoming_tone.pause();
            outgoing_tone.pause();            
        }
    }

    // prettier-ignore
    rejectCall = (callType) => {
        const msg = callType === "ic" ? "rejected" : "terminated"
        const { callStatusRef } = this.state;
        const { callData:{conf} } = this.props;
        callStatusRef.child(conf).child("status").set(msg);
        this.props.handleClose();
    };
    // prettier-ignore
    acceptCall = () => {
        const { callStatusRef } = this.state;
        const { callData:{conf} } = this.props;
        callStatusRef.child(conf).child("status").set("accepted");
        this.setState({disableBtn: true}, () => {
            setTimeout(() => {
                this.setState({disableBtn: false})
                this.props.handleClose();
            }, 100)
        })
    };

    render() {
        const {disableBtn } = this.state;
        const {
            show, 
            callData: { callType, name, callStatusType },
        } = this.props;
        if(!show) {
            return null;
        }
        return (
            <div
                className="modal dailer-modal fade modal_call_popup in"
                id="video-call-pop"
                tabIndex="-1"
                ariaHidden="true"
            >
                <div className="modal-dialog modal-sm justify-content-end" role="document" style={{flexDirection: 'column',alignItems: 'flex-end'}}>
                    <div className="modal-content" style={{width: '331px',position: 'absolute',bottom: '130px'}}>
                        <div className="row">
                            <div className=" col-md-12">
                                <div className="call_window">
                                    <h4>{callType && callTypes[callType]}</h4>
                                    <div className="col-md-12 form-group">
                                        <h5 id="ringing_number">{callStatusType}</h5>
                                        {name && <h5 id="incoming_number">{name}</h5>}
                                    </div>
                                    <h6 className="text-center">
                                        {callType == "ic" && <><button
                                            id="answerCall"
                                            className="btn btn-success"
                                            onClick={() => this.acceptCall()}
                                            disabled={disableBtn}
                                        >
                                            <i className="fa fa-phone"></i>
                                            &nbsp;Answer
                                        </button>
                                        &nbsp;</>}
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => this.rejectCall(callType)}
                                        >
                                            <i className="fa fa-phone"></i>
                                            &nbsp;{callType == "ic" ? "Reject" : "End"}
                                        </button>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) 
    }
}

export default CallAlertPopup;
